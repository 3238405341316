.blog-post {
	.blog-list-meta-wrap {
		display: table;
		width: 100%;
		border-bottom: 1px solid #ccc;
		margin-bottom: 30px;
		ul {
			&.blog-list-meta {
				list-style: none;
				margin: 0;
				padding: 0;
				display: table;
				@media (max-width:767px) {
					margin-top: 7px;
					padding: 0;
				}							
				li {
					display: table-cell;
					vertical-align: middle;
					border: none;
					padding: 0 30px 25px 0;
					&:nth-child(even) {
						background-color: transparent;
					}	
					@media (max-width:767px) {
						display: table;
						margin: 9px 0;
						padding: 0;
					}								
					.meta-author-table {
						list-style: none;
						margin: 0;
						padding: 0;
						display: table;
						width: 100%;	
						@media (max-width:767px) {
							margin-bottom: 7px;
						}								
						.author-image {
							display: table-cell;
							vertical-align: middle;
							width: 50px;
							img {
								width: 42px;
								-webkit-border-radius: 50px;
								-moz-border-radius: 50px;
								border-radius: 50px;									
							}
						}
						.author-by {
							display: table-cell;
							vertical-align: middle;
							font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
							font-size: 14px;
							@media (max-width:767px) {
								padding-left: 10px;
							}								
						}
						.author-image {
							&+ {
								.author-by {
									@media (max-width:767px) {
										padding-left: 0;
									}											
								}
							}
						}						
					}
					.blog-date-wrap {
						display: table;
						margin: 0;
						padding: 0;
						width: 100%;	
						.icon-wrap {
							display: table-cell;
							vertical-align: middle;
							width: 37px;
							@media (max-width:767px) {
								width: 50px;
								text-align: center;
							}									
							.icon {						
								fill: #0a4e8c;
								width: 30px;
								height: 30px;
							}
						}
						.blog-date {
							display: table-cell;
							vertical-align: middle;	
							font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
							font-size: 14px;
						}
					}
					.comments {
						display: table;
						margin: 0;
						padding: 0;
						width: 100%;	
						a {
							display: table-row;
							.icon-wrap {
								display: table-cell;
								vertical-align: middle;
								width: 37px;
								@media (max-width:767px) {
									width: 50px;
									text-align: center;
								}										
								.icon {						
									fill: #0a4e8c;
									width: 30px;
									height: 30px;
								}
							}
							.icon-txt {
								display: table-cell;
								vertical-align: middle;		
								font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
								font-size: 14px;	
								span {
									box-decoration-break: clone;
									transition: all .5s ease-in-out;
									position: relative;
									background-image: linear-gradient(to bottom,rgba(10,78,140,.3),rgba(10,78,140,.3));										
									background-position: 0 100%;
									background-repeat: no-repeat;
									line-height: 5px;
									color: #0a4e8c;
									letter-spacing: 1px;
									margin: 10px 0 0;										
									background-size: 0 1px;											
								}
							}
							&:hover,
							&:focus {
								.icon-txt {
									span {
										background-size: 100% 1px;
									}
								}
							}									
						}
					}
					.blog-date {
						font-size: 14px;
						font-weight: 400;
						list-style: none;
						margin: 0;
						padding: 0;
						border: none;
						@media (max-width:767px) {
							font-size: 13px;
							line-height: 18px;									
						}			
					}						
				}
			}	
		}				
	}	
	h1,
	h2 {
		margin: -7px 0 20px;
		padding: 0;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 54px;
		font-weight: 900;
		background: 0 0;
		border: 0;
		color: #373737;
		text-transform: uppercase;
		line-height: 54px;	
		@media (max-width:767px) {
			font-size: 30px;
			line-height: 35px;	
			margin-bottom: 0;
		}		
	}
	.media {
		float: left;
		max-width: 30%;
		margin: 7px 3% 1% 0;
		@media (max-width:767px) {
			float: none;
			display: table;
			max-width: 100%;
			margin: 0 auto 15px;
		}			
	}
	.content {
		font-size: 18px;
		font-weight: 300;
		line-height: 25px;
		color: #444;		
	}
	.comments-link {
		display: block;
		margin-top: -120px;
		padding-bottom: 120px;
	}	
	.leave-a-comment {
		display: table;
		border-top: 1px solid #ccc;
		margin-top: 15px;
		padding: 15px 0 0; 
		width: 100%;
		h3 {
			padding-bottom: 15px;
		}
		[data-sf-role="comments-new-form-button"] {
			display: block;
			margin-bottom: 15px;
		}
		[data-sf-role="comments-new-form"] {
			display: table;
			width: 100%;
			img {
				display: table;
				margin-bottom: 15px;
			}
			textarea {
				display: table;
				margin-bottom: 15px;
				width: 100%;
				border-color: #bebebe #dadada #d7d7d7;
				border-style: solid;
				border-width: 1px;				
				background: #fff;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-ms-box-sizing: border-box;
				-webkit-box-sizing: border-box;				
				color: #000;
				font-size: 16px;
				font-family: inherit;
				-moz-border-radius: 2px;
				-webkit-border-radius: 2px;
				border-radius: 3px;
				padding: 6px 10px;
				line-height: 1.42857;				
			}
			button {
				display: block;
				float: right;
				margin: 0;
				margin-left: 10px;
				padding: 9px 25px 10px;
				color: #bdd7ef !important;
				background-color: #0a4e8c;
				border: 1px solid #084174;
				font-size: .875em;
				font-weight: 700;
				text-transform: uppercase;
				text-align: center;
				text-decoration: none;
				line-height: 1.42857;
				cursor: pointer;
				overflow: visible;
				transition: all .3s;
				border-radius: 5px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;		
				&:focus,
				&:hover {
					background-color: #e4701e;
					border-color: #e4701e;
					-moz-box-shadow: 0 0 10px rgba(0,0,0,.3);
					-webkit-box-shadow: 0 0 10px rgba(0,0,0,.3);
					-o-box-shadow: 0 0 10px rgba(0,0,0,.3);
					box-shadow: 0 0 10px rgba(0,0,0,.3);
				}				
			}
		}		
	}
}