﻿.company-training-container {

    h2 { 
        &.section-title {
            line-height: 1em;
        }
    }
    
    .training-intro {
        padding: 0 80px 0 0;

        @media(max-width:992px) {
            width: 100%;
            padding: 0;
        }

        h2 {
            color: $color-primary-light;
        }
    }

    .training-search {
        @media(max-width:992px) {
            width: 100%;
        }

        .events-search-top {
            display: flex;
            flex-wrap: wrap;
            background: transparent;
            border: none;
            padding: 0;

            h2 {
                width: 100%;
                color: $color-primary-light;
                font-family: Montserrat,Arial,sans-serif;
                font-size: 1.35em;
                font-weight: 400;
                margin: 0 0 20px 0;
            }

            .formee {
                width: 100%;

                input {
                    height: unset;
                    padding: 15px;
                    border: 1px solid $color-border;

                    &.input-txt {
                        color: #000;
                    }

                    &.input-txt::-webkit-input-placeholder,
                    &.input-txt:-moz-placeholder,
                    &.input-txt::-moz-placeholder,
                    &.input-txt:-ms-input-placeholder {
                        color: #000;
                        font-style: normal;
                        text-transform: none;
                    }
                }

                select {
                    padding: 14px 15px;
                    border: 1px solid $color-border;
                }
            }

            .events-search-category,
            .events-search-location {
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;

                &:after {
                    content: '\f107';
                    font-size: 28px;
                    position: absolute;
                    top: 12px;
                    right: 20px;
                    color: $color-primary-light;
                    pointer-events: none;
                }
            }

            .events-search-date-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .events-search-date, .events-search-date-to, .events-search-location {
                    width: 32%;
                }

                .events-search-date, .events-search-date-to {
                    margin: 0 2% 0 0;

                    @media(max-width:1200px) and (min-width:992px) {
                        width: 49%;
                        margin: 0;
                    }

                    @media(max-width:600px) {
                        width: 49%;
                        margin: 0;
                    }
                }

                .events-search-date {
                    @media(max-width:1200px) and (min-width:992px) {
                        margin: 0 2% 0 0;
                    }

                    @media(max-width:600px) {
                        margin: 0 2% 0 0;
                    }
                }

                .events-search-location {
                    @media(max-width:1200px) and (min-width:992px) {
                        width: 100%;
                    }

                    @media(max-width:600px) {
                        width: 100%;
                    }
                }
            }

            .btnWrapper {
                width: 100%;
                display: flex;

                .btn {
                    min-width: 200px;
                    color: #fff;
                    font-size: 1em;
                    padding: 15px;
                }
            }
        }

    }
}

h2 {
    &.section-title {
        font-family: Montserrat,Arial,sans-serif;
        font-size: 2.75em;
        font-weight: 700;
    }
}

.training-featured-event-wrapper {
        margin: 50px 0;
}

.training-featured-event {
        width: 100%;
        background-color: $color-primary-light;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 50px 25px;

        h3 {
            width: 100%;
            color: #fff;
            font-size: 2em;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 7px 0;
        }

        p {
            width: 100%;
            color: #fff;
            font-size: 1em;
            font-weight: 300;
            text-transform: uppercase;

            &.featured-event-subtitle {
                font-size: 1.5em;
                margin: 0;
            }
        }

        .featured-event-buttons-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 20px 0 0 0;
        }

        .featured-event-register-button-wrapper {
            margin: 0 20px 0 0;

            @media(max-width:600px) {
                margin: 0 20px 20px 0;
            }

            .btn {
                background-color: #e4701e;
                min-width: 200px;
                font-weight: 500;

                &:hover {
                    background-color: darken(#e4701e, 10%);
                }
            }
        }

        .featured-event-details-button-wrapper {
            .btn {
                min-width: 200px;
                font-weight: 500;
                border: 1px solid #fff;

                a {
                    color: #fff;
                }
            }
        }
}


.training-upcoming-events-wrapper, 
.training-on-demand-webinars-wrapper {
	margin: 50px 0 0;
	@media(min-width:981px) {
		margin-top: 80px;
	}	
	@media(max-width:700px) {
		margin-top: 20px;
	}
	h2 {
        font-family: Montserrat,Arial,sans-serif;
		font-size: 1.75em;
		font-weight: 700;
		text-align: center;
		line-height: 0;
		border-bottom: 1px solid $color-border;
		margin: 0 0 50px 0;
		@media(max-width:700px) {
			line-height: 30px;
			border-bottom: none;
			margin-bottom: 10px;
		}			

		span {
			background-color: #fff;
			padding: 0 100px;
			@media(max-width:700px) {
				padding: 0 10px;
			}				
		}
	}
}

.eventsWidget {
    width: 100%;
    display: flex;
    //flex-wrap: wrap;

    .events-widget-content {
        /*min-height: 245px;*/

        @media(max-width:992px) {
            /*min-height: 270px;*/
        }

        @media(max-width:700px) {
            /*min-height: 220px;*/
        }

        .events-widget-view-btn {
            align-self: flex-end;
        }
    }
}

.events-widget-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
    @media(min-width:701px) and (max-width:992px) {
    	grid-template-columns: 1fr 1fr 1fr;
    }
    @media(max-width:700px) {
    	grid-template-columns: 1fr 1fr;
    }	
}

.events-widget-item {
    padding: 15px 25px;
	/* IE10+ specific styles go here */  
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
		display: inline-block;
		width: 25%;
		padding: 15px 10px;
	}	

//    @media(max-width:700px) {
//        width: 100%;
//        padding: 15px 10px;
//    }
}

.training-upcoming-events-wrapper {
	#upcomingEvents {
		&.eventsWidget {
			display: block;
            width: 100%;
			overflow: hidden;
			-moz-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			-webkit-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			&.widget-visible {
				overflow: visible;
			}
			.events-widget-item {
				padding: 20px 25px;

                @media(max-width:700px) {
                    padding: 20px 10px;
                }
			}					
			.events-widget-title {
				font-size: 16px;
                min-height: 90px; 

                @media(max-width:992px) {
                    font-size: 14px;
                    min-height: 80px;
                }
			}
			.events-widget-location, 
			.events-widget-time {
				font-size: 12px;
				margin-bottom: 5px;
			}
			.events-widget-view-btn {
				margin-top: 3px;
				.btn {
					padding: 7px 10px 7px 20px;
					font-size: 12px;
					.icon {
						margin: -3px 0 0 5px;
					}
				}
			}
			.events-widget-image {
				display: block;
				margin: 0 auto;
				img {
					margin: 0 auto;
					width: 100%;
					height: 100%;
					max-height: 200px;
				}
			}
		}
	}
	.training-on-demand-webinars-wrapper {
		.eventsWidget {
			display: block;
            width: 100%;
			overflow: hidden;
			-moz-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			-webkit-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			&.widget-visible {
				overflow: visible;			
			}

            .events-widget-content {
                /*min-height: 215px;*/

                @media(max-width:992px) {
                    /*min-height: 175px;*/
                }

                @media(max-width:700px) {
                    /*min-height: 190px;*/
                }

                .events-widget-view-btn {
                    align-self: flex-end;
                }
            }
			.events-widget-title {
				font-size: 16px;

                @media(max-width:992px) {
                    font-size: 14px;
                    max-height: 120px;
                    min-height: 115px;
                }

                @media(max-width:700px) {
                    max-height: 115px;
                }
			}
			.events-widget-location, 
			.events-widget-time {
				font-size: 12px;
				margin-bottom: 5px;
			}
			.events-widget-view-btn {
				margin-top: 3px;
				.btn {
					padding: 7px 10px 7px 20px;
					font-size: 12px;
					.icon {
						margin: -3px 0 0 5px;
					}
				}
			}
			.events-widget-image {
				display: block;
				margin: 0 auto;
				img {
					margin: 0 auto;
					width: 100%;
					height: 100%;
					max-height: 200px;
				}
			}
		}
	}	
}


.events-widget-image {
    background-color: #0a4e8c;

    img {
        &:hover, &:focus {
            opacity: .5;
        }
    }
}

.events-widget-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.over-display-event {
    .events-widget-content {
        min-height: 125px;

        @media(max-width:992px) {

        }
    }
}

.events-widget-details-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: center;

    @media(max-width:700px) {
        padding: 0 0 15px 0;
    }
}

.events-widget-title {
    width: 100%;
    margin: 10px 0;

    a {
        color: #000;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.events-widget-time, .events-widget-location {
    width: 100%;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    margin: 0 0 10px 0;
}

.events-widget-view-btn {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: 500;
}

.events-widget-load-more {
    width: 100%;
    text-align: center;
    padding: 25px 0;

    .btn {
        font-size: 1em;
        font-weight: 500;
        padding: 20px 75px;
    }

    .btnTextAction {
        width: 25%;
        display: flex;
        justify-content: center;
        color: #e4701e;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0 0 0 40px;

        .icon {
            display: inline-block;
            vertical-align: middle;
            fill: #e4701e;
            width: 12px;
            height: 22px;
            margin: 0 0 0 5px;
        }
    }
}


.training-upcoming-events-wrapper {

    .events-widget-summary {
        display: none;
    }
}

.training-on-demand-webinars-wrapper {
    margin-top: 150px;

    @media(max-width:700px) {
        margin-top: 100px;
    }

    .events-widget-time,
    .events-widget-summary,
    .pagination {
        display: none;
    }
}

.interior-callouts-header-title {
    width: 100%;
    @include title-font-large;
    font-family: Montserrat,Arial,sans-serif;
	font-weight: 800;
	color: #333;
    margin: 40px 0;

    @media(max-width:767px) {
		font-size: 25px;
		margin: 20px 0;
    }	
}