/* Account Progress Bar */
.account_progress {
    &-bar-wrap {
        padding-left: 10px;
        padding-right: 10px;
        .account_progress {
            &-setup {
                &--slow {
                    position: relative;
                    display: flex;
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    @media(max-width:1089px) {
                        flex-flow: column;
                    }                       
                    svg {
                        margin: 0 auto;
                    }
                }
                &--dsc {
                    min-width: 54%;
                }
                &--dsc-inner {
                    max-width: 45%;
                    @media(min-width:1090px) and (max-width:1270px) {
                        max-width: 70%;
                    }  
                    @media(max-width:1089px) {
                        max-width: none;
                    }                                                          
                }
                &__content,
                &__number,
                &__label,
                &__icon {
                    width: 100%;
                }
                &__number {
                    font-size: 1.8em;
                    line-height: 25px;
                    font-weight: 800;
                    margin-bottom: 30px;                    
                }
                &__label {
                    margin-top: 32px;
                    font-size: 12px;
                }
                &__svg {
                    display: block;
                    position: relative;
                    width: 246px;     
                    @media(max-width:499px) {
                        width: 100px;
                    }                                  
                    svg {
                        position: relative;
                        @media(max-width:499px) {
                            width: 100px;
                        }                         
                    }
                }
                &__svg-wrap {
                    position: relative;
                    width: 46%;
                }
                &__svg-inner {
                    position: relative;
                    display: inline-block;
                    @media(max-width:1089px) {
                        margin-left: 180px;
                    } 
                    @media(max-width:579px) {
                        margin-left: 100px;
                    } 
                    @media(max-width:499px) {
                        margin-left: 95px;
                    }                                        
                }
                &__list {
                    position: absolute;
                    display: block;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    >li {
                        display: block;
                        position: absolute;
                        font-size: 0.8em;
                        text-align: center;
                        span {
                            display: block;
                        }                         
                        &:nth-child(1) {
                            top: -5px;
                            right: -60px;
                            @media(max-width:499px) {
                                right: -70px;
                            }                            
                        }
                        &:nth-child(2) {
                            bottom: 40px;
                            right: -130px;
                            @media(max-width:499px) {
                                bottom: -15px;
                                right: -90px;
                                width: 100px;
                            }                              
                        }
                        &:nth-child(3) {
                            bottom: -35px;
                            left: 0;
                            right: 0;
                            width: 100%;
                            @media(max-width:499px) {
                                bottom: -75px;
                            }                               
                        }
                        &:nth-child(4) {
                            bottom: 40px;
                            left: -130px;
                            @media(max-width:579px) {
                                left: -105px;
                                width: 120px;
                            }    
                            @media(max-width:499px) {
                                bottom: -15px;
                                width: 100px;
                            }                                                    
                        }
                        &:nth-child(5) {
                            top: -5px;
                            left: -130px;
                            @media(max-width:579px) {
                                left: -105px;
                                width: 130px;
                            } 
                            @media(max-width:499px) {
                                top: 0;
                                left: -95px;
                                width: 100px;
                            }                                                        
                        }
                    }
                    &--b2b {
                        position: absolute;
                        display: block;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;                        
                        >li {
                            display: block;
                            position: absolute;
                            font-size: 0.8em;
                            text-align: center;
                            span {
                                display: block;
                            }                         
                            &:nth-child(1) {
                                top: -5px;
                                right: -60px;
                                @media(max-width:499px) {
                                    right: -80px;
                                }
                            }
                            &:nth-child(2) {
                                bottom: 90px;
                                right: -110px;
                                @media(max-width:499px) {
                                    bottom: 30px;
                                }
                            }                            
                            &:nth-child(3) {
                                bottom: 20px;
                                right: -130px;
                                @media(max-width:499px) {
                                    bottom: -10px;
                                }
                            }
                            &:nth-child(4) {
                                bottom: -34px;
                                right: -10px;
                                @media(max-width:499px) {
                                    bottom: -75px;
                                    right: -30px;
                                    width: 70px;
                                }
                            }
                            &:nth-child(5) {
                                bottom: -34px;
                                left: 10px;
                                @media(max-width:499px) {
                                    bottom: -56px;
                                    left: -10px;
                                    width: 70px;
                                }                                
                            }
                            &:nth-child(6) {
                                bottom: 20px;
                                left: -170px;    
                                @media(max-width:579px) {
                                    left: -105px;
                                    width: 120px;
                                }
                                @media(max-width:499px) {
                                    bottom: -30px;
                                    width: 100px;
                                }                                                                     
                            }                  
                            &:nth-child(7) {
                                top: 20px;
                                left: -110px;
                                @media(max-width:579px) {
                                    left: -105px;
                                    width: 130px;
                                } 
                                @media(max-width:499px) {
                                    top: 0;
                                    width: 100px;
                                }                                                                 
                            }
                        }
                    }
                }
                .account_progress {
                    &-setup {
                        &__svg-inner {
                            >.account_progress {
                                &-setup__svg {
                                    &.loading-svg {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* This will need to be fixed / redone before DEV work */

.account_progress-setup__svg-chart path { fill: #d1d5d7; stroke: #d1d5d7; stroke-width: 2px; }
.account_progress-setup__svg-chart path.active { fill: #084985; display: none; }
.account_progress-setup__svg + .account_progress-setup__svg { display: none; }
.account_progress-setup__svg-chart.loading-svg-hidden { position: absolute; top: 0;  z-index: 1; }
.account_progress-setup--slow.progress-hidden { width: 0; height: 0;}
.account_progress-setup--slow.progress-hidden .account_progress-setup--dsc { display: none; }
.account_progress-bar-wrap.b2c .account_progress-setup__svg + .account_progress-setup__svg { display: block;}
.dot-placholder-white {   
   position: absolute;
   left: 57px;
   top: 56px;
   height: 131px;
   width: 131px;
   background-color: #fff;
   border-radius: 50%;
   display: inline-block; 
   z-index: 2;
 }
 @media screen and (max-width:499px) {
    .account_progress-setup__svg-chart.loading-svg-hidden svg {
        width: 100px !important;
        height: 100px !important;
    }
   .dot-placholder-white {
        left: 25px;
        top: 26px;    
       height: 52px;
       width: 52px;
   }
 }