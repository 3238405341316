﻿.sign-in-pg-content-wrapper {
    @include containers-w-margin;
}

.login-form-col {
    &:first-of-type { padding: 0 20px 0 0; }
    &:last-of-type { padding: 0 0 0 20px; }
}

.login-form-wrapper {

    input {
        display: inline-block;
        margin: 0 10px 0 0;
    }

    label {
        display: inline-block;
    }

    a {
        font-weight: 600;
    }
}

.login-form-header {
    @include title-font;
    padding: 15px 40px;
    text-align:center;
    font-weight:bold;
}

.login-form-content {
    padding: 40px;
}


.login-form-title {
    width: 70%;
    display: inline-block;
    font-weight: 500;
    margin: 0 0 30px 0;
}

.login-form-req-title {
    width: 29%;
    display: inline-block;
    @include form-req-font;
    text-align: right;
}

.login-form-keep-login {
    width: 49.62%;
    display: inline-block;
    margin: 10px 0 20px;
}

.login-form-forgot-pass {
    width: 49.62%;
    display: inline-block;
    text-align: right;
    margin: 10px 0 20px 0;
}

.login-form-btn {
    .btn {
        width:100%;
    }
}

.login-form-existing-account,
.login-form-email-updates {
    margin: 0 0 20px 0;
}

.tab-module {

	.tabs-nav {
		padding:0 0 30px 0;
		margin:0 0 40px 0;
		list-style-type: none;
		border-bottom:1px solid #e3e3e3;
		text-align:center;
	
		 li {
			display:inline-block;
			margin:0 20px;
	
			a {
				font-size:24px;
				color:#333333;
				font-weight:bold;
				text-decoration:none;
				padding:10px 0;
				border-bottom:2px solid #fff;
				
				&.active {
					border-bottom:2px solid #e47426;
				}
			}
	
		}
	}

	.tabs-panel {
		display:none;
		margin: 0 auto;
		float: none;
		max-width:525px !important;
		width:100%;
		padding:0px !important;
	
		&.active {
			display:block;
		}
	}	
	
}

.rocMenu {

    .signIn {
        font-family: "IBM Plex Sans",sans-serif;
    }

    .login-form-header {
        margin:0 0 10px 0;
        padding:0 0 3px 0;
        border-bottom:2px solid #e47426;
        font-size: 16px;
        font-weight: 600;
        display:inline-block;
    }

    .login-form-content {
        padding: 0px;
    }

    .login-form-req-title {
        float: right;
        font-size: 12px;
        position: relative;
        top:22px;
    }

    .login-form-field label {
        text-align:left;
        @include login-form-label-font;
    }

    .login-form-keep-login {
        width:100%;
        text-align:left;
        margin:0 0 10px;

        label {
            font-size:14px;
            display:inline-block;
        }
    }

    .login-form-forgot-pass {
        float: right;
        font-size: 12px;
        margin: -84px 0 0 0;
    }
    
    hr {
        margin:20px 0;
    }

    .create-account-title {
        font-size:14px;
        font-weight:600;
    }

    .contentBoxInterior {
        padding:10px;
    }

    .contentBoxInterior > .title {
        @include login-form-label-font;
        margin-bottom:10px;
    }
}