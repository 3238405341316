/* # PRINT STYLES */

@media print {
	* { background: transparent !important; color: #000 !important; box-shadow:none !important; text-shadow: none !important; }
	a, a:visited { text-decoration: underline; }
	
	/* a[href]:after { content: " (" attr(href) ")"; }*/
	
	abbr[title]:after { content: " (" attr(title) ")"; }
	.ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }
	pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
	thead { display: table-header-group; }
	tr, img { page-break-inside: avoid; }
	img { max-width: 100% !important; }
	@page { margin: 0.5cm; }
	p, h2, h3 { orphans: 3; widows: 3; }
	h2, h3 { page-break-after: avoid; }
	
	
	/* ## Print Layout */
	
	.siteBannerTop {
		display: none;
	}
	
	.navbarWrapper {
		display: none;
	}
	
	.siteHeader {
		.headerTop {
			display: none;
		}
		
		.textBanner {
			display: none;
		}
	}
	
	.productDetails {
		.grid_6 {
			width: 48%;
			float: left;
		}
	}
	
	.siteBody {
		.pageToolsWrapper {
			display: none;
		}
		
		.siteBodyTop {
			display: none;
		}
	}
	
	.footer {
		.navSpan {
			display: none;
		}
		
		.followUs {
			display: none;
		}
	}
	
	.emailSignup {
		display: none;
	}
	
	/* ## left rail */
	
	.grid_3 {
	
		.railNavWrapper {
			display: none;
		}
		
		.contentBox {
			display: none;
		}
	}
	
	.rocFavoritesButtons {
		display: none;
	}

    .invoice-details-table {
        .print-small {
            font-size: 14px;
        }
    }
	
}