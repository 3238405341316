.events-search-top {
	display: table;
	padding: 20px 0;
	margin-bottom: 15px;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;		
	position: relative;
	width: 100%;
	background-color: rgba(223, 223, 223, 0.3);
	border: 1px solid #ccc;
	h2 {
		margin: 0 20px 10px;
		padding: 0;
		font-family: Montserrat,Arial,sans-serif;
		font-weight: 600;
		font-size: 14px;
		text-transform: uppercase;
		color: #373737;
	}
	.events-search-table {
		display: table;
		table-layout: fixed;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;		
		width: 100%;	
		label {
			border: 0; 
			clip: rect(0 0 0 0); 
			height: 1px; 
			margin: -1px; 
			overflow: hidden; 
			padding: 0; 
			position: absolute; 
			width: 1px;
		}
		input {
			&.input-txt {
				border: 1px solid #bebebe;
				background: #fff;
				color: #373737;
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
				font: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
				font-size: 14px;
				font-weight: 400;
				font-family: inherit;	
				-moz-border-radius: 3px;
				-webkit-border-radius: 3px;
				border-radius: 3px;			
				padding: 0 15px;
				width: 100%;
				height: 39px;				
			}
			&.input-txt::-webkit-input-placeholder { /* WebKit, Blink, Edge */
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
				font: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
				font-size: 14px;
				font-weight: 400;	
				font-style: italic;
				text-transform: uppercase;
				color: #373737;						
			}
			&.input-txt:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
				font: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
				font-size: 14px;
				font-weight: 400;
				font-style: italic;
				text-transform: uppercase;
				color: #373737;
			}
			&.input-txt::-moz-placeholder { /* Mozilla Firefox 19+ */
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
				font: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
				font-size: 14px;
				font-weight: 400;	
				font-style: italic;
				text-transform: uppercase;
				color: #373737;	
			}
			&.input-txt:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
				font: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
				font-size: 14px;
				font-weight: 400;
				font-style: italic;
				text-transform: uppercase;
				color: #373737;
			}							
		}
		.events-search-table-tr {
			display: table-row;
			@media (max-width:767px) {
				display: table;
				width: 100%;
			}			
			.events-search-name {
				display: table-cell;
				vertical-align: middle;
				padding: 0 10px 15px 20px;
				width: 50%;
				@media (max-width:767px) {
					display: table;
					margin: 0 auto 0;
					padding-left: 20px;
					padding-right: 20px;
					width: 100%;
				}				
			}
			.events-search-category {
				display: table-cell;
				vertical-align: middle;	
				padding: 0 20px 15px 10px;
				width: 50%;
				@media (max-width:767px) {
					display: table;
					margin: 0 auto 0;
					padding-left: 20px;
					padding-right: 20px;
					width: 100%;
				}				
			}
			.events-search-dates {
				display: table-cell;
				vertical-align: middle;
				padding: 0 10px 15px 20px;
				@media (max-width:767px) {
					display: table;
					width: 100%;
					padding-left: 20px;
					padding-right: 20px;					
				}				
				.events-search-date-in {
					display: block;
					table-layout: fixed;
					width: 100%;	
					@include sm {
						display: table;					   
					}
					.events-search-date {						
						@include sm {
							display: table-cell;
							vertical-align: middle;
							padding: 0 10px 0 0;
							width: 50%;		   
						}				
					}
					.events-search-date-to {
						@include sm {
						    display: table-cell;
							vertical-align: middle;		
							padding: 0 0 0 10px;
							width: 50%;
						}
					}					
				}
			}
			.events-search-location {
				display: table-cell;
				vertical-align: middle;	
				padding: 0 20px 15px 10px;
				@media (max-width:767px) {
					display: table;
					width: 100%;
					padding-left: 20px;
					padding-right: 20px;					
				}				
			}			
		}
	}

    .formee-clear-all {
        margin: 0 0 25px 0;
    }

	.btnWrapper {
		display: table;
		margin: 5px auto 0;
		.btn {			
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 1px;
			color: #bdd7ef;	
            
            a {
                color: #fff;
            }		
		}
	}
}

.eventsWidget {
	.eventsWidget-wrapper {
		.eventsWidget-item {
			display: table;
			position: relative;
			margin: 30px 0;
			width: 100%;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			@media (max-width:979px) {
				margin: 25px 0;
				padding-top: 25px;
			}			
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
				@media (max-width:979px) {
					margin-bottom: 25px;
				}				
            }			
			&:nth-child(even) {
				background-color: rgba(223, 223, 223, 0.3);
			}			
			.eventsWidget-itemLeft {
				display: table-cell;
				vertical-align: middle;
				text-align: center;				
				padding-right: 25px;
				width: 25%;
				@media (max-width:979px) {
					display: table;
					margin: 0 auto 0;
					padding: 0;
					width: auto;
				}
				&+.eventsWidget-itemRight {
					@media (max-width:979px) {
						padding-top: 10px;
					}				
				}				
			}
			.eventsWidget-itemRight {
				display: table-cell;
				vertical-align: top;	
				padding-right: 15px;
				@media (max-width:979px) {
					display: table;
					margin: 0 auto;
					text-align: center;
					padding: 0 15px 10px;
					width: auto;
				}				
				h3 {
					&.eventsWidget-title {
						display: block;
						margin: 15px 0 5px;
						font-size: 22px;
						line-height: 30px;
						color: #444;
						font-weight: 300;
						word-wrap: break-word;
						text-transform: uppercase;
						@media (max-width:979px) {
							margin-top: 0;
							font-size: 16px;
							line-height: 20px;
						}							
						a {
							color: #444;
							font-weight: 300;
							box-decoration-break: clone;
							transition: all .5s ease-in-out;
							position: relative;
							background-image: linear-gradient(to bottom,rgba(68, 68, 68,.3),rgba(68, 68, 68,.3));
							background-size: 0 1px;
							background-position: 0 100%;
							background-repeat: no-repeat;
							letter-spacing: 1px;
							&:hover,
							&:focus {
								background-size: 100% 1px;	
							}								
						}						
					}
				}
				.eventsWidget-time {
					display: block;
					margin: 0;
					font-weight: 400;
					font-style: italic;	
					color: #000;
					@media (max-width:979px) {
						font-size: 14px;
						line-height: 18px;									
					}					
				}
				.eventsWidget-location {
					display: block;
					margin: 5px 0 0;		
					font-weight: 400;
					color: #000;
					@media (max-width:979px) {
						font-size: 14px;
						line-height: 18px;									
					}					
				}
				.eventsSummary {
					margin: 10px 0 15px;
					font-family: Lato,sans-serif;
					font-size: 18px;
					font-weight: 100;
					line-height: 25px;
					color: #444;
					@media (max-width:979px) {
						font-size: 14px;
						line-height: 18px;									
					}					
					.read-more {
						display: table;
						box-decoration-break: clone;
						transition: all .5s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom,rgba(10,78,140,.3),rgba(10,78,140,.3));
						background-size: 100% 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;
						font-size: 15px;
						font-weight: 400;
						line-height: 22px;
						color: #0a4e8c;
						letter-spacing: 1px;
						margin: 10px 0 0;
						&:hover,
						&:focus {
							background-size: 0 1px;
						}
						@media (max-width:979px) {
							margin-left: auto;
							margin-right: auto;
							font-size: 12px;
							line-height: 16px;
						}						
					}					
				}
			}
		}
	}
	&:last-child {
		.eventsWidget-wrapper {
			.eventsWidget-item {
				@media (max-width:979px) {
					margin-bottom: 0;
				}				
			}
		}		
	}		
}