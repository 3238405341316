﻿.roc-pageOptions-filter {
    display: inline-block;
    margin-right: 10px;
	vertical-align: top;

    @media (max-width: 767px){
		width: 100%;
	}

	label, .roc-pageOptions-label {
		font-size: 1em;
        font-weight: 600;
        margin: 0 10px 0 0;

        @media only screen and (max-width:48em){
            white-space: nowrap;
        }
	}

    select {
		width: auto;
        min-width: 60px;
		margin: 0;
        height: 50px;
        
        &:first-child {
            margin-left: 0;
        }
    }

    input[type='text']{
        width: 200px;
    }

    .calendar-image {
        background-repeat: no-repeat !important;
        background-image: url(../../images/calendar.png) !important;
        background-size: 25px 26px !important;
        background-position: 98% center !important;
    }
}

.search-filter-pagination {
    .roc-pageOptions-filter {
        width: 50%;
    }

    .pagination {
        display: inline-block;
        width: 48%;
    }
}

.rocFavoritesListing  {
    .roc-pageOptions-filter {
		&.favorites {
			margin-right: 30px;		
			@media (max-width:767px) {
				display: table;
				width: auto;
				margin-left: auto;
				margin-right: auto;			
			}
			label  {
				@media (max-width:767px) {
					display: table-cell;
					width: 50%;
					padding-right: 15px;
				}				
			}		
			select  {
				@media (max-width:767px) {
					display: table-cell;
					min-width: 50% !important;
				}				
			}
		}
	}
}