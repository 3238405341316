﻿.pagination {
    text-align: right;
}

.paging {
	display: table;
	margin: 0 auto;
    input[type=number] {
		display: table-cell;
		vertical-align: middle;
        text-align: center;
        margin: 0 10px 0 10px;
		width: 48px;
		height: 50px;
		border: 1px solid #ccc;
    }
	/* Hide HTML5 Up and Down arrows. */
	input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="number"] {
		-moz-appearance: textfield;
	}	
	.total {
		display: table-cell !important;
		vertical-align: middle !important;		
		font-family: "IBM Plex Sans",sans-serif !important;
		font-size: 16px !important;
		font-weight: 600 !important;
		color: #333 !important;
		padding-right: 10px;
	}
    .icon-arrow-bracket-left, .icon-arrow-bracket-right {
		display: table-cell;
		vertical-align: middle;
        background-color: $color-primary;
        color: $color-text-secondary;
        font-weight: 700;
        text-align: center;
        padding: 1px 0;
        border: none;
		-webkit-transform: perspective(1px) scale(1);
		-ms-transform: perspective(1px) scale(1);
		transform: perspective(1px) scale(1);
		transition: all 1s cubic-bezier(.25,.46,.45,.94);		
		&:hover,
		&:focus {
			 background-color: #e4701e;
			-moz-box-shadow: 0 0 10px rgba(228,112,30,.5);
			-webkit-box-shadow: 0 0 10px rgba(228,112,30,.5);
			-o-box-shadow: 0 0 10px rgba(228,112,30,.5);
			box-shadow: 0 0 10px rgba(228,112,30,.5);
			-webkit-transform: perspective(1px) scale(1.08);
			-ms-transform: perspective(1px) scale(1.08);
			transform: perspective(1px) scale(1.08);			
		}
		
        &:before {
            display: inline-block;
            position: relative;
            left: 2px;
        }

        &.disabled {
            background-color: transparent;
            color: $text-muted;
            border: 1px solid $color-border-dark;
        }
    }

    .icon-arrow-bracket-left {
        margin: 0 10px 0 0;
		width: 50px;
		height: 48px;
		font-size: 38px;
		line-height: 38px;
        &:before {
            content: '\e637';
			font-size: 38px;
			line-height: 48px;
			padding-right: 3px;
        }
    }

    .icon-arrow-bracket-right {
        margin: 0 0 0 10px;
		width: 50px;
		height: 48px;
		font-size: 38px;
		line-height: 38px;		
        &:before {
            content: '\e63a';
			font-size: 38px;
			line-height: 48px;
			padding-left: 3px;			
        }
    }
}

.roc-pageOptions-count {
	margin: 0 25px 0 0;
}

/* hide spinner buttons firefox */
/*input[type='number'] {
    padding: 5px 10px;
    -moz-appearance:textfield;
}*/

/* hide spinner buttons webkit */
/*input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}*/