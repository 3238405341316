﻿.find-location-wrapper {
    @include containers;
    margin-bottom: 80px;
}

.location-mobile-view {
    display: none;
}

.find-location-container {
    @include flex100;
}

.location-search-results-container {
    width: 30%;
    display: inline-block;
    @include border-dark-default;
}

.location-search-container {
    background-color: $gray;
    padding: 15px;
    @include border-btm-dark-default;

    &.formee {
        label {
            color: #2b2b2b;
            font-weight: 400;
            margin: 0;
        }

        input, select {
            margin: 0;
        }
    }
}

.location-search-field {
    margin: 0 0 15px 0;
}

.location-search-btn {
    .btn { width: 100%; }
}

#branch-locator-list-wrapper {
	height: 200px;
	overflow-y: scroll;



	@include sm {
		height: 634px;
	}
	@include md {
		height: 404px;
	}
	@include lg {
		height: 584px;
	}
}

.dealer-results-wrapper {
    background-color: $light-gray;
}

.dealer-result-container {
    padding: 15px;
    @include border-btm-dark-default;

    &:last-of-type { border: none; }
}

.dealer-result-num {
    display: none;
}

.dealer-result-distance {
    .icon {
        width: 18px;
        height: 18px;
        fill: $color-primary;
        margin: 0 5px 0 0;
    }
}

.dealer-result-name {
    @include sm-callout-title-font;
    margin: 0 0 5px 0;
}

.dealer-result-phone {
    margin: 0 0 10px 0;
}

.dealer-result-more-info {
    margin: 0 0 5px 0;

    a {
        .arrow {
            @include right-arrow-sm;
            display: inline-block;
            margin: 0 0 0 5px;
        }
    }
}

.dealer-result-view-map {
    a {
        cursor: pointer;
        
        .arrow {
            @include right-arrow-sm;
            display: inline-block;
            margin: 0 0 0 5px;
        }
    }
}

.location-map-container {
    width: 70%;
    display: inline-block;
}

.map-canvas-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    float: left;
}

.mapCanvas {
    height: 100%;
    position: relative;
    overflow: hidden;
}



/*************************************/
/*   MAP CANVAS - LOCATION POP-UP   */
/***********************************/

.location-results-popup-arrows-wrapper {
    position: absolute;
    top: 45%;
}

.location-results-popup-wrapper {
    .mfp-close {
        color: #888;
        top: 10px;
        right: 10px;

        &:hover,
        &:focus {
            color: #777;
        }
    }
}

// .location-results-popup-arrow-outline {
//     width: 0;
//     height: 0;
//     border: 0 solid transparent;
//     border-top-width: 15px;
//     border-bottom-width: 15px;
//     border-right: 15px solid #ccc;
// }

// .location-results-popup-arrow-fill {
//     width: 0;
//     height: 0;
//     border: 0 solid transparent;
//     border-top-width: 14px;
//     border-bottom-width: 14px;
//     border-right: 15px solid #fff;
//     position: absolute;
//     top: 1px;
//     left: 1px;
// }

.location-results-popup-wrapper {
    margin: 14px;
    background-color: transparent;
    font-size: 16px;
    line-height: 1.5em;
}

.location-results-popup-container {
    border: 1px solid #ccc;
    background-color: #fff;
    max-width: 400px;

    a {
        font-weight: 500;
    }
}

.location-results-info-container {
    padding: 15px;
}

.location-results-distance {
    margin: 0 0 10px 0;

    .icon {
        width: 18px;
        height: 18px;
        fill: $color-primary;
        margin: 0 5px 0 0;
    }
}

.location-results-phone {
    margin: 0 0 10px 0;
}

.location-results-name {
    @include sm-callout-title-font;
}

.location-results-more-info,
.location-results-directions {
    a {
        .arrow {
            @include right-arrow-sm;
            display: inline-block;
            margin: 0 0 0 5px;
        }
    }
}

.location-results-more-info-container {
    width: 320px;
    padding: 0 15px 15px 15px;
}

.location-results-hours-container {
    margin: 0 0 15px 0;
}

.location-results-hours-title,
.location-results-contact-title {
    font-weight: 600;
}

.location-results-hours-day,
.location-results-contact-type {
    width: 170px;
    display: inline-block;
    font-size: .85em;
    font-weight: 500;
}

.location-results-hours-time,
.location-results-contact-link {
    width: 115px;
    display: inline-block;
    font-size: .85em;
}

.location-results-services-container {
    background-color: $color-primary;
    color: #fff;
    font-weight: 600;
    padding: 15px;
}

.location-results-service-name {
    margin: 0 0 10px 0;

    &:last-of-type {
        margin: 0;
    }
}

// Overwriting Google Maps styles
.mapCanvas {

    .gm-style {

        .gm-ui-hover-effect {
            opacity: 0;
        }

        .gm-style-iw-a,
        .gm-style-iw-t {
            left: auto !important;
            top: auto !important;
        }

        .gm-style-iw {
            max-width: 100%;
            max-height: initial;
            background-color: transparent;
            top: 70px;
            left: 2.4%;
            padding: 0;
            border: none;
            box-shadow: none;
            z-index: 2;
        }

        .gm-style-iw-d {
            max-width: 100%;
            max-height: 100%;
            overflow: hidden !important;
        }

        .gm-style-iw-t::after {
            display: none;
        }
    }

    @media only screen and (max-width: 1290px) {
        .gm-style {
            .gm-style-iw {
                left: 2%;
                top: 120px;
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        .gm-style {
            .gm-style-iw {
                top: 200px;
            }
        }
    }

    @media only screen and (max-width: 1039px) {
        .gm-style {
            .gm-style-iw {
                left: 1.5%;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .gm-style {
            .gm-style-iw {
                top: 90px;
            }
        }
    }

    @media only screen and (max-width: 913px) {
        .gm-style {
            .gm-style-iw {
                left: 1%;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .gm-style {
            .gm-style-iw {
                left: 0%;
                top: 110px;
                max-width: 250px !important;
            }

            .location-results-more-info-container {
                display: none;
            }

            .gm-style-iw-d {
                max-width: 250px !important;
            }
        }
    }
}