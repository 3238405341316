﻿.row {
	>.col-sm-12 {
		>.container-fluid {
			&.no-padding {
				>.member-sub-navigation-wrap {
					margin: 0 -10px !important;
				}	
			}
		}
	}
}

.member-sub-navigation-wrap {
	position: relative;
	display: block;
	background-color: $color-primary;

	.table-sub-navigation-member-row {
		position: relative;
		display: table;
		width: 100%;
		max-width: 1340px;
		min-height: 90px;		
		margin: 0 auto;
		padding-left: 10px;
		padding-right: 10px;	
        	
		.page-title-column {
			display: table-cell;
			vertical-align: middle;
			width: 30%;

			.pageHeading {
				margin: 0 !important;

				&:not(.pageHeadingAlt) {
					padding-bottom: 0 !important;
					margin-bottom: 0 !important;
					border-bottom: none !important;					
				}

				h1 {
					&.title {
						position: relative;
						display: block;
						margin: 0;
						padding: 0;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 40px;
						font-weight: 600;
						line-height: 45px;						
						background: none;
						border: 0;
						color: $color-text-secondary;
						text-transform: none;					
					}
				}
			}
		}

		.sub-navigation-member {
			display: table-cell;
			vertical-align: top;
			width: 70%;

			.railNavWrapper {
				display: none;
			}
		}		
	}
}