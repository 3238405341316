@import "hawksearch-icons";


#hawkbreadcrumb {
	display: block;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;	
	margin-bottom: 10px;
	.breadcrumbs {
		a,
		span {
			display: inline-block;
		}
		.filterCrumbs {
			.facet-remove {
				margin-right: 5px;
				padding-left: 16px;
				.icon-cancel-circle {
					position: absolute;
					top: -3px;
					left: 0;
				}
			}
			.clearLink {
				margin-left: 7px;
			}
		}
	}
}

/* ## Contain floats: h5bp.com/q ## */
.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.hawk-visuallyHidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.hawk-visuallyHidden.focusable:active, .hawk-visuallyHidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }


#hawktoppager,
#hawkbottompager {
    // stops users from being able to select or highlight container
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
    
    // stops users from being able to select or highlight container
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
}

#hawktoppager:active,
#hawkbottompager:active {
    // helps to prevent select/highlight on IE touch
    -ms-touch-action: none;
    background: none;
}


/* ## directly embed icons on :before and :after psuedo classes ## */
[class^="hawk-iconBefore"]:before,
[class^="hawk-iconAfter"]:after {
    font-family: 'hawksearch';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* ## icon image sprites ## */

.iconImage {
    display: inline-block;
    width: 20px;
    height: 18px;
    margin-right: 5px;
    text-align: left;
    vertical-align: middle;
    overflow: hidden;
    background: url(/sites/shared/assets/images/icons_png.png) no-repeat 0 0;
}

.iconCheckbox {
    width: 18px;
    height: 14px;
    background-position: -23px -112px;
    position: relative;
}

.iconXSquareRed {
    width: 12px;
    height: 12px;
    background-position: 0 -112px;
}

.iconSearch {
    width: 14px;
    height: 14px;
    background-position: -55px 0;
}


/* ## search view options ## */

.hawk-searchView {
    margin-bottom: 10px;
    padding: 0;
    border-bottom: 5px solid #f0f0f0;
}

.hawk-searchView .hawk-viewOption {
    position: relative;
    display: inline-block;
    margin-bottom: -5px;
    border-bottom: 5px solid #f37622;
}

.hawk-searchView .hawk-viewOption:after {
    content: " ";
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #f37622;
}

.hawk-searchView .hawk-viewOption .hawk-viewOptionInner,
.hawk-searchView .hawk-viewOption .hawk-viewOptionInner:visited {
    display: inline-block;
    padding: 5px 15px;
    color: inherit;
    font-size: 0.938em;
    font-weight: bold;
    text-decoration: none;
}

.hawk-searchView .hawk-viewOptionOff {
    border-color: #f0f0f0;
}

.hawk-searchView .hawk-viewOptionOff:after {
    display: none;
}

.hawk-searchView .hawk-viewOptionOff .hawk-viewOptionInner {
    color: #999 !important;
}


/* ## layout control box ## */

.hawk-listingControls {
    position: relative;
    margin-bottom: 12px;
    padding: 1px;
    background: #fff;
    border: 1px solid #ccc;
}

.hawk-listingControls .hawk-mainControls {
    padding: 7px 10px;
    background: #5f3b17;
}

.hawk-listingControls .hawk-subControls {
    padding: 8px 10px;
    background: #f0f0f0;
}

.hawk-sortWrapper {
    display: inline;
    float: left;
    margin: 0 0 5px;
}

.hawk-sortWrapper .sortList {
    display: inline;
    float: left;
    padding: 3px 10px 0;
}

.hawk-sortWrapper .sortList label {
    display: inline;
    margin: 0 5px 0 0;
    font-size: 0.8em;
    font-weight: bold;
    color: #fff;
}

.hawk-sortWrapper .sortList select {
    margin-right: 10px;
}

.hawk-listingControls .hawk-pagination {
    float:right;
    width: auto;
    margin: 3px 0 0;
}

.hawk-listingControls .hawk-pagination .hawk-viewNumber {
    float:right;
    margin-left:10px;
}

.hawk-listingControls .hawk-pagination .hawk-paging {
    float:left;
    border: 0;
}

.hawk-listingControls .hawk-pagination .hawk-paging a.hawk-pageLink,
.hawk-listingControls .hawk-pagination .hawk-paging a.hawk-pageLink:link,
.hawk-listingControls .hawk-pagination .hawk-paging a.hawk-pageLink:visited {
    display:inline-block;
    min-width:15px;
    padding:0 4px;
    text-decoration:none;
    text-align:center;
    background-color: #fff;
}

.hawk-listingControls .hawk-pagination .hawk-paging .hawk-pageActive {
    display:inline-block;
    min-width:15px;
    padding:0 4px;
    text-decoration:none;
    text-align:center;
    background-color: #f37622;
}

.hawk-listingControls .hawk-pagination .hawk-paging .hawk-arrowLeft,
.hawk-listingControls .hawk-pagination .hawk-paging .hawk-arrowRight {
    display:inline-block;
    min-width:15px;
    padding:0 4px;
    color:#fff;
    text-decoration:none;
}

/* embedding icomoon for previous and next arrows */
.hawk-listingControls .hawk-pagination .hawk-paging .hawk-arrowLeft:before,
.hawk-listingControls .hawk-pagination .hawk-paging .hawk-arrowRight:after {
    font-family: 'hawksearch';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align:middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hawk-listingControls .hawk-pagination .hawk-paging .hawk-arrowLeft:before {
    content: "\e603";
}

.hawk-listingControls .hawk-pagination .hawk-paging .hawk-arrowRight:after {
    content: "\e606";
}

.hawk-listingControls .hawk-pagination .hawk-paging .total {
    color: #fff;
}

.hawk-compareList {
    float: left;
    display: inline;
}

.hawk-compareList .hawk-compareHeading {
    float: left;
    color: #333;
    font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
}

.hawk-compareList .hawk-compareHeading span {
    display: block;
    font-size: 11px;
	font-weight: 400;
    color: #333;
	text-transform: capitalize;
}

.hawk-compareList ul {
    float: left;
    display: inline;
    margin: 0 20px 10px 0 !important;
    padding: 0;
    list-style-type: none;
}

.hawk-compareList li {
    position: relative;
    float: left;
    display: inline;
    width: 40px;
    height: 40px;
    margin-left: 14px;
    background: #fff;
    border: 1px solid #c5c3c4;
	@media (max-width: 767px) {
		margin-left: 10px;
	}		
}

.hawk-compareList li img {
    position: absolute;
    left: 2px;
	top: 1px;
    right: 2px;
    width: 35px !important;
    height: auto;
}
    

.hawk-compareList li a:after {
    @extend %iconFont;
    @extend .icon-cancel-circle:before;
    
    position: absolute;
    right: -8px;
    top: -8px;
    color: #004e8e;
    font-size: 19px;
    line-height: 1;
    z-index: 5;
    cursor: pointer;
}

.hawk-compareList .btnWrapper {
    float: left;
    margin: 0;
	.btn {
		padding: 10px 15px 10px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 1px;
		color: #bdd7ef;
	}
}

.clear {
    overflow: hidden;
    clear: both;
    height: 0;
    margin: 0;
    font-size: 1px;
    line-height: 0;
}



/* ## Layout Control Styles ## */

.hawk-gridView, .hawk-listView {
    height: 16px;
    background: url(/sites/shared/assets/images/icon_grid_list.gif) no-repeat 0 0;
    padding-left: 24px;
}

.hawk-listView {
    background-position: 0 -44px;
}


/* ## Results Styles ## */

.results {
    border-width: 1px;
    border-spacing: 4px;
    border-style: hidden;
}

.results td {
    vertical-align: top;
    border-width: 1px;
    padding: 10px 5px 10px 5px;
    border-style: none;
    border-color: gray;
    -moz-border-radius: 0px 0px 0px 0px;
}


/* ## ajax popup overlay ## */

.apOvrly {
    display: none;
    position: absolute;
    width: 708px;
    height: 400px;
    min-height: 1px;
    z-index: 1000;
}

.apOvrly .apOvrlyTop,
.apOvrly .apOvrlyBottom {
    height: 5px;
    background: url(/sites/shared/assets/images/core/bg_overlay_ends.png) no-repeat 0 0;
    overflow: hidden;
}

.apOvrly .apOvrlyBottom {
    background-position: 0 -5px;
}

.apOvrly .apOvrlyInner {
    height: 1%;
    min-height: 400px;
    background: #fff url(/sites/shared/assets/images/core/bg_overlay.gif) no-repeat 0 0;
    padding: 20px 0 15px;
}

.apOvrlyBar {
    position: relative;
}

.apOvrly .ovrlyClse {
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    top: -17px;
    right: 7px;
    background: url(/sites/shared/assets/images/core/overlay_close.gif) no-repeat 0 0;
    cursor: pointer;
}

.apOvrlyContent {
    margin: 0 25px;
    overflow: auto;
    width: 680px;
    height: 400px;
}


/* ## more like this overlay ## */

.mltOvrly {
    display: none;
    position: absolute;
    width: 508px;
    height: 400px;
    min-height: 50px;
    text-align: left;
    background-color: #fff;
    border: 2px solid #143f6a;
    padding-bottom: 10px;
    z-index: 1000;
}

.mltOvrly .mltOvrlyTop,
.mltOvrly .mltOvrlyBottom {
    height: 0;
    overflow: hidden;
}

.mltOvrly .mltOvrlyBottom {
    background-position: 0 -5px;
}

.mltOvrly .mltOvrlyInner {
    min-height: 400px;
    padding: 0 0 15px;
}

.mltOvrly .ovrlyClse {
    position: absolute;
    right: 5px;
    top: 6px;
    padding: 0 5px;
    font-size: 12px;
    color: #BE8E03;
    cursor: pointer;
}

.mltOvrlyBar {
    position: relative;
    margin: 0 0 7px 0;
    padding: 4px 15px;
    font-size: 16px;
    color: #283380;
    background-color: #CEE1F1;
}

.mltOvrlyContent {
    margin: 0 25px;
    overflow: auto;
    width: 480px;
    height: 365px;
}


/* ## Grid View ## */

.MultilineTable {
}

.MultilineTable th {
    background: url(/assets/images/table_header.gif) repeat-x;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    height: 24px;
    text-align: left;
    vertical-align: middle;
    padding: 1px 4px 1px 4px;
}

.MultilineTable th a {
    color: #fff;
    text-decoration: none;
}

.MultilineTable th a:link {
    color: #fff;
    text-decoration: none;
}

.MultilineTable th a:visited {
    color: #fff;
    text-decoration: none;
}

.MultilineTable th a:hover {
    color: #fff;
    text-decoration: underline;
}

.MultilineTable th a:active {
    color: #fff;
    text-decoration: underline;
}

.MultilineTable td {
    vertical-align: middle;
}

.MultilineTable td.ActionButtons {
    width: 75px;
}

.MultilineTable td.ActionButtons a {
    padding: 1px;
}

.alternate {
    background: #f1f1f1;
    color: #000;
    font-size: 12px;
}

.alternate a {
    color: #069;
    text-decoration: none;
}

.alternate a:link {
    color: #069;
    text-decoration: none;
}

.alternate a:visited {
    color: #069;
    text-decoration: none;
}

.alternate a:hover {
    color: #069;
    text-decoration: underline;
}

.alternate a:active {
    color: #069;
    text-decoration: underline;
}

.MultilineTable .row,
.MultilineTable .field {
    text-align: center;
    color: #000;
    font-size: 12px;
}

.accountBlocks {
    position: relative;
    min-height: 1px;
    margin: 25px 10px 5px 0;
    border: 1px solid #444;
}

.accountBlocks .inner {
    padding: 25px 15px 10px;
    text-align: left;
    font-size: 11px;
}

.accountBlocks .blockhdng {
    position: absolute;
    font-size: 1.2em;
    top: -14px;
    left: 10px;
    padding: 3px 5px;
    margin: 0;
    background-color: #fff;
    border: 1px;
    background-position: 50% 50%;
}

.bannerTop {
    text-align: center;
}

.bannerLeftTop {
    text-align: center;
}

.bannerLeftBottom {
    text-align: center;
}


/* ## TagCloud ## */

/* Applies to the entire tag cloud */
.TagCloud {
    font-family: Arial;
    margin: 10px 3px 3px;
    padding: 3px;
    text-align: center;
}
/* Applies to each tag of the tag cloud */
.TagCloud > span {
    margin-right: 3px;
    text-align: center;
}
/* Applies to the largest to smallest tags */
.TagCloud > span.TagWeight1 {
    font-size: 24px;
}

.TagCloud > span.TagWeight2 {
    font-size: 21px;
    font-weight: bold;
}

.TagCloud > span.TagWeight3 {
    font-size: 17px;
}

.TagCloud > span.TagWeight4 {
    font-size: 15px;
    font-weight: bold;
}

.TagCloud > span.TagWeight5 {
    font-size: 12px;
}



/* ## results List Display ## */

.hawk-brokenImage {
    min-width:10px;
    min-height:10px;
}



/* ## hawk guided nav ## */

.hawk-railNavHeading {
    display: none;
    margin: 0;
    padding: 10px;
    font-size: 1.2em;
    position:relative;
    margin: 0 0 -1px;
    padding: 10px 20px;
    background: #002f65;
    border: 1px solid #002f65;
	color: #fff;
}

.hawk-railNavHeading:after {
    display: none;
}

.hawk-railNavHeadingActive:after {
    display: none;
    @extend .icon-arrow-bracket-up:before;
}

.hawk-railNavHeading span {
    padding: 0 0 0 15px;
}

.hawk-railNavHeading a,
.hawk-railNavHeading a:link,
.hawk-railNavHeading a:visited {
    color: #fff;
    text-decoration: none;
}

.hawk-railNavHeading a:hover,
.hawk-railNavHeading a:active {
    color: #fff;
    text-decoration: none;
}

.hawkRailNav {
    background-color: #fff;
    border: 0px solid #c6c6c6;
    overflow: hidden;
    margin: 0 0 15px 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.hawk-guidedNavWrapper {
	/*border-top: 1px solid #c6c6c6;*/
	font-size: 0.875em;
}

.hawk-guidedNavWrapper .hawk-navGroupHdng {
    margin: 10px 6px 0 6px;
    font-size: 1em;
    font-weight: bold;
}

.hawk-guidedNavWrapper .hawk-navGroupContent {
    padding: 18px 24px;
	border-left: 1px solid #bdbdbd;
	border-right: 1px solid #bdbdbd;
	border-bottom: 1px solid #bdbdbd;
	@media (min-width:768px) and (max-width:979px) {
		padding: 10px 15px;
	}
	@media (max-width:767px) {
		padding: 18px 15px;
	}	
}

.hawk-guidedNavWrapper .hawk-collapsed .hawk-navGroupContent {
    position: absolute;
    left: -9999em;
}

.hawk-guidedNavWrapper .hawk-navGroup:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}
//
//.hawk-guidedNavWrapper .hawk-navGroup:last-child .hawk-navGroupContent {
//    border-bottom: 0;
//}



/* ## facets group heading ## */

.hawk-guidedNavWrapper > .hawk-navGroup {
	margin-bottom: 10px;	
}

.hawk-guidedNavWrapper .hawk-groupHeading {
    position:relative;
    margin: 0 0 -1px;
	font-size: 24px;
	line-height: 28px;
    padding: 15px 30px 15px 24px;
    background: #dfdfdf;
	color: #333;
	@media (max-width:979px) {
		padding: 10px 15px;
	}
}


.hawk-navGroup.hawk-selectedNav .hawk-groupHeading {
	background: none;
}

.hawk-guidedNavWrapper .hawk-navGroup:first-child .hawk-groupHeading {
	border-top: none;
}

/* embedding icomoon for groupheading usage */
//.hawk-guidedNavWrapper .hawk-groupHeading:after {
//    position: absolute;
//    display:none;
//	top: 10px;
//	right: 10px;
//	font-size: 28px;
//    font-family: 'ROC-Icons';
//    color: #444444;
//    content: "\e63a";
//	speak: none;
//	font-style: normal;
//	font-weight: normal;
//	font-variant: normal;
//	text-transform: none;
//	line-height: 1.42857;
//	-webkit-font-smoothing: antialiased;
//	-moz-osx-font-smoothing: grayscale;
//	font-weight: 400;
//	text-shadow: none;
//	@media (max-width:1279px) {
//		top: 5px;
//		font-size: 23px;
//	}		
//}

//							
//.hawk-guidedNavWrapper [data-field].hawk-collapsible > .hawk-groupHeading:before {
//	width: 0;
//}
//.hawk-guidedNavWrapper [data-field].hawk-collapsible > .hawk-groupHeading:after {
//	border-bottom: 2px solid #f0f0f0;
//}

//.hawk-guidedNavWrapper [data-field].hawk-collapsible.hawk-collapsed > .hawk-groupHeading:before {
//	content: '';
//	display: inline-block;
//	position: absolute;
//	top: 26px;
//	right: 14px;
//	margin: 0;
//	width: 13px;
//	height: 0px;
//	border-bottom: 2px solid #2b2b2b;
//	transform: rotate(90deg);
//	transition: width 0.3s;
//}	
//.hawk-guidedNavWrapper [data-field].hawk-collapsible.hawk-collapsed > .hawk-groupHeading:after {
//	content: '';
//	display: inline-block;
//	position: absolute;
//	top: 26px;
//	right: 14px;
//	margin: 0;
//	width: 13px;
//	height: 0px;
//	border-bottom: 2px solid #2b2b2b;
//	transform: rotate(0deg);
//	transition: width 0.3s;
//}
	
.hawk-guidedNavWrapper {
	[data-field] {
		&.hawk-collapsed {
			>.hawk-groupHeading {
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 26px;
					right: 14px;
					margin: 0;
					width: 14px;
					height: 0px;
					border-bottom: 3px solid #2b2b2b;
					transform: rotate(90deg);
					transition: width 0.3s;
				}
				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					top: 26px;
					right: 14px;
					margin: 0;
					width: 14px;
					height: 0px;
					border-bottom: 3px solid #2b2b2b;
					transform: rotate(0deg);
					transition: width 0.3s;
				}				
			}
		}
		&:not(.hawk-collapsed) {
			>.hawk-groupHeading {
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 26px;
					right: 14px;
					margin: 0;
					height: 0px;
					border-bottom: 3px solid #2b2b2b;
					transform: rotate(90deg);					
					width: 0;
				}
				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					top: 26px;
					right: 14px;
					margin: 0;
					width: 14px;
					height: 0px;
					border-bottom: 3px solid #2b2b2b;
					transform: rotate(0deg);
					transition: width 0.3s;
				}
			}
		}
	}
}

/* setting font styling to groupheading and possible nested links */
.hawk-guidedNavWrapper .hawk-groupHeading,
.hawk-guidedNavWrapper .hawk-groupHeading a,
.hawk-guidedNavWrapper .hawk-groupHeading a:link,
.hawk-guidedNavWrapper .hawk-groupHeading a:visited {
	font-family: "IBM Plex Sans",sans-serif !important;
	font-size: 24px;
	font-weight: 500;
	text-transform: none;
	color: #333;
//	@media (max-width:1279px) {
//		font-size: 15px;
//	}	
}

.hawk-guidedNavWrapper .hawk-collapsible .hawk-groupHeading {
    cursor:pointer;
}

//.hawk-guidedNavWrapper .hawk-collapsible .hawk-groupHeading:after {
//    display:block;
//    content: "\2212";
//}
//
//.hawk-guidedNavWrapper .hawk-collapsed .hawk-groupHeading:after {
//    content: "\2b";
//}

.hawk-guidedNavWrapper .hawk-navGroup input[type="text"] {
    border:1px solid #d0d0d0;
}



/* ## facet list ## */

.hawk-guidedNavWrapper .hawk-navGroup.hawk-selectedNav ul {
	margin: 5px 0 0;
	padding: 8px 10px;
    list-style-type: none;
    background: #dfdfdf;
}

.hawk-guidedNavWrapper .hawk-navGroup ul {
    margin: 0;
    padding: 0 0 20px;
    list-style-type: none;
    background: transparent;
	@media (max-width:979px) {
		word-break: break-word;
	}		
	ul {
		padding-bottom: 0;
	}
}

.hawk-guidedNavWrapper .hawk-navGroup ul.hawk-scrollList {
    max-height: 167px;
    overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: 0;
}

.hawk-guidedNavWrapper .hawk-navGroup li {
    position: relative;
    margin-top: 5px;

    li {
        margin-left: 20px;
    }
}

.hawk-guidedNavWrapper .hawk-navGroup li a,
.hawk-guidedNavWrapper .hawk-navGroup li a:visited {
    display:inline-block;
    font-family: "IBM Plex Sans",sans-serif!important;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #333;
    text-decoration: none;
	@media (min-width:768px) and (max-width:1279px) {
		font-size: 14px;
	}		
}

.hawk-guidedNavWrapper .hawk-navGroup li .hawk-selectionInner .hawk-facetCount {
	font-family: 'Lato', sans-serif;
    color: #e4701e;
	font-size: 16px;
	@media (min-width:768px) and (max-width:1279px) {
		font-size: 14px;
	}		
}

.hawk-guidedNavWrapper .hawk-navGroup li.hawk-navMore {
    color: #000;
}

.hawk-guidedNavWrapper .hawk-navGroup li.hawk-navMore span {
    display: inline-block;
    cursor: pointer;
}

//.hawk-collapseState {
//    display: block;
//    position:absolute;
//    color: #373737;
//    right: 7px;
//    top: -6px;
//    cursor:pointer;	
//	width: 20px;
//	height: 20px;
//}
//.hawk-collapseState:before {
//    display:block;
//	position:absolute;
//	top: 0;
//    content:"\2b";
//    font-size: 25px;
//    font-family:'ROC-Icons';
//    color: #373737;
//    cursor:pointer;
//}

//.hawk-collapsed.hawk-collapseState:before {
//    content:"\2212";
//}

.hawkRailNav .collapse {
	display: block !important;
}

.hawk-collapseState {
    cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	width: 14px;
	height: 19px;
	display: block;
}

.hawk-collapseState:before,
.collapsed.hawk-collapseState:before {
	content: '';
	display: inline-block;
	position: absolute;
	top: 9px;
	right: 0;
	margin: 0;
	width: 12px;
	height: 0px;
	border-bottom: 2px solid #2b2b2b;
	transform: rotate(90deg);
	transition: width 0.3s;
}

.hawk-collapseState:after,
.collapsed.hawk-collapseState:after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 9px;
	right: 0;
	margin: 0;
	width: 12px;
	height: 0px;
	border-bottom: 2px solid #2b2b2b;
	transform: rotate(0deg);
	transition: width 0.3s;
}

.hawk-collapseState[aria-expanded="true"]:before {
	content: '';
	display: inline-block;
	position: absolute;
	top: 9px;
	right: 0;
	margin: 0;
	height: 0px;
	border-bottom: 2px solid #2b2b2b;
	transform: rotate(90deg);					
	width: 0;	
}
.hawk-collapseState[aria-expanded="false"]+ul {
	overflow: hidden;
	height: 0 !important;
}

.hawk-collapseState[aria-expanded="true"]+ul {
	height: auto !important;	
}

//.hawk-navGroup {
//	.hawk-navGroupContent {
//		.hawk-facetFilters {
//			.hawkFacet-parent {
//				>.hawk-collapseState {
//					position: absolute;
//					top: 0;
//					right: 0;
//					width: 14px;
//					height: 19px;
//					&:before {
//						content: '';
//						display: inline-block;
//						position: absolute;
//						top: 9px;
//						right: 0;
//						margin: 0;
//						width: 12px;
//						height: 0px;
//						border-bottom: 2px solid #2b2b2b;
//						transform: rotate(90deg);
//						transition: width 0.3s;
//					}
//					&:after {
//						content: '';
//						display: inline-block;
//						position: absolute;
//						top: 9px;
//						right: 0;
//						margin: 0;
//						width: 12px;
//						height: 0px;
//						border-bottom: 2px solid #2b2b2b;
//						transform: rotate(0deg);
//						transition: width 0.3s;
//					}					
//					&.hawk-collapsed,&.collapsed.hawk-collapsed {
//						&:before {
//							content: '';
//							display: inline-block;
//							position: absolute;
//							top: 9px;
//							right: 0;
//							margin: 0;
//							width: 12px;
//							height: 0px;
//							border-bottom: 2px solid #2b2b2b;
//							transform: rotate(90deg);
//							transition: width 0.3s;
//						}
//						&:after {
//							content: '';
//							display: inline-block;
//							position: absolute;
//							top: 9px;
//							right: 0;
//							margin: 0;
//							width: 12px;
//							height: 0px;
//							border-bottom: 2px solid #2b2b2b;
//							transform: rotate(0deg);
//							transition: width 0.3s;
//						}				
//					}
////					&:not(.collapsed) {
////						&:before {
////							content: '';
////							display: inline-block;
////							position: absolute;
////							top: 26px;
////							right: 14px;
////							margin: 0;
////							height: 0px;
////							border-bottom: 3px solid #2b2b2b;
////							transform: rotate(90deg);					
////							width: 0;
////						}
////						&:after {
////							content: '';
////							display: inline-block;
////							position: absolute;
////							top: 26px;
////							right: 14px;
////							margin: 0;
////							width: 14px;
////							height: 0px;
////							border-bottom: 3px solid #2b2b2b;
////							transform: rotate(0deg);
////							transition: width 0.3s;
////						}
////					}
//					&.collapsed {
//						&:before {
//							content: '';
//							display: inline-block;
//							position: absolute;
//							top: 9px;
//							right: 0;
//							margin: 0;
//							height: 0px;
//							border-bottom: 2px solid #2b2b2b;
//							transform: rotate(90deg);					
//							width: 0;
//						}
//						&:after {
//							content: '';
//							display: inline-block;
//							position: absolute;
//							top: 9px;
//							right: 0px;
//							margin: 0;
//							width: 12px;
//							height: 0px;
//							border-bottom: 2px solid #2b2b2b;
//							transform: rotate(0deg);
//							transition: width 0.3s;
//						}
//					}					
//				}								
//			}
//		}
//	}
//}


.hawk-nestedfacet ul.hawk-facetgroup { border:0px }

.hawk-accordionTitle { cursor:pointer;}

.hawkfacet-nestedlinklist .hawkFacet-active > a > .hawk-selectionInner,
.hawkfacet-nestedlinklist .hawkFacet-isInPath > a > .hawk-selectionInner{
    font-weight:bold;
}

//.hawk-collapseState + .hawk-facetgroup.collapse {
//    display:none;
//}
//
//.hawk-collapseState + .hawk-facetgroup.collapse.in {
//    display:block;
//}
//
//.hawk-facetgroup.collapse + .hawk-facetgroup.collapse {
//    display:none;
//}



/* ## current selections ## */

.hawk-guidedNavWrapper .hawk-selectedNav {
    border-bottom: none;
	background: #efefef;
	padding: 8px 15px;
	margin-bottom: 10px;
	@media (max-width:1279px) {
		padding: 5px 10px;
	}
	.hawk-navGroupContent {
		padding: 0 5px !important;
		border: none !important;	
	}
	.hawk-groupHeading {
		padding: 5px;
		&:after {
			top: -2px;
		}
	}
}

.hawk-guidedNavWrapper .hawk-selectedGroup {
    margin: 5px 0;
}

.hawk-guidedNavWrapper .hawk-selectedGroup .hawkIcon-close {
    font-size:12px;
    color:#004e8e;
	top: 0 !important;
}


.hawk-guidedNavWrapper .hawk-selectedHeading {
    position: relative;
    margin: 0;
    padding: 0 0 0 0px;
}

.hawk-guidedNavWrapper .hawk-selectedHeading,
.hawk-guidedNavWrapper .hawk-selectedHeading a,
.hawk-guidedNavWrapper .hawk-selectedHeading a:link,
.hawk-guidedNavWrapper .hawk-selectedHeading a:visited {
	font-family: "IBM Plex Sans",sans-serif !important;		
    font-size: 17px;
    font-weight: 700;
	line-height: 20px;
    text-decoration: none;
	color: #333;
	padding-left: 13px;
	@media (max-width:1279px) {
		padding-left: 12px;	
		font-size: 14px;
	}			
}
.hawk-guidedNavWrapper .hawk-navGroup li.hawkFacet-hover {
	a {
		color: #004e8e !important;
	}
}

.hawk-guidedNavWrapper .hawk-selectedGroup ul {
    max-height: none;
    margin: 2px 0px 0px 0px;
    overflow: visible;
}

.hawk-guidedNavWrapper .hawk-selectedGroup ul li {
	margin: 2px 0px 2px 0px;
}

.hawk-guidedNavWrapper .hawk-selectedGroup ul a,
.hawk-guidedNavWrapper .hawk-selectedGroup ul a:link,
.hawk-guidedNavWrapper .hawk-selectedGroup ul a:visited {
    font-family: "IBM Plex Sans",sans-serif !important;	
    font-size: 15px;
	font-weight: 600;
	line-height: 20px;
	color: #333;
	padding-left: 27px;	
	@media (max-width:1279px) {
		padding-left: 23px;	
		font-size: 14px;
	}		
}

.hawk-guidedNavWrapper .hawk-clearSelected {
    padding: 7px 0 5px;
    text-align: right;
	@media (max-width:1279px) {
		padding-bottom: 5px;	
	}		
}

.hawk-guidedNavWrapper .hawk-clearSelected a,
.hawk-guidedNavWrapper .hawk-clearSelected a:link,
.hawk-guidedNavWrapper .hawk-clearSelected a:visited {
	font-family: "IBM Plex Sans",sans-serif !important;	
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
	color: rgb(0, 78, 142);
	text-transform: none;
	display: inline;
	box-decoration-break: clone;
	transition: all 0.5s ease-in-out;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(0, 78, 142, 0.3), rgba(0, 78, 142, 0.3));
	background-size: 0 1px;
	background-position: 0 100%;
	background-repeat: no-repeat;
}

.hawk-guidedNavWrapper .hawk-clearSelected a:hover,
.hawk-guidedNavWrapper .hawk-clearSelected a:active {
    text-decoration: none;
	background-size: 100% 1px;	
}



/* ## search within ## */

.hawk-guidedNavWrapper .hawk-navGroup .hawk-resultsSearch {
	display: block;
	background: #efefef;
	padding: 15px 25px;
	margin: 15px 0;
	@media (max-width:1279px) {
		padding: 10px 10px;
	}	
}

.hawk-guidedNavWrapper .hawk-navGroup .hawk-resultsSearch .hawk-searchWithin {
    position: relative;
    margin: 5px 0;
}


.hawk-guidedNavWrapper .hawk-navGroup .hawk-resultsSearch label {
    display: block;
    margin-bottom: 0;
    color: #444;
    font-size: 16px;
	font-weight: 600;
	@media (max-width:1279px) {
		 font-size: 14px;
	}	
}


.hawk-guidedNavWrapper .hawk-navGroup .hawk-resultsSearch input[type="text"] {
    width: 100%;
	height: 33px;
    padding: 4px 8px;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
	border-radius: 5px;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawk-resultsSearch .hawk-searchWithinButton {
    position:absolute;
    top:2px;
    bottom:2px;
    right:2px;
    padding:5px 7px 0;
    font-size:14px;
    color:#333;
    background-color:#d0d0d0;
    -webkit-border-radius: 3px;
    border-radius: 2px;
    cursor:pointer;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawk-resultsSearch .iconSearch {
    margin: 0;
}

.hawk-guidedNavWrapper.recentSearches .hawk-navGroup .hawk-clearSelected {
    border: 0 none;
}



/* ## facet checkbox style ## */

.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleCheckbox,
.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleCheckbox:link,
.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleCheckbox:visited {
    position: relative;
    padding-left: 29px;
	padding-right: 18px;
}

.hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleCheckbox:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    color: #000;
    font-size: 12px;
    line-height: 14px;
	padding-top: 1px;
	padding-left: 2px;
    border: 1px solid #bdbdbd;
    background-color: transparent;
	border-radius: 3px;
    -moz-box-shadow: inset 0px 0px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0px 0px 0px rgba(0,0,0,0.3);
    box-shadow: inset 0px 0px 0px rgba(0,0,0,0.3);    
	transition: all 0.5s ease-in-out;
}

.hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleCheckbox:hover,
.hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleCheckbox:focus {
	&:before {
		border-color: #c2c2c2;
		content: "\2714";
		background-color: #dfdfdf;
		border-radius: 5px;		
	}
}

.hawk-guidedNavWrapper .hawk-navGroup li.hawkFacet-hover > .hawk-styleCheckbox:before {
    border-color: #c2c2c2;
    content: "\2714";
    background-color: #fff;
	border-radius: 5px;
}

.hawk-guidedNavWrapper .hawk-navGroup li.hawkFacet-active > .hawk-styleCheckbox:before {
    border-color: #c2c2c2;
    content: "\2714";
    background-color: #fff;
	border-radius: 5px;
}
.hawk-guidedNavWrapper .hawk-navGroup li.hawkFacet-indetermined > .hawk-styleCheckbox:before {
    content: "";
}

.hawk-guidedNavWrapper .hawk-navGroup li.hawkFacet-indetermined > .hawk-styleCheckbox:after {
    font-family: hawksearch;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    color: #000;
    font-size: 12px;
    line-height: 14px;
    padding-top: 1px;
    padding-left: 2px;
    
    border: 1px solid #c2c2c2;
    content: "\2714";
    background-color: #fff;
    border-radius: 5px;
}

.hawk-guidedNavWrapper .hawk-navGroup li.hawkFacet-active .hawk-rating{
    display:inline-block;
    width: 85px;
    position:relative;
    top:2px;
}

/* ## facet swatch and size style ## */

.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-swatch .value {
    display: none;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-swatch li.hawk-navMore,
.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-size li.hawk-navMore {
    float: none;
    clear: both;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-swatch,
.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-size {
    padding: 5px 0px 0px 7px;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-swatch li,
.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-size li {
    float: left;
    padding: 0;
    margin: 1px;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawkfacet-swatch li,
.hawk-guidedNavWrapper .hawk-navGroup .size-value:hover {
    color: #999;
}

.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleSwatch,
.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleSwatch:visited {
    display:inline-block;
    height: 38px;
    width: 38px;
    padding:0;
    text-align: center;
    overflow: hidden;
    text-shadow: 0 1px 0 #FFF;
    display: inline-block;
    color: #333;
    font-weight: bold;
    border:3px solid #fff;
}

.hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleSwatch .hawk-selectionInner {
    display:block;
    height:32px;
    width:32px;
    border:2px solid #fff;
    overflow:hidden;
}

.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleBlock,
.hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleBlock:visited {
    display:inline-block;
    height: 36px;
    min-width: 36px;
    padding:0;
    text-align: center;
    overflow: hidden;
    text-shadow: 0 1px 0 #FFF;
    display: inline-block;
    color: #333;
    font-weight: bold;
    border:3px solid #fff;
}

.hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleBlock .hawk-selectionInner {
    display:block;
    height:32px;
    padding:0 5px;
    line-height: 32px;
    background: #E6E7E8;
    border:2px solid #fff;
    overflow:hidden;
}

.hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleBlock .hawk-selectionInner span {
    color: #333;
}

.hawk-guidedNavWrapper .hawk-navGroup .hawkFacet-active .hawk-styleSwatch,
.hawk-guidedNavWrapper .hawk-navGroup .hawkFacet-active .hawk-styleBlock {
    border-color: #E98820;
}


/* ## embedded clear fix ## */

.hawk-guidedNavWrapper .hawk-navGroup li:before, .hawk-guidedNavWrapper .hawk-navGroup li:after {
    content: "";
    display: table;
}

.hawk-guidedNavWrapper .hawk-navGroup li:after {
    clear: both;
}

.hawk-guidedNavWrapper .hawk-navGroup li {
    zoom: 1;
}


/* ## nav list search ## */

.hawk-guidedNavWrapper .hawk-quickSearch label {
    display: block;
    margin: 0 0 2px 0;
    font-size: 0.875em;
}

.hawk-guidedNavWrapper .hawk-quickSearch {
    margin-bottom: 1em;
}

.hawk-guidedNavWrapper .hawk-quickSearch input[type="text"] {
    width: 100%;
	height: 33px;
    padding: 4px 8px;
    font-size: 14px;
	font-weight: 400;
	color: #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
	border-radius: 0;
	@media (max-width:1279px) {
		font-size: 12px;
	}	
}

.hawk-guidedNavWrapper .hawk-quickSearch input::-webkit-input-placeholder { 
    font-size: 14px;
	font-weight: 400;
	color: #333;
	opacity: 1;
	@media (max-width:1279px) {
		font-size: 12px;
	}		
}
.hawk-guidedNavWrapper .hawk-quickSearch input:-moz-placeholder { 
    font-size: 14px;
	font-weight: 400;
	color: #333;
	opacity: 1;
	@media (max-width:1279px) {
		font-size: 12px;
	}		
}
.hawk-guidedNavWrapper .hawk-quickSearch input::-moz-placeholder { 
    font-size: 14px;
	font-weight: 400;
	color: #333;
	opacity: 1;
	@media (max-width:1279px) {
		font-size: 12px;
	}		
}
.hawk-guidedNavWrapper .hawk-quickSearch input:-ms-input-placeholder { 
    font-size: 14px;
	font-weight: 400;
	color: #333;
	opacity: 1;
	@media (max-width:1279px) {
		font-size: 12px;
	}		
} 

/* ## facet Slider Styles ## */

.hawk-guidedNavWrapper .hawk-slideFacet {
    display: block;
    position: relative;
	/*background: #efefef;*/
	padding: 0 25px 40px;
	margin: 0;
	@media (max-width:1279px) {
		padding: 10px 10px;
	}	
	strong {
		color: #444;
		font-size: 16px;
		font-weight: 600;		
		@media (max-width:1279px) {
			font-size: 14px;
		}			
	}
}

.hawk-guidedNavWrapper .hawk-slideFacet .slider-clear {
    font-size: .95em;
    float: right;
    clear: both;
    cursor: pointer;
}

.hawk-guidedNavWrapper .hawk-slideFacet .numeric-from,
.hawk-guidedNavWrapper .hawk-slideFacet .numeric-to {	
    float: left;
    display: inline-block;
	width: 48%;
	height: 33px;
    padding: 4px 3px;
    position: relative;
    font-family: inherit;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
	color: #333333;
	border-radius: 5px;
	margin-top: 5px;
	@media (max-width:1279px) {
		font-size: 12px;
	}	
}

.hawk-guidedNavWrapper .hawk-slideFacet .slider-cur-value {
    float: left;
    display: block;
    position: absolute;
    left: 45px;
    height: 15px;
    color: #000;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
    padding-top: 2px;
    font-weight: normal;
}

.hawk-guidedNavWrapper .hawk-slideFacet .numeric-to {
    float: right;
	text-align: center;
}

.hawk-guidedNavWrapper .hawk-slideFacet .slider-start-value {
    float: left;
    display: block;
    position: relative;
    height: 15px;
    color: #868686;
    font-family: Arial;
    font-size: 11px;
    text-align: left;
    padding-top: 2px;
}

.hawk-guidedNavWrapper .hawk-slideFacet .slider-end-value {
    float: right;
    display: block;
    position: relative;
    height: 15px;
    color: #868686;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
    padding-top: 2px;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-widget-header {
    background: #ccc;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider {
    position: relative;
    text-align: left;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
}

.hawk-slideFacet .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider .ui-slider-single {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal {
    height: 12px;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-handle {
    top: -3px;
    width: 10px;
    height: 18px;
    margin-left: -6px;
    padding: 0;
    background: #A30008;
    border: 1px solid #930000;
    cursor: hand;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-single {
    top: 0;
    height: 100%;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-single-min {
    left: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-horizontal .ui-slider-single-max {
    right: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .slider-min-value {
    float: left;
}
.hawk-guidedNavWrapper .hawk-slideFacet .slider-max-value {
    float: right;
}

.hawk-slideFacet .ui-slider-horizontal .ui-slider-single-cur {
    left: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical {
    width: .8em;
    height: 100px;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-range-max {
    top: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-single {
    left: 0;
    width: 100%;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-single-min {
    bottom: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-single-max {
    top: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .ui-slider-vertical .ui-slider-single-cur {
    bottom: 0;
}

.hawk-guidedNavWrapper .hawk-slideFacet .hawk-slideRange {
    margin: 0;
    background: transparent;
	border: none;
}

.hawk-guidedNavWrapper .hawk-slideFacet #slider-single {
    margin-bottom: 10px;
}

.hawk-guidedNavWrapper .hawk-slideFacet #slider-single a.handle {
    cursor: pointer;
    background: url(/assets/images/layout/leftslider.png) 50% 50% no-repeat;
    border: none;
    padding: 0;
    width: 15px;
    height: 19px;
    z-index: 12;
}



/* ## Horizontal Facets ## */

.hawk-horizontalFacets {
	position:relative;
	margin-bottom:20px;
	z-index:10;
}

/* embedding clearfix on horzontal facet container */
.hawk-horizontalFacets:before, .hawk-horizontalFacets:after { content: ""; display: table; }
.hawk-horizontalFacets:after { clear: both; }
.hawk-horizontalFacets { zoom: 1; }

.hawk-horizontalFacets .hawk-navGroup {
	position:relative;
	float:left;
	width:25%;
	padding:0 5px;
	border:0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.hawk-horizontalFacets .hawk-navGroup:first-child {
	padding-left:0;
}

.hawk-horizontalFacets .hawk-groupHeading {
	height: 20px;
	padding:5px;
	border:1px solid #ccc;
	cursor:pointer;
}

.hawk-horizontalFacets .hawk-groupHeading:after {
	content: "\e639";
	display:block;
	right:6px;
	top:7px;
}


.hawk-horizontalFacets .hawk-navGroupContent {
	position:absolute;
	top:32px;
	left:5px;
	right:5px;
	padding:5px;
	background:#f3f3f3;
	border:1px solid #ccc;
	border-top:0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 4px 1px rgba(50,50,50,0.25);
    box-shadow: 0 0 4px 1px rgba(50,50,50,0.25);
}

.hawk-horizontalFacets .hawk-navGroup:first-child .hawk-navGroupContent {
	left:0;
}

.hawk-horizontalFacets .hawk-collapsed .hawk-groupHeading:after {
	content: "\e63a";
}

.hawk-horizontalFacets .hawk-collapsed .hawk-navGroupContent {
	left:-9999em !important;
	right:auto !important;
}



div.growlUI {
    padding: 2px;
    border-top: 1px solid #000000;
    box-shadow: 0 0 3px 3px rgba(50, 50, 50, 0.5);
    bottom: 0;
    position: fixed;
    background-color: #311F09;
    filter: alpha(opacity=70);
    -khtml-opacity: 0.7;
    -moz-opacity: 0.7;
    opacity: 0.7;
    width: 100%;
    z-index: 99998;
    right: 0px;
}

div.growlUI span {
    font-size: 21px;
    color: white;
    padding: 10px 0 10px 20px;
    margin: 0;
    text-align: left;
    border-bottom: none;
    background-color: transparent;
}

#aBug {
    color: #666;
    background-color: #fff;
    border: 1px solid #5a3810;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 5px;
    text-decoration: none;
}

#aEye {
    color: #666;
    background-color: #fff;
    border: 1px solid #5a3810;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 5px;
    text-decoration: none;
}


/* ## Smart Bug ## */

.smartbug {
    background: #5a3810;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    padding: 0;
    border: 1px solid #AAAAAA !important;
    border-radius: 0 !important;
    box-shadow: 0 0 3px 3px rgba(50, 50, 50, 0.5);
}

.smartbug ul {
    padding: 0px 5px 0px 5px;
    list-style-type: none;
    background-color: #5a3810;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}

.smartbug li {
    border-bottom: 1px solid #5a3810;
}

.smartbug a,
.smartbug a:link,
.smartbug a:visited {
    text-decoration: none;
    display: block;
    color: #fff;
    padding: 5px;
}

.smartbug a:hover {
    background-color: #fff;
    color: #5a3810;
}

/* ## Search Lookup Dropdown ## */

.hawk-searchQuery {
    position: fixed;
    left: 50% !important;
    transform: translateX(-50%);
    right: 10px !important;
    display: none;
    background: #fff;
    margin: 0 0 0 0;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
	-webkit-box-shadow: 0 1px 7px -2px #666;
	box-shadow: 0 1px 7px -2px #666;
	border: 1px solid #d7d7d7;
	overflow: hidden;
    z-index: 999999;
}

.hawk-searchQuery-keyword {
    margin-left:-1px;
}

.hawk-searchQuery .hawk-sqTermsItems {
	display: table;
	width: 100%;
}

.hawk-searchQuery .hawk-sqHeader {
    padding: 5px 15px;
    font-weight: bold;
}

.hawk-searchQuery .hawk-sqContent {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.hawk-searchQuery .hawk-sqItem {
    position: relative;
    display: block;
    margin: 0;
    padding: 6px 15px;
    color: #8b2300;
    text-decoration: none;
    cursor: pointer;
    zoom: 1;
}


/* embedded clearfix on results item: h5bp.com/q */
.hawk-searchQuery .hawk-sqItem:before, .hawk-searchQuery .hawk-sqItem:after {
    content: "";
    display: table;
}

.hawk-searchQuery .hawk-sqItem:after {
    clear: both;
}

.hawk-searchQuery .hawk-sqItemImage {
    display: inline;
    float: left;
    width: 70px;
    height: auto;
    margin: 0;
	padding: 2px;
    text-align: center;
	border: 1px solid #fff;
}

.hawk-searchQuery .hawk-sqItemContent {
    display: block;
    margin-left: 80px;
}

.hawk-searchQuery .hawk-sqItemName {
    display: block;
    margin:0;
	padding: 0;
	color: #000;
    font-weight:normal;
    font-size: 0.875em;
    border: 0;
}

.hawk-searchQuery .hawk-sqRating {
	margin: 0 0 5px;
	padding: 0;
	border: none;
}

.hawk-searchQuery .hawk-sqRating > .ratingDisplay {
	float: none;
}

.hawk-searchQuery .hawk-sqTerms,
.hawk-searchQuery .hawk-sqItems {
	display: table-cell;
	vertical-align: top;
    padding-top: 15px;
}

.hawk-searchQuery .hawk-sqTerms {
	background-color: #EEEDEE;
	border-right: 1px solid #EEEDEE;
}

.hawk-searchQuery .hawk-sqTerms .hawk-sqItem {
	background: none;
	border: none;
}

.hawk-searchQuery .hawk-sqTerms .hawk-sqActive {
	background-color: #fff;
}

.hawk-searchQuery .hawk-sqTerms .hawk-sqItemName {
	padding: 0;
}

.hawk-searchQuery .hawk-sqItems {
	width: 60%;	
	padding: 15px 15px 40px;
}

.hawk-searchQuery .hawk-sqItems .hawk-sqHeader {
	margin: 0;
	padding-left: 0;
    padding-right: 0;
}

.hawk-searchQuery .hawk-sqItems .hawk-sqItem {
	padding: 20px 0;
	border-top: 1px solid #ccc;
}

.hawk-searchQuery .hawk-sqItems .hawk-sqItem:first-child {
	border-top: none;
}

.hawk-searchQuery .hawk-sqItems .hawk-sqActive .hawk-sqItemImage {
	border-color: #a80000;
}

.hawk-searchQuery .hawk-sqFooter {
    position: absolute;
	bottom: 15px;
	right: 10px;
	padding: 0;
	font-size: 0.875em;
	text-align: right;
	background: none;
}

.hawk-searchQuery .hawk-sqFooterCount {
	display: none;
}

.hawk-searchQuery .hawk-sqFooter a,
.hawk-searchQuery .hawk-sqFooter a:link,
.hawk-searchQuery .hawk-sqFooter a:visited {
    display: inline-block;
	padding: 0;
	text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
}



/* Rating*/

.hawk-listingRating {
    text-align: center;
    width: 115px;
    margin: 0 0 10px 2px;
}

.hawk-listingRating .hawk-rating {
    float: left;
    width: 85px;
}

.hawk-rating {
	position: relative;
    cursor: pointer;
    display: block;
    height: 15px;
	margin-top: 3px;
    margin-left: 0px;
    position: relative;
    text-indent: 85px;
}

.hawk-rating:before,
.hawk-rating:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 80px;
	height: 16px;
    background-image: url("../../images/layout/star.png");
    background-repeat: repeat-x;
}


/* rating foreground */
.hawk-rating:after {
	width: 0;
	background-position: 0 -48px;
}

.hawk-ratingDecimal {
    height: 15px;
    padding: 0 3px;
    color: #fff;
    font-size: 12px;
    vertical-align: top;
    background-color: #414142;
}

.hawk-rated05:after {
    width: 8px;
}

.hawk-rated1:after {
    width: 16px;
}

.hawk-rated15:after {
    width: 24px;
}

.hawk-rated2:after {
    width: 32px;
}

.hawk-rated25:after {
    width: 40px;
}

.hawk-rated3:after {
    width: 48px;
}

.hawk-rated35:after {
    width: 56px;
}

.hawk-rated4:after {
    width: 64px;
}

.hawk-rated45:after {
    width: 72px;
}

.hawk-rated5:after {
    width: 80px;
}

/* ## responsive media-queries ## */

@media only screen and (min-width: 768px) {

	#hawktitle {
		.pageHeading {
			margin-top: -20px !important;
			&:not(.pageHeadingAlt) {
				padding-bottom: 0px !important;
				margin-bottom: 0px !important;
			}
		}
	}
	
}
	
	
@media only screen and (max-width: 767px) {
    .hawk-railNavHeading {
		display: block;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
		text-transform: uppercase;
		font-size: 14px;
		padding: 10px 45px;
    }

	#hawktitle {
		.title {
			padding-top: 5px;
			padding-bottom: 5px;
			margin-bottom: 5px !important;
		}
	}
	
	#hawktoppager .pagination,
	#hawkbottompager .pagination {
		float: none;
		margin: 7px 0 10px 0;
		text-align: center;
	}	
	
	#hawktoppager .roc-pageOptions,
	#hawkbottompager .roc-pageOptions {
		display: table;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0px;
	}	
	
	#hawktoppager .roc-pageOptions-filter,
	#hawkbottompager .roc-pageOptions-filter {
		label {
			margin-top: 3px;
			margin-bottom: 3px;
		}
		select {
			margin-top: 3px;
			margin-bottom: 3px;		
			width: 100%;
			height: 33px;
			padding: 1px 4px 4px 4px;			
		}
	}
	
	#hawktoppager.hawk-top-pager {
		/*border-top: 1px solid #c6c6c6;*/
		padding-top: 10px !important;
	}
	
	#hawkbottompager.hawk-bottom-pager {
		display: none;
	}
	
//	#hawkitemlist.productList {
//		margin-top: 20px !important;
//		padding-top: 20px !important;
//	}
	
	#hawktitle .pageHeading:not(.pageHeadingAlt) {
		padding-bottom: 0px !important;
		margin-bottom: 0px !important;
	}
	
    .hawk-railNavHeading:after {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: block;
    }

	.hawk-railNavHeading:after {
		content: "\ea0a";
		font-family: 'ROC-Icons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1.42857;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;		
	}
	
	.hawk-railNavHeading:before {
        position: absolute;
        left: 5px;
        top: -5px;
        display: block;
		font-size: 35px;
    }

	.hawk-railNavHeading:before {
		content: "\e636";
		font-family: 'ROC-Icons';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1.42857;
		font-weight: 400;
		text-shadow: none;		

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;		
	}
	
    .hawk-railNavHeadingActive {
        margin-bottom:0;
        
        &:after {
			content: "\e606";
        }
    }

    .hawk-railNavHeading span {
        padding: 0 0 0 15px;
    }

    .hawk-railNavHeading a,
    .hawk-railNavHeading a:link,
    .hawk-railNavHeading a:visited {
        color: #fff;
        text-decoration: none;
    }

    .hawk-railNavHeading a:hover,
    .hawk-railNavHeading a:active {
        color: #fff;
        text-decoration: none;
    }

    .hawkRailNav {
        display: none;
        overflow: hidden;
    }

    .hawk-notCollapsed {
        display: block;
    }

    .hawk-guidedNavWrapper {
        margin-bottom: -2px;
        border-bottom: 1px solid #fff;
    }

    .hawk-guidedNavWrapper hawk-navGroup .hawk-groupHeading {
        position: relative;
        cursor: pointer;
    }


    /* ## overwrite default collapse mode ## */

    .hawk-guidedNavWrapper .hawk-collapsed .hawk-navGroupContent {
        position: static;
        left: 0;
    }


    /* ## Use special responsive collapse styles ## */

//    .hawk-guidedNavWrapper .hawk-navGroup .hawk-groupHeading:after {
//        display:block;
//        content: "\e63a";
//    }
//
//    .hawk-guidedNavWrapper .hawk-notCollapsed .hawk-groupHeading:after {
//        content: "\e639";
//    }
//
//    .hawk-guidedNavWrapper .hawk-navGroup .hawk-navGroupContent {
//        display: none;
//    }
//
//    .hawk-guidedNavWrapper .hawk-notCollapsed .hawk-navGroupContent {
//        display: block;
//    }


    /* ## small device facet list style ## */

    .hawk-guidedNavWrapper .hawk-navGroup .hawk-navGroupContent > ul {
        padding: 0 0 10px;
        list-style-type: none;
    }

//    .hawk-guidedNavWrapper .hawk-navGroup ul.hawk-scrollList:before,
//    .hawk-guidedNavWrapper .hawk-navGroup ul.hawk-scrollList:after {
//        content: "";
//        display: table;
//    }
//
//    .hawk-guidedNavWrapper .hawk-navGroup ul.hawk-scrollList:after {
//        clear: both;
//    }

//    .hawk-guidedNavWrapper .hawk-navGroup ul.hawk-scrollList {
//        max-height: none !important;
//        height: auto !important;
//        list-style-type: none;
//        overflow-y: visible;
//        zoom: 1;
//    }

	.hawk-navGroup {
		&.hawk-collapsed {
			.hawk-nestedfacet {
				max-height: 0;
				overflow: hidden;
			}
		}
	}
	
	.hawk-guidedNavWrapper {
		.hawk-navGroup {
			&.hawk-collapsed {
				.hawk-nestedfacet {
					max-height: 0;
					overflow: hidden;
				}
				.hawk-navGroupContent {
					padding: 0;
					>ul {
						padding: 0;
					}
				}
			}
		}		
	}
	
    .hawk-guidedNavWrapper .hawk-navGroup li {
        position: relative;
        height: auto;
        margin: 1px;
        padding: 3px 0;
    }

    .hawk-guidedNavWrapper .hawk-navGroup li a,
    .hawk-guidedNavWrapper .hawk-navGroup li a:visited {
        display: block;
        /*padding: 1em;
        border-top: 1px solid #e0dfdf;*/
    }

    .hawk-guidedNavWrapper .hawk-navGroup li:first-child a.hawk-styleList {
        border-top: 0;
    }

    .hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleCheckbox,
    .hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleCheckbox:link,
    .hawk-guidedNavWrapper .hawk-navGroup li a.hawk-styleCheckbox:visited {
        position: relative;
        padding-left: 28px;
    }

    .hawk-guidedNavWrapper .hawk-navGroup li .hawk-styleCheckbox:before {
        left: 0;
        top: 0;
        margin-top: 1px;
    }


    .hawk-guidedNavWrapper .hawk-navGroup li.hawk-navMore span {
        display: inline-block;
        cursor: pointer;
    }


    /* ## selections ##*/

    .hawk-guidedNavWrapper .hawk-selectedGroup ul {
        padding:0;
        background: none;
        border:0;
    }

    .hawk-guidedNavWrapper .hawk-selectedGroup ul li {
        display: inline-block;
        margin: 5px;
    }
    
    
    /* ## autosuggest */
    
    .hawk-searchQuery {
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        padding: 0 !important;
        overflow-y: auto;
        transform: none;
        top: 125px !important;
    }
    
    .hawk-searchQuery .hawk-sqTermsItems {
        height: 100%;
    }
    
}


@media only screen and (max-width: 560px) {

    .hawk-searchQuery .hawk-sqFooter {
        position: static;
        padding: 0 15px 15px;
    }
    
    .hawk-searchQuery .hawk-sqTermsItems {
        display: block;
        height: auto;
    }
    
    .hawk-searchQuery .hawk-sqTerms, .hawk-searchQuery .hawk-sqItems {
        display: block;
        width: auto !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}


/* preview info*/
.banner-container, .featured-container {position:relative; width:100%}
    .featured-container .preview-info {
        right:0;
    }

.preview-info {
    box-shadow:0 5px 15px rgba(0, 0, 0, 0.5); 
    border-radius:5px; 
    background-color:#FEF087;
    position:absolute; 
    top:5px; 
    right:5px; 
    opacity:0.6; 
    filter:alpha(opacity=60);
    cursor:pointer;
    z-index:100;
    font-size:12px;
}
    .preview-info.hover {
        opacity:1;
    }

    .preview-info.preview-info-icon {
        width:30px; height:30px;
        background-image:url(/sites/shared/images/question.gif);
        background-repeat:no-repeat;
        background-position:3px 3px;
    }
    .preview-info.explain-info {
        padding:3px;
    }


.preview-tip .popover-content {
    text-align:center;
}

.banner-tip {
    width:200px;
}
.trigger-tip .trigger-name {font-weight:bold;padding-top:5px;}
.trigger-tip .rule {margin-top:8px; padding-top:8px; border-top:1px solid #e7bea2;}
.trigger-tip .rule .condition { text-transform: uppercase; color: #abc; }
.trigger-tip .rule .connector { text-transform: uppercase; color: red; }
.trigger-tip .rule .field, .trigger-tip .rule .value { text-decoration: underline; -webkit-text-decoration-style: dotted; -moz-text-decoration-style: dotted; -ms-text-decoration-style: dotted; -o-text-decoration-style: dotted; text-decoration-style: dotted; }


.bootbox-close-button{    
    margin-top:-2px;
    padding: 0;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: 0;
    float:right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #FFF;
    opacity: .2;
    filter: alpha(opacity=20);
}
.bootbox .modal-header{
    padding:15px;
}
.bootbox .modal-title{
    margin:0;
    line-height: 1.4;
    font-size: 18px;
    font-weight: 500;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.hawkNoResults .hawkNoResultsLabel
{
  font-weight: bold;
  padding-top: 1em;
  padding-right: 0px;
  padding-bottom: 0.5em;
  padding-left: 0px;
}
.hawkNoResults .hawkNoResultsKeyword
{
  font-weight: bold;
  padding-bottom: 1em;
}

.hawk-facet-tooltip{
    cursor: pointer;
}
.hawk-facet-tooltip-content{
    display:none;
}

.hawk-bottom-pager {
	.roc-pageOptions-filter,
	.hawk-subControls {
		display: none;
	}
}

/* # Recommendations */

.hawk-recommendation{
    position:relative;
}

.hawk-recommendation .hawk-recommendation-inner {
    display:inline-block;
    margin: 0 auto;
    position: relative;
}
	
.hawk-recommendation .hawk-recommendation-inner .hawk-recommendation-list {
    display: block !important;
}

.hawk-recommendation.hawk-recommendation-horizontal {
	text-align: left;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-recommendation-inner .hawk-recommendation-list.slick-initialized {
    margin:0 25px;
    padding:0;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-recommendation-inner .hawk-recommendation-item {
    min-width:100px;
    float:left;
}

.hawk-recommendation.hawk-recommendation-vertical .hawk-recommendation-inner .hawk-recommendation-item{
    width:161px;
    margin:0;
    padding:0;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-recommendation-inner .hawk-recommendation-item img {
	width: auto !important;
}

.hawk-recommendation .hawk-carousel-next, .hawk-recommendation .hawk-carousel-prev {
    display:inline-block;
    height:38px;
    width:38px;
    padding:0;
    color:#000;
    text-decoration:none;
    font-size:1.8em;
    background: none;
    border: none;    
    z-index:10;
}

.hawk-recommendation .hawk-recommendation-inner.has-arrows {
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-carousel-next, 
.hawk-recommendation.hawk-recommendation-horizontal .hawk-carousel-prev {
    position:absolute;
    bottom: 50%;
    margin-top:-19px;
}

.hawk-recommendation .hawk-carousel-next.slick-disabled,
.hawk-recommendation .hawk-carousel-prev.slick-disabled{
    display: none !important;
}

.hawk-recommendation .hawk-carousel-next > span, 
.hawk-recommendation .hawk-carousel-prev > span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-recommendation-inner {
	display: block;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-carousel-next{
    right: -40px;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-carousel-prev{
    left: -40px;
}

.hawk-recommendation.hawk-recommendation-vertical .has-arrows {
    padding: 40px 0;
}

.hawk-recommendation.hawk-recommendation-vertical .hawk-carousel-prev,
.hawk-recommendation.hawk-recommendation-vertical .hawk-carousel-next {
    position: absolute;
    left: 50%;
    margin-left: -19px;
}

.hawk-recommendation.hawk-recommendation-vertical .hawk-carousel-prev {
    position: absolute;
    top: -40px;
}

.hawk-recommendation.hawk-recommendation-vertical .hawk-carousel-next {
    position: absolute;
    bottom: -40px;
}


/* embedding icomoon for previous and next arrows */
.hawk-recommendation .hawk-carousel-next:before,
.hawk-recommendation .hawk-carousel-prev:after {
    font-family: 'hawksearch';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align:middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.hawk-recommendation del{
    display: block;
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-carousel-next:before {
    content: "\e606";
}

.hawk-recommendation.hawk-recommendation-horizontal .hawk-carousel-prev:after {
    content: "\e603";
}

.hawk-recommendation.hawk-recommendation-vertical .hawk-carousel-next:before {
    content: "\e604";
}

.hawk-recommendation.hawk-recommendation-vertical .hawk-carousel-prev:after {
    content: "\e605";
}

.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}

.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-dots
{
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'hawksearch';
    font-size: 20px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '\2219';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: #F37624;
}

.hawk-noUi-connect {
	background: #0a4e8d !important;
}

.hawk-noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #e4701d !important;
    cursor: default;
    box-shadow: inset 0 0 1px #e4701d, inset 0 1px 7px #e4701d, 0 3px 6px -3px #e4701d !important;
}

.smc-hawk-recommendation{
    @extend .hawk-recommendation;

    .hawk-recommendation-item{
        .itemTitle a:hover{
            color: #e4701d;
        }
        .itemContent{
            margin-bottom: 5px !important;
            flex: 1 1 auto;
        }
    }
    
}
.smc-recommended-slider {
    .title {
        color: #e4701e;
        margin-bottom: 20px !important;
        display: block !important;
        font-weight: 600 !important;
    }
    .rocModule-miniList {
        border: none !important;
        @include md{
            padding: 0 !important;
            margin: 0;
        }
    }
    .productList img.itemImage {
         @include md{
            max-height: 120px !important;
         }
    }
    .itemWrapper{
        padding: 15px;
        @include md{
            padding: 30px;
        }
        .product-list-item-sku{
            @include md{
                margin: 0;
            }
        }
         .itemTitle .itemLink {
            font-weight: 600 !important;
            color: #333 !important;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @include md{
                font-size: 14px !important;
            }
            &:after {
                display: none;
            }
        }
    }

}

.slide-equal-height{
    &.slick-initialized{
        .slick-track {
            display: -ms-flexbox  !important;
            display: -webkit-flex !important;
            display: flex !important;
        }
        .hawk-recommendation-item{
            display: -ms-flexbox  !important;
            display: -webkit-flex !important;
            display: flex !important;
            height: auto !important;
            flex-direction: column;
    
            & > div{
                height: auto !important;
                &:last-child {
                    margin-top: auto !important;
                }
            }
            .itemContent > *{
                height: auto !important;
                min-height: 0 !important;
            }
        }
    }  
}