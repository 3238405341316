/* # Category List */

.productList.categoryList {
	margin: 1.5em 0;
	padding: 0;
	border: 0;
}

.your-warehouse-box {
	display: table;
	margin-bottom: 15px;
	padding: 15px 15px 20px 15px;
	background: #dde6f1;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 16px;		
	color: #333333;		
	width: 100%;
	/* ## 16px baseline (767px) */
	@media only screen and (max-width:47.94em){
		margin-bottom: 15px;
	}	
	.member-info {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	.member-info {
		display: inline-block;
		vertical-align: middle;		
		margin-right: 0px;
		.ship-to {
			b {
				position: relative;
				margin-left: 7px;
				padding-left: 18px;
				color: #002f65;
			}
			button {
				&.selectExpressShipToBtn {
					font-size: 14px;
					font-weight: 400;
				}
			}
			.icon-location {
				position: absolute;
				left: 0px;
				fill: #002f65;
				width: 18px;
				height: 23px;
			}
		}
	}
}

button {
	&.selectExpressShipToBtn {
		padding: 0;
		margin: 0;
		display: inline;
		box-decoration-break: clone;
		transition: all 1s ease-in-out;
		-webkit-transform: perspective(1px) scale(1);
		-ms-transform: perspective(1px) scale(1);
		transform: perspective(1px) scale(1);	
		position: relative;
		background-image: linear-gradient(to bottom,rgba(0,77,142,.6),rgba(0,77,142,.6));
		background-position: 0 100%;
		background-size: 100% 1px;
		background-repeat: no-repeat;
		background-color: transparent;
		border: none;
		text-decoration: none;
		font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
		font-size: 16px;
		font-weight: 500;
		color: #0a4e8c;
		letter-spacing: 0;
		text-transform: none;
		text-align: left;
		&:hover,
		&:focus {
			background-size: 0 1px;
			background-repeat: no-repeat;
			background-color: transparent;
			box-shadow: none;	
			-webkit-transform: perspective(1px) scale(1);
			-ms-transform: perspective(1px) scale(1);
			transform: perspective(1px) scale(1);			
		}
	}
}

/* # Product List Display Options */

.listing-display {
	/* ## 16px baseline (480px) */
	@media only screen and (max-width:30em){
		display: none !important;
	}
}

.listing-display-option {
    display: inline-block;
	margin: 0 3px;
    color: #b3b3b3;
    line-height: 0;
	cursor: pointer;
    
    &.active {
        color: #e4701e;
    }
    
    &:hover,
    &:active,
    &:focus {
        color: #e4701e;
    }
    
    .display-icon {
		@extend %iconFont;
        
		position: relative;
		display: inline-block;
		font-size: 29px;
		vertical-align: middle;
    }
	
	&[data-display-mode="grid"] .display-icon:before {
		// .icon-grid2
		content: "\e62a";
		
		/* ## 16px baseline (480px+) */
		@media only screen and (min-width:30em){
			// .icon-grid3
			content: "\e635";
		}
		
		/* ## 16px baseline (768px+) */
		@media only screen and (min-width:48em){
			// .icon-grid4
			content: "\e64c";
		}
	}
	
	&[data-display-mode="grid-alt"] .display-icon:before {
		// .icon-grid2
		content: "\e62a";
		
		/* ## 16px baseline (480px+) */
		@media only screen and (min-width:30em){
			// .icon-grid2
			content: "\e62a";
		}
		
		/* ## 16px baseline (768px+) */
		@media only screen and (min-width:48em){
			// .icon-grid3
			content: "\e635";
		}
	}
	
	&[data-display-mode="list"] {
        display: none;
    
		/* ## 16px baseline (768px+) */
		@media only screen and (min-width:48em){
            display: inline-block;
        }
    
        .display-icon:before {
            @extend .icon-list;
        }
    }
}

/* # Product Listing  */

.productListInner .itemTitle {
    min-height: auto !important;
}

.smc-ribbon-wrapper-Hoffman {
	margin-left: auto;
	z-index: 1;
	.smc-ribbon-Hoffman {
		img {
			width: 120px;
			margin-bottom: 5px;
		}
	}
}

.productListInner {
    .itemWrapper {
        .itemContent {
            margin-bottom: 40px;
        }
    }
}

.productList {
	margin: 25px 0 0 0;
    padding: 2em 0 0 0;
    border-top: 1px solid $color-border;
  	
	&.list-singlet {
		margin: 0;
		padding: 0;
		border: none;
	}
    	
    .productListInner {
        margin: 0 -10px;
		padding: 0;
		list-style-type: none;
		
		@include clearfix;
    }
}


/* # Product Items */

.itemWrapper {
    min-height: 0 !important;
    font-size: 0.938em;
	/*
	&:after {
		content: '';
		position: absolute;
		bottom: -9px;
		left: 0px;
		width: 93%;
		border-bottom: 1px solid #cccccc;
	}
	*/
    
	.media {
        position: relative;
		text-align: center;
		margin-bottom: 25px;
		height: auto !important;

		.recentlyViewedWrapper & {
			margin-bottom: 0;
		}
    }
	    
    .quickViewButton {
        margin-top: 0px;
        padding: 5px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
		color: #002f65;
        text-transform: uppercase;
        background-color: #cccccc;
        border: 0px solid $color-border;
        cursor: pointer;
		-webkit-transition: background-color 0.3s;
		-moz-transition: background-color 0.3s;
		transition: background-color 0.3s;
        
        &:hover {
            background-color: darken($gray, 5%);
        }
    }
    
    .itemImageWrapper {
		display: block;
		vertical-align: middle;
		text-decoration: none;
		/*border: 1px solid $color-border;*/
		text-align: center;
	}
    

	.itemImage {
		display: block;
		margin: 0 auto;
		padding: 0;
		/*(width: 100% !important;*/
		/*@media (min-width: 480px) and (max-width: 767px) {
			height: 100% !important;
		}*/		
	}
	
	a {
		text-decoration: none;
	}
	
	/* ## modifier for item swatches */
	.itemSwatches {
		display: inline-block;
		margin: 0 auto;
		
		ul {
			margin: 0 -4px;
		}
		
		li {
			margin: 8px 4px 0;
            cursor: pointer;
		}
	}
	
	.itemContent {
		padding: 0;
		text-align: center;
		margin-bottom: 10px;
		height: auto !important;
		flex: 1 0;
		@media (max-width:479px) {
			height: auto !important;
		}
		@media (max-width:767px) {
			padding: 0;
        }		
	}

	.add-toggle {
		display: block;		
		padding: 5px 20px 0;
	}	
	
	.itemTitle {
		display: block;
		margin: 5px 0;
		font-size: 16px;
		line-height: 22px;
		color: #444444;
		font-weight: 300;
		word-wrap: break-word;
		word-break: break-all;
		min-height: 0 !important;
		text-transform: uppercase;
        min-height: 100px !important;

        @media (max-width: 1350px) {
            min-height: 120px !important;
        }

		@media (max-width: 1279px) {
			font-size: 16px;
			line-height: 20px;
            min-height: 100px !important;
        }		

		a {
            color: inherit;
			text-decoration: none;
			font-weight: 300;
            
            &:hover {
                color: $color-text-secondary;
            }
		}
	}
	
	.listing-desc {
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		font-size: 16px;		
		color: #333333;		
		p {
			margin-bottom: 5px;
		}
	}
	
	.itemPrice {
		display: block;
		margin: 5px 0 15px;
		text-align: center;
		.price {	
			font-size: 18px;
			color: #444;		
			@media (max-width: 1279px) {
				font-size: 14px;
			}				
		}
	}

	.linkList {
		font-size: 0.875em;
		margin: 15px 0px 0px 0px;
		
		li {
			padding: 5px 0px 5px 0px;
		}
			
	}

	.itemSku {
		display: block;
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: 300;
		color: #818181;
		@media (max-width: 1279px) {
			font-size: 12px;
        }			
	}
	
	.timeStamp {
		font-size: 0.875em;
	}
	
	.itemMemo {
		font-size: 0.875em;
		
		.inlineList {
			font-size: 1em;
		}
		
		p:last-child {
			margin-bottom: 0;
		}
	}

	.itemCO {
		padding: 3px 5px;
	}

	.reviewItem {
		margin: 0 0 8px 0;
		padding: 0;
		border: 0;
	}
    
	.inlineList {
		font-size: 0.938em;
	}
	
    .btn,
    .btnText,
    .btnTextAction {
        font-size: 0.933em;
		padding: 9px 20px 10px 25px;
		@media (max-width: 1279px) {
			padding: 5px 5px 6px 10px;
		}		
    }
    
    .btnTextAction:before {
        width:8px;
        height:8px;
        padding:5px;
        color: $white;
        text-align: center;
        font-size: 7px;
        background-color: $color-action;
        border-radius: 14px;
    }
    
    .itemExtra {
        margin: 10px 0px;
    }
	
	.roc-itemTools {
		position: absolute;
		top: 10px;
		right: 10px;
		display: none;
	}
	
	.itemAction {
		.contentBox-note {			
			.contentBoxInterior {
				padding: 10px 15px;
			}
		}
	}
}

.productAddToCart .itemPrice .price {
	color: #fff;
}

.productList {
	.itemSwatches {
		display: none;
	}
	.reviewItem  {
		margin: 25px 0;
		text-align: center;
		.ratingDisplay {
			float: none;
			display: table;
			width: 100%;
			text-align: center;
			.ratingStatic {
				vertical-align: middle;
			}
			.nm-reviews {
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
				color: #e4701e;
			}
		}
	}
	.itemExtra {
		.sale {
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			font-weight: 400;
			color: #333333 !important;
		}
	}
	.itemButtons {
		.btn {
			padding: 10px 17px 8px 25px;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 1px;
			width: 100% !important;
			@media (max-width: 1279px) {
				padding: 10px 7px 8px 15px;
				font-size: 10px;
			}				
			&:hover,
			&:focus {
				color: #fff !important;
			}
			.icon {
				vertical-align: top;
				margin-top: 3px;
				@media (max-width: 1279px) {
					margin-top: 1px;
				}					
			}
		}
	}	
	.itemCompare {
		display: block;
		margin: 25px 0 10px;
		font-size: 14px;
		font-weight: 500;
		color: #333333;		
		text-transform: uppercase;
		@media (max-width: 1279px) {
			font-size: 12px;
		}		
		input {
			&.ckbItemCompare {
				display: inline-block;
				vertical-align: top;
				margin-right: 8px;
				margin-top: 3px;
				@media (max-width: 1279px) {
					margin-top: 1px;
				}					
			}
		}
		label {
			display: inline-block;
			vertical-align: top;
		}
	}
}

.department-list {
	.itemWrapper {
		&:after {
			display: none !important;
		}
	}
	.itemWrapper {
		.itemTitle {
			font-size: 24px;
			text-transform: uppercase;
			margin-bottom: 7px !important;
			a {
				color: #0a4e8c;
				font-weight: 400;
			}		
		}
		.linkList {
			font-size: 16px;
			line-height: 23px;
			margin: 0;
			text-transform: uppercase;
			a {
				color: #818181;
				font-weight: 300;
				display: inline;
				box-decoration-break: clone;
				transition: all 0.5s ease-in-out;
				position: relative;
				background-image: linear-gradient(to bottom, rgba(129, 129, 129, 0.3), rgba(129, 129, 129, 0.3));
				background-size: 0 1px;
				background-position: 0 100%;
				background-repeat: no-repeat;
				&:focus,
				&:hover {
					text-decoration: none;
					background-size: 100% 1px;						
				}				
			}
			li {
				padding: 7px 0;
				@media (max-width:1279px) {
					padding: 3px 0;
					line-height: 17px;
				}				
				&+li {
					&.view-all-dept {
						display: none;
					}
				}			
				&+li {				
					&+li {
						&+li {
							&+li {
								display: none;
								&+li {
									&.view-all-dept {
										display: block;
										margin-top: 7px;									
										a {
											span {
												&.more {
													font-size: 16px;
													font-weight: 600;
													color: rgb(10, 78, 140);
													@media only screen and (max-width:1279px) {
														font-size: 13px;
													}													
													span {
														&.depmore {
															padding-left: 3px;
															display: inline;
															box-decoration-break: clone;
															transition: all 0.5s ease-in-out;
															position: relative;
															background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
															background-size: 0 1px;
															background-position: 0 100%;
															background-repeat: no-repeat;															
														}														
													}
												}											
											}
											&:focus,
											&:hover {
													text-decoration: none;
													background-size: 0;													
													span {
														&.depmore {
															text-decoration: none;
															background-size: 100% 1px !important;																
														}														
													}																		
											}												
										}
									}
								}
							}
						}
					}
				}
			}				
		}		
	}	
}

/* ## micro list */

.productList.list-micro {
	margin-top: 0;
	padding: 0;
	border: none;
	
	.itemWrapper:first-child {
		margin-top: 0;
		padding-top: 0;
	}
	
	.itemWrapper:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	
	.itemWrapper {
        float: none;
        display: table;

		.media,
		.itemImageWrapper {
			width: 60px;
		}

        .media,
        .itemContent {
            display: table-cell;
            height: auto;
            min-height: 0;
            vertical-align: top;
        }

		.itemContent {
			padding: 0 15px;
			
			&:last-child {
				padding-right: 0;
			}
		}
		
		.itemTitle {
			margin: 0;
			font-size: 1.125em;
			a {
				color: $color-action;
			}
		}

		.itemAction {
			text-align: right;
			border: none;
		}	
	}
}


#quickOrderProducts {
    li {
        
        &.item {
            padding: 0px 0px !important;
            border-top: 0px;
            &:first-child {
                border-top: none;
            }
        }
    }
    
    .itemAvailability {
		display: block;
        vertical-align: top;  
		@include lg {
	        display: table-cell;		   
		}      
    }
    .product-availability {
        display: table;
        width: 100%;
        .item {  
            display: table-row;
            border: none;
            font-size: 14px !important;		
			.available-td-left {
				display: table-cell;
				vertical-align: top;
				width: 60%;
				@media (max-width: 768px) {
					width: 40%;
				}							
				label {
					line-height: 0px;
					margin-top: 8px;
					display: inline-block;
					vertical-align: top;	
					font-weight: 300;
				}							
			}	
            .available-td-right {
                display: table-cell;
                vertical-align: top;
                text-align: center;
            }                        
        }
		.item+.item {
			.available-td-left {
				padding-bottom: 3px;
			}
			.available-td-right {
				padding-bottom: 3px;
				line-height: 15px;
			}						
		}		
        label {
            vertical-align: middle;              
        }
    }
    .product-qts {
        display: table-cell;      
        padding-left: 20px;
        .item {
            border-top: none;
            margin-left: -10px;    
            padding: 2px 0px;
            &:first-child {
                position: relative;
                margin-bottom: 5px;
                padding: 0px 0px 8px 0px !important;
                &:after {
                    content: '';
                    //border-bottom: 2px dotted #333333;
                    display: block;
                    width: 100%;
                    position: absolute;
                    bottom: 0px;
                }
            }
            div {
                padding: 0px !important;
            }
        }                    
    }   
    .min-qty-all {
        display: table-cell;
        vertical-align: top;
        padding-top: 1px;
        padding-left: 10px;
        line-height: 17px;
    }
    .min-qty-table {
        display: table;
        width: 100%;
        min-width: 120px;
        .min-qty-table-tr {
            display: table-row;
        }
        .min-qty-table-td-lft {
            display: table-cell;
            vertical-align: top;
            padding: 0px 0px 4px 0px;
            width: 60%;
            &.hdr {
                //border-bottom: 2px dotted #333333;
                padding-bottom: 0px;
            }
        }
        .min-qty-table-td-rgt {
            display: table-cell;   
            vertical-align: top;       
            text-align: center;
            padding: 0px 0px 4px 0px;        
            &.hdr {
                //border-bottom: 2px dotted #333333;
                padding-bottom: 7px;            
            }   
        }   
    }
    .itemQuantity {
        margin-top: 10px;
        margin-bottom: 5px;
        select {
            display: table-cell;
            vertical-align: middle;
            margin-bottom: 0px !important;
        }
        label {
            display: block;
			text-align: right;
            font-size: 20px;
            font-weight: 400;
            padding-left: 5px;
        }
    }   
	.cart-item-table {
		display: flex;	
		width: 100%;		   
		flex-wrap: wrap;
		justify-content: center;
		@include lg {
		   justify-content: flex-start;
		   flex-wrap: nowrap;
		}
	}
	.cart-item-table-cell {
		padding-right: 15px;
		width: 100%;
		@include lg {
		   width: auto;
		}
		&:last-child {
			padding-right: 0;
		}
	} 
	.quick-order-img-desc {
		@include lg {
			width: 370px;
    		max-width: 100%;
    		text-align: left;
		}
	}
	.delivery-option-list {
		display: block;
		padding: 0;
		border: 0;
		width: auto;		
	}
	.productAddToCart .itemPrice .price {
		color: $color-text-primary;
	}
	.itemPrice {
		text-align: right;
	}
	.delivery-option-list ul li label {
		display: inline-block;
	}
	.itemDetails {
		display: inline-block;
		@include lg {
		   display: table-cell;
		}

	}
}


.delivery-option-ship-two-days {
    font-size: .85em;
    color: #e4701e;
    margin: 0;
}



/* # Listing Views */

.gridView {
    .productListInner {
        margin: 0 -10px;
		padding: 0;
		list-style-type: none;
//        display: -webkit-flexbox;
//        display: -ms-flexbox;
//        display: -webkit-flex;
//        display: flex;
//        flex-direction: row;
//        -webkit-flex-wrap: wrap;
//            -ms-flex-wrap: wrap;
//                flex-wrap: wrap;
//        -webkit-flex-wrap: wrap;
//                flex-wrap: wrap;
//        flex-direction: row;
    }
    .itemWrapper {
		display: inline-block;
		position: relative;
		width: 100%;
		margin: 0 0 40px;
		padding: 0 10px 250px;
        vertical-align: top;
		text-align: center;
		@media (max-width:479px) {
			margin: 0 0 25px;
			padding: 0 0 20px;
		}		
	
		&:nth-child(odd) {
			@media (min-width:480px) and (max-width:767px) {
				padding-right: 10px;
			}			
		}
		&:nth-child(even) {
			@media (min-width:480px) and (max-width:767px) {
				   padding-left: 10px;
			}			
		}
		@media (min-width:780px) and (max-width:1279px) {
			padding-left: 5px;
			padding-right: 5px;			
		}		
		@include lg {
			padding: 0 10px 230px;
		}		
	}
	
	.itemCallouts {
		display: block;
	}
	
	.itemAction {
		.itemExtra {
			display: block;
		}
	}
	
	/* ## 16px baseline (480px+) */
	@media only screen and (min-width:30em){
		.itemWrapper {
            // less than 33.33 due to webkit rounding error
			width: 33.25%;
		}
	}
	
	/* ## 16px baseline (768px+) */
	@media only screen and (min-width:48em){
		.itemWrapper {
			width: 33.25%;
		}
	}

	@include lg {
		.itemWrapper {
			width: 24.5%;		
		}
	}
}

.department-list {
	.itemWrapper {
		/* ## 16px baseline (479px) */
		@media only screen and (max-width:29.94em){
			display: table;
			width: 100%;
			margin: 0 0 25px;
			padding: 0 0 15px;
			border-bottom: 1px solid #d4d4d4;			
		}
		@media only screen and (max-width:1279px){
			.media {

			}
			.itemContent {
				.itemTitle {
					margin-top: -5px;
					font-size: 14px;
				}
				.linkList {
					margin-top: 5px;
					a {
						font-size: 13px;;
					}
				}
			}
		}	
	}
}	

.gridViewAlt {
	//@extend .gridView;
	
	.itemWrapper {
		width: 100%;
	}
	
	/* ## 16px baseline (480px+) */
	@media only screen and (min-width:30em){
		.itemWrapper {
            // less than 50% due to webkit rounding error
			width: 49.5%;
		}
	}
	
	/* ## 16px baseline (768px+) */
	@media only screen and (min-width:48em){
		.itemWrapper {
            // less than 33.33 due to webkit rounding error
			width: 49%;
			padding-bottom: 200px;
		}
	}

	@include lg {
		.itemWrapper {
			width: 33.25%;        
			padding-bottom: 220px;			    
		}
	}
}

.listView {	
    // 768px 

    

    @media(min-width:48em) {
    
        .productListInner {
            margin: 0;
        }

		.itemWrapper .productAddToCart {
			min-width: 300px;
			width: 300px;
		}
        
        .itemWrapper {     
			display: flex;    
			flex-wrap: wrap;  
			justify-content: center;
            margin: 0;
            padding: 20px 0;
           // border-top: 1px solid $color-border;

            &:first-child {
                border-top: 0;
            }

            .itemImageWrapper {
                width: 160px;
            }

            .itemAddon {
                padding: 0 10px;
            }

            > .media {
               
            }

            > .itemContent {
                padding: 0 20px;
				flex-grow: 1;
            }

            > .itemQty {
                width: 90px;
                padding: 0 20px;
                text-align: center;
                font-weight: bold;

                .formee {
                    margin: 0;
                }

                label {
                    font-size: 0.875em;
                    font-weight: bold;
                }

                input {
                    margin-bottom: 3px;
                }

                button {
                    font-size: 0.875em;
                }
            }

            > .itemAction {
                padding-left: 20px;
                border-left: 1px dotted $color-border;
				position: static;
				min-width: 250px;
				width: 250px;
            }

            > .itemSorting {
                display: table-cell;
                vertical-align: middle;
                width: 22px;
            }

            .itemDetails {
                padding-left: 20px;
                vertical-align: top;
            }

            .itemButtons {
                padding: 0;
                text-align: center;
            }

            .itemTitle {
                margin: 0;
                min-height: auto !important;
            }
        }
    }

	@media(min-width:75em) {
		.itemWrapper {
			flex-wrap: nowrap;
			justify-content: flex-start;
		}
	}
        
    // 767px 
    @media(max-width:47.938em) {
        .itemWrapper {            
            display: inline-block;
            position: relative;
            width: 100%;
            margin: 0 0 20px;
            padding: 0 10px;	
        }

        > .itemContent {
            display:block;
            width: auto;

            > .itemMemo {
                float: none;
            }
        }

        > .itemSorting {
            display: none;
        }

        > .itemQty {
            display: block;
            margin: 10px 0;
            text-align: left;
        }

        > .itemAction {
            display: block;
            width: auto;
            margin: 10px 0;
            padding: 0 20px;
            text-align: left;
        }
    }
}

.pagination {
	&.favorites {
		@media (max-width:767px) {
			margin-top: 20px !important;
			margin-bottom: 15px !important;
		}			
	}
	.icon-arrow {
		fill: #cccccc;
		transition: all .2s ease-in-out;
		width: 38px;
		height: 38px;	
	}
}

/* # Department Listing */

.rocModule-categories {
	margin-bottom: 0;
	
	> .rocModule-header {
		display: table;
		width: 100%;
		margin-bottom:20px;
		border-bottom:1px solid #d4d4d4;

		.title {
			margin: 0;
			padding: 0 10px 0 0;
			font-size: 1.625em;
			font-weight: bold;
			background: none;
			border: 0;

			.titleAlt {
				display: block;
				margin: 0;
				white-space: nowrap;

				span {
					color: $color-action;
				}
			}
		}
	}
}

.categoryList {
	margin-bttom: 1em;
	
	> .categoryHeading {
		display: table;
		width: 100%;
		margin-top: 0;
		margin-bottom: 35px;
		padding-top: 47px;
		border-top: 1px solid #dbdbdb;
		/* ## 16px baseline (767px) ## */
		@media only screen and (max-width:47.94em) {
			margin-bottom: 10px;
			padding-top: 15px;
		}
		@media (min-width:768px) and (max-width:1279px) {
			padding-top: 20px;
			margin-bottom: 15px;
		}		
		.title {
			margin: 0;
			padding: 0 10px 0 0;
			font-size: 24px;
			line-height: 27px;
			font-weight: 600;
			background: none;
			border: 0;
			color: #373737;
			@media only screen and (max-width:1279px){
				font-size: 20px;
				line-height: 20px;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.titleAlt {
				display: block;
				margin: 0;
				white-space: nowrap;

				span {
					color: $color-action;
				}
			}
		}
	}
	
	.itemImageWrapper {
		border: none;
	}
	
	.itemImage {
		width: auto !important;
	}
	
	.itemWrapper .itemTitle {
		margin-bottom: 5px;
	}
	
	.itemWrapper .itemTitle .itemLink:after {
		@extend %iconFont;
		@extend .icon-arrow-bracket-right:before;
		
		position: relative;
		top: 2px;
		color: $color-action;
	}
}

.categoryList-condensed .itemTitle .itemLink:after {
	display: none;
}


/* Mini Items List */

.rocModule-miniList {
	padding: 20px 20px 10px;
	border: 1px solid $color-border;
	
	.productList {
		margin: 0;
		padding: 0;
		border: none;
	}
	
	.gridView .productListInner {
        display: block;
		margin: 0 -15px;
	}
	
	.gridView {
		.itemWrapper {
			margin: 0 0 15px;
			width: 19.5%;
			padding: 0 15px;
			font-size: 0.75em;
		}
		
		/* ## 16px baseline (240px+) */
		@media only screen and (min-width:15em){
			.itemWrapper {
				width: 100%;
			}
		}
		
		/* ## 16px baseline (480px+) */
		@media only screen and (min-width:30em){
			.itemWrapper {
				width: 49.5%;
			}
		}

		/* ## 16px baseline (768px+) */
		@media only screen and (min-width:48em){
			.itemWrapper {
				width: 19.5%;
			}
		}
	}
	
	.gridViewAlt {
		.itemWrapper {
			margin: 0 0 15px;
			width: 24.5%;
			padding: 0 15px;
			font-size: 0.75em;
		}
		
		/* ## 16px baseline (240px+) */
		@media only screen and (min-width:15em){
			.itemWrapper {
				width: 100%;
			}
		}
		
		/* ## 16px baseline (480px+) */
		@media only screen and (min-width:30em){
			.itemWrapper {
				width: 49.5%;
			}
		}

		/* ## 16px baseline (768px+) */
		@media only screen and (min-width:48em){
			.itemWrapper {
				width: 25%;
			}
		}
	}
	
	.itemPrice {
		margin-bottom: 0;
	}
}

.rocWidget-miniList {
	padding: 20px 20px 10px;
	border: 1px solid $color-border;
	
	.productList {
		margin: 0;
		padding: 0;
		border: none;
	}
	
	.gridView .productListInner {
		margin: 0;
	}
	
	.gridView .itemWrapper {
		margin: 0 0 15px;
		width: 100%;
		padding: 0;
		font-size: 0.75em;
	}
}


/* ## Related Items */

.rocModule-relatedItems {
	padding: 20px 20px 10px;
	border: 1px solid $color-border;
}


/* ## Recently Viewed Items */

.recentlyViewedWrapper {
	margin: 10px 0 3%;
	overflow: hidden;
	
	.rocModule-recentlyViewed {
		margin: 0;
		padding: 15px 15px 0;
		background: #fff;
		text-align: left;
		border: none;

		.rocModule-header {
			margin-bottom: 10px;

			> .title {
				font-size: 1.25em;
				margin-bottom: 15px;
				display: block;
			}
		}

		.itemWraspper {
			width: 25%;
			margin-bottom: 0;
		}
		
		/* ## 16px baseline (480px+) */
		@media only screen and (min-width:30em){
			.itemWrapper {
				width: 150px;
			}
		}		
		
		/* ## 16px baseline (1190px+) */
		@media only screen and (min-width:74.375em){
			z-index: 2;
			
			&.affix {
				position: fixed;
				top: 70px;
				z-index: 10;
			}
			
			&.affix-bottom {
				position: absolute;
			}
		}
	}
}


/* ## Saved Items */

.saved-list {
    margin: 0;
    padding: 0;
    border: none;
    
    .itemWrapper {
        > div:first-child {
            padding-left: 0;
        }
        
        > div:last-child {
            padding-right: 0;
        }

        .buttons-wrapper {
            .btn {
                &:hover, &:focus {
                    color: darken(#07335c, 50%);
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    -moz-box-shadow: none;
                    -webkit-box-shadow: none;
                }
            }
        }
    }
    
    .itemWrapper:first-child {
        padding-top: 0;
    }
}

#savedItems {
    .buttons-wrapper {
        .btn {
            &:hover, &:focus {
                color: darken(#07335c, 50%);
                background-color: transparent;
                border: none;
                box-shadow: none;
                -moz-box-shadow: none;
                -webkit-box-shadow: none;
            }
        }
    }
}

.hawk-subControls {
	margin-top: 15px;
}

/* 16px baseline (767px) */
@media only screen and (max-width:47.94em) {	
	
	.view-all-dept-list-desktop {
		display: none !important;
	}
	
	.your-warehouse-box {
		padding: 10px 10px 10px 10px !important;
		font-size: 13px !important;
		line-height: 22px;
		.ship-to {
			span {
				display: none;
			}
		}
	}
}

.roc-pageOptions-sort {
    display: inline-block;
    padding: 3px 10px 0;

    label {
        margin:0 5px 0 0;
    }
}

.sort-link {
    display: inline;
    margin: 0;
    padding: 2px 4px;
    font-size: 0.8em;
    border-left: 1px solid #999999;

    a,
    a:visited {
        padding-right: 12px;
        text-decoration: none;
        background: url(../../images/layout/arrow_sortorder.gif) no-repeat 100% 2px;
    }

    a.down {
        font-weight: bold; background-position:100% -18px;
    }

    a.up {
        font-weight: bold;
        background-position:100% -38px;
    }

    &:last-child {
        border-left:0;
    }
}

#hawktoppager .roc-pageOptions,
#hawkbottompagerr .roc-pageOptions {
    display: block;
    margin: 0;
    
    /* ## 16px baseline (768px) m = medium ## */
//    @media only screen and (max-width:48em){
//        display: table;
//		margin-top: 15px;
//		width: 100%;
//    }

    .pagination {
        margin: 0;
        
        /* ## 16px baseline (768px) m = medium ## */
        @media only screen and (max-width:48em){
            display: table-row;
        }
    }
	
	.pageToolsWrapper {
		margin: 3px 0;
	}
}

/* ==========================================================================
   Filters
   ========================================================================== */

.filter{
	padding: 1em;
	border: 1px solid #444444;
	background: #cccccc;
}

.filter-options{
	float: left;
}

.filter-actions{
	float: right;
}


.roc-pageOptions {
    margin: 10px 0px 0px 0px;
    
    /* ## 16px baseline (768px) m = medium ## */
    
    
    .pagination {
        margin: 0;
        
        /* ## 16px baseline (768px) m = medium ## */
        @media only screen and (max-width:48em){
            display: table-row;
        }
    }
	
	.pageToolsWrapper {
		margin: 3px 0;
	}
}


/* # Filters */

.rocFavoritesListing {
	
	.roc-pageOptions {
		&.alignRight {	
			@media (max-width:767px) {
				display: table;
				width: 100%;
			}		
		}
	}

	.roc-pageOptions-count {
		&.floatLeft  {	
			@media (max-width:767px) {
				float: none !important;
				display: table;
				width: auto;
				margin-left: auto;
				margin-right: auto !important;		
				margin-bottom: 15px;
			}		
		}
	}

	.pagination {
		&.favorites {
			@media (max-width:767px) {
				display: table;
				float: none;
				margin-left: auto;
				margin-right: auto;
			}					
		}
	}
	
}

.rocFavoritesSearch-inline {
	.formee-group {
		margin-right: 15px;
		input {
			height: 41px;
		}
		span {
			&.formee-group-btn {
				button {
					height: 41px;
				}
			}
		}
	}
}

.roc-pageOptions-sort {
    display: inline-block;
    padding: 3px 10px 0;

    label {
        margin:0 5px 0 0;
    }
}

.content-in {
	margin-bottom: 10px;
}

.chooseLocation {
	.gridListAlt .item .itemContent {
		padding-bottom: 0;
	}
	.gridListAlt .item {
		position: relative;
		padding-bottom: 65px !important;
	}
	.bottom-cap {
		bottom: 10px;
		position: absolute;
		width: calc(100% - 20px);
	}
}

.listingItemTitleSku {
	.gridViewAlt &, .gridView & {
		@include sm {
			min-height: 100px;	   
		}
	}	
}



/******************************/
/*          PHASE 2          */
/****************************/

.listing-phase2 {
    #hawkresults {
        display: inline-block;
        width: 49%;

        span { font-weight: 600; }
    }

    #hawktoppager {
        display: inline-block;
        width: 50%;

        .pagination { display: none; }
    }

    .roc-pageOptions-filter {
        .roc-pageOptions-label, 
        .roc-pageOptions-filter {
            label {
                font-size: 1.15em;
                text-transform: none;
            }
        }
    }

    #hawkbottompager {
        .pagination {
            text-align: center;
            padding: 0;
            border: none;
        }

        .roc-pageOptions {
            display: none;
        }
    }
}


.product-list-item-wrapper {
    display: block;
   	width: 100%;
    padding: 15px;
//    border-left: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
}

.productList .productListInner:after, 
.productList .productListInner:before {
    display: none !important;
}

.gridView {
	>.productListInner {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 0;
		transform: inherit !important;		
		@media (min-width:768px) and (max-width:979px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media (min-width:700px) and (max-width:767px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media (min-width:540px) and (max-width:699px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media (min-width:480px) and (max-width:539px) {
			grid-template-columns: 1fr 1fr;
		}
		@media (max-width:479px) {
			grid-template-columns: 1fr;
			.product-list-item-wrapper {
				border-top: none !important;
				border: 1px solid $color-border !important;
				&:first-child {
					border-top: 1px solid $color-border !important;
				}
			}
		}		
	}
    .product-list-item-wrapper {
       // width: 25%;
        &:first-of-type, &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
            border-top: 1px solid $color-border;
        }
		border-left: none;
		border-right: 1px solid $color-border;
        &:nth-of-type(4n+1) {
            border-left: 1px solid $color-border;
        }
		.product-list-item-image,
		.product-list-item-details,
		.product-list-item-details-right {
			display: block !important;
			button {
				display: block;
				width: 100%;
			}
		}
		@media (min-width:768px) and (max-width:979px) {
			&:first-of-type, &:nth-of-type(2), &:nth-of-type(3)  {
				border-top: 1px solid $color-border;
			}
			&:nth-of-type(4) {
				border-top: none;	
			}
			border-left: none;
			border-right: 1px solid $color-border;
			&:nth-of-type(4n+1) {
				border-left: none;
			}		
			&:nth-of-type(3n+1) {
				border-left: 1px solid $color-border;
			}
		}
		@media (min-width:540px) and (max-width:699px) {
			border-left: 1px solid $color-border;
		}		
		@media (min-width:480px) and (max-width:539px) {
			border-left: 1px solid $color-border;			
		}	
        /* IE10+ specific styles go here */
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          display: inline-block;
          width: 24%;
          margin: 0;
        }		
    }
}

@media (max-width:979px) {
	[data-display-mode="grid"] {
		visibility: hidden;
		opacity: 0;
		margin: 0;
		font-size: 0;
	}
	.listing-display-option.link-icon.active {
		+[data-display-mode="grid-alt"] {
			color: #e4701e;			
		}
	}
	[data-display-mode="list"] {
		visibility: hidden;
		opacity: 0;
		margin: 0;
		font-size: 0;
	}	
	.roc-pageOptions-filter.listing-display	{
		display: none !important;
	}	
}

.gridViewAlt {
	>.productListInner {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0;
		transform: inherit !important;
		@media (min-width:700px) and (max-width:767px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media (min-width:540px) and (max-width:699px) {
			grid-template-columns: 1fr 1fr 1fr;
		}	
		@media (min-width:480px) and (max-width:539px) {
			grid-template-columns: 1fr 1fr;
		}	
		@media (max-width:479px) {
			grid-template-columns: 1fr;
			.product-list-item-wrapper {
				border-top: none !important;
				border: 1px solid $color-border !important;
				&:first-child {
					border-top: 1px solid $color-border !important;
				}
			}
		}		
	}	
    .product-list-item-wrapper {
       // width: 33%;
        &:first-of-type, &:nth-of-type(2), &:nth-of-type(3)  {
            border-top: 1px solid $color-border;
        }
		&:nth-of-type(4) {
			border-top: none;	
		}
		border-left: none;
		border-right: 1px solid $color-border;
        &:nth-of-type(4n+1) {
            border-left: none;
        }		
        &:nth-of-type(3n+1) {
            border-left: 1px solid $color-border;
        }
		.product-list-item-image,
		.product-list-item-details,
		.product-list-item-details-right {
			display: block !important;
			button {
				display: block;
				width: 100%;
			}
		}	
		@media (min-width:540px) and (max-width:699px) {
			border-left: 1px solid $color-border;
		}		
		@media (min-width:480px) and (max-width:539px) {
			border-left: 1px solid $color-border;			
		}
        /* IE10+ specific styles go here */
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          display: inline-block;
          width: 32%;
          margin: 0;
        }		
    }
}

.listView {
	padding: 0 !important;
	@media (min-width:980px) {
		.product-list-item-wrapper {
			display: table;
			width: 100%;
			border-left: 1px solid $color-border;
			border-right: 1px solid $color-border;
			&:first-child {
				border-top: 1px solid $color-border;
			}
			.product-list-item-image {
				display: table-cell !important;
				vertical-align: middle;
				margin: 0 !important;
				width: 180px;
				min-height: auto !important;
			}
			.product-list-item-details {
				display: table-cell;
				vertical-align: middle;
				padding-left: 15px;
				padding-right: 15px;
			}
			.product-list-item-details-right {
				display: table-cell;
				vertical-align: middle;
				text-align: right;
				width: 180px;
			}
			.product-list-item-title,
			.product-list-item-availability,
			.product-list-item-price {
				min-height: auto !important;
			}
		}	
	}
	>.productListInner {	
		@media (max-width:979px) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 0;
			transform: inherit !important;			
			.product-list-item-wrapper {
				.product-list-item-image,
				.product-list-item-details,
				.product-list-item-details-right {
					display: block !important;
					button {
						display: block;
						width: 100%;
					}
				}
				&:first-of-type, &:nth-of-type(2), &:nth-of-type(3)  {
					border-top: 1px solid $color-border;
				}
				&:nth-of-type(4) {
					border-top: none;	
				}
				border-left: none;
				border-right: 1px solid $color-border;
				&:nth-of-type(4n+1) {
					border-left: none;
				}		
				&:nth-of-type(3n+1) {
					border-left: 1px solid $color-border;
				}
				@media (min-width:540px) and (max-width:699px) {
					border-left: 1px solid $color-border;
				}		
				@media (min-width:480px) and (max-width:539px) {
					border-left: 1px solid $color-border;			
				}				
			}
		}		
		@media (min-width:700px) and (max-width:767px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media (min-width:540px) and (max-width:699px) {
			grid-template-columns: 1fr 1fr 1fr;
		}	
		@media (min-width:480px) and (max-width:539px) {
			grid-template-columns: 1fr 1fr;
		}	
		@media (max-width:479px) {
			grid-template-columns: 1fr;
			.product-list-item-wrapper {
				border-top: none !important;
				border: 1px solid $color-border !important;
				&:first-child {
					border-top: 1px solid $color-border !important;
				}
			}
		}	
	}	
}

.gridView,
.gridViewAlt{
	>.productListInner .product-list-item-wrapper {
		&.h-auto  {
			display: flex;
			flex-direction: column;
			height: auto;
			min-height: auto !important;
			& > div,
			.product-list-item-title,
			.product-list-item-price {
				min-height: auto !important;
			}
			
			.product-list-item-details-right {
				margin-top: auto;
			}
		}
	}
}


.product-list-item-image {
    img {
        width: 220px;
    }
}

.gridView {
	.product-list-item-sku {
		font-size: .875em;
	}
}

.product-list-item-sku {
    color: $dark-gray;
    font-weight: 500;
    margin: 0 0 10px 0;
}

.product-list-item-title {
    color: $color-primary;
    font-weight: 600;
    margin: 0 0 10px 0;
	a {
		display: inline;
		box-decoration-break: clone;
		transition: all .5s ease-in-out;
		position: relative;
		background-image: linear-gradient(to bottom,rgba(10, 78, 140,.6),rgba(10, 78, 140,.6));
		background-size: 0 1px;
		background-position: 0 100%;
		background-repeat: no-repeat;
		text-decoration: none;		
		&:hover,
		&:focus {
			background-size: 100% 1px;
		}
	}
	@media (max-width:479px) {
		min-height: auto !important;
	}	
}

.product-list-item-price {
    width: 100%;
    font-weight: 500;
    margin: 0 0 10px 0;
	@media (max-width:479px) {
		min-height: auto !important;
	}
    & > span {
        font-size: 1.5em;
        font-weight: 600;
    }
}
.product-list-item-availability {
	@media (max-width:479px) {
		min-height: auto !important;
	}	
}

.product-list-item-shipping,
.product-list-item-pick-up,
.product-list-item-mfg {
    width: 100%;
    font-weight: 500;
    margin: 0 0 15px 0;

    &.disabled {
        color: #ccc;
    }

    span {
        font-weight: 600;
    }
}

.expressShipToPanel {
    .icon {
        width: 25px;
        height: 25px;
		&.icon-location-2 {
			fill: #e4701e;
			width: 17px;
			height: 17px;			
		}
	}	
}

.prop-65-warning-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
    border: 2px solid #ffdc52;
    border-radius: 10px;
}

.prop-65-warning-icon-container {
    width: 8%;
    background-color: #ffdc52;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .icon {
        width: 50px;
        height: 50px;
    }
}

.prop-65-warning-content-container {
    width: 90%;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 10px;
}

.prop-65-warning-title {
    font-weight: 700;
}

.prop-65-warning-content {
    font-size: .8em;
}

.recentlyViewedWrapper {
	#recently-viewed {
		.productList.gridViewAlt {
			.productListInner {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}
	}
}


.smc-virtual-info {
	border: none;
	display: flex;
    align-items: center;
	flex-wrap: nowrap;
    padding: 5px 0;

	.smc-virtual-info__icon {
		color: $color-button-secondary;
		font-size: 24px;
		line-height: normal;
	}
	.prop-65-warning-content-container {
		align-items: center;
		padding: 0;
		width: auto;
	}
	.prop-65-warning-icon-container {
		background: transparent;
		padding: 0;
		width: auto;
		margin-right: 10px;
	}
	.prop-65-warning-title {
		font-weight: 600;
		color: #004d8e;
		line-height: normal;
	}
}


/* Ticket Details: #595355 */

.custom-hawk-list-container {
	.chkAvailableForPickupAt {
		&-container {
			position: relative;
			display: flex;
			align-items: center;
			align-content: center;
			margin-bottom: 14px;

			input {
				&[type="checkbox"] {
					/* Add if not using autoprefixer */
					-webkit-appearance: none;
					/* Remove most all native input styles */
					appearance: none;
					/* For iOS < 15 */
					background-color: #f6f6f6;
					/* Not removed via appearance */
					margin: 0;
					margin-right: 7px;
					font: inherit;
					color: currentColor;
					width: 1.15em;
					height: 1.15em;
					border: 0.15em solid #d9d9d8;
					border-radius: 0.15em;
					transform: translateY(-0.075em);
					display: grid;
					place-content: center;
					cursor: pointer;

					&::before {
						font-family: hawksearch;
						content: "\2714";
						color: #6f6f6f;
						font-size: 11px;
						line-height: 14px;
						width: 11px;
						height: 12px;
						// clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
						transform: scale(0);
						transform-origin: bottom left;
						transition: 120ms transform ease-in-out;
					}

					&:checked::before {
						transform: scale(1);
					}

					&:hover {
						&::before {
							font-family: hawksearch;
							content: "\2714";
							color: #6f6f6f;
							font-size: 11px;
							line-height: 14px;
							width: 11px;
							height: 12px;
							// clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
							transform: scale(1);
							transform-origin: bottom left;
							transition: 120ms transform ease-in-out;
						}
					}
				}
			}

			label {
				font-weight: 600;
				line-height: 20px;
				color: #e4701e;
				cursor: pointer;
			}
		}
	}
}