
/* # Favorites Toolbar */

.roc-pageOptions {
	&.favorites {
		@media (max-width:767px) {
			margin-left: auto;
			margin-right: auto;
		}		
	}
}

.pagination {
	&.favorites {
		@media (max-width:767px) {
			display: table;
			float: none;
			margin-left: auto;
			margin-right: auto;
		}					
	}
}

.roc-pageOptions-filter {
	&.favorites {
		margin-right: 30px;		
		@media (max-width:767px) {
			display: table;
			width: auto;
			margin-left: auto;
			margin-right: auto;			
		}
		label  {
			@media (max-width:767px) {
				display: table-cell;
				width: 50%;
				padding-right: 15px;
			}				
		}		
		select  {
			@media (max-width:767px) {
				display: table-cell;
				min-width: 50% !important;
			}				
		}
	}
}

.roc-favoritesToolbar {
	position: fixed;
	display: block;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	border-top: 1px solid $color-border;
	z-index: 5;
	
	.roc-moduleHeader {
		padding: 3px 0;
		background: $gray;
		
		.icon-heart-circle {
			color: $color-action;
			font-size: 18px;
			vertical-align: middle;
		}
		
		.roc-title {
			display: inline;
			font-size: 0.875em;
			margin: 0 5px;
			padding: 0 10px 0 0;
			border-right: 1px solid $color-border-dark;
		}
		
		.icon-cancel-circle {
			position: absolute;
			right: 10px;
			top: 0;
			color: $color-action;
			font-size: 22px;
			line-height: 1;
            z-index: 5;
            cursor: pointer;
		}
		
		button {
			font-size: 0.75em;
            text-decoration: none;
		}
	}
	
	.roc-moduleContent {
		padding: 10px 0;
	}
}


/* # Favorites Summary List */

.roc-favoritesSummary {
	
	.roc-widgetContent {
		display: table;
	}
	
	.roc-favoritesSummary-selection,
	.roc-favoritesSummary-list {
		display: table-cell;
		vertical-align: top;
		font-size: 0.825em;
	}
	
	.roc-favoritesSummary-list {
		padding: 0 0 0 10px;
				
		.icon-cancel-circle {
			position: absolute;
			top:-10px;
			right: -10px;
			font-size: 24px;
			line-height: 1;
			color: $color-action;
			background: #fff;
			border-radius: 12px;
			cursor: pointer;
		}
	}
	
	ul {
		display: inline-block;
		margin: 0 10px 0 0;
		padding: 0;
		list-style-type: none;
		
		li {
			position: relative;
			display: inline-block;
			margin: 5px;
		}

		/* 16px base 450px */
		@media (max-width: 28.125em) {
			li:nth-child(n+2) {
				display: none;
			}
		}
		
		/* 16px base 530px */
		@media (max-width: 33.125em) {
			li:nth-child(n+3) {
				display: none;
			}
		}
		
		/* 16px base 610px */
		@media (max-width: 38.125em) {
			li:nth-child(n+4) {
				display: none;
			}
		}
		
		/* 16px base 690px */
		@media (max-width: 43.125em) {
			li:nth-child(n+5) {
				display: none;
			}
		}
		
		/* 16px base 770px */
		@media (max-width: 48.125em) {
			li:nth-child(n+6) {
				display: none;
			}
		}
		
		/* 16px base 850px */
		@media (max-width: 53.125em) {
			li:nth-child(n+7) {
				display: none;
			}
		}
		
		/* 16px base 930px */
		@media (max-width: 58.125em) {
			li:nth-child(n+8) {
				display: none;
			}
		}
	}
}


/* ## Favorites Search Top Buttons */

.rocFavoritesSearch {
	margin: 0;
	vertical-align: top;
	
	.formee-group {
		margin: 0;
	}
}

.rocFavoritesButtons {
	margin: 0;
	padding-bottom: 1em;
	border-bottom: 1px solid $color-border;
	
	.rocFavoritesSearch {
		margin: 0 0 5px;
		vertical-align: middle;
	}
	
	.rocFavoritesSearch-inline {
		display: inline-block;
		margin: 10px 0 0;
		width: 100%;
		vertical-align: middle;
		
		/* 16px base 530px */
		@media (max-width: 33.125em) {
            margin-bottom: 5px;
        }
		
		.formee-control {
			width: auto;
			min-width: 230px;
		}
		
		.formee-group-btn {
			width: auto;
		}		
		
		&.someone {
			.formee-group {
				width: 100% !important;
				.formee-control {
					width: 100% !important;
				}				
			}			
		}
       
	}
	
	.actionLink {
		font-size: 0.825em;
	}
}

.someone {
	margin-bottom: 15px !important;
	.formee-group-btn {
		width: 65px !important;
	}	
}

/* # Favorites Listing */

.rocFavoritesRead {
	.itemContent,
	.itemQty,
	.itemAction {
		@media (max-width:767px) {
			display: table;
			width: auto !important;
			margin: 10px auto 0;
			text-align: center;
		}		
	}
}

.rocReviewsListing {
	margin-top: 0;
	&.rocModule {
		&.favorites {
			@media (min-width:768px) {
				margin-top: 0 !important;
			}			
			.roc-pageOptions {
				@media (min-width:768px) {
					margin-top: 0 !important;
				}				
			}
		}		
	}
}

.rocFavoritesListing {
	margin-top: 0;
	
	.productList {
		margin-top: 10px;
		padding-top: 10px;
		.itemWrapper {
			.itemContent {
				@media (min-width:768px) {
					text-align: left;
					display: flex;
				}	
				.media {
					@media (max-width:767px) {
						display: table;
						margin-left: auto;
						margin-right: auto;
					}					
				}
			}				
		}	
	}
	.clearance .price {
		color: #333 !important;
	}
	.itemQty {
	    min-width: 147px;
	}

	.reviewItem .ratingDisplay {
		text-align: left;
	}

	.reviewItem {
		text-align: left;
	}
	
	.reviewMini .jumpToReview {
		margin-left: 0;	
	}

	.rocModule-heading > .pageHeading {
        .title {
            display: inline;
        }
        
        .formee-group {
            margin: 0;
        }
	}
	
	.productList {
		border-bottom: none;
	}

	.pageToolsWrapper {
		float: right;
		margin: 0;
		
		// 767px 
		@media(max-width:47.938em) {
			float: none;
			margin: 10px 0;
		}
	}
}

.favoriteList .itemWrapper {
	
	.itemSku {
		margin-bottom: 2px;
	}
	
	.itemAction {
		border: none;
		
		.itemPrice {
			margin-top: 7px;
		}
		
		.itemQty {
			float: right;
			width: 50px;
			margin: 0;
			font-weight: bold;
		}
	}
	
	.itemButtons {
		padding: 0;
		border: 0;
		background: none;
	}

    .favorites-item-price-qty-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row-reverse;

        .itemQty {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .itemPrice {
            display: inline-flex;
            flex-wrap: wrap;
            margin: 0 15px 0 0;
        }
    }
}
		
.rocFavoritesRead {
	.itemWrapper {
		.itemQty {
			width: 125px;
			white-space: nowrap;
		}
	}
}



/* # Find Favorites List */

.rocFindFavoritesList {
	margin: 10px 0;
	padding: 10px 0;
	border-top: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
}

.rocFindFavoritesList .item {
	font-size: 16px;
	
	.itemHeader {
		margin-bottom: 10px;
		padding-bottom: 10px;
		color: $color-text-secondary;
		border-bottom: 1px solid $color-border;
		
		P:last-child {
			margin-bottom: 0;
		}
	}
	
	.linkList {
		li {
			padding: 0;
		}
	}
	
	.linkList a {
		display: inline-block;
		padding: 1px 0;
	}
	
	button {
		font-size: 1em;
		text-transform: uppercase;
	}
}
		