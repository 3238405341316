﻿@mixin xs {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin smd {
    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin smo {
    @media (max-width:991px) and (min-width:768px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin mdd {
    @media only screen and (max-width: 1199px) {
        @content;
    }
}

@mixin mdo {
    @media (max-width:1199px) and (min-width:992px) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin xlg {
    @media only screen and (min-width: 1400px) {
        @content;
    }
}
