/* ##  Left Nav  ## */

.railNavHeading {
	margin: 0 0 10px 0;
	font-size: 1.625em;
	font-weight: bold;
	display: none;

	span.trigger {
		display: none;
	}
	
	a {
		color: #fff;
		text-decoration: none;
		font-weight: normal;
		
		&:hover,
		&:active {
			color: #fff;
			font-weight: normal;
		}
	}
}

.railNavWrapper {
	ul {
		li {
			a {
				display: block;
				&:before {
					display: none !important;
				}
			}
			li {
				a {
					display: block;
					&:before {
						display: none !important;
					}
				}				
			}
		}
	}
}

/* If not the first rail nav heading should be smaller */
.railNavWrapper:nth-child(n+2) .railNavHeading {
	font-size: 1.375em;
}

.railNav {
	margin: 0 0 60px;
	padding: 0;
	list-style-type: none;
	font-size: 18px;
	border-top: 1px solid #c6c6c6;
	@media (max-width:767px) {
		margin-bottom: 30px;
	}
	@media (min-width:768px) and (max-width:980px) {
		font-size: 14px;
	}	
	
	a {
		display: block;
		padding: 16px 30px 16px 12px;
		color: #444444;
		font-weight: 400;
		text-decoration: none;
		text-transform: uppercase;
		@media (min-width:768px) and (max-width:980px) {
			padding: 10px 30px 10px 12px;
		}		
		.icon-arrow-bracket-right {
			position: absolute;
			font-size: 25px;
			top: 11px;
			right: 10px;
		}
	}
	
	.active > a {
		position: relative;
		font-weight: 400;
		
		&.current {
			color: #07335c;
		}
		
		/*&.current:before {
			@extend %iconFont;
			@extend .icon-arrow-bracket-right:before;
			
			position: absolute;
			left: -14px;
			font-size: 14px;
		}*/
	}
	
    li {
		position: relative;
        padding: 0px 0px 0px 0px;
		border-bottom: 1px solid #bfbfbf;
		ul {
			margin-bottom: 15px;
			margin-left: 15px;
		}
		li {
			border-bottom: none;
			position: relative;
			padding-bottom: 7px !important;
			a {
				color: #0a4e8c;
				text-transform: capitalize;
				display: inline;
				box-decoration-break: clone;
				transition: all 0.5s ease-in-out;
				position: relative;
				background-size: 0 1px;
				background-position: 0 100%;
				background-repeat: no-repeat;
				&:hover,
				&:focus,
				&.active {
					text-decoration: none;
					background-size: 100% 1px;	
				}
			}
		}
		&> {
			a {
				&:before {
					@extend %iconFont;
					@extend .icon-arrow-bracket-right:before;
					position: absolute;
					top: 9px;
					right: 10px;
					font-size: 28px;		
					-wekbit-transition: all 0.4s ease-in-out;
							transition: all 0.4s ease-in-out;	
					@media (min-width:768px) and (max-width:980px) {
						top: 4px;
						right: 5px;
						font-size: 22px;
					}							
				}
			}
		}
		&.active {
			> a {
				&:before {
					content: '\e639';			
					@media (min-width:768px) and (max-width:980px) {
						top: 4px;
					}					
				}	
				&.active {
					color: #444444;
				}
			}
		}
    }
    
	// subnav
	ul {
		margin: 0 0 5px;
		padding: 0;
		font-size: 0.875em;
		list-style-type: none;
		
		li {
			padding: 0 0 0 10px;
		}
		
		a {
			padding: 1px 0;
			font-weight: normal;
		}
	}
	
	li.verticalSpace {
		margin-top: 10px;
	}
	
	li.dividedSub {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-border;	
		
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
	}
}

/* # Nav Span */

.navSpan {
	margin: 0 0 10px;
		
	.navSpan-column {
		vertical-align: top;
		
		.title {
			margin: 0 0 5px;
			font-size: 1em;
		}
				
	}
	
	a {
		color: $color-text-secondary;
	}
	
	/* ## 16px baseline (660px) */
	/* setting up column layout on larger screens */
	@media only screen and (min-width:41.25em){
		display: table;
		width: 100%;
		
		.navSpan-content {
			display: table-row;
		}
		
		.navSpan-column {
			display: table-cell;
			padding: 0 10px;
			
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}
}

/* ## Footer Navigation ##  */

.col-sm-12 {
	&.ftr-nav {
		display: table;
		margin: 2.5% auto 0;
		width: 100%;
		max-width: 1495px;
		float: none;
		ul {
			&.linkList {
				margin-bottom: 0;
			}
		}
		.navSpan {
			margin-bottom: 0;
		}
	}
}

/* ## My catalog navigation - dropdown  ##  */

.my-catalog-selected-parent {
	position: relative;
	.my-catalog-selected {
		&:after {
			position: absolute;
			display: block;
			top: 10px;
			right: 10px;
			font-size: 28px;
			font-family: ROC-Icons;
			color: #444;
			content: "\e63a";
			speak: none;
			font-style: normal;
			font-weight: 400;
			font-variant: normal;
			text-transform: none;
			line-height: 1.42857;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-weight: 400;
			text-shadow: none;		
			cursor: pointer;
		}
		&.visible {
			&:after {
				content: "\e639";	
			}
		}
	}	
	ul {
		list-style: none;
		margin: 0;
		margin-left: 15px;	
		margin-bottom: 0 !important;
		padding: 0;
		opacity: 0;
		width: 100%;
		height: 0;	
		li {
			padding: 0;
			a {
				padding: 0;
			}
		}
		&.visible {
			opacity: 1;
			transition: .50s ease;
			-moz-transition: .50s ease;
			-webkit-transition: .50s ease;
			height: auto;
			width: 100%;
			font-size: .875em;
			margin-bottom: 15px !important;			
			li {
				padding: 0 0 0 10px;

                &:first-of-type {
                    color: #e4701e;
                }

                &:not(:first-of-type) {
                    padding: 0 0 0 30px;
                }
				a {
					padding: 1px 0;
                    text-transform: unset;

                    ul{
                        height: 100%;
                        opacity: 1;
                        margin: 10px 0 0 20px;
                    }
				}			
			}
		}			
	}		
}	


//@media (min-width:768px) and (max-width:1279px) {
//	.footer {
//		.navSpan {
//			.navSpan-content {
//				display: grid;
//				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
//				.footer-last-rail,
//				.navSpan-column {
//					&.products-nav-column,
//					&.solutions-nav-column,
//					&.services-nav-column,
//					&.location-nav-column,
//					&.contact-nav-column {
//						width: 100%;
//						padding: 0 10px;
//					}					
//				}
//			}
//		}		
//	}
//}	