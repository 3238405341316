﻿.my-account-container {

    .callout-wrapper {
        padding: 0 0 0 50px;
    }

    .callout-container {
        background-color: $color-primary-light;
        padding: 25px;
    }

    .callout-header {
        @include sm-callout-title-font;
        margin: 0 0 25px 0;
    }

    .callout-content {

        .sfContentBlock {
            margin: 0 0 15px 0;
        }

        ul {
            margin: 0;
            padding: 0 0 0 25px;
        }

        li {
            padding: 0 0 7px 0;
        }
    }
}