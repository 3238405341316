﻿.secondary-navbar-wrapper {
    width: 100%;
}

.secondary-nav {
    .cm-menu {
        .li-nav {
            display: inline-block;
            padding: 0 15px;
            cursor: pointer;

            span {
                display: inline-block;
                font-size: 1.15em;
                font-weight: 500;

                &.has-sub-anchor {
                    font-weight: 400;
                }
            }

            .has-sub-anchor:after {
                content: "\e638";
                font-family: ROC-Icons;									
				position: relative;
				top: 5px;
				display: block;
				font-size: 18px;
				font-style: normal;
				-wekbit-transition: all .4s ease-in-out;
				transition: all .4s ease-in-out;
            }

            .opened {
                .has-sub-anchor:after {
                    -webkit-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					transform: rotate(180deg);
                }
            }
        }

        .sub-menu {
            display: none;
            width: 100%;
            min-width: 290px;
            max-height: 80em;
            background-color: $site-bg;
            position: absolute;
            top: 40px;
            opacity: 0;
            padding: 15px;
            -webkit-box-shadow: 0px 7px 18px -5px rgba(0,0,0,0.56);
            -moz-box-shadow: 0px 7px 18px -5px rgba(0,0,0,0.56);
            box-shadow: 0px 7px 18px -5px rgba(0,0,0,0.56);
            -webkit-border-radius: 0;
            border-radius: 0;	
            -wekbit-transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, left 0.4s ease-in-out, right 0.4s ease-in-out;
            transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, left 0.4s ease-in-out, right 0.4s ease-in-out;
            z-index: 1;

            &.opened {
                display: block;
                opacity: 1; 
                z-index: 5;
            }

            .li-nav {
                width: 100%;
            }

            a {
                font-weight: 500;
                text-shadow: none;
            }
        }
    }
}