@font-face {
	font-family: 'ROC-Icons';
	src:url('../../fonts/ROC-Icons.eot?-vwmjqb');
	src:url('../../fonts/ROC-Icons.eot?#iefix-vwmjqb') format('embedded-opentype'),
		url('../../fonts/ROC-Icons.woff?-vwmjqb') format('woff'),
		url('../../fonts/ROC-Icons.ttf?-vwmjqb') format('truetype'),
		url('../../fonts/ROC-Icons.svg?-vwmjqb#ROC-Icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], %iconFont {
	font-family: 'ROC-Icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1.42857;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
	content: "\e606";
}

.icon-calendar:before {
	content: "\e953";
}

.icon-trash:before {
	content: "\e9ad";
}

.icon-list:before {
	content: "\e9ba";
}

.icon-warning:before {
	content: "\ea07";
}

.icon-notification-circle-hollow:before {
	content: "\ea08";
}

.icon-question-circle-hollow:before {
	content: "\ea09";
}

.icon-plus:before {
	content: "\ea0a";
}

.icon-info-circle-hollow:before {
	content: "\ea0c";
}

.icon-spell-check:before {
	content: "\ea12";
}

.icon-loop:before {
	content: "\ea2d";
}

.icon-youtube:before {
	content: "\ea97";
}

.icon-newspaper:before {
	content: "\e602";
}

.icon-file:before {
	content: "\e603";
}

.icon-copy:before {
	content: "\e604";
}

.icon-tag:before {
	content: "\e621";
}

.icon-cart:before {
	content: "\e605";
}

.icon-credit:before {
	content: "\e622";
}

.icon-phone:before {
	content: "\e609";
}

.icon-mail:before {
	content: "\e607";
}

.icon-location:before {
	content: "\e60c";
}

.icon-compass:before {
	content: "\e60d";
}

.icon-print:before {
	content: "\e623";
}

.icon-bubble:before {
	content: "\e60e";
}

.icon-bubbles:before {
	content: "\e60f";
}

.icon-user:before {
	content: "\e610";
}

.icon-users:before {
	content: "\e611";
}

.icon-search:before {
	content: "\e624";
}

.icon-zoom-in:before {
	content: "\e625";
}

.icon-zoom-out:before {
	content: "\e626";
}

.icon-expand:before {
	content: "\e627";
}

.icon-expand2:before {
	content: "\e628";
}

.icon-gift:before {
	content: "\e64b";
}

.icon-airplane:before {
	content: "\e608";
}

.icon-truck:before {
	content: "\e629";
}

.icon-globe:before {
	content: "\e60a";
}

.icon-earth:before {
	content: "\e60b";
}

.icon-flag:before {
	content: "\e643";
}

.icon-star:before {
	content: "\e62b";
}

.icon-star2:before {
	content: "\e62c";
}

.icon-star3:before {
	content: "\e62d";
}

.icon-heart:before {
	content: "\e62e";
}

.icon-heart-alt:before {
	content: "\e62f";
}

.icon-info-circle:before {
	content: "\e630";
}

.icon-play-circle-hollow:before {
	content: "\e644";
}

.icon-play:before {
	content: "\e645";
}

.icon-refresh:before {
	content: "\e64e";
}

.icon-arrow-up:before {
	content: "\e646";
}

.icon-arrow-right:before {
	content: "\e647";
}

.icon-arrow-down:before {
	content: "\e648";
}

.icon-arrow-left:before {
	content: "\e649";
}

.icon-share:before {
	content: "\e613";
}

.icon-mail-circle:before {
	content: "\e631";
}

.icon-heart-circle:before {
	content: "\e601";
}

.icon-print-circle:before {
	content: "\e64f";
}

.icon-video-circle:before {
	content: "\e650";
}

.icon-grid2:before {
	content: "\e62a";
}

.icon-grid3:before {
	content: "\e635";
}

.icon-grid4:before {
	content: "\e64c";
}

.icon-checkmark-circle:before {
	content: "\e612";
}

.icon-cancel-circle:before {
	content: "\e614";
}

.icon-plus-circle:before {
	content: "\e615";
}

.icon-minus-circle:before {
	content: "\e616";
}

.icon-cogs:before {
	content: "\e617";
}

.icon-checkmark:before {
	content: "\e618";
}

.icon-cancel:before {
	content: "\e619";
}

.icon-box-open:before {
	content: "\e61a";
}

.icon-box:before {
	content: "\e61b";
}

.icon-edit:before {
	content: "\e600";
}

.icon-reply:before {
	content: "\e61c";
}

.icon-forward:before {
	content: "\e61d";
}

.icon-dollar:before {
	content: "\e61e";
}

.icon-dollars:before {
	content: "\e61f";
}

.icon-shipping:before {
	content: "\e620";
}

.icon-pencil:before {
	content: "\e64a";
}

.icon-export:before {
	content: "\e632";
}

.icon-tools:before {
	content: "\e633";
}

.icon-help:before {
	content: "\e634";
}

.icon-menu:before {
	content: "\e636";
}

.icon-arrow-bracket-left:before {
	content: "\e637";
}

.icon-arrow-bracket-down:before {
	content: "\e638";
}

.icon-arrow-bracket-up:before {
	content: "\e639";
}

.icon-arrow-bracket-right:before {
	content: "\e63a";
}

.icon-arrow-bracket-left-large:before {
	content: "\e63b";
}

.icon-arrow-bracket-down-large:before {
	content: "\e63c";
}

.icon-arrow-bracket-up-large:before {
	content: "\e63d";
}

.icon-arrow-bracket-right-large:before {
	content: "\e63e";
}

.icon-twitter-circle:before {
	content: "\e63f";
}

.icon-facebook-circle:before {
	content: "\e640";
}

.icon-pinterest-circle:before {
	content: "\e641";
}

.icon-linkedin-circle:before {
	content: "\e642";
}

