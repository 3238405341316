/* Default Store Styles
================================================
This is the default stylesheet for the store related elements. */

/* # Header Links cart addition ## */

.headerLinks a.cartLink,
.headerLinks a.cartLink:link,
.headerLinks a.cartLink:visited {
	margin-left: 5px;
}

/* # Product Page Control */

.backToLink,
.backToHomeLink {
	display: block;
	margin: 20px 0 15px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
	@media(max-width: 767px) {
		margin-top: 10px;
		margin-bottom: 0;
	}
	a {
		font-weight: 600;
		letter-spacing: 2px;
	}
	[class^="icon-"] {
		position: relative;
		top: 2px;
		font-size: 1.125em;
	}
}

.pageControl {
	float: right;
	font-size: 0.875em;
	text-align: right;
	color: $color-text-secondary;
	a {
		font-weight: bold;
		text-transform: uppercase;
	}
	[class^="icon-"] {
		position: relative;
		top: 2px;
		font-size: 1.125em;
	}
}

/* ## homepage styles ## */

.listHomeFeatured {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px 0;
	border: 1px solid #ccc;
	.productList {
		border: none;
	}
}

/* ## Selections */

.selectionList {
	margin: 0;
	padding: 0;
	list-style-type: none;
	&:focus,
	&:active {
		outline: none;
	}
	li {
		float: left;
		display: inline;
		min-width: 14px;
		margin: 4px 8px 4px 0;
		padding: 4px 8px;
		text-align: center;
		cursor: pointer;
		-webkit-box-shadow: inset 0 0 0 1px $color-border;
		box-shadow: inset 0 0 0 1px $color-border;
		-webkit-transition: box-shadow 0.1s;
		-moz-transition: box-shadow 0.1s;
		transition: box-shadow 0.1s;
		outline: 0;
		&.selected,
		&:hover,
		&:focus {
			-webkit-box-shadow: inset 0 0 0 2px $color-border;
			box-shadow: inset 0 0 0 2px $color-border;
		}
		&.active,
		&:active {
			cursor: default;
			-webkit-box-shadow: inset 0 0 0 2px $color-action;
			box-shadow: inset 0 0 0 2px $color-action;
		}
		&.disabled,
		&[disabled] {
			position: relative;
			background-color: #eee;
			cursor: default;
			img {
				opacity: 0.5;
			}
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: url(../../images/layout/diagonal-line.png) no-repeat 50% 50%;
				background-size: 100% 100%;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		img {
			display: block;
			margin: 0 -4px !important;
			max-width: none !important;
			width: 14px !important;
			height: 14px !important;
		}
	}
}

.selectionSet {
	display: table;
	.selectionGroup {
		display: table-cell;
	}
	.selectionGroup:first-child {
		padding-right: 2em;
	}
	.formee-lbl {
		margin: 0;
		font-size: 0.838em;
		font-weight: bold;
		em {
			color: $color-text-secondary;
			font-style: normal;
			font-weight: normal;
		}
	}
}

.swatchSelection {
	display: table;
	.swatchGroup {
		display: table-cell;
	}
	.swatchGroup:first-child {
		padding-right: 2em;
	}
	.formee-lbl {
		font-size: 0.813em;
		font-weight: bold;
		em {
			color: $color-text-secondary;
			font-style: normal;
			font-weight: normal;
		}
	}
}

.enlargeSwatch {
	display: none;
}

.productDetails {
	@media (max-width: 767px) {
		margin-top: 5px;
	}
	.detailsRow {
		&.product-price-det-row {
			padding-top: 0 !important;
			margin-top: -5px !important;
		}
	}
	.col-sm-6 {
		padding-left: 0;
		padding-right: 3%;
		&+.col-sm-6 {
			padding-left: 3%;
			padding-right: 0;
		}
	}
	.titleProduct {
		margin: 0 0 25px;
		font-family: $font-stack;
		font-size: 30px;
		font-weight: 600;
		line-height: 35px;
		letter-spacing: 0;
		word-wrap: break-word;
		color: #333;
		text-transform: none;
		@media (max-width: 767px) {
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0;
		}
		.titleAlt {
			display: block;
			margin: 0;
			font-size: 0.75em;
			font-weight: normal;
			line-height: 1;
		}
	}
	.manufacturer {
		display: block;
		margin: -4px 0 0;
		font-size: 22px;
		color: #444444;
		text-transform: uppercase;
		@media (max-width: 767px) {
			margin-top: 10px;
			font-size: 15px;
			line-height: 26px;
		}
	}
	.itemSku {
		float: none;
		display: block;
		margin: 0 0 3px;
		font-family: $font-stack;
		font-size: 20px;
		font-weight: 600;
		color: #767676;		
//		@media (max-width: 767px) {
//			margin-top: 5px;
//			font-size: 14px;
//			line-height: 20px;
//			margin-bottom: 15px;
//		}
	}
	.itemBrand {
		float: none;
		display: block;
		margin: 0 0 10px;
		font-family: $font-stack;
		font-size: 18px;
		font-weight: 500;
		color: #231f20;		
//		@media (max-width: 767px) {
//			margin-top: 5px;
//			font-size: 14px;
//			line-height: 20px;
//			margin-bottom: 15px;
//		
	}
	.smc-manufacturer-inventory {
		font-family: SemplicitaPro, HelveticaNeue, "Helvetica Neue", Helvetica-Neue, Helvetica, Arial, sans-serif;
		font-size: 1rem;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: -14px 0 0;
		padding: 7px 0 10px;
		color: #333;	

		.smc-manufacturer-inventory__inner {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 5px;
			padding: 13px 18px;
			text-align: center;

			@media (min-width: 480px) {
				flex-direction: row;
			}

			strong {
				margin: 0;
				text-decoration: underline;
			}

			span {
				@media (min-width: 480px) {
					padding-top: 1px;
				}
			}

			.smc-manufacturer-inventory__inner--text {
				flex: 0 0 100%;
				margin: 0;
				font-style: italic;				
			}
		}
	}
	.ratingDisplay {
		float: none;
		display: table;
		width: 100%;
	}
	.details-main-info {
		.ratingDisplay {
			float: left;
			display: block;
			width: inherit;
			.ratingStatic {
				margin-right: 7px;
				margin-top: 1px;
			}
		}
		.jumpToReview {
			margin-right: 0 !important;
		}
		.reviewItem {
			.sep {
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}
	.reviewMini {
		@media (max-width: 767px) {
			margin-bottom: 5px;
		}
		.jumpToReview {
			margin-left: 0;
		}
	}
	.reviewItem:first-child {
		padding: 0;
		margin-bottom: 10px;
		border: 0;
	}
}

.itemSelections {
	.radioList label {
		margin-right: 7px;
	}
	ul.textLink li a,
	ul.textLink li a:link,
	ul.textLink li a:visited,
	ul.textLink li a.disabled {
		display: block;
		width: auto;
		height: 26px;
		margin-bottom: 5px;
		padding: 1px 3px;
		text-decoration: none;
		line-height: 25px;
		text-align: center;
		font-weight: bold;
		background: #f6f6f6;
		border: 2px solid #d9d9d9;
		overflow: hidden;
	}
	ul.textLink li a.disabled {
		background: #ddd;
		border: 2px solid #fff;
		color: #fff;
	}
	ul.textLink li a.disabled:hover {
		cursor: default;
		background: #ddd;
	}
	ul.textLink li a.active,
	ul.textLink li a.active:link,
	ul.textLink li a.active:visited {
		border-color: #000;
		background: #fff;
	}
	ul.textLink li a:hover,
	ul.textLink li a:active {
		background: #f9f9f9;
	}
}

.detailsRow {
	padding: 10px 0;
	/*border-top: 1px solid $color-border-light;*/
	.itemCO {
		padding: 3px 5px;
	}
	.formee a {
		font-size: 0.838em;
	}
}

.detailsRowTitle {
	display: block;
	margin-bottom: 3px;
	font-weight: bold;
	.selectionName {
		font-weight: normal;
		font-size: 0.875em;
	}
}

.details-page-description {
	display: block;
	margin: 15px 0 25px;
	font-size: 18px;
	line-height: 30px;
	font-weight: 300;
	color: #373737;
	@media (max-width: 767px) {
		margin-top: 7px;
		font-size: 16px;
		line-height: 25px;
	}
}

label.detailsRowTitle {
	@extend .detailsRowTitle;
}

.itemInventory {
	font-size: 0.938em;
}

/* item Callouts */

.itemCallouts {
	margin: 10px 0;
	padding: 0;
	text-align: left;
	color: $color-callout;
	font-size: 0.867em;
	font-weight: bold;
	list-style-type: none;
	li {
		position: relative;
		margin: 5px 0;
		padding-left: 23px;
	}
	[class^="icon-"] {
		position: absolute;
		left: 0;
		top: -4px;
		color: $color-callout;
		font-size: 1.325em;
	}
	span {
		color: $color-text-primary;
		font-weight: normal;
	}
}

/* ## Item Price */

.itemPrice .price {
	font-weight: bold;
	font-size: 1.2em;
}

.detailsRow {
	.itemPrice {
		display: table;
		.price,
		.clearancePrice {
			font-family: $font-stack;
			font-size: 40px;
			font-weight: 600;
			color: #333;
			.unit-of-measure {
				font-family: $font-stack;
				font-size: 20px;
				padding-left: 5px;
			}
		}
		.msrp {
			display: inline-block;
			margin-left: 10px;
			font-size: 14px;
			color: #868686;
			line-height: 35px;
			vertical-align: bottom;
		}
	}
	.currentPrice,
	.priceAddon {
		display: table-cell;
		vertical-align: middle;
	}
	.currentPrice {
		padding-right: 1em;
		white-space: normal;
	}
	.priceAddon {
		padding-left: 1em;
		border-left: 1px dotted darken($color-border, 10%);
		white-space: nowrap;
	}
}

.sale {
	font-weight: bold;
	color: #e4701e !important;
}

.currentPrice,
.previousPrice {
	display: inline-block;
}

.previousPrice {
	display: block;
	font-size: 0.933em;
	font-weight: bold;
	p {
		margin: 0;
	}
	.strike {
		color: $color-text-secondary;
	}
}

.clearance{
	.price{
		color: #bfbdbd !important;
	}
	.price .price-txt{
		text-decoration: line-through !important;
		// margin-right: 10px;
	}
	.price .unit-of-measure{
		margin-right: 10px;
	}
}
.list-clearance{
	display: flex;
	flex-direction: column;
}

.detail-clearance {
	display: flex;
	flex-wrap: wrap;
}
.clr-header {
	font-size: 18px !important;
	font-weight: 600;
	display: inline-block;
	width: 100%;
	color: black !important;
}
.clearancePrice {
	&, &.mini Strong {
		font-size: 18px !important;
		font-weight: 700;
		text-decoration: none !important;
		color: $color-secondary !important;
	}
}

/* ## Product Page Checkout Box */

.itemCheckoutBox {
	justify-content: center;
	width: 100%;
	margin: 1em 0;
	padding: 1em;
	background: #0a4e8c;
	border: 1px solid #0a4e8c;
	border-radius: 5px;
	@media(max-width: 767px) {
		margin: 15px 0 40px;
		padding: 30px 30px 10px;
	}
	@media (min-width:768px) and (max-width:1279px) {
		padding: 10px;
	}
	@include sm {
		display: flex;
	}
	.productListInner & {
		display: block;
	}
	.itemQuantity {
		vertical-align: middle;
		text-align: center;
		margin-right: 15px;
		@include lg {
			margin-right: 40px;
		}
		@media(max-width:767px) {
			display: block;
			margin-bottom: 20px;
			width: 100%;
			text-align: center;
		}
		@media (min-width:768px) and (max-width:1279px) {
			display: inline-block;
		}
		label {
			display: inline-block;
			font-size: 15px;
			font-weight: 500;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-right: 5;
			@media(max-width: 767px) {
				font-size: 18px;
				margin-right: 10px;
			}
			@media (min-width:768px) and (max-width:1279px) {
				margin-right: 10px;
			}
		}
		input[type="text"],
		input[type="number"] {
			width: 65px;
			height: 45px;
			margin: 0;
			text-align: center;
			border-radius: 7px;
			padding: 4px;
		}
	}
	.itemButtons {
		display: flex;
		align-items: center;
		&+.itemButtons {
			width: 35%;
			text-align: center;
			@media(max-width: 767px) {
				display: block;
				margin-right: 0;
				margin-bottom: 10px;
				width: 100%
			}
			@media (min-width:768px) and (max-width:1279px) {
				display: block;
				width: 100%;
				margin-top: 10px;
			}
		}
		@media(max-width:767px) {
			display: block;
			margin-bottom: 10px;
			width: 100%;
		}
		.itemButtons-in {
			display: block;
			text-align: center;			
			width: 100%;
			.product-list-item-add-cart {
				@media(max-width: 767px) {
					margin-bottom: 10px;
				}
				.btnAlt {
					margin-left: 15px;
					width: 363px;
					@media (min-width:980px) and (max-width:1279px) {
						width: 280px;
					}
					@media (max-width: 767px) {
						width: 100%;
						margin-left: 0;
					}
					-webkit-transform: perspective(1px) scale(1.0);
					-ms-transform: perspective(1px) scale(1.0);
					transform: perspective(1px) scale(1.0);
					&:hover,
					&:focus {
						-webkit-transform: perspective(1px) scale(1.08);
						-ms-transform: perspective(1px) scale(1.08);
						transform: perspective(1px) scale(1.08);						
					}
				}
			}
		}
		.itemExtra {
			flex: 1;
		}
		.btnAction {
			margin-right: 3%;
			@media(max-width: 767px) {
				display: block;
				margin-right: 0;
				margin-bottom: 10px;
				width: 100%
			}
		}
		.btn {
			max-width: 100%;
			&.btnAction {
				background-color: #e4701e;
				height: auto;
				font-size: 18px;
				color: #fff !important;
				text-transform: uppercase;
				margin-right: 20px;
				@media (min-width: 768px) and (max-width:1279px) {
					padding: 9px 15px 10px;
					font-size: 15px;
				}
				@include lg {
					width: 340px;
				}
				&:hover,
				&:focus {
					-moz-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					-o-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					box-shadow: 0 0 10px rgba(0, 0, 0, .3);
				}
			}
			&.btnText {
				font-size: 14px;
				font-weight: 600;
				color: #a5cff6;
				text-transform: uppercase;
				letter-spacing: 2px;
				@media(max-width: 1279px) {
					padding: 0 10px;
				}
				&:hover,
				&:focus {
					background-color: transparent;
					border-color: transparent;
					box-shadow: none;
					text-decoration: none;
					color: #e4701e !important;
				}
			}
		}
	}
	.restrictions {
		font-weight: 600;
		color: #ef8d49;
	}
	.discontinued {
		font-weight: 600;
		color: #ef8d49;
		@include sm {
			white-space: nowrap;
			position: relative;
			left: -20px;
		}
	}
	.delivery-option-list {
		margin-top: 20px;
	}
}

/* ## Product Page Images */

.productImage {
	position: relative;
	display: table;
	margin: 0 auto;
	border: none;
	text-align: center;
	img.mainImage {
		display: block;
		width: 100% !important;
		max-width: 100% !important;
		height: 100%;
		max-height: 468px !important;
	}
	.roc-itemTools {
		position: absolute;
		top: 10px;
		right: 20px;
		z-index: 6;
	}
}

.productImage .btnEnlarge {
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
}

//.product-image-controls {
//	margin: 0;
//	text-align: center;
//	background-color: transparent;
//	border: none;
//	height: 49px;
//	@media(max-width: 767px) {
//		display: none;
//	}
//	>span {
//		display: inline-block;
//		margin: 0 0.5em;
//		padding: 3px;
//	}
//	[class^="icon-"] {
//		color: #0a4e8c;
//		cursor: pointer;
//		font-size: 28px;
//		&:hover,
//		&:focus,
//		&:active {
//			color: #e4701e;
//		}
//	}
//}

.product-image-controls {
	position: absolute;
	width: 25px;
	height: 25px;
    right: 40px;
    bottom: 30px;
	margin: 0;
	background-color: rgba(255, 255, 255, 0);
	z-index: 91;
	&.not-in-yet {
		visibility: hidden;
		overflow: hidden;
	}
	@media(max-width:767px) {
		right: 15px;
	}				
	.icon-refresh {
		display: none;
	}
	>span {
		margin: 0;
		padding: 0;
		&.icon-expand {
			display: none;
			&:before {
				display: none;
			}
		}
		&.icon-zoom-in {
			width: 25px;
			height: 25px;
			opacity: 1;							
			&.zoom-in-on {
				width: 0;
				height: 0;
				opacity: 0;
				font-size: 0;
			}						
		}
		&.icon-zoom-out {
			width: 0;
			height: 0;
			opacity: 0;						
			&.zoom-in-on {
				width: 25px;
				height: 25px;
				opacity: 1;
				margin-right: 0;
			}						
		}					
		&.icon-zoom-in,
		&.icon-zoom-out {
			&:before {
				display: none;
			}					
		}
		&.icon-zoom-in {
			&.zoom-in-on {
				.icon-zoom-in {
					width: 0;
					height: 0;
				}
			}
		}		
		svg.icon-zoom-in {
			width: 25px;
			height: 25px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			margin-top: 2px;
			fill: #0a4e8c;
			//transition: all 0.9s ease-in-out;
			cursor: pointer;
			&:before {
				display: none;
			}
//			&:hover,
//			&:focus {
//				-webkit-transform: scale(1.05);
//				-ms-transform: scale(1.05);
//				transform: scale(1.05);				
//			}
		}
		svg.icon-zoom-out {
			width: 25px;
			height: 25px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			margin-top: 2px;
			fill: #0a4e8c;
			//transition: all 0.9s ease-in-out;
			cursor: pointer;
			&:before {
				display: none;
			}
//			&:hover,
//			&:focus {
//				-webkit-transform: scale(1.05);
//				-ms-transform: scale(1.05);
//				transform: scale(1.05);					
//			}			
		}					
	}
}

ul.altViews {
	@include clearfix();
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		float: left;
		display: block;
		margin: 0;
		img {
			border: 1px solid #c9c9c9;
//			@media(max-width: 767px) {
//				width: 60px !important;
//			}
//			@media (min-width:767px) and (max-width:1279px) {
//				width: 70px !important;
//			}
		}
		.active img {
			border: 1px solid #e4701e;
		}
	}
	li.last {
		margin-right: 0;
	}
}

.enlarge-view-modal {
	.roc-productImage-large {
		min-height: 250px;
		margin: 0 0 15px;
		background: url(../../images/ajax-loader.gif) no-repeat 50% 50%;
		>img {
			width: 100%;
		}
	}
}

/* ## Enlarge View */

.enlargeView {
	.mainImage {}
	.thumbnails {
		text-align: center;
	}
	.altViews li {
		float: none;
		display: inline-block;
	}
}

/* Item added to cart */

#item-dialog {
	display: none;
	background: #fff;
	border: solid 2px #005e8f;
	position: absolute;
	top: 176px;
	left: 626px;
	width: 320px;
	z-index: 5000;
}

.hdng-item-dialog {
	padding: 6px 0;
	text-align: center;
	font-size: 1.4em;
	background-color: #ecf1f8;
	border-bottom: solid 1px #ccd1d8;
}

.item-dialog-content {
	padding: 6px 0 8px 0;
	margin: 0 12px;
}

.item-dialog-details {
	color: #444;
}

.errorWrap {}

.errorWrap input,
.errorWrap select {
	color: #881414;
	background: none repeat scroll 0 0 #FCD5D5;
	border-color: #881414;
}

.userMessage {
	width: 46%;
	padding: 10px 15px;
	margin-bottom: 10px;
	background: #E0E0E0;
	border: 1px solid #c7c7c7;
}

/* faq */

ul.faqLinks {
	margin: 5px 0 10px 15px;
	padding: 0;
	list-style-type: none;
}

ul.faqLinks li {
	padding-bottom: 5px;
}

dl.faqList {}

dl.faqList dt {
	font-weight: bold;
	margin-bottom: 3px;
}

dl.faqList dd {
	position: relative;
	padding-left: 20px;
	margin: 0 0 7px 0;
}

dl.faqList .answerLabel {
	position: absolute;
	top: 0;
	left: 0;
	font-weight: bold;
}

/* site tags */

.tagsWrapper {
	padding: 5px;
	border: 1px solid #ccc;
}

.tagsWrapper .taghdng {
	font-weight: bold;
	color: #000;
	text-decoration: none;
}

.tagList {
	display: inline;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.tagList li {
	display: inline;
	margin: 0 2px;
}

.tagList a,
.tagList a:link,
.tagList a:visited {
	color: #666;
}

.addTag-trigger {
	text-decoration: underline;
	cursor: pointer;
}

.addTagWrapper {
	display: none;
}

.hTagcloud .tagList {
	display: block;
	margin: 0 auto;
	text-align: center;
}

.tagList .popular1 {}

.tagList .popular2 {
	font-size: 1.2em;
}

.tagList .popular3 {
	font-size: 1.4em;
}

.tagList .popular4 {
	font-size: 1.6em;
}

.tagList .popular5 {
	font-size: 1.8em;
}

/* # Item Tools */

.roc-itemTools {
	text-align: left;
	font-size: 14px;
	.roc-itemTools-option {
		position: relative;
		margin-bottom: 1em;
		padding-right: 15px;
		@include clearfix;
	}
	.roc-itemTools-header {
		position: relative;
		display: block;
		width: 0;
		float: right;
		height: 28px;
		color: #fff;
		white-space: nowrap;
		background: $color-action;
		box-sizing: border-box;
		overflow: hidden;
		cursor: pointer;
		z-index: 2;
		/*transition: all .1s linear;*/
		a {
			color: #fff;
		}
		span {
			display: block;
			padding: 3px 25px 0 10px;
		}
	}
	.roc-itemTools-menu {
		position: absolute;
		top: 15px;
		right: 0;
		width: 100%;
		max-height: 0%;
		background: #fff;
		border: 0;
		box-sizing: border-box;
		overflow: hidden;
		/*transition: max-height .1s linear;*/
		.roc-itemTools-menuContent {
			padding: 20px 10px 0;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			padding: 5px 0;
			>button {
				color: $color-action;
				font-weight: normal;
				text-align: left;
				text-decoration: none;
			}
		}
		li.roc-divider {
			margin-top: 5px;
			padding: 10px 0;
		}
		.roc-divider {
			border-top: 1px solid $color-border;
		}
	}
	[class*="icon-"] {
		position: absolute;
		display: inline-block;
		top: 0;
		right: 0;
		padding: 6px;
		font-size: 14px;
		color: #fff;
		text-align: center;
		background: $color-text-secondary;
		z-index: 2;
		border-radius: 15px;
		line-height: 1;
		cursor: pointer;
		border: 1px solid $color-text-secondary;
		&.status-active {
			color: $color-action;
			background-color: #fff;
			border-color: $color-action;
		}
		&:before {
			display: inline-block;
			width: 14px;
		}
		/* ## 16px baseline (0 - 767px) */
		@media only screen and (max-width:47.938em) {
			padding: 10px;
			border-radius: 18px;
		}
	}
	.roc-active {
		z-index: 3;
		.roc-itemTools-header {
			width: 100%;
		}
		.roc-itemTools-menu {
			display: block;
			max-height: 230px;
			border: 1px solid $color-border-dark;
			-webkit-box-shadow: 0 1px 3px 0 $color-border;
			box-shadow: 0 1px 3px 0 $color-border;
			overflow: auto;
		}
	}
	.roc-itemTools-option:hover [class*="icon-"],
	.roc-active [class*="icon-"] {
		color: $color-text-secondary;
		background-color: #fff;
		border-color: $color-border-dark;
		&.status-active {
			color: $color-action;
			background-color: #fff;
			border-color: $color-action;
		}
	}
}

.roc-addToCartFavoritesWrapper {
	display: inline-block;
	position: relative;
	>button {
		padding: 3px 10px;
		border-radius: 0;
		&:focus {
			outline: 0;
		}
	}
	.roc-addToCartFavoritesMenu {
		position: absolute;
		top: 100%;
		right: 0;
		background: #fff;
		border: 1px solid $color-border;
		-webkit-box-shadow: 0 1px 3px 0 $color-border;
		box-shadow: 0 1px 3px 0 $color-border;
		overflow: hidden;
		z-index: 10;
		font-size: 14px;
	}
	ul {
		margin: 0;
		padding: 0 10px;
		list-style-type: none;
		li {
			padding: 5px 0;
			>button {
				color: $color-action;
				font-weight: normal;
				text-align: left;
				text-decoration: none;
			}
		}
		li.roc-divider {
			margin-top: 5px;
			padding: 10px 0;
		}
		.roc-divider {
			border-top: 1px solid $color-border;
		}
	}
}

/* # Page Description / SEO Text Styling */

.pageDescription {
	padding: 25px 0 0;
	font-size: 0.875em;
	border-top: 1px solid $color-border;
	.title {
		margin-bottom: 0.455em;
		font-size: 1.571em;
	}
}

/* ## Product Comparison Styles */

.product-compare {
	overflow: auto;
}

.product-compare-table {
	display: table;
	width: 100%;
	background-color: #fff;
	border-collapse: collapse;
	@media (min-width: 48.000em) {
		table-layout: fixed;
	}
	.item-row,
	.detail-row {
		display: table-row;
	}
	.column {
		display: table-cell;
		vertical-align: middle;
		border: 1px solid #d8d8d8;
		padding: 12px 10px;
		text-align: center;
		word-wrap: break-all;
		&:not(:first-child) {
			width: 20%;
		}
		@media (max-width:48.000em) {
			min-width: 60vw;
			padding-top: 0;
			vertical-align: top;
		}
		&.column-heading {
			background-color: #eeeeee;
			text-align: left;
		}
		>.column-heading {
			margin: 0 -10px 10px;
			padding: 5px 10px;
			font-size: 1em;
			background-color: #eeeeee;
			@media (min-width: 48.000em) {
				display: none;
			}
		}
		&:first-child {
			display: none;
			@media (min-width: 48.000em) {
				display: table-cell;
			}
		}
	}
	.item-row {
		.column {
			padding: 10px;
			vertical-align: top;
		}
	}
	.itemWrapper {
		position: relative;
		padding-top: 30px;
		.itemImageWrapper {
			border: 0;
		}
		.itemButtons {
			display: none;
		}
		.reviewItem {
			text-align: center;
			>.ratingDisplay {
				float: none;
				display: inline;
			}
		}
		.itemAction {
			position: absolute;
			top: -10px;
			left: -10px;
			right: -10px;
			padding: 5px;
			background: $gray-lighter;
			border-bottom: 1px solid $color-border;
		}
	}
}

.productList {
	img {
		&.itemImage {
			max-height: 220px !important;
		}
	}
}

/* ## Payment Icons */

.payment-icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	@each $size in 64,
	48,
	32 {
		&.payment-icon-#{$size} {
			@if $size==64 {
				width: 64px;
				height: 52px;
			}
			@else if $size==48 {
				width: 48px;
				height: 35px;
			}
			@else {
				width: 32px;
				height: 20px;
			}
			@each $type in amazon,
			americanexpress,
			delta,
			discover,
			ebay,
			maustro,
			mastercard,
			paypal,
			solo,
			stripe,
			switch,
			visa,
			visaelectron {
				// icons from: https://dribbble.com/shots/1078898-Payment-Card-Icons
				&.payment-#{$type} {
					background-image: url(../../images/icons/payment/#{$size}x#{$size}/#{$type}.png);
				}
			}
		}
	}
}

.payment-type-selection {
	.payment-icon:not(.active) {
		opacity: 0.35;
	}
}

/* ## Help */

.rocWidget-help {
	margin: 30px 0;
	.rocWidget-header {
		padding-bottom: 10px;
		border-bottom: 1px solid $color-border;
	}
	.item {
		border: none;
		margin: 20px 0;
		padding: 0;
		.content>.title,
		.content>.title>a {
			color: $color-text-secondary;
		}
		&:last-child {
			background: url(../../../assets/images/dev/placeholders/logos_security.png) no-repeat 100% 100%;
			padding-bottom: 50px;
			@include sm {
				padding-right: 200px;
				padding-bottom: 0;
			}
		}
	}
	[class^="icon-"] {
		margin-right: 8px;
		font-size: 20px;
		color: $color-text-secondary;
	}
	.icon-box {
		font-size: 24px; // needs to be bigger due to different base size;
	}
}

/* # login Modal */

#signInPopupWrapper {
	#guest-checkout {
		.btnAction {
			position: absolute;
			bottom: 0;
		}
	}
	.divider {
		position: relative;
		width: 9px;
		&:before {
			position: absolute;
			display: block;
			content: "";
			top: 0;
			bottom: 0;
			left: 50%;
			width: 1px;
			background-color: $color-border;
		}
	}
}

.delivery-option {
	.btn {
		padding: 0;
		&:hover,
		&:focus {
			box-shadow: none;
			background-color: transparent;
		}
		&.btnTextAction {
			margin-left: 45px;
		}
	}
}

/* # Product Delivery Options */

.delivery-option-list {
	display: table;
	width: 100%;
	//border-top: 1px solid #ccc;
	//padding-top: 23px;
	>ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		text-align: left;
		>li {
			position: relative;
			padding: 0;
			/*border-bottom: 1px solid $color-border;*/
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	ul li {
		color: #AAAAAA;
		display: block;
		position: relative;
		text-align: center;
		@include lg {
			text-align: left;
		}
	}
	ul li input[type=radio] {
		/*position: absolute;
		visibility: hidden;*/
	}
	ul li label {
		/*display: block;*/
		position: relative;
		font-weight: 300;
		font-size: 1.35em;
		/*padding: 0 0 0 45px;*/
		margin: 0 0 10px;
		z-index: 9;
		cursor: pointer;
		-webkit-transition: all 0.25s linear;
		strong {
			&.title {
				margin: 0;
				font-size: 18px;
				color: #202020;
			}
		}
		span {
			&.ship-when {
				display: block;
				font-family: 'Lato', sans-serif;
				font-size: 18px;
				font-weight: 300;
				color: #202020;
				span {
					&.ship-date {
						font-weight: 600;
					}
				}
			}
		}
	}
	ul li:hover label {
		color: #202020;
	}
	ul li .check {
		display: block;
		position: absolute;
		border: 1px solid #c6c6c6;
		border-radius: 100%;
		height: 22px;
		width: 22px;
		top: 6px;
		left: 0;
		z-index: 5;
		transition: border .25s linear;
		-webkit-transition: border .25s linear;
	}
	ul li:hover .check {
		border: 1px solid #c6c6c6;
	}
	ul li .check::before {
		display: block;
		position: absolute;
		content: '';
		border-radius: 100%;
		height: 9px;
		width: 9px;
		top: 6px;
		left: 6px;
		margin: auto;
		transition: background 0.25s linear;
		-webkit-transition: background 0.25s linear;
	}
	input[type=radio]:checked~.check {
		border: 1px solid #c6c6c6;
	}
	input[type=radio]:checked~.check::before {
		background: #0a4e8c;
	}
	input[type=radio]:checked~label {
		color: #0a4e8c;
	}
	.delivery-option {
		display: inline-block;
		@include lg {
			display: table;
			width: 100%;
		}
	}
	.addon {
		display: table-cell;
		vertical-align: top;
		padding-top: 0.3em;
		&:first-child {
			padding-right: 5px;
		}
	}
	.content {
		display: table-cell;
		vertical-align: top;
		input {
			margin-right: 15px;
		}
		.title {
			font-size: 18px;
			>span {
				font-weight: normal;
			}
		}
		>button {
			font-size: .75em;
		}
		button {
			&.selectExpressShipToBtn {
				display: none;
			}
		}
	}
}

.delivery-location-list {
	max-height: 150px;
	overflow-y: auto;
	label>span {
		font-weight: normal;
	}
}

.department-list {
	.department-item-wrapper {
		.itemTitle {
			position: relative;
			.icon-arrow-bracket-right {
				display: table-cell;
				font-size: 25px;
				&:before {
					position: absolute;
					top: -5px;
				}
			}
		}
		&.itemWrapper {
			.media {
				margin-top: 5px;
			}
		}
		.department-more-link {
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			font-weight: 400;
			color: #002f65;
			cursor: pointer;
			.depmore {
				&:after {
					content: url('../../images/layout/depmore.gif');
					position: absolute;
					margin: 1px 0px 0px 4px;
				}
			}
		}
	}
}

h2 {
	&.title-inventory {
		position: relative;
		display: inline-block;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 18px;
		font-weight: 500;
		text-transform: none;
		margin: 5px 0 8px;
		padding: 0;
		&:after {
			position: absolute;
			right: -10px;
			display: inline-block;
			content: "*";
			font-family: Montserrat,Arial,sans-serif;
			font-size: 22px;
			color: #ff0000;
			border: none;
		}		
	}			
}

.delivery-option-list {

	.delivery-options-mfg {
		font-family: $font-stack;
		width: 100%;
		position: relative;
		display: block;
		margin: 0 !important;
		padding: 7px 0 !important;
		color: #333;
		li {
			display: block;
			margin: 0 !important;
			padding: 0 !important;		
			background-color: #fff !important;
			border: none !important;
			text-align: left;
			>.delivery-option {
				margin: 3px 0 !important;
				border: 1px solid #ccc !important;
			}
			label {
				position: relative;
				display: block;
				margin: 0 !important;
				padding: 13px 18px;
				font-family: $font-stack;
				font-size: 16px !important;
				font-weight: 400 !important;
				line-height: 21px;
				color: #333 !important;
				-wekbit-transition: all 0.9s ease-in-out;
				transition: all 0.9s ease-in-out;			
				strong {
					font-family: $font-stack;
					font-size: 16px !important;
					font-weight: 400 !important;
					line-height: 24px;
					color: #333 !important;
				}
				&:before {
					position: absolute;
					content: '';
					bottom: 0;
					left: 0;
					width: 0;
					height: 3px;
					background-color: #e26f1e;
					transform-origin: left;
					-wekbit-transition: all 0.9s ease-in-out;
					transition: all 0.9s ease-in-out;	
					z-index: 1;				
				}
				&:hover,
				&:focus {
					background-color: #dfdfdf;
					&:before {
						width: 100%;
					}
				}
			}
			input {
				&[type="radio"] {
					width: 0 !important;
					height: 0 !important;
					margin: 0 !important;
					padding: 0 !important;
					visibility: hidden;
					position: absolute;
					&:checked {
						+label {
							background-color: #dfdfdf;
							&:before {
								width: 100%;
							}						
						}
					}
				}
			}		
		}
	}

	.delivery-options-pick-delivery {
		display: table;
		margin: 20px 0 20px;
		border-top: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea;
		padding-bottom: 20px;
		padding-top: 15px;
		position: relative;
		width: 100%;
		li {
			display: table-cell;
			vertical-align: top;
			width: 58%;
			text-align: left;
			@media (min-width: 768px) and (max-width: 980px) {
				width: 50%;
			}
			@media (max-width: 480px) {
				display: block;
				width: 100%;
			}			
			label {
				position: relative;
				display: block;
				margin: 0 !important;
				padding: 23px 18px 13px 63px;
				font-family: $font-stack;
				font-size: 16px !important;
				font-weight: 400 !important;
				line-height: 21px;
				color: #333 !important;
				-wekbit-transition: all 0.9s ease-in-out;
				transition: all 0.9s ease-in-out;	
				text-align: left;
				strong {
					display: block;
					font-family: $font-stack;
					font-size: 18px !important;
					font-weight: 600 !important;
					line-height: 24px;
					color: #333 !important;
					margin-bottom: 4px !important;
					+span {
						position: relative;
						padding-left: 25px;
						>span {
							background-color: #e76e00;
							border-radius: 50px;
							position: absolute;
							display: table;
							top: 2px;
							left: 0;
							width: 16px;
							height: 16px;
							>span {
								display: table-cell;
								vertical-align: middle;
								text-align: center;
							}							
						}
						.icon {
							&.icon-outofstock-2 {
								position: absolute;
								top: 2px;
								left: 0;
								fill: #999;
								transition: all 0.9s ease-in-out;
								width: 16px;
								height: 16px;
								display: inline-block;								
							}
							&.icon-instore-2 {
								fill: #fff;
								display: table;
								margin: 0 auto;
								transition: all 0.9s ease-in-out;
								width: 10px;
								height: 8px;
							}							
						}
					}
				}
				&:before {
					position: absolute;
					content: '';
					bottom: 0;
					left: 0;
					width: 0;
					height: 3px;
					background-color: #e26f1e;
					transform-origin: left;
					-wekbit-transition: all 0.9s ease-in-out;
					transition: all 0.9s ease-in-out;	
					z-index: 1;				
				}
				&:hover,
				&:focus {
					//background-color: #dfdfdf;
					&:before {
						width: 98%;
					}
					.icon {
						-webkit-transform: scale(1.15);
						-ms-transform: scale(1.15);
						transform: scale(1.15);	
					}
				}
			}
			input {
				&[type="radio"] {
					width: 0 !important;
					height: 0 !important;
					margin: 0 !important;
					padding: 0 !important;
					visibility: hidden;
					position: absolute;
					&:checked {
						+label {
							//background-color: #dfdfdf;
							&:before {
								width: 98%;
							}						
						}
					}
				}
			}
			.icon-pickup {
				position: absolute;
				display: inline-block;
				top: 22px;				
				left: 0;
				z-index: 91;
				.icon {
					fill: #0a4e8c;
					transition: all 0.9s ease-in-out;
					width: 47px;
					height: 50px;
					display: inline-block;	
				}				
			}
			.icon-delivery {
				position: absolute;
				display: inline-block;
				top: 25px;				
				left: 0;
				z-index: 91;
				.icon {
					fill: #0a4e8c;
					transition: all 0.9s ease-in-out;
					width: 47px;
					height: 50px;
					display: inline-block;	
				}				
			}
			.delivery-option {
				+.btnText {
					padding: 0;
					margin: 0 0 0 63px;
					display: inline;
					box-decoration-break: clone;
					transition: all 1s ease-in-out;
					position: relative;
					background-image: linear-gradient(to bottom, rgba(0, 77, 142, 0.6), rgba(0, 77, 142, 0.6));
					background-position: 0 100%;
					background-size: 100% 1px;
					background-repeat: no-repeat;
					background-color: transparent;
					border: none;		
					text-decoration: none;
					font-family: $font-stack;
					font-size: 16px;
					font-weight: 500;
					color: #0a4e8c;
					letter-spacing: 0;
					text-transform: none;
					text-align: left;
					&:hover,
					&:focus {
						background-size: 0 1px;
						background-repeat: no-repeat;
						background-color: transparent;
						border: none;
						box-shadow: none;
					}
				}
				display: table;
				width: 100%;
			}
		}
	}
	.delivery-options-mfg {
		label {
			&:hover {
				background-color:transparent!important;
			}
		}
		div.delivery-option {
			border:none!important;
			background:transparent;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.view-manufacturer-inv {
			border:none;
			background:transparent;
		}
		.live-inventory-wrapper {
			strong {
				color: black!important;
				font-size: 1em!important;
				font-weight: 700!important;
				line-height: inherit;
				text-align: center;
				text-decoration: underline;
				padding: 0;
			}
			cursor: pointer;
			p {
				margin:0;
				margin-top: 5px;
				color: black;
				font-style:italic;
			}
			.ship-update-text {
				text-align: center;
			}
		}
	}
	.view-all-in-button {
		width: auto;
		padding: 10px 80px;
		margin-top: 20px;
		border: 2px solid #e4701e;
		float:left;
		position:relative!important;
		transform:none;
		margin-bottom: 10px;
		span {
			display:block;
			transition:0.3s;
			color:black;
		}
		&:hover {
			transform:none;
			&:after {
				right:30px;
				transition: .3s;
			}
		}
		&:after {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='200' zoomAndPan='magnify' viewBox='0 0 150 135.000003' height='180' preserveAspectRatio='xMidYMid meet' version='1.0'%3E%3Cdefs%3E%3CclipPath id='01e6438af0'%3E%3Cpath d='M 10.867188 4.019531 L 139.300781 4.019531 L 139.300781 129.988281 L 10.867188 129.988281 Z M 10.867188 4.019531 ' clip-rule='nonzero'/%3E%3C/clipPath%3E%3CclipPath id='58e2582b86'%3E%3Cpath d='M 10.867188 4.019531 L 76.148438 4.019531 L 139.132812 67.003906 L 76.148438 129.988281 L 10.867188 129.988281 L 73.851562 67.003906 Z M 10.867188 4.019531 ' clip-rule='nonzero'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%2301e6438af0)'%3E%3Cg clip-path='url(%2358e2582b86)'%3E%3Cpath fill='%23e4701e' d='M 10.867188 4.019531 L 139.300781 4.019531 L 139.300781 129.988281 L 10.867188 129.988281 Z M 10.867188 4.019531 ' fill-opacity='1' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			scale: .14;
			position: absolute;
			top: 0;
			width: 20px;
			height: 20px;
			right: 53px;
			transition: .3s;
		}
	}
}

.login-prompt-banner {
	font-family: $font-stack;
    background-color: #e0ebf5;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 13px 18px;
    margin: 0 0 35px;
	color: #333;
	border: 1px solid #b5cadc;
	visibility: hidden;
    
//    @media (max-width:965px) {
//        justify-content: center;
//    }	

	.login-message {
		text-transform: none;
		font-size: 16px;
		font-weight: 500;
		opacity: 0;
		-moz-transition: opacity 0.5s ease-in;
		-o-transition: opacity 0.5s ease-in;
		-webkit-transition: opacity 0.5s ease-in;
		transition: opacity 0.5s ease-in;		
		.text-login-wrapped {
			font-size: 0;
			width: 0;
			height: 0;
			opacity: 0;
			visibility: hidden;
			overflow: hidden;			
		}
	}
	&.visible {
		visibility: visible;
		.login-message {
			opacity: 1;			
		}
	}
	.login-btn {
		font-size: 0;
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;		
	}
	.btn {
		padding: 0;
        margin: 0;
		display: inline;
		box-decoration-break: clone;
		transition: all 1s ease-in-out;
		position: relative;
		background-image: linear-gradient(to bottom, rgba(0, 77, 142, 0.6), rgba(0, 77, 142, 0.6));
		background-position: 0 100%;
		background-size: 100% 1px;
		background-repeat: no-repeat;
		background-color: transparent;
        border: none;		
		text-decoration: none;
		font-family: $font-stack;
		font-size: 16px;
		font-weight: 500;
		color: #0a4e8c;
		letter-spacing: 0;
		text-transform: none;
		text-align: left;
		&:hover,
		&:focus {
			background-size: 0 1px;
			background-repeat: no-repeat;
			background-color: transparent;
			border: none;
			box-shadow: none;
		}
	}
}
.accordionModule {
	margin: 1em 0;
	.panel-accordion-links {
		display: none;
	}
	&.panel-group {
		margin: 25px 0 0;
	}
	&.accordionModule-hawkRecommendation {
		position: relative;
		top: -1px;
		margin-top: 0px;
	}
}

@media ( min-width: 768px) {
	.accordionModule {
		.panel-accordion-links {
			display: block;
			border: none;
			padding: 30px;
			background: #ededed;
			font-size: 20px;
			text-align: center;
			@media (min-width: 768px) and (max-width:1279px) {
				padding: 15px 20px;
			}
			.panel-link {
				padding: 0 16px;
				color: #0a4e8c;
				font-size: 18px;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
		.panel {
			border-left: none;
			border-right: none;
			.panel-heading {
				padding: 35px 15px 15px;
			}
			&:nth-child(2) {
				border-top: none;
			}
		}
	}
	.panel-default>.panel-heading {
		background-color: transparent !important;
	}
	.panel-title {
		a {
			display: table;
			width: 100%;
			.pannel-accordion {
				display: table-cell;
				vertical-align: middle;
			}
			.icon-carrot {
				display: table-cell;
				vertical-align: middle;
				width: 3%;
				&:before {
					content: "\e638";
					font-size: 33px;
					vertical-align: top;
					font-weight: 500;
					color: #0a4e8c;
				}
			}
			&.collapsed {
				.icon-carrot {
					&:before {
						content: "\e63a";
						font-size: 33px;
						vertical-align: top;
						font-weight: 500;
						color: #0a4e8c;
					}
				}
			}
		}
	}
}

@media ( max-width: 767px) {
	.product-accordion {
		.panel-heading {
			padding: 10px 5px !important;
		}
	}
	.panel-title {
		a {
			display: table;
			width: 100%;
			.pannel-accordion {
				display: table-cell;
				vertical-align: middle;
				font-size: 18px;
				color: #002f65;
			}
			.icon-carrot {
				display: table-cell;
				vertical-align: middle;
				width: 5%;
				padding-right: 10px;
				&:before {
					content: "\e638";
					font-size: 22px;
					vertical-align: top;
					font-weight: 500;
					color: #0a4e8c;
					line-height: 40px;
				}
			}
			&.collapsed {
				.icon-carrot {
					&:before {
						content: "\e63a";
						font-size: 22px;
						vertical-align: top;
						font-weight: 500;
						color: #0a4e8c;
						line-height: 40px;
					}
				}
			}
		}
	}
}

#productDocumentsTabWrapper {
	color: #333;
	font-family: 'Lato', sans-serif;
	font-size: 18px;
	strong {
		display: block;
		color: #333;
		font-weight: 900;
		text-transform: uppercase;
	}
	span {
		&.file-size {
			display: block;
			margin-top: -3px;
			margin-bottom: 3px;
			color: #333;
			font-size: 16px;
			font-weight: 300;
		}
		&.file-down {
			display: block;
			font-size: 16px;
			font-weight: 300;
			color: #002f65;
		}
		&.file-down-text {
			display: inline-block;
			margin-left: 5px;
			vertical-align: middle;
			font-size: 16px;
			font-weight: 900;
			color: #002f65;
		}
	}
	.icon {
		display: inline-block;
		vertical-align: middle;
		fill: #002f65;
		width: 21px;
		height: 24px;
	}
}

.productVideoTabWrapper {
	color: #333;
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	margin-bottom: 20px;
	@media (max-width: 767px) {
		margin-top: 0;
		margin-bottom: 0;
	}
	.product-video {
		@media (max-width: 767px) {
			margin-top: 25px;
		}
		h4 {
			&.title {
				display: block;
				margin: 0 0 10px;
				color: #333;
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
			}
		}
		p {
			margin: 0 0 8px;
			font-size: 18px;
		}
		ul {
			list-style: none;
			margin: 0 0 20px;
			padding: 0;
			li {
				margin: 0;
			}
		}
	}
}

.productListInner {
	.productAddToCart {
		@media (min-width: 480px) {
			position: absolute;
			width: calc(100% - 20px);
			display: block;
			left: auto;
			bottom: 0;
		}
	}
	.smc-productAddToCart{
		position: static;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding-top: 10px;
	}
}

.productAddToCartContainer {
	display: block !important;
}

.quick-order-price-qty {
	width: 230px !important;	
	margin: 0 auto;
	@include sm {
	   margin: 0;
	}
}



.quickOrderItem {
	.item-ava-min {
		flex-grow: 1;
	}
	.content {
		display: flex;
		align-items: flex-start;
		input {
			margin-top: 8px;
		}
	}
}

.quickOrderItem .productAddToCart {
	width: auto !important;
	min-width: 1px !important;
	@include smd {
		display: table;
		margin: 0 auto;
	}
}

/* =================================================================
                        SMC Electric - Phase 2 
===================================================================*/

.container-product-detail-page {
	.productDetails {
		.col-sm-6 {
			&:first-child {
				@media (min-width:980px) and (max-width:1279px) {
					width: 45%;
					padding-left: 0;
					padding-right: 15px;						
				}
				@media (max-width:979px) {
					display: block;
					width: 100%;
					padding-left: 0;
					padding-right: 0;						
				}
				@media (min-width:980px) {
					border-right: 1px solid #eaeaea;
					margin-bottom: 40px;
				}
			}
			@media (min-width:980px) and (max-width:1279px) {
				width: 55%;
				padding-left: 0;
				padding-right: 0;				
			}
			@media (max-width:979px) {
				display: block;
				width: 100%;
				padding-left: 0;
				padding-right: 0;						
			}			
		}
	}
	.breadcrumbs-product-page {
		padding: 0 10px;
		@media (min-width: 768px) {
			margin-top: 38px;	
		}		
		.page-control-wrap {
			.backToHomeLink,
			.backToLink {
				visibility: hidden;
				overflow: hidden;
				opacity: 0;
				width: 0;
				height: 0;
				@media (min-width: 768px) {
					margin: 0;	
				}
			}			
		}
		.breadcrumbs {
			min-height: 34px;
			font-family: $font-stack;
			opacity: 0;
			-moz-transition: opacity 0.5s ease-in;
			-o-transition: opacity 0.5s ease-in;
			-webkit-transition: opacity 0.5s ease-in;
			transition: opacity 0.5s ease-in;				
			&.visible {
				opacity: 1;
			}
			a {
				display: inline-block;
				vertical-align: top;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				text-transform: none;
				color: #004d8e;
				text-shadow: 1px 0 0 rgba(0, 77, 142, 0.4);
			}
			.divider {
				+span {
					display: none;
				}
				&.last-divider {
					display: none;
				}
			}
			.back-to-link  {
				position: relative;
				display: inline-block;
				vertical-align: top;
				margin: 0 13px 10px 0;
				padding-right: 15px;
				text-decoration: none;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					display: inline-block;
					width: 1px;
					height: 22px;
					background-color: #999;
				}
				.link-icon {
					display: table;
					text-decoration: none;
					.icon {
						position: relative;
						display: table-cell;
						vertical-align: middle;
						width: 14px;
						height: 22px;
						.icon-left-arrow-2 {
							position: absolute;
							left: 0;
							top: 0;
							fill: #0a4e8c;
							width: 14px;
							height: 22px;
							transition: all 0.5s ease-in-out;
						}					
					}
					.text {
						display: table-cell;
						vertical-align: middle;
						padding-left: 8px;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 23px;
						text-transform: none;
						color: #004d8e;
						text-shadow: 1px 0 0 rgba(0, 77, 142, 0.4);
						>span {
							display: inline;
							box-decoration-break: clone;
							transition: all 1s ease-in-out;
							position: relative;
							background-image: linear-gradient(to bottom, rgba(0, 77, 142, 0.6), rgba(0, 77, 142, 0.6));
							background-size: 0 1px;
							background-position: 0 100%;
							background-repeat: no-repeat;						
							text-decoration: none;
							font-size: 18px;
							font-weight: 600;
							letter-spacing: 0;
							text-transform: none;
							color: #004d8e;
							text-shadow: 1px 0 0 rgba(0, 77, 142, 0.5);						
						}
					}
				}
				&:hover,
				&:focus {
					text-decoration: none;
					background-size: 0 1px;
					.link-icon {
						.icon {
							.icon-left-arrow-2 {
								-webkit-transform: scale(1.15);
								-ms-transform: scale(1.15);
								transform: scale(1.15);
							}					
						}					
						.text {
							>span {
								background-size: 100% 1px;
							}					
						}						
					}
				}				
			}
		}
	}
	.productDetails {
		@media (min-width: 768px) {
			margin-top: 0;	
		}
	}
	.itemCheckoutBox {
		&.pdp-qty-add-to-cart {
			//justify-content: left;
			width: 100%;
			margin: 1em 0;
			padding: 0;
			background: none;
			border: none;
			border-radius: 0;
			.itemQuantity {
				&.formee {
					color: #333;
					margin-right: 0;
					text-align: left;
					width: 100%;
					@media (max-width: 767px) {
						text-align: center;
					}					
					.pdp-qty-sub-add-block {
						display: inline-block;
						vertical-align: middle;
					}
					label {
						position: relative;
						margin: 0;
						font-size: 18px;
						text-align: left;
						color: #231f20;
						width: 57px;
						height: 25px;
						vertical-align: middle;
						&:after {
							position: absolute;
							right: 10px;
							display: inline-block;
							content: "*";
							font-family: $font-stack;
							font-size: 22px;
							color: #ff0000;
							border: none;
						}						
					}
					input {
						&[type=number] {
							border: 1px solid #ccc;
							border-radius: 0;							
							width: 50px;
							height: 50px;
							&::-webkit-inner-spin-button {
							-webkit-appearance: none;
							}
						}
					}
					button {
						&.pdp-qty-sub {
							position: relative;
							display: inline-block;
							vertical-align: middle;
							border: 1px solid #ccc;
							background: none;
							border-radius: 0;							
							width: 50px;
							height: 50px;	
							@media (min-width:980px) and (max-width:1279px) {
								width: 45px;
							}							
							&:after {
								content: '';
								display: table;
								position: absolute;
								top: 23px;
								left: 17px;
								margin: 0 auto;
								width: 14px;
								height: 0;
								border-bottom: 1px solid #767676;
								transform: rotate(0);
								transition: width 0.5s ease-in-out;
							}
							&:hover,
							&:focus {
								&:after {
									-webkit-transform: scale(1.15);
									-ms-transform: scale(1.15);
									transform: scale(1.15);								
								}
							}							
						}
						&.pdp-qty-add {
							position: relative;
							display: inline-block;
							vertical-align: middle;
							border: 1px solid #ccc;
							background: none;
							border-radius: 0;	
							width: 50px;
							height: 50px;
							@media (min-width:980px) and (max-width:1279px) {
								width: 45px;
							}							
							&:before {
								content: '';
								display: table !important;
								position: absolute;
								top: 23px;
								left: 17px;
								margin: 0 auto;
								width: 14px;
								height: 0;
								border-bottom: 1px solid #767676;
								transform: rotate(90deg);
								transition: width 0.5s ease-in-out;							
							}							
							&:after {
								content: '';
								display: table !important;
								position: absolute;
								top: 23px;
								left: 17px;
								margin: 0 auto;
								width: 14px;
								height: 0;
								border-bottom: 1px solid #767676;
								transform: rotate(0);
								transition: width 0.5s ease-in-out;								
							}
							&:hover,
							&:focus {
								&:before {
									-webkit-transform: scale(1.15) rotate(90deg);
									-ms-transform: scale(1.15) rotate(90deg);
									transform: scale(1.15) rotate(90deg);								
								}								
								&:after {
									-webkit-transform: scale(1.15);
									-ms-transform: scale(1.15);
									transform: scale(1.15);								
								}
							}							
						}
					}
				}
			}
			.itemButtons {
				flex-direction: column;
				.itemButtons-in {
					+.itemButtons-in {
						margin-top: 10px;
						@media (max-width: 767px) {
							margin-top: 0;
						}						
					}
					.roc-addToCartFavoritesWrapper {
						display: block;
					}
				}
				.btn {
					&.btnAction {
						width: 353px;
						height: 50px;
						margin-left: 15px;
						margin-right: 0 !important;
						text-transform: none;
						border-radius: 0;
						border: none;
						font-family: $font-stack;
						font-size: 16px;
						font-weight: 600;
						@media (min-width:980px) and (max-width:1279px) {
							width: 280px;
						}
						@media (max-width: 767px) {
							width: 100%;
							margin-left: 0;
						}						
					}
					&.btnTextAction {
						width: 353px;
						height: 50px;
						margin-left: 15px;
						margin-right: 0 !important;
						text-transform: none;
						border-radius: 0;
						border: none;
						font-family: $font-stack;
						font-size: 16px;
						font-weight: 600;
						letter-spacing: 0;
						@media (min-width:980px) and (max-width:1279px) {
							width: 280px;
						}
						@media (max-width: 767px) {
							width: 100%;
							margin-left: 0;
						}						
						&.btnText {
							margin-left: 15px;
							margin-right: 0 !important;
							text-transform: none;
							border-radius: 0;
							border: 1px solid #ccc;
							background: none;
							font-family: $font-stack;
							font-size: 16px;
							font-weight: 600;
							color: #0a4e8c;
							@media (max-width: 767px) {
								width: 100%;
								margin-left: 0;
							}								
							span {
								position: relative;								
								display: table;
								margin: 0 auto;
								color: #0f518e;
								span {
									position: relative;
									padding-left: 25px;
									&:first-child {
										position: absolute;
										top: 2.3px;
										left: 0;										
										border: 2px solid #0f518e;
										border-radius: 50px;
										width: 16px;
										height: 16px;
										padding: 0;
										font-size: 15px;
										font-weight: 600;
										line-height: 12px;
										text-align: center;
										color: #0f518e;
										-webkit-transform: perspective(1px) scale(1.0);
										-ms-transform: perspective(1px) scale(1.0);
										transform: perspective(1px) scale(1.0);		
										/* IE10+ specific styles go here */
										@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
											line-height: 14px;
										}										
										/* Edge 12+ specific styles go here */
										@supports (-ms-ime-align:auto) {
											line-height: 14px;
										}										
										/* Firefox */
										@supports (-moz-appearance:none) { 
											width: 17px;
											height: 17px;
											line-height: 10px;
										}										
									}
								}							
							}
							&:hover,
							&:focus {
								border: 1px solid rgba(204, 204, 204, 0);
								-moz-box-shadow: 0 0 10px rgba(0,0,0,.2);
								-webkit-box-shadow: 0 0 10px rgba(0,0,0,.2);
								-o-box-shadow: 0 0 10px rgba(0,0,0,.2);
								box-shadow: 0 0 10px rgba(0,0,0,.2);								
							}
						}						
					}					
				}
			}
		}
	}
	
	.accordionModule {
		&.panel-group {
			margin: 25px 0 0;
			h2 {
				&.accordion-module-title {
					border-top: 2px solid #dfdfdf;
					display: block;
					margin: 0 0 35px;
					padding: 3.5% 0 0;
					font-family: Montserrat,Arial,sans-serif;
					font-size: 30px;
					font-weight: 800;
					line-height: 30px;
					text-align: center;
					color: #333;
				}
			}			
			.accordion-to-grid {
				.panel {
					padding-left: 0;
					border-top: none !important;
					background-color: transparent !important;
					.panel-heading {
						padding: 0 !important;
						border-bottom: none !important;
						.panel-title {
							margin: 0;
							font-family: $font-stack;
							text-transform: none;
							font-size: 24px;
							font-weight: 500;
							color: #333;
							letter-spacing: 1px;
							>a {
								font-weight: 500;
								.pannel-accordion {
									position: relative;
									display: table;
									padding: 10px 20px;
									width: 100%;
									@media (min-width:768px) {
										padding-left: 0;
										padding-right: 0;
									}
									.icon-wrap {
										display: table-cell;
										vertical-align: middle;
										width: 35px;
										.icon {
											fill: #e76e00;
											width: 22px;
											height: 27px;
											display: block;
											margin: 0;		
										}										
										+span {
											display: table-cell;
											vertical-align: middle;													
										}
									}
								}								
							}
						}
					}
				}
			}
		}
	}
	
	@media (min-width:768px) {
		.accordionModule {
			&.panel-group {
				h2 {
					&.accordion-module-title {
						border-top: 2px solid #dfdfdf;
						display: block;
						margin: 0 0 35px;
						padding: 3.5% 0 0;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 30px;
						font-weight: 600;
						line-height: 30px;
						text-align: center;
						color: #333;
					}
				}			
				.accordion-to-grid {
					display: grid;
					grid-gap: 0;
					grid-template-columns: 50% 50%;
					width: 100%;
					.panel {
						padding-left: 60px;
						border-top: none !important;
						border-color: transparent !important;
						/* IE10+ specific styles go here */
						@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
							padding-left: 0;
						}
						&:first-child {
							grid-column: 1;
							grid-row: 1/3;
							border-top: none;
							padding-left: 0;
						}
						&:last-child {
							grid-column: 1/3;
							grid-row: row 2;
							padding-left: 0;
							.panel-heading {
								h2 {
									&.panel-title {
										border-top: 2px solid #dfdfdf;
										display: block;
										margin: 3.3% 0 3%;
										padding: 3.5% 0 0;
										font-family: Montserrat,Arial,sans-serif;
										font-size: 30px;
										font-weight: 800;
										line-height: 30px;
										text-align: center;
										color: #333;
										>a {
											font-family: Montserrat,Arial,sans-serif;
											font-size: 30px;
											font-weight: 600;
											line-height: 30px;
											text-align: center;
											color: #333;
										}
									}
								}							
							}							
						}
						.panel-heading {
							padding: 0 !important;
							border-bottom: none !important;
							.panel-title {
								margin: 0;
								font-family: $font-stack;
								text-transform: none;
								font-size: 24px;
								font-weight: 500;
								color: #333;
								letter-spacing: 1px;
								>a {
									font-weight: 500;
									cursor: default;
									pointer-events: none;
									>.icon-carrot {
										display: none;
									}
									.pannel-accordion {
										position: relative;
										display: table;
										width: 100%;
										.icon-wrap {
											display: table-cell;
											vertical-align: middle;
											width: 35px;
											.icon {
												fill: #e76e00;
												width: 22px;
												height: 27px;
												display: block;
												margin: 0;		
											}										
											+span {
												display: table-cell;
												vertical-align: middle;
												font-weight:800;													
											}
										}
									}								
								}
							}
						}
						.panel-collapse {
							&.collapse {
								display: block !important;
							}
							.panel-body {
								padding: 30px 0 !important;
								table {
									tbody {
										>tr {
											background: #f1f1f1;
											border-top: 1px solid #fff;
											&:first-child {
												border-top: 1px solid #fff;
											}
											&:nth-child(even) {
												background: #fff;
												border-bottom: 1px solid #fff;
											}
											>th {
												width: 35%;
												padding: 10px 20px;
												font-family: $font-stack;
												font-size: 16px;
												font-weight: 700;
												text-transform: none;	
												color: #231f20;
												background: #dfdfdf;
											}
											>td {
												padding: 10px 20px;
												font-family: $font-stack;
												font-size: 16px;
												font-weight: 400;
												text-transform: none;	
												color: #231f20;
											}
										}									
									}
								}							
							}
						}
					}

				}
			}
		}
	}
	ul {
		&.pdp-custom-documents {
			margin: 0;
			padding: 0;
			list-style: none;
			width: 100%;
			li {
				display: block;
				margin: 0;
				padding: 10px 20px;
				font-family: "IBM Plex Sans",sans-serif;
				font-size: 18px;
				font-weight: 500;
				text-transform: none;
				color: #333;
				&:nth-child(odd) {
					background: #f1f1f1;
				}				
				a {
					font-family: "IBM Plex Sans",sans-serif;
					font-size: 18px;
					font-weight: 600;
					color: #0a4e8c;
					word-break: break-word;
					span {
						position: relative;
						display: inline;
						box-decoration-break: clone;
						transition: all 0.9s ease-in-out;
						background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.4), rgba(10, 78, 140, 0.4));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;
						text-decoration: none;
						&:after {
							content: '';
							display: inline;
							position: absolute;
							bottom: 7px;
							right: -12px;
							margin: 0;
							width: 0;
							height: 0;
							border: 0 solid transparent;
							border-bottom-width: 3px;
							border-top-width: 3px;
							border-left: 5px solid #004e8f;
							transition: all 0.8s ease-in-out;
						}
					}
					&:hover,
					&:focus {
						span {
							background-size: 100% 1px;
							&:after {
								-webkit-transform: scale(1.5);
								-ms-transform: scale(1.5);
								transform: scale(1.5);											
							}							
						}
					}					
				}
			}
		}
		&.pdp-videos-list {
			position: relative;
			margin: -45px 0 0;
			padding: 0;
			list-style: none;
			@media (max-width:767px) {
				margin: -15px 0 0;
			}			
			li {
				display: block;
				margin: 0;
				padding: 25px 0;
				border-bottom: 1px solid #ccc;
				color: #333;
				@media (max-width:480px) {
					padding: 15px 0;
				}
				&:first-child {
					padding-top: 0;
				}				
				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}
				a {
					font-family: "IBM Plex Sans",sans-serif;
					font-size: 18px;
					font-weight: 600;
					color: #0a4e8c;
					&.pdp-videos-list-item {
						display: table;
						width: 100%;
						.pdp-videos-list-item-image {
							position: relative;
							display: table-cell;
							vertical-align: top;
							width: 35%;
							padding-right: 20px;
							.pdp-videos-list-item-image-in {
								position: relative;
								text-align: center;
								.btn {
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
									width: 100%;
									height: 100%;
									margin: 0;
									padding: 0;
									background-color: transparent;
									border: none;
									transition: all 0.5s ease-in-out;
									.btn-in {
										display: table;
										width: 100%;
										height: 100%;
										.icon {
											display: table-cell;
											vertical-align: middle;
											transition: all 0.5s ease-in-out;
											.icon-youtube-2 {
												fill: rgba(51, 51, 51, 0.8);
												width: 75px;
												height: 55px;
												transition: all 0.5s cubic-bezier(.25,.46,.45,.94);
												@media (min-width:768px) and (max-width:980px) {
													width: 55px;
													height: 35px;
												}
												@media (max-width:480px) {
													width: 55px;
													height: 35px;
												}												
											}
											.play-video {
												display: block;
												margin: 10px 0 0;
												text-transform: none;
												transition: all 0.5s ease-in-out;
												color: rgba(255, 255, 255, 0);
												@media (max-width:767px) {
													display: none;
												}												
											}											
										}
									}
								}								
							}							
						}
						.pdp-videos-list-item-description {
							display: table-cell;
							vertical-align: top;
							h2 {
								display: block;
								margin: 0;
								padding: 0 0 15px 0;
								font-family: Montserrat,Arial,sans-serif;
								font-size: 18px;
								font-weight: 500;
								line-height: 26px;
								text-transform: none;
								color: #0a4e8c;
								word-break: break-word;
								@media (max-width:480px) {
									padding-bottom: 0;
								}								
								span {
									position: relative;
									display: inline;
									box-decoration-break: clone;
									transition: all 0.9s ease-in-out;
									background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.4), rgba(10, 78, 140, 0.4));
									background-size: 0 1px;
									background-position: 0 100%;
									background-repeat: no-repeat;
									text-decoration: none;
									&:after {
										content: '';
										display: inline;
										position: absolute;
										bottom: 7px;
										right: -12px;
										margin: 0;
										width: 0;
										height: 0;
										border: 0 solid transparent;
										border-bottom-width: 3px;
										border-top-width: 3px;
										border-left: 5px solid #004e8f;
										transition: all 0.8s ease-in-out;
									}
								}
							}
						}
						&:hover,
						&:focus {
							border-radius: 0;
							.pdp-videos-list-item-image {
								.pdp-videos-list-item-image-in {
									.btn {
										.btn-in {
											.icon {
												background-color: rgba(51, 51, 51, 0.6);												
												.icon-youtube-2 {
													fill: rgba(228, 112, 30, 0.8);
													-webkit-transform: scale(1.15);
													-ms-transform: scale(1.15);
													transform: scale(1.15);
												}
												.play-video {
													color: rgba(228, 112, 30, 1);
												}												
											}
										}										
									}
								}
							}
							.pdp-videos-list-item-description {
								h2 {
									span {
										background-size: 100% 1px;
										&:after {
											-webkit-transform: scale(1.5);
											-ms-transform: scale(1.5);
											transform: scale(1.5);											
										}
									}	
								}
							}
						}						
					}
				}
			}
		}	
	}	
	.pdp-custom-recently {
		h2 {
			&.title {
				display: block;
				margin: 3.3% 0 35px !important;
				padding: 3.5% 0 0 !important;
				font-family: Montserrat,Arial,sans-serif !important;
				font-size: 30px !important;
				font-weight: 800 !important;
				line-height: 30px !important;
				text-align: center;
				color: #333;
				@media (min-width:768px) {
					border-top: 2px solid #dfdfdf;
				}				
			}
		}		
	}
	.pdp-image-alt-wrap {
		@media (min-width:768px) {
			display: table;
			width: 100%;
		}
		@media (max-width:767px) {
			flex: 1;
			display:flex;
			flex-direction: column;
			justify-content: center;
		}		
		.pdp-alt-view-wrap  {
			@media (min-width:768px) {
				display: table-cell;
				vertical-align: top;				
				width: 100px;
			}
			@media (max-width:767px) {
				order: 2;
				margin-bottom: 25px;
			}			
//			@media (max-width:767px) {
//				display: inline-block;
//			}			
			.pdp-alt-view-in {
				display: block;
				position: relative;
				max-height: 490px;
				overflow: hidden;
				ul {
					&.altViews {
						margin: 0;
						&.not-in-yet {
							max-height: 147px;
							visibility: hidden;
							overflow: hidden;
						}						
						.slick-track {
							@media (min-width:768px) {
								width: 100px !important;
							}								
							.slick-slide {						
								@media (min-width:768px) {
									width: 100px !important;									
									margin: 0 0 6px 0;
								}
								@media (max-width:767px) {
									margin: 0 3px;
								}								
							}
						}
						img {
							@media (min-width:768px) {
								width: 100px !important;
								height: 100px !important;
							}							
							border: 1px solid #bebebe;
						}							
						.active {
							img {
								border: 1px solid #bebebe;
							}
						}
						.slick-current {
							img {
								border: 2px solid #e97812;
							}
						}
					}
				}
				button {
					&.pdp-btn-arrow {
						transition: all 0.9s ease-in-out;
						.icon {
							&.arrow-icon-up-2,
							&.arrow-icon-down-2 {
								display: block;
								fill: #004d8e;
								width: 28px;
								height: 20px;
								transition: all 0.9s ease-in-out;
								@media (min-width:768px) {
									margin-left: auto;
									margin-right: auto;									
								}								
							}
							&.arrow-icon-up-2 {
								@media (max-width:767px) {
									position: absolute;
									top: 40%;
									left: 2px;
									-webkit-transform: rotate(-90deg);
									-ms-transform: rotate(-90deg);
									transform: rotate(-90deg);									
								}
								@media (max-width:480px) {
									top: 33% !important;
								}
							}
							&.arrow-icon-down-2 {
								@media (max-width:767px) {
									position: absolute;
									top: 40%;
									right: -2px;									
									-webkit-transform: rotate(-90deg);
									-ms-transform: rotate(-90deg);
									transform: rotate(-90deg);									
								}
								@media (max-width:480px) {
									top: 33% !important;
								}								
							}							
						}
						&.top-arrow {
							position: absolute;
							z-index: 1;
							display: block;
							background-color: transparent;
							background: none;
							border: none;
							//border: 1px solid #ff0000;
							margin: 0 0 0;
							padding: 0;
							@media (min-width:768px) {
								top: 0;
								left: 0;
								right: 0;
								width: 100px;
								height: 40px;								
							}								
							@media (max-width:767px) {
								top: 0;
								left: 0;
								width: 62px;
								height: 100%;								
							}							
							.wrap-icon {
								position: relative;
								display: table;
								margin: 0 auto;								
								@media (min-width:768px) {
									width: 100px;
									height: 40px;
									/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
									background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
									background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */	
								}
								@media (max-width:767px) {
									margin-left: 0;
									margin-right: 0;
									width: 62px;
									height: 100%;
									/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
									background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
									background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
									background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
								}
								.wrap-icon-in {
									display: table-cell;
									vertical-align: top;
									text-align: center;
									@media (max-width:767px) {
										vertical-align: middle;
									}									
									.in-icon {
										display: table;
										margin: 25px auto 0;
									}
								}							
							}
						}					
						&.bottom-arrow {
							position: absolute;
							z-index: 1;						
							display: block;
							background-color: transparent;
							background: none;
							border: none;
							//border: 1px solid #ff0000;
							margin: -100px 0 0;
							padding: 0;
							@media (min-width:768px) {
								bottom: 0;
								left: 0;
								right: 0;
								width: 100px;
								height: 40px;								
							}								
							@media (max-width:767px) {
								top: 0;
								right: 0;
								width: 62px;
								height: 100%;
								margin-top: 0;
							}							
							.wrap-icon {
								position: relative;
								display: table;
								margin: 0 auto;								
								@media (min-width:768px) {
									width: 100px;
									height: 40px;
									/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
									background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
									background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
									background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
								}
								@media (max-width:767px) {
									margin-left: 0;
									margin-right: 0;									
									width: 62px;
									height: 100%;
									/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
									background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
									background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
									background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
								}
								.wrap-icon-in {
									display: table-cell;
									vertical-align: bottom;
									text-align: center;
									@media (max-width:767px) {
										vertical-align: middle;
									}									
									.in-icon {
										display: table;
										margin: 0 auto;
									}
								}							
							}
						}
						&.slick-disabled {
							opacity: 0;
							visibility: hidden;
						}
						&.alt-thumb-nav-hidden {
							opacity: 0;
							visibility: hidden;							
						}						
						&:hover,
						&:focus {
							outline: none;
							.arrow-icon-up-2,
							.arrow-icon-down-2 {
								@media (min-width:768px) {
									-webkit-transform: scale(1.5);
									-ms-transform: scale(1.5);
									transform: scale(1.5);
								}
							}
							&.arrow-icon-up-2 {
								@media (max-width:767px) {
									-webkit-transform: rotate(-90deg) scale(1.5);
									-ms-transform: rotate(-90deg) scale(1.5);
									transform: rotate(-90deg) scale(1.5);									
								}
							}
							&.arrow-icon-down-2 {
								@media (max-width:767px) {
									-webkit-transform: rotate(-90deg) scale(1.5);
									-ms-transform: rotate(-90deg) scale(1.5);
									transform: rotate(-90deg) scale(1.5);									
								}
							}							
						}
					}				
				}				
			}
		}
		.pdp-image-wrap {
			&.not-in-yet {
				max-height: 458px;
				.productImage {
					visibility: hidden;
					overflow: hidden;
				}
			}			
			@media (min-width:768px) {
				position: relative;
				display: table-cell;
				vertical-align: top;
			}			
			@media (max-width:767px) {
				order: 1;
				margin-bottom: 15px;
			}			
			.slick-slider {
				margin-bottom: 0;
			}
			.slider-main-image {
				&.zoom-in-on {
					-moz-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					-o-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
					box-shadow: 0 0 10px rgba(0, 0, 0, .3);							
				}
			}
		}
	}
}

.roc-itemTools {
	.icon-heart {
		background: #656565;
	}

	.icon-heart:hover:before,
	.icon-heart:focus:before {
		color: #656565;
	}

	.roc-itemTools .roc-active .icon-heart,
	.roc-itemTools .roc-itemTools-option:hover .icon-heart {
		color: #656565;
	}
}


@media (min-width: 767px) {
	.smc-thumbs{
		padding-right: 20px;
	}
}


.smc-productImage{
	padding-top: 15px;
}

.smc-catalogPrice{
    display: inline-block;
    width: 100%;
    margin-top: 5px;
}
/*Clearance Ribbon*/
.smc-ribbon-scope{
	position: relative;
}
.smc-ribbon-wrapper {
	width: 130px;
	height: 130px;
	overflow: hidden;
	position: absolute;
	top: -10px;
	left: -10px;
	z-index: 3;

	.smc-ribbon {
		line-height: 18px;
		text-align: center;
		text-transform: uppercase;
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		position: relative;
		padding: 7px 0;
		left: -36px;
		top: 33px;
		width: 170px;
		background-color: #e26e1e;
		color: #fff;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		letter-spacing: 0.5px;
		&:before, &:after {
			content: "";
			position: absolute;
			bottom: -14px;
		}
		&:before {
			left: 0;
			border-bottom: 14px solid transparent;
			border-right: 14px solid transparent;
			border-left: 14px solid #71380f;
		}
		
		&:after {
			right: 0;
			border-top: 14px solid #71380f;
			border-left: 14px solid transparent;
		}
	}
	&.mini {
		width: 70px;
		height: 75px;
		.smc-ribbon {
			line-height: 8px;
			width: 82px;
			left: -13px;
			top: 18px;
			font-size: 10px;
			&:before {
				border-bottom: 6px solid transparent;
				border-right: 6px solid transparent;
				border-left: 6px solid #71380f;
				bottom: -5px;
			}
			&:after {
				border-top: 6px solid #71380f;
				border-left: 6px solid transparent;
				bottom: -5px;
			}
		}
	}
}
.smc-ribbon-wrapper-hoffman {
	position: absolute;
	top: 15px;
    left: 10px;
    z-index: 2;
	.smc-ribbon-hoffman {
		img {
			width: 152px;
		}
	}
}
.smc-ribbon-wrapper-Hoffman {
	margin-left: auto;
	z-index: 2;
	.smc-ribbon-Hoffman {
		img {
			width: 120px;
			margin-bottom: 5px;
		}
	}
}
.cartList {
	.smc-ribbon-wrapper-Hoffman {
		margin-left: unset;
		.smc-ribbon-Hoffman {
			img {
				float:left;
				width:100px;
			}
		}
	}
}
.my-account-container {
	.catalog-preview-pg-wrapper {
		.search-products-wrapper {
			.search-products-content {
				.product-item-container {
					.product-image.smc-ribbon-scope {
							display: flex;
							flex-direction: column;
						.smc-ribbon-wrapper-Hoffman {
							margin-left: unset;
							.smc-ribbon-Hoffman {
								img {
									float:left;
									width:100px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.quick-view-top-table-td {
	.productImage.smc-productImage {
		.smc-ribbon-wrapper-Hoffman {
			margin-left: unset;
			.smc-ribbon-Hoffman {
				img {
					float:left;
					width:100px;
				}
			}
		}
	}
	.delivery-option-list {
		.view-all-in-button {
			padding: 10px 25px;
			width: 100%;
			font-size: 14px;
			&:after {
				right: 29px;
			}
			&:hover {
				&:after {
					right: 20px;
				}
			}
		}
	}
}
.smc-exp-date {
	text-align: center;
	color: #000;
	font-weight: 500;
	font-size: 18px;
	margin: 10px 0;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 20px;

	.smc-ed-txt {
		font-weight: 700;
	}

	.leadtime-disclaimer-wrapper {
		border: none;
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-start;
		width: 100%;
		padding: 0px 0;
		margin: 5px 0;

		.leadtime-disclaimer-icon-container {
			background: 0 0;
			padding: 0;
			width: auto;
			margin-right: 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			.leadtime-disclaimer_icon {
				color: #e4701e;
				font-size: 24px;
				line-height: normal;
			}
		}

		.leadtime-disclaimer-content-container {
			align-items: center;
			padding: 0;
			width: auto;
			display: inline-flex;
			flex-wrap: wrap;

			.leadtime-disclaimer-title {
				text-align: left;
				font-size: 16px;
				color: #333;
				line-height: normal;
			}
		}
	}
}

.white-popup .smc-exp-date {
    padding-top: 20px;
    margin-top: 0;
}

.checkoutSummary .smc-exp-date,
.smc-orderConfirmationDetails .smc-exp-date,
.cart-summary-item-container .smc-exp-date {
	padding-top: 20px;
	border-top: 1px solid #eaeaea;
}

/* Height huge white space issue on PDP fix ->  Ticket Details: #570399 */
.recentlyViewedWrapper {
	.rocModule {
		&-miniList {
			.itemWrapper {
				.itemContent {
					&.visuallyHidden {
						.itemTitle {
							position: absolute; 
							z-index: -2;
						}
					}
				}
			}
		}
	}
}