/*$font-stack: 'Montserrat', Arial, sans-serif;
$font-new: 'IBM Plex Sans', sans-serif;*/

/* ## base colors */

/*$red: #ac0000;*/

$green: #0E804E;
/*$blue: #0a4e8c;*/

/*$gray: #eee;*/

$white: #fff;

/* ## global colors */

/*$color-primary: #084985;*/

$color-primary-light: #0a4e8c;
/*$color-secondary: #231F20;*/

$color-success: $green;
/*$color-info: #fcf8e1;*/

$color-warning: #FF9B01;
/*$color-error: $red;
$color-danger: $red;
$color-action: $blue;
$color-sale: $red;*/

$color-callout: $green;
$color-process: #f57e1e;
$color-black: #000;

/* ## base text colors */

/*$color-text-primary: #000;
$color-text-secondary: #656565;*/

/* ## border colors */

/*$color-border: #dfdfdf;*/

/*$color-border-light: #e7e7e7;*/

/*$color-border-dark: darken($color-border, 10%);

$table-border-color: $color-border;*/

/* ## button colors */

$color-button-primary: #0a4e8c;
$color-button-secondary: #e4701e;
/*$color-button-action: $color-action;*/

$color-button-process: $color-process;

$line-height: 1.42857;


/* ## States and Alerts */

$state-success-text: $color-success !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;

/*$state-info-text:				$color-text-primary !default;
$state-info-bg:					$color-info !default;
$state-info-border:				darken(adjust-hue($state-info-bg, -10), 7%) !default;*/

$state-warning-text: #8a6d3b !default;
$state-warning-bg: lighten(adjust-hue($color-warning, -10), 50%) !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;

/*$state-danger-text:              $color-danger !default;
$state-danger-bg:                lighten($color-danger, 50%) !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;*/

/* ## Panels */

$panel-bg : #fff !default;
$panel-body-padding : 15px !default;
$panel-heading-padding : 10px 15px !default;
$panel-footer-padding : $panel-heading-padding !default;

/*//** Border color for elements within panels
$panel-inner-border:          $color-border-light !default;
$panel-footer-bg:             $gray !default;*/

/*$panel-default-text:			$color-text-primary !default;
$panel-default-border:			$color-border !default;
$panel-default-heading-bg:		$gray !default;

$panel-primary-text:          $color-text-primary !default;
$panel-primary-border:        $color-border !default;
$panel-primary-heading-bg:    $color-primary !default;

$panel-secondary-text:          #fff !default;
$panel-secondary-border:        $color-border !default;
$panel-secondary-heading-bg:    lighten($color-secondary, 10%) !default;

$panel-action-text:				$color-action !default;
$panel-action-border:			$color-border !default;
$panel-action-heading-bg:		$gray !default;*/

$panel-success-text : $state-success-text !default;
$panel-success-border : $state-success-border !default;
$panel-success-heading-bg : $state-success-bg !default;

/*$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;*/

$panel-warning-text : $state-warning-text !default;
$panel-warning-border : $state-warning-border !default;
$panel-warning-heading-bg : $state-warning-bg !default;

/*$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;*/
