﻿.panel {
    background-color: $panel-bg;
    border: 1px solid $panel-bg;
}

.panel + .formee {
    padding: 30px;
    border: 1px solid $panel-bg;
}

.panel-heading {
    display: block;
    width: 100%;
    color: $panel-text;
    text-align: left;
    padding: 10px 15px;
    cursor: pointer;
}

.panel-title {
    font-size: 2em;
    font-weight: 700;
}