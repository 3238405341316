/* # Global Table Styles */
/* These are global styles for tables used across the site */

/* ## Table Wrapper to allow for overflow/scroll on smaller screens */
.table-overflow {
	overflow:auto;
}


/* ## General Table Setup */

table {
	width: 100%;
	border-collapse: collapse;
	
	th, td {
		padding: 10px 25px;
		text-align: left;
		vertical-align: top;
	}
	
    td {
        word-wrap: break-word;
		font-size: 18px;
		font-weight: 300;
    }
	
	tbody {
		> tr {
			&:first-child {
				border-top: 1px solid #cccccc;
			}
			&:nth-child(even) {
				background: #e6e6e6;
				border-bottom: 1px solid #cccccc;
			}
			> th {
				width: 300px;
				font-size: 16px;
				font-weight: 500;
				text-transform: uppercase;				
			}

		}
	}
}


.table-responsive {
    overflow-x: auto;
    min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

	// 767px 
	@media(max-width:767px) {
        width: 100%;
        margin-bottom: 10px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid $color-border;

        // Tighten up spacing
        > .table {
            margin-bottom: 0;

            // Ensure the content doesn't wrap
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}


/* ## Simple Responsive Table */
/* This works best when a table consists of row headings instead of columns */

table.table-responsive-simple {
	// 767px 
	@media(max-width:767px) {
		tbody > tr {
			&:first-child {
				border-top: none;
			}
			&:last-child {
				border-bottom: none;
			}
			> th {
				display: block;
				width: auto;
				padding: 10px 15px 0;
			}
			
			> td {
				display: block;
				padding: 0 15px 10px;
			}
		}
	}
}

#productSpecificationsWrapper {
	margin: 0;
	@media(max-width:767px) {
		margin: -15px 0;
	}
}