/* ## footer ## */

footer {
	.container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		.col-sm-12 {
			padding-left: 0 !important;
			padding-right: 0 !important;
			min-height: 0;
		}
		.row {
			&:not(.ftr-new-sitemap-links-wrap-new) {
				&:last-child {
					display: table;
					width: 100%;
					color: #fff;
					background-color: #231f20;
					margin: 0 auto;
				}
			}
		}
		.ftr-full-width {
			display: table;
			margin-left: auto;
			margin-right: auto;			
			width: 100%;
			max-width: 1340px;
			float: none;
			.row,
			.row:last-child {
				margin:0;
			}			
			.col-sm-3 {
				text-align: right;
				@media (max-width:767px) {
					text-align: left;
					padding-left: 20px;
					padding-right: 20px;
				}				
			}
		}
	}
	.bannerImage {
		display: table;
		margin: 2.2% auto 0;	
		@media (max-width:767px) {
			display: none;
		}			
	}	
}

.footer {
	position:relative;
	margin-top: -15px;
	padding: 0;
	color:#fff;
	background-color: #373737;
	.row {
		margin-left: 10px;
		margin-right: 10px;
		@media (max-width:767px) {
			margin-left: 0;
			margin-right: 0;
		}		
	}
}

.footer p { font-size:0.875em; }

.footerHeading {
	display: table-cell;
	vertical-align: middle;	
	padding: 0 20px 0 0;
	color: #fff;
	font-family: $font-stack;
	font-size: 24px;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: none;
	text-align: left;
	width: 41%;
	@media (max-width:1279px) {
		display: table;
		width: 100%;
		padding-right: 0;
		padding-bottom: 15px;
		text-align: center;
		font-size: 20px;
	}		
	@media (max-width:767px) {
		padding-bottom: 10px;
		line-height: 30px;		
	}	
}

.formee-group {
	.formee-group-btn {
		&:last-child {
			>.btn {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;					
			}
		}
	}
}

#footerNewsletter {
	display: table-cell;
	vertical-align: middle;
	padding: 0 0 0 20px;
	@media (max-width:1279px) {
		display: table;
		width: 100%;
		padding-left: 0;
	}		
	.formee-group {
		width: 100%;		
		margin-bottom: 0px;
		input {
			&.formee-control {
				font-size: 18px;
				font-family: 'Lato', sans-serif;
				font-weight: 300;
				color: #444;
				float: none;				
				width: 100%;
				height: 52px;
				padding-left: 15px;
				padding-right: 15px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;					
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;				
				@media (max-width:1279px) {
					width: 100%;
				}	
//				@media (max-width:767px) {
//					display: table;
//					padding-left: 15px;
//					padding-right: 15px;					
//					border-top-right-radius: 0;
//					border-bottom-right-radius: 0;	
//					width: 100%;
//					margin-left: auto;
//					margin-right: auto;				
//				}					
			}			
		}
		input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			opacity: 1 !important;
		}
		input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			opacity: 1 !important;
		}
		input::-moz-placeholder { /* Mozilla Firefox 19+ */
			opacity: 1 !important;
		}
		input:-ms-input-placeholder { /* Internet Explorer 10-11 */
			opacity: 1 !important;
		}
		input:focus::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			opacity: 0.75 !important;
		}
		input:focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			opacity: 0.75 !important;			
		}
		input:focus::-moz-placeholder { /* Mozilla Firefox 19+ */
			opacity: 0.75 !important;			
		}
		input:focus:-ms-input-placeholder { /* Internet Explorer 10-11 */
			opacity: 0.75 !important;		
		}		
		.formee-group-btn {
//			@media (max-width:767px) {
//				display: table;
//				margin-left: auto;
//				margin-right: auto;	
//				margin-top: 10px;
//			}			
			.btn {
				position: relative;
				display: table;
				width: 52px;				
				height: 52px;
				padding-left: 0;
				padding-right: 0;
				background-color: #e4701e;
				border: 1px solid #e4701e;				
				@media (max-width:767px) {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;						
				}					
				.btn-text {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: 1px;
					color: #fff;
					text-transform: uppercase;
					&.icon-arrow-bracket-right {
						&:before {
							font-size: 38px;
							line-height: 48px;
							padding-left: 8px;
						}
					}
				}
			}
		}
	}
}

.footerPhone {
	text-transform: uppercase;
	
	span {
		font-size: 0.813em;
		color: #c6c1c1;
	}
}

.footerWelcome {
	.footerHeading {
		cursor: pointer;
	}
	
	.flyover-display {
		margin: 0 -14px;
		background: #fff;
		color: $color-text-primary;
		border: 4px solid $color-border;
	}
	
	.flyover-content {
		padding: 10px;
	}
	
	.title {
		margin-bottom: 5px;
	}
	
	p:last-child {
		margin-bottom: 0;
	}
}

.subFooter {
	.copyright {
		font-size: 14px; 
		text-align: left;
		a { 
			text-decoration: none;
			color:#fff; 
			font-weight: 500; 
			display: inline;
			box-decoration-break: clone;
			transition: all 0.5s ease-in-out;
			position: relative;
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
			background-size: 0 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			&:focus,
			&:hover {
				text-decoration: none;
				background-size: 100% 1px;						
			}
		}		
	}	
}

.subFooter {	
	.footerLinks {
		.copyright {
			display: table-cell;
			vertical-align: middle;
		}
		a { 
			text-decoration: none;
			color:#fff; 
			font-weight: 600; 
			line-height: 20px;			
			display: inline;
			box-decoration-break: clone;
			transition: all 0.5s ease-in-out;
			position: relative;
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
			background-size: 100% 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			&:focus,
			&:hover {
				text-decoration: none;
				background-size: 0 1px;
			}
		}		
	}
	@media (max-width:767px) {
		flex: 1 !important;
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		.copyright {
			order: 2 !important;
			text-align: center;
			margin-top: 20px;
		}
	}	
}

.subFooter {
	background-color: #231f20;
	display: table;
	width: 100%;
	margin: 25px 0;
	padding:0;
	font-size: 14px;
	@media (min-width:768px) and (max-width:979px) {
		margin: 18px 0;
	}
		
	.col-sm-offset-4 {
		margin-left: 0px !important;
	}
	
	// 767px 
	@media(max-width:47.938em) {
		padding-top: 0px;
		margin-top: 15px;
		margin-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;		
		
		.footerLinks {
			text-align: left;
			margin: 5px 0 0;
			 li {
				display: block;
				position: relative;
				vertical-align: middle;	
				margin-left:0px;
				padding-left: 0;
				padding-right: 0;
				margin-top: 5px;
				white-space:nowrap;
			 	text-align: center;
				&:first-child {
				 	padding-left: 0;
				}
				&:before {
				 	display: none;
				}
//				&:after {
//					content: '';		
//					display: inline-block;
//					position: absolute;
//					background-color: #a6a6a6;
//					width: 2px;
//					height: 11px;
//					top: 20%;
//					right: 0;
//				}
				&:last-child {
				 	padding-right: 0;
					&:after {
						display: none;
					}
				}				 
			}
		}
		
		.copyright {
			text-align: center;
		}
	}
}

ul.footerLinks {
	margin: 0 0 0 15px;
	padding: 0;
	text-align: right;
	@media (min-width:768px) and (max-width:1279px) {
		margin-top: 5px;
		margin-left: 0px;
	}		
}

ul.footerLinks li {
	display: inline-block;
	position: relative;
	vertical-align: middle;	
	margin-left: 0px;
	padding-left: 10px;
	padding-right: 10px;
	white-space: nowrap;
//	&:before {
//		content: '';		
//		display: inline-block;
//		position: absolute;
//		background-color: #a6a6a6;
//		width: 2px;
//		height: 11px;
//		top: 20%;
//		left: 0;
//	}
	&:first-child {
		padding-left: 0px;	
		&:before {
			@media (min-width:768px) and (max-width:1279px) {
				display: none;
			}
		}
	}
	&:last-child {
		padding-right: 0px;
	}
}


.subFooter {
	nav {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		@media (max-width:767px) {
			display: block;
			text-align: center;
		}		
	}
}

/* ## Footer Email Signup ## */

.emailSignup {
	display: table;
	width: 100%;
	max-width: 1340px;
	background-color: #4d4d4d;
	margin: 2.7% auto 0;
	padding: 30px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;	
	@media (min-width:768px) and (max-width:979px) {
		margin-top: 25px;
		padding: 20px;
	}	
	@media (min-width:768px) and (max-width:1279px) {
		padding: 15px 15px 25px;
	}	
	@media (max-width:767px) {
		margin-top: 0;
		padding: 15px;
	}		
}
.emailSignup p { margin:0 0 2px 0; }
.emailSignup .imgLabel {float:left; margin:5px 7px 0 0;}
.emailSignup .text {float:left; width:165px; margin:0 5px 0 0;}


/* ## Footer Social Follow ## */

.followUs {margin:0 0 10px;}
ul.socialFollow {
	margin: 23px 0;
	padding:0; 
	list-style-type:none;
	@media (max-width:767px) {
		margin: 0 0 20px -10px;
	}	
}

ul.socialFollow li {display: table-cell; vertical-align: bottom; margin: 0 !important; padding: 0 8px; }
ul.socialFollow li:first-child {padding-left: 0;}
ul.socialFollow li a {display: block; width:25px; height:25px; /*background:url(../../images/layout/social-32.png) no-repeat 0 0; text-indent:-9999em; overflow:hidden;*/}

ul.socialFollow {
	display: table;
	li {
		a {
			.icon {
				display: block;
				fill: #fff;
				width: 25px;
				height: 25px;
				margin: 0;
				-webkit-transition: fill 0.3s ease-in-out;
				transition: fill 0.3s ease-in-out;
				&.icon-facebook {
					width: 25px;
					height: 25px;
				}
				&.icon-linkedin {
					width: 25px;
					height: 25px;					
				}
				&.icon-youtube {
					width: 23px;
					height: 23px;					
				}				
			}			
			&:hover,
			&:focus {
				.icon {
					fill: rgba(193, 193, 193, 0.8);
					width: 28px;
					height: 28px;	
				}
			}			
		}				
	}
}

.ftr-nav {
	.footer-first-rail {
		display: table-cell;
		//width: 180px;
		.footer-logo {
			position: relative;
//			&:after {
//				content: '';
//				position: absolute;
//				left: 0;
//				right: 0;
//				top: 0;
//				bottom: 0;
//				width: 100%;
//				height: 100%;
//				background-color: rgba(55, 55, 55, 0.5);
//			}
			img {
				-webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
				filter: grayscale(100%); /* FF 35+ */
			}
		}
		h2 {
			&.title {
				font-family: Montserrat,Arial,sans-serif;
				margin: 0 !important;
				padding: 0;
				line-height: 0;
				width: 0;
				visibility: hidden;
			}
		}
		ul {
			margin: 15px 0 0;
			padding: 0;
			list-style: none;
			li {
				&.phone {
					a {
						font-size: 16px;
						font-weight: 500;
						display: inline;
						box-decoration-break: clone;
						transition: all 0.5s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;
						text-decoration: none;
						&:focus,
						&:hover {
							text-decoration: none;
							background-size: 100% 1px;						
						}						
					}
				}
				&.email {
					a {
						position: relative;
						font-size: 16px;
						font-weight: 500;
						display: inline;
						box-decoration-break: clone;
						transition: all 0.5s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;
						text-decoration: none;
						&:focus,
						&:hover {
							text-decoration: none;
							background-size: 100% 1px;						
						}						
						&:after {
							content: '';
							display: inline-block;
							position: absolute;
							top: 9px;
							right: -13px;
							margin: 0;
							vertical-align: middle;
							width: 0;
							height: 0;
							border: 0 solid transparent;
							border-bottom-width: 3px;
							border-top-width: 3px;
							border-left: 5px solid #fff;							
						}
					}
				}
				margin: 5px 0;
			}
		}
	}
	.navSpan {
		max-width: 1340px;
		margin-left: auto;
		margin-right: auto;
		@media (min-width:768px) and (max-width:979px) {
			padding-left: 15px !important;
			padding-right: 15px !important;		
		}
	}		
	.navSpan-column {
		&+.navSpan-column+.navSpan-column+.navSpan-column+.navSpan-column+.navSpan-column {
			display: none;
		}	
	}
}

@media (max-width:767px) {
	.navSpan-content {
		flex: 1 !important;
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		.footer-first-rail {
			order: 2 !important;
			text-align: center;
			margin-top: 20px;
			ul {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

/* ## Footer Nav Span */

.navSpan {
	table-layout: fixed;
	a {
		color: #fff;
		&:hover {
			text-decoration: underline;
		}
	}
}

.navSpan-column {
	ul {
		li {
			&.view-all {
				display: block;
				margin-top: 4px;
				font-family: 'Lato', sans-serif;				
				a {
					font-size: 12px;
					font-weight: 700 !important;
					letter-spacing: 1px;
					text-transform: uppercase;
					padding-bottom: 2px;
					@media (min-width:768px) and (max-width:979px) {
						font-size: 11px;
					}							
				}
			}	
			&+li+li+li {
				@media (min-width:768pxm) {
					display: none;
				}
				&+li {
					&.view-all {
						display: block;
					}
				}
			}			
		}		
	}

//    &.products-nav-column {
//        width: 170px;
//    }
//
//    &.solutions-nav-column {
//        width: 215px;
//    }
//
//    &.services-nav-column {
//        width: 300px;
//    }
//
//    &.location-nav-column {
//        width: 240px;
//    }
//
//    &.contact-nav-column {
//        width: 150px;
//    }
}

/* 16px baseline (768px) */
@media only screen and (min-width:48em) {

	
	.navSpan {
		.navSpan-column {
			padding: 0 20px;
			.linkList {
				font-size: 18x !important;
			}				
		}
	}
	
    .navSpan-content {
		h2 {
			&.title {
				margin-bottom: 12px !important;	
				font-family: Montserrat,Arial,sans-serif;
				font-size: 20px !important;
				font-weight: 600;
				letter-spacing: 1px;
				cursor: default;
				text-transform: none;
				line-height: 24px;
				a {
					font-weight: 600;				
				}
			}
		}

        ul.linkList {
            display: block !important;
            list-style: none;
			li {
				font-size: 16px;
				line-height: 21px;
				padding: 2px 0;
			}
        }
        ul.linkList.default {
            display: block !important;
        }
        .navSpan-column .title:after {
            display: none;
        }          
    }
	 .navSpan-content {
		.navSpan-column {
			h2 {
				&.title {
					pointer-events: none;					
				}
			}
		}
		.footer-last-rail {
			h2 {
				&.title {
					&:first-child {
						margin-bottom: 17px !important;
					}					
				}
			}	
			a {
				&.ftr-phone {
					text-decoration: none;
					font-weight: 500;
					&:hover,
					&:active,
					&:focus {
						text-decoration: none;						
						background-size: 0;
					}					
				}
			}
		}		 
	}
	
}


.navSpan-content {
	h2 {
		&.title {
			@media (min-width:768px) and (max-width:1279px) {
				margin-bottom: 7px !important;
				font-size: 14px !important;
				line-height: 16px;
			}	
			a {
				display: inline;
				box-decoration-break: clone;
				transition: all 0.5s ease-in-out;
				position: relative;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
				background-size: 0 1px;
				background-position: 0 100%;
				background-repeat: no-repeat;
				text-decoration: none;
				&:focus,
				&:hover {
					text-decoration: none;
					background-size: 100% 1px;						
				}						
			}			
		}
	}
	ul.linkList {
		li {
			@media (min-width:768px) and (max-width:979px) {
				line-height: 18px;
			}			
			a {
				display: inline;
				box-decoration-break: clone;
				transition: all 0.5s ease-in-out;
				position: relative;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
				background-size: 0 1px;
				background-position: 0 100%;
				background-repeat: no-repeat;
				text-decoration: none;
				font-size: 16px;
				font-weight: 500;
				&:focus,
				&:hover {
					text-decoration: none;
					background-size: 100% 1px;						
				}										
				@media (min-width:768px) and (max-width:979px) {
					font-size: 12px;
				}				
			}
		}
	}	
	.footer-last-rail {		
		a {
			display: inline;
			box-decoration-break: clone;
			transition: all 0.5s ease-in-out;
			position: relative;
			background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
			background-size: 0 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			text-decoration: none;
			&:focus,
			&:hover {
				text-decoration: none;
				background-size: 100% 1px;						
			}				
			&.ftr-phone {
				@media (min-width:768px) and (max-width:979px) {
					font-size: 12px;
				}						
			}
		}
	}
}

.col-sm-12 {
	&.ftr-nav {
		@media (max-width:767px) {
			max-width: 100%;
			margin: 25px 0 5px;
		}		
	}
}

@media only screen and (max-width:767px) {
	
	.navSpan-content .navSpan-column {
		display: table;
		width: 100%;
        cursor: pointer;
		padding: 0;
        &:first-child {
            border-top: none !important;
        } 		
        &:last-child {
            border-bottom: none !important;
        }  
		.title {
			margin-bottom: 0px;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;				
			&:hover,
			&:active {
				background-color: #4d4d4d;
			}			
			a {
				&:hover,
				&:active
				&:focus {
					text-decoration: none;
					background-size: 0;
				}				
			}
		}
		&.footer-onToggle {
			.title {
				background-color: #4d4d4d;
			}
			.linkList {
				background-color: #4d4d4d;
			}
		}
    }

    .navSpan-content .navSpan-column:last-child {
        border-bottom: 1px solid #999;         
    }
    
    .navSpan-content h2.title {
		margin-bottom: 0px;
        padding: 10px 20px;
		font-size: 16px !important;
		font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
		text-transform: uppercase;
		line-height: 18px;		
		a {
			background-size: 0;
			font-weight: 500;
			&:hover,
			&:active
			&:focus {
				text-decoration: none;
				background-size: 0;
			}			
		}
    }
    .navSpan-content li {
        margin: 0px 0px 10px 0px;
    }    
    
	.navSpan-content .navSpan-column .title:before,
	.navSpan-content .navSpan-column .title:after {
		content:"";
		position:absolute;
		top:50%;
		background-color:currentColor;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;	
	}
	.navSpan-content .navSpan-column .title:before {
		right:28px;
		margin-top:-7px;
		width:2px; 
		height:14px;
	}
	.navSpan-content .navSpan-column .title:after {
		right:22px;
		margin-top:-1px;
		width:14px;
		height:2px;
	}	
	.navSpan-content .navSpan-column .title.footer-on:before {
		margin-top:0;
		height:0;
	}	
	
    .navSpan-content ul.linkList {
		-webkit-transition: all 0.3s;
		transition: all 0.3s;			
        list-style: none;
        padding: 0 0 2px;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        li {
			margin-bottom: 6px;
            padding: 0px 0px 0px 0px;
			a {
				display: block;
				&:hover,
				&:active,
				&:focus {
					text-decoration: none;
					background-size: 0;
				}				
			}
			&.view-all {
				display: none;				
			}
        }
        a {
			background-size: 0;
			padding: 3px 20px;
            font-weight: 400;
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				background-size: 0;
			}
        }
    }	
	
}

.app-logo {
    display: flex;
	margin: 20px auto;
    align-items: center;
    justify-content: center;
	
	img {
		width: 100%;
		padding-right: 10px;
		max-width: 100%;
	}
}

footer {
	background-color: #231f20 !important;
	ul {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	>.container {
		>.row {
			@media (min-width:768px) {
				margin-left: 0;
				margin-right: 0;
			}			
			>.col-sm-12 {
				background-color: #373737;
			}	
		}
	}
	.emailSignup {
		//background-color: #6e7172;
//		@media (min-width:768px) {
//			margin-bottom: 1%;
//		}
	}
	.row {
		&.footer-new-sitemap {
			background-color: #373737 !important;
			@media (min-width:768px) {
				margin-left: -10px !important;
				margin-right: -10px !important;
			}			
		}
	}
	.container {
		.row {		
			&.row-footer-new-sitemap {
				&:last-child {
					display: table;
					max-width: 1340px;
					background-color: #373737;
					padding: 40px 10px 35px;
					@media (max-width:767px) {
						display: flex;
						flex-flow: column;
					}					
					.col-footer-new-sitemap {
						.ftr-new-sitemap-left {
							display: flex;
							flex-flow: row;
							@media (max-width:767px) {
								flex-flow: column;
							}							
							.ftr-new-sitemap-left-logo-apps {
								@media (max-width:767px) {
									margin-top: 15px;
								}
								ul {
									margin: 0;
									padding: 0;
									list-style: none;
									@media (max-width:767px) {
										display: flex;
										flex-flow: column;
										align-items: center;
									}									
									.app-logo {
										flex-flow: column;
										align-items: start;
										margin-top: 40px;
										margin-bottom: 0;
										>div {
											margin: 5px 0;
										}
										img {
											padding-right: 0;
										}
									}
								}
							}
							.ftr-new-sitemap-left-social {
								padding-left: 38px;
								@media (max-width:767px) {
									padding-left: 0;
								}
								ul {
									&.socialFollow {
										display: flex;
										flex-flow: column;
										align-items: start;
										margin: 0;
										padding: 0;
										@media (max-width:767px) {
											display: table;
											margin: 20px auto;
										}											
										li {
											padding: 5px 0;
											@media (max-width:767px) {
												padding: 0 10px;
												vertical-align: middle;
											}											
											a {
												.icon {
													&.icon-youtube {
														width: 25px;
														height: 25px;
													}
												}
												&:hover,
												&:focus {
													.icon {
														&.icon-youtube {
															width: 28px;
															height: 28px
														}
													}													
												}
											}
											&:first-child {
												padding-top: 0;
											}											
											&:nth-child(2) {
												padding-bottom: 2px;
												@media (max-width:767px) {
													padding-bottom: 0;
												}
											}
											&:last-child {
												padding-top: 0;
												@media (max-width:767px) {
													padding-bottom: 5px;
												}												
											}
										}
									}
								}
							}
						}
						ul {
							&.ftr-new-sitemap-links-right {
								list-style: none;
								margin: 0;
								padding: 0;
								display: flex;
								flex-direction: column;
								align-content: space-between;
								align-items: center;
								grid-row-gap: 5px;
								@media (min-width:768px) {
									height: 100px;
									flex-wrap: wrap;									
								}						
								@media (max-width:767px) {
									grid-row-gap: 15px;
								}									
								>li {
									flex: 1;
									>a {
										display: inline;
										font-size: 18px;
										font-weight: 600;
										letter-spacing: 1px;
										text-transform: none;
										line-height: 22px;
										color: #fff;
										transition: all .5s ease-in-out;
										position: relative;
										background-image: linear-gradient(to bottom,rgba(255,255,255,.6),rgba(255,255,255,.6));
										background-size: 0% 1px;
										background-position: 0 100%;
										background-repeat: no-repeat;
										&:hover,
										&:focus {
											background-size: 100% 1px;
										}
									}
								}
							}							
						}
						.ftr-new-sitemap-links-wrap-new {
							display: block;
							ul {
								&.ftr-new-sitemap-links-right {
									display: flex;
									text-align: center;

									&.ftr-new-sitemap-links-right--middle {
										@media (max-width:767px) {
											margin-bottom: 15px;							
										}
									}

									@media (min-width:768px) {
										height: auto;	
										flex-wrap: wrap;							
									}
									
									> li {
										width: 100%;
										margin-bottom: 5px;
									}

								}
							}
						}
					}
					.col-sm-3 {
						&.col-footer-new-sitemap {
							@media (max-width:767px) {
								order: 2;
							}							
						}
					}					
					.col-sm-9 {
						&.col-footer-new-sitemap {
							padding-left: 10%;
							@media (min-width:768px) and (max-width:979px) {
								padding-left: 5%;
							}
							@media (max-width:767px) {
								padding-left: 0;
								padding-bottom: 25px;
								order: 1;
							}							
						}
					}
				}				
			}
		}
	}
	.subFooter {
		@media (min-width:768px) {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}			
	}
}

/* Overwriting Autopilot inline CSS  */
.be-ix-link-block {
	.be-related-link-container { 
		margin-top: 0 !important; 
		margin-bottom: 0 !important; 
		padding-left: 10px !important; 
		padding-right: 10px !important; 
		padding-bottom: 15px;
		@media (max-width:767px) {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}		
	}
}