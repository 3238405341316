ul {
    &.blog-listing {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        list-style: none;
        li {
            margin: 30px 0;
            padding: 0;
			border-top: 1px solid #ccc; 
            border-bottom: 1px solid #ccc; 
			@media (max-width:767px) {
				margin: 25px 0;
			}			
            &:first-child {
                margin-top: 0;
				padding-top: 0;
            }
			&:nth-child(even) {
				background-color: rgba(223, 223, 223, 0.3);
			}	
            &:last-child {
                margin-bottom: 0;
            }
			.blog-list-meta-wrap {
				display: table;
				width: 100%;
				border-bottom: 1px solid #ccc;
				ul {
					&.blog-list-meta {
						list-style: none;
						margin: 0;
						padding: 0 15px;
						display: table;
						@media (max-width:767px) {
							margin-top: 7px;
							padding: 0;
						}							
						li {
							display: table-cell;
							vertical-align: middle;
							border: none;
							padding: 15px 15px 10px;
							&:nth-child(even) {
								background-color: transparent;
							}	
							@media (max-width:767px) {
								display: table;
								margin: 9px 0;
								padding: 0;
							}								
							.meta-author-table {
								list-style: none;
								margin: 0;
								padding: 0;
								display: table;
								width: 100%;	
								@media (max-width:767px) {
									margin-bottom: 7px;
								}								
								.author-image {
									display: table-cell;
									vertical-align: middle;
									width: 50px;
									img {
										width: 42px;
										-webkit-border-radius: 50px;
										-moz-border-radius: 50px;
										border-radius: 50px;									
									}
								}
								.author-by {
									display: table-cell;
									vertical-align: middle;
									font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
									font-size: 14px;
									@media (max-width:767px) {
										padding-left: 10px;
									}										
								}	
								.author-image {
									&+ {
										.author-by {
											@media (max-width:767px) {
												padding-left: 0;
											}											
										}
									}
								}
							}
							.blog-date-wrap {
								display: table;
								margin: 0;
								padding: 0;
								width: 100%;	
								.icon-wrap {
									display: table-cell;
									vertical-align: middle;
									width: 37px;
									@media (max-width:767px) {
										width: 50px;
										text-align: center;
									}									
									.icon {						
										fill: #0a4e8c;
										width: 30px;
										height: 30px;
									}
								}
								.blog-date {
									display: table-cell;
									vertical-align: middle;	
									font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
									font-size: 14px;
								}
							}
							.comments {
								display: table;
								margin: 0;
								padding: 0;
								width: 100%;	
								a {
									display: table-row;
									.icon-wrap {
										display: table-cell;
										vertical-align: middle;
										width: 37px;
										@media (max-width:767px) {
											width: 50px;
											text-align: center;
										}										
										.icon {						
											fill: #0a4e8c;
											width: 30px;
											height: 30px;
										}
									}
									.icon-txt {
										display: table-cell;
										vertical-align: middle;		
										font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
										font-size: 14px;	
										span {
											box-decoration-break: clone;
											transition: all .5s ease-in-out;
											position: relative;
											background-image: linear-gradient(to bottom,rgba(10,78,140,.3),rgba(10,78,140,.3));										
											background-position: 0 100%;
											background-repeat: no-repeat;
											line-height: 5px;
											color: #0a4e8c;
											letter-spacing: 1px;
											margin: 10px 0 0;										
											background-size: 0 1px;											
										}
									}
									&:hover,
									&:focus {
										.icon-txt {
											span {
												background-size: 100% 1px;
											}
										}
									}									
								}
							}
							.blog-date {
								font-size: 14px;
								font-weight: 400;
								list-style: none;
								margin: 0;
								padding: 0;
								border: none;
								@media (max-width:767px) {
									font-size: 13px;
									line-height: 18px;									
								}			
							}						
						}
					}	
				}				
			}
            .blog-entry {
                display: block;
                margin: 0;
                .media {
                    display: table-cell;
                    padding-left: 0;
                    padding-right: 0;
                    vertical-align: middle;
					text-align: center;
                    width: 25%;
					@media (max-width:767px) {
						display: table;
						margin: 25px auto 0;
						padding: 0;
						width: auto;
					}				
                }
                .blog-detail  {
                    display: table-cell;
                    padding-left: 30px;					
                    padding-right: 15px;
                    vertical-align: top;  
                    width: 80%;
					@media (max-width:767px) {
						display: table;
						margin: 0 auto;
						text-align: center;
						padding: 25px 15px 10px;
						width: auto;
					}						
                    h2 {
                        &.title  {
							display: block;
							margin: 15px 0 2px;
							font-family: Montserrat,Arial,sans-serif;
							font-size: 22px;
							line-height: 30px;
							color: #444;
							font-weight: 800;
							word-wrap: break-word;
							text-transform: uppercase;
							@media (max-width:767px) {
								margin-top: 0;
								font-size: 16px;
								line-height: 20px;									
							}							
							a {
								color: #444;
								font-weight: 800;
								box-decoration-break: clone;
								transition: all .5s ease-in-out;
								position: relative;
								background-image: linear-gradient(to bottom,rgba(68, 68, 68,.3),rgba(68, 68, 68,.3));
								background-size: 0 1px;
								background-position: 0 100%;
								background-repeat: no-repeat;
								letter-spacing: 1px;
								&:hover,
								&:focus {
									background-size: 100% 1px;	
								}								
							}
                        }
                    }
                    .blog-content  {
						margin: 10px 0 15px;
						font-family: Lato,sans-serif;
						font-size: 18px;
						font-weight: 100;
						line-height: 25px;
						color: #444;
						font-family: 'SemplicitaPro-Medium';
						@media (max-width:767px) {
							font-size: 14px;
							line-height: 18px;									
						}						
						.read-more {
							display: table;
							box-decoration-break: clone;
							transition: all .5s ease-in-out;
							position: relative;
							background-image: linear-gradient(to bottom,rgba(10,78,140,.3),rgba(10,78,140,.3));
							background-size: 100% 1px;
							background-position: 0 100%;
							background-repeat: no-repeat;
							font-size: 15px;
							font-weight: 400;
							line-height: 22px;
							color: #0a4e8c;
							letter-spacing: 1px;
							margin: 10px 0 0;
							&:hover,
							&:focus {
								background-size: 0 1px;
							}
							@media (max-width:767px) {
								margin-left: auto;
								margin-right: auto;
								font-size: 12px;
								line-height: 16px;								
							}							
						}
                    }
                }
            }			
        }
    }
}
.blog {
	&.module-list {
		ul {
			&.pagination {
				float: right;
				text-align: right;
				list-style: none;
				margin: 20px 0 0;
				padding: 0;
				white-space: nowrap;
				display: inline-block;
				font-weight: 700;
				height: 33px;
//				width: 0;
//				height: 0;
//				visibility: hidden;
				li {
//					display: inline-block;
//					background-image: none;
//					margin: 0;
//					padding: 0;
//					text-decoration: none;
//					text-align: center;
//					vertical-align: top;
//					line-height: 0;
//					font-size: 0;
//					color: #000;
//					border: 0; 
//					clip: rect(0 0 0 0); 
//					height: 1px;
//					margin: -1px; 
//					overflow: hidden; 
//					padding: 0; 
//					position: absolute; 
//					width: 1px;
					display: inline-block;
					position: relative;
					background-image: none;
					overflow: visible;
					margin: 0 2px;
					padding: 0;
					text-decoration: none;
					text-align: center;
					vertical-align: top;
					min-width: 33px;
					a {
						display: block;
						height: 33px;						
						color: #bdd7ef;
						background-color: #0a4e8c;
						border: 1px solid #084174;
						border-radius: 5px;
						margin-left: 0;
						padding: 2px 13px 2px 13px;
						transition: all .5s ease-in-out;
						font-size: 16px;
						font-weight: 700;
						line-height: 28px;						
						>span {
							&.sr-only {
								visibility: hidden;
								font-size: 0;
								width: 0;
								height: 0;
							}
						}
						&:focus,
						&:hover {
							color: #0a4e8c;
							background-color: rgba(223,223,223,.3);
							border: 1px solid #ccc;
						}						
					}
					&.active,
					&.previous-pagination,
					&.next-pagination,
					&.last-page {
						display: inline-block;
						position: relative;
						background-image: none;
						overflow: visible;
						margin: 0;
						padding: 0;
						text-decoration: none;
						text-align: center;
						vertical-align: top;
						color: #0a4e8c;		
						a {
							color: #0a4e8c;
							background-color: rgba(223,223,223,.3);
							border: 1px solid #ccc;
							border-radius: 5px;
							margin-left: 0;
							padding: 2px 13px 2px 13px;
							font-size: 16px;
							font-weight: 700;
							line-height: 28px;
							pointer-events: none;
							&.icon-arrow-bracket-left {
								transition: all .2s ease-in-out;
								&:focus,
								&:hover {
									transform: scale(1.1);
								}							
								&:before {
									font-size: 30px;
									font-weight: 100;
								}
							}
							&.icon-arrow-bracket-right {
								transition: all .2s ease-in-out;
								&:focus,
								&:hover {
									transform: scale(1.1);
								}							
								&:before {
									font-size: 30px;
									font-weight: 100;
								}
							}
							&:focus,
							&:hover {
								color: #626262;
							}						
						}
					}
				}
			}
		}		
	}
}
.blog-list-rail {
	@media (max-width:767px) {
		margin-top: 20px;
	}		
	ul {
		margin: 0 0 60px;
		padding: 0;
		list-style-type: none;
		font-size: 18px;
		border-top: 1px solid #c6c6c6;
		li {
			position: relative;
			display: block;
			padding: 16px 30px 16px 12px;
			color: #444444;
			font-weight: 400;
			text-decoration: none;
			text-transform: uppercase;
			border-bottom: 1px solid #bfbfbf;	
			h3 {
				margin: 0;
				padding: 0;
				font-size: 18px;
				line-height: 25px;
				a {
					color: #444444;
					font-size: 18px;
					font-weight: 400;
					text-decoration: none;
					text-transform: uppercase;				
					&:hover,
					&:focus {
						color: #07335c;
					}
				}
			}
			p {
				margin: 3px 0 0;
				font-weight: 300;
				text-transform: none;
			}
		}
	}
	div {
		position: relative;
		display: block;
		padding: 16px 30px 16px 12px;
		color: #444444;
		font-weight: 400;
		text-decoration: none;
		text-transform: uppercase;
		border-bottom: 1px solid #bfbfbf;	
		&:first-child {
			border-top: 1px solid #bfbfbf;					
		}
		ul {
			margin: 0;
			border-top: none;
			li {
				padding: 0;
				border: none;
			}
		}
		h3 {
			margin: 0;
			padding: 0;
			font-size: 18px;
			line-height: 25px;
			span {
				color: #07335c;
				font-size: 18px;
				font-weight: 400;
				text-decoration: none;
				text-transform: uppercase;				
			}
			&+div {
				display: none;
			}
		}
	}		
}