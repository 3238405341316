﻿.pageHeading {
    width: 100%;
    padding: 10px 0;  
    
    .title {
        color: $color-text-primary;
        font-size: 3em;
        font-weight: 600;
        line-height: 1em;

		@media (max-width:767px) {
			font-size: 1.85em;
			line-height: 1.15em;
		}        
    }
}





/* ## Text Helpers */
.text-muted {
	color: $text-muted;
}

.text-primary {
	color: $color-text-primary;
}

.text-success {
	color: $color-success;
}

.text-info {
	color: $color-info;
}

.text-warning {
	color: $color-warning;
}

.text-danger {
	color: $color-danger;
}

.text-action,
.action {
	color: $color-action;
	font-weight: bold;
}

.text-process {
	color: $color-process;
}


.text-larger {
	font-size: 1.125em;
	line-height: 1;
}

.text-largest {
	font-size: 1.250em;
	line-height: 1;
}

// listing
.container-full-width-custom {
	.container-hawk-list-wrap {
		>.col-sm-12 {
			padding-left: 0;
			padding-right: 0;
			.filterCrumbs {
				display: none;
			}
			>.login-prompt-banner {
				margin-bottom: 0;
				position: absolute;
				padding: 0;				
			}
			>#hawkbreadcrumb {
				margin-bottom: 0;
			}
			#hawkbreadcrumb {
				height: 0;
				opacity: 0;
				-moz-transition: opacity 0.5s ease-in;
				-o-transition: opacity 0.5s ease-in;
				-webkit-transition: opacity 0.5s ease-in;
				transition: opacity 0.5s ease-in;				
				&.visible {
					padding: 0;
					height: auto;
					opacity: 1;
				}
				.breadcrumbs {
					.brmbwrpr {
						font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;					
						color: #fff;
						text-shadow: 0 0 0 rgba(0,77,142,0) !important;
						font-size: 16px;
						letter-spacing: 0;
						text-shadow: 1px 0 0 rgba(0,77,142,.4);
						text-transform: none;
						a {
							font-weight: 600;
							&:after {
								content: " ";
								display: inline-block;
								position: relative;
								top: 50%;
								right: -11px;
								margin: -2px 0 0;
								vertical-align: middle;
								border: 4px solid transparent;
								border: 4px solid rgba(0,0,0,0);
								border-left-color: #004e8f;
								-wekbit-transition: all .4s ease-in-out;
								transition: all .4s ease-in-out;						
							}
						}
						span {
							font-size: 16px;
							font-weight: 500;
							color: #767676;
						}
					}
				}				
			}			
			.custom-hawk-header-banner-container-in {
				position: relative;					
				display: table;
				width: 100%;
				max-width: 1330px;
				margin: 0 auto;
				padding: 0 15px 0;
				>#hawktitle {
					display: table-cell;
					vertical-align: middle;
					padding-right: 10px;
					@media (max-width:767px) {
						display: table;
					}						
					.hdng {
						display: block;
						margin: 0;
						padding: 8px 0;
						font-size: 40px;
						font-weight: 600;
						line-height: 44px;
						color: #fff;
					}
				}
				>.express-ship-listing {
					display: table-cell;
					vertical-align: middle;
					text-align: right;
					@media (max-width:767px) {
						display: table;
						text-align: left;
					}					
					.expressShipToPanel {
						font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
						font-size: 24px;
						font-weight: 600;
						line-height: 28px;
						color: #fff;
						@media (min-width:480px) and (max-width:767px) {
							font-size: 17px;
						}						
						@media (max-width:479px) {
							.text-wrap {
								display: block;
								margin-bottom: 10px;
							}
							.ship-to-name {
								font-size: 17px;
							}
						}												
						.icon {
							&.icon-location-2 {
								width: 23px;
								height: 23px;
							}
						}
						button {
							&.selectExpressShipToBtn {
								display: inline-block;
								margin-left: 30px;
								padding: 15px 22px;
								background-color: #fff !important;								
								font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
								font-weight: 600;
								color: #0a4e8c !important;
								background-size: 0 1px !important;
								&:hover,
								&:focus {
									background-color: rgba(228,112,30,.7) !important;
									background-size: 0 1px !important;
									border-color: #e4701e;
									color: #fff !important;
									-moz-box-shadow: 0 0 10px rgba(228,112,30,.5);
									-webkit-box-shadow: 0 0 10px rgba(228,112,30,.5);
									-o-box-shadow: 0 0 10px rgba(228,112,30,.5);
									box-shadow: 0 0 10px rgba(228,112,30,.5);
								}
								@media (max-width:767px) {
									margin-left: 10px;
									padding: 10px 15px;
								}								
							}
						}
					}
				}
			}			
			.login-prompt-banner {
				margin-bottom: 20px;
			}
			.custom-hawk-list-container {
				position: relative;					
				display: table;
				width: 100%;
				max-width: 1330px;
				margin: 0 auto;
				padding: 0;
				@media (max-width:767px) {
					margin-top: 20px;
				}				
				#hawktoppager {
					display: table;
					width: 100%;
					>.pagination {
						display: none;
					}
					.roc-pageOptions {
						&.formee {
							display: table;
							float: right;
							@media (max-width:767px) {
								float: none;
								width: 100%;
							}							
							.roc-pageOptions-filter {
								display: table-cell;
								vertical-align: middle;
								.roc-pageOptions-label {
									display: inline-block;
									vertical-align: middle;
									margin-right: 5px;
									font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
									font-size: 18px;
									font-weight: 500;
									color: #333;
								}
								.hawksortby {
									display: inline-block;
									vertical-align: middle;									
								}
								+.roc-pageOptions-filter {
									&.listing-display {
										padding-left: 35px;
										text-align: right;
									}
								}
							}
						}
					}
				}
				.breadcrumbs-in-col-12 {
					display: block;
					margin-top: 10px;
					min-height: 80px;
					@media (min-width:768px) and (max-width:979px) {
						min-height: 40px;
					}
					@media (max-width:767px) {
						display: none;
					}					
				}
				.productList {
					margin: 20px 0 0;
					padding: 0 10px;
					border-top: none;
					@media (max-width:767px) {
						//margin-top: 0 !important;
						padding: 0;
					}					
					.product-list-item-wrapper {
						.product-list-item-image {
							display: table;
							margin: 0 auto;
							text-align: center;
							@media (max-width:479px) {
								min-height: auto !important;
							}							
						}
					}
				}
				#hawkbottompager {
					margin-top: 40px;
					margin-bottom: 40px;
					@media (max-width:767px) {
						margin-top: 20px;
						margin-bottom: 20px;
					}					
					.roc-pageOptions {
						&.formee {
							display: none;
						}
					}
					.pagination {
						text-align: center;
					}
				}
			}
		}
	}
}


.btn {
	&[disabled] {
		&:not(.btnText) {
			&:not(.btnTextAction) {
				background-color: #0a4e8c !important;
			}
		}
	}
}