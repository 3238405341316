/*
Base Styles
===========
*/

/* # corrects possible view port issues in browsers that do not recognize the viewport meta tag
    http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
*/
@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }


/* used to force word-wrapping on non-breaking strings, such as emails */
.break-word,
%break-word {
    word-break: break-all;
}


/* # Default Adjustments */

html {
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	overflow-y: auto;	
}

html.cm-js-menu-active {
    overflow: hidden;
}

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html, body {
    font-family: $font-stack;
    background-color: #fff;
	line-height: $line-height;
}

::-moz-selection { background: #3197fc; text-shadow: none; }
::selection { background: #3197fc; text-shadow: none; }

hr {
    display: block;
    height: 1px;
    margin: 0 0 35px;
    padding: 0;
    border: 0;
    border-top: 1px solid #dbdbdb;
}

img {
    max-width: 100% !important;
    height:auto !important;
    vertical-align: middle;
}

.svg-legend {
	position: absolute;
	top: -999em;
	width: 0;
	height: 0;
}

fieldset {border: 0;margin: 0; padding: 0; }
textarea { resize: vertical; }

/** FIX Google Maps Info window styes problem **/
img[src*="gstatic.com/"],
img[src*="googleapis.com/"] {
	max-width: none !important;
}

p { margin:0 0 1em 0; }

/* image replacement styles */
.ir { background-color: transparent; border: 0; overflow: hidden; *text-indent: -9999px; }
.ir:before { content: ""; display: block; width: 0; height: 100%; }

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.visuallyHidden, .visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyHidden.focusable:active,.visuallyHidden.focusable:focus,
.visuallyhidden.focusable:active,.visuallyhidden.focusable:focus{ clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

.visually-hidden {
    @extend .visuallyHidden;
}

.invisible { visibility: hidden; }

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

.smallest   { font-size:.8em; }
.smaller    { font-size:.9em; }
.larger     { font-size:1.1em; }
.largest    { font-size:1.2em; }
.bold       { font-weight:bold; }
.italic     { font-style:italic !important; }
.strike     { text-decoration:line-through !important; }

.red, .red:visited          { color: $red !important; }
.green, .green:visited      { color: $green !important; }

.bgYellow { background:yellow; }
.bgGreen { background:lime; }
.vtop { vertical-align:top; }
.alignCenter { text-align:center; }
.alignLeft { text-align:left; }
.alignRight { text-align:right; }
.floatLeft { float:left; }
.floatRight { float:right; }
.imgLeft { float:left; margin-right:5px; padding:5px; border:1px solid #c4c4c4; }
.imgRight { float:right; margin-left:5px; padding:5px; border:1px solid #c4c4c4; }
.nowrap { white-space:nowrap; }
.borderless { border:0; border-collapse:collapse; }
.breakforprint { page-break-after:always; }
.clear { overflow:hidden; clear:both; height:0; margin:0; font-size:1px; line-height:0; }

/* ## Skip navigation */
.skip {
	position:absolute;
	top:0;
	left:0;
	width:100%;
    margin: 0;
    padding: 0;
	font-size:.857143em;
    list-style-type: none;
}
.skip li {
	position:absolute;
	z-index:1;
	margin:0;
}
.skip a {
	position:absolute;
	left:-9999px;
}
.skip a:active,
.skip a:focus {
	display:block;
	position:static;
	left:0;
	padding:.25em 1em;
    color: #fff;
    background-color: $color-primary;
}


/* ## Text Helpers */

/*.text-muted {
	color: $color-text-secondary;
}

.text-primary {
	color: $color-primary;
}

.text-success {
	color: $color-success;
}

.text-info {
	color: $color-info;
}

.text-warning {
	color: $color-warning;
}

.text-danger {
	color: $color-danger;
}

.text-action,
.action {
	color: $color-action;
	font-weight: bold;
}

.text-process {
	color: $color-process;
}


.text-larger {
	font-size: 1.125em;
	line-height: 1;
}

.text-largest {
	font-size: 1.250em;
	line-height: 1;
}*/


/* # List styles

```
<ul>
    <li>Unordered List</li>
</ul>
```
```html
<ol>
    <li>Ordered List</li>
</ol>
```
```html
<ul class="noBullet">
    <li>noBullet</li>
</ul>
```
```html
<ul class="noIndent">
    <li>noIndent</li>
</ul>
```
```html
<ul class="bulletSquare">
    <li>bulletSquare</li>
</ul>
```
*/
ul, ol { margin:0 0 1em; }
.noBullet { padding:0 0 0 1em; list-style-type:none; }
.noIndent { padding:0; list-style-type:none; }
.bulletSquare { list-style-type:square; }


/* # Note/hint text or link */

.note {
    margin: 0;
    font-size: .875em;
    color: $color-text-secondary;
    
    a {
        text-decoration:underline; 
    }
}

select .roc-divider {
	height: 0;
	margin: 10px 0;
	font-size: 0em;
	border-top: 1px dashed $color-border;
}


/* # Site Containers */

.container {
	position:relative;
	margin:0px auto;
    width: auto;
	min-width:16em; 
	max-width:120.000em;
	-webkit-transition: all .1s linear;
	-moz-transition: all .1s linear;
	-ms-transition: all .1s linear; 
	-o-transition: all .1s linear;
	transition: all .1s linear;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box; 
}

/* ## Embedded clearfix on .container & .row ## */
.container { @include clearfix; }
.row { @include clearfix; }

.outerContainer {
	max-width:87.500em;
	margin:0 auto;
}

.siteBody { 
	min-height: 550px;
	padding: 0 0 15px;
	background-color: #fff;
	border-top: 1px solid #bdbdbd;
	.siteBodyTop {
		margin-bottom: 10px;
		padding-bottom:10px;
		//border-bottom: 1px solid $color-border-light;
	}
	.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.main-content {
    min-height: 400px;

    &:active,
    &:focus {
        outline: none;
    }
}

/* # Heading and Title styles */
.title {
    position: relative;
    margin: 0 0 1em;
    font-weight: bold;
    font-size: 1.250em;
    
	a {
		text-decoration: none;
	}
		
    .titleAlt {
        font-size: 0.8em;
		color: $color-text-secondary;
    }
}

.titleAlt {
    margin:0 0 0.1em;
    font-size:1.125em;
}

.titleAlt2 {  }

.title .more {
	position: absolute;
	top:0;
	right: 0;
	font-size: 0.6em;
}

.modContent{
	padding: 15px;
}

.actionHeader {
    cursor: pointer;
    
    .title {
        color: $color-action;
    }
	
	[class^="icon-"] {
		position: relative;
		bottom: -3px;
		margin: -2px 0;
		font-size: 20px;
		line-height: 1;
	}
}


/* # Module Structure Styling */

.rocModule {
    margin: 1em 0;
	@media (min-width: 480px) and (max-width: 1279px) {
		margin-top: 0;
	}		
}

.rocModule-header {
	display: table;
	width: 100%;
	margin-bottom: 25px;

	> .title {
		display: table-cell;
		margin:0;
		font-size: 1.625em;
		
		// 767px 
		@media(max-width:47.938em) {
			display: block;
		}
	}
	
	> .titleAlt {
		font-size: 1em;
	}
	
	> .addon {
		.actionLink {
			text-transform: uppercase;
		}
	}
}

.rocModule-content {
    padding: 0;

	.rocModule-header {
		border-bottom: 1px solid $color-border;
	}
	
    .rocModule-header > .title {
        font-size: 1em;
    }
	
    > :first-child {
        margin-top:0;
    }
    
    > :last-child {
        margin-bottom: 0;
    }
	
	> .title {
		margin-bottom: 0;
		line-height: 1.2;
	}
	
	.formee {
		margin-top:0.75em;
	}
}


/* # Widget Structure Styling */

.rocWidget {
    margin: 10px 0;
}

.rocWidget-header {
	display: table;
	width: 100%;
	margin-bottom: 15px;

	> .title {
		display: table-cell;
		margin:0;
		font-size: 0.938em;
		
		// 767px 
		@media(max-width:47.938em) {
			display: block;
		}
	}
	
	> .actionLink {
		display: table-cell;
		padding: 0;
		font-size:0.75em;
		font-weight: bold;
		text-align: right;
		vertical-align: middle;

		a {
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}
	}
}

.rocWidget-content {
    padding: 0;

	.rocWidget-header {
		border-bottom: 1px solid $color-border;
	}
	
    .rocWidget-header > .title {
        font-size: 0.875em;
    }
	
    > :first-child {
        margin-top:0;
    }
    
    > :last-child {
        margin-bottom: 0;
    }
	
	> .title {
		margin-bottom: 0;
		line-height: 1.2;
	}
	
	.formee {
		margin-top:0.75em;
	}
}


/* # Content Box Structure Styling */

.contentBox {
	position: relative;
    margin-bottom: 1em;
    border: 1px solid $color-border;
    text-align: left;

	&-note {
		background: $gray;
		border-color: $color-border;
		
		*:not(a) {
			color: darken($gray, 40%);
		}

        a {
            text-decoration: underline;
            font-weight: normal;
        }
	}
	
	.roc-close {
		position: absolute;
		top: 5px;
		right: 10px;
		padding: 0;
		font-size: 22px;
		line-height: 1;
		color: $color-text-secondary;

		&:hover {
			color: $color-action;
		}
	}
}

.contentBoxHeader {
	display: table;
	width: 100%;
	margin-bottom:-1px;
	background-color: $gray;
	border-bottom: 1px solid $color-border;

	> .title {
		display: table-cell;
		margin:0;
		padding: 10px 0 10px 20px;
		font-size: 1.125em;
		
		// 767px 
		@media(max-width:47.938em) {
			display: block;
			padding: 10px 10px;
		}
	}

	> .addon {
		padding: 5px 5px;
		
		&:last-child {
			padding-right:20px;
		}
		
		// 767px 
		@media(max-width:47.938em) {
			margin-top: -10px;
			padding: 5px 10px;
		}
	}
	
	> .actionLink {
		display: table-cell;
		padding: 10px 20px;
		font-size:0.838em;
		font-weight: bold;
		text-align: right;
		vertical-align: middle;

		a {
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}
	}
}

.panel-body > .contentBoxHeader {
    margin-bottom: 10px;
    border: 1px solid $color-border;
    
    .title {
        font-size: 1em;
    }
}

.contentBoxInterior {
    padding: 20px;
	font-size: 0.938em;
	
    > :first-child {
        margin-top:0;
    }
    
    > :last-child {
        margin-bottom: 0;
    }
	
	> .title {
		margin-bottom: 5px;
		line-height: 1.2;
		font-size: 1.125em;
	}
	
	.formee {
		margin-top:0.75em;
	}
	
	.icon-bubbles {
		color: $color-warning;
		font-size: 2.25em;
	}
	
	// 767px 
	@media(max-width:47.938em) {
		padding: 10px;
	}
}

.contentBox.hasIcon {	
	.contentBoxInterior {
		padding-left: 60px;
		
		[class^="icon-"] {
			position: absolute;
			left: 22px;
			font-size: 20px;
			line-height: 1;
		}
		
		.icon-flag {
			position: absolute;
			left: 20px;
			width: 24px;
			height: 24px;
			padding: 5px 0 0;
			color: #fff;
			font-size: 14px;
			text-align: center;
			background: $color-text-primary;
			border-radius: 12px;
			line-height: 14px;
		}
	}
}

.contentBox.contentBox-info {
	background: $color-info;
	
	.contentBoxInterior {
		.icon-flag {
			background: $color-callout;
		}
	}
}

.contentBox.contentBox-warning {
	background: lighten($color-warning, 35%);
	border-color: $color-warning;
		
	.contentBoxInterior {
		[class^="icon-"] {
			color: $color-warning;
		}
	}
}

.contentBox.contentBox-error {
	background: lighten($color-error, 55%);
	border-color: $color-error;
	
	.title {
		color: $color-error;
	}
	
	.contentBoxInterior {
		[class^="icon-"] {
			color: $color-error;
		}
	}
}

.addon {
	display: table-cell;
	padding: 0;
	font-size:0.838em;
	vertical-align: middle;

	&:last-child {
		text-align: right;
	}
	
	// 767px 
	@media(max-width:47.938em) {
		display: block;
	}
}


/* # Item Lists Content */

/*.itemList { 
	margin:0; 
	padding:0; 
	list-style:none; 
}

.item { 
	display: table;
	padding: 1.5em 0; 
	width: 100%;
	border-top: 1px solid #ccc;
	
    &.collapse,
    &.collapsing {
        padding: 0;
        border-color: $color-border-light;
    }
    
    &.collapse {
        display: none;
    }
    
    &.collapse.in {
        display: table;
    }
    
	> .content {
		display: table-cell;
		width: 100%;
		padding-right: 10px;
		vertical-align: top;
		
		&:last-child {
			padding-right: 0;
		}
	}
	
	.media+.content {
		padding-left: 10px;
	}
    
    &:first-child {
        padding-top: 0;
        border-top:0;
    }
    
    &:last-child {
        padding-bottom: 0;
    }
    
    .productList {
        margin: 0;
        padding: 0;
        border: none;
    }

	&.item-disabled,
	&[disabled] {
        background-color: $gray-lighter;
		opacity: 0.75;
	}

    &.item-selected {
        background-color: $gray-lighter;
    }

}

.itemList.selectable {
    > .item {
        padding: 5px 10px;
        transition: background-color 0.3s;
    }

    > .item:hover,
    > .item:focus {
        background-color: $gray-lighter;
    }
}

.item .contentExtra {
	display: table-cell;
	vertical-align: top;
    
    @media (max-width : 500px) {
       display: block; 
    }
    
	.date {font-size:0.85em;}
}

.itemList .content p { margin: 0; }
.itemList .content .title { margin:0; }
.itemList .content .date { font-size:0.85em; }*/

/* Item Media
   ========================================================================== */

.aside { 
	display: table-cell;
	padding: 0 15px 0 0; 
	text-align: center;
}

.caption {
	display: block;
	font-size: 0.8em;
	line-height: 1.2em;
}


/* Media Queries for Item Lists 
  ========================================================================== */


@media (max-width : 400px) { 
	
	.item { display: block; }
	.item > .content { display: block;}
	.item > .aside { display: block;}

}


/* # Grid view for item list */

.gridList {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	.interiorList {
		margin: -10px 0;
        display: -webkit-flexbox;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        flex-direction: row;
        //justify-content: space-between;
	}
	
	.item {
		position: relative;
        display: block;
        width: 100%;
        margin: 10px 0;
        padding: 0;
		font-size: 0.813em; // 13px base 16
		border: 1px solid $color-border;
		
		.itemHeader .title {
			font-size: 1em;
		}
		
		.itemContent {
			padding: 10px;
			
			.content p {
				margin: 0 0 15px;
			}
            
            &.capped-bottom {
                padding-bottom: 45px;
                
                .bottom-cap {
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    bottom: 15px;
					.btn {
						padding: 9px 10px 10px;
					}
                }
            }
		}
		
		.btnFullWidth {
			font-size: 0.938em;
			text-transform: none;
		}

		&.item-disabled,
		&[disabled] {
			border: 0;
		}
	}
	
	/* 16px base 480px - 609px */
	@media (min-width: 30em) and (max-width: 38.063em) {
		.item {
			width: 48%;
            margin-right: 4%;
            
            &:nth-child(2n) {
                margin-right: 0;
            }
		}
	}


	/* 16px base 610px - 929px */
	@media (min-width: 38.125em) and (max-width: 58.063em) {
		.item {
			width: 31.5%;
            margin-right: 2.7495%;
            
            &:nth-child(3n) {
                margin-right: 0;
            }
		}
	}

	/* 16px base 930px */
	@media (min-width: 58.125em) {
		.item {
			width: 23%;
            margin-right: 2.667%;
            
            &:nth-child(4n) {
                margin-right: 0;
            }
		}
	}
}

.gridListAlt {
	@extend .gridList;
	
	/* 16px base 610px */
	@media (min-width: 38.125em) and (max-width: 58.063em)  {
		.item {
			width: 48%;
            margin-right: 4% !important;
            
            &:nth-child(2n) {
                margin-right: 0 !important;
            }
		}
	}
    
	/* 16px base 930px */
	@media (min-width: 58.125em) {
		.item {
			width: 31.5%;
            margin-right: 2.7495% !important;
            
            &:nth-child(3n) {
                margin-right: 0 !important;
            }
		}
	}
}



/* News
   ========================================================================== */

.article > .aside {
	float:right; 
	margin: 1em;
}

.meta{
	margin:0.5em 0;
}

.meta > div {
	float: left;
}

.meta .social-tools{
	float: right;
}






.sort-link {
    display: inline;
    margin: 0;
    padding: 2px 4px;
    font-size: 0.8em;
    border-left: 1px solid #999999;

    a,
    a:visited {
        padding-right: 12px;
        text-decoration: none;
        background: url(../../images/layout/arrow_sortorder.gif) no-repeat 100% 2px;
    }

    a.down {
        font-weight: bold; background-position:100% -18px;
    }

    a.up {
        font-weight: bold;
        background-position:100% -38px;
    }

    &:last-child {
        border-left:0;
    }
}


/* # Sorting Handle */

.handle {
	display: inline;
	padding: 0;
	background: none;
	border: none;
	color: darken($gray, 20%);
	text-align: center;
	cursor: pointer;

	.handleBar {
		display: block;
		width: 22px;
		height: 1px;
		background-color: darken($gray, 20%);
	}
	
	.handleBar + .handleBar {
		margin-top: 2px;
	}
	
	&:before {
		line-height: 1;
		@extend %iconFont;
		@extend .icon-arrow-bracket-up:before;
	}
	
	&:after {
		line-height: 1;
		@extend %iconFont;
		@extend .icon-arrow-bracket-down:before;
	}
}


/* # Border Classes */

.bdr        { border:1px solid #555244; padding:1px; background-color:#a7a496; }
.bdrTop     { border-top:1px solid #999; }
.bdrRight   { border-right:1px solid #999; }
.bdrBottom  { border-bottom:1px solid #999; }
.bdrLeft    { border-left:1px solid #999; }

.bdrDash        { border:1px dashed #555244; }
.bdrDashTop     { border-top:1px dashed #999; }
.bdrDashRight   { border-right:1px dashed #999; }
.bdrDashBottom  { border-bottom:1px dashed #999; }
.bdrDashLeft    { border-left:1px dashed #999; }



/* # Expandable content (simple accordion) */

.expandable {
	max-height: 0;
	overflow: hidden;
	-wekbit-transition: max-height 0.4s ease-in-out;
	transition: max-height 0.4s ease-in-out;
}

.expandable.expanded {
	max-height:80em;
}

.expandable-trigger {
	cursor: pointer;
}

.no-js .expandable,
.no-js .expandable-trigger{
	max-height: 0;
	visibility: hidden;
}

/* # Page Heading */

.col-sm-12 {
	.pageHeading {
		margin-left: -10px;
		margin-right: -10px;
	}
}

/*.pageHeading {
    display: table;
    width: 100%;
    
    &:not(.pageHeadingAlt) {
        padding-bottom: 10px;
        margin-bottom: 40px;
        border-bottom: 0px solid #dbdbdb;        
		@media (max-width:767px) {
			margin-bottom: 0px;
		}		
    }        
    
    .title {
        margin: 0;
        padding: 20px 0 0;
        font-size: 54px;
        font-weight: 900;
        background: none;
        border: 0;
		color: #373737;
		text-transform: uppercase;
		line-height: 54px;
		@media (max-width:767px) {
			font-size: 30px;
			line-height: 35px;
			padding: 10px 0;
		}        
        .titleAlt {
            margin: 0;
            
            span {
                color: $color-action;
            }
        }
    }
}*/

h2 {
	&.section-title {
		margin: 40px 0 30px;
		padding: 0;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 35px;
		font-weight: 800;
		background: 0 0;
		border: 0;
		color: #373737;
		text-transform: uppercase;
		line-height: 35px;	
		&:first-child {
			margin-top: 0;		
		}		
	}
}

@media (max-width:767px) {
	h2 {
		&.section-title {
			margin-top: 0;
			margin-bottom: 15px;
			font-size: 22px;
			line-height: 26px;
		}
	}		
}

/* # PAGE TOOLS */

.pageToolsWrapper {
    position: relative;
	margin: 1em 0;
	z-index: 1;
}

.pageTool {
    position: relative;
	display: inline-block;
	top:-2px;
    margin: 0 2px;
    font-size: 32px;
	line-height: 1;
    color: $color-button-primary;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    vertical-align: middle;
	transition: color 0.3s;
    
    &:hover,
    &:active {
        color: lighten( $color-button-primary, 5% )
    }
}



.addthis_toolbox {
    display: inline-block;
    vertical-align: middle;
	
	a,
	a:link,
	a:visited {
		padding:0;
		margin:0 0 0 5px;
	}
}


/* ## truncate styling ## */

.truncateLink {}
.truncateLink a, .truncateLink a:link, .truncateLink a:visited { margin-left:5px; }
.truncateLink a.truncateMore, .truncateLink a.truncateMore:link, .truncateLink a.truncateMore:visited {}
.truncateLink a.truncateLess, .truncateLink a.truncateLess:link, .truncateLink a.truncateLess:visited {}


.white-popup {
	.delivery-option-list {
		ul {
            height: 100% !important;
			margin: 0;
			border-top: none;
			padding: 0;

            .delivery-option {
                .content {
                    padding: 0 0 10px 0;

                    input {
                        margin: 0 -33px 0 15px;
                    }

                    label {
                        margin: 0 0 10px 40px;
                    }

                    .delivery-option-ship-two-days {
                        margin: 0 0 0 40px;
                    }
                }
            }
		}
        
	}
}

/* ## Accordion Wrapper */

.accordionModule {
    margin: 1em 0;
}

/* # Questions & Answers List */

dl.qaList {
	margin: 1em 0;

	dt {
        font-weight: bold;

		.icon-help {
			position: absolute;
            left: 0;
            top: -4px;
			margin-right:6px;
			font-size: 1.25em;
			color: $color-callout;
		}
    }
        
	.qaDescription {
		@extend %break-word;
        display: inline;
	}
	
    small {
        margin-left: 0.350em;
        color: $color-text-secondary;
        font-weight: normal;
        font-size: 0.813em;
        white-space: nowrap;
    }
    
    dd {
        margin: 0 0 1em;
        padding: 1em 0;
        font-size: 0.875em;
        border-bottom: 1px solid $color-border-light;
        
        small {
            font-size: 0.929em;
        }
		
		&:before {
            position: absolute;
            left: 0;
			content: "A.";
			margin: 0 10px 0 4px;
			font-size: 1.125em;
			font-weight: bold;
			color: $color-callout;
		}
    }

    dt, dd {
        position: relative;
        padding-left: 35px;
    }
}


/* # Info List */

.infoList {
	list-style-type: none;
	margin: 10px 0;
	padding: 0;
	
	li {
		margin-bottom: 0.5em;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		> .title {
			font-size: 1em;
		}
	}
}

.infoList-horizontal {
	display: table;
	text-align: left;
	li {
		display: table-row;
		
		> .title {
			display: inline-block;
			padding: 0 15px 5px 15px;
			text-align: left;
			vertical-align: top;
			@media (max-width:767px) {
				display: block;
				padding: 10px 0 0;
			}			
			a {
				display: table;
				width: 100%;				
				span {
					&.file-left {
						display: table-cell;
						vertical-align: middle;		
						padding-right: 10px;
					}
					&.file-right {
						display: table-cell;
						vertical-align: middle;				
					}	
					&.file-down-text {
						box-decoration-break: clone;
						transition: all 0.5s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;			
					}					
				}
				&:hover,
				&:focus {
					span {
						&.file-down-text {
							background-size: 100% 1px;		
						}					
					}				
				}
			}
		}
		
		> .content {
			display: table-cell;
			vertical-align: top;
            word-break: break-word;
		}
	}
}

dl.infoList {
    dt {
        margin-bottom: 5px;
        font-weight: bold;
    }
    
    dd {
        margin: 0;
        padding: 0;
        font-size: 0.875em;
    }
}

/* ## inline lists */

.inlineList {
	margin: 0;
	padding: 0;
	list-style-type: none;
	
	li {
		display: inline-block;
		
		&:not(:last-child) {
			margin-right: 7px;
		}
	}
}


/* ## Content Lists */

.contentList {
	margin: 0;
	padding: 0;
	list-style-type: none;
	
	li {
		margin: 10px 0;
		font-size: 0.875em;
	}
	
	.title {
		margin: 0;
		font-size: 1.143em;
	}
}
	


/* # Contact Controls */

.contactControls {
	float: right;
	font-size: 0.875em;
	font-weight: bold;
	color: $color-text-secondary;
	
	.contactControls-trigger {
		margin-left: 1em;
		color: $color-text-secondary;
		
		&:first-child {
			margin-left: 0;
		}
	}
}

/* ## Dismissable Content */

.roc-dismissable {
	max-height: 80em;
	position: relative;	
	-wekbit-transition: max-height 0.4s ease-in-out, margin 0.4s ease-in-out, opacity 0.4s ease-in-out;
	transition: max-height 0.4s ease-in-out, margin 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.roc-close {
	padding: 0;
	line-height: 1;
	cursor: pointer;
	vertical-align: top;
	background: none;
	border: none;

	&:hover {
		color: $color-action;
	}
}

.roc-dismissing {
	max-height: 0;
	margin: 0;
	opacity: 0;
	overflow: hidden;
}

.roc-dismissed {
	@extend .roc-dismissing;
	display: none;
}


.flyover {
	position: relative;
	
	.flyover-display {
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 2;
	}
}


/* # Back To Top button */
a.backToTop {
	display: none;
	position: fixed;
	padding: 0 5px;
	z-index: 9999;
	right: 20px;
	bottom: 45px;
	color: #fff;
	text-align: center;
	background: $color-button-primary;
	border: 1px solid darken($color-button-primary, 20%);
	text-decoration: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	
	abbr {
		border: none;
		font-size: 32px;
	}
}


// extended styles for carot styles
.carrot:before {
	@extend %iconFont;
	@extend .icon-arrow-bracket-up:before;
	
	font-size: 14px;
	vertical-align: middle;
}

.collapsed .carrot:before {
	@extend .icon-arrow-bracket-down:before;
}

.flip .carrot:before {
	@extend .icon-arrow-bracket-down:before;
}

.collapsed.flip .carrot:before {
	@extend .icon-arrow-bracket-up:before;
}

/* =================================================================
                        Flexbox
===================================================================*/

.fbox-content {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1700px;
	h2 {
		&.title {
			display: table;
			margin: 0 auto 50px;
			padding: 0;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 40px;
			font-weight: 800;			
			text-align: center;
			color: #373737;
			text-transform: uppercase;
			background-color: #fff;
			width: 80%;
			span {
				color: #e4701e;
			}
			@media (max-width:767px) {
				font-size: 30px;
				line-height: 40px;					
			}			
		}
	}
	p {
		&.img {
			margin: 0;
		}
	}
}

.minus-margin-50 {
	margin-top: -50px;
	@media (min-width:980px) and (max-width:1279px) {
		margin-top: -20px;
	}	
	@media (max-width:979px) {
		margin-top: 0;
	}	
	.fbox-content {
		h2 {
			&.title {
				&.hdr {
					padding-top: 50px;
					font-family: Montserrat,Arial,sans-serif;
					@media (min-width:980px) and (max-width:1279px) {
						padding-top: 40px;
						margin-bottom: 0;
					}
					@media (max-width:979px) {
						padding-top: 20px;
						margin-bottom: 0;
					}						
				}					
			}
		}
	}
}

.fbox-row {
	display: flex;
	flex-direction: row;
	font-family: 'Lato', sans-serif;
	color: #444;
	font-size: 18px;
	font-weight: 300;
	line-height: 27px;
	margin: 40px 0 0;
	@media (min-width:980px) and (max-width:1279px) {
		font-size: 16px;
		line-height: 25px;
	}	
	@media (min-width:768px) and (max-width:979px) {
		margin: 20px 0;
		font-size: 14px;
		line-height: 23px;
	}	
	@media (max-width:767px) {
		flex-direction: column;
		font-size: 16px;
		line-height: 24px;	
		margin-top: 20px;
		margin-left: 0;
		margin-right: 0;
	}	
	@media (max-width:640px) {
		margin-bottom: 20px;
	}	
	h2 {
		&.title {
			display: block;
			margin: 0 0 20px;
			padding: 0;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 24px;
			font-weight: 300;			
			text-align: left;
			color: #444;
			text-transform: uppercase;
			width: 100%;
			line-height: 30px;
			&.connect {
				font-family: Montserrat,Arial,sans-serif;
				font-weight: 800;
				letter-spacing: 1px;
				line-height: 30px;				
				color: #0a4e8c;
				text-align: center;
				margin: 23px 0 30px;
				@media (min-width:768px) and (max-width:979px) {
					font-size: 15px;
					line-height: 23px;
					margin: 10px 0 10px;
				}	
				@media (max-width:767px) {
					margin-top: 0px;
				}				
			}			
			span {
				color: #e4701e;
			}
			@media (min-width:768px) and (max-width:979px) {
				font-size: 20px;
				line-height: 23px;
				margin-bottom: 10px;
			}	
			@media (max-width:767px) {
				padding-top: 30px;
				margin-bottom: 15px !important;
				width: 100%;
				font-size: 20px;
				line-height: 30px;
			}				
		}
	}	
	p {
		margin-bottom: 25px;
		@media (min-width:768px) and (max-width:979px) {
			margin-bottom: 10px;
		}	
		@media (max-width:767px) {
			margin-bottom: 20px;		
		}			
	}
	.fbox-in {
		padding-left: 21%; 
		padding-right: 21%;
		@media (max-width:1500px) {
			padding-left: 15px; 
			padding-right: 15px;
		}
		
	}
	.fbox-col-1 {
		flex: 1;
		display:flex;
		flex-direction: column;
		justify-content: center;	
		@media (max-width:767px) {
			margin-left: -10px;
			margin-right: -10px;
		}			
		&.mb-bottom {
			@media (max-width:767px) {
				order: 2;
			}	
		}
	}
	.fbox-col-2 {
		flex: 1;
		flex-direction: column;
		justify-content: center;	
		@media (max-width:767px) {
			margin-left: -10px;
			margin-right: -10px;
		}			
		&.mb-top {
			@media (max-width:767px) {
				order: 1;
			}	
		}		
	}
	ul {
		&.socialFollow {
			margin-top: 40px;
			@media (max-width:979px) {
				margin-top: 25px;
			}
		}
	}
}

.fbox-content-col-3 {
	.fbox-row {
		margin-top: 0px;
	}
	.fbox-col-2 {
		flex: 8;
		flex-direction: unset;
		//background-color: #eee;
		order: 1;
		&+.fbox-col-2 {
			order: 3;
		}
	}
	.fbox-col-3 {
		border: 1px solid #e3e3e3;
		background-color: #fff;
		flex: 4;
		order: 2;
		padding-left: 10px;
		padding-right: 10px;		
		&.connect-with {
			flex-direction: column;
			justify-content: center;
			display: flex;
			text-align: center;
			@media (max-width:767px) {
				padding-bottom: 10px;
			}				
		}
	}
}

.box-latest {
	position: relative;
	display: block;
	-webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
	-moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
	box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
	overflow: hidden;
	@media (max-width:767px) {
		height: auto !important;
	}	
	.box-latest-img {
		text-align: right;
	}
	.box-latest-content {
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 50%;
		height: 100%;
		.box-latest-content-table {
			position: relative;
			display: table;
			width: 100%;
			height: 100%;
			&:after {
				content: '';
				position: absolute;
				right: -85px;
				top: -6px;
				width: 0;
				height: 0;
				border-top: 500px solid transparent;
				border-bottom: 0 solid transparent;
				border-left: 86px solid rgba(255, 255, 255, 1);
			}			
			.box-latest-content-table-td {
				display: table-cell;
				vertical-align: middle;
				padding: 14%;
				@media (max-width:1279px) {
					padding: 10%;
				}				
				h2 {
					&.title {
						position: relative;
						display: block;
						padding-bottom: 18px;
						margin: 0 0 25px;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 24px;
						line-height: 32px;
						font-weight: 600;
						text-transform: none;
						color: #333;
						&:after {
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							width: 39px;
							height: 5px;
							background-color: #e4701e;
						}
						@media (min-width:981px) and (max-width:1390px) {
							font-size: 20px;
							line-height: 24px;
						}						
						@media (min-width:768px) and (max-width:980px) {
							font-size: 14px;
							line-height: 18px;
						}
						@media (min-width:535px) and (max-width:767px) {
							font-size: 22px;
							line-height: 26px;
						}
						@media (min-width:480px) and (max-width:534px) {
							font-size: 19px;
							line-height: 23px;
						}
						@media (max-width:479px) {
							font-size: 14px;
							line-height: 20px;
						}						
					}
				}
				.more {
					position: relative;
					display: table;
					min-width: 173px;
					min-height: 46px;
					font-family: $font-stack;
					font-size: 16px;
					font-weight: 600;
					line-height: 20px;
					text-transform: none;
					padding: 0;
					color: #0a4e8c;
					text-align: center;
					background-color: #fff;
					border-radius: 0;
					@media (min-width:768px) and (max-width:980px) {
						min-width: 100px;
						min-height: 15px;
						font-size: 12px;
						line-height: 16px;				
					}
					@media (max-width:480px) {
						min-width: 100px;
						min-height: 15px;
						font-size: 12px;
						line-height: 16px;		
					}					
					span {
						&.text-wrap {
							position: relative;
							display: table-cell;
							vertical-align: middle;
							border: none;
							padding: 8px;
							&:before {
								content: '';
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								width: 100%;
								height: 100%;
								text-shadow: 1px 0 0 rgba(10, 78, 140, 0);
								border: 1px solid rgba(10, 78, 140, 1);								
								-webkit-transform: scale(1.04);
								-ms-transform: scale(1.04);
								transform: scale(1.04);
								transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);									
							}
						}
						&.text-wrap-in {
							position: relative;
							z-index: 1;
						}						
					}
					&:hover,
					&:focus {
						span {
							&.text-wrap {
								&:before {
									background-color: rgba(228, 112, 30, 0.7);
									border: 1px solid rgba(10, 78, 140, 1);									
									-moz-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
									-webkit-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
									-o-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);		
									box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
									-webkit-transform: scale(1.08);
									-ms-transform: scale(1.08);
									transform: scale(1.08);									
								}
							}
						}
					}
				}				
			}
		}
	}
	+p {
		margin: 5% 0 0;
		text-align: center;
		.latest-more-link {
			position: relative;
			display: table;
			min-width: 173px;
			min-height: 46px;
			font-family: $font-stack;
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
			text-transform: none;
			margin: 0 auto;
			padding: 0;
			color: #fff;
			text-align: center;
			background-color: transparent;
			border-radius: 0;
			@media (min-width:768px) and (max-width:980px) {
				min-width: 120px;
				min-height: 15px;
				font-size: 14px;
				line-height: 16px;				
			}
			@media (max-width:480px) {
				min-width: 100px;
				min-height: 15px;
				font-size: 12px;
				line-height: 16px;		
			}			
			span {
				&.text-wrap {
					position: relative;
					display: table-cell;
					vertical-align: middle;
					border: none;
					padding: 8px;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						text-shadow: 1px 0 0 rgba(10, 78, 140, 0);
						border: 1px solid rgba(255, 255, 255, 1);								
						-webkit-transform: scale(1.04);
						-ms-transform: scale(1.04);
						transform: scale(1.04);
						transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);									
					}
				}
				&.text-wrap-in {
					position: relative;
					z-index: 1;
				}						
			}
			&:hover,
			&:focus {
				span {
					&.text-wrap {
						&:before {
							background-color: rgba(228, 112, 30, 0.7);
							border: 1px solid rgba(255, 255, 255, 1);						
							-moz-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
							-webkit-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
							-o-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);		
							box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
							-webkit-transform: scale(1.08);
							-ms-transform: scale(1.08);
							transform: scale(1.08);									
						}
					}
				}
			}
		}		
	}
	&.smc-box-latest {
		display: flex;
		flex-direction: row-reverse;
		background: #fff;
		.box-latest-content {
			position: static;
			width: calc(100% - 92px);
			z-index: 1;
			.box-latest-content-table{
				display: block;
				.box-latest-content-table-td {
					display: flex;
					flex-direction: column;
					height: 100%;
				}
			}
		}
		
		.box-latest-img {
			width: 100%;
			background-size: cover;
			background-position: right center;
			background-repeat: no-repeat;
			display: flex!important;
			align-items: center;
			justify-content: end;
			>img {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: auto;
			}
		}
	}	
}

.container-news-bg {
	.fbox-content-col-3 {
		.fbox-col-2 {
			&:first-child {
				.box-latest {
					margin-right: 7%;
					@media (max-width:767px) {
						margin-left: auto;
						margin-right: auto;
						max-width: 613px;
					}					
				}
			}
			&:last-child {
				>h2 {
					margin-left: 7%;
					@media (max-width:767px) {
						margin-left: 0;
					}					
				}
				.box-latest {
					margin-left: 7%;
					@media (max-width:767px) {
						margin-left: auto;
						margin-right: auto;
						max-width: 613px;
					}					
				}				
			}			
		}
	}
	&.footer-programs-Special {
		.box-latest {
			.box-latest-content {
				.box-latest-content-table {
					.box-latest-content-table-td {
						vertical-align: top;
						h2 {
							&.title {
								font-family: Montserrat,Arial,sans-serif;
								&:after {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

.interior-vertical-text-rail {
	position: absolute;
	top: 0;
	right: -80px;
	bottom: 0;
	width: 0;
	height: 100%;
	text-align: left;
	@media (max-width:767px) {
		display: none;
	}			
	@media (max-width:1840px) {
		left: 0;
		right: inherit;
		top: -35px;
		width: 100%;
		height: 30px;
		display: none;
	}		
	.interior-vertical-text {	
		position: relative;
		top: 0%;
		overflow: hidden;			
		.interior-vertical-text-in {
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);	
			transform-origin: left top 0;	
			display: table;
			min-width: 500px;
			width: 100%;
			overflow: hidden;
			font-family: 'Lato', sans-serif;
			font-size: 22px;
			font-weight: 300px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #dcdfe0;
			@media (max-width:1840px) {
				-webkit-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				transform: rotate(0deg);	
			}				
		}
	}			
}

.interior-vertical-text-rail-left {
	position: absolute;
	top: 400px;
	left: -70px;
	bottom: 0;
	width: 0;
	height: 100%;
	text-align: left;		
	@media (max-width:1840px) {
		display: none;
	}		
	.interior-vertical-text {	
		position: relative;
		top: 0%;
		overflow: hidden;			
		.interior-vertical-text-in {
			-webkit-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			transform: rotate(-90deg);	
			transform-origin: left top 0;	
			display: table;
			min-width: 500px;
			width: 100%;
			overflow: hidden;
			font-family: 'Lato', sans-serif;
			font-size: 22px;
			font-weight: 300px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #dcdfe0;
			@media (max-width:1840px) {
				-webkit-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				transform: rotate(0deg);	
			}				
		}
	}			
}

.title-box-link {
	display: block;
	text-align: center;
	padding-bottom: 30px;
	@media (max-width:767px) {
		padding-bottom: 15px;
	}	
	span {
		&.img {
			display: block;
		}
		&.more {
			display: inline;
			box-decoration-break: clone;
			transition: all 0.5s ease-in-out;
			position: relative;
			background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
			background-size: 0 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			font-size: 12px;			
			text-transform: uppercase;
			color: #0a4e8c;
			text-align: center;		
			letter-spacing: 1px;
		}
	}
	h2 {
		&.title {
			margin: 0;
			padding: 30px 15px 0;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 24px;
			line-height: 28px;
			font-weight: 400;
			color: #444;
			text-align: center;
			@media (max-width:767px) {
				padding: 20px 40px 0;
				font-size: 18px;
				line-height: 24px;
				margin-bottom: 5px !important;
			}			
		}
	}
	&:hover,
	&:focus {
		span {
			&.more {
				background-size: 100% 1px;
			}
		}		
	}
}

.max-1700 {
	max-width: 1700px;
}
.max-1730 {
	max-width: 1730px;	
}
.max-1780 {
	max-width: 1780px;	
}

/*.no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}*/

.padding-left {
	padding-left: 0;
}
.padding-right {
	padding-right: 0;
}

.rocModule-miniList {
	&.services-row {
		padding: 0;
		border: none;
		.rocModule-header {
			margin: 0 auto 10px;
			h2 {
				&.title {
					position: relative;
					display: table;
					margin: 0 auto;
					width: 100%;
					padding: 0;
					font-family: Montserrat,Arial,sans-serif;
					font-size: 24px;
					font-weight: 600;
					line-height: 30px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: #373737;
					text-align: center;
					@media (max-width:640px) {		
						font-size: 20px;
						line-height: 24px;
					}					
					span {
						padding: 0 5%;						
						position: relative;
						background: #fff;
						z-index: 1;
						@media (max-width:640px) {
							background: transparent;
							padding: 0 2%;
						}						
					}
					&:after {
						position: absolute;
						content: '';
						left: 0;
						right: 0;
						top: 50%;						
						background-color: #d4d4d4;
						height: 1px;
						@media (max-width:640px) {		
							display: none;
						}						
					}
				}
			}
		}
		.productList {
			.productListInner {
				display: table;
				margin: 0;
				.slick-prev {
					left: -1.1%;
					z-index: 9;
					width: 35px;
					height: 35px;		
					@media (min-width:768px) {		
						top: 50% !important;
					}
					@media (min-width:641px) and (max-width:1800px) {	
						left: 5px;
					}		
					@media (max-width:640px) {		
						top: 23% !important;
						left: 15px;
					}		
					&:before {
						display: none;
					}
					-webkit-border-top-left-radius: 0;
					-moz-border-top-left-radius: 0;
					-ms-border-top-left-radius: 0;
					-o-border-top-left-radius: 0;
					border-top-left-radius: 0;
					-webkit-border-bottom-left-radius: 0;
					-moz-border-bottom-left-radius: 0;
					-ms-border-bottom-left-radius: 0;
					-o-border-bottom-left-radius: 0;
					border-bottom-left-radius: 0;
					.icon-arrow {
						background: #fff;	
						-webkit-border-radius: 50px;
						-moz-border-radius: 50px;
						border-radius: 50px;							
						fill: rgba(6, 6, 6,.95);
						transition: all .2s ease-in-out;
						width: 32px;
						height: 32px;	
						@media (max-width:979px) {		
							width: 30px;
							height: 30px;
						}
					}
					&:hover,
					&:active {
						.icon-arrow {
							fill: rgba(217,107,29,.95);
							transform: scale(1.1);				
						}
					}		
				}
				.slick-next {
					right: -1.1%;
					z-index: 9;
					width: 35px;
					height: 35px;
					@media (min-width:768px) {		
						top: 50% !important;
					}			
					@media (min-width:641px) and (max-width:1800px) {	
						right: 5px;
					}		
					@media (max-width:640px) {		
						top: 23% !important;
						right: 15px;
					}		
					&:before {
						display: none;
					}
					-webkit-border-top-right-radius: 0;
					-moz-border-top-right-radius: 0;
					-ms-border-top-right-radius: 0;
					-o-border-top-right-radius: 0;
					border-top-right-radius: 0;
					-webkit-border-bottom-right-radius: 0;
					-moz-border-bottom-right-radius: 0;
					-ms-border-bottom-right-radius: 0;
					-o-border-bottom-right-radius: 0;
					border-bottom-right-radius: 0;
					.icon-arrow {
						background: #fff;	
						-webkit-border-radius: 50px;
						-moz-border-radius: 50px;
						border-radius: 50px;						
						fill: rgba(6, 6, 6,.95);
						transition: all .2s ease-in-out;
						width: 32px;
						height: 32px;	
						@media (max-width:979px) {		
							width: 30px;
							height: 30px;
						}		
					}	
					&:hover,
					&:active {
						.icon-arrow {
							fill: rgba(217,107,29,.95);
							transform: scale(1.1);				
						}
					}		
				}
				.slick-list {
					margin: 0;			
					padding: 0 20px;
					@media (max-width:640px) {		
						padding: 0;
					}					
				}
			}
		}

		.itemWrapper {
			padding: 25px 20px;
			text-align: center;
			display: table-cell;
					
			min-height: 1px;
			@media (max-width:640px) {		
				padding: 0 0 25px;
			}		
			.sf-Image-wrapper {
				display: block;
				width: 100%;
				overflow: hidden;
				min-height: 1px;
			}	
			.itemImageWrapper {
				position: relative;
				border: none;
				padding: 25px 0 50px;				
				&:before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;					
					-wekbit-transition: all 0.5s ease-in-out;
					transition: all 0.5s ease-in-out;	
					@media (max-width:640px) {
						-webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.20);
						-moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.20);
						box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.20);
					}					
				}
				@media (max-width:640px) {
					padding-bottom: 35px;
				}					
				&:hover,
				&:focus {
					.btn {
						background-color: #e4701e;
						border-color: #e4701e;
						-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
						-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
						-o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);		
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);				
					}
					.text {
						color: #fff;
					}						
					&:before {
						content: '';
						position: absolute;
						-webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.20);
						-moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.20);
						box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.20);
						left: -25px;
						right: -25px;
						top: 0;
						bottom: 0;
					}					
				}				
			}		
			span,
				h2 {
					&.title {
						display: block;
						margin: 30px 0 15px;
						padding: 0;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 24px;
						font-weight: 400;
						text-transform: uppercase;
						color: #444;
						@media (max-width:640px) {
							margin: 20px 0 10px;
							font-size: 18px;
						}	
					}
				}			
				p,
				span {
					&.p {
						display: block;
						margin: 0;
						padding: 0 20px;
						font-size: 18px;
						font-weight: 300;
						line-height: 30px;
						color: #444;
						width: 100%;
						@media (max-width:640px) {
							font-size: 16px;
							line-height: 24px;
						}					
					}				
				}
				span {
					&.btn {
						margin-top: 25px;
					}
				}	
			.media {
				position: relative;
				
			}
		}		
	}	
}

.rocModule-miniList {
	&.services-row {
		.productList {
			.productListInner {
				&.slick-slider {
					display: inherit;	
				}
			}
		}
		.slick-track {
			.itemWrapper {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}	
		}		
	}
	.itemWrapper {
		.item-slide-wrap {
			display: block;
			margin-bottom: 5px;
			-wekbit-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;
			@media(max-width:479px){
				height: auto !important;
			}		
		}
		.itemTitle {
		    min-height: 44px !important;
			display: inline-block;
			line-height: 22px;
		}
		.itemSku {
			margin-top: 5px;
		}
		.btn-wrap {
			display: block;
			text-align: center;
			margin-top: 15px;
			.btn {
				font-size: 14px;
				padding: 10px 20px;			
			}
		}
	}
	.slick-prev,
	.slick-next {
		overflow: hidden;
		&.slick-disabled {
			pointer-events: none;
		}
	}
}

.content-section {
	display: block;
	margin: 40px 0 30px 0;
	padding-bottom: 20px;
	border-bottom: 1px solid #d4d4d4;
	/* ## 16px baseline (950px) ## */
	@media only screen and (max-width:59.38em){
		margin-top: 15px;
	}	
	img {
		margin: 0 0 10px 0;
	}
	h2 {
		&.title {
			margin: 20px 0 25px 0;
			font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
			font-size: 24px;
			font-weight: 600;
			line-height: 30px;
			color: #373737;
			text-transform: uppercase;
			@media (max-width: 1279px) {
				margin: 0 0 10px 0;
				line-height: 29px;
			}			
		}
	}
	p {
		margin: 0 0 25px;
		font-family: 'Lato', sans-serif;
		font-size: 18px;
		font-weight: 100;
		line-height: 30px;
		color: #444;
		@media (max-width:1279px) {
			margin: 0 0 15px;
			line-height: 26px;
		}		
	}
}

.col-sm-3 {
	.content-section {
		display: block;
		margin: 40px 0px 20px 0px;
		padding-bottom: 30px;
		border-bottom: none;
		@media only screen and (max-width:1279){
			margin: 15px 0 0;
		}		
		img {
			width: 100%;
		}
		h3 {
			&.title {
				margin: 10px 0 7px;
				font-size: 18px;
				font-weight: 400;
				color: #444444;
				text-transform: uppercase;
				@media (max-width:47.94em){
					margin-bottom: 2px;
				}				
			}
		}
		p {
			margin: 0px 0px 12px 0px;
			font-size: 18px;
			font-weight: 100;
			line-height: 30px;
			color: #444444;
			@media (max-width:47.94em){
				margin-bottom: 8px;
			}			
		}
		.btn {
			padding: 8px 25px 8px 25px;
			border-radius: 5px;			
		}
	}	
}

.productDetails {
	.scroll {
		display: block;
		position: relative;
		margin: 0 0px 30px 0px;
		padding: 20px 0px 20px 0px;
		border-top: 1px solid #cccccc;
		border-bottom: 1px solid #cccccc;
		.slick-prev {
			margin-right: 50px;
		}
		.slick-next {
			margin-right: 0;
		}
		&:last-child {
			border-bottom: none;
			margin-bottom: 0px;
			padding-bottom: 0px;
		}
	}
}

.section-in-table {
	position: relative;
	display: table;
	table-layout: fixed;
	margin-left: auto;
	margin-right: auto;
	width: 100%;	
	.section-in-table-td {
		display: table-cell;
		vertical-align: top;
		padding: 10px 15px 10px 15px;	
		&:first-child {
			padding-left: 0px;
		}
		&:last-child {
			padding-right: 0px;
		}		
	}
}
	
ul {
	&.section-ul {
		margin: 0px 0px 0px 0px;
		padding: 0px 0px 0px 0px;
		list-style: none;
		li {
			text-transform: uppercase;
			a {
				display: block;
				border-top: 1px solid #bfbfbf;
				color: #002f65;
				font-weight: 400;
				padding: 7px 10px;
				&:hover,
				&:active {			
					background-color: rgba(191, 191, 191, 0.5);
					-webkit-transition: background-color 1000ms linear;
					-moz-transition: background-color 1000ms linear;
					-o-transition: background-color 1000ms linear;
					-ms-transition: background-color 1000ms linear;
					transition: background-color 1000ms linear;							
				}
				&.icon-arrow-bracket-right {
					&:before {
						position: absolute;
						right: 0px;
						font-size: 25px;
						line-height: 25px;
					}
					span {
						font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;	
						font-size: 16px;						
					}
				}
			}
			&:last-child {
				a {
					border-bottom: 1px solid #bfbfbf;
				}
			}
		}
	}
}

span {
	&.button {
		display: inline-block;
		margin-top: 10px;
		@media (max-width:47.94em){
			margin-top: 2px;
		}			
		.btn {
			font-size: 16px !important;
			padding: 8px 40px;
			border-radius: 0px;
			border: none;
		}
	}
}	

.scroll {
	.rocModule-miniList {
		&.full {
			padding-top: 30px !important;
			padding-left: 0px !important;
			padding-right: 0px !important;
		}	
	}	
}

.scroll {
	.rocModule-miniList {
		padding: 0px 0px 10px;
		border: none;
		@media (max-width:47.94em){
			margin-bottom: 0;
			padding-bottom: 0;
		}		
		@media (min-width: 480px) and (max-width: 1279px) {
			padding-bottom: 0;
		}			
	}	
	.rocModule-header {
		h2 {
			&.title {
				margin: 0px 0px 0px 0px;
				font-family: Montserrat,Arial,sans-serif;
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 600;
				color: #0a4e8c;				
			}
		}
	}
	.itemWrapper {
		padding: 10px 20px;
		color: #818181;
		text-align: center;
		.media {
			display: table;
			margin-left: auto;
			margin-right: auto;
		}
		.itemImageWrapper {
			border: none;
		}
		.itemContent {
			display: table;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			@media (max-width:479px) {
				height: auto !important;
			}			
			p {
				&.description {
					margin: 0px 0px 10px 0px;				
					font-size: 16px;
					font-weight: 300;
					color: #818181;
					@media (max-width: 1279px) {
						font-size: 12px;
					}					
				}
				&.price {
					margin: 15px 0 25px;
					font-size: 18px;
					line-height: 18px;
					font-weight: 600;
					color: #444444;		
					@media (max-width:47.94em) {
						margin-bottom: 15px;
					}
					@media (max-width: 1279px) {
						font-size: 14px;
					}					
				}
			}
		}
		.itemTitle {
			font-size: 22px;
			@media (max-width: 1279px) {
				font-size: 16px;
				line-height: 20px;
			}			
			a {
				color: #444444;
				font-weight: 300;
				&:after {
					display: none;
				}
			}
		}
	}
	.slick-prev {
		left: inherit;
		right: 0%;
		margin-right: 50px;
		top: -43px !important;
		z-index: 9;
		&:before {
			display: none;
		}
		-webkit-border-top-left-radius: 0;
		-moz-border-top-left-radius: 0;
		-ms-border-top-left-radius: 0;
		-o-border-top-left-radius: 0;
		border-top-left-radius: 0;
		-webkit-border-bottom-left-radius: 0;
		-moz-border-bottom-left-radius: 0;
		-ms-border-bottom-left-radius: 0;
		-o-border-bottom-left-radius: 0;
		border-bottom-left-radius: 0;
		.icon-arrow {
			background: #fff;	
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;							
			fill: rgba(6, 6, 6,.95);
			transition: all .2s ease-in-out;
			width: 32px;
			height: 32px;	
			@media (max-width:979px) {		
				width: 30px;
				height: 30px;
			}
		}
		&:hover,
		&:active {
			.icon-arrow {
				fill: rgba(217,107,29,.95);
				transform: scale(1.1);				
			}
		}
	}
	.slick-next {
		right: 10px;
		top: -43px !important;
		&:before {
			display: none;
		}
		-webkit-border-top-right-radius: 0;
		-moz-border-top-right-radius: 0;
		-ms-border-top-right-radius: 0;
		-o-border-top-right-radius: 0;
		border-top-right-radius: 0;
		-webkit-border-bottom-right-radius: 0;
		-moz-border-bottom-right-radius: 0;
		-ms-border-bottom-right-radius: 0;
		-o-border-bottom-right-radius: 0;
		border-bottom-right-radius: 0;
		.icon-arrow {
			background: #fff;	
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;						
			fill: rgba(6, 6, 6,.95);
			transition: all .2s ease-in-out;
			width: 32px;
			height: 32px;	
			@media (max-width:979px) {		
				width: 30px;
				height: 30px;
			}		
		}	
		&:hover,
		&:active {
			.icon-arrow {
				fill: rgba(217,107,29,.95);
				transform: scale(1.1);				
			}
		}	
	}	
	.brands {
		.itemWrapper {
			.media {
				display: block;
				padding-right: 0px;
				width: 100%;
			}
		}
	}
	.industry {		
		.itemWrapper {
			.media {
				padding-right: 0px;
				width: 100%;
			}
		}
	}	
}


/* =================================================================
                        Media Queries
===================================================================*/


/* 16px baseline (180px - 767px) */
@media only screen and (min-width:11.250em) and (max-width:47.938em) {	

	.pageTools {display:none;}
}

/* =================================================================
                       Branch Locator
=================================================================*/

.branchLocatorsWrpr .map {
    width: 1408px;
    overflow: hidden;
    float: none;
    display: table-cell;
    vertical-align: top;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.branchLocatorMapWrapper {
	height: 100%;
}
.branchLocatorsWrpr .map #map-canvas {
    width: 100%;
    height: 100%;
}
.branchLocator-map-locations {
    padding: 0 15px;
    float: none;
    width: auto;
    margin: 0;
	@include sm {
		float: left;
		display: block;
		height: 100%;
		width: 100%;
		margin-bottom: 0px;
	}
}
#branchLocatorListWrapper {
	height: 200px;
	overflow-y: scroll;
	@include sm {
		height: 634px;
	}
	@include md {
		height: 404px;
	}
	@include lg {
		height: 584px;
	}
}
.module-branchLocator-map.map {
    height: 100%;
    overflow: hidden;
    display: block;
    float: left;
    width: 100%;
}
.branchLocatorResultsAll {
    width: 100%;
    height: 515px;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 0px;
    padding-left: 0px;
}
.branchLocatorResult {
    padding: 5px 15px;
    border-bottom: 2px solid #fff;
}
.branchLocatorResult .results-buttons {
    cursor: pointer;
}
.location-search-row {
    background-color: #cccccc;
    border-bottom: 2px solid #fff;    
    padding: 15px 15px 15px 15px;
}
.title-location {
    margin: 0px;
    padding: 0px;
}    
.location-search-all input {
    width: 100%;
    margin-bottom: 5px;
}    
.location-select {
    width: 100%;
    margin-bottom: 5px;    
}  

.branchLocatorResult {
	.title-location {
		text-transform: capitalize;
	}
	.address {
		text-transform: capitalize;
	}	
}

.member-info {
	.ship-to {
		b {
			text-transform: capitalize;		
		}	
		.btn {
			-moz-border-radius: 0px;
			-webkit-border-radius: 0px;
			border-radius: 0px !important;	
			/*border-bottom: 1px solid #e6e6e6;*/
			&:hover {
				text-decoration: none;
				/*border-bottom: 1px solid #b30000;*/
			}
		}
	}
}

.rocModule-miniList {
	&.prod-group {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}	
}

a {
	&.itemTitle {
		display: block;
		cursor: pointer;
		transition: color 0.3s;
	}
	.itemTitle {
		font-size: 1.5em;
		margin: 0px 0px 5px;
		text-align: center;
		&.pg-lnk {
			@media (max-width:1024px) {
				font-size: 1.5em;
			}			
		}
	}
	&.hover-link {
		text-align: center;
		transition: color 0.3s;
		&:hover {
			color: #b30000;
			text-decoration: underline;
		}
	}
	span {
		&.icon-folder-open {
			display: inline-block;
			margin-left: 11px;
			height: 151px;
			text-decoration: none;
			width: 100%;
			&:before {
				color: #333;
				font-size: 150px;	
				line-height: 135px;				
				transition: color 0.3s;
			}		
		}
	}
	&:hover {
		.itemTitle {
			color: #b30000;
		}
		span {
			&.icon-folder-open {
				&:before {
					color: #b30000;
					text-decoration: none;
				}		
			}
		}		
	}	
}


.rocModule-header {
	.addon {
		margin-bottom: 3px;
		a {
			&.actionLink {
				&:hover {
					text-decoration: underline;
				}
			}
		}		
	}
}

.rocModule-header {
	&> .title {
		@media (max-width:47.938em) {
			line-height: 35px;
		}	
	}	
}

.interiorList {
	.location-name {
		text-transform: capitalize;	
	} 	
}

.interiorList {
	.location-address {
		text-transform: capitalize;	
	}	
}

.locationResultsItem {
	.contentExtra {
		text-transform: capitalize;			
	}	
	.location-results-table {		
		width: auto;
		max-width: 320px;
		font-size: 13px;
		@media (max-width: 767px) {
			width: auto;						
		}		
		.location-results-table-tr {
			display: block;
			padding-bottom: 15px;
			&.mobile-table {
				@media (max-width: 767px) {
					display: table;
					width: 100%;
				}
			}
			.location-results-table-td {
				display: table-cell;
				vertical-align: top;
				padding: 2px 0px;
				&.contact {
					vertical-align: top;
					padding-top: 10px;				
					@media (max-width: 767px) {
						display: table;
						padding-left: 0px;
						padding-right: 0px;
						width: 100%;						
					}					
				}
				&.hours {
					@media (max-width: 767px) {
						display: table;
						padding-left: 0px;
						padding-right: 0px;
						width: 100%;						
					}					
				}				
				&.image {
					vertical-align: middle;
				}
				&.left {
					text-align: left;					
				}
				&.right {
					text-align: left;
				}				
				&:first-child {
					padding-right: 10px;
				}					
				&:last-child {
					padding-left: 10px;
					@media (max-width: 767px) {
						padding-left: 0px;
					}
				}
				.call {
					margin-top: 10px;
				}
				.location-in-table {
					display: table;
					width: 100%;
					.location-in-table-tr {
						display: table-row;
						.location-in-table-td {
							display: table-cell;
							vertical-align: top;
							padding: 2px 0px;
							&:first-child {
								text-align: left;
								padding-right: 0px;
							}								
							&:last-child {
								text-align: left;
								padding-left: 0px;
								text-transform: capitalize;
							}
							&.hours {
								padding-left: 18px;
								text-transform: uppercase;
								white-space: nowrap;
							}
						}
					}
				}
			}
		}
		h3 {
			&.title {
				margin: 0px 0px 10px;
				padding: 0px;
				font-size: 20px;				
			}
		}
		.location-contact-header {
			text-align: left;
		}
		.location-hours-header {
			text-align: left;
			font-size: 13px;
		}
		.results-buttons {
			margin-top: 10px;
		}
		p {
			&.address {
				font-size: 13px;
				line-height: 20px;				
				@media (max-width: 767px) {
					margin-bottom: 0px;
				}				
			}
		}
	}
}

.container {
	&.content-go-to-store {
		max-width: 118.75em;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
		h2 {
			&.title {
				margin: 15px 0px 15px 0px;
				font-family: Montserrat,Arial,sans-serif;
				font-size: 55px;
				font-weight: 500;
				letter-spacing: 1px;
			}
		}
		p {
			&.desc {
				margin: 15px 0px 15px 0px;
				font-size: 35px;
				font-weight: 500;	
				letter-spacing: 1px;
			}
		}
	}
}

.container {
	&.full-width-banner {
		max-width: 118.75em;
		text-align: left;
		margin-bottom: 50px;
		padding-left: 0px;
		padding-right: 0px;
		.slider-banner-all {
			max-width: 118.75em;
		}
	}
}

.home-bottom-list {
	position: relative;
	display: block;
	overflow: hidden;
	.banner-corp-home {
		text-align: center;
		h3 {
			&.title {
				font-size: 30px;
				line-height: 30px;				
				text-transform: uppercase;
				margin: 10px 0px 10px 0px;
				padding: 0px 0px 0px 0px;
			}
		}
		p {
			font-size: 25px;
			line-height: 25px;
			margin: 0px 0px 0px 0px;
			padding: 0px 0px 0px 0px;			
		}
	}
}


.our-family {
	position: relative;
	display: block;
	overflow: hidden;	
	.banner-corp-home {
		text-align: center;
		h3 {
			&.title {
				font-size: 30px;
				line-height: 30px;
				text-transform: none;
				margin: 10px 0px 10px 0px;
				padding: 0px 0px 0px 0px;
			}
		}
		p {
			font-size: 16px;
			line-height: 20px;			
			margin: 10px 0px 25px 0px;
			padding: 0px 0px 0px 0px;			
		}
	}
}

h2 {
	&.title-corp-home {
		position: relative;
		margin: 25px 0px 25px 0px;
		padding: 17px 10px 17px 10px;
		background-color: #4d4d4d;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 50px;
		line-height: 50px;
		color: #fff;
		text-align: center;
	}
}

.credit-app-page {
	.sfFormBox {
		position: relative;
		.sfError {
			font-size: 12px;
			margin-top: -15px;
			position: absolute;
		}		
	}
}

.monthly-summary-wrapper {
	display: table;
	width: 100%;
	margin-bottom: 15px;
}

.mthl-summary-all {
	margin-bottom: 15px;
	.roc-pageOptions {
		margin-top: -10px;
		padding: 2px 10px 2px 15px;
	}	
}

.summary-table {
	h4 {
		margin-top: 0px;		
		margin-bottom: 10px;
	}
}


/* Group Selections Table */
table { 
	&.group-selections-table {
		width: 100%; 
		border-collapse: collapse;
		margin-bottom: 25px;
		@media(min-width: 1280px) {
			table-layout: fixed;
		}		
		tr {
			&:nth-of-type(odd) { 
				background: #eee; 
			}
			th { 
				background: #333; 
				color: white; 
				font-weight: bold; 
				padding: 15px 10px;
				line-height: 20px;
			}			
			td, th { 
				border: 1px solid #ccc; 
				text-align: left; 
				@media(min-width: 980px) {
					text-align: center;
					vertical-align: middle;
				}
			}	
			td {
				padding: 6px 10px; 
				word-wrap: break-word;
			}
		}
	}
}

@media only screen and (max-width: 979px) {

	/* Force table to not be like tables anymore */
	table.group-selections-table, 
	table.group-selections-table thead, 
	table.group-selections-table tbody, 
	table.group-selections-table th, 
	table.group-selections-table td, 
	table.group-selections-table tr {
		display: block !important;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	table.group-selections-table thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	table.group-selections-table tr { border: 1px solid #ccc; }

	table.group-selections-table {
		tr {
			&:nth-of-type(odd) { 
				td {
					border-bottom: 1px solid #d7d7d7 !important;					
				}
			}
		}
	}
	
	table.group-selections-table td {
		/* Behave  like a "row" */
		border: none !important;
		border-bottom: 1px solid #eee !important;
		position: relative !important;
		text-align: right !important;
		vertical-align: bottom;
		display: table !important;
		width: 100%;
	}

	span.inline-block {
		display: inline-block;
		vertical-align: middle;
		margin-top: 5px;		
		margin-bottom: 5px;
	}
	
	table.group-selections-table td:before {
		/* Now like a table header */
		position: relative;
		/* Top/left values mimic padding */
		display: table-cell;
		text-align: left !important;
		vertical-align: middle;
		width: 50%;
		padding-left: 10px;
	}

	/*
	Label the data
	*/
	table.group-selections-table td:before {
		content: attr(data-content); 
		text-align: left !important; 
		font-weight: 700;
	}
    table.group-selections-table td .td-text {
		display: table-cell; 
		width: 50%;
		vertical-align: middle;
		padding: 2px 10px;
	}
    table.group-selections-table td:before {
		width: 50%; 
		padding-right: 0px; 
		text-align: left; 
		content: attr(data-content); 
		display: table-cell;
		vertical-align: middle;
	}
	
	table.group-selections-table input.quantity-field {
		margin-bottom: 5px !important;
	}	
	table.group-selections-table button.btn {
		width: 100%;
	}
	
}



@media only screen and (min-width:768px) and (max-width:1280px) {	
    .branchLocator-map-table .branchLocator-map-table-td-right {
        padding-left: 0px;
        width: 70%;
    }
}

/* 16px baseline (767px) */
@media only screen and (max-width:47.94em) {

    .location-mobile-view {
        display: block;
        text-align: center;
        color: #c00;
        font-weight: 700;
        cursor: pointer;
        margin-bottom: 15px;
        &:hover {
            color: #900;
        }
    }
    
    .branchLocatorsWrpr .map {
        width: 100% !important;
    }
    
    .branchLocator-map-table {
        display: block;
        width: 100%;
        .branchLocator-map-table-td-left {
            display: block; 
            padding-left: 0px;
            padding-right: 0px;
            background-color: #e6e6e6;
            width: 100%;
        }
        .branchLocator-map-table-td-right {
            display: block; 
            padding-left: 0px;
            width: 100%;
			height: 250px;
        }
    } 
}

@media only screen and (min-width: 768px) {

    .m-view-all {
        span {
            &.m-view-upc {
                display: block;
				margin-top: 4px;
            }			
        }
    }	
	
    .module-locators {
        .location-mobile-view {
            display: none;
        }
    }
    
    .branchLocator-map-table-td-left {
        &.open-search-btn-closed {
            .inner {
                display: block !important;
            }
        }        
    }
    
    .branchLocator-map-table {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		background-color: #e6e6e6;
		height: 800px;
        .branchLocator-map-table-td-left {
			padding: 0;
			width: 50%;
        }
        .branchLocator-map-table-td-right {
			padding-left: 0;
			padding-right: 0;
			width: 50%;        
        }
    }  
}

@include md {
	.branchLocator-map-table {	
		height: 570px;	
        .branchLocator-map-table-td-left {		
			width: 33.33%;
        }
        .branchLocator-map-table-td-right {		
			width: 66.66%;        
        }
    }  
}

@include lg {
	.branchLocator-map-table {
		height: 750px;
			
        .branchLocator-map-table-td-left {		
			width: 20%;
        }
        .branchLocator-map-table-td-right {		
			width: 80%;        
        }
    }  
}

.pageHeading {
	.title {
		&:empty {
			margin: 0;
			padding: 0;
		}		
	}	
}

#shipping-address-form,
#billing-address-form {
	.col-sm-3,
	.grid_6 {
		padding-left: 10px !important;
		padding-right: 10px !important;
		@media (max-width:767px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}			
	}
	.col-sm-3 {
		width: 25% !important;
		@media (max-width:767px) {
			width: 100% !important;
		}		
	}
	.col-sm-6,
	.col-sm-12 {
		@media (max-width:767px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}		
	}
	.col-sm-3 {
		@media (max-width:767px) {
			display: table;
			.railNavWrapper {
				margin-top: 25px;
			}			
		}
	}
	.col-sm-9 {
		@media (max-width:767px) {
			display: table;
		}			
	}	
}

/*.panel-body {
	&.no-padding {
		padding-left: 10px !important;
		padding-right: 10px !important;
		padding-bottom: 0 !important;
		&.billing {
			padding-left: 0 !important;
			padding-right: 0 !important;			
		}
		fieldset {
			padding-left: 10px !important;
			padding-right: 10px !important;		
			@media (max-width:767px) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}			
			fieldset {
				padding-left: 0 !important;
				padding-right: 0 !important;				
			}
		}
		.reviewItem {
			.foundHelpful {
				@media (max-width:767px) {
					margin-bottom: 15px;
				}					
			}			
		}		
		.reviewsWrapper {
			.itemWrapper {
				.itemContent {
					text-align: left;
				}				
			}
			.reviewContent {
				margin-top: 15px;
			}
		}
		.infoList-horizontal {
			li {
				&> {
					.title {
						padding-left: 10px;
						padding-right: 10px;
						margin-bottom: 0;
						@media (max-width:767px) {
							padding: 0;
							margin-bottom: 0;
						}						
					}
					.content {
						@media (max-width:767px) {
							display: table;
							padding-left: 0;
							padding-right: 0;	
						}											
					}
				}
			}
		}
		.col-sm-6 {
			@media (max-width:767px) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}	
		.itemWrapper {
			.itemContent {
				@media (min-width:768px) {
					text-align: left;
				}	
				.media {
					@media (max-width:767px) {
						display: table;
						margin-left: auto;
						margin-right: auto;
					}					
				}
			}		
		}
	}	
}*/

.infoList-horizontal {
	&.no-padding {
		li {
			&> {
				.title {
					padding-left: 0;
					padding-right: 0;
					margin-bottom: 0;
					@media (max-width:767px) {
						padding: 0;
						margin-bottom: 0;
					}						
				}
				.content {
					@media (max-width:767px) {
						display: table;
						padding-left: 0;
						padding-right: 0;	
					}											
				}
			}
		}
		.col-sm-6 {
			padding-left: 0;
			padding-right: 0;			
		}
	}
}


.productList {
	&.saved-list {
		.itemWrapper {
			.media {
				@media (max-width:767px) {
					display: table;
					margin-left: auto;
					margin-right: auto;
				}					
			}
			.itemAction {
				text-align: right;
				@media (max-width:767px) {
					text-align: center;
				}						
			}				
		}
		.itemContent {
			@media (min-width:768px) {
				text-align: left;
			}				
		}	
		.itemAddon {
			@media (max-width:767px) {
				text-align: center !important;
			}				
			.btn {
				&.btnSmall {
					&.btnTextAction {
						padding: 0 !important;
						color: #0a4e8c;
						text-decoration: none;				
						text-align: right;
						display: inline;
						box-decoration-break: clone;
						transition: all 0.5s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;	
						&:hover,
						&:focus {
							background-color: transparent !important;
							box-shadow: none !important;
							text-decoration: none;
							color: #07335c;
							background-size: 100% 1px;
						}				
					}			
				}
			}	
		}		
	}
}	

.container {
	.row {
		.col-sm-3 {
			@media (max-width:767px) {
			
				.railNavWrapper {
					margin-top: 15px;
				}
			}
		}
		.col-sm-9 {
			// @media (max-width:767px) {
			// 	display: table-header-group;
			// }			
		}
	}
}

.row {
	&.custom-table-row-4 {
		float: none;
		display: table;
		table-layout: fixed;
		width: 100%;
		.col-sm-4 {
			float: none;
			display: table-cell;
			vertical-align: top;		
			width: inherit;
			padding: 10px 3%;
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
			@media (max-width:47.94em) {
				display: table;
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}
		.content-col-in  {
			font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
			margin-top: 30px;
			@media (max-width:47.94em) {
				margin-top: 0;
			}			
			h2 {
				&.title {
					display: block;
					margin: 0 0 20px;
					padding: 0;
					font-family: Montserrat,Arial,sans-serif;
					font-size: 24px;
					font-weight: 300;
					text-align: left;
					color: #444;
					text-transform: uppercase;
					width: 100%;
					line-height: 30px;
					@media (max-width:47.94em) {
						margin-bottom: 10px;
					}						
				}
			}
			p {
				font-family: Lato,sans-serif;
				color: #444;
				font-size: 18px;
				font-weight: 300;
				line-height: 27px;		
				&:first-of-type {
					padding-top: 0.3em;					
				}
			}
		}		
	}
}

.rocModule-content.custom-style {
	.pagination {
		display: block;
		float: none;
		margin: 0;
		text-align: center;
		@include sm {
			padding-left: 20px;		   
		}
		.roc-pageOptions-count {
			width: 100%;
		}
	}
	.roc-pageOptions {
		width: 100%;
		.roc-pageOptions-filter {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			text-align: center;
			@include sm {
			   justify-content: flex-start;
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			input {
				margin: 0;
			}
		}
	}
}

@media (min-width: 768px ) {
	.rocModule-content.custom-style {
		.pagination {			
			order: 2;
			text-align: right;
			flex: 1;
		}
		.roc-pageOptions {
			width: auto;
			margin: 0;
			.roc-pageOptions-filter {
				text-align: left;
			}
		}
	}
}

@media (min-width: 992px ) {
	.rocModule-content.custom-style {
		.pagination {
			.roc-pageOptions-count {
				width: auto;
				margin: 13px 0 0 0;
				padding: 0;
			}
		}
	}
}

.quickOrderWrapper {	
	max-width: 100%;
	@include sm {
		margin: 0 auto;
		padding: 0 30px;   
	}	
}

.quickOrder .media {
	width: 180px;
    max-width: 100%;
	margin: 0 auto;
	@include lg {
	   margin: 0;
	}
}

ul.ui-autocomplete {
	z-index: 10000;
}

.addThisWrapper {
	text-align: center;
	margin-top: 28px;
	@include sm {
		float: right;	   
	}
}

.reorder-pad-wrapper {
	text-align: center;
	@include sm {
	   text-align: left;
	}
	.productAddToCart {
		@include sm {
		
		}
	}
}

.eventsWidget-image {
	display: block;
	width: 100%;
}

/* loading icon */

.blockUI+.blockUI.blockOverlay,
.blockUI+.blockUI.blockOverlay+.blockUI.blockMsg.blockPage {
	display: none !important;
}

.load-wrap {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;	
	height: 0%;
	opacity: 0;
	z-index: 9999999;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;	
	.load-spinner-wrap {
		display: none;
		width: 100%;
		max-width: 50%;
		margin: 18% auto 0;
		.load-tr,
		.text-tr {
			display: table-row;
		}		
		.load-spinner {
			display: table-cell;
			vertical-align: bottom;
			padding-bottom: 115px;
			.load-gear {
				position: absolute;
				left: 47%;
				z-index: 1;
				@media (max-width: 480px) {
					left: 30%;
				}
			}	
			.gear {
				&.one {
					fill: rgba(189, 215, 239, 0.1);
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-webkit-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;				
				}
				&.two {
					fill: rgba(228, 112, 30, 0.1);
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-webkit-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
				}
				&.three {
					fill: rgba(8, 73, 133, 0.1);
					-moz-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					-webkit-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
				}				
			}	
		}
		.text {
			display: table-cell;
			vertical-align: top;	
			color: rgba(189, 215, 239, 0.5);
			font-size: 30px;
			font-weight: 700;
			font-family: 'Open Sans', sans-serif;
			text-align: center;
		}		
	}
	&.spinner-on {
		//background-color: rgba(0, 0, 0, 0.7);
		height: 150%;
		opacity: 1;
		.load-spinner {
			.gear {
				&.one {
					fill: rgba(189, 215, 239, 0.5);
				}
				&.two {
					fill: rgba(228, 112, 30, 0.7);
				}
				&.three {
					fill: rgba(8, 73, 133, 0.7);
				}				
			}			
		}		
	}	
}

//.body-overflow-hidden {
//	overflow: hidden;
//	background-color: #f1f1f1;
//	body {
//		padding-right: 17px;	
//	}
//	.load-wrap {	
//		height: 150%;
//		.load-spinner-wrap {
//			display: table;
//		}
//	}
//}

.body-overflow-hidden {
	@media (min-width: 940px) {
		overflow: hidden;
	}	
	background: #f1f1f1;
//	.site-container {
//		@media (min-width: 940px) {
//			padding-right: 17px;
//		}		
//	}
	.load-wrap {	
		height: 150%;
		.load-spinner-wrap {
			display: table;
		}
	}
	.load-spiner-bg-on {
		background: rgba(0, 0, 0, 0.7);
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 9999991;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
	.body-overflow-hidden {
		overflow: hidden;
		background: #f1f1f1;
//		.site-container {
//			padding-right: 0;	
//		}
	}
}

.sfPageEditorWrp {
	.body-overflow-hidden {
		overflow: visible;
		background: #f1f1f1;
		.site-container {
			padding-right: 0;	
		}
		.load-wrap {
			display: none;
		}
		.load-spiner-bg-on {
			display: none;
		}
	}	
}

.load-wrap .load-spinner .gear {
	position: absolute;
	z-index: -10;
	width: 40px;
	height: 40px;
	-webkit-animation: spin 5s infinite;
	  animation: spin 5s infinite;
}

.load-wrap .load-spinner .two {
	left: 40px;
	width: 80px;
	height: 80px;
	-webkit-animation: spin-reverse 5s infinite;
	animation: spin-reverse 5s infinite;
}

.load-wrap .load-spinner .three {
	top: 45px;
	left: -10px;
	width: 60px;
	height: 60px;
}

.profiler-results {
	display: none;
}

html {
	&.body-overflow-hidden {
		&.sfPageEditorWrp {
			overflow: visible !important;
			.sfPageEditor {
				.site-container {
					padding-right: 0px !important;
				}
				.load-wrap,
				.load-spiner-bg-on {	
					display: none !important;
				}					
			}
		}
	}
}



/* =================================================================
                        SMC Electric - Phase 2 
===================================================================*/

.icon-lightbulb {
	@include home-icons;
	width: 100px;
	height: 100px;
}
.icon-manufacturing {
	@include home-icons;
	width: 94px;
	height: 94px;
}

.icon-automation {
	@include home-icons;
}
.icon-safety {
	@include home-icons;
}
.icon-security {
	@include home-icons;
}
.icon-efficiency {
	@include home-icons;	
}
.icon-atomation {
	@include home-icons;		
}
.icon-utilities {
	@include home-icons;		
}
.icon-smc {
	@include home-icons;
	width: 115px;
	height: 115px;	
}
.icon-locations {
	@include home-icons;
	width: 115px;
	height: 115px;		
}
.icon-expert {
	@include home-icons;
	width: 115px;
	height: 115px;		
}
.icon-training {
	@include home-icons;
	width: 115px;
	height: 115px;	
}

.max-width-1300 {
	max-width: 1340px;
}

.center {
	text-align: center;
}

.content-block-1 {
	display: block;
	margin: 1% 0 5%;
	@media (max-width: 767px) {
		margin-bottom: 25px !important;
	}	
	h2  {
		display: block;
		font-family: 'SemplicitaPro-Semibold';
		font-size: 24px;
		font-weight: 600;
		text-transform: none;
		color: #e4701e;
		margin: 0 0 35px;
		padding: 0;
	}
}

.fbox {
	display: grid;
	grid-gap: 25px 10px;
	justify-items: center;
	&.fbox-col-8 {
        grid-template-columns: auto auto auto auto auto auto auto auto;
		@media (min-width: 768px) and (max-width: 1339px) {
			grid-template-columns: auto auto auto auto;
		}
		@media (min-width: 600px) and (max-width: 767px) {
			grid-template-columns: auto auto auto;
		}	
		@media (min-width: 480px) and (max-width: 599px) {
			grid-template-columns: auto auto;
		}	
		@media (max-width: 479px) {
			grid-template-columns: auto;
		}			
	}
	&.fbox-col-6 {
        grid-template-columns: auto auto auto auto auto auto;
		@media (min-width: 768px) and (max-width: 1339px) {
			grid-template-columns: auto auto auto auto;
		}
		@media (min-width: 600px) and (max-width: 767px) {
			grid-template-columns: auto auto auto;
		}	
		@media (min-width: 480px) and (max-width: 599px) {
			grid-template-columns: auto auto;
		}	
		@media (max-width: 479px) {
			grid-template-columns: auto;
		}			
	}	
	&.fbox-col-4 {
        grid-template-columns: auto auto auto auto;
		@media (min-width: 600px) and (max-width: 1339px) {
			grid-template-columns: auto auto auto auto;
		}	
		@media (min-width: 480px) and (max-width: 599px) {
			grid-template-columns: auto auto;
		}	
		@media (max-width: 479px) {
			grid-template-columns: auto;
		}			
	}	
}

.fbox-item {
	text-align: center;
	/* IE10+ specific styles go here */
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		display: inline-block;
		width: 24%;
		margin: 0;
	}
    .home-icon {
        display: block;
        margin: 0 auto 18px;
        @include home-icons;
	}
	
	.home-icon.icon-pharma {
		width: 115px;
		height: 115px;
		margin-top: -20px;

	}

    .icon {
        display: block;
        margin: 0 auto 18px;
		transition: all .2s ease-in-out;
		fill: #fff;
		stroke: #fff;
		stroke-width: .25px;
		width: 120px;
		height: 120px;
		@media(max-width:980px) {
			width: 70px;
			height: 70px;
		}		
	}	
	


    .title-item-1 {
	    color: $dark-gray;
		font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
        font-size: .85em;
        font-weight: 600;
        line-height: 1.5em;
        margin: 0;

        span {
			display: block;
            margin: 20px 0 15px 0;
            transition: all .2s ease-in-out;
        }

        a {
            display: block;
			color: $dark-gray;
            -webkit-transform: scale(1.04);
            -ms-transform: scale(1.04);
            transform: scale(1.04);
            transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

            &:hover {
                color: $color-secondary;
				-webkit-transform: scale(1.15);
				-ms-transform: scale(1.15);
				transform: scale(1.15);

				.icon-smc, .icon-locations, .icon-expert, .icon-training {
					fill: $color-secondary;
					stroke: $color-secondary;
				}

                .home-icon {
                    fill: $color-secondary;
					stroke: $color-secondary;
                }
            }
        }
    }
	.title-item-3 {
		color: #fff;
		font-size: .85em;
		font-weight: 600;
		line-height: 1.5em;
		margin: 0;

		span {
			display: block;
			margin: 20px 0 15px 0;
			transition: all .2s ease-in-out;
			&.title-item {
				display: block;
				color: #fff;
				-webkit-transform: scale(1.04);
				-ms-transform: scale(1.04);
				transform: scale(1.04);
				transition: all 1s cubic-bezier(.25,.46,.45,.94);				
			}
		}

		a {
			display: block;
			color: #fff;
			-webkit-transform: scale(1.04);
			-ms-transform: scale(1.04);
			transform: scale(1.04);
			transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			
			&:hover {
				color: #fff;
				-webkit-transform: scale(1.15);
				-ms-transform: scale(1.15);
				transform: scale(1.15);

				.icon {
					fill: #fff;
					stroke: #fff;
				}
			}
		}
	}	
}

.content-block-1 {
	.title-item-1 {
        a {
            cursor:default;

            &:hover {
                color: $dark-gray;
				-webkit-transform: scale(1.04);
				-ms-transform: scale(1.04);
				transform: scale(1.04);

                .home-icon {
                    fill: $dark-gray;
					stroke: $dark-gray;
                }
            }
        }
    }
	.title-item-3 {
		a {
			cursor: default;
			
			&:hover {
				color: $dark-gray;
				-webkit-transform: scale(1.04);
				-ms-transform: scale(1.04);
				transform: scale(1.04);

				.icon {
					fill: $dark-gray;
					stroke: $dark-gray;
				}
			}
		}
	}
}

.white-popup {
	&.video-modal {
		max-width: 980px !important;
	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 25px;	
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}	
}

.content-block-video {
	position: relative;
	display: block;
	margin: 0 0 3%;
	@media (max-width: 767px) {
		margin-bottom: 0 !important;
	}
	.content-in-img {
		display: block;
		text-align: center;
		
		img {
			margin: 0;
			@media (max-width: 767px) {
				min-height: 200px;
			}			
		}
	}
	.content-in {
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		.content-in-table {
			display: table;
			width: 100%;
			height: 100%;			
			.content-in-td {
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				padding: 0 15px;
			}
		}
		h2 {
			display: block;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 40px;
			font-weight: 600;
			line-height: 40px;
			color: #fff;
			margin: 30px 0;
			@media (min-width: 768px) and (max-width: 1279px) {
				margin: 15px 0;
			}
			@media (max-width: 767px) {
				margin: 15px 0 10px;
				font-size: 25px;
				line-height: 25px;
			}
		}
		p {
			display: block;
			font-size: 24px;
			font-weight: 500;
			line-height: 34px;
			color: #fff;
			margin: 30px 0;
			@media (min-width: 768px) and (max-width: 1279px) {
				margin: 15px 0;
			}
			@media (max-width: 767px) {
				margin: 15px 0;
				font-size: 14px;
				line-height: 17px;
			}			
		}
		.btn {
			padding: 0;
			background-color: transparent;
			border: none;
			border-radius: 0;
			width: auto;
			height: 0;
			&:hover,
			&:focus {
				box-shadow: 0 0 0 rgba(0,0,0,0);				
			}
		}
		.icon-play {
			display: block;
			margin: 0 auto;
			fill: #fff;
			width: 100px;
			height: 100px;
			-webkit-transform: scale(1.04);
			-ms-transform: scale(1.04);
			transform: scale(1.04);
			transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			@media (min-width: 768px) and (max-width: 1279px) {
				width: 60px;
				height: 60px;
			}
			@media (max-width: 767px) {
				width: 40px;
				height: 40px;
			}			
		}
		.play-video {
			display: block;
			margin: 10px 0 0;
			text-transform: none;
			transition: all .5s ease-in-out;
			color: rgba(255, 255, 255, 0);
		}
		a {
			&:hover,
			&:focus {
				.play-video {
					color: #fff;
				}
				.icon-play {
					-webkit-transform: scale(1.15);
					-ms-transform: scale(1.15);
					transform: scale(1.15);							
				}		
			}
		}
	}
	
}

.content-block-2 {
	position: relative;
	display: block;
	margin: 0 0 4%;
    background-image: none;
    background-repeat: no-repeat;
	background-position: center right -20%;
	@media (max-width: 767px) {
		margin-bottom: 25px !important;
	}
	@media (max-width: 979px) {
		background-position: center bottom;
	}	
}
.content-block-table-wrap {
	position: relative;
	display: block;
	margin: 0 0 4%;
	@media (max-width: 767px) {
		margin-bottom: 25px !important;
	}
}
.content-block-in {
	display: block;
	position: relative;
	margin: 0 auto;
	max-width: 1340px;
	@media (max-width: 767px) {
		padding: 0 15px;
	}	
}

.company-about-pg-wrapper {
	.smc-approach-wrapper {
		padding-top: 40px !important;
	}
}

.content-block-table {
	display: table;
	table-layout: fixed;
	width: 100%;
	margin: 0 0 8%;
	@media (max-width: 979px) {
		margin-bottom: 30px;
	}	
	.content-block-table-td {
		display: table-cell;
		vertical-align: top;
		@media (max-width: 979px) {
			display: block;
		}		
		+.content-block-table-td {
			vertical-align: bottom;
		}
		&:first-child {
			width: 55%;
			@media (max-width: 979px) {
				width: 100%;
			}			
		}
		.image-left-mrg-40 {
			position: relative;
			display: block;
			z-index: 1;
			margin: 0 0 -40px -40px;
			@media (min-width: 768px) and (max-width: 979px) {
				margin: -40px auto 0 auto;
				text-align: center;
			}
			@media (max-width: 767px) {
				margin: -20px auto 0 auto;
				text-align: center;
			}			
		}
		img {
			-webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
			-moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
			box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);			
		}
	}
}

.content-block-table-wrap {
	position: relative;
	display: block;
	margin: 0 0 3%;
	@media (max-width: 767px) {
		margin-bottom: 25px !important;
	}
	.content-block-in {
		max-width: 1300px;
		padding: 0 15px;
		.content-block-table {
			margin-bottom: 0;
			.content-block-table-td {
				@media (min-width: 980px) {
					display: table-cell;
					vertical-align: top;
				}				
				@media (max-width: 979px) {
					display: block;
				}		
				+.content-block-table-td {
					vertical-align: bottom;
				}
				&:first-child {
					width: 55%;
					@media (max-width: 979px) {
						width: 100%;
					}			
				}
				.image-left-mrg-40 {
					position: relative;
					display: block;
					z-index: 1;
					margin: 0 0 -40px -40px;
					@media (min-width: 768px) and (max-width: 979px) {
						margin: -40px auto 0 auto;
						text-align: center;
						background-color: #f6f6f6;
					}
					@media (max-width: 767px) {
						margin: -20px auto 0 auto;
						text-align: center;
						background-color: #f6f6f6;
					}			
				}
				img {
					-webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
					-moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
					box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);			
				}
			}			
		}
		&.image-left-block {
			.content-block-table {	
				@media (max-width:979px) {
					display: flex;
					flex-flow: column;
				}						
				.content-block-table-td {
					vertical-align: top;
					@media (max-width:979px) {
						order: 2;
					}						
					+.content-block-table-td {
						vertical-align: bottom;
						@media (max-width:979px) {
							order: 1;
						}						
					}
					&:first-child {
						width: 45%;
						@media (max-width: 979px) {
							width: 100%;
						}			
					}
					.image-right-mrg-40 {
						position: relative;
						display: block;
						z-index: 1;
						margin: 0 0 -40px 0;
						@media (min-width: 768px) and (max-width: 979px) {
							margin: -40px auto 0 auto;
							text-align: center;
						    background-color: #f6f6f6;
						}
						@media (max-width: 767px) {
							margin: -20px auto 0 auto;
							text-align: center;
							background-color: #f6f6f6;
						}			
					}
					.content-block-in-wrap {
						margin-top: 40px;
						margin-left: -40px;
						padding-left: 13%;
						@media (max-width: 979px) {
							margin-top: 0;
							margin-left: 0;
							padding-left: 7%;
						}						
					}
				}
			}
		}		
	}
}


.content-block-in-wrap {
	position: relative;	
	display: block;
	margin: 0;
	padding: 7% 10% 7% 7%;
	background-color: #f6f6f6;
	color: #333;
	@media (max-width: 979px) {
		padding-bottom: 20px;
	}	
	h2 {
		&.title {
			display: block;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 40px;
			font-weight: 800;
			line-height: 45px;
			color: #333;
			padding-bottom: 37px;
			margin: 0 0 35px;
			@media (max-width: 767px) {
				margin: -20px auto 0 auto;
				text-align: left;
			}
			@media (min-width:980px) and (max-width:1280px) {			
				font-size: 30px;
				line-height: 30px;				
			}
			@media (min-width:768px) and (max-width:979px) {		
				font-size: 25px;
				line-height: 25px;				
			}
			@media (max-width:480px) {
				margin: 0 0 12px;
				padding: 0 0 15px 0;				
				font-size: 25px;
				line-height: 25px;				
			}			
			span {
				display: block;
				font-family: SemplicitaPro-Semibold;
				font-size: 24px;
				font-weight: 600;
				line-height: 28px;
				color: #e4701e;
				margin: 0 0 25px;
				@media (max-width:480px) {
					margin-bottom: 5px;				
				}					
			}
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 80px;
				height: 5px;
				background-color: #e4701e;
			}			
		}
	}
	.dsc {
		display: block;
		margin: 0 0 30px;
		font-size: 16px;
		line-height: 25px;
	}
	.btn-wrap {
		display: block;
		margin: 0 0 20px;
		.btn {
			display: table;
			min-width: 173px;
			min-height: 46px;
			font-family: $font-stack;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			text-shadow: 1px 0 0 rgba(10, 78, 140, 0);
			text-transform: none;
			padding: 0;
			color: #fff;
			background-color: #0a4e8c;
			border-color: rgba(10, 78, 140, 0);
			border-radius: 0;
			-webkit-transform: perspective(1px) scale(1.04);
			-ms-transform: perspective(1px) scale(1.04);
			transform: perspective(1px) scale(1.04);
			transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			@media (min-width:1281px) and (max-width:1599px) {				
				min-width: 140px;
				min-height: 35px;			
			}
			@media (min-width:980px) and (max-width:1280px) {
				min-width: 120px;
				min-height: 25px;
				font-size: 14px;
				line-height: 18px;				
			}
			@media (min-width:768px) and (max-width:979px) {
				min-width: 100px;
				min-height: 15px;
				font-size: 12px;
				line-height: 16px;				
			}
			@media (max-width:480px) {
				min-width: 100px;
				min-height: 15px;
				font-size: 12px;
				line-height: 16px;		
			}
			span {
				display: table-cell;
				vertical-align: middle;
				border: none;
				padding: 8px;
			}
			&:hover,
			&:focus {
				background-color: rgba(228, 112, 30, 0.7);
				border-color: rgba(228, 112, 30, 1);
				-moz-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
				-webkit-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
				-o-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);		
				box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
				-webkit-transform: perspective(1px) scale(1.08);
				-ms-transform: perspective(1px) scale(1.08);
				transform: perspective(1px) scale(1.08);					
			}
		}
	}
}

.container-news-bg {
	display: block;
	position: relative;
	background-image: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 4% 15px 3.4%;
	@media (max-width:479px) {
		padding-top: 30px;
		padding-bottom: 30px;		
	}	
	h2 {
		display: block;
		margin: 0 0 4%;
		padding: 0;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 40px;
		font-weight: 800;
		line-height: 40px;		
		text-align: center;
		color: #fff;
		@media (max-width: 767px) {
			margin-bottom: 3.5%;
		}
		@media (max-width:479px) {
			font-size: 25px;
			line-height: 30px;
			margin-bottom: 15px;
		}		
	}
	.fbox-col-2 {
		h2 {
			display: table;
			margin: 0 0 20px;
			padding: 0;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 24px;
			font-weight: 600;
			line-height: 32px;		
			text-align: left;
			color: #fff;
			>span {
				position: relative;
				display: table-cell;
				vertical-align: middle;
				padding-left: 15px;
				font-family: SemplicitaPro-Semibold;
				@media (max-width:479px) {
					padding-left: 5px;
				}				
				&:first-child {
					padding-left: 0;
					width: 30px;
				}
			}			
		}
		.icon-latest-news {		
			display: block;
			fill: #ff8b3a;
			width: 30px;
			height: 21px;		
		}
		.icon-latest-insights {
			display: block;		
			fill: #ff8b3a;
			width: 22px;
			height: 25px;		
		}
		+.fbox-col-2 {
			@media (max-width:767px) {
				margin-top: 20px;
			}			
		}
	}
}

.container-branch-bg {
	display: block;
	position: relative;
	background-image: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 4% 15px 3.4%;
}

.solutions-pg-wrapper  {
    .interior-callout-item {
		display: inline !important;
		padding-bottom: 60px;
		position: relative;

		.interior-callout-btn {
			position: absolute;
			bottom: 0;
		}
		
		.interior-callout-title {
			width: 100%;
			text-align: center;
		}
    }
}

.container-branch-table {
	position: relative;
	display: table;
	width: 100%;
	max-width: 1340px;	
	margin: 0 auto;
	.container-branch-table-td {
		display: table-cell;
		vertical-align: middle;
		padding: 10px;
		@media (max-width:767px) {		
			display: block;
			padding: 0 15px;
			+.container-branch-table-td {
				padding-bottom: 15px;
				+.container-branch-table-td {
					padding-bottom: 0;
				}				
			}
		}
		&:first-child {
			@media (max-width:480px) {		
				padding-top: 15px !important;
			}			
		}
		&:last-child {
			width: 175px;
			@media (max-width:767px) {		
				width: 100%;
			}
			@media (max-width:480px) {		
				padding-bottom: 15px !important;
			}			
		}
		h2 {
			display: block;
			margin: 0;
			padding: 0;
			font-family: SemplicitaPro-Semibold;
			font-size: 40px;
			font-weight: 800;
			line-height: 40px;		
			text-align: left;
			color: #fff;
			@media (min-width:980px) and (max-width:1280px) {			
				font-size: 30px;
				line-height: 30px;				
			}
			@media (min-width:768px) and (max-width:979px) {		
				font-size: 25px;
				line-height: 25px;		
				text-align: center;
			}
			@media (max-width: 767px) {
				text-align: center;
			}	
			@media (max-width: 480px) {
				font-size: 30px;
				line-height: 30px;
				margin-bottom: 15px;
			}			
		}
		p {
			display: block;
			font-size: 24px;
			font-weight: 500;
			line-height: 34px;
			color: #fff;
			margin: 5px 0 0;
			@media (min-width: 768px) and (max-width: 1279px) {
				font-size: 18px;
				line-height: 20px;
			}
			@media (max-width:979px) {		
				text-align: center;
			}			
			@media (max-width: 480px) {
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 5px;
			}			
		}
		.btn-wrap {
			margin: 0;
			.btn {
				position: relative;
				display: table;
				min-width: 173px;
				min-height: 46px;
				font-family: $font-stack;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				text-transform: none;
				padding: 0;
				color: #0a4e8c;
				text-align: center;
				background-color: transparent;
				border-radius: 0;
				border: none;
				@media (min-width:768px) and (max-width:979px) {
					min-width: 100px;
					min-height: 15px;
					font-size: 12px;
					line-height: 16px;				
				}
				@media (max-width:767px) {		
					margin-left: auto;
					margin-right: auto;
				}				
				@media (max-width:480px) {
					min-width: 100px;
					min-height: 15px;
					font-size: 12px;
					line-height: 16px;		
				}					
				span {
					&.text-wrap {
						position: relative;
						display: table-cell;
						vertical-align: middle;
						border: none;
						padding: 8px;
						&:before {
							content: '';
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							width: 100%;
							height: 100%;
							background-color: #fff;
							text-shadow: 1px 0 0 rgba(10, 78, 140, 0);
							border: 1px solid rgba(255, 255, 255, 1);								
							-webkit-transform: scale(1.04);
							-ms-transform: scale(1.04);
							transform: scale(1.04);
							transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);									
						}
					}
					&.text-wrap-in {
						position: relative;
						z-index: 1;
					}						
				}
				&:hover,
				&:focus {
					color: #fff;
					span {
						&.text-wrap {
							&:before {
								background-color: rgba(228, 112, 30, 1);
								border: 1px solid rgba(255, 255, 255, 1);									
								-moz-box-shadow: 0 0 10px rgba(105, 105, 105, 0.5);
								-webkit-box-shadow: 0 0 10px rgba(105, 105, 105, 0.5);
								-o-box-shadow: 0 0 10px rgba(105, 105, 105, 0.5);		
								box-shadow: 0 0 10px rgba(105, 105, 105, 0.5);
								-webkit-transform: scale(1.08);
								-ms-transform: scale(1.08);
								transform: scale(1.08);									
							}
						}
					}
				}
			}			
		}
	}
}

/* Accordions */

.e-Learning-training-accordion-child-content {
	display: none;
}

.e-Learning-training-accordion-button.open ~ .e-Learning-training-accordion-child-content {
	display: block;
}

.e-Learning-training-accordion-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #eee;
	font-size: 1.125rem;
	padding: .5rem 1rem;
	transition: all .3s;

	&::after {
	content: "";
	width: 10px;
	height: 10px;
	border-top: 3px solid black;
	border-right: 3px solid black;
	padding: .2rem;
	transform: rotate(315deg);
	transition: all .3s;
	}

	&.open {
		&::after {
			transform: rotate(135deg);
		}
	}
}

.e-Learning-training-accordion-child-content {
	img {
		min-width: 100%;
		max-height: 25rem;
		object-fit: cover;
	}
}

.e-learning-training-content-section-wrapper {
	@media(min-width: 1024px) {
		display: flex;
		margin: 0 auto 20px;
		max-width: 1200px;
	}
	@media(max-width: 1023px) {
		margin-bottom: 20px;
	}

	&--upper {
		margin-bottom: 0;
		.e-learning-training-content-subsection-wrapper {
			border-bottom: 1px solid $color-black;
			&:first-child {
				@media(max-width: 1023px) {
					border-bottom: none;
				}				
			}
		}
	}
	
	h3 {
		font-size: 1rem;
	}
}

.e-learning-training-content-subsection-wrapper {
	@media(min-width: 1024px) {
		flex: 0 0 50%;
	}
}



@-webkit-keyframes spin {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  50% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes spin-reverse {
  50% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

.non-bold{
    font-weight : 300;
}

.branch-location-map-wrapper {
	.branch-map-info-certs-container {
		display: none;
	}
}

.smc-pdf-viewer{
	margin: 15px 0;
	.smc-preview{
		position: relative;
		margin-right: 5px;
		&:before {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			background-image: url(/ResourcePackages/Roc/assets/images/file-pdf.png);
			background-size: contain;
			background-repeat: no-repeat;
			margin-right: 5px;
		}
	}
}

.smc-pdf{
	width: 100%;
	height: 100%;
	min-height: 500px;
	border: none;
}
#site-container-blank.site-container {
	margin: 20px auto;
    padding: 30px;
    width: 920px;
    background-color: #fff;
	max-width:100%;
	.siteBody.clearfix {
		padding-top:0;
		border-top:none;
	}
}