.siteBody {
    overflow: hidden;
}
.gird-banners-parent-wrap-wrap {
    margin-left: -10px;
    margin-right: -10px;
}
.gird-banners-parent-wrap {
    max-width: 1920px;
    margin: 0 auto;
    #awebChromeHelper {
        display: none !important;
    }
}
.gird-banners {
    display: grid;
    grid-gap: 10px;
    margin-bottom: 20px;

    @media (min-width:980px) {
        margin-bottom: 30px;
    }	

    @media (min-width:768px) {
        grid-template-columns: 1fr 50%;
        grid-template-rows: 2fr 40%;
        grid-gap: 10px;
    }

    @media (min-width:768px) and (max-width:1279px) {
        grid-template-rows: 2fr 50%;
    }      
    @media (min-width:1280px) and (max-width:1365px) {
        grid-template-columns: 1fr 50%;
        grid-template-rows: 2fr 40%;
        h2 {
            font-size:1em;
        }
        .simple-banner-content {
            max-height: 100%;
        }
        .simple-banner-wrap {
            max-height: unset;
            overflow: hidden;
            .banner-link {
                height:100%;
            }
        }
    }  

    .simple-banner-media {
        position: relative;
        display: block;
        height: 100%;        

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(55,55,55,0.6);
        }

        img {
            min-width: 100% !important;
            min-height: 100% !important;
            width: auto !important;
            height: auto !important;
        }

        .simple-banner-content {
            position: absolute;                     
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            padding: 15px 15px 20px;
            color: #fff;
            z-index: 1;

            @media (min-width:768px) and (max-width:1279px) {
                padding: 20px;
            }	            

            @media (min-width:1280px) {
                padding: 40px;
            }	

            .simple-banner-title {
                font-family: 'SemplicitaPro-Semibold','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
                font-weight: 700;
                line-height: 1.1;
                text-transform: uppercase;
                letter-spacing: 1px;

                @media (min-width:1280px) {
                    font-size: 2rem;
                }	                

                @media (max-width:979px) {
                    font-size: 1.2rem;
                }  

                h2 {
                    font-family: 'SemplicitaPro-Semibold','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
                    font-weight: 700;
                    line-height: 1.1;
                    text-transform: uppercase;       
                    letter-spacing: 1px;             
                    margin: 0;
                }
            }

            .btn {
                font-family: 'SemplicitaPro-Medium','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
                font-size: 1rem;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding-left: 20px;
                padding-right: 20px;
                min-width: 290px;
                margin-top: 40px;

                @media (max-width:979px) {
                    font-size: .875rem;
                    margin-top: 20px;
                } 

            }
        }
    }

    .gird-banner {
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 1.5em;

        .simple-banner-wrap {
            flex: 1;
        }
        
        .simple-banner-container {
            display: flex;
            flex: 1;          

            .banner-link {
                display: block;
                img {
                    object-fit: cover;
                }

                &:hover,
                &:focus {
                    .btn {
                        background-color: #e4701e;
                        box-shadow: 0 0 10px rgba(228,112,30,.5);
                        transform: perspective(1px) scale(1.08);
                    }
                }
            }
        }        

        &:first-child {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            height: 100%;

            .simple-banner-wrap {
                height: 100%;   
                
                @media (min-width:768px) and (max-width:1279px) {
                    height: calc(100% - 5px);
                }       
                @media (min-width:1000px) and (max-width:1279px) {
                    .simple-banner-content {
                        bottom: 55px;
                        h2 {
                            font-size:1.2em;
                        }
                    }
                } 
                @media (min-width:1000px) and (max-width:1599px) {
                    .simple-banner-media {
                        max-height:633px;
                        img {
                            max-height:633px;
                        }
                    }
                }        
                              

            }
            
            .simple-banner-container {
                display: block;
                height: 100%;

                .banner-link {
                    height: 100%;
                    max-height: none;

                    img {
                        object-fit: cover;
                    }
                }
            }
        }

        &:nth-child(2) {

            .simple-banner-wrap {

                @media (min-width:1280px) {
                    max-height: 100%;
                }	
                @media (min-width:1600px) {
                    max-height: 367px;
                }
                @media (min-width:1000px) and (max-width:1599px) {
                    .simple-banner-media {
                        max-height:330px;
                        overflow:hidden;
                        img {
                            max-height:330px;
                        }
                    }
                }   	 
                @media (min-width:1000px) and (max-width:1279px) {
                    .simple-banner-content {
                        h2 {
                            font-size:1.2em;
                        }
                    }
                }                

                .banner-link {
                    img {

                        @media (min-width:1280px) {
                            max-height: 100%;
                        }	   
                        @media (min-width:1600px) {
                            max-height: 367px;
                        }	                     
                    }
                }

            }
        }

        &:nth-child(3) {

            .simple-banner-wrap {

                @media (min-width:1280px) {
                    max-height: 100%;
                }	
                @media (min-width:1000px) and (max-width:1599px) {
                    .simple-banner-media {
                        max-height:229px;
                        overflow:hidden;
                        img {
                            max-height:229px;
                        }
                    }
                } 
                @media (min-width:1000px) and (max-width:1279px) {
                    .simple-banner-content {
                        h2 {
                            font-size:1.2em;
                        }
                    }
                } 
                .banner-link {
                    img {

                        @media (min-width:1280px) {
                            max-height: 251px;
                        }                        
                    }
                }

            }
        }        
        
    }
    @media (min-width:1000px) and (max-width:1599px) {
        overflow:hidden;
    }
}

.sfPageEditorWrp {
    .gird-banners-parent-wrap {
        margin-left: 0;
        margin-right: 0;
    }
    .gird-banners {
        display: block !important;

        > div {
            display: grid;
            grid-template-columns: 1fr 50%;
            grid-template-rows: 2fr 40%;
            grid-gap: 10px;
        }        
    }
}


a,
button,
input {
    &:focus-visible {
        outline: 2px solid #000;
        outline-offset: 1px;
    }
}