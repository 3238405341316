/********************************************************************

Project: Formee - http://www.formeeee.org
Launched: 
Version: 3.1
Authors: Bernard De Luna - http://www.bernarddeluna.com
         Daniel Araujo - http://www.dnlaraujo.com.br
		 Marcello Manso - http://www.marcellomanso.com.br

********************************************************************
Structure
********************************************************************/

/* fix  ff bugs */
.formee:after, .formee div:after, .formee ol:after, .formee ul:after, .formee li:after, .formee dl:after {
	content:"";
	display:block;
	clear:both;
	visibility:hidden;
	height:0;
	overflow:hidden;
}

/* Form Structure */

.formee {margin:0;padding:0;}
.formee ul{margin:0px;padding:0px;}
.formee fieldset {
	border: none;
	position: relative;
	height:100%;
	padding: 5px 0;
}
.formee legend {
    display: block;
    width: 100%;
}
.formee h3 {
	margin:2em 1% .8%;
	padding: 0.5% 0.5% 0.8%;
}

/* Field Grid */
.grid-1-12, .grid-2-12, .grid-3-12, .grid-4-12, .grid-5-12, .grid-6-12, .grid-7-12, .grid-8-12, .grid-9-12, .grid-10-12, .grid-11-12, .grid-12-12 {
	display:inline-block;
	/*position:relative;*/
	float:left;
	margin-left:1%;
	margin-right:1%;
	padding: 0 0.5% 1%;
	height:100%;
/*ie7*/
*margin-left:.8%;
*margin-right:.8%;
*padding: 0.8% 0.25% 1%;
}

/* 12-column Grid (thanks for Fluid 960 Grid System) */
.grid-1-12 {width: 5.333%;}
.grid-2-12 {width: 13.666%;}
.grid-3-12 {width: 22%;}
.grid-4-12 {width: 30.333%;}
.grid-5-12 {width: 38.666%;}
.grid-6-12 {width: 47%;}
.grid-7-12 {width: 55.333%;}
.grid-8-12 {width: 63.666%;}
.grid-9-12 {width: 72%;}
.grid-10-12 {width: 80.333%;}
.grid-11-12 {width: 88.666%;}
.grid-12-12 {width: 97%;}


/* clear */ .formee .clear {clear:both;}
/* input without label */ .formee-no-lbl {position:relative;padding-bottom:0;}
/* input without label */ .formee-no-lbl input,  .formee-no-lbl label,  .formee-no-lbl span,  .formee-no-lbl ul,  .formee-no-lbl p {position:absolute;bottom:0;}

/* hide element */ .formee-hidden { display:none !important; visibility:hidden !important; }

/* multiple containers inside field-grid */
.formee .floatLeft {
	clear:none;
	float:left !important;
	margin-right:0.3em !important;
}
.formee .floatRight {
	clear:none;
	float:right !important;
	margin-left:0.3em !important;
}


/* form elements  */
.formee-lbl, .formee label {
	display: block;
	height: auto;
	margin: 0.2em 0 0.3em;
	
	> input,
	> select {
		margin-top: 0.2em;
	}
}

.formee .formee-txt, 
.formee input[type="text"],
.formee input[type="search"],
.formee input[type="email"],
.formee input[type="url"],
.formee input[type="tel"],
.formee input[type="number"],
.formee input[type="range"],
.formee input[type="date"],
.formee input[type="month"],
.formee input[type="week"],
.formee input[type="time"],
.formee input[type="datetime"],
.formee input[type="datetime-local"],
.formee input[type="color"],
.formee input[type="password"],
.formee input[type="file"],
.formee textarea,
.formee select {
	margin-bottom:15px;
	vertical-align: middle;
	/*line-height: 1.1em;*/
	width: 100%;
	/*padding:10px;*/
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-box-sizing: border-box;
/*ie7*/
*width: 99%;
}
.formee textarea {height: 10em;}
.formee select 
{
    /*padding:9px;
    height:2.7em;*/
}

.formee-list {
	height: 100%;
	list-style: none;
	padding:0;
	margin:0 !important;
	
	li {
		position: relative;
		margin: 0 1em 0.8em 0;
		float: left;
		display: inline-block;
		list-style:none;
		
		> input {
			position: absolute;
			left: 0;
			top: 4px;
		}
	}

	input + label {
		display: inline-block;
		margin: 0;
		margin-left: 20px;
		font-weight: normal;
		cursor: pointer;
	}
}


.formee-list-vertical {
    list-style:none;
    padding:0;
    margin:0;

	li {
		float:none;
		display:block;
	}
}

/* input size */
.formee-small {
	width: 30% !important;
	float:left;
	margin-right: 10px;
	display:inline;
	/*ie7*/
	*width: 25% !important;
}

.formee-medium {
	width: 50% !important;
	float:left;
	margin-right: 10px;
	display:inline;
/*ie7*/
*width: 45% !important;
}



/********************************************************************

Project: Formee - http://www.formeeee.org
Last Updated: 
Version: 3.1
Authors: Bernard De Luna - http://www.bernarddeluna.com
         Daniel Araujo - http://www.dnlaraujo.com.br
		 Marcello Manso - http://www.marcellomanso.com.br
			 

********************************************************************
Style
********************************************************************/

.formee fieldset {
	background: none;
	border: none;
}

/* hover effect */
.grid-1-12:hover, .grid-2-12:hover, .grid-3-12:hover, .grid-4-12:hover, .grid-5-12:hover, .grid-6-12:hover, .grid-7-12:hover, .grid-8-12:hover, .grid-9-12:hover, .grid-10-12:hover, .grid-11-12:hover, .grid-12-12:hover {}
.field-button:hover {background:transparent;}


/* Form legend and titles */

.formee 
{
    /*font: normal 10px/1.1em Arial,Sans-Serif;*/
}
.formee legend, .formee h3 {
	color:#2b2b2b;
	font-weight: bold;
}

/* form elements  */
.formee-lbl, .formee label {
	color:#2b2b2b;
	/*font-size:1.4em;
	line-height: 1.3em;*/
    font-weight:bold;
	vertical-align:middle;
	clear: both;
}

.formee-bold { font-weight:bold !important; }

.formee-list .formee-lbl, .formee-list label {
	display: inline;
	height: auto;
	vertical-align:top !important;
	font-weight: bold;
}

.formee-req-key {
    text-align: right;
    font-size: 0.975em;
}

.formee-req,
.formee-req[title] {
	font-weight: bold;
	font-style: normal;
	color: $red;
	border: none;
	
	&:before {
		display: inline-block;
		content: "*";
	}
}

.formee .formee-txt,
.formee input[type="text"],
.formee input[type="search"],
.formee input[type="email"],
.formee input[type="url"],
.formee input[type="tel"],
.formee input[type="number"],
.formee input[type="range"],
.formee input[type="date"],
.formee input[type="month"],
.formee input[type="week"],
.formee input[type="time"],
.formee input[type="datetime"],
.formee input[type="datetime-local"],
.formee input[type="color"],
.formee input[type="password"],
.formee input[type="file"],
.formee textarea,
.formee select {
	border-color: #ccc;
	background: #fff;
	color: $color-text-primary;
	font-size: 16px;
	font-family:inherit;
	/*corner*/
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 6px 10px;
	height: 48px;
	line-height: $line-height;
}

.formee input[type="number"] {
	padding-right: 1px;
}

.formee select {
	padding: 8px 4px;
}
.formee .formee-txt:hover,
.formee input[type="text"]:hover,
.formee input[type="search"]:hover,
.formee input[type="email"]:hover,
.formee input[type="url"]:hover,
.formee input[type="tel"]:hover,
.formee input[type="number"]:hover,
.formee input[type="range"]:hover,
.formee input[type="date"]:hover,
.formee input[type="month"]:hover,
.formee input[type="week"]:hover,
.formee input[type="time"]:hover,
.formee input[type="datetime"]:hover,
.formee input[type="datetime-local"]:hover,
.formee input[type="color"]:hover,
.formee input[type="password"]:hover, 
.formee input[type="file"]:hover,
.formee textarea:hover,
.formee select:hover {
	color:#666;
	background: #fff none;
	border-color:#c6c6c6;
}
.formee .formee-txt:focus,
.formee input[type="text"]:focus,
.formee input[type="search"]:focus,
.formee input[type="email"]:focus,
.formee input[type="url"]:focus,
.formee input[type="tel"]:focus,
.formee input[type="number"]:focus,
.formee input[type="range"]:focus,
.formee input[type="date"]:focus,
.formee input[type="month"]:focus,
.formee input[type="week"]:focus,
.formee input[type="time"]:focus,
.formee input[type="datetime"]:focus,
.formee input[type="datetime-local"]:focus,
.formee input[type="color"]:focus,
.formee input[type="password"]:focus,
.formee input[type="file"]:focus,
.formee textarea:focus,
.formee select:focus  {
	color:#333;
	background: #fff none;
	border-color:#659ec9 #70aedd #a8cfec;
	outline: 0;
}


/* # Disabled Form Fields
<input type="text" disabled />
*/
.formee input[disabled]    { color: #666; background-color: #F0F0F0; }
.formee textarea[disabled] { color: #666; background-color: #F0F0F0; }
.formee select[disabled]   { color: #666; background-color: #F0F0F0; }
.formee input[readonly]    { color: #666; background-color: #F0F0F0; }
.formee textarea[readonly] { color: #666; background-color: #F0F0F0; }
.formee select[readonly]   { color: #666; background-color: #F0F0F0; }


/* form msg status  */

.formee-msg-note {
	display:block;
	margin: -10px 0 0.8em;
	padding:0 7px 1px;
	font-size:0.875em;
	color:#acacac;
}

.formee-msg-info, .formee-msg-warning, .formee-msg-error, .formee-msg-success {
	display: block;
	height: auto;
	margin: -12px 0 15px;
	padding: 0 10px;
	font-size:0.75em;
	clear:both;
	color:#2b2b2b;
}

.formee-msg-info-small, .formee-msg-warning-small, .formee-msg-error-small, .formee-msg-success-small {
	display: block;
	height: auto;
	margin: -15px 0 15px;
	clear:both;
	color:#2b2b2b;
	font-size:0.7em
}

.formee-msg-info ul, .formee-msg-warning ul, .formee-msg-error ul, .formee-msg-success ul {
	margin: 0px 0px 10px 27px;
	
}
.formee-msg-info li, .formee-msg-warning li, .formee-msg-error li, .formee-msg-success li {
	list-style: none;
	padding:0;	
	margin-bottom: .4%;
	font-size:1.0em;
	line-height:1.1em;
	vertical-align: top;
}

.formee-msg-info h3, .formee-msg-warning h3, .formee-msg-error h3, .formee-msg-success h3 {
	font-size:1.1em;
	line-height:1.5em;
	letter-spacing:-.02em;
	padding:0;
	margin:0 0 .3em 0;
}

.formee-msg-info-small h3, .formee-msg-warning-small h3, .formee-msg-error-small h3, .formee-msg-success-small h3 {
	font-size:1.4em;
	line-height:1em;
	letter-spacing:-.02em;
	padding:0;
	margin:0 0 .3em 0;
}


.formee-msg-success			{color:	$color-success;}
.formee-msg-success-small	{color:	$color-success;}
.formee-msg-info 			{color:	$color-info;}
.formee-msg-info-small 		{color:	$color-info;}
.formee-msg-warning 		{color:	$color-warning;}
.formee-msg-warning-small 	{color:	$color-warning;}
.formee-msg-error 			{color:	$color-error;}
.formee-msg-error-small 	{color:	$color-error;}


.formee-msg-success strong, .formee-msg-success a	{color:	$color-success;}
.formee-msg-info strong, .formee-msg-info a 		{color:	$color-info;}
.formee-msg-warning strong, .formee-msg-warning a 	{color:	$color-warning;}
.formee-msg-error strong, .formee-msg-error a 		{color:	$color-error;}
.formee-msg-error-label 							{color: $color-error !important; }

/* form validation */
input.formee-error,
select.formee-error,
textarea.formee-error {
	border-color: lighten($color-error, 50%) !important;
	color: $color-error !important;
  /* shadow */
  -moz-box-shadow: 0 0 3px 0 rgba(176,23,23,.4);
  -webkit-box-shadow: 0 0 3px 0 rgba(176,23,23,.4);
  box-shadow: 0 0 3px 0 rgba(176,23,23,.4);
}

.formee-lbl.formee-error,
label.formee-error,
label.formee-red,
label.formee-error .formee-req,
.formee-lbl.formee-error .formee-req,
label.formee-red .formee-req {
	font-weight: bold;
	font-style:normal;
	color: $color-error !important;
}

.formee-lbl.formee-error em,
label.formee-error em,
em.formee-error,
em.formee-red {
	font-weight: bold;
	font-style:normal;
	color: $red !important;
	font-size:1.2em;
	line-height: .85em;
}


/* ## NEW Limit Text Box formee-offset fix ## */
.formee .limitTextBoxBar { margin:-15px 0 15px 0; }


/* # Formee Groups */

.formee-group {
	position:relative;
	display: table;
	border-collapse: separate;
	margin-bottom: 15px;
	
	.formee-control {
		position: relative;
		display: table-cell;
		z-index: 2;
		float: left;
		width: 100%;
		height: 35px;
		margin-bottom: 0 !important;
	}
	
	.formee-control:first-child,
	.formee-group-addon:first-child,
	.formee-group-btn:first-child > .btn,
	.formee-group-btn:first-child > .btn-group > .btn,
	.formee-group-btn:first-child > .dropdown-toggle,
	.formee-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
	.formee-group-btn:last-child > .btn-group:not(:last-child) > .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0 none;
	}
	
	.formee-group-addon:first-child {
		border-right: 0 none;
	}
	
	.formee-control:first-child {
		margin-right: -1px;
	}
	
	.formee-control:last-child,
	.formee-group-addon:last-child,
	.formee-group-btn:last-child > .btn,
	.formee-group-btn:last-child > .btn-group > .btn,
	.formee-group-btn:last-child > .dropdown-toggle,
	.formee-group-btn:first-child > .btn:not(:first-child),
	.formee-group-btn:first-child > .btn-group:not(:first-child) > .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		margin-left: -1px;
		border-left: 0 none;
	}
	
	.formee-group-addon:last-child {
		border-left: 0 none;
	}
	
	.formee-control:last-child {
		margin-left: -1px;
	}
	
	.formee-group-addon:not(:first-child):not(:last-child),
	.formee-group-btn:not(:first-child):not(:last-child),
	.formee-control:not(:first-child):not(:last-child) {
		border-radius: 0;
	}
}

.formee-group-addon,
.formee-group-btn {
	display: table-cell;
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.formee-group-btn {
	position: relative;
	
	.btn {
		height: 35px;
	}
}

.formee-group-addon {
	padding: 6px 12px;
	font-weight: 400;
	line-height: 1;
	text-align: center;
	background-color: $gray;
	border: 1px solid $color-border;
	border-radius: 5px;
}

.formee .formee-control-inline {
	display: inline-block;
	width: auto;
	margin: 0;
	vertical-align: middle;
}


.formee .infoList {
	margin: 0 0 10px 0;
}


// ## Inline Form labels

.formee-inline {
    
    .formee-lbl,
    label {
        display:inline-block;
        margin: 0;
        vertical-align: middle;
    }
    
    .formee-txt,
    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="range"],
    input[type="date"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="color"],
    input[type="password"],
    input[type="file"],
    textarea,
    select {
        display: inline-block;
        width: auto;
        margin: 0;
        vertical-align: middle;
    }
}

// ## Marketo Form Styles

.mktoForm * {
	font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
}

.mktoForm {
	width: 100% !important;
	/* ## 16px baseline (767px) ## */
	@media only screen and (max-width:47.94em){
		margin-bottom: 1em;
	}	
	input[type="text"], 
	input[type="url"], 
	input[type="email"], 
	input[type="tel"], 
	input[type="number"], 
	input[type="date"], 
	select.mktoField, 
	textarea.mktoField {	
		color: #444 !important;
		font-weight: 300 !important;
	}
	input.mktoField.mktoInvalid, 
	textarea.mktoField.mktoInvalid, 
	select.mktoField.mktoInvalid, 
	.mktoLogicalField.mktoInvalid {
		border: 1px solid #e4701e !important;
	}
	.mktoOffset {
		display: none !important;
	}
	.mktoAsterix {
		color: #e4701e !important;
	}
	.mktoError {
		z-index: 9 !important;
		color: #e4701e !important;
		.mktoErrorArrow {
			background-color: #e4701e !important;
			border: 1px solid #e4701e !important;
			border-right-color: rgba(228, 112, 30, 0.5) !important;
			border-bottom-color: rgba(228, 112, 30, 0.5) !important;
		}		
		.mktoErrorMsg {
			background-color: #e4701e;
			background-image: -webkit-linear-gradient(#e4701e 43%, #e4701e 100%) !important;
			background-image: -moz-linear-gradient(#e4701e 43%, #e4701e 100%) !important;
			background-image: linear-gradient(#e4701e 43%, #e4701e 100%) !important;
			background-image: -ms-linear-gradient(#e4701e 43%, #e4701e 100%) !important;
			border: 1px solid #e4701e !important;
			-webkit-box-shadow: rgba(0,0,0,0.65) 0 2px 7px, inset #e4701e 0 1px 0px !important;
			box-shadow: rgba(0,0,0,0.65) 0 2px 7px, inset #e4701e 0 1px 0px !important;
			color: #f3f3f3;
			text-shadow: #e4701e 0 -1px 0 !important;
		}		
	}
	.mktoFormCol {
		width: 100% !important;
	}
	.mktoFieldWrap {
		width: 100% !important;
	}
	label {
		&.mktoLabel {
			font-family: Lato,sans-serif !important;
			color: #444 !important;
			font-size: 18px !important;
			font-weight: 300 !important;
			line-height: 27px !important;			
			padding-bottom: 0.1em !important;
			width: 100% !important;
			strong {
				font-weight: 300 !important;
			}
		}
	}	
	.mktoField,
	.mktoLogicalField {
		border: 1px solid #bfbfbf !important;
		box-shadow: inset 0 0 0 0 transparent !important;
		border-radius: 5px !important;
		padding: 8px 10px !important;		
		width: 100% !important;		
	}
	.mktoButtonRow {
		margin-top: 10px !important;
		.mktoButtonWrap {
			&.mktoInset {
				margin-left: 0 !important;
				button {
					&.mktoButton {
						min-width: 174px;
						padding: 14px 25px 18px !important;
						font-family: "IBM Plex Sans",sans-serif !important;
						font-size: 16px !important;
						font-weight: 500 !important;
						line-height: 16px !important;
						letter-spacing: 0 !important;		
						text-transform: none !important;
						color: #fff !important;
						background: #0a4e8c !important;
						border-color: #084174 !important;		
						border-radius: 0 !important;
						box-shadow: 0 0 0 0 transparent !important;
						transition: all .3s;
						&:hover,
						&:focus {
							color: #fff !important;
							background: #e4701e !important;
							border-color: #e4701e !important;
							-moz-box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
							-webkit-box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
							-o-box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
							box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
						}
					}
				}				
			}
		}
	}
}

.site-container {
	.mktoAsterix {
		float: left !important;
		padding-left: 0 !important;
		padding-right: 5px !important;
	}
	.mktoGutter {
    	display: none !important;
	}
	.mktoButtonWrap {
		margin-left: 0!important;
	}	
	button {
		&.mktoButton {
			padding: 11px 25px 10px !important;
			font-size: 12px !important;
			font-weight: 500 !important;
			line-height: 15px !important;
			letter-spacing: 2px !important;		
			text-transform: uppercase !important;
			color: #bdd7ef !important;
			background: #0a4e8c !important;
			border-color: #084174 !important;		
			border-radius: 5px !important;
			box-shadow: 0 0 0 0 transparent !important;
			transition: all .3s;
			&:hover,
			&:focus {
				color: #fff !important;
				background: #e4701e !important;
				border-color: #e4701e !important;
				-moz-box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
				-webkit-box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
				-o-box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
				box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
			}
		}
	}	
}

.formee-clear-all {
    a {
        font-size: .75em;
        font-weight: 600;
        text-decoration: underline;
    }
}