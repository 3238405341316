@font-face {
	font-family: 'hawksearch';
	src:url('../../../fonts/hawksearch.eot?-lisfor');
	src:url('../../fonts/hawksearch.eot?#iefix-lisfor') format('embedded-opentype'),
		url('../../fonts/hawksearch.woff?-lisfor') format('woff'),
		url('../../fonts/hawksearch.ttf?-lisfor') format('truetype'),
		url('../../fonts/hawksearch.svg?-lisfor#hawksearch') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="hawkIcon-"], [class*=" hawkIcon-"] {
	font-family: 'hawksearch';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.hawkIcon-search:before {
	content: "\e601";
}
.hawkIcon-menu:before {
	content: "\2261";
}
.hawkIcon-notification:before {
	content: "\21";
}
.hawkIcon-question:before {
	content: "\3f";
}
.hawkIcon-info:before {
	content: "\69";
}
.hawkIcon-blocked:before {
	content: "\20e0";
}
.hawkIcon-close:before {
	content: "\58";
}
.hawkIcon-checkmark:before {
	content: "\2714";
}
.hawkIcon-checkmark2:before {
	content: "\2713";
}
.hawkIcon-minus:before {
	content: "\2212";
}
.hawkIcon-plus:before {
	content: "\2b";
}
.hawkIcon-sub-checked:before {
	content: "\25a0";
}
.hawkIcon-radio-checked:before {
	content: "\1f518";
}
.hawkIcon-radio-unchecked:before {
	content: "\20dd";
}
.hawkIcon-filter:before {
	content: "\25e2";
}
.hawkIcon-arrow-left:before {
	content: "\27";
}
.hawkIcon-arrow-down:before {
	content: "\e600";
}
.hawkIcon-arrow-up:before {
	content: "\e602";
}
.hawkIcon-arrow-right:before {
	content: "\26";
}
.hawkIcon-arrow-left2:before {
	content: "\e603";
}
.hawkIcon-arrow-down2:before {
	content: "\e604";
}
.hawkIcon-arrow-up2:before {
	content: "\e605";
}
.hawkIcon-arrow-right2:before {
	content: "\e606";
}