.flex-grid-row {	
	[class*="grid-"] { 	
		position:relative;
		margin-left: 10px;
		margin-right: 10px;
		-webkit-transition: all .2s linear;
		   -moz-transition: all .2s linear;
			-ms-transition: all .2s linear; 
			 -o-transition: all .2s linear;
				transition: all .2s linear;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
				box-sizing: border-box;
	}
	
	[class*="grid-"] [class*="grid_"] { padding:0; }
}

/* ## 16px baseline (480px - 767px) sw = small wide ## */
@media only screen and (min-width:30em){
	.flex-grid-row {	
		.alpha { padding:0; }
		.omega { padding:0; }

		[class*="grid-"] [class*="grid_"] { padding:0 10px; }

		[class*="grid-"] .alpha { padding-left:0; }
		[class*="grid-"] .omega { padding-right:0; }

		[class*="grid-"] .grid_1  { width:8.333%; }
		[class*="grid-"] .grid_2  { width:16.666%; }
		[class*="grid-"] .grid_3  { width:25%; }
		[class*="grid-"] .grid_4  { width:33.333%; }
		[class*="grid-"] .grid_5  { width:41.666%; }
		[class*="grid-"] .grid_6  { width:50%; }
		[class*="grid-"] .grid_7  { width:58.333%; }
		[class*="grid-"] .grid_8  { width:66.666%; }
		[class*="grid-"] .grid_9  { width:75%; }
		[class*="grid-"] .grid_10 { width:83.333%; }
		[class*="grid-"] .grid_11 { width:91.666%; }
		[class*="grid-"] .grid_12 { width:100%; }
		
	
		.sw_1half > [class*="grid_"] { width:50%; }
		.sw_1half > [class*="push_"] { left:50%; }
		.sw_1half > [class*="pull_"] { left:-50%; }
		.sw_1half > [class*="grid_"] .alpha { padding:0 10px 0 0; }
		.sw_1half > [class*="grid_"] .omega { padding:0 0 0 10px; }

		.sw_1third > [class*="grid_"] { width:33.333%; }
		.sw_1third > [class*="push_"] { left:33.333%; }
		.sw_1third > [class*="pull_"] { left:-33.333%; }
		.sw_1third > [class*="grid_"] .alpha { padding:0 10px 0 0; }
		.sw_1third > [class*="grid_"] .omega { padding:0 0 0 10px; }

		.sw_stack > [class*="grid_"] { width:100%; }
		.sw_stack > .alpha, .sw_stack > .omega { padding:0; }

		/* show & hide base helpers */
		.sw_showInline { display:inline !important; visibility:visible !important; }
		.sw_showBlock { display:block !important; visibility:visible !important; }
		.sw_hide { display:none !important; visibility:hidden !important; }
	}
}

/* ## 16px baseline (768px) m = medium ## */
@media only screen and (min-width:48em){
	.flex-grid-row {
		display: flex;
		flex-direction: row;
		
		[class*="grid-"] [class*="grid_"] { padding:0 10px; }

		[class*="grid-"] .alpha { padding-left:0; }
		[class*="grid-"] .omega { padding-right:0; }

		.grid-1  { width:8.333%; }
		.grid-2  { width:16.666%; }
		.grid-3  { width:25%; }
		.grid-4  { width:33.333%; }
		.grid-5  { width:41.666%; }
		.grid-6  { width:50%; }
		.grid-7  { width:58.333%; }
		.grid-8  { width:66.666%; }
		.grid-9  { width:75%; }
		.grid-10 { width:83.333%; }
		.grid-11 { width:91.666%; }
		.grid-12 { width:100%; }

		.push-1  { left:8.333%; }
		.push-2  { left:16.666%; }
		.push-3  { left:25%; }
		.push-4  { left:33.333%; }
		.push-5  { left:41.666%; }
		.push-6  { left:50%; }
		.push-7  { left:58.333%; }
		.push-8  { left:66.666%; }
		.push-9  { left:75%; }
		.push-10 { left:83.333%; }
		.push-11 { left:91.666%; }
		.push-12 { left:100%; }

		.pull-1  { left:-8.333%; }
		.pull-2  { left:-16.666%; }
		.pull-3  { left:-25%; }
		.pull-4  { left:-33.333%; }
		.pull-5  { left:-41.666%; }
		.pull-6  { left:-50%; }
		.pull-7  { left:-58.333%; }
		.pull-8  { left:-66.666%; }
		.pull-9  { left:-75%; }
		.pull-10 { left:-83.333%; }
		.pull-11 { left:-91.666%; }
		.pull-12 { left:-100%; }

		.prefix-1  { margin-left:8.333%; }
		.prefix-2  { margin-left:16.666%; }
		.prefix-3  { margin-left:25%; }
		.prefix-4  { margin-left:33.333%; }
		.prefix-5  { margin-left:41.666%; }
		.prefix-6  { margin-left:50%; }
		.prefix-7  { margin-left:58.333%; }
		.prefix-8  { margin-left:66.666%; }
		.prefix-9  { margin-left:75%; }
		.prefix-10 { margin-left:83.333%; }
		.prefix-11 { margin-left:91.666%; }
		.prefix-12 { margin-left:100%; }

		.suffix-1  { margin-right:8.333%; }
		.suffix-2  { margin-right:16.666%; }
		.suffix-3  { margin-right:25%; }
		.suffix-4  { margin-right:33.333%; }
		.suffix-5  { margin-right:41.666%; }
		.suffix-6  { margin-right:50%; }
		.suffix-7  { margin-right:58.333%; }
		.suffix-8  { margin-right:66.666%; }
		.suffix-9  { margin-right:75%; }
		.suffix-10 { margin-right:83.333%; }
		.suffix-11 { margin-right:91.666%; }
		.suffix-12 { margin-right:100%; }
	}
}

/* ## 16px baseline (768px - 959px) m = medium ## */ 
@media only screen and (min-width:48em) and (max-width:59.938em){
	.flex-grid-row {
		.m_1half > [class*="grid_"] { width:50%; }
		.m_1half > [class*="push_"] { left:50%; }
		.m_1half > [class*="pull_"] { left:-50%; }
		.m_1half > [class*="prefix_"] { margin-left:0; }
		.m_1half > [class*="suffix_"] { margin-right:0; }

		.m_1third > [class*="grid_"] { width:33.333%; }
		.m_1third > [class*="push_"] { left:33.333%; }
		.m_1third > [class*="pull_"] { left:-33.333%; }

		.m_1fourth > [class*="grid_"] { width:25%; }
		.m_1fourth > [class*="push_"] { left:25%; }
		.m_1fourth > [class*="pull_"] { left:-25%; }

		.m_2thirds_left > [class*="grid_"] { width:50%; }
		.m_2thirds_left > [class*="grid_"]:nth-child(2n)    { width:33.333%; }
		.m_2thirds_left > [class*="grid_"]:nth-child(2n+1) { width:66.666%; }
		.m_2thirds_left > [class*="push_"] { left:0; }
		.m_2thirds_left > [class*="pull_"] { left:0; }
		.m_2thirds_left > [class*="prefix_"] { margin-left:0; }
		.m_2thirds_left > [class*="suffix_"] { margin-right:0; }

		.m_2thirds_right > [class*="grid_"] { width:50%; }
		.m_2thirds_right > [class*="grid_"]:nth-child(2n)   { width:66.666%; }
		.m_2thirds_right > [class*="grid_"]:nth-child(2n+1) { width:33.333%; }
		.m_2thirds_right > [class*="push_"] { left:0; }
		.m_2thirds_right > [class*="pull_"] { left:0; }	
		.m_2thirds_right > [class*="prefix_"] { margin-left:0; }
		.m_2thirds_right > [class*="suffix_"] { margin-right:0; }

		.m_stack > [class*="grid_"] { width:100%; }
		.m_stack > .alpha, .m_stack > .omega { padding:0; }

		/* show & hide base helpers */
		.m_showInline { display:inline !important; visibility:visible !important; }
		.m_showBlock { display:block !important; visibility:visible !important; }
		.m_hide { display:none !important; visibility:hidden !important; }
	}
}