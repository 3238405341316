﻿@mixin containers {
    width: 100%;
	max-width: 1300px;
    margin: 0 auto;
}

@mixin container-checkout {
    width: 100%;
	max-width: 1350px;
    margin: 0 auto;
	padding: 0 15px;
}

@mixin containers-w-margin {
    @include containers;
    margin: 30px auto;
}

@mixin containers-skinny {
    width: 60%;
    margin: 0 auto;
}

@mixin title-font {
    font-size: 2em;
    font-weight: 600;
}

@mixin title-font-large {
    font-size: 2.75em;
    font-weight: 600;
}

@mixin title-font-small {
    font-size: 1.5em;
    font-weight: 600;
}

@mixin subtitle-font {
    font-size: 1.25em;
    font-weight: 600;
}

@mixin sm-callout-title-font {
    font-size: 1.15em;
    font-weight: 600;
}

@mixin form-label-font {
    font-size: 1.15em;
    font-weight: 600;
}

@mixin login-form-label-font {
    font-size: 1em;
    font-weight: 600;
}

@mixin form-req-font {
    color: $red;
    font-size: .9em;
    font-weight: 600;
}

@mixin disclaimer-font {
    font-size: .8em;
    line-height: 1.75em;
}

@mixin title-line {
    width: 100px;
    height: 4px;
    background-color: $color-secondary;
    margin: 0 0 0;
	@media(min-width:980px) {
		margin-bottom: 40px;
	}	
	@media(min-width:768px) and (max-width:979px) {
		margin-bottom: 15px;
	}	
	@media(max-width:767px) {
		margin-bottom: 15px;
	}	
}

@mixin interior-container-half {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

@mixin border-default {
    border: 1px solid $color-border;
}

@mixin border-top-default {
    border-top: 1px solid $color-border;
}

@mixin border-btm-default {
    border-bottom: 1px solid $color-border;
}

@mixin border-right-default {
    border-right: 1px solid $color-border;
}

@mixin border-dark-default {
    border: 1px solid $color-border-dark;
}

@mixin border-btm-dark-default {
    border-bottom: 1px solid $color-border-dark;
}

@mixin interior-box-shadow {
    -webkit-box-shadow: 5px 5px 10px 5px rgba(223,223,223,1);
    -moz-box-shadow: 5px 5px 10px 5px rgba(223,223,223,1);
    box-shadow: 5px 5px 10px 5px rgba(223,223,223,1);
}
@mixin interior-box-shadow-2 {
    -webkit-box-shadow: 3px 3px 7px 3px rgba(223,223,223,1);
    -moz-box-shadow: 3px 3px 7px 3px rgba(223,223,223,1);
    box-shadow: 3px 3px 7px 3px rgba(223,223,223,1);
}


@mixin flex100 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

@mixin right-arrow-sm {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-left: 5px solid $color-primary;
}



/******************************/
/*     HOME PAGE CONTENT     */
/****************************/

// HOME PAGE ICONS
@mixin home-icons {
    transition: all .2s ease-in-out;
	fill: $dark-gray;
	stroke: $dark-gray;
    stroke-width: .25px;
	width: 96px;
	height: 96px;
}




/*******************************/
/*   INTERIOR PAGES CONTENT   */
/*****************************/

// BANNERS
@mixin interior-bannner-content-container {
//    width: 50%;
//    position: absolute;
//    top: 40px;
}

@mixin banner-no-line {
    display: none;
}

@mixin interior-banner-title {
    @include title-font-large;
}

@mixin interior-banner-content {
    font-size: 1.5em;
    font-weight: 500;
	line-height: 1.2em;
	@media(max-width:767px) {
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: 20px;	
	}	
}


// HEADER CONTENT
@mixin interior-header-title {
    @include title-font;
    line-height: 1.25em;
    margin: 0 0 20px 0;
}


// CATEGORIES
@mixin categories-flex {
    @include flex100;
}

@mixin categories-flex-row {
    @include flex100;
}

@mixin categories-row {
    height: 100%;
    display: inline-block;
}

@mixin categories-title {
    color: $color-secondary;
    font-size: 1.5em;
    font-weight: 600;
}

@mixin categories-item {
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
}

@mixin categories-item-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@mixin categories-item-current {
    color: $color-primary;
}

@mixin categories-right-borders {
    border-right: 1px solid $color-border;

    &:last-of-type {
        border-right: none;
    }
}


// CALLOUT CONTENT
@mixin callout-container-bkgd {
    background-color: $light-gray;
}

@mixin callout-container {
    @include callout-container-bkgd;
    padding: 40px;
}

@mixin callout-title {
    @include title-font-large;
    line-height: 1.15em;
    margin: 0 0 40px 0;
}

@mixin callout-subtitle {
    @include subtitle-font;
    line-height: 1.15em;
    margin: 15px 0;
}

@mixin callout-content {
    line-height: 1.5em;
    margin: 0 0 40px 0;
}



// INTERIOR BOTTOM FULL WIDTH CALLOUTS
@mixin full-width-callout-wrapper {
    width: 100%;
    padding: 40px 0;
}

@mixin full-width-callout-content {
    @include containers-skinny;
    color: $color-text-secondary;
    font-size: 1.15em;
    font-weight: 500;
    line-height: 1.75em;
    text-align: center;
    padding: 40px 0;
}

@mixin full-width-callout-title {
    @include title-font;
    padding: 0 0 40px 0;
}

@mixin full-width-callout-subtitle {
    @include containers-skinny;
    color: $color-text-secondary;
    @include subtitle-font;
    text-align: center;
}


// FORM - DEFAULT
@mixin form-title {
    @include title-font-small;
}


// FORM - LEFT COL
@mixin form-wrapper-left-col {
    border: 1px solid #eaeaea;
    padding: 25px 40px 40px;
	@media (max-width:767px) {
		padding: 25px 20px 20px;
	}
}


// CALLOUTS - RIGHT COL
@mixin callout-wrapper-right-col {
    padding: 0 0 0 40px;
	@media (max-width:767px) {
		padding-left: 0;
		margin-top: 30px;
	}	
}

@mixin callout-container-right-col {
    @include callout-container-bkgd;
    padding: 25px;
    margin: 0 0 40px 0;
}

@mixin callout-title-right-col {
    @include sm-callout-title-font;
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
    @include border-btm-default;
}

@mixin callout-hours-table {
    @include flex100;
    margin: 0 0 25px 0;
}

@mixin callout-hours-day {
    width: 40%;
    font-weight: 500;
    margin: 0 0 5px 0;
}

@mixin callout-hours-time {
    width: 60%;
    font-weight: 500;
    margin: 0 0 5px 0;
}


// SPEAKERS AND COORDINATORS
@mixin speaker-coordinator-container {
    width: 48%;
    display: inline-block;
    background-color: $light-gray;
    text-align: center;
    margin: 0 2% 0 0;
    padding: 30px;
}

@mixin speaker-coordinator-title {
    @include subtitle-font;
    margin: 0 0 30px 0;
}

@mixin speaker-coordinator-image {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 15px auto;
}

@mixin speaker-coordinator-image-img {
    width: auto;
    height: 100% !important;
    display: inline;
    margin: 0 auto;
}

@mixin speaker-coordinator-name {
    font-weight: 600;
    line-height: 1.75em;
}

@mixin speaker-coordinator-info-links {
    color: $color-text-primary;
    font-weight: 400;
}



// INTERIOR BOTTOM LOGOS
@mixin interior-logos-container {
    @include containers-w-margin;
    padding: 40px 0;
}

@mixin interior-logos-title {
    @include title-font;
    text-align: center;
    margin: 0 0 40px 0;
}