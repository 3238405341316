﻿.training-details-container {

    .training-details-course-banner {
        width: 100%;
        position: relative;
        color: #fff;

        .training-details-course-image {
            width: 100%;

            img {
                width: 100%;
                max-height: 500px;
                object-fit: cover;
            }
        }

        .training-details-course-content {
            width: 100%;
            position: absolute;
            top: 25%;
            padding: 0 100px;

            @media(max-width:992px) {
                padding: 0 50px;
            }
            @media(max-width:767px) {
                top: 15%;
            }
            @media(max-width:550px) {
                padding: 0 25px;
            }
        }

        h2 {
            font-family: Montserrat,Arial,sans-serif;
            font-size: 2.5em;
            font-weight: 900;
            line-height: 1em;
            text-transform: uppercase;
            margin: 0 0 10px 0;

            @media(max-width:900px) {
                font-size: 2em;
            }
            @media(max-width:600px) {
                font-size: 1.5em;
                margin: 0;
            }
            @media(max-width:500px) {
                font-size: 1.25em;
            }
        }

        p {
            font-size: 2em;
            font-weight: 200;
            line-height: 1em;
            text-transform: uppercase;
            margin: 0 0 25px 0;

            @media(max-width:900px) {
                font-size: 1.5em;
            }
            @media(max-width:767px) {
                margin: 0 0 10px 0;
            }
            @media(max-width:600px) {
                font-size: 1em;
            }
            @media(max-width:500px) {
                font-size: .8em;
            }
        }

        .btn {
            background-color: #e4701e;
            border: 1px solid #e4701e;

            &:hover, &:focus {
                background-color: $color-primary-light;
                border: 1px solid $color-primary-light;
            }

            @media(max-width:450px) {
                padding: 5px 15px;
            }
        }
    }



    .training-details-course-info-wrapper {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 50px 0;

        @media(max-width:1150px) {
            width: 100%;
        }
        @media(max-width:767px) {
            width: 65%;
        }
    }

    .training-details-course-info-container {
        width: 33.333%;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        padding: 20px 0;

        @media(max-width:767px) {
            width: 100%;
            padding: 10px 0;
        }

        h3 {
            width: 100%;
            color: $color-primary;
            font-size: 2em;
            text-transform: uppercase;
            margin: 10px 0;

            @media(max-width:992px) {
                font-size: 1.5em;
            }
        }

        p {
            width: 100%;
            height: 85px;
            color: $color-primary;
            font-size: 1.25em;
            font-weight: 600;

            @media(max-width:992px) {
                font-size: 1em;
            }
            @media(max-width:767px) {
                height: unset;
            }
        }

        &.training-details-course-info-location, &.training-details-course-info-cost {
            border-right: 1px solid #000;

            @media(max-width:767px) {
                border-right: none;
                border-bottom: 1px solid #000;
            }
        }

        &.training-details-course-info-time {
            p {
                text-transform: uppercase;
            }
        }
    }

    .training-details-course-info-icon {
        width: 100%;
        
        .icon {
            fill: #e4701e;
            width: 75px;
            height: 70px;
        }
    }





    .training-details-about-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: rgba(223, 223, 223, 0.3);
        text-align:center;
        margin: 50px 0;
        padding: 50px;

        h3 {
            width: 100%;
            font-size: 2em;
            text-transform: uppercase;
            margin: 0 0 15px 0;
        }

        h4 {
            width: 100%;
            text-transform: uppercase;
            margin: 10px 0;
        }

        p {
            width: 100%;
        }

        .training-details-about-btn-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 15px 0 0 0;
        }

        .btn {
            min-width: 275px;
            font-weight: 600;

            @media(max-width:350px) {
                min-width: unset;
                width: 100%;
            }
        }

        .training-details-download-btn {
            margin: 0 20px 0 0;

            @media(max-width:707px) {
                margin: 0 20px 20px 0;
            }
            @media(max-width:687px) {
                margin: 0 0 20px 0;
            }
        }

        .training-details-register-btn {
            background-color: #e4701e;
            border: 1px solid #e4701e;

            @media(max-width:707px) {
                margin: 0 0 20px 0;
            }
            @media(max-width:687px) {
                margin: 0;
            }

            &:hover, &:focus {
                background-color: darken(#e4701e, 10%);
                border: 1px solid darken(#e4701e, 10%);
            }
        }
    }






    .training-details-speakers-wrapper {
        width: 100%;
        position: relative;
        background-color: #084985;
        color: #fff;

        @media(max-width:992px) {
            height: 505px;
        }
        @media(max-width:800px) {
            height: 885px;
        }

        a {
            color: #fff;

            &:hover, &:focus {
                color: #e4701e;
            }
        }
        .training-details-speakers-image {
            width: 100%;

            @media(max-width:992px) {
                min-height: 230px;
            }

            img {
                max-height: 370px;
                object-fit: cover;
            }
        }

        .training-details-speakers-container {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            text-align: center;

            @media(max-width:992px) {
                height: 505px;
            }
        }

        h3 {
            width: 100%;
            font-size: 2em;
            line-height: 1em;
            margin: 1em 0 25px 0;

            @media(max-width:1250px) {
                font-size: 1.75em;
            }
            @media(max-width:1100px) {
                font-size: 1.5em;
                margin: 15px 0;
            }
        }

        .training-details-featured-speakers-wrapper {
            width: 59%;
            /*width: 60%;*/
            /*height: 100%;*/
            display: inline-block;
            /*display: inline-flex;
            flex-wrap: wrap;*/
            padding: 0 0 25px 0;

            @media(max-width:992px) {
                width: 100%;
                /*height: 250px;*/
                padding: 15px 0;
            }
            @media(max-width:800px) {
                width: 100%;
                /*height: 600px;*/
                padding: 15px 0;
            }
        }

        .training-details-featured-speaker-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;

            li {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                list-style: none;
                padding: 0 20px;

                &:first-child:nth-last-child(1) {
                    width: 100%;
                }

                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ li {
                    width: 50%;

                    @media(max-width:800px) {
                        width: 100%;
                        padding: 0 20px 20px 20px;
                    }
                }

                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ li {
                    width: 33.333%;

                    @media(max-width:800px) {
                        width: 100%;
                        padding: 0 20px 20px 20px;
                    }
                }

                @media(max-width:700px) {
                    width: 100%;
                    padding: 0 20px 20px 20px;
                }
            }
        }

        &.training-details-three-featured-speaker {

            @media(max-width:800px) {
                /*height: 600px;*/
            }

            .training-details-featured-speaker-container {

                ul {
                    padding: 0;
                }

                li {
                    width: 33.333%;

                    @media(max-width:750px) {
                        width: 100%;
                        padding: 0 20px 20px 20px;
                    }
                }
            }
        }

        .training-details-coordinator-wrapper {
            width: 40%;
            height: 100%;
            display: inline-block;
            float: right;
            background-color: #084985;
            padding: 0 0 25px 0;

            @media(max-width:992px) {
                width: 100%;
                height: auto;
                padding: 15px 0;
            }
        }

        .training-details-coordinator-container {
            width: 100%;
            /*width: 80%;*/
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            ul {
                display: flex;
                padding: 0;
            }

            li {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                list-style: none;
                padding: 0 20px;
            }
        }

        li {
            p {
                width: 100%;
                font-size: 1.25em;
                font-weight: 500;
                line-height: 1em;
                margin: 10px 0 0 0;

                @media(max-width:1250px) {
                    font-size: 1.1em;
                }
                @media(max-width:1100px) {
                    font-size: 1em;
                }

                &:first-of-type {
                    width: 125px;
                    height: 125px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 50%;

                    @media(max-width:1100px) {
                        width: 100px;
                        height: 100px;
                    }
                }

                &:last-of-type {
                    font-size: 1em;
                    font-weight: 300;

                    @media(max-width:1250px) {
                        font-size: .9em;
                    }
                    @media(max-width:1100px) {
                        line-height: 1em;
                    }
                }

                img {
                    width: 100% !important;
                }
            }
        }
    }






    .training-details-agenda-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin: 50px 0 0 0;

        h4 {
            width: 100%;
            font-size: 2em;
            text-transform: uppercase;
        }

        .training-details-agenda-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            font-size: 1.75em;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0 0 20px 0;
            list-style: none;

            li {
                width: 20%;
                display: inline-flex;
                flex-wrap: wrap;
                padding: 0 20px;

                @media(max-width:992px) {
                    width: 33.333%;
                    padding: 0 20px 20px 20px;
                }
                @media(max-width:767px) {
                    width: 100%;
                    padding: 0 50px 25px 50px;
                }

                ul {
                    width: 100%;
                    font-size: .6em;
                    font-weight: 300;
                    text-align: left;
                    text-transform: none;
                    margin: 10px 0 0 0;
                    padding: 20px 0 0 20px;
                    border-top: 2px solid #e4701e;

                    li {
                        width: 100%;
                        display: list-item;
                        padding: 0 0 7px 0;
                    }
                }
            }
        }

        .btn {
            background-color: #e4701e;

            &:hover, &:focus {
                background-color: darken(#e4701e, 10%);
            }
        }
    }

    .training-details-register-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 0 50px 0;
    }



    .rocModule-miniList {
        border: none;
        border-top: 3px solid $color-border;
        padding: 50px 20px;

        .rocModule-header {
            color: $color-primary-light;
            text-transform: uppercase;
        }

        .slick-prev, .slick-next {
            top: -60px;
            font-size: 20px;
            border: 1px solid $color-border;
            border-radius: 50%;

            &:before {
                font-size: 20px;
            }
        }

        .slick-prev {
            left: auto;
            right: 50px;
            padding: 4px;
        }

        .slick-next {
            right: 0;
            padding: 0 0 0 6px;
        }

        .itemWrapper {
            .item-slide-wrap {
                min-height: 400px;

                @media(max-width:1200px) {
                    min-height: 440px;
                }

                @media(max-width:992px) {
                    min-height: 445px;
                }

                @media(max-width:767px) {
                    min-height: 420px;
                }

                @media(max-width:480px) {
                    min-height: unset;
                }

                a {
                    &:hover {
                        color: darken(#444, 50%);
                        font-weight: 500;
                    }
                }
            }

            .btn-wrap {
                a.btn {
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

    }

}

.item-slide-wrap {
    a {
        &:hover {
            .itemTitle {
                color: darken(#444, 50%);
            }
        }
    }
}