﻿.chooseLocation {
    display: block;
}

.chooseLocation-item {
    margin-bottom: 15px;
}

.chooseLocation-headingWrapper {
    position: relative;
}

.chooseLocation-heading {
    cursor: pointer;
    padding: 3px 10px;
    margin: 0;
    transition: background-color .2s ease;
    &:hover,
    &:active,
    &:focus {
        background-color: #dedede;
    }
}

.chooseLocation-heading--uncollapsed {
    background-color: #dedede;
}

.chooseLocation-heading:after {
    font-family: hawksearch;
    content: "\2b";
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #002f65;
    speak: none;
    font-style: normal;
    font-weight: 400!important;
    font-variant: normal;
    text-transform: none;
    line-height: 1.42857;
    -webkit-font-smoothing: antialiased;
    .chooseLocation-heading--uncollapsed & {
        content: "\2212";
    }
}

.chooseLocation-list {
    overflow: hidden;
    max-height: 0;
    padding: 0 8px;
    transition: none;
}

.chooseLocation-list--uncollapsed {
    max-height: 40em;
    overflow-y: auto;
    transition: all .3s ease-in-out;
}

.chooseLocation-listInner {
    padding: 15px;
    border: 1px solid #ccc;
    margin: 0 -8px !important;
}


.white-popup {
	&.pickup-selector {
		padding: 44px 30px;
		@media(max-width:979px) {
			max-width: 760px;
		}
		@media(min-width:980px) and (max-width:999px) {
			max-width: 850px;
		}
		@media(min-width:1000px) {
			max-width: 875px;
		}		
		.mfp-close {
			background: none !important;
			color: #333 !important;
		}
		.chooseLocation-item {
			display: block;
			margin: 0;
			padding: 0;
			border-bottom: 2px solid #d7d7d7;
			&:last-child {
				border-bottom: none;
			}
			.collapsableLabel {
				>.chooseLocation-heading {
					padding: 10px;
					&:after {
						top: 18px;
					}
				}
			}
		}
	}
}
.mfp-content {
	.white-popup {
		&.pickup-selector {
			position: relative !important;
			top: inherit !important;
			left: auto !important;
			display: block !important;
		}
	}
}

.itemList  {
	&.chooseLocation-list {
		position: relative;
		box-sizing: border-box;
		margin: 0 0;
		&.chooseLocation-list--uncollapsed {
			margin: 20px 0;
		}
		>.chooseLocation-listInner {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 20px;
			border: none;
			padding: 0;
			margin: 0 auto !important;		
			@media(max-width:767px) {
				grid-template-columns: 1fr 1fr;
				grid-gap: 20px;
			}
			@media(max-width:479px) {
				grid-template-columns: 1fr;
				grid-gap: 20px;
			}			
			>.item {
				width: 100% !important;
				padding-bottom: 17px !important;
				/* IE10+ specific styles go here */
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					display: inline-block !important;
					width: 33% !important;
					margin: 0 !important;
				}					
				.itemInterior {
					&.capped-bottom {
						display: table;
						width: 100%;
						margin: 0 auto;
					}
				}
				.itemContent {
					display: table;
					padding: 0;
					width: 100%;
					.content {
						.content-in {
							line-height: 17px;
						}
						.bottom-cap {
							position: relative;
							display: block;
							box-sizing: border-box;
							padding: 0;
							bottom: 0;
							width: 100% !important;
							.btn {
								padding: 10px !important;
							}
						}
					}					
				}
			}
		}
	}
}