﻿.saved-later-container {
    padding: 30px;
    border: 1px solid $color-border;

    .product-list {
        padding: 0;
        margin: 0;
        border: none;

        .product-item {
            padding: 20px 0 0 0;
            margin: 20px 0 0 0;
            border-top: 1px solid $color-border;

            &:first-of-type {
                padding: 0;
                margin: 0;
                border: none;
            }
        }

        .product-price {
            text-align: left;
        }
    }
}