/* # Banners Slideshow */

/* Modified by Fernando Peña to include the styles for the custom hero banners */
.bannerEffects {
    position: relative;
	margin: 10px 0 20px;
	z-index: 1;
	padding: 0;
        
    .slideItem {
		color: #fff;
		
        &:not(:first-child) {
            display: none;
            
            &.slick-slide {
                display: block;
            }
        }
        
		.item-wrapper {
			width: 100%;
		}
    }

    .slideItem img {
        display: block;
		margin: 0 auto;
        padding: 0;
    }

    /* in case script does not load */
    .slideItem img:first-child {
		position: static; 
		z-index: 100; 
	}
	
	button {
		&:before {
			color: rgba(255,255,255,.8);
		}
		
		&:hover, &:focus {
			&:before {color: #fff;}
		}
	}

}

/* Hero Banner */

.hero-box {
	position: relative;
	@media(min-width:768px) {
		margin-top: 0;
	}
	.slick-dots {
		border: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;
		margin: 0;
		width: 100%;
		z-index: 1;
		li {
			position: relative;
			display: inline-block;
			width: 12px;
			height: 12px;					
			margin: 5px;
			padding: 0;
			cursor: pointer;
			button {
				display: block;
				position: relative;
				width: 12px;
				height: 12px;
				&:before {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					content: "";
					width: 12px;
					height: 12px;
					font-size: 0;
					line-height: 0;
					text-align: center;
					background-color: #fff;					
					opacity: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					border-radius: 50px;
					-webkit-transform: scale(1.04);
					-ms-transform: scale(1.04);
					transform: scale(1.04);
					transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);				
				}
				&:hover {
					&:before {
						-webkit-transform: scale(1.15);
						-ms-transform: scale(1.15);
						transform: scale(1.15);	
					}
				}
				&:focus {
					&:before {
						background-color: #e4701e;					
					}
				}				
			}
			&.slick-active {
				button {
					&:before {
						background-color: #e4701e;
						opacity: 1;
					}
				}
			}
		}
	}
	.slick-dots-wrap {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		bottom: 5%;
		@media (min-width:768px) and (max-width:1599px) {
			bottom: 2%;
		}
		@media (max-width:480px) {
			bottom: 5px;		
		}		
		.slick-dots {
			display: table;
			position: relative;
			width: 100%;
			max-width: 1300px;
			margin: 0 auto;
			text-align: center;
			left: inherit;
			right: inherit;
			@media (min-width:768px) and (max-width:1599px) {
				max-width: 100%;
				padding-left: 15px;
				padding-right: 15px;
			}
			@media (max-width:767px) {
				max-width: 100%;
				padding-left: 20px;
				padding-right: 20px;	
			}			
		}
	}
}

.banner-hero-all {
	@media(min-width:768px) {
		.hero-slide {
			display: table;
			position: relative;
			width: 100%;
			max-width: 1700px;
			margin-left: auto;
			margin-right: auto;
			.hero-media {
				display: table-cell;
				img {
					margin-left: auto;
					margin-right: auto;					
				}
				.hero-text {
					position: absolute;
					top: 0px;
					width: 100%;
					height: 100%;
					.hero-text-table {
						display: table;
						table-layout: fixed;
						margin-left: auto;
						margin-right: auto;
						width: 100%;
						height: 100%;
						.hero-text-table-tr {
							display: table-row;
							.hero-text-table-td {
								display: table-cell;
								height:100%;
								vertical-align: middle;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
	.slick-prev {
		left: 47%;
		z-index: 9;
		width: 40px;
		height: 40px;		
		@media (min-width:768px) {		
			top: 90% !important;
		}
		@media (min-width:980px) and (max-width:1279px) {	
			top: 88% !important;
			left: 45%;
		}		
		@media (min-width:768px) and (max-width:979px) {	
			top: 88% !important;
			left: 44%;
		}	
		@media (max-width:767px) {		
			left: 3%;
			top: 25% !important;			
		}		
		&:before {
			display: none;
		}
		-webkit-border-top-left-radius: 0;
		-moz-border-top-left-radius: 0;
		-ms-border-top-left-radius: 0;
		-o-border-top-left-radius: 0;
		border-top-left-radius: 0;
		-webkit-border-bottom-left-radius: 0;
		-moz-border-bottom-left-radius: 0;
		-ms-border-bottom-left-radius: 0;
		-o-border-bottom-left-radius: 0;
		border-bottom-left-radius: 0;
		.icon-arrow {
			fill: rgba(217,107,29,.95);
			transition: all .2s ease-in-out;
			width: 40px;
			height: 40px;		
			@media (max-width:979px) {		
				width: 30px;
				height: 30px;
			}
		}
		&:hover,
		&:active {
			.icon-arrow {
				fill: rgba(217,107,29,.95);
				transform: scale(1.1);				
			}
		}		
	}
	.slick-next {
		right: 47%;
		z-index: 9;
		width: 40px;
		height: 40px;
		@media (min-width:768px) {		
			top: 90% !important;
		}			
		@media (min-width:980px) and (max-width:1279px) {	
			top: 88% !important;
			right: 45%;
		}
		@media (min-width:768px) and (max-width:979px) {	
			top: 88% !important;
			right: 44%;
		}		
		@media (max-width:767px) {		
			right: 3%;
			top: 25% !important;
		}		
		&:before {
			display: none;
		}
		-webkit-border-top-right-radius: 0;
		-moz-border-top-right-radius: 0;
		-ms-border-top-right-radius: 0;
		-o-border-top-right-radius: 0;
		border-top-right-radius: 0;
		-webkit-border-bottom-right-radius: 0;
		-moz-border-bottom-right-radius: 0;
		-ms-border-bottom-right-radius: 0;
		-o-border-bottom-right-radius: 0;
		border-bottom-right-radius: 0;
		.icon-arrow {
			fill: rgba(217,107,29,.95);
			transition: all .2s ease-in-out;
			width: 40px;
			height: 40px;	
			@media (max-width:979px) {		
				width: 30px;
				height: 30px;
			}		
		}	
		&:hover,
		&:active {
			.icon-arrow {
				fill: rgba(217,107,29,.95);
				transform: scale(1.1);				
			}
		}		
	}	
}

.carousel-hero-new {
	position: relative;
	display: block;
	margin: 0 auto;
	max-width: 1920px;
	width: 100%;
	//max-height: 495px;
	overflow: hidden;
	@media(min-width:768px) {	
		.hero-slide {
			.hero-media {
				display: table-cell;
				img {
					margin-left: auto;
					margin-right: auto;		
					width: 100%;			
				}
				.hero-text {
					position: absolute;
					top: 0px;
					left: 0;
					width: 100%;
					height: 100%;
					
					.hero-text-table {
						display: table;
						table-layout: fixed;
						margin-left: auto;
						margin-right: auto;
						width: 100%;
						max-width: 1300px;
						height: 100%;
						.hero-text-table-tr {
							display: table-row;
							.hero-text-table-td {
								display: table-cell;
								height:100%;
								vertical-align: middle;
								text-align: left;
								position: relative;

								&:first-child {
									width: 47%;
								}
								+.hero-text-table-td {
									background-color: transparent;
									&:after {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.slick-prev {
		left: 47%;
		z-index: 9;
		width: 40px;
		height: 40px;		
		@media (min-width:768px) {		
			top: 90% !important;
		}
		@media (min-width:980px) and (max-width:1279px) {	
			top: 88% !important;
			left: 45%;
		}		
		@media (min-width:768px) and (max-width:979px) {	
			top: 88% !important;
			left: 44%;
		}	
		@media (max-width:767px) {		
			left: 3%;
			top: 25% !important;			
		}		
		&:before {
			display: none;
		}
		-webkit-border-top-left-radius: 0;
		-moz-border-top-left-radius: 0;
		-ms-border-top-left-radius: 0;
		-o-border-top-left-radius: 0;
		border-top-left-radius: 0;
		-webkit-border-bottom-left-radius: 0;
		-moz-border-bottom-left-radius: 0;
		-ms-border-bottom-left-radius: 0;
		-o-border-bottom-left-radius: 0;
		border-bottom-left-radius: 0;
		.icon-arrow {
			fill: rgba(217,107,29,.95);
			transition: all .2s ease-in-out;
			width: 40px;
			height: 40px;		
			@media (max-width:979px) {		
				width: 30px;
				height: 30px;
			}
		}
		&:hover,
		&:active {
			.icon-arrow {
				fill: rgba(217,107,29,.95);
				transform: scale(1.1);				
			}
		}		
	}
	.slick-next {
		right: 47%;
		z-index: 9;
		width: 40px;
		height: 40px;
		@media (min-width:768px) {		
			top: 90% !important;
		}			
		@media (min-width:980px) and (max-width:1279px) {	
			top: 88% !important;
			right: 45%;
		}
		@media (min-width:768px) and (max-width:979px) {	
			top: 88% !important;
			right: 44%;
		}		
		@media (max-width:767px) {		
			right: 3%;
			top: 25% !important;
		}		
		&:before {
			display: none;
		}
		-webkit-border-top-right-radius: 0;
		-moz-border-top-right-radius: 0;
		-ms-border-top-right-radius: 0;
		-o-border-top-right-radius: 0;
		border-top-right-radius: 0;
		-webkit-border-bottom-right-radius: 0;
		-moz-border-bottom-right-radius: 0;
		-ms-border-bottom-right-radius: 0;
		-o-border-bottom-right-radius: 0;
		border-bottom-right-radius: 0;
		.icon-arrow {
			fill: rgba(217,107,29,.95);
			transition: all .2s ease-in-out;
			width: 40px;
			height: 40px;	
			@media (max-width:979px) {		
				width: 30px;
				height: 30px;
			}		
		}	
		&:hover,
		&:active {
			.icon-arrow {
				fill: rgba(217,107,29,.95);
				transform: scale(1.1);				
			}
		}		
	}
	
}

.carousel-hero-new {
	.hero-slide {
		.hero-media {
			.hero-text {
				&:before {
					@media(min-width:1200px) and (max-width:1280px) {
						display: none;
					}
					@media(min-width:768px) and (max-width:1199px) {
						background-color: rgba(4, 61, 120, 0.5);
					}					
					@media(min-width:1281px) and (max-width:1800px) {
						background-color: rgba(4, 61, 120, 1);
					}					
				}
			}
		}
	}
}

.banner-hero-all {
	margin-bottom: 40px;
	position: relative;
	overflow: hidden;
	max-height: 500px;
}

.carousel-hero-new {
	.hero-slide {
		.hero-media {
			.hero-text {
				.hero-text-table {
					.hero-text-table-tr {
						.hero-text-table-td {
							&:first-child {
								@media (max-width:1600px) {
									padding: 20px 15px;									
								}
								@media (min-width:768px) and (max-width:1599px) {
									padding-bottom: 20px;									
								}
								@media (max-width:767px) {
									padding: 20px 20px 30px;	
								}
							}							
						}						
					}						
				}			
			}				
		}	
	}
}

.hero-text {
	@media (max-width:767px) {		
		display: block;
		background-color: #043d79;
		padding-bottom: 30px;
	}	
	h1, h2 {
		&.title {
			display: block;
			margin: 0 0 37px;
			padding: 0 27px 40px 0;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 50px;
			font-weight: 600;
			line-height: 50px;
			text-transform: none;		
			color: #fff;
			letter-spacing: 0;
			text-align: left;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 80px;
				height: 5px;
				background-color: #e4701e;
			}
			@media (min-width:1281px) and (max-width:1599px) {
				margin: 0 0 17px;
				padding: 0 17px 20px 0;				
				font-size: 40px;
				line-height: 40px;				
			}
			@media (min-width:980px) and (max-width:1280px) {
				margin: 0 0 12px;
				padding: 0 12px 15px 0;				
				font-size: 30px;
				line-height: 30px;				
			}
			@media (min-width:481px) and (max-width:979px) {
				margin: 0 0 12px;
				padding: 0 12px 15px 0;				
				font-size: 25px;
				line-height: 25px;				
			}
			@media (max-width:480px) {
				margin: 0 0 12px;
				padding: 0 0 15px 0;				
				font-size: 25px;
				line-height: 25px;				
			}			
		}
		span {
			display: block;		
			text-align: center;
			margin-top: -12px;
			&:first-child {
				margin-top: 0px;
				font-size: 36px;
				font-weight: 200;
				line-height: 40px;		
				@media (min-width:481px) and (max-width:979px) {		
					font-size: 24px;
					line-height: 27px;
				}
				@media (max-width:767px) {		
					font-size: 21px;
					line-height: 25px;	
				}				
			}
			@media (max-width:767px) {		
				margin-top: -4px;	
			}			
		}
	}
	p {
		float: left;
		display: block;
		margin: 0 0 50px;
		padding: 0 27px 0 0;
		font-family: $font-stack;
		font-size: 24px;
		font-weight: 600;
		line-height: 33px;
		text-transform: none;		
		color: #fff;
		@media (min-width:1281px) and (max-width:1599px) {
			margin: 0 0 17px;
			padding: 0 17px 0 0;				
			font-size: 19px;
			line-height: 23px;				
		}
		@media (min-width:481px) and (max-width:1280px) {
			margin: 0 0 12px;
			padding: 0 12px 0 0;				
			font-size: 16px;
			line-height: 20px;				
		}
		@media (max-width:480px) {
			margin: 0 0 22px;
			padding: 0;				
			font-size: 16px;
			line-height: 20px;			
		}		
	}
	span {
		&.btn {
			display: table;
			min-width: 180px;
			min-height: 50px;
			font-family: $font-stack;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			text-shadow: 1px 0 0 rgba(10, 78, 140, 0);
			text-transform: none;
			padding: 0;
			color: #0a4e8c;
			background-color: #fff;
			border-color: rgba(10, 78, 140, 0);
			border-radius: 0;
			-webkit-transform: scale(1.04);
			-ms-transform: scale(1.04);
			transform: scale(1.04);
			transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);	
			@media (min-width:1281px) and (max-width:1599px) {				
				min-width: 140px;
				min-height: 35px;			
			}
			@media (min-width:980px) and (max-width:1280px) {
				min-width: 120px;
				min-height: 25px;
				font-size: 14px;
				line-height: 18px;				
			}
			@media (min-width:481px) and (max-width:979px) {
				min-width: 100px;
				min-height: 15px;
				font-size: 12px;
				line-height: 16px;				
			}
			@media (max-width:480px) {
				min-width: 100px;
				min-height: 15px;
				font-size: 12px;
				line-height: 16px;		
			}			
			span {
				display: table-cell;
				vertical-align: middle;
				border: none;
				padding: 8px;
			}				
		}
	}	
}

.hero-slide {
	a {
		&.banner-link {
			display: block;
			&:hover,
			&:focus {
				.btn {
					border-color: rgba(228, 112, 30, 1);
					-moz-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
					-webkit-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
					-o-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);		
					box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
					-webkit-transform: scale(1.08);
					-ms-transform: scale(1.08);
					transform: scale(1.08);							
				}
			}
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				bottom: -5px;
				border-bottom: 10px solid #fff;
			}
		}
	}
}


.hero-banner-vertical-text-rail {
	position: absolute;
	left: 30px;
	width: 0;
	height: 100%;
	text-align: center;
	@media (max-width:767px) {
		display: none;
	}			
	@media (max-width:1840px) {
		left: 0;
		right: inherit;
		top: -35px;
		width: 100%;
		height: 30px;
		display: none;
	}		
	.hero-banner-vertical-text {	
		position: relative;
		top: -8%;
		overflow: hidden;			
		.hero-banner-vertical-text-in {
			-webkit-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			transform: rotate(-90deg);	
			transform-origin: left top 0;	
			display: table;
			min-width: 500px;
			width: 100%;
			overflow: hidden;
			font-family: 'Lato', sans-serif;
			font-size: 22px;
			font-weight: 300px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #dcdfe0;
			@media (max-width:1840px) {
				-webkit-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				transform: rotate(0deg);	
			}				
		}
	}			
}

@media only screen and (min-width:320px) and (max-width:767px) {	

	.hero .hero-slide {
		overflow: hidden
	} 
	.hero > .hero-slide {
		display: block
	}
	.hero > .hero-slide img {
		display: block
	}
	.hero > .hero-slide img {
		position: relative; 
		left: 50%;
		margin-left: -390px;
		width: 800px !important;
		min-height: 225px;
		height: auto !important;
		max-height: 225px !important;
		max-width: none !important;
	}
	
}

.services {
	.slick-track {
		display: flex;
	}
	.slick-slide {
		height: auto;
	}
}

.hawk-recommendation-list {
	.slick-next {
		right: 10px;
	}
	.slick-prev {
		left: 10px;
	}
}

.carousel-hero-new {
	margin-bottom: 30px;
}

//.hero-slide {
//	visibility: hidden;
//	height: 0;
//	.banner-link {
//		.hero-media {
//			display: none;
//			img {
//				height: 0 !important;
//			}		
//		}	
//	}
//	&.slick-active {
//		visibility: visible;
//		height: 100%;
//		min-height: 1px;
//		.banner-link {
//			.hero-media {
//				display: table-cell;
//				img {
//					height: auto !important;
//				}		
//			}
//		}		
//	}
//	&:first-child {
//		visibility: visible;
//		height: 100%;
//		min-height: 1px;
//		.banner-link {
//			.hero-media {
//				display: table-cell;
//				img {
//					height: auto !important;
//				}		
//			}
//		}	
//	}
//}

.hero-slide {
	&.slick-slider {
		.hero-media {
			display: table-cell !important;
		}
	}
}

.carousel-hero-new {
	.slick-slide {
		.hero-text {
			h2 {
				display: none;
			}

			span.btn {
				display: none;
			}
		}
	}
}