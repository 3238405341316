﻿.my-account-header {
    background-color: $color-primary;
    color: $color-text-secondary;

    .pageHeading {
        .title {
            color: $color-text-secondary;
            margin: 0;
        }
    }

    .railNav {
        display: none;
    }
}

.my-account-container {
    max-width: 1340px;
    margin: 25px auto 50px auto;

    .panel-default {
        > .panel-heading {
            padding: 0;
        }
    }

    h2 {
        color: #333;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 1.6em;
        font-weight: 500;
        margin: 0;

        &.panel-title {
            padding: 0 0 25px 0;
            border-bottom: 1px solid $color-border;
        }
    }

    h4 {
        color: #ff0000;
        font-size: 1.15em;
        font-weight: 400;
        margin: 5px 0 25px 0;
    }

    .panel-body {
        padding: 25px 0;
    }

    .formee-req-key {
        font-size: 1.25em;
        margin: 0 0 25px 0;
    }

    .formee label, .formee-lbl {
        color: #333;
        font-size: 1.25em;
        font-weight: 600;
    }

    .formee {
        .add-sub-user-row {
            .col-sm-6 {
                padding: 0;

                &.add-fname-col, &.add-email-col {
                    padding: 0 50px 0 0;
                }
            }
        }
    }
}


.my-account-logo-container {
    margin: 0 20px 0 0;
    @include border-default;
}

.my-account-setup-container {
    padding: 20px 40px;
    margin: 0 0 40px 0;
    @include border-default;
}

.add-sub-users-content-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 20px 0;
}

.add-sub-users-icon-container {
    display: inline-flex;

    .sub-users-icon {
        fill: $dark-gray;
        width: 80px;
        height: 80px;
    }
}

.add-sub-users-content-container {
    width: 70%;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0 15px;
}

.add-sub-users-header {
    @include subtitle-font;
    line-height: 1.75em;
}

.add-sub-users-content {
    line-height: 1.75em;
}

.add-sub-users-action-container {
    display: inline-flex;
}

.my-account-details-container {
    padding: 20px 40px;
    margin: 0 0 40px 0;
    @include border-default;
}
 
.my-account-details-title {
    @include title-font-small;
    line-height: 1.75em;
    padding: 0 0 20px 0;
    margin: 0 0 40px 0;
    @include border-btm-default;
}

.my-account-details-info-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.my-account-details-org-container,
.my-account-details-member-container {
    width: 49%;
    display: inline-flex;
    align-items: center;

    @media(max-width:767px) {
        width: 100%;
    }
}

.my-account-details-icon-container {
    display: inline-flex;
    padding: 0 20px 0 0;

    .icon {
        fill: $dark-gray;
        width: 80px;
        height: 80px;
    }
}

.my-account-details-content-container {
    flex-wrap: wrap;
}

.my-account-details-content-title {
    @include sm-callout-title-font;
    line-height: 1.75em;
}


.my-account-history-catalog-container {
    padding: 20px 40px;
    margin: 0 0 40px 0;
    @include border-default;

    .search-products-wrapper {
        border: none;
    }

    .search-products-header {
        background-color: transparent;
        padding: 0;
    }

    #order-history-wrapper {
        .search-products-header {
            .roc-pageOptions-filter {
                width: 100%;
                margin: 0 0 20px 0;

                &:last-of-type {
                    margin: 0;
                }

                .formee-group-btn {
                    // top: 0;
                    // left: 0;
                    width: 18%;
                    vertical-align: middle;
                    margin-right: -2%;
                    margin-bottom: -4px;
                    display: inline-block;

                    @media(max-width:767px) {
                        width: 9%;
                    }
                }

                .btn {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    padding: 0;
                    // margin: 20px 0 0 0;

                    .icon {
                        fill: $white;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

.my-account-favorites-container {
    padding: 20px 40px;
    @include border-default;
}

.my-account-container {
    .roc-pageOptions-filter {
        width: 100%;
        margin: 0 0 20px 0;

        &:last-of-type {
            margin: 0;
        }

        .formee-group-btn {
            top: 0;
            left: 0;
        }

        .btn {
            width: 96%;
            height: 50px;
            margin: 0 0 0 0;

            .icon {
                fill: $white;
                width: 30px;
                height: 30px;
                position: relative;
                top: -5px;
            }
        }
    }
}





/**********************************/
/*   MY ACCOUNT - ADD SUB USERS  */
/********************************/
.create-account-container {
    margin: 25px 0;
    padding: 25px 50px;
    border: 1px solid $color-border;
}


.permissions-wrapper {
    .col-sm-12 {
        display: flex;
        align-items: flex-start;
        margin: 0 0 15px 0;
    }

    input[type=checkbox] {
        -webkit-appearance: auto;
        background-color: #fff;
        width: 18px;
        height: 18px;
        position: relative;
        top: 2px;
        margin: 0 8px 0 0;
        border: 1px solid #888;
        border-radius: 4px;
    }

    label {
        display: inline-block;
        margin: 0 3px 0 0;

        span {
            display: none;
            width: 8px;
            height: 13px;
            transform: rotate(45deg);
            position: relative;
            top: -1px;
            left: -13px;
            border-bottom: 4px solid #333;
            border-right: 4px solid #333;
            border-radius: 3px;
        }

        &.checked {
            input[type=checkbox] {
                margin: 0;
            }
            span {
                display: inline-block;
            }
        }
    }

    .sub-user-permissions-option {
        background-color: #888;
        width: 20px;
        height: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 2px;
        margin: 0 0 0 10px;
        border: 1px solid #888;
        border-radius: 50%;

        a {
            color: #fff !important;
            width: 20px;
            font-size: 1em;
            font-style: italic;
            text-align: center !important;
            position: relative;
            top: 1px;
            border-radius: 50%;

            &:first-of-type {
                a { top: 0; }
            }
        }

        .popover {
            max-width: 100%;
            min-width: 300px;
            background-color: #dfdfdf;
            border: none;
            border-radius: 0;

            &.right {
                > .arrow {
                    border-right-color: #dfdfdf;
                }
            }

            h3.popover-title {
                width: 100%;
                background-color: #dfdfdf;
                color: #333;
                font-size: 1.15em;
                line-height: 1.6em;
                padding: 15px;
                margin: 0;
                border: 1px solid #dfdfdf;
                border-radius: 0;
            }

            .popover-content {
                width: 100%;
                background-color: #fff;
                font-size: 1.15em;
                line-height: 1.6em;
                padding: 15px;
                border-right: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                border-left: 1px solid #ccc;
                border-radius: 0;
            }
        }
    }
}

.add-sub-user-btns {
    float: right;
    text-align: right;

    .back-my-account {
        font-size: 1.25em;
        font-weight: 600;
    }

    .btn-addSubUser {
        font-size: 1.15em;
        padding: 15px 25px;
        margin: 0 0 0 25px;
    }
}




/*******************************************/
/*   MY ACCOUNT - CUSTOMER PART NUMBERS   */
/*****************************************/

.customer-part-number-wrapper {
    .listView {
        .itemWrapper {
            .productAddToCart {
                width: 200px;
                min-width: 200px;

                .btn {
                    margin: 0 0 10px 0;
                }

                @media(max-width:900px) and (min-width:767px) {
                    width: 120px;
                    min-width: 120px;

                    .btn {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}


/**********************************/
/*   MY ACCOUNT - RE-ORDER PAD   */
/********************************/

.reorder-pad-wrapper {
    .listView {
        .itemWrapper {
            .itemButtons {
                text-align: right;

                .btn {
                    padding: 5px 10px 6px;
                }
            }
        }
    }
}

.my-account-header {
	.my-account-sub-nav,
	 .my-account-title {
		@media(max-width:979px) {
			display: table !important;
			width: 100% !important;
			height: auto !important;
			align-items: inherit !important;
			.secondary-navbar-wrapper {
				.toggle-menu {
					display: none !important;
				}
				.secondary-nav {
					.nav-mobile-account {
						display: none !important;	
					}
					.cm-menu {
						max-height: none !important;
						opacity: 1 !important;
						overflow: visible !important;	
						>li {
							>.has-sub {
								&:before,
								&:after {
									display: none !important;
								}
							}
							&.li-nav {
								&.visible-xs {
									display: none !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**********************************/
/*  SMCUSA Configurator */
/********************************/

.smcusa-wrapper-all {
	position: relative;
	display: block;
	margin: 35px 0 0;	
	.smcusa-bredcrumbs-wrapper {
//		opacity: 0;
//		max-height: 0;
//		-wekbit-transition: all .4s ease-in-out;
//		transition: all .4s ease-in-out;
//		overflow: hidden;
		.breadcrumbs {
			margin: 0 0 20px;
		}
	}
//	&.bredcrumbs-visible {
//		.smcusa-bredcrumbs-wrapper {
//			opacity: 1;
//			max-height: 2000em;
//			.breadcrumbs {
//				margin-bottom: 20px;
//			}			
//		}
//	}
}

.smcusa-wrapper {
	position: relative;
	display: block;
	margin: 0;
	.item-wrapper {
		position: relative;
		display: block;			
		border: 1px solid #eaeaea;
		margin: 0 0 15px;
		.configurator-title,
		.configurator-sub-title {
			position: relative;
			display: block;	
			font-family: "IBM Plex Sans",sans-serif;
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
			color: #fff;
			background-color: #0a4e8c;
			margin: 0;
			padding: 15px 20px;
			width: 100%;
			cursor: pointer;
			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(90deg);
				transition: width 0.3s;
			}
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(0deg);
				transition: width 0.3s;
			}
			&.configurator-item-open {
				&:before {
					width: 0;
				}										
			}			
		}
		.configurator-content,
		.configurator-sub-content {
			position: relative;
			display: block;
			padding: 0 35px;			
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;		
			&.configurator-item-open {			
				padding-top: 35px;
				padding-bottom: 35px;
				max-height: 2000em;
			}
			.image-wrapper {
				position: relative;
				display: block;
				float: right;
				margin: 0 0 15px 15px;
				border: 1px solid #eaeaea;
				@media(max-width:479px) {
					float: none;
					display: table;
					width: auto;
					margin: 0 auto 15px;
				}
				img {
					width: 100%;
					max-width: 140px;
				}				
			}
			ul {
				position: relative;
				display: block;
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					display: block;
					margin: 0 0 7px;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						position: relative;
						font-size: 16px;
						color: #0a4e8c;
						display: inline;
						box-decoration-break: clone;
						transition: all .5s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom,rgba(10, 78, 140,.7),rgba(10, 78, 140,.7));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;
						text-decoration: none;
						cursor: pointer;
						&:after {
							content: " ";
							display: inline-block;
							position: absolute;
							top: 9.5px;
							right: -12px;
							margin: 1px 0 0 0;
							width: 8px;
							height: 8px;
							vertical-align: middle;
							border-bottom: 2px solid #0a4e8c;
							border-right: 2px solid #0a4e8c;
							border-bottom-right-radius: 1px;
							-wekbit-transition: all 0.8s ease-in-out;
							transition: all 0.8s ease-in-out;
							-webkit-transform: scale(0.85) translateY(-75%) rotate(-45deg);
							-ms-transform: scale(0.85) translateY(-75%) rotate(-45deg);
							transform: scale(0.85) translateY(-75%) rotate(-45deg);							
						}
						&:hover,
						&:focus {
							text-decoration: none;
							background-size: 100% 1px;
							&:after {
								border-bottom: 2px solid rgba(10, 78, 140,1);
								border-right: 2px solid rgba(10, 78, 140,1);	
								-webkit-transform: scale(0.97) translateY(-75%) rotate(-45deg);
								-ms-transform: scale(0.97) translateY(-75%) rotate(-45deg);
								transform: scale(0.97) translateY(-75%) rotate(-45deg);								
							}
						}
					}
				}
			}
		}
	}
	.smcusa-wrapper-sub-all {
		position: relative;
		display: block;		
		.smcusa-sub-description-wrap {
			position: relative;
			display: table;
			width: 100%;
			margin-bottom: 20px;
			h2 {
				&.title {
					display: block;
					font-family: Montserrat,Arial,sans-serif;
					font-size: 28px;
					font-weight: 400;
					line-height: 34px;						
					text-align: left;
					color: #e4701e;
					text-transform: uppercase;
					width: 100%;
					margin: 0 0 20px;
					padding: 0;					
				}
			}
			.image-wrapper {
				position: relative;
				display: block;
				float: right;
				margin: 0 0 15px 15px;
				border: 1px solid #eaeaea;
				@media(max-width:479px) {
					float: none;
					display: table;
					width: auto;
					margin: 0 auto 15px;
				}				
				img {
					width: 100%;
					max-width: 140px;
				}
			}	
		}
	}
}

.my-account-pg-wrapper {
    @media(max-width: 1200px) {
        .my-account-history-catalog-container {
            padding: 20px 10px;
        }
    }
}

@media(max-width: 951px) {
    #order-history-wrapper {
        .search-products-pagination {
            .paging {
                display: block;
                text-align: center;

                .icon-arrow-bracket-left,
                .icon-arrow-bracket-right,
                .total {
                    display: inline-block !important;
                }

                input[type=number] {
                    margin: 0;
                }
            }

            .pagination input {
                margin-top: 0;
            }

            .roc-pageOptions-count {
                display: block;
                top: auto;
                text-align: center;
            }
        }
    }
}