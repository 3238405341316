﻿.company-cols3 {

    .sfContentBlock {

        .fbox-row {
            margin: 40px 0 0 0;

            @media(max-width:767px) {
                margin: 0;
            }
        }

        h2 {
            min-height: 60px;
            
            @media(max-width:992px) {
                min-height: 90px;
            }

            @media(max-width:980px) {
                min-height: 75px;
            }

            @media(max-width:767px) {
                min-height: 0;
            }
        }

        p {
            min-height: 115px;

            @media(max-width:992px) {
                min-height: 200px;
            }

            @media(max-width:767px) {
                min-height: 0;
            }
        }

        .company-button-container {
            margin: 0 0 40px 0;

            @media(max-width:767px) {
                margin: 0 0 20px 0;
            }
        }
    }

    @media (max-width:992px) {
        &.sf_3cols_1_33 {
            width: 32%;
            margin: 0 1% 0 0;
        }

        &.sf_3cols_2_34 {
            width: 33%;
            margin: 0 1% 0 0;
        }
    }

    @media (max-width:767px) {
        width: 100% !important;

        &.sf_3cols_2_34, .sf_3cols_3_33 {
            .sf_3cols_2in_34, .sf_3cols_3in_33 {
                margin-left: 0;
            }
        }
    }
}

.newsletter-popup {
    h3 {
        font-size: 2em;
        margin: 10px 0;
    }

    p {
        font-size: 1.25em;
        font-weight: 700;
    }

    span {
        color: #ac0000;
    }

    .icon-arrow-right {
        color: #fff;
    }
}

.company-about-pg-wrapper {

    .interior-callout-item {
        width: 30%;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 25px 4.5% 25px 0;

        @media(min-width:1401px) {
            &:nth-of-type(3n+0) {
                margin: 25px 0;
            }
        }

        @media(max-width:1400px) {
            width: 48%;
            margin: 25px 4% 25px 0;

            &:nth-of-type(2n+0) {
                margin: 25px 0;
            }
        }

        @media(max-width:768px) {
            width: 100%;
            margin: 25px 0;
        }
    }
}