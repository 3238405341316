.sf-backend-wrp .list-group-item {
    &.roc-admin-disabled {
        color: #bbb;
        background-color: #FbFbFb;
        cursor: default;
        
        select {
            opacity: 0.75;
        }
    }
    
    &.roc-admin-enabled {
        background: #fff !important;
        cursor: default !important;
    }
}


/* SF admin icons */

.RadDock [class*="rocIconSF-"] { background-image: url(../../images/admin-icons/rocAdminWidgetThumbs.gif) !important; }
.RadDock .rdContent a[class*="rocIconSF-"] { background-position: 50% 0; }

.RadDock .rocIconSF-hawksearchListing { background-position: 3px -100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-hawksearchListing { background-position: 50% -100px !important; }

.RadDock .rocIconSF-hawksearchRecommendations { background-position: 3px -200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-hawksearchRecommendations { background-position: 50% -200px !important; }

.RadDock .rocIconSF-hawksearchRelated { background-position: 3px -300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-hawksearchRelated { background-position: 50% -300px !important; }

.RadDock .rocIconSF-topNav { background-position: 3px -400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-topNav { background-position: 50% -400px !important; }

.RadDock .rocIconSF-breadcrumb { background-position: 3px -500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-breadcrumb { background-position: 50% -500px !important; }

.RadDock .rocIconSF-footer { background-position: 3px -600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-footer { background-position: 50% -600px !important; }

.RadDock .rocIconSF-leftNav { background-position: 3px -700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-leftNav { background-position: 50% -700px !important; }

.RadDock .rocIconSF-pageHeading { background-position: 3px -800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-pageHeading { background-position: 50% -800px !important; }

.RadDock .rocIconSF-reviewNew { background-position: 3px -900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-reviewNew { background-position: 50% -900px !important; }

.RadDock .rocIconSF-changePassword { background-position: 3px -1000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-changePassword { background-position: 50% -1000px !important; }

.RadDock .rocIconSF-favoritesEmail { background-position: 3px -1100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-favoritesEmail { background-position: 50% -1100px !important; }

.RadDock .rocIconSF-favoritesLanding { background-position: 3px -1200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-favoritesLanding { background-position: 50% -1200px !important; }

.RadDock .rocIconSF-favoritesReadOnly { background-position: 3px -1300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-favoritesReadOnly { background-position: 50% -1300px !important; }

.RadDock .rocIconSF-favoritesFind { background-position: 3px -1400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-favoritesFind { background-position: 50% -1400px !important; }

.RadDock .rocIconSF-forgotPassword { background-position: 3px -1500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-forgotPassword { background-position: 50% -1500px !important; }

.RadDock .rocIconSF-login { background-position: 3px -1600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-login { background-position: 50% -1600px !important; }

.RadDock .rocIconSF-logout { background-position: 3px -1700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-logout { background-position: 50% -1700px !important; }

.RadDock .rocIconSF-announcements { background-position: 3px -1800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-announcements { background-position: 50% -1800px !important; }

.RadDock .rocIconSF-memberLanding { background-position: 3px -1900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-memberLanding { background-position: 50% -1900px !important; }

.RadDock .rocIconSF-memberNav { background-position: 3px -2000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-memberNav { background-position: 50% -2000px !important; }

.RadDock .rocIconSF-memberRegistration { background-position: 3px -2100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-memberRegistration { background-position: 50% -2100px !important; }

.RadDock .rocIconSF-productAttributes { background-position: 3px -2200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productAttributes { background-position: 50% -2200px !important; }

.RadDock .rocIconSF-productDetails { background-position: 3px -2300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productDetails { background-position: 3px -2300px !important; }

.RadDock .rocIconSF-productImage { background-position: 3px -2400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productImage { background-position: 50% -2400px !important; }

.RadDock .rocIconSF-productReviews { background-position: 3px -2500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productReviews { background-position: 50% -2500px !important; }

.RadDock .rocIconSF-recentlyViewed { background-position: 3px -2600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-recentlyViewed { background-position: 50% -2600px !important; }

.RadDock .rocIconSF-upload { background-position: 3px -2700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-upload { background-position: 50% -2700px !important; }

.RadDock .rocIconSF-attributeLanding { background-position: 3px -2800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeLanding { background-position: 50% -2800px !important; }

.RadDock .rocIconSF-attributeMenu { background-position: 3px -2900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeMenu { background-position: 50% -2900px !important; }

.RadDock .rocIconSF-banner { background-position: 3px -3000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-banner { background-position: 50% -3000px !important; }

.RadDock .rocIconSF-siteHeader { background-position: 3px -3100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-siteHeader { background-position: 50% -3100px !important; }

.RadDock .rocIconSF-pageTools { background-position: 3px -3200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-pageTools { background-position: 50% -3200px !important; }

.RadDock .rocIconSF-contact { background-position: 3px -3300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-contact { background-position: 50% -3300px !important; }

.RadDock .rocIconSF-liveChat { background-position: 3px -3400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-liveChat { background-position: 50% -3400px !important; }

.RadDock .rocIconSF-newsletterSignup { background-position: 3px -3500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-newsletterSignup { background-position: 50% -3500px !important; }

.RadDock .rocIconSF-attributeListing { background-position: 3px -3600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeListing { background-position: 50% -3600px !important; }

.RadDock .rocIconSF-attributeListingAlt { background-position: 3px -3700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeListingAlt { background-position: 50% -3700px !important; }

.RadDock .rocIconSF-newsletterPopup { background-position: 3px -3800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-newsletterPopup { background-position: 50% -3800px !important; }

.RadDock .rocIconSF-cartBase { background-position: 3px -3900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-cartBase { background-position: 50% -3900px !important; }

.RadDock .rocIconSF-cart { background-position: 3px -4000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-cart { background-position: 50% -4000px !important; }

.RadDock .rocIconSF-cartHeader { background-position: 3px -4100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-cartHeader { background-position: 50% -4100px !important; }

.RadDock .rocIconSF-cartSummary { background-position: 3px -4200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-cartSummary { background-position: 50% -4200px !important; }

.RadDock .rocIconSF-cartSummaryRail { background-position: 3px -4300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-cartSummaryRail { background-position: 50% -4300px !important; }

.RadDock .rocIconSF-attributeCustomField { background-position: 3px -4400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeCustomField { background-position: 50% -4400px !important; }

.RadDock .rocIconSF-emailManage { background-position: 3px -4500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-emailManage { background-position: 50% -4500px !important; }

.RadDock .rocIconSF-favoritesBar { background-position: 3px -4600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-favoritesBar { background-position: 50% -4600px !important; }

.RadDock .rocIconSF-favoritesFriends { background-position: 3px -4700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-favoritesFriends { background-position: 50% -4700px !important; }

.RadDock .rocIconSF-reviewLatest { background-position: 3px -4800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-reviewLatest { background-position: 50% -4800px !important; }

.RadDock .rocIconSF-accountDetails { background-position: 3px -4900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-accountDetails { background-position: 50% -4900px !important; }

.RadDock .rocIconSF-backToAdmin { background-position: 3px -5000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-backToAdmin { background-position: 50% -5000px !important; }

.RadDock .rocIconSF-productAccordion { background-position: 3px -5100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productAccordion { background-position: 50% -5100px !important; }

.RadDock .rocIconSF-productAddToCart { background-position: 3px -5200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productAddToCart { background-position: 50% -5200px !important; }

.RadDock .rocIconSF-productQA { background-position: 3px -5300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productQA { background-position: 50% -5300px !important; }

.RadDock .rocIconSF-productTools { background-position: 3px -5400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productTools { background-position: 50% -5400px !important; }

.RadDock .rocIconSF-productPrevNext { background-position: 3px -5500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productPrevNext { background-position: 50% -5500px !important; }

.RadDock .rocIconSF-productTools { background-position: 3px -5600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productTools { background-position: 50% -5600px !important; }

.RadDock .rocIconSF-hawksearchListingFacets { background-position: 3px -5700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-hawksearchListingFacets { background-position: 50% -5700px !important; }

.RadDock .rocIconSF-attributeNavigation1Level { background-position: 3px -5800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeNavigation1Level { background-position: 50% -5800px !important; }

.RadDock .rocIconSF-attributeNavigation2Level { background-position: 3px -5900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-attributeNavigation2Level { background-position: 50% -5900px !important; }

.RadDock .rocIconSF-description { background-position: 3px -6000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-description { background-position: 50% -6000px !important; }

.RadDock .rocIconSF-sitemap { background-position: 3px -6100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-sitemap { background-position: 50% -6100px !important; }

.RadDock .rocIconSF-myFavorites { background-position: 3px -6200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-myFavorites { background-position: 50% -6200px !important; }

.RadDock .rocIconSF-myReviews { background-position: 3px -6300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-myReviews { background-position: 50% -6300px !important; }

.RadDock .rocIconSF-orderHistory { background-position: 3px -6400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-orderHistory { background-position: 50% -6400px !important; }

.RadDock .rocIconSF-savedOrderCarts { background-position: 3px -6500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-savedOrderCarts { background-position: 50% -6500px !important; }

.RadDock .rocIconSF-addressBook { background-position: 3px -6600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-addressBook { background-position: 50% -6600px !important; }

.RadDock .rocIconSF-savedPaymentMethods { background-position: 3px -6700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-savedPaymentMethods { background-position: 50% -6700px !important; }

.RadDock .rocIconSF-savedItems { background-position: 3px -6800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-savedItems { background-position: 50% -6800px !important; }

.RadDock .rocIconSF-orderStatus { background-position: 3px -6900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-orderStatus { background-position: 50% -6900px !important; }

.RadDock .rocIconSF-emailOrderCanceled { background-position: 3px -7000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-emailOrderCanceled { background-position: 50% -7000px !important; }

.RadDock .rocIconSF-emailOrderReturned { background-position: 3px -7100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-emailOrderReturned { background-position: 50% -7100px !important; }

.RadDock .rocIconSF-emailOrderShipped { background-position: 3px -7200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-emailOrderShipped { background-position: 50% -7200px !important; }

.RadDock .rocIconSF-productSpecifications { background-position: 3px -7300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productSpecifications { background-position: 50% -7300px !important; }

.RadDock .rocIconSF-searchBreadcrumb { background-position: 3px -7400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-searchBreadcrumb { background-position: 50% -7400px !important; }

.RadDock .rocIconSF-facets { background-position: 3px -7500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-facets { background-position: 50% -7500px !important; }

.RadDock .rocIconSF-shoppingCartListing { background-position: 3px -7600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-shoppingCartListing { background-position: 50% -7600px !important; }

.RadDock .rocIconSF-shoppingCartEmail { background-position: 3px -7700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-shoppingCartEmail { background-position: 50% -7700px !important; }

.RadDock .rocIconSF-shoppingCartHelp { background-position: 3px -7800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-shoppingCartHelp { background-position: 50% -7800px !important; }

.RadDock .rocIconSF-savedCartListing { background-position: 3px -7900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-savedCartListing { background-position: 50% -7900px !important; }

.RadDock .rocIconSF-shoppingCartTools { background-position: 3px -8000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-shoppingCartTools { background-position: 50% -8000px !important; }

.RadDock .rocIconSF-emailConfirmation { background-position: 3px -8100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-emailConfirmation { background-position: 50% -8100px !important; }

.RadDock .rocIconSF-checkout { background-position: 3px -8200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-checkout { background-position: 50% -8200px !important; }

.RadDock .rocIconSF-shoppingCartSummary { background-position: 3px -8300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-shoppingCartSummary { background-position: 50% -8300px !important; }

.RadDock .rocIconSF-productPrice { background-position: 3px -8400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productPrice { background-position: 50% -8400px !important; }

.RadDock .rocIconSF-productTitle { background-position: 3px -8500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-productTitle { background-position: 50% -8500px !important; }

.RadDock .rocIconSF-quickOrder { background-position: 3px -8600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-quickOrder { background-position: 50% -8600px !important; }

.RadDock .rocIconSF-customerBalance { background-position: 3px -8700px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-customerBalance { background-position: 50% -8700px !important; }

.RadDock .rocIconSF-seo { background-position: 3px -8800px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-seo { background-position: 50% -8800px !important; }

.RadDock .rocIconSF-emailNewMember { background-position: 3px -8900px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-emailNewMember { background-position: 50% -8900px !important; }

.RadDock .rocIconSF-editSubUser { background-position: 3px -9000px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-editSubUser { background-position: 50% -9000px !important; }

.RadDock .rocIconSF-addSubUser { background-position: 3px -9100px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-addSubUser { background-position: 50% -9100px !important; }

.RadDock .rocIconSF-organizationMembers { background-position: 3px -9200px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-organizationMembers { background-position: 50% -9200px !important; }

.RadDock .rocIconSF-billingInfo { background-position: 3px -9300px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-billingInfo { background-position: 50% -9300px !important; }

.RadDock .rocIconSF-defaultShippingAddress { background-position: 3px -9400px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-defaultShippingAddress { background-position: 50% -9400px !important; }

.RadDock .rocIconSF-compare { background-position: 3px -9500px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-compare { background-position: 50% -9500px !important; }

.RadDock .rocIconSF-orderConfirmation { background-position: 3px -9600px !important; }
.RadDock .rdContent a.sfAddContentLnk.rocIconSF-orderConfirmation { background-position: 50% -9600px !important; }