﻿.my-account-header {
    width: 100%;
    display: flex;
    padding: 15px 0;

    > .container {
        width: 100%;
        max-width: 1340px;
        display: flex;
        padding: 0;

        > .row {
            width: 100%;
        }
    }

    .my-account-title, .my-account-sub-nav {
        height: 100%;
        display: inline-flex;
        align-items: center;
    }
}