﻿









.event-details-container {

    .event-details-banner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #fff;
        padding: 150px 50px;

        h2 {
            width: 100%;
            font-size: 2.5em;
            margin: 0;
        }

        p {
            width: 100%;
            font-size: 2em;
            font-weight: 200;
            margin: 0 0 15px 0;
        }

        .event-details-register-btn {
            width: 100%;

            .btn {
                background-color: #e4701e;
                font-weight: 500;

                &:hover {
                    background-color: darken(#e4701e, 10%);
                }
            }
        }
    }

    .event-details-info-icons-wrapper {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 50px 0;

        .event-details-info-icon-container {
            width: 33.333%;
            display: inline-flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            padding: 20px 0;

            .event-details-info-icon {
                width: 100%;
                color: #e4701e;
            }

            h3 {
                width: 100%;
                color: $color-primary;
                font-size: 2em;
                margin: 10px 0;
            }

            p {
                width: 100%;
                height: 85px;
                color: $color-primary;
                font-size: 1.25em;
                font-weight: 600;
            }

            &.event-details-info-icon-location, &.event-details-info-icon-cost {
                border-right: 1px solid #000;
            }

            &.event-details-info-icon-time {
                p {
                    text-transform: uppercase;
                }
            }
        }
    }

    .event-details-about-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: rgba(223, 223, 223, 0.3);
        text-align: center;
        margin: 50px 0;
        padding: 50px;

        h3 {
            width: 100%;
            font-size: 2em;
            margin: 0 0 15px 0;
        }

        h4 {
            width: 100%;
            margin: 10px 0;
        }

        p {
            width: 100%;
        }

        .event-details-about-btn-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 15px 0 0 0;

            .btn {
                min-width: 275px;
                font-weight: 600;
            }

            .event-details-download-btn {
                margin: 0 20px 0 0;
            }

            .event-details-register-btn {
                .btn {
                    background-color: #e4701e;

                    &:hover {
                        background-color: darken(#e4701e, 10%);
                    }
                }
            }
        }
    }

    .event-details-speakers-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: $color-primary-light;
        color: #fff;
        text-align: center;
        margin: 50px 0;
        padding: 50px;

        .event-details-featured-speakers-wrapper {
            width: 60%;
            display: inline-flex;
            flex-wrap: wrap;
        }

        .event-details-coordinator-wrapper {
            width: 40%;
            display: inline-flex;
            flex-wrap: wrap;
        }

        h3 {
            width: 100%;
            font-size: 2em;
        }

        h4 {
            font-size: 1.25em;
            font-weight: 500;
            margin: 20px 0 5px 0;
        }

        .event-details-featured-speaker-container {
            width: 50%;
        }

        .event-details-coordinator-container {
            width: 100%;
        }
    }

    .event-details-agenda-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin: 50px 0 0 0;

        h3 {
            width: 100%;
            font-size: 2em;
        }

        h4 {
            width: 100%;
            font-size: 1.75em;
            margin: 0 0 20px 0;
            padding: 0 0 10px 0;
            border-bottom: 2px solid #e4701e;
        }

        ul {
            margin: 0 0 0 -20px;
        }

        li {
            margin: 0 0 8px 0;
        }

        .event-details-agenda-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            text-align: left;
        }

        .event-details-agenda-date {
            width: 20%;
            display: inline-flex;
            flex-wrap: wrap;
            padding: 10px 20px;
        }

        .event-details-agenda-register-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 20px 0;

            .btn {
                background-color: #e4701e;

                    &:hover {
                        background-color: darken(#e4701e, 10%);
                    }
            }
        }
    }
}

.enrollment-policy-wrapper {
    max-width: 1300px;
    margin: auto;
    
    .enrollment-policy-title {
        font-size: 1.25em;
        font-weight: 700;
        text-align: center;
    }

    .enrollment-policy-subtitle {
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 0 5px 0;
    }

    .enrollment-policy-content {
        padding: 0 0 10px 40px;
    }

    .enrollment-policy-link {
        font-weight: 700;
        padding: 15px 0;
    }
}