﻿.print-invoice-container {

    table {
        border-collapse: collapse;
    }

    .table {
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;

        th, td {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid #dee2e6;
        }

        thead {
            th {
                vertical-align: bottom;
                border-bottom: 2px solid #dee2e6;
            }
        }

        thead-dark {
            th {
                color: #fff;
                background-color: #212529;
                border-color: #32383e;
            }
        }

        thead-light {
            th {
                color: #495057;
                background-color: #e9ecef;
                border-color: #dee2e6;
            }
        }

        tbody {
            + tbody {
                border-top: 2px solid #dee2e6;
            }
        }
    }

    .table-sm {
        th, td {
            padding: 0.3rem;
        }
    }

    .table-bordered {
        border: 1px solid #dee2e6;

        th, td {
            border: 1px solid #dee2e6;
        }

        thead {
            th, td {
                border-bottom-width: 2px;
            }
        }
    }

    .table-striped {
        tbody {
            tr {
                &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    .table-primary {
        background-color: #b8daff;

        > th, > td {
            background-color: #b8daff;
        }
    }

    .table-secondary {
        background-color: #d6d8db;

        > th, > td {
            background-color: #d6d8db;
        }
    }

    .table-success {
        background-color: #c3e6cb;

        > th, > td {
            background-color: #c3e6cb;
        }
    }

    .table-info {
        background-color: #bee5eb;

        > th, > td {
            background-color: #bee5eb;
        }
    }

    .table-warning {
        background-color: #ffeeba;

        > th, > td {
            background-color: #ffeeba;
        }
    }

    .table-danger {
        background-color: #f5c6cb;

        > th, > td {
            background-color: #f5c6cb;
        }
    }

    .table-light {
        background-color: #fdfdfe;

        > th, > td {
            background-color: #fdfdfe;
        }
    }

    .table-dark {
        color: #fff;
        background-color: #c6c8ca;
        /*background-color: #212529;*/

        > th, > td {
            background-color: #c6c8ca;
        }

        th, td {
            border-color: #32383e;
        }

        thead {
            th {
                border-color: #32383e;
            }
        }

        &.table-bordered {
            border: 0;
        }

        &.table-striped {
            tbody {
                tr {
                    &:nth-of-type(odd) {
                        background-color: rgba(255, 255, 255, 0.05);
                    }
                }
            }
        }

        &.table-hover {
            tbody {
                tr {
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.075);
                    }
                }
            }
        }
    }

    .table-active {
        background-color: rgba(0, 0, 0, 0.075);

        > th, > td {
            background-color: rgba(0, 0, 0, 0.075);
        }
    }

    .table-responsive {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        > .table-bordered {
            border: 0;
        }
    }

    .table-hover {
        tbody {
            tr {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.075);
                }
            }
        }

        .table-primary {
            &:hover {
                background-color: #9fcdff;

                td, th {
                    background-color: #9fcdff;
                }
            }
        }

        .table-secondary {
            &:hover {
                background-color: #c8cbcf;

                > td, > th {
                    background-color: #c8cbcf;
                }
            }
        }

        .table-success {
            &:hover {
                background-color: #b1dfbb;

                > td, > th {
                    background-color: #b1dfbb;
                }
            }
        }

        .table-info {
            &:hover {
                background-color: #abdde5;

                > td, > th {
                    background-color: #abdde5;
                }
            }
        }

        .table-warning {
            &:hover {
                background-color: #ffe8a1;

                > td, > th {
                    background-color: #ffe8a1;
                }
            }
        }

        .table-danger {
            &:hover {
                background-color: #f1b0b7;

                > td, > th {
                    background-color: #f1b0b7;
                }
            }
        }

        .table-light {
            &:hover {
                background-color: #ececf6;

                > td, > th {
                    background-color: #ececf6;
                }
            }
        }

        .table-dark {
            &:hover {
                background-color: #b9bbbe;

                > td, > th {
                    background-color: #b9bbbe;
                }
            }
        }

        .table-active {
            &:hover {
                background-color: rgba(0, 0, 0, 0.075);

                > td, > th {
                    background-color: rgba(0, 0, 0, 0.075);
                }
            }
        }
    }


    






    .container {
        max-width: 960px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .print-invoice-billing {
        float: right;

        table {
            td, th {
                padding: 10px;
            }
        }
    }

    .print-invoice-date-num {
        table {
            td, th {
                padding: 10px;
            }
        }
    }

    h2 {
        &.print-invoice-remit {
            margin: 10px 0 0 0;
        }

        &.print-invoice-bill, 
        &.print-invoice-ship {
            margin: 25px 0 0 0;
        }
    }

    p {
        &.print-invoice-remit, 
        &.print-invoice-bill, 
        &.print-invoice-ship {
            margin: 0;
        }
    }

    .invoice-details-table {
        font-size: 14px;
        margin: 15px 0;

        table {
            td, th {
                padding: 5px;
            }
        }
    }
}