﻿.itemList { 
    width: 100%;
    display: flex;
    flex-wrap: wrap;
	margin: 30px 0; 
	padding: 0; 
	list-style: none; 

    .item {
        width: 23%;
        display: inline-flex;
        flex-wrap: wrap;
        line-height: 1.75em;
        margin: 0 1.5% 1.5% 0;
        padding: 20px;
        border: 1px solid $color-border;
        cursor: default;

        @media(min-width:1200px) {
            &:nth-child(4n) {
                margin: 0;
            }
        }

        @media(max-width:1200px) and (min-width:900px) {
            width: 30%;
            &:nth-child(3n) {
                margin: 0;
            }
        }

        @media(max-width:900px) and (min-width:650px) {
            width: 48%;
            &:nth-child(2n) {
                margin: 0;
            }
        }

        @media(max-width:650px) {
            width: 100%;
            margin: 0 0 1.5% 0;
        }
    }

    .itemHeader {
        width: 100%;
        font-size: 1.15em;
        font-weight: 700;
    }

    .payment-icon {
        display: inline-block;
        position: relative;
        top: 3px;
        margin: 0 5px 0 0;
    }

    .payment-card-name {
        display: inline-block;
        margin: 0 0 5px 0;
    }

    .itemContent {
        width: 100%;
        padding: 0 0 15px 0;
    }

    .payment-edit {
        display: inline-block;
    }

//    .editMethod, .editAddress, .copyAddress {
//        font-weight: 500;
//        margin: 5px 5px 0 0;
//    }
	.editMethod, .editAddress, .copyAddress,
    .removeMethod, .removeAddress {
        font-weight: 500;
        margin: 5px 3px 0 3px;
		box-decoration-break: clone;
		transition: all 0.5s ease-in-out;
		position: relative;
		background-image: linear-gradient(to bottom, rgba(228, 112, 30,0.6), rgba(228, 112, 30,0.6)) !important;
		background-size: 0 1px;
		background-position: 0 100%;
		background-repeat: no-repeat;
		text-decoration: none;
		&:hover,
		&:focus {
			background-size: 100% 1px !important;
			-moz-box-shadow: 0 0 10px rgba(228,112,30,0) !important;
			-webkit-box-shadow: 0 0 10px rgba(228,112,30,0) !important;
			-o-box-shadow: 0 0 10px rgba(228,112,30,0) !important;
			box-shadow: 0 0 10px rgba(228,112,30,0) !important;
			-webkit-transform: perspective(1px) scale(1) !important;
			-ms-transform: perspective(1px) scale(1) !important;
			transform: perspective(1px) scale(1) !important;			
		}
    }

    .itemButton {
        width: 100%;
        display: flex;
        align-items: flex-end;

        .btn {
            @media(max-width:1400px) {
                font-size: .9em;
            }
        }
    }
}

#store-pickup-modal {
    .store-pickup {
        .itemList {
            .item {
                width: 100%;
                margin: 0 5px;
                display: inline-block;

                .contentExtra {
                    display: inline-block;
                }

                .alignRight {
                    float: right;
                }
            }
        }

        .formee-group-btn {
            .btn {
                height: 48px;

                &:hover,
                &:focus {
                    transform: scale(1);
                }
            }
        }
    }
}

.slideCartCompanion {
	#quote-details-wrapper {
		.panel {
			background-color: #fff;

			.panel {
				border: none;
			}

			.panel-open {
				background-color: #fff;

				.panel-body {
                    background: #fff;
                    padding: 0 0 15px 0;
				}
			}
		}
	}

	#quote-transaction-wrapper{
        margin-top: 15px;

		.panel {
            background-color: #fff;
            margin-bottom: 15px;

			.panel {
				border: none;
			}

			.panel-open {
				background-color: #fff;

				.panel-body {
                    background: #fff;
                    padding: 0 0 15px 0;
                    
                    .itemList {
                        margin: 0;

                        .interiorList {
                            .item {
                                border: none;
                            }
                        }
                    }
				}
			}
		}
    }

    .my-account-container {
        .panel-body {
            padding: 5px 0;
        }
    }
    
    .summary-left {
        width: 45%;
        float: left;
    }

    .summary-right {
        width: 45%;
        float: right;
    }
}