﻿.btn,
%btn {
    display: inline-block;
    background-color: $color-button-primary;
    color: #fff;
	font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif !important;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
    text-align: center;
	margin: 0;
	padding: 15px 40px;
    border: none;
	-webkit-transform: perspective(1px) scale(1);
	-ms-transform: perspective(1px) scale(1);
	transform: perspective(1px) scale(1);
	transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);

	@media(max-width: 1279px) {
		min-width: inherit;
		padding-left: 5px;
		padding-right: 5px;
	}
     
    &:active,
    &:focus,
	&:hover {
		color: #fff;
		background-color: $color-button-hover-primary;
		-moz-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
		-webkit-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
		-o-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);		
		box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
		-webkit-transform: perspective(1px) scale(1.08);
		-ms-transform: perspective(1px) scale(1.08);
		transform: perspective(1px) scale(1.08);
    }
}


.btnAlt {
    @extend %btn;
    background-color: $color-button-secondary;
    
    &:hover {
	   background-color:  darken( $color-button-secondary, 5% );
    }
}


.btnOutline {
    @extend %btn;
    background-color: $btn-outline;
    color: $btn-outline-accent;
    border: 1px solid $btn-outline-accent;
	padding-left: 15px;
	padding-right: 15px;
    &:focus,
	&:active,
	&:hover {
        background-color: $btn-outline;
        color: $btn-outline-hover;
        border: 1px solid $btn-outline-hover;
    }

}


.btnFullWidth {
    @extend %btn;
	display:block;
    width: 100%;
}


.btnText,
.btnTextAlt {
    @extend %btn;
    background: none;
    color: $link-text;
    font-size: 1em;
    font-weight: 700;
    line-height: inherit;
    text-align: center;
    text-decoration: none;
	padding: 0;
	cursor: pointer;

    &:active, 
    &:focus {
        background: none;
        color: $black;
    }
    &:hover {
        background: none;
        color: darken($link-text, 10%);
    }
}

.btnTextAlt {
    color: $color-button-primary;
}