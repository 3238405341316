
.home-full-banner {
	.home-full-banner-image {
		display: table;
		position: relative;
		margin-left: auto;
		margin-right: auto;		
		img {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.home-full-banner-text {
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
		.home-full-banner-text-table {
			display: table;
			table-layout: fixed;
			margin-left: auto;
			margin-right: auto;
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;
			max-width: 88.13em;
			height: 100%;
			.home-full-banner-text-table-tr {
				display: table-row;
				.home-full-banner-text-table-td {
					display: table-cell;
					height:100%;
					vertical-align: middle;
					text-align: center;
				}
			}
		}
	}
	h2 {
		&.title {
			display: block;
			margin: 0px auto 10px auto;
			padding: 0px 0px 0px 0px;
			max-width: 500px;
			font-family: Montserrat,Arial,sans-serif;
			font-size: 55px;
			font-weight: 700;
			line-height: 70px;
			text-transform: uppercase;		
			color: #fff;
			letter-spacing: 1px;
		}
	}
	p {
		margin: 0px 0px 10px 0px;
		font-family: 'Open Sans', sans-serif;	
		font-size: 38px;
		font-weight: 300;
		line-height: 47px;		
		color: #fff;
		
	}
	span {
		&.button {
			display: inline-block;
			margin-top: 10px;
			.btn {
				font-size: 14px !important;
				padding: 7px 40px;
				border-radius: 0px;
			}
		}
	}	
}

/* 16px baseline (980px - 1279px) */
@media only screen and (min-width:61.25em) and (max-width:79.94em) {	

	.home-full-banner h2.title {
		margin-bottom: 0px;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 45px;
		line-height: 50px;
	}
	.home-full-banner p {
		font-size: 30px;
		line-height: 35px;		
	}
	.home-full-banner span.button {
		margin-top: 5px;
	}
	
}

/* 16px baseline (768px - 979px) */
@media only screen and (min-width:48em) and (max-width:61.19em) {	
	
	.home-full-banner h2.title {
		margin-bottom: 0px;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 45px;
		line-height: 50px;
	}
	.home-full-banner p {
		font-size: 20px;
		line-height: 25px;		
	}
	.home-full-banner span.button {
		margin-top: 0px;
	}	
	
}

section {
	&.banner-wrap {
		&.image {
			background-repeat: no-repeat;
		}
		.container {
			max-width: 88.13em;
		}	
		.banner-image-table {
			display: table;
			width: 100%;
			.banner-image-tr {
				display: table-row;
				.pageHeading {
					display: table-cell;
					vertical-align: middle;			
					width: 70%;
					h2 {
						&.title {
							font-family: Montserrat,Arial,sans-serif;
							font-size: 4.38em;
							line-height: 75px;
							color: #f79800; 
							margin: 0px 0px 55px 0px;								
						}
					}
					p {
						margin: 0px 0px 0px 0px;	
						font-size: 1.63em;
						line-height: 35px;
						color: #092162; 						
					}
					&:not(.pageHeadingAlt) {
						padding-bottom: 0px;
						margin-bottom: 0px;
						border-bottom: 0px solid #dbdbdb;
					}
				}
				.contact-us {
					display: table-cell;	
					vertical-align: top;
					width: 30%;
					padding: 20px 60px;
					background-color: #3f3f3f;
					.row {
						margin-left: 0px;
						margin-right: 0px;
					}
					.form-side {
						.form-side-header {
							display: block;
							text-align: center;
							margin-bottom: 25px;
							h2 {
								&.title {
									font-family: Montserrat,Arial,sans-serif;
									font-size: 30px;
									line-height: 35px;
									color: #f79800; 
									margin: 0px 0px 0px 0px;
								}
							}
							h3 {
								&.sub-title {
									font-size: 20px;
									line-height: 25px;
									font-weight: 300;
									color: #ffffff; 
									margin: 0px 0px 0px 0px;
								}
							}							
						}
						.sfFormBox {
							.sfTxtLbl {
								display: none;
							}
							input {
								margin-bottom: 25px;
								&.btn {
									font-size: 1.19em;
									width: 100%;
									padding: 5px 14px 7px 14px;
									margin-bottom: 10px;
								}
							}
						}
					}
				}
			}
		}		
	}
}

.manufacturers-title {
	font-family: 'SemplicitaPro-Semibold'!important;
}
.manufacturers-index {
	a {
		font-weight:800;
	}
}

.rocModule-Banner {
	position: relative;
	&.top {
		margin-top: 0px;
		margin-bottom: 50px;
		@media (max-width:979px) {		
			margin-bottom: 15px;
		}	
		@media (max-width:767px) {		
			overflow: hidden;
		}			
		img {
			width: 100%;
		}
		.bannerImage {
			.banner-text {
				h1.title {
					font-family: 'SemplicitaPro-Semibold';
				}
				h2.sub-title {
					font-family: 'SemplicitaPro-LtIt';
				}
			}
		}
	}
	.banner-text {
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		.banner-text-table {
			display: table;
			table-layout: fixed;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			/*max-width: 88.13em;*/
			height: 100%;
			.banner-text-table-tr {
				display: table-row;
				.banner-text-table-td {
					display: table-cell;
					vertical-align: middle;
					text-align: left;
					padding: 20px 105px;
					@media (max-width:47.94em){
						text-align: center !important;
						padding: 15px 15px;
					}			
					@media (max-width:480px) {		
						padding: 5px 5px;
					}					
					@media (min-width:768px) and (max-width:1279px) {
						padding: 10px 20px;
					}					
				}
			}
		}
		h1, h2 {
			&.title {
				display: block;
				margin: 0;
				padding: 0 0 4px;
				font-family: Montserrat,Arial,sans-serif;
				font-size: 40px;
				font-weight: 900;
				line-height: 40px;
				text-transform: uppercase;		
				color: #fff;
				letter-spacing: 1px;
				@media (max-width:1279px) {
					font-size: 25px;
					line-height: 27px;
				}	
				@media (max-width:767px){
					font-size: 25px;
					line-height: 27px;
				}	
				@media (max-width:480px) {		
					font-size: 16px;
					line-height: 18px;
				}				
			}
		}
		h2 {
			&.sub-title {
				margin: 0 0 30px;
				padding: 0;
				font-family: Montserrat,Arial,sans-serif;
				font-size: 30px;
				font-weight: 100;
				line-height: 35px;
				text-transform: uppercase;		
				color: #fff;
				letter-spacing: 1px;
				@media only screen and (max-width:1279px){
					font-size: 20px;
					line-height: 25px;
					margin-bottom: 10px !important;
				}	
				/* ## 16px baseline (767px) ## */
				@media only screen and (max-width:47.94em){
					font-size: 20px;
					line-height: 20px;
				}	
				@media (max-width:480px) {		
					font-size: 11px;
					line-height: 15px;
					font-weight: 400;
				}				
			}
		}
		h3 {
			&.title {
				margin: 0 0 30px;
				padding: 0;
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
				font-size: 30px;
				font-weight: 100;
				line-height: 35px;
				text-transform: uppercase;		
				color: #fff;
				letter-spacing: 1px;
				@media only screen and (max-width:1279px){
					font-size: 20px;
					line-height: 25px;
					margin-bottom: 10px !important;
				}	
				/* ## 16px baseline (767px) ## */
				@media only screen and (max-width:47.94em){
					font-size: 20px;
					line-height: 20px;
				}	
				@media (max-width:480px) {		
					font-size: 11px;
					line-height: 15px;
					font-weight: 400;
				}				
			}
		}	
		span {
			&.button {
				display: inline-block;
				margin-top: 0px;
				.btn {
					font-size: 12px !important;
					padding: 10px 35px;
					color: #0a4e8c;
					background-color: #fff;
					border-color: #fff;
					border-radius: 5px;
					@media (min-width:768px) and (max-width:1279px) {
						padding: 7px 15px;
					}					
					.text {
						display: inline-block;
						vertical-align: middle;
						font-size: 12px !important;
						font-weight: 600;
						line-height: 15px;
						letter-spacing: 2px;
						color: #0a4e8c;								
					}					
				}
				.icon {
					display: inline-block;
					vertical-align: middle;
					fill: #0a4e8c;
					font-weight: 600;
					width: 9px;
					height: 10px;
					margin: -1px 0 0 5px;
				}
			}
		}		
	}
	a {
		-wekbit-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;			
		&:focus, 
		&:hover {
			span {
				&.button {
					display: inline-block;
					margin-top: 0px;
					.btn {
						background-color: #e4701e;
						border-color: #0a4e8c;
						-moz-box-shadow: 0 0 10px rgba(0,0,0,.3);
						-webkit-box-shadow: 0 0 10px rgba(0,0,0,.3);
						-o-box-shadow: 0 0 10px rgba(0,0,0,.3);
						box-shadow: 0 0 10px rgba(0,0,0,.3);
						.text {
							color: #fff;
						}
					}
					.icon {
						fill: #fff;
					}					
				}
			}
		}		
	}
	.full-banner-vertical-text-rail {
		position: absolute;
		top: 0;
		right: -80px;
		bottom: 0;
		width: 0;
		height: 100%;
		text-align: left;
		@media (max-width:767px) {
			display: none;
		}			
		@media (max-width:1840px) {
			left: 0;
			right: inherit;
			top: -35px;
			width: 100%;
			height: 30px;
			display: none;
		}		
		.full-banner-vertical-text {	
			position: relative;
			top: 0%;
			overflow: hidden;			
			.full-banner-vertical-text-in {
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);	
				transform-origin: left top 0;	
				display: table;
				min-width: 500px;
				width: 100%;
				overflow: hidden;
				font-family: 'Lato', sans-serif;
				font-size: 22px;
				font-weight: 300px;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #dcdfe0;
				@media (max-width:1840px) {
					-webkit-transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					transform: rotate(0deg);	
				}				
			}
		}			
	}	
}	

/* 16px baseline (980px - 980px) */
@media only screen and (min-width:61.25em) and (max-width:80em) {	

	.productList .productListInner {
		margin-left: 0px !important;
		margin-right: 0px !important;		
	}
	
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading {
		width: 50%;
		padding-right: 15px;
		padding-bottom: 15px !important;
	}	
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading h2.title {
		margin-bottom: 20px;
	}

	section.banner-wrap .banner-image-table .banner-image-tr .contact-us {
		width: 50%;
	}	
	
	.interior-row {
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}	
	
}

/* 16px baseline (768px - 979px) */
@media only screen and (min-width:48em) and (max-width:61.19em) {	

	.productList .productListInner {
		margin-left: 0px !important;
		margin-right: 0px !important;		
	}
	
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading {
		width: 50%;
		padding-right: 15px;
		padding-bottom: 15px !important;
	}	
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading h2.title {
		margin-bottom: 20px;
	}

	section.banner-wrap .banner-image-table .banner-image-tr .contact-us {
		width: 50%;
	}	
	
	.scroll .rocModule-miniList.full {
		padding-top: 0px !important;
	}
	
	.interior-row {
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}	
	.interior-row .col-sm-9 {
		display: table !important;
		width: 100% !important;
		&.grey {
			height: 230px;
		}
	}	
	.interior-row .col-sm-3 {
		padding-top: 20px;
		padding-left: 10px;
		display: table !important;
		width: 100% !important;
		&.grey {
			height: 230px;
		}		
	}	
	.interior-row img.full-image {
		left: inherit;
	}	
	
}
	
/* 16px baseline (180px - 767px) */
@media only screen and (min-width:11.250em) and (max-width:47.938em) {	

	.home-full-banner .home-full-banner-image {
		overflow: hidden
	} 
	.home-full-banner > .home-full-banner-image {
		display: block
	}
	.home-full-banner > .home-full-banner-image img {
		display: block
	}
	.home-full-banner > .home-full-banner-image img {
		position: relative; 
		left: 50%;
		margin-left: -300px;
		width: 800px !important;
		min-height: 270px;
		height: auto !important;
		max-height: 270px !important;
		max-width: none !important;
	}
	
	.rocModule-Banner {
		overflow: hidden
	} 
	.rocModule-Banner > a,
	.rocModule-Banner .bannerImage > a {
		display: block
	}
	.rocModule-Banner > a img,
	.rocModule-Banner .bannerImage > a img {
		display: block
	}
	.rocModule-Banner > a img,
	.rocModule-Banner .bannerImage > a img {
		position: relative; 
		left: 50%;
		margin-left: -525px;
		width: 1050px !important;
		min-height: 250px;
		height: auto !important;
		max-height: 250px !important;
		max-width: none !important;
	}	
	
	.home-full-banner h2.title {
		max-width: 320px;
		font-family: Montserrat,Arial,sans-serif;
		font-size: 30px;
		line-height: 35px;
	}	
	.home-full-banner p {
		margin: 0 0 10px 0;
		font-size: 20px;
		line-height: 25px;
	}
	.home-full-banner span.button {
		margin-top: 5px;
	}	
	
	.productList .productListInner {
		margin-left: 0px !important;
		margin-right: 0px !important;		
	}
	
	.content-section {
		margin-top: 10px;
		margin-bottom: 10px;
		img {
			display: table;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	/*
	.rocModule-Banner .banner-text .banner-text-table .banner-text-table-tr .banner-text-table-td {
		display: table;
		vertical-align: middle;
		text-align: left;
		width: 100%;
		padding: 20px 10px;
	}
	*/
	
	.rocModule-Banner .banner-text h2.title {
		margin-left: 0px;
		color: #fff;
	}
		
	section.banner-wrap.image .container {
		padding-left: 0px;
		padding-right: 0px;		
	}
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading {
		display: table !important;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	}	
	
	section.banner-wrap .banner-image-table .banner-image-tr .contact-us .form-side .form-side-header {
		 margin-bottom: 15px;
	}	
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading h2.title {
		margin-bottom: 20px;
	}

	section.banner-wrap .banner-image-table .banner-image-tr .contact-us {
		display: table !important;
		padding: 20px 10px;
		width: 100%;
	}	
	
	.scroll .rocModule-miniList.full {
		padding-top: 0px !important;
	}
	
	.interior-row {
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}	
	.interior-row .col-sm-9 {
		display: table !important;
		width: 100% !important;
		&.grey {
			height: 230px;
		}
	}	
	.interior-row .col-sm-3 {
		padding-top: 20px;
		padding-left: 10px;
		display: table !important;
		width: 100% !important;
		&.grey {
			height: 230px;
		}		
	}	
	.interior-row img.full-image {
		left: inherit;
	}
	
	section.banner-wrap .banner-image-table .banner-image-tr .pageHeading p {
		margin: 0;
		font-size: 20px;
		line-height: 25px;
		color: #092162;
	}	
	
}

.bannerImage.contentPageBanner {
	background-position: 50% 50%;
	margin-top: 0;
	text-align: left;
	.container {
		padding: 5% 10px;
		p {
			font-size: 20px;
			color: $color-primary;
			@media(min-width: 768px) {
				font-size: 24px;
			}
		}
	}
}

.contentPageBannerWithFormWrapper {
	.contact-us-form-inner-wrapper {
		margin: -10px -10px 0;
		padding: 10px;
		background: #003466;		
		.contact-us-form {
			text-align: center;
			h3 {
				margin-top: 0;
				color: #f19100;
			}
			p {
				color: #fff;
			}
			label {
				position: absolute; 
				overflow: hidden; 
				clip: rect(0 0 0 0); 
				height: 1px; width: 1px; 
				margin: -1px; padding: 0; border: 0; 
			}
			.sf-fieldWrp {
				margin-bottom: 10px;
			}
			.btn {
				button {
					border: 0;
					background: none;
				}
			}	
		}	
	}
}

@media(min-width: 768px) {
	.contentPageBannerWithFormWrapper {
		position: relative;
		.content-us-form-outer-wrapper {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;	
			.contact-us-form-inner-wrapper {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 33.3333333333%;
				margin: 0;
			}
		}
		.contact-us-form {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: calc(100% - 20px);
		}
	}
}
	

/* New */

/* # Banners */

/* ## Banner Images */
.bannerImage {
	margin: 10px auto;
	text-align: center;
}

.rocModule-Banner {
	&.top {
		.bannerImage {
			margin: 0;
		}		
	}
}


/* ## Custom Banners */
.bannerCustom {
	margin: 10px auto;
}


/* ## Full Width Banner */

.full-banner {
	.full-banner-image {
		position: relative;
		margin-left: auto;
		margin-right: auto;		
		text-align: center;
		img {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.full-banner-text {
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
		overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
		.full-banner-text-table {
			display: table;
			table-layout: fixed;
			margin-left: auto;
			margin-right: auto;
			padding: 15px;
			width: 84%;
			max-width: 88.13em;
			height: 100%;
			.full-banner-text-table-tr {
				display: table-row;
				.full-banner-text-table-td {
					display: table-cell;
					height:100%;
					vertical-align: middle;
					text-align: center;
				}
			}
		}
	}
	h1,
	h2 {
		&.title {
			display: block;
			margin: 0px auto 10px auto;
			padding: 0px 0px 0px 0px;
			font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
			font-size: 24px;
			font-weight: 700;
			line-height: 42px;
			text-transform: uppercase;		
			color: #fff;
			letter-spacing: 1px;
			@media (min-width:980px) and (max-width:1279px) {
				font-size: 18px;
				line-height: 30px;
			}
			@media (min-width:768px) and (max-width:979px) {
				font-size: 16px;
				line-height: 25px;
			}	
			@media (max-width:767px) {
				font-size: 18px;
				line-height: 30px;
				margin-bottom: 15px;
			}			
		}
	}
	p {
		margin: 0px 0px 25px 0px;
		font-family: 'Lato', sans-serif;	
		font-size: 22px;
		font-weight: 300;
		line-height: 42px;		
		color: #fff;
		@media (min-width:980px) and (max-width:1279px) {
			font-size: 16px;
			line-height: 30px;
		}		
		@media (min-width:768px) and (max-width:979px) {
			font-size: 14px;
			line-height: 25px;
		}	
		@media (max-width:767px) {
			font-size: 18px;
			line-height: 25px;
		}		
	}
	span {
		&.button {
			display: inline-block;
			margin-top: 10px;
			@media (min-width:768px) and (max-width:979px) {
				margin-top: 0px;
			}			
			.btn {
				font-size: 12px;
				padding: 10px 40px 10px;
				border-color: #0a4e8c;
			}
		}
	}
    .btn {
        min-width: 150px;
    }
	a {
		&.banner-link {
			display: block;
			&:hover,
			&:focus {
				.btn {
					background-color: #e4701e;
					border-color: #e4701e;
					-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					-o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);		
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);	
					.text {
						color: #fff;
					}
				}
			}
		}
	}		
	.full-banner-vertical-text-rail {
		position: absolute;
		top: 8%;
		right: -30px;
		bottom: 0;
		width: 0;
		height: 100%;
		text-align: center;
		@media (max-width:767px) {
			display: none;
		}			
		@media (max-width:1840px) {
			left: 0;
			right: inherit;
			top: -35px;
			width: 100%;
			height: 30px;
			display: none;
		}		
		.full-banner-vertical-text {	
			position: relative;
			top: 0%;
			overflow: hidden;			
			.full-banner-vertical-text-in {
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);	
				transform-origin: left top 0;	
				display: table;
				min-width: 500px;
				width: 100%;
				overflow: hidden;
				font-family: 'Lato', sans-serif;
				font-size: 22px;
				font-weight: 300;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #dcdfe0;
				@media (max-width:1840px) {
					-webkit-transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					transform: rotate(0deg);	
				}				
			}
		}			
	}	
	
}

/*
@media only screen and (max-width:479px) {	

	.full-banner .full-banner-image {
		overflow: hidden;
	} 
	.full-banner > .full-banner-image > .banner-link {
		display: block;
	}
	.full-banner > .full-banner-image > .banner-link img {
		display: block;
	}
	.full-banner > .full-banner-image > .banner-link img {
		position: relative; 
		left: 50%;
		margin-left: -300px;
		width: 800px !important;
		min-height: 725px;
		height: auto !important;
		max-height: 725px !important;
		max-width: none !important;
	}
	
}
*/

.about-us-homepage-banner {
    background-image:url('https://assetcloud.roccommerce.net//_smcelectric/6/7/3/aboutus-01.jpg');

    h3 {
        width: 80%;
        color: #fff;
        font-size: 1.5em;
        margin: 0 auto;
        padding: 50px 0 0 0;

        @media(max-width: 550px) {
            width: 90%;
            font-size: 1.25em;
            padding: 25px 0 0 0;
        }

        a {
            color: #fff;
        }
    }

    p {
        width: 80%;
        color: #fff;
        font-size: 1.3em;
        line-height: 2em;
        text-align: center;
        margin: 0 auto;
        padding: 25px 0;

        @media(max-width:992px) {
            font-size: 1.1em;
        }

        @media(max-width:767px) {
            font-size: 1em;
            line-height: 1.5em;
        }

        @media(max-width:550px) {
            width: 90%;
            font-size: .9em;
        }

        a {
            color: #fff;
            font-weight: 100;
        }
    }

    .about-us-btn {
        margin: 0 0 65px 0;
    }
}

.homepage-help-succeed-title {
    position: relative;
    top: -50px;

    .title {
        padding: 25px 0 0 0;
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {	

	.full-banner .full-banner-image {
		overflow: hidden
	} 
	.full-banner > .full-banner-image {
		display: block
	}
	.full-banner > .full-banner-image img {
		display: block
	}
	.full-banner > .full-banner-image img {
		position: relative; 
		left: 50%;
		margin-left: -390px;
		width: 800px !important;
		min-height: 725px;
		height: auto !important;
		max-height: 725px !important;
		max-width: none !important;
	}
	
}

span {
	&.banner-overlay-grad {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0a4e8c+0,0a4e8c+100&1+33,0+69 */
		background: -moz-linear-gradient(left,  rgba(10,78,140,1) 0%, rgba(10,78,140,1) 50%, rgba(10,78,140,0) 100%, rgba(10,78,140,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  rgba(10,78,140,1) 0%,rgba(10,78,140,1) 50%,rgba(10,78,140,0) 100%,rgba(10,78,140,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  rgba(10,78,140,1) 0%,rgba(10,78,140,1) 50%,rgba(10,78,140,0) 100%,rgba(10,78,140,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a4e8c', endColorstr='#000a4e8c',GradientType=1 ); /* IE6-9 */
	}
	
	@media(min-width:768px) {

		&.banner-overlay-grad {
			background: -moz-linear-gradient(left,  rgba(10,78,140,1) 0%, rgba(10,78,140,1) 33%, rgba(10,78,140,0) 69%, rgba(10,78,140,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(10,78,140,1) 0%,rgba(10,78,140,1) 33%,rgba(10,78,140,0) 69%,rgba(10,78,140,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(10,78,140,1) 0%,rgba(10,78,140,1) 33%,rgba(10,78,140,0) 69%,rgba(10,78,140,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a4e8c', endColorstr='#000a4e8c',GradientType=1 ); /* IE6-9 */
		}

    }
	
}




/*******************************/
/*   INTERIOR PAGES BANNERS   */
/*****************************/
.interior-banner {
    position: relative;
	.banner-content-container-in {
		.banner-title {
			font-family: SemplicitaPro-Semibold;
		}
		.banner-content {
			font-family: 'SemplicitaPro-SemibdIt';
		}
	}

    .banner-content-container {
        @include interior-bannner-content-container;
        color: $color-text-secondary;
        padding: 0 0 0 10%;
    }

    .banner-title {
        @include title-font-large;
        margin: 0 0 25px 0;
    }

    .banner-line {
        @include title-line;
    }

    .banner-content {
        @include interior-banner-content;
    }
}

[data-roc-action="register-today"] {
	cursor: pointer;
}

// INTERIOR BANNERS - LEVEL 2
.interior-pg-banner,
.programs-offers-wrapper {
    .interior-banner {

        .banner-content-container {
            top: 40%;
			left: 0;
			width: 100%;
			padding-left: 0 !important;
			.banner-content-container-in {
				position: relative;
				display: table;
				width: 100%;
				max-width: 1330px;				
				margin: 0 auto;
				padding: 15px;
			}

//            @media(max-width:992px) {
//                width: 80%;
//                top: 30%;
//                padding: 0 0 0 5%;
//            }
//            @media(max-width:768px) {
//                width: 100%;
//                top: 20%;
//                padding: 0 0 0 30px;
//            }
        }

        .banner-title {
            @media(max-width:992px) {
                @include title-font;
			}
			
			@media(max-width:500px) {
                font-size: 1em;
            }
        }

        .banner-line {
            @include banner-no-line;
        }
    }
}


.training-banner,
.interior-feature-training-banner {
    width: 100%;
    position: relative;
    display: flex;
    margin: 0;
}
.training-banner .interior-banner {
    position: relative;
    display: block;
    overflow: hidden;
	margin: 0 -15px;
}
.interior-feature-training-banner .interior-banner {
    position: relative;
    display: block;
    overflow: hidden;
	margin: 0;
}
.training-banner .interior-banner .banner-image,
.interior-feature-training-banner .interior-banner .banner-image {
    position: relative;
    display: block;
    overflow: hidden;
}
.training-banner .interior-banner .banner-image img {
    position: relative;
    display: block;
	@media(min-width:768px) {
		min-height: 350px;
		max-height: 350px;
		max-width: none!important;
		left: 50%;
		transform: translateX(-50%);
	}	
}
.interior-feature-training-banner .interior-banner .banner-image img {
    position: relative;
    display: block;
	@media(min-width:768px) {
		min-height: 400px;
		max-height: 400px;
		max-width: none!important;
		left: 50%;
		transform: translateX(-50%);
	}
}
.training-banner .interior-banner .interior-banner-in-wrap,
.interior-feature-training-banner .interior-banner .interior-banner-in-wrap {
	@media(min-width:768px) {
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
	@media(max-width:767px) {
		display: block;
		position: relative;
		background-color: #043d79;
		padding: 20px 20px 30px;		
	}
}
.training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in {
    display: table;
    margin: 0 auto;
    width: 100%;
	max-width: 1330px;	
    height: 100%;	
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	@media(min-width:768px) {
		padding: 0 15px;
	}	
}
.interior-feature-training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in {
    display: table;
    margin: 0 auto;
    width: 100%;	
    height: 100%;	
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	@media(min-width:768px) {
		padding: 0 35px;
	}	
}
.training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in .banner-content-container,
.interior-feature-training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in .banner-content-container {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    color: #fff;
	padding: 0 !important;
}
.training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in .banner-content-container .banner-content-container-in,
.interior-feature-training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in .banner-content-container .banner-content-container-in {
	display: table;
	margin: 0;
	width: 50%;
	@media(min-width:768px) {
		padding: 15px 0;
	}	
	@media(max-width:767px) {
		width: 100%;		
	}	
}
.training-banner .interior-banner .interior-banner-in-wrap .interior-banner-in .banner-content-container h2 {
	font-size: 50px;
    font-weight: 600;
    margin: 0 0 25px 0;
	@media(max-width:767px) {
		margin: 0 0 12px;
		padding: 0 0 5px 0;
		font-size: 25px;
		line-height: 25px;		
	}	
}


// INTERIOR FEATURE BANNERS 

.interior-feature-training-banner {
    width: 100%;
    position: relative;

    .banner-content-container {
        @include interior-bannner-content-container;
        width: 40%;
        color: $color-text-secondary;
        padding: 0 0 0 40px;
    }

    .banner-title {
        @include interior-banner-content;
        margin: 0 0 30px 0;
    }

    .banner-line {
        @include title-line;
    }

    .banner-content {
        @include title-font;
        margin: 0 0 20px 0;
		@media (max-width:979px) {
			font-size: 23px;
			line-height: 27px;
		}		
    }
}

.training-event-pg-wrapper {
	.interior-pg-banner {
		.interior-banner {
			.banner-image + .banner-content-container {
				position: absolute;
				background: rgba(10, 78, 140, .8);
				height: 100%;
				top: 0;

				.banner-content-container-in {
					position: absolute;
					bottom: 30px;
					left: 50%;
					transform: translateX(-50%);

					@media(max-width: 500px) {
						bottom: 0;
					}
				}
			}
		}
	}
}
.smc-globalBanner{
	margin: 0;
	font-size: 14px;
}
.smc-globalBanner__inner{
	// background-color: #0a4e8c;
    // color: white;
	padding: 7px 10px;

	a{
		color: inherit;
		font-weight: normal;
	}
}

[data-roc-template="bannerSolutions-Tmp"],
[data-roc-template="bannerFullWidth-Tmp"],
[data-roc-template="bannerDepartment-Tmp"] {
	>h1 {
		visibility: hidden;
	}
}