ul {
    &.news-listing {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        list-style: none;
        max-height: 550px;
        overflow-y: scroll;

        li {
            margin: 30px 0;
            padding: 0;
			border-top: 1px solid #ccc; 
            border-bottom: 1px solid #ccc; 
			@media (max-width:979px) {
				margin: 25px 0;
			}			
            &:first-child {
                margin-top: 0;
				padding-top: 0;
            }
			&:nth-child(even) {
				background-color: rgba(223, 223, 223, 0.3);
			}	
            &:last-child {
                margin-bottom: 0;
            }
            .news-entry {
                display: block;
                margin: 0;
                .media {
                    display: table-cell;
                    padding-left: 0;
                    padding-right: 30px;
                    vertical-align: middle;
					text-align: center;
                    width: 25%;

                    img {
                        max-width: 350px !important;
                    }
					@media (max-width:979px) {
						display: table;
						margin: 25px auto 0;
						padding: 0;
						width: auto;
					}				
                }
                .sfeventMetaInfo  {
                    display: table-cell;
                    padding-left: 0px;
                    padding-right: 15px;
                    vertical-align: top;  
                    width: 80%;
					@media (max-width:979px) {
						display: table;
						margin: 0 auto;
						text-align: center;
						padding: 25px 15px 10px;
						width: auto;
					}						
                    h2 {
                        &.sfeventTitle  {
							display: block;
							margin: 15px 0 5px;
							font-family: Montserrat,Arial,sans-serif;
							font-size: 22px;
							line-height: 30px;
							color: #444;
							font-weight: 300;
							word-wrap: break-word;
							text-transform: uppercase;
							@media (max-width:979px) {
								margin-top: 0;
								font-size: 16px;
								line-height: 20px;									
							}							
							a {
								color: #444;
								font-weight: 300;
								box-decoration-break: clone;
								transition: all .5s ease-in-out;
								position: relative;
								background-image: linear-gradient(to bottom,rgba(68, 68, 68,.3),rgba(68, 68, 68,.3));
								background-size: 0 1px;
								background-position: 0 100%;
								background-repeat: no-repeat;
								letter-spacing: 1px;
								&:hover,
								&:focus {
									background-size: 100% 1px;	
								}								
							}
                        }
                    }
                    .news-date {
                        font-weight: 400;
                        font-style: italic;	
						@media (max-width:979px) {
							font-size: 14px;
							line-height: 18px;									
						}						
                    }
                    .news-location {
                        font-style: italic;
						@media (max-width:979px) {
							font-size: 14px;
							line-height: 18px;									
						}
                        .news-city {
                            display: inline-block
                        }
                        .news-state {
                            display: inline-block;
                        }
                    }
                    .sfeventContent  {
						margin: 10px 0 15px;
						font-family: Lato,sans-serif;
						font-size: 18px;
						font-weight: 100;
						line-height: 25px;
						color: #444;
						@media (max-width:979px) {
							font-size: 14px;
							line-height: 18px;									
						}						
						.read-more {
							display: table;
							box-decoration-break: clone;
							transition: all .5s ease-in-out;
							position: relative;
							background-image: linear-gradient(to bottom,rgba(10,78,140,.3),rgba(10,78,140,.3));
							background-size: 100% 1px;
							background-position: 0 100%;
							background-repeat: no-repeat;
							font-size: 15px;
							font-weight: 400;
							line-height: 22px;
							color: #0a4e8c;
							letter-spacing: 1px;
							margin: 10px 0 0;
							&:hover,
							&:focus {
								background-size: 0 1px;
							}
							@media (max-width:979px) {
								margin-left: auto;
								margin-right: auto;
								font-size: 12px;
								line-height: 16px;								
							}							
						}
                    }
                }
            }
			.event-entry {
				.media {
					&+.sfeventMetaInfo {
						@media (max-width:979px) {
							padding-top: 20px;
						}				
					}					
				}	
			}			
        }
    }
    &.training {
        padding-bottom: 0px;
        border-bottom: 1px solid #ccc;
        li {
            padding-bottom: 30px;
            h2 {
                &.sfeventTitle {
					font-family: Montserrat,Arial,sans-serif;
                    font-size: 1.2em;
                    margin-bottom: 0px !important;
                }
                a {
                    color: #333;
                    pointer-events: none;
                    cursor: default;
                }
            }
            .event-location {
                font-size: 1.2em;
                font-style: normal !important;
            }
            p {
                margin-bottom: 0px;
                &.button {
                    margin-top: 15px;
                }
            }
        }
    }
}