﻿.create-catalog-container {
    font-size: 1em;
    line-height: 1.5em;

    .req-field {
        width: 100%;
        color: $red;
        font-size: 1em;
        font-weight: 600;
        text-align: right;
        padding: 0 0 15px 0;
    }

    .formee {
        margin: 0 0 15px 0;

        label {
            font-size: 1.15em;
        }

        textarea {
            height: 150px;
        }

        &.submit-btn {
            margin: 0;
            text-align: right;

            .btn {
                padding: 15px 50px;
            }
        }
    }
}