﻿.headerMain {
    background-color: $nav-bg;
}

#sticky-nav {
    padding-left: 0;
    padding-right: 0;
	@media (min-width:768px) {
		max-width:100%!important;
		transition:none!important;
	}
}

.header-main-table {
    width: 100%;
    max-width: 1330px;
    background-color: $nav-bg;
    margin: 0 auto;
    z-index: 11;
}

.header-main-logo {
	display:inline-block;
    padding: 21px 0;
    vertical-align: top;
}

.navbarWrapper {
    display: inline-block;
    /*background-color: $nav-bg;*/
}

.navbar {
    border: none;

    .container {
        padding: 0;
    }
}

.headerLinks {
    display: inline-block;
    width: 100px;
    vertical-align: top;

    @media (max-width:767px){ 
        display: none;
    }

    .icon {
		display: block;
    }
}

.headerLinks {
    text-align: right;
	color: $color-text-secondary;
	font-size: 0.813em;
}

.headerLinks-text {
	display: inline-block;
	
	> p,
	> a {
		display: inline-block;
		margin: 0;
		padding: 10px;
	}
	
	> a,
	> * > a {
		color: $color-text-secondary;
		text-decoration: none;
		font-weight: 700;
	}
}

.headerMain {
	
	.textBanner {
		padding-top:5px;
	}
}

/* # account flyout nav */

.accountNav {
	display: table;
	position: relative;
	text-align: center;
	width: 100%;
	min-width: 122px;
	height: 100px;
	border-right: 1px solid $color-border;

	@media (min-width:980px) and (max-width:1279px) {
		min-width: 70px;
	}
	a.account {
		display: table-cell;
		vertical-align: middle;
		padding: 0;
		font-size: 16px;
		font-weight: 600;
		color: $color-text-primary;
		text-decoration: none;
		text-transform: none;
		-wekbit-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
		.my-account-text {
			position: relative;
			&:after {
				position: absolute;
				content: '';
				bottom: -19px;
				left: 0;
				width: 0;
				height: 3px;
				background-color: #e26f1e;
				transform-origin: left;
				-moz-transition: width .45s ease;
				-o-transition: width .45s ease;
				-webkit-transition: width .45s ease;
				transition: width .45s ease;
				z-index: 1;
				@media (max-width:767px) {
					display: none;
				}				
			}
		}

		&:focus,
		&:hover,
		[aria-expanded="true"] {
			background-color: $nav-bg-hover;
			.my-account-text {
				&:after {
					width: 100%;
				}
			}
		}
		
		&.collapsed {
			background: none;
			&:focus,
			&:hover {
				background-color: $nav-bg-hover;
				.my-account-text {
					&:after {
						width: 100%;
					}
				}				
			}			
		}
	}
	
	.rocMenu {
		position: absolute;
		top: 100px;
		right: 0;
		color: $color-text-secondary;
		background-color: $nav-bg;
		border: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		-webkit-box-shadow: 0px 15px 15px 0px rgba(0,0,0,.36);
		-moz-box-shadow: 0px 15px 15px 0px rgba(0,0,0,.36);
		box-shadow: 0px 15px 15px 0px rgba(0,0,0,.36);	
		z-index: 91;
		width: 280px;
		font-size: 16px;
		font-weight: 300;		
		text-align: center;
		color: $color-text-primary;
	
		&-content {
			padding: 20px 15px;
			@media (max-width:767px) {
				padding-left: 31px;
				padding-right: 31px;
			}
		}
	}
	
	.btn {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		font-size: 12px;
		font-weight: 500;
		line-height: 15px;
		letter-spacing: 2px;
		padding: 10px 15px;
		@media (max-width:767px){
			width: 100%;
		}
	}

    .btn.disabled {
        background-color: $nav-disabled;
        border-color: $nav-disabled;
        cursor: default;
    }
	
	.signIn {
		padding: 0 0 7px 0;
		
		.icon {
			display: none;
		}
		
		p {
			margin: 7px 0 0 0;
		}
	}
	
	.linkList {
		margin: 0 0 10px 0;
		padding: 7px 0;
		border-top: 1px solid $color-border;
		
		&:last-child {
			padding-bottom: 0;
		}
		
		&:first-child {
			padding-top: 0;
			border-top: none;
		}
		
		a {
			color: $color-text-primary;
		}
	}
}



.headerLinks-text {
	.icon {
		display: none;
		fill: $color-primary;
		width: 20px;
		height: 25px;	
		margin: 4px auto 7px;
	}	
}

/* Custom Header */

.siteHeader {
	&.layout-custom {
		//font-family: 'Montserrat', sans-serif;
		.headerMain {
			padding: 0px;
			//background-color: #084985;
			.container {
				&.container-fluid {
					&.no-padding {
						padding-left: 0px;
						padding-right: 0px;
						.header-main-table {
							display: table;
							width: 100%;
							max-width: 1300px;
							margin: 0 auto;
							position: relative;
							z-index: 91;
							@media (max-width:767px){
								background-color: #fff;
							}							
							.header-main-logo {
								display: table-cell;
								vertical-align: middle;
								padding: 0;
								@media (max-width:767px) {
									padding-left: 25px;
								}
								@media(min-width:768px) and (max-width:979px) {
									padding-left: 15px;
								}								
								img {
									width: 180px !important;
									max-width: 180px !important;
									height: 58px !important;
									@media (max-width:767px) {
										width: 140px !important;
										max-width: 140px !important;
										height: 46px !important;
									}									
								}
							}
							.navbarWrapper {
								display: table-cell;
								vertical-align: middle;
								background-color: transparent;
								height: 100px;
								@media (max-width:767px){
									height: 68px;
									//padding-right: 30px;
								}								
								.navbar {
									background-color: transparent;
									border-top: 0;
									border-bottom: 0;				
									min-height: 100px;		
									@media (max-width:767px){
										min-height: 68px;
									}									
									.container {
										min-height: 100px;
										padding-left: 0px;
										padding-right: 0px;	
										@media (max-width:767px){
											min-height: 68px;
										}										
										.navbar-content {
											float: right;
											height: 100px;
											@media (max-width:767px){
												height: 68px;
											}											
										}
									}
								}
							}
							/*.nav-mobile-headerLinks {
								display: table-cell;
								vertical-align: middle;			
								width: 100px;			
								.headerLinks {		
									display: table;
									width: 100%;
								}
							}*/
							.headerLinks {
								display: table-cell;
								vertical-align: middle;			
								width: 100px;
								//background-color: #e4701e;		
								@media (max-width:767px){
									display: none;
								}
								@include md {
									min-width: 100px;								   
								}
								.headerLinks-text {
									.account {
										span {
											&+span {
												.icon {
													display: none;
												}
										}
										}
									}
								}
								.navbar-group {
									background-color: #dfdfdf;
								}
								.icon {		
									display: table;
									fill: #0a4e8c;
									width: 20px;
									height: 25px;	
									margin: 4px auto 7px;
								}	
								.rocMenu-content {
									.icon {
										display: none;
									}
								}
							}
							.cm-js-menu-active {
								.headerLinks {
									@media (max-width:979px){
										display: block !important;
										width: 100% !important;
									}																	
								}
							}
						}
					}
				}
			}
		}
	}
}

.headerLink-signin {
	position: relative;

    .icon {
        display: inline-block;
        vertical-align: middle;
        fill: $color-text-secondary;
        width: 9px;
        height: 10px;
        margin: 0 0 0 3px;
    }

    &:hover, &:focus {
        .icon {
            fill: $color-primary;
        }
    }
}

.sign-inline {
	display: inline-block;
	vertical-align: middle;
}

.header-search {
	display: table;
	width: 100%;
	padding: 20px 6px;
	background-color: $search-bg;
	border-top: 1px solid $color-border;

	@media (max-width:767px) {
		padding: 8px 15px;
	}				
	.formee {
		display: table;
		width: 100%;
		max-width: 1300px;
		position: relative;
		margin: 0 auto;
	}

	.formee-group {
						&.siteSearch {
            				width: 100%;
							position: relative;
							width: 100%;
						}
						.formee-control {
							display: table;
							width: 100%;
						}		
						input[type="text"] {
							-webkit-border-radius: 0;
							border-radius: 0;	
							border: 1px solid #ccc;
							height: 49px;
							padding-left: 18px;
							padding-right: 50px;
							font-style: normal;
							@media (max-width:767px) {
								height: 41px;
								padding-left: 10px;
								padding-right: 35px;
							}								
						} 
						input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
							@media (max-width:767px) {
								color: #fff;
							}							
						}
						input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
							@media (max-width:767px) {
								color: #fff;
							}	
						}
						input::-moz-placeholder { /* Mozilla Firefox 19+ */
							@media (max-width:767px) {
								color: #fff;
							}	
						}
						input:-ms-input-placeholder { /* Internet Explorer 10-11 */
							@media (max-width:767px) {
								color: #fff;
							}	
						}		
						input.visable::-webkit-input-placeholder { /* WebKit, Blink, Edge */
							@media (max-width:767px) {
								color: #6b6b6b;
								opacity: 1 !important;
								font-size: 13px;
							}								
						}
						input.visable:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
							@media (max-width:767px) {
								color: #6b6b6b;
								opacity: 1 !important;
								font-size: 13px;
							}	
						}
						input.visable::-moz-placeholder { /* Mozilla Firefox 19+ */
							@media (max-width:767px) {
								color: #6b6b6b;
								opacity: 1 !important;
								font-size: 13px;
							}	
						}
						input.visable:-ms-input-placeholder { /* Internet Explorer 10-11 */
							@media (max-width:767px) {
								color: #6b6b6b;
								opacity: 1 !important;
								font-size: 13px;
							}	
						}						
						.icon {		
							display: inline-block;
							width: 24px;
							height: 24px;
							fill: $color-primary;
							position: absolute;
							display: inline-block;
							width: auto;
							top: 0;
							left: 0;									
							fill: #0a4e8c;
							width: 24px;
							height: 24px;
							z-index: 9;
						}						
						.formee-group-btn {
							position: absolute;
							display: inline-block;
							width: auto;
							top: 12px;
							right: 19px;
							z-index: 9;	
							@media (max-width:767px) {
								top: 8px;
								right: 10px;
							}
							.btn {
								font-size: 12px;
								font-weight: 500;
								letter-spacing: 2px;
								font-style: normal;
								color: #bdd7ef;
								text-transform: uppercase;
								width: 24px;
								height: 24px;
								background-color: transparent;
								border: none;								
								border-radius: 0;
								margin-left: 0px;	
								padding: 0;
								box-shadow: 0 1px 2px rgba(0,0,0,0);
								transform-origin: 0 0;
								-webkit-transition: all .6s cubic-bezier(.165, .84, .44, 1);
								transition: all .6s cubic-bezier(.165, .84, .44, 1);
								&:hover,
								&:focus {
									color: #0a4e8c;
									background-color: #fff;
									border-color: #084174;
									-webkit-transform: scale(1.09, 1.09);
									transform: scale(1.09, 1.09);
								}								
							}
						}
					}

	#sticky-nav {
		&.affix {
			.header-main-table {
				background-color: #fff;
			}
		}
	}
}

@media (min-width:980px) and (max-width:1280px) {
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .header-main-logo {
		padding: 0px 19px 0px;
	}	
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .navbarWrapper,
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .navbarWrapper .navbar,
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .navbarWrapper .navbar .container,
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .navbarWrapper .navbar .container .navbar-content {
		height: 90px;
		min-height: 90px;
	}
	.accountNav {
		width: 60px;
		height: 90px;
	}
	.accountNav .rocMenu {
		top: 90px;
	}
	.accountNav a.account {
		padding-left: 15px;
		padding-right: 15px;
		font-size: 10px;
	}
//	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .headerLinks a .icon {
//		width: 24px;
//		height: 31px;
//	}
}

@media (min-width:980px) and (max-width:1280px) {
	.accountNav a.account {
		padding-left: 8px;
		padding-right: 8px;
	}
}

@media (max-width:767px) {
	
	.accountNav {
		width: 100%;
		height: inherit;
		text-align: left;
	}	

	.accountNav .rocMenu {
		position: relative;
		top: inherit;
		right: inherit;
		color: #656565;
		background-color: #959ca1;
		border: 0;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		-webkit-box-shadow: 0 0 0 0 #8a9197;
		box-shadow: 0 0 0 0 #8a9197;
		z-index: 2;
		width: 100%;
		font-size: 16px;
		font-weight: 300;
		text-align: left;
		color: #fff;

		label {
			color:#fff;
		}
	}		
	
	.headerLinks .headerLink-signin .icon {
		display: inline-block;
		vertical-align: middle;
		fill: #fff;
		width: 9px;
		height: 10px;
		margin: 0 0 0 3px;
	}		
	
}

@media (min-width:980px) and (max-width:1024px) {
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .headerLinks {
		width: 60px;
	}	
}

@media (min-width:1025px) and (max-width:1280px) {
	.siteHeader.layout-custom .headerMain .container.container-fluid.no-padding .header-main-table .headerLinks {
		min-width: 80px;
	}
}

/*
.cm-js-menu-active {
	.siteHeader {
		&.layout-custom {
			.headerMain {
				.container {
					&.container-fluid {
						&.no-padding {
							.header-main-table {
								.navbarWrapper {
									@media (max-width:767px) {
										padding-right: 15px;
									}									
								}
							}
						}
					}
				}			
			}	
		}
	}
}
*/

@media (max-width:979px) {
	.cm-js-menu-active {
		body {
			padding-top: 0 !important;
			.roc-navbar-impersonation {
				display: none !important;
			}
			.navbar {
				.navbar-mainNav {
					z-index: 9999;
				}
			}
			.main-nav {
				padding-right: 0 !important;
				width: 83% !important;
				.nav-mobile-account {
					.headerLinks {
							display: block !important;
							width: 100% !important;
						}		
						a {
							&.account {
								span {
									+span {
										text-transform: uppercase;
										color: #fff;
									}
								}
							}
						}
					}
				}			
			ul {
				&.cm-menu {
					>li {
						&.li-nav {
							&.visible-xs {
								&:first-child {
									display: none !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

.my-account-icon-wrapper {
	display: block;
}

.my-account-text {
	white-space: nowrap;
}