﻿.my-account-container {

    .req-field {
        color: $red;
        font-size: 1em;
        font-weight: 600;
        margin: 0 0 0 5px;
    }

    .form-wrapper {
        padding: 25px;
        border: 1px solid $color-border;

        .req-field {
            width: 100%;
            text-align: right;
            padding: 0 0 15px 0;
        }

        .formee {
            margin: 0 0 15px 0;

            label {
                font-size: 1.15em;
            }

            textarea {
                height: 150px;
            }

            &.submit-btn {
                margin: 0;
                text-align: right;

                .btn {
                    padding: 15px 50px;
                }
            }
        }

        .form-list-wrapper {
            margin: 0 0 15px 0;
        }

        .btn-left {
            width: 49.5%;
            display: inline-block;
        }

        .btn-right {
            width: 50%;
            display: inline-block;
            text-align: right;
        }
    }
}