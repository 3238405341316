.arrow-icon {
	position: relative;
	top: -1px;
	
	&:not(:last-child) {
		margin-right: 5px;
	}
	
	&.arrow-up {
		@include arrow(10px, 8px, up, $color-text-primary);	
	}
		
	&.arrow-down {
		@include arrow(10px, 8px, down, $color-text-primary);	
	}
	
	&.arrow-left {
		@include arrow(8px, 10px, left, $color-text-primary);	
	}
	
	&.arrow-right {
		@include arrow(8px, 10px, right, $color-text-primary);	
	}
	
	&.arrow-action {
		&.arrow-up {
			@include arrow(10px, 8px, up, $color-action);	
		}

		&.arrow-down {
			@include arrow(10px, 8px, down, $color-action);	
		}
		
		&.arrow-left {
			@include arrow(8px, 10px, left, $color-action);	
		}
		
		&.arrow-right {
			@include arrow(8px, 10px, right, $color-action);	
		}
	}
}