//
// ## Panels
// bootstrap based with modifications


// Base class
/*.panel {
  margin-bottom: $line-height;
  background-color: $panel-bg;
}*/

// Panel contents
.panel-body {
  padding: $panel-body-padding;
  @include clearfix;
}

// Optional heading
.panel-heading {
    /*display: block;
    width: 100%;
    text-align: left;
    padding: $panel-heading-padding;*/

    // if heading is not followed by content remove bottom border
    &:last-child {
        border-bottom: 0;
    }
    
    > .dropdown .dropdown-toggle {
        color: inherit;
    }
}

// Within heading, allow for title group with optional addons
.panel-heading-group {
    display: table;
    width: 100%;
    
    > .panel-title {
        display: table-cell;
        vertical-align: middle;
    }
    
    > .panel-addon {
        display: table-cell;
        text-align: right;
        vertical-align: middle;
    }
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title {
    /*margin-top: 0;
    margin-bottom: 0;
    font-size: ceil((1em * 1.125));
    font-weight: bold;
    color: inherit;

    > a,
    > small,
    > .small,
    > small > a,
    > .small > a {
        color: inherit;
    }*/
}



// Optional footer (stays gray in every modifier class)
.panel-footer {
  padding: $panel-footer-padding;
  background-color: $panel-footer-bg;
  border-top: 1px solid $panel-inner-border;
}


// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.

.panel {
    // prevent bottom margin on last nested panel
    /*.panel:last-child {
        margin-bottom: 0;
    }*/
    

    > .list-group,
    > .panel-collapse > .list-group {
        margin-bottom: 0;

        .list-group-item {
            border-width: 1px 0;
            border-radius: 0;
        }

        // Add border top radius for first one
        &:first-child {
            .list-group-item:first-child {
                border-top: 0;
            }
        }
        
        // Add border bottom radius for last one
        &:last-child {
            .list-group-item:last-child {
                border-bottom: 0;
            }
        }
    }
}

// Collapse space between when there's no additional content.
.panel-heading + .list-group {
  .list-group-item:first-child {
    border-top-width: 0;
  }
}
.list-group + .panel-footer {
  border-top-width: 0;
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .table,
  > .table-responsive > .table,
  > .panel-collapse > .table {
    margin-bottom: 0;

    caption {
      padding-left: $panel-body-padding;
      padding-right: $panel-body-padding;
    }
  }

  > .panel-body + .table,
  > .panel-body + .table-responsive,
  > .table + .panel-body,
  > .table-responsive + .panel-body {
    border-top: 1px solid $table-border-color;
  }
  > .table > tbody:first-child > tr:first-child th,
  > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
  }
  > .table-bordered,
  > .table-responsive > .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }
    > thead,
    > tbody {
      > tr:first-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
    > tbody,
    > tfoot {
      > tr:last-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
  }
  > .table-responsive {
    border: 0;
    margin-bottom: 0;
  }
}


// Collapsable panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  margin: 0;
    margin-bottom: $line-height;
  padding: 0;
  list-style-type: none;

    // Tighten up margin so it's only between panels
    > .panel {
        margin-bottom: 0;

        + .panel {
            margin-top: -1px;
        }

      .panel-heading {
        border-bottom: 0;

        + .panel-collapse > .panel-body,
        + .panel-collapse > .list-group {
          border-top: 1px solid $panel-inner-border;
        }
      }

      .panel-footer {
        border-top: 0;
        + .panel-collapse .panel-body {
          border-bottom: 1px solid $panel-inner-border;
        }
      }
    }
}

.panel > .panel-group {
    margin: -1px;
}


// Contextual variations
.panel-default {
  @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
}
.panel-primary {
  @include panel-variant($panel-primary-border, $panel-primary-text, $panel-primary-heading-bg, $panel-primary-border);
}
.panel-secondary {
  @include panel-variant($panel-secondary-border, $panel-secondary-text, $panel-secondary-heading-bg, $panel-secondary-border);
}
.panel-action {
  @include panel-variant($panel-action-border, $panel-action-text, $panel-action-heading-bg, $panel-action-border);
}
.panel-success {
  @include panel-variant($panel-success-border, $panel-success-text, $panel-success-heading-bg, $panel-success-border);
}
.panel-info {
  @include panel-variant($panel-info-border, $panel-info-text, $panel-info-heading-bg, $panel-info-border);
}
.panel-warning {
  @include panel-variant($panel-warning-border, $panel-warning-text, $panel-warning-heading-bg, $panel-warning-border);
}
.panel-danger {
  @include panel-variant($panel-danger-border, $panel-danger-text, $panel-danger-heading-bg, $panel-danger-border);
}

