﻿// PRODUCT LIST
@mixin product-item-container {
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
    @include border-top-default;
}

@mixin product-item {
    display: flex;
    font-size: 1em;
    line-height: 1.5em;
}

@mixin product-item-sections {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

@mixin product-item-image-section {
    width: 24%;
}

@mixin product-item-content-section {
    width: 50%;
    padding: 0 25px;
    vertical-align: top;
}

@mixin product-item-restriction {
    color: $red;
    margin: 15px 0 0 0;
}

@mixin product-item-action-section {
    width: 25%;
    vertical-align: top;
}

@mixin product-item-sku {
    color: $text-muted;
    font-weight: 500;
    padding: 0 0 7px 0;
}

@mixin product-item-title {
    font-weight: 700;
}

@mixin product-item-delivery {
    margin: 40px 0 0 0;
}

@mixin product-item-delivery-span {
    font-weight: 600;
}

@mixin product-item-delivery-action {
    margin: 0 0 15px 0;
}

@mixin product-item-price {
    width: 100%;
    font-size: 1.75em;
    font-weight: 600;
    text-align: right;
    padding: 0 0 15px 0;
}

@mixin product-item-price-span {
    font-size: .6em;
    font-weight: 500;
}

@mixin product-item-price-prev {
    color: $text-muted;
    font-size: .8em;
    font-weight: 500;
    text-decoration: line-through;
    margin: 0 0 10px 0;
}

@mixin product-item-price-curr {
    display: inline-block;
}

@mixin product-item-qty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.25em;
    font-weight: 500;
    padding: 0 0 15px 0;
}

@mixin product-item-qty-increment {
    display: inline-flex;
    width: 70%;
    margin: 0 0 0 15px;
}

@mixin product-item-qty-btns {
    display: inline-block;
    width: 33.333%;
    font-size: 1.25em;
    font-weight: 300;
    text-align: center;
    padding: 12px 0;
    @include border-default;
}

@mixin product-item-qty-amount {
    display: inline-block;
    width: 33.333%;
    height: 50px;
    text-align: center;
    margin: 0 5px;
    @include border-default;
}

@mixin product-item-qty-spinner {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

@mixin product-action-item {
    width: 100%;
    margin: 0 0 15px 0;
}