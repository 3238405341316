/* ## Main Navigation Theme ## */

.navbarWrapper {
	height: 54px;
    margin-bottom: 15px;
	background-color: $nav-bg;
	font-weight: 400;
	.container {
		@media (max-width:979px){
			min-width: 100%;
		}
		.navbar-siteCart {
			@media (max-width:767px){
				display: none;
			}			
		}
	}
}

.navbar {
	position: relative;

	&-content {
		display: table;
	}
	.navbar-group {
		display: table-cell;
		vertical-align: middle;
		padding: 0;
	}
	.navbar-mainNav {
		padding-right: 0;
		border-right: 1px solid $color-border;

		@media (max-width:767px) {
			border-right: none;
		}
	}

	.navbar-siteCart {
		min-width: 110px;
		text-align: center;
		background-color: $nav-bg;
		border-right: 1px solid $color-border;

		.icon {		
			fill: $color-primary;
			width: 34px;
			height: 25px;	
			display: table;
			margin: 0 auto 11px;
		}
	}

	#siteCart {
		display: table;
		width: 100%;
		min-width: 122px;
		height: 100px;

		@media (min-width:980px) and (max-width:1279px) {
			min-width: 70px;
		}		
	}

	.cartLink {
		display: table-cell;
		vertical-align: middle;
		padding-left: 0px;
		color: $link-text;
		font-size: 16px;
		font-weight: 500;
		text-decoration: none;
		text-shadow: 1px 0 0 rgba(228, 112, 30, 0.7);
		text-align: center;
		line-height: 1;
		white-space: nowrap;
		text-transform: none;
		-wekbit-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;

		.site-cart-wrap {
			position: relative;

			/* IE10+ specific styles go here */
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				display: block;
			}	
            		
			&:after {
				position: absolute;
				content: '';
				bottom: -19px;
				left: 0;
				width: 0;
				height: 3px;
				background-color: darken($color-button-secondary, 20%);
				transform-origin: left;
				-moz-transition: width .45s ease;
				-o-transition: width .45s ease;
				-webkit-transition: width .45s ease;
				transition: width .45s ease;
				z-index: 1;
			}
		}		
		&:hover,
		&:focus {
			background-color: $nav-bg-hover;

			.site-cart-wrap {
				&:after {
					width: 100%;
				}				
			}
		}
		.site-cart-txt {
			font-weight: 500;
			color: $color-text-primary;
		}
		#spanCartTotal {
			text-shadow: 1px 0 0 rgba(228, 112, 30, 0.9);
		}
		
		.icon-cart {
			display: block;
			color: $color-process;
			font-size: 1.286em;
		}
	}
}

.navbar-logo {
    display: none;
	position: absolute;
	left: -40px;
	top: 0;
	bottom: 0;
	width: 34px;
	height: 46px;
	padding-top: 46px;
	overflow: hidden;
	-wekbit-transition: padding-top 0.3s ease-in-out;
	transition:padding-top 0.3s ease-in-out;
	
    /* ## 16px baseline (768px+) */
    @media only screen and (min-width:1060px){
        display: block;
    }
    
	> a {
		display: inline-block;
		width: 34px;
		height: 34px;
		background: url("../../images/dev/placeholders/roc-icon-small.png") no-repeat 0 2px;
		-wekbit-transition: top 0.3s ease-in-out;
		transition:top 0.3s ease-in-out;
	}
}

body {
	&.roc-toolbar-enabled {
		&.roc-toolbar-scroll {
			.roc-tools {
				&.roc-navbar {
					&.roc-navbar-impersonation {
						position: absolute !important;
						visibility: hidden !important;
						left: 0;
						top: -200px;
						height: 0;
					}
				}
			}			
		}
		&:not(.roc-toolbar-scroll) {
			padding-top: 0 !important;									
			.roc-tools {
				&.roc-navbar {
					&.roc-navbar-impersonation {
						position: relative !important;
					}
				}
			}
			#sticky-nav {
				&.affix {
					position: relative !important;
					-webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0) !important;
					box-shadow: 0 2px 3px 0 rgba(0,0,0,0) !important;					
				}
			}
		}
	}
}

#sticky-nav {
    &.affix {
        position: fixed;
        width: 100%;
        top: 0;
		background-color: $nav-bg;
        -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
        box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
		z-index: 99;
		
		.navbar-logo {
			padding-top: 6px;
		}
    }
}

#events-search-button {
	padding-left: 15px;
	padding-right: 15px;
}

#sticky-nav.affix-top,
#sticky-nav.affix {
	top: 0 !important;
}

@media(max-width:767px) {
	.navbarWrapper {
		height: auto;
	}
	
	#sticky-nav {
//		position: fixed;
//		top: 0;
//		width: 100%;
//		z-index: 10;

		&.affix {
			top: 0 !important;
			-webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
			box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);			
		}
	}
	
//	body {
//		padding-top: 68px;
//	}
}

/*================================================================
                 CLICK MENU STYLES
================================================================*/

/*
    THESE STYLES will probably not be needed and are adjusted in your main styles
    - * for example is most efficient when not a child of a class
       -- hopefully you already are using the box sizing globally
    - you may not need text-decoration etc. depending on your global anchors
*/
.cm-menu a, .cm-menu a:visited { 
	color: $color-text-primary; 
	text-shadow: 1px 0 0 rgba(51, 51, 51, 0.7); 
	text-decoration: none; 
	-wekbit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}


.cm-menu li li a, .cm-menu li li a:visited { color:#444; }
.cm-menu *, .cm-menu *:after, .cm-menu *:before {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.cm-menu:focus { outline:3px solid blue;  }
.cm-menu, .cm-menu ul { margin:0; padding:0; list-style:none; }

.toggle-menu + .cm-menu, .cm-menu ul {
    max-height:0;
    opacity:0;
    overflow:hidden;
    -wekbit-transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
            transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.cm-menu { position:relative; }
.cm-menu li a { display:block; padding:10px; position:relative;}

/*.cm-menu li { border-top:1px solid #ccc; }*/
.cm-menu li:first-child { border-top:0; }
.cm-menu li { position:relative; }

@media (min-width:980px) {

	.cm-menu > li li .has-sub:after {
		content:" ";
		display:inline-block;
		position:absolute; top:50%; right:10px;
		margin:0 0 0 5px;
		vertical-align:middle;
		border:5px solid transparent;
		/* Extra is for anti-aliasing issues in firefox and others */
		border:5px solid rgba(0,0,0,0);
		border-top-color:#444;
		-wekbit-transition: all 0.4s ease-in-out;
				transition: all 0.4s ease-in-out;
		-webkit-transform: scale(0.98) translateY(-50%) rotate(-90deg);
			-ms-transform: scale(0.98) translateY(-50%) rotate(-90deg);
				transform: scale(0.98) translateY(-50%) rotate(-90deg);
	}
	
}

.cm-menu > li.opened li .has-sub:hover:after,
.cm-menu > li.opened li.opened .has-sub:after,
.cm-menu > li.opened li .has-sub:focus:after {
	border-top-color: #e26f1e;
}

.cm-menu > .opened > a,
.cm-menu > li > a:hover,
.cm-menu > li > a:focus,
.cm-menu > li > div > a:hover,
.cm-menu > li > div > a:focus { 
	color: $color-text-primary; 
	background-color: $nav-bg-hover;
}

.cm-menu .expander-wrap .has-sub:hover > span,
.cm-menu .expander-wrap .has-sub:focus > span { color: $color-text-primary; background: $nav-bg-hover; }

.cm-menu ul .opened > a,
.cm-menu ul a:hover, .cm-menu ul li a:focus { color: $link-text; }

.cm-menu ul .opened > .has-sub:after {
    border-top-color:#444;
    -webkit-transform: scale(0.98) translateY(-50%) rotate(-270deg);
        -ms-transform: scale(0.98) translateY(-50%) rotate(-270deg);
            transform: scale(0.98) translateY(-50%) rotate(-270deg);	
}

.cm-js-active .cm-menu { max-height:80em; opacity:1; }
.cm-menu .opened > ul { max-height:80em; opacity:1; }

/* ================= SEPARATE EXPANDERS OPTION IS ENABLED ================= */


.cm-menu .expander-wrap { display:table; width:100%; }
.cm-menu .expander-wrap a { display:table-cell; }
.cm-menu .expander-wrap .has-sub { position:relative; width:30px; }
.cm-menu .expander-wrap .has-sub:after { display:none; }
.cm-menu .expander-wrap .has-sub > span {
    display:block;
    position:absolute; top:0; right:0;
    width:100%; height:100%;
    background:#aaa;
}

.cm-menu .expander-wrap .has-sub > span:before,
.cm-menu .expander-wrap .has-sub > span:after {
	content:" ";
	position:absolute;
	top:50%;
	background-color:currentColor;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.cm-menu .expander-wrap .has-sub > span:before {
	right:21px;
	margin-top:-7px;
	width:2px; height:14px;
}

.cm-menu .expander-wrap .has-sub > span:after {
	right:15px;
	margin-top:-1px;
	width:14px; height:2px;
}

.cm-menu .opened > .expander-wrap .has-sub > span:before {
	margin-top:0;
	height:0;
}


@media (max-width:979px) {

	.cm-menu .has-sub {
		cursor: pointer;
	}
	
	.cm-menu > li > .has-sub:before,
	.cm-menu > li > .has-sub:after {
		content:"";
		position:absolute;
		top:50%;
		background-color:currentColor;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;	
	}
	.cm-menu > li > .has-sub:before {
		right:37px;
		margin-top:-7px;
		width:2px; height:14px;
	}
	.cm-menu > li > .has-sub:after {
		right:31px;
		margin-top:-1px;
		width:14px; height:2px;
	}	
	.cm-menu > .opened > .has-sub:before {
		margin-top:0;
		height:0;
	}
	
	.cm-menu > li li > .has-sub:before,
	.cm-menu > li li > .has-sub:after {
		content:"";
		position:absolute;
		top:50%;
		background-color:currentColor;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;	
	}
	.cm-menu > li li > .has-sub:before {
		right:37px;
		margin-top:-7px;
		width:2px; height:14px;
	}
	.cm-menu > li li > .has-sub:after {
		right:31px;
		margin-top:-1px;
		width:14px; height:2px;
	}	
	.cm-menu > li .opened > .has-sub:before {
		margin-top:0;
		height:0;
	}	
	.cm-menu > li .opened > .has-sub:after {
		-webkit-transform: scale(0.98) translateY(-50%) rotate(0deg);
		-ms-transform: scale(0.98) translateY(-50%) rotate(0deg);
		transform: scale(0.98) translateY(-50%) rotate(0deg);		
	}		
	
}

/* ================= HOVER FALLBACK IF JAVASCRIPT IS DISABLED ================= */

.no-js .toggle-menu + .cm-menu { opacity:1; max-height:80em; }
.no-js .cm-menu > li:hover > a { background:#333; }
.no-js .cm-menu ul ul li:hover > a { background:#444; }

.no-js .cm-menu li:hover > ul { display:block; max-height:80em; opacity:1; width:100%; }
.no-js .cm-menu ul { display:none; overflow:visible; }


/* ================= MENU OPEN FUNCTIONALITY ADDED ON SETUP ================= */

@media (min-width:980px){
    .toggle-menu + .cm-menu { max-height:80em; opacity:1; overflow:visible; }
    .cm-menu ul { top:100%; overflow:hidden; }
    .cm-menu .opened > ul { max-height:80em; opacity:1; padding:15px 0;}

    .cm-menu .animated > ul { overflow:visible; z-index:10; }
    .cm-menu .animating > ul { z-index:-1; }
}


/*================================================================
                 CLICK MENU MENU TYPES
================================================================*/

/*  NAV BAR STYLE TYPE
    ================================================================*/

@media (min-width:980px){
    .nav-bar li { border-top:0; }

    .nav-bar > ul { display: table; width: 100%; }
    .nav-bar > ul > li { display: table-cell; vertical-align:middle; }
    .nav-bar > ul > li > a { 
		display: table;
		width: 100%; 
		height: 100px;
		padding: 10px 16px;
		cursor: pointer; 
		font-family: 'SemplicitaPro-Medium';
		
	}
	
	
    .nav-bar > ul > li > a > span { display:table-cell; vertical-align:middle; font-size: 18px; text-align:center; text-transform: none; font-weight: 400; letter-spacing: .6px;
		-moz-transition: all 0.25s ease;
		-o-transition: all 0.25s ease;
		-webkit-transition: all 0.25s ease;
		transition:color 0.25s ease;	
		position: relative;
	}
    
	.nav-bar > ul > li > a > span:before {
		position: absolute;
		content: '';
		bottom: -7px;
		left: 0;
		width: 0;
		height: 3px;
		background-color: #e26f1e;
		transform-origin:left;
		-moz-transition: width 0.45s ease;
		-o-transition: width 0.45s ease;
		-webkit-transition: width 0.45s ease;		
		transition: width 0.45s ease;
		z-index: 1;
	}
	.nav-bar > ul > li.opened > a > span:before,
	.nav-bar > ul > li > a > span:focus:before,
	.nav-bar > ul > li > a > span:hover:before {
		width: 100%;
	}		
	
	
	.nav-bar {
		> ul {
			> li {
				> a {
					&.has-sub {
						> span {
							&.has-sub-anchor {
								position: relative;
								display: table-cell;
								vertical-align: middle;
								width: 18px;
								&:before {
									display: none;
								}
								&:after {
									content: "\e638";
									font-family: ROC-Icons;									
									position: absolute;
									top: 26%;
									left: 3px;
									display: block;
									font-size: 18px;
									font-style: normal;
									-wekbit-transition: all .4s ease-in-out;
									transition: all .4s ease-in-out;
									/* IE10+ specific styles go here */
									@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
										top: 18px;
									}									
								}
							}
						}						
					}
					&:not(.has-sub) {
						> span {
							&.has-sub-anchor {
								display: none;
							}
						}
					}
				}
				&.opened {
					> a {
						&.has-sub {
							> span {
								&.has-sub-anchor {
									&:after {
										-webkit-transform: rotate(180deg);
										-ms-transform: rotate(180deg);
										transform: rotate(180deg);
									}
								}
							}						
						}
					}
				}
			}
		}
	}	
	
	.nav-bar > ul > li > ul { position:absolute; }

    .nav-bar .has-sub span:after { position:static; margin-top:10px; }
    .nav-bar ul ul .has-sub:after { right:5px; }

    .nav-bar ul li li { margin:5px; }
    .nav-bar ul li li li { margin-left:0; margin-right:0; }
    .nav-bar ul ul a { 
		padding: 3px 25px;
		color: #444;
		font-weight: 400;
		font-size:18px;
		font-family: 'SemplicitaPro-Medium';
	}

    .nav-bar ul ul a:hover, 
	.nav-bar ul ul a:focus,
    .nav-bar ul ul .opened > a { 
		color: #e4701e; 
	}

    /* ================= HOVER FALLBACK IF JAVASCRIPT IS DISABLED ================= */

    .no-js .nav-bar ul ul li:hover > a { color:#000; background:#ddd; }

}

@media (min-width:980px) and (max-width:1280px) {
	.nav-bar>ul>li>a.has-sub>span.has-sub-anchor:after { font-size: 15px !important; }
}

/*  ACCORDION NAVIGATION
    ================================================================*/

/* ------------- CURRENTLY KEEPS SMALL SCREEN STYLES -------------- */


/*  DROP DOWN NAVIGATION
    ================================================================*/

@media (min-width:980px){
    [data-type="dropdown"] ul {
        display:block;
        position:absolute;
        z-index:-1;
        top:100%;
		left: 0;
        width:100%;
		min-width:198px;
        background:#fff;
		padding:0px;
		-webkit-box-shadow: 0px 7px 18px -5px rgba(0,0,0,0.56);
		-moz-box-shadow: 0px 7px 18px -5px rgba(0,0,0,0.56);
		box-shadow: 0px 7px 18px -5px rgba(0,0,0,0.56);
		-webkit-border-radius: 0;
		border-radius: 0;			
        -wekbit-transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, left 0.4s ease-in-out, right 0.4s ease-in-out;
                transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, left 0.4s ease-in-out, right 0.4s ease-in-out;
    }

    [data-type="dropdown"] ul ul { top:-20px; }
    [data-type="dropdown"] .opened > ul { 
		left:100%; 	
		max-height:80em; 
		opacity:1; 
		-webkit-box-shadow: 11px 6px 15px -4px rgba(0,0,0,.36);
		-moz-box-shadow: 11px 6px 15px -4px rgba(0,0,0,.36);
		box-shadow: 11px 6px 15px -4px rgba(0,0,0,.36);	
		-webkit-border-top-left-radius: 0; 
		-moz-border-top-left-radius: 0; 
		border-top-left-radius: 0; 		
	}

	li[data-type="dropdown"]:nth-last-child(-n+2) ul { 
		left:auto; 
		right:0;
	}

    li[data-type="dropdown"] li > ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		width: 550px;
		@include xlg {
			width: 840px;		   
		}
	}
	
    li[data-type="dropdown"]:nth-last-child(-n+2) .opened > ul { 
		left:auto; 
		right:100%; 
	}

    .nav-bar ul li[data-type="dropdown"] li li { width: 33.33%; }

    [data-type="dropdown"] a + .has-sub { width:0; min-height:0; height:100%; }
    [data-type="dropdown"] a + .has-sub > span { min-height:0; height:100%; }

    /* ================= HOVER FALLBACK IF JAVASCRIPT IS DISABLED ================= */

    .no-js .cm-menu [data-type="dropdown"] li:hover > ul { left:100%; }
    .no-js .cm-menu [data-type="dropdown"]:nth-last-child(-n+2) ul { left:auto; right:0; }
    .no-js .cm-menu [data-type="dropdown"]:nth-last-child(-n+2) li:hover > ul { left:auto; right:100%; }

}

/*  SLIDING MENU NAVIGATION ADJUSTMENTS
    ================================================================*/


[data-type="sliding"].opened .sub-menu,
[data-type="sliding"].opened .sub-menu ul { max-width:100%; }

.cm-menu [data-type="sliding"].opened .sub-menu > ul { max-height:80em; opacity:1; }
.cm-menu [data-type="sliding"].animated .sub-menu > ul { overflow:visible; z-index:10; }
.cm-menu [data-type="sliding"].animating .sub-menu { z-index:-1; }

@media (min-width:980px){

    [data-type="sliding"] .sub-menu {
        position:absolute;
        -wekbit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
    }
    [data-type="sliding"] .sub-menu > ul {
        display:block;
        position:absolute;
        z-index:-1;
        top:100%; 
		left:0;
        width:198px;
        background:#333;
        -wekbit-transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, left 0.4s ease-in-out, right 0.4s ease-in-out;
                transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, left 0.4s ease-in-out, right 0.4s ease-in-out;
    }

    [data-type="sliding"] .sub-menu > ul li { position:static; }
    [data-type="sliding"] .sub-menu > ul > li > ul { position:absolute; top:0; }

    [data-type="sliding"] .sub-menu > ul ul { position:absolute; top:0; }

    [data-type="sliding"] .sub-menu ul ul ul { position:static; margin:0; padding:0; max-height:80em; width:100%; overflow:hidden; background:transparent; opacity:1; }

    [data-type="sliding"] .sub-menu ul ul .has-sub { color:#fff; cursor:default; }
    [data-type="sliding"] .sub-menu ul ul .has-sub { background: none; border-bottom:1px solid #fff; }
    [data-type="sliding"] .sub-menu ul ul .has-sub:after,
    [data-type="sliding"] .sub-menu ul ul .expander-wrap .has-sub  { display:none; }

    [data-type="sliding"] .sub-menu > ul > li > ul > li { float:left; width:50%; padding:0 5px; }
    [data-type="sliding"] .sub-menu > ul > li > ul > li:nth-child(2n+1) { clear:left; }
    [data-type="sliding"] .sub-menu > ul > li > ul > .link-landing { margin-left:0; margin-right:0; width:100%; }
    [data-type="sliding"] .sub-menu > ul > li > ul > .link-landing ~ li:nth-child(2n+1) { clear:none; }
    [data-type="sliding"] .sub-menu > ul > li > ul > .link-landing ~ li:nth-child(2n+2) { clear:left; }

    /* ================= ESSENTIAL SLIDING FUNCTIONALITY ================= */

    [data-type="sliding"] .sub-menu.cm-js-inactive { position:relative; left:0 !important; width:100% !important; }
    [data-type="sliding"] .sub-menu.cm-js-inactive > ul { min-width:100%; }
    [data-type="sliding"] .sub-menu.cm-js-inactive > ul > li > ul { padding:0; max-width:0; }

    [data-type="sliding"] .sub-menu > ul ul { width:100%; margin-left: 10px; padding-left:198px; padding-right:5px; z-index:-1; opacity:0; }
    [data-type="sliding"] .sub-menu .animated ul { opacity:1; }

    [data-type="sliding"].opened .sub-menu, [data-type="sliding"].opened .sub-menu ul { max-width:none; }

    /* ================= HOVER FALLBACK IF JAVASCRIPT IS DISABLED ================= */

    .no-js .cm-menu [data-type="sliding"] li:hover > ul { left:100%; }
    .no-js .cm-menu [data-type="sliding"]:nth-last-child(-n+2) ul { left:auto; right:0; }
    .no-js .cm-menu [data-type="sliding"]:nth-last-child(-n+2) li:hover > ul { left:auto; right:100%; }

}

/*  MEGA MENU NAVIGATION ADJUSTMENTS
    ================================================================*/

@media (min-width:980px){

	[data-type="dropdown"] ul > li.link-landing { display: none; }
	
	[data-type="dropdown"] ul ul li.link-landing { display: block; }
	
    .cm-menu [data-type="mega"] { position:static; }
    [data-type="mega"] > ul {
        position:absolute;
        top:100%; left:0;
        width:100%; max-height:0;
        background:#333;
        overflow:hidden;
        -wekbit-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
    }

    [data-type="mega"] > ul ul { position:static; max-height:80em; width:100%; overflow:hidden; background:transparent; opacity:1; }
    [data-type="mega"] > ul .opened ul { margin-left:0; }
    [data-type="mega"] > ul > li { float:left; width:25%; }
    [data-type="mega"] > ul > li:nth-child(4n+1) { clear:left; }

    [data-type="mega"] > ul > .link-landing { margin:0; width:100%; }
    [data-type="mega"] > ul > .link-landing ~ li:nth-child(4n+1) { clear:none; }
    [data-type="mega"] > ul > .link-landing ~ li:nth-child(4n+2) { clear:left; }

    /* ================= OPENED FUNCTIONALITY ADDED ON SETUP ================= */

    [data-type="mega"] a + .has-sub { width:0; min-height:0; height:100%; }
    [data-type="mega"] a + .has-sub > span { min-height:0; height:100%; }
    [data-type="mega"] ul .has-sub:after { display:none; }
    [data-type="mega"].opened > ul { padding:20px; }

    /* ================= SEPARATE EXPANDERS OPTION IS ENABLED ================= */

    [data-type="mega"] ul a + .has-sub { display:none; }

    /* ================= HOVER FALLBACK IF JAVASCRIPT IS DISABLED ================= */

    .no-js .cm-menu [data-type="mega"] ul ul { display:block; }

}


/*================================================================
                 NAVIGATION TOGGLE STYLES
================================================================*/

.toggle-menu { display:block; position:relative; border:0; width:50px; height:46px; background:#333; }
.toggle-menu:before, .toggle-menu:after, .toggle-menu > span:before {
    content:" ";
    position:absolute;
    left:15%;
    border-radius:2px;
    width:70%; height:3px;
    background:#fff;
}

.toggle-menu:before { top:11px; }
.toggle-menu:after { top:21px; }
.toggle-menu > span:before { top:31px; }

.toggle-menu:hover, .toggle-menu:focus, .js-menu-active .toggle-menu { background:#111; }

@media (min-width:980px){
    .toggle-menu { display:none; }
}

.new-toggle {
	float: right;
	position: relative;
	text-decoration: none;
	width: 32px;
	height: 21px;
	font-size: 0;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	display: block;
	font-weight: 700;
	transition: left .3s ease-in-out;
	background-color: transparent !important;
	outline: 0px !important;
	margin-right: 20px;
}

.new-toggle:after,
.new-toggle:before,
.new-toggle>span:before {
  content: " ";
  position: absolute;
  left: 0;
  width: 32px;
  height: 3px;
  background: #0a4e8c;
  border-radius: 2px;	
  transition: all .3s ease-in-out .4s, -webkit-transform .3s ease-in-out .6s;
  transition: all .3s ease-in-out .4s, transform .3s ease-in-out .6s;
  transition: all .3s ease-in-out .4s, transform .3s ease-in-out .6s, -webkit-transform .3s ease-in-out .6s
}

.new-toggle:before {
  top: 0px;
}

.new-toggle:after {
  top: 8px;
}

.new-toggle>span:before {
  top: 16px;
}


@media (max-width:767px) {
	.cm-js-menu-active {
		body {
			padding-top: 0;
			#sticky-nav {
				position: absolute;
			}
		}
	}	
}

.cm-js-menu-active .new-toggle {
  margin-left: -10px;
}

.cm-js-menu-active .new-toggle:after,
.cm-js-menu-active .new-toggle:before {
  top: 14px;
  top: .575rem;
  left: 50%;
  width: 24px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.cm-js-menu-active .new-toggle>span:before {
  top: 14px;
  top: .575rem;
  left: 50%;
  width: 24px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.secondary-navbar-wrapper {
	.secondary-nav {
		.cm-menu {
			.li-nav {
				.sub-menu {
					.li-nav {
						ul {
							max-height: 1000px;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
/*================================================================
                 EXTRA OVERWRITE STYLES
================================================================*/

.main-nav { position:relative; z-index:1000; font-family: $font-stack;}

[data-type="sliding"] .sub-menu .item-banner { width:100%; }
[data-type="sliding"] .sub-menu .item-banner a { text-align:center; background:none; }

[data-type="mega"] .custom-sub {
    max-height:0; opacity:0;
    overflow:hidden;
    -wekbit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
}
[data-type="mega"] .custom-sub ul { position:static; opacity:1; max-height:80em; }
[data-type="mega"] .custom-sub p { margin:10px; color:#fff; }

[data-type="mega"] .custom-sub .banner-wrap { width:100%; }
[data-type="mega"] .custom-sub .banner-wrap a { text-align:center; background:none; }

.cm-menu .opened > .custom-sub { max-height:80em; opacity:1; padding:20px; }
.cm-menu .animated > .custom-sub { overflow:visible; z-index:10; }
.cm-menu .animating > .custom-sub { z-index:-1; }

@media (min-width:980px) {
    [data-type="mega"] .custom-sub {
        position:absolute;
        top:100%; left:0;
        width:100%;
        background:#333;
    }
}

@media (min-width:980px) {
    .table-row { display:table; width:100%; }
    .table-col { display:table-cell; vertical-align:top; }
}

.section-nav .opened > ul { border-bottom:1px solid #d4d4d4; max-height:80em; opacity:1; }
.section-nav .opened > a,
.section-nav .opened > .expander-wrap a { border-bottom:1px solid #aaa; color:#333; background:#d4d4d4; }
.section-nav .opened .opened > .expander-wrap > a { background:#c4c4c4; }


/* Additional changes for drawer */
.site-container {
	position:relative; 
	right:0;
	width:100%;
	-wekbit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
body > .main-nav {
	position:absolute; 
	z-index:1000;
	top:0; 
	right:100%;
	width:100%;
	height:100%;
	background:#ffffff;
	-wekbit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
}
body > .main-nav .toggle-menu { display:none; }
.cm-js-menu-active .main-nav { 
	padding-right:17%; 
	left:17%; 
	overflow-y: auto;
}
.cm-js-menu-active .site-container {
	right:83%; 
}

.headerMain .main-nav { display:none; }

@media (min-width:768px) and (max-width:979px) {
	.nav-mobile-headerLinks {
		.new-toggle {
			display: none;
		}	
	}
	.cm-js-menu-active {
		.nav-mobile-headerLinks {
			display: table-cell;
			vertical-align: middle;
			width: 60px;
			.new-toggle {
				display: block;
				margin-right: 8px;
			}	
		}	
		.new-toggle:after,
		.new-toggle:before {
		  left: -2px;
		}
		.new-toggle>span:before {
		  left: -2px;
		}		
	}
}

@media (max-width:767px) {
	.cm-js-menu-active {
		.nav-mobile-headerLinks {
			display: none;
		}
	}
	.nav-mobile-headerLinks {
		display: none;
	}

	.main-nav {
		ul {
			&.cm-menu {
				overflow: visible;
				margin: 0;
				padding: 20px 0 14px;
				border-bottom: 1px solid #ccc;
				li {
					a {
						padding: 6px 31px;
						font-size: 16px;
						font-weight: 500;
						text-transform: uppercase;
					}
					ul {
						margin: 0;
						background-color: #959ca1;
						&[aria-expanded="true"] {
							padding-top: 11px;
							padding-bottom: 9px;							
						}
						li {
							a {
								padding-top: 2px;
								padding-bottom: 2px;									
								font-size: 16px;
								font-weight: 400;
								text-transform: none;
								color: #fff;
							}
							ul {							
								&[aria-expanded="true"] {
									padding: 2px 0px 2px 5px;	
								}
								li {
									a {									
										font-size: 14px;
										font-weight: 400;
									}	
								}
							}							
						}
					}
				}
			}
		}
	}
	
	.main-nav .nav-mobile-account .accountNav a.account {
		display: table;
		width: 100%;
		background-color: #e4701e;
		padding: 10px 31px 10px;
		font-size: 14px;
		font-weight: 600;
	}
	
	.main-nav .nav-mobile-account .headerLinks a.account span {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		width: 22px;
		padding-right: 15px;
	}
	.main-nav .nav-mobile-account .headerLinks a.account .icon {
		display: inline-block;
		vertical-align: middle;
		fill: #fff;
		width: 22px;
		height: 24px;
		padding-right: 0px;
	}		
	.main-nav .nav-mobile-account .headerLinks a.account span+span {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		width: inherit;
	}	
	
	.main-nav .nav-mobile-account .headerLinks a.account span+span .icon {
		display: inline-block;
		position: absolute;
		fill: #fff;
		width: 10px;
		height: 10px;
		margin: 5px 0 0;	
		right: 0px;
		padding-right: 0px;
		vertical-align: top;
		-wekbit-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;		
	}
	
	.main-nav .nav-mobile-account .headerLinks a.account[aria-expanded="true"] span+span .icon {
		margin-top: 12px;
		-webkit-transform: scale(0.98) translateY(-75%) rotate(90deg);
		-ms-transform: scale(0.98) translateY(-75%) rotate(90deg);
		transform: scale(0.98) translateY(-75%) rotate(90deg);			
	}
	
	.cm-js-menu-active {
		.nav-mobile-cart {
			.navbar-siteCart {
				width: 100%;
				text-align: left;
				background-color: #ffffff;
				.icon {		
					display: table-cell;
					vertical-align: middle;
					fill: #0a4e8c;
					width: 33px;
					height: 22px;	
					display: table;
					margin-bottom: 0px;
				}
			}
			.cartLink {
				display: table;
				padding: 20px 31px 10px;				
				color: #0a4e8c;
				font-size: 12px;
				text-decoration: none;
				text-align: center;
				line-height: 1;
				white-space: nowrap;
				text-transform: uppercase;
				widows: 100%;
				span {
					padding-left: 5px;
					display: table-cell;
					vertical-align: middle;					
					span {
						display: inline-block;
						margin-left: 5px;
						font-weight: 500;
						color: #e4701e;
					}
				}

				.icon-cart {
					display: block;
					color: $color-process;
					font-size: 1.286em;
				}
			}
		}
	}
	
}

@media (max-width:979px){
	.cm-menu a, 
	.cm-menu a:visited { 
		color: #3d3d3d; 
	}	
	.cm-menu > .opened > a, 
	.cm-menu > li > a:focus, 
	.cm-menu > li > a:hover, 
	.cm-menu > li > div > a:focus, 
	.cm-menu > li > div > a:hover {
		color: #e4701e;
	}	
}

@media (min-width:980px) {
	.new-toggle { display:none; }
	body > .main-nav { display:none; }
	.headerMain .main-nav { display:block; }

	.cm-js-menu-active .site-container { right:0; }
}

@media (min-width:980px) and (max-width:1024px) {
	.navbar .navbar-mainNav {
		padding-right: 10px;
	}	
	.nav-bar > ul > li > a {
		padding: 10px 7px;
	}
	.nav-bar > ul > li > a > span {
		font-size: 13px;
	}
	.navbar .navbar-siteCart {
    	width: 70px;
	}
	.navbar .cartLink {
		font-size: 10px;
	}
	.navbar .navbar-siteCart .icon {
		margin-left: auto;
		margin-right: auto;		
		width: 33px;
		height: 22px;
	}
	.cm-menu > li > .has-sub:after {
		bottom: -7px;
		left: 18px;
	}
	[data-type="dropdown"] ul {
		left: 0;
	}
}

@media (min-width:1280px) and (max-width:1380px) {
	.navbar .navbar-siteCart {
		min-width: 90px;
	}
}

@media (min-width:980px) and (max-width:1279px) {
	.navbar .navbar-siteCart {
		min-width: 70px;
	}
}

@media (min-width:980px) and (max-width:1380px) {
	.navbar .navbar-mainNav {
		padding-right: 10px;
	}	
	.nav-bar > ul > li > a {
		padding: 10px 10px;
	}
	.navbar .cartLink {
		font-size: 11px;
	}
	.navbar .navbar-siteCart .icon {
		margin-left: auto;
		margin-right: auto;		
		width: 33px;
		height: 22px;
	}
	.cm-menu > li > .has-sub:after {
		bottom: -7px;
		left: 18px;
	}
	[data-type="dropdown"] ul {
		left: 0;
	}
	.header-main-logo {
		img {
			width: 100%;
		}
	}
}