﻿.my-catalogs-list-container {
    padding: 25px;
    border: 1px solid $color-border;

    h2 {
        font-family: Montserrat,Arial,sans-serif;
        font-weight: 600;
    }

    .my-catalogs-more {
        display: none;
    }
}

.my-catalogs-list {
    color: $color-primary;
    margin: 15px 0;

    .my-catalog-item {
        padding: 10px 25px;

        &:nth-of-type(2n+1) {
            background-color: $color-primary-light;
        }

        .catalog-title {
            cursor: pointer;
        }

        .catalog-arrow {
            display: inline-block;
            width: 0;
            height: 0;
            margin: 0 0 0 5px;
            border-style: solid;
            border-width: 6px 0 6px 8px;
            border-color: transparent transparent transparent $color-primary;
            -wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
        }

        .catalog-info-container {
            display: none;
            padding: 10px 0 0 0;
        }

        &.opened {
            .catalog-arrow {
                -webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
            }

            .catalog-info-container {
                display: block;
            }
        }
    }

    .catalog-title {
        font-weight: 700;
    }

    .catalog-item-num {
        font-size: .8em;
        cursor: default;
    }
    .catalog-item-date {
        color: $color-text-primary;
        font-size: .9em;
        cursor: default;
    }
}

.my-catalogs-more {
    text-align: right;
}