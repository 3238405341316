﻿.search-products-wrapper {
    border: 1px solid $color-border;
}

.search-products-header {
    background-color: $light-gray;
    padding: 30px;

    h2 {
        font-family: Montserrat,Arial,sans-serif;
        display: inline;
        margin: 0 0 25px 0;
        vertical-align: middle;
    }

    .icon {
        fill: $dark-gray;
        stroke: $dark-gray;
        width: 25px;
        height: 25px;
        display: inline;
        vertical-align: middle;
        margin: 0 0 0 0px;

        &:hover, &:focus {
            fill: $dark-gray-lighter;
            stroke: $dark-gray-lighter;
        }
    }

    .find-favorites-search {
        margin: 25px 0 15px 0;
    }

    #favorites-title-edit {
        .btn {
            height: 48px;
        }
    }

    .roc-pageOptions-filter {
        width: 100%;

        input[type=text] {
            width: 96%;
            height: 50px;
            padding: 15px;
        }
    }

    .formee {
        label {
            font-size: 1em;
        }
    }
 
}

.search-products-header-title-container {
    margin: 0 0 15px 0;
}

.search-products-filter, .search-products-settings {
    width: 48%;
    display: inline-flex;
    align-items: center;

    label {
        display: inline-flex;
        font-size: 1em;
        margin: 0 10px 0 0;
    }

    select {
        display: inline-flex;
        margin: 0;
    }
}

.search-products-filter {
    margin: 0 3.5% 0 0;

    label {
        width: 90px;
    }
}

.search-products-settings {
    label {
        width: 190px;
    }
}

.find-favorites-search {
    position: relative;

    .formee-group-btn {
        position: absolute;
        top: 2px;
        right: 35px;
    }

    .btn {
        width: 40px;
        height: 40px;
        background-color: transparent;
        font-size: 1.5em;
        padding: 0;
    }

    .icon-search {
        color: $color-primary;
    }
}

.search-products-content {
    padding: 40px;
}

.search-products-list {

    .product-item-container {
        @include product-item-container;
        margin: 40px 0 0 0;
    }

    .product-item {
        @include product-item;
    }

    .product-image, .product-content, .product-action {
        @include product-item-sections;
    }


    /*** PRODUCT IMAGE SECTION ***/
    .product-image {
        @include product-item-image-section;
    }


    /*** PRODUCT CONTENT SECTION ***/
    .product-content {
        @include product-item-content-section;
    }

    .product-sku {
        @include product-item-sku;
    }

    .product-title {
        @include product-item-title;
    }

    .product-restriction {
        @include product-item-restriction;
    }

    .product-delivery {
        width: 100%;
        @include product-item-delivery;

        span {
            @include product-item-delivery-span;
        }
    }


    /*** PRODUCT ACTION SECTION ***/
    .product-action {
        @include product-item-action-section;

        .product-delivery {
            @include product-item-delivery-action;
        }
    }

    /* PRICING */
    .product-price {
        @include product-item-price;
        text-align: left;

        span {
            @include product-item-price-span;
        }
    }

    .previous-price {
        @include product-item-price-prev;
    }

    .sale-price, .current-price {
        @include product-item-price-curr;
    }

    /* QUANTITY */
    .product-qty {
        @include product-item-qty;
    }

    .product-qty-label {
        display: inline-flex;
        width: 30%;
    }

    .qty-increment {
        @include product-item-qty-increment;
        margin: 0;
    }

    .qty-decrease, .qty-increase {
        @include product-item-qty-btns;
    }

    input[type=number] {
        &.qty-amount {
            @include product-item-qty-amount;

            &::-webkit-inner-spin-button {
                @include product-item-qty-spinner;
            }
            &::-webkit-outer-spin-button {
                @include product-item-qty-spinner;
            }
        }
    }

    /* BUTTONS */
    .product-add-cart {
        @include product-action-item;
    }

    .product-move-item {
        @include product-action-item;

        .moveItemSelect {
            width: 100%;
            padding: 10px;
        }
    }

    .product-delete-item {
        @include product-action-item;
        text-align: center;
        margin: 0;
    }
}