// Panels
// bootstrap-based

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;

  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;

    + .panel-collapse > .panel-body {
      border-top-color: $border;
    }
    .badge {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      //border-bottom-color: $border;
    }
  }
}

.panel-default {
//	border-left: none !important;
//	border-right: none !important;
}

.panel-body {
	margin: 0px 0px 0px 0px;
	padding: 15px 5% 35px;
	font-size: 16px;
	font-weight: 400;
	color: #333;
	@media(max-width:767px) {
		padding: 15px 5% 15px !important;
	}		
}

/* 16px baseline (768px) */
@media only screen and (min-width:48em) {	
	
	.panel-group > .panel .panel-heading + .panel-collapse > .panel-body, .panel-group > .panel .panel-heading + .panel-collapse > .list-group {
		border-top: 0px solid #e7e7e7 !important;
	}	
	
	/*.panel {
		border-bottom: none !important; 
	}*/
	
	.panel-group {
		.panel-heading {
			padding: 15px 0px 15px 0px !important;
			.icon-carrot {
			
			}
			.panel-title {
				margin: 0px 0px 0px 0px;
				font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 500;
				color: #0a4e8c;	
				letter-spacing: 1px;
				a {
					/*pointer-events: none;*/
				}
			}
		}
		.panel-collapse {
			&.collapse {
				/*display: block !important;
				height: auto !important;*/
			}
		}		
	}
	
}

.change-password-pg-wrapper,
.manage-account-pg-wrapper {
	.panel {
		background: #fff;
	}

	.panel-title {
		background: #dfdfdf;
		padding: 15px 10px !important;
	}

	.panel-body {
		padding: 15px !important;
	}

	.my-account-container .formee-req-key {
		margin: 0 !important;
		padding: 5px !important;
	}
}

.slideCartCompanion {
	#quote-details-wrapper {
		.panel {
			background-color: #fff;

			.panel {
				border: none;
			}

			.panel-open {
				background-color: #fff;

				.panel-body {
                    background: #fff;
                    padding: 0 0 15px 0;
				}
			}

			h2.panel-title {
                padding: 0 !important;
				font-family: Montserrat,Arial,sans-serif;
				font-weight: 700;
				padding: 0 10px;
				border-bottom: none;
				color: #333 !important;
			}
		}
	}

	#quote-transaction-wrapper{
        margin-top: 15px;

		.panel {
            background-color: #fff;
            margin-bottom: 15px;

			.panel {
				border: none;
			}

			.panel-open {
				background-color: #fff;

				.panel-body {
                    background: #fff;
                    padding: 0 0 15px 0;
                    
                    .itemList {
                        margin: 0;

                        .interiorList {
                            .item {
                                border: none;
                            }
                        }
                    }
				}
			}

			h2.panel-title {
                padding: 0 !important;
				font-family: Montserrat,Arial,sans-serif;
				font-weight: 700;
				padding: 0 10px;
				border-bottom: none;
				color: #333 !important;
			}
		}
    }
    
    .summary-left {
        width: 45%;
        float: left;
    }

    .summary-right {
        width: 45%;
        float: right;
    }
}


#quote-summary-rail {
	.title {
		position: relative;
		display: block;
		font-family: "IBM Plex Sans",sans-serif;
		font-size: 24px;
		font-weight: 600;
		line-height: 30px;
		margin: 0;
		padding: 20px;
		color: #333!important;
		background-color: #dfdfdf;
	}

	.orderList-summary {
		&:after {
			content: '';
			display: block;
			width: 100%;
			clear: both;
		}
	}

	.quote-summary {
		background-color: #dfdfdf;
		font-family: "IBM Plex Sans",sans-serif;
		font-size: 16px;
		line-height: 20px;
		border-top: 1px solid #eaeaea;
	}
}

.quote-rail-container {
	padding: 20px;
}

.my-account-container {
	.quote-transaction-pg-wrapper {
		#quote-details-wrapper,
		#quote-transaction-wrapper {
			padding: 0 !important;

			.panel-heading {
				padding: 0 !important;

				h2.panel-title {
					background: #0a4e8c;
					color: #fff !important;
					padding: 15px !important;
					font-family: Montserrat,Arial,sans-serif;
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;
					text-transform: none;
				}
			}
		}

		.checkout-btn-wrapper {
			margin: 15px 0;
		}

		#quote-transaction-wrapper {
			margin-top: 0;

			.panel {
				margin-bottom: 0px;
			}

			.panel.panel-primary {
				margin-top: 15px;
			}

			.btn.btnFullWidth {
				padding: 15px 0;
			}
		}

		.payment-selector {
			.interiorList {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-gap: 20px;
				margin: 0;
				width: 100%;

				@media only screen and (max-width: 500px) {
					grid-template-columns: 1fr;
				}

				.item {
					display: inline-block !important;
					flex-wrap: unset !important;
					width: 100% !important;
					margin: 0 !important;
					padding: 17px 20px 20px !important;
					border: 1px solid #eaeaea !important;
					font-size: 16px;
				}
			}
		}

		#selectAddressWrapper {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 20px;
			margin: 0;
			width: 100%;

			@media only screen and (max-width: 500px) {
				grid-template-columns: 1fr;
			}

			.item {
				display: inline-block !important;
				flex-wrap: unset !important;
				width: 100% !important;
				margin: 0 !important;
				padding: 17px 20px 60px !important;
				border: 1px solid #eaeaea;
				font-size: 16px;
				position: relative;


				.btn.btnFullWidth {
					padding: 15px 0;
					position: absolute;
					bottom: 10px;
					width: 90%;
					left: 50%;
					transform: translateX(-50%);
					display: inline-block;
					font-size: 16px;
					font-weight: 600;
					text-transform: capitalize;
					padding: 14px 20px;
					color: #0a4e8c;
					background-color: #fff;
					border: 1px solid #0a4e8c;

					&[data-roc-action=btnChecked] {
						padding-left: 25px;
						background-color: #0a4e8c;
						border: 1px solid #0a4e8c;
						color: #fff;

						&:before {
							content: "\e618";
							font-family: ROC-Icons;
							speak: none;
							font-style: normal;
							font-weight: 400;
							font-variant: normal;
							text-transform: none;
							line-height: 1.42857;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							display: inline-block;
							position: absolute;
							margin-left: -23px;
						}
					}
				}
			}
		}

		#shipping-methods-list {
			.btn.btnFullWidth {
				background-color: #fff;
				color: #0a4e8c;
				border: 1px solid #0a4e8c;

				&[data-roc-action=btnChecked] {
					padding-left: 25px;
					background-color: #0a4e8c;
					border: 1px solid #0a4e8c;
					color: #fff;

					&:hover {
						transform: scale(1);
					}

					&:before {
						content: "\e618";
						font-family: ROC-Icons;
						speak: none;
						font-style: normal;
						font-weight: 400;
						font-variant: normal;
						text-transform: none;
						line-height: 1.42857;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						display: inline-block;
						position: absolute;
						margin-left: -23px;
					}
				}
			}
		}





		.roc-save-payment li {
			margin: 30px 0 0 !important;
		}

		.form-card-types {
			margin-top: 18px;

			@media only screen and (max-width: 500px) {
				text-align: center;
				margin-top: 0;
			}
		}
	}

	#newAddressFormWrapper {
		display: none;

		&.add-new-address-on {
			display: block;
		}

		.opacity-hidden {
			margin: 7px 0 6px;
		}

		.formee-req {
			font-size: .75em;
			margin-right: 5px;
		}
	}
}