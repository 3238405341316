/* ## reviews and rating ## */

.reviewsWrapper a {
	text-decoration: none;
}

.reviewsWrapper .controls a,
.reviewsWrapper .controls a:link,
.reviewsWrapper .controls a:visited {
	text-decoration: none;
}

.reviewsWrapper .controls .inactive {
	color: #999999;
}

.reviewsWrapper .controls .postLinks {
	float: left;
	font-size: .9em;
}

.reviewsWrapper .controls .postLinks a,
.reviewsWrapper .controls .postLinks a:link,
.reviewsWrapper .controls .postLinks a:visited {
	text-decoration: underline;
}

.reviewsWrapper .controls label {
	vertical-align: baseline;
}

.reviewsWrapper .roc-pageOptions {
	margin: 0;
}

.reviewsWrapper .reviewhdng {
	margin: 0 0 5px;
	padding: 0;
	font-size: 1.125em;
	font-weight: bold;
	color: #000;
}

/* ## Rating Display Styles */

.ratingDisplay {
	float: left;
	margin: 0 3px 0 0;
	padding: 0;
	list-style-type: none;
	
	a {
		font-weight: bold;
	}
}

.textRating {
	display: none;
	margin-left: 5px;
	font-size: 0.938em;
	vertical-align: top;
	color: $color-text-secondary;
	
	.ratingValue {
		font-weight: bold;
	}
}


/* ## Review Average */

.reviewAverage .reviewItem {
	margin: 0 !important;
	padding: 0;
	border-bottom: 0;
}
.reviewAverageInterior {
	.ratingDisplay {
		margin: 5px 0;
		
		a {
			font-size: 0.938em;
		}
	}

	.textRating {
		position: relative;
		top: -6px;
		display: inline-block;
		margin-right: 5px;
		font-size: 1.5em;
		font-weight: bold;
		color: $color-text-primary;
		
		.ratingMax {
			display: none;
		}
	}
}


/* ## Review List */

.reviewList {
	padding: 20px 20px 10px;
	background: #e6e6e6;
	/*border: 1px solid $color-border;*/
	
	.paging input[type="number"], 
	.paging input[type="text"] {
		margin-right: 0;
		width: 40px;
		height: 40px;
		border: none !important;
	}
	
	select {
		height: 40px !important;
	}
	
	.roc-pageOptions-count {
		padding-top: 8px;
	}
	
	// 659px 
	@media(max-width:41.188em) {
		padding: 5px;
	}
}

.reviewItem {
	margin: 20px 0;
	padding: 20px;
	background-color: $white;
	border: 1px solid $color-border;
	word-wrap: break-word;
}

.reviewItem .itemName {
	margin-bottom: 10px;
	font-size: 1.3em;
	font-weight: bold;
}

.reviewItem .itemName a,
.reviewItem .itemName a:link,
.reviewItem .itemName a:visited {
	text-decoration: none;
}

.reviewItem .postStamp {
	margin-bottom: 20px;
	color: $color-text-secondary;
	
	p {
		margin: 0;
	}
	
	.location {
		display: block;
	}
}

.reviewItem .reviewAuthor {
	font-weight: bold;
}

.postInfo .adr {} .postInfo .verifiedPurchase {
	color: blue;
}

.reviewItem .reviewer a,
.reviewItem .reviewer a:link,
.reviewItem .reviewer a:visited {
	color: #000;
}

.reviewItem .reviewer .fn {
	font-weight: bold;
}

.reviewItem .reviewer .adr {
	font-weight: normal;
}

.reviewItem .description {
	margin-bottom: 10px;
	
	p:last-child {
		margin-bottom: 0;
	}
}

.reviewItem .foundHelpful {
	float: right;
	margin: 0;
	color: $color-text-secondary;
}

.reviewItem .titleAlt {
	font-size: 1.2em;
	margin: 5px 0;
}

.rocReviewsListing {
	&.favorites {
		.productList {
			.itemWrapper {
				.media {
					@media (max-width:767px) {
						display: table;
						margin-left: auto;
						margin-right: auto;
					}					
				}
				.itemAction {
					text-align: right;
					@media (max-width:767px) {
						text-align: center;
					}						
				}				
			}
			.itemContent {
				@media (min-width:768px) {
					text-align: left;
				}				
			}
		}	
		.reviewItem {
			.ratingDisplay {
				@media (max-width:767px) {
					float: none;
					display: table;
					margin: 0 auto;
				}				
			}
			.foundHelpful {
				@media (max-width:767px) {
					float: none;
					display: table;
					margin: 0 auto 15px;
				}					
			}			
		}		
	}
}

.reviewItemBase {
	display: table;
	width: 100%;
	
	.reviewHelpful {
		display: table-cell;
		color: $color-text-secondary;
	}
	.reviewLinks {
		display: table-cell;
		text-align: right;
		color: $color-text-secondary;
		
		a {
			font-weight: bold;
		}
	}
	
	.shareReview {
		display: inline-block;
	}
	
	// 659px 
	@media(max-width:41.188em) {
		display: block;
		
		.reviewHelpful {
			display: block;
			
			:first-child {
				display: block;
			}
		}
	}
}


/* ## Share Review */

.shareReview {
	margin: 0 0 10px 0;
}

.shareReview .addthis_toolbox {
	display: inline-block;
}

.reviewItem .ratingsGroup .reviewhdng {
	float: none;
	display: block;
}


/* ## Write a Review */

#writeReview {
	display: none;
}

.reviewCompose {
	padding: 20px 0;
}

.reviewCompose .reviewRatingLabel {
	display: block;
	margin-bottom: 3px;
}

.reviewCompose .ratingSelect {
	margin-bottom: 10px;
}

.reviewCompose textarea {
	height: 150px;
}

.commentList {
	padding-top: 20px;
	margin: 20px 0 0;
	border-top: 1px dashed $color-border-light;
}

.reviewCommentNested {
	margin-left: 10px;
}

.reviewCommentCompose {
	display: none;
	margin: 30px 20px 0;
}

.reviewCommentCompose textarea {
	height: 75px;
}

.reviewComment {
	margin: 0;
	padding: 10px 0 0;
	border: 0;
	border-top: 1px solid $color-border-light;
	
	.description {
		margin-bottom: 5px;
	}
	
	.postStamp {
		margin: 0;
	}
}

.reviewComment .commentInterior {
	margin: 0;
	padding: 0 0 0 10px;
	border-left: 5px solid $color-primary;
}


/* ## Ratings Breakdown */

.ratingBreakdownTrigger {
	font-size: 0.9em;
	text-decoration: underline;
	cursor: pointer;
}

.ratingBreakdown {
	padding-left: 20px;
	border-left: 1px dotted $color-border;
}

.ratingBreakdown .rbItem {
	clear: both;
	font-size: .938em;
	font-weight: bold;
}

.ratingBreakdown .rbItemName {
	float: left;
	display: inline;
	width: 100px;
	margin-right: 10px;
	padding-top: 1px;
	text-align: right;
}

.ratingSecondary {
	margin: 0 0 10px 0;
}

.ratingSecondary .rsItem {
	margin-bottom: 2px;
	font-size: .938em;
}

.ratingSecondary .rsItemName {
	display: inline-block;
	width: 105px;
	font-weight: bold;
}

.ratingSecondary .textRating {
	line-height: 1em;
}


/* ## Bar Ratings */

.ratingSecondary .barGraph {
	display: inline-block;
	font-weight: bold;
	width: 60px;
	height: 4px;
	border: 1px solid #000;
	vertical-align: middle;
}

.ratingSecondary .barGraph span {
	display: inline-block;
	height: 4px;
	background: #999;
}

.ratingSecondary .bar0_0 span { width: 0; }
.ratingSecondary .bar0_5 span { width: 6px; }
.ratingSecondary .bar1_0 span { width: 12px; }
.ratingSecondary .bar1_5 span { width: 18px; }
.ratingSecondary .bar2_0 span { width: 24px; }
.ratingSecondary .bar2_5 span { width: 30px; }
.ratingSecondary .bar3_0 span { width: 36px; }
.ratingSecondary .bar3_5 span { width: 42px; }
.ratingSecondary .bar4_0 span { width: 48px; }
.ratingSecondary .bar4_5 span { width: 54px; }
.ratingSecondary .bar5_0 span { width: 60px; }

.ratingSelection .hover-text {
	margin-left: 5px;
}

.reviewDetailsLanding .ratingSummary {
	width: auto;
}
.reviewDetailsLanding .reviewAverage {
	margin-bottom: 10px;
}
.reviewItemImage {
	float: left;
	display: inline;
	margin: 0 10px 10px 0;
}
.reviewItemSummary {
	display: table;
}
.reviewComparison .titleAlt {
	margin: 0 0 10px 0;
	font-weight: bold;
	font-size: 1.4em;
}
.reviewComparison .reviewHigh {
	display: inline;
	float: left;
	width: 48%;
}
.reviewComparison .reviewLow {
	display: inline;
	float: right;
	width: 48%;
}
.reviewComparison .postStamp {
	float: left;
	display: block;
	width: auto;
	min-height: auto !important;
	margin-bottom: 10px;
}
.reviewComparison .postStamp .postInfo {
	display: inline;
	margin-right: 5px;
}
.reviewComparison .postStamp .reviewer {
	display: block;
	margin: 0;
}
.reviewComparison .ratingsGroup {
	margin-left: 0;
}
.reviewComparison .reviewContent {
	display: block;
	clear: both;
}
div.view.rateit {
	top: 3px;
}


/* ## Review embedded ItemWrapper */

.reviewItem .productList {
	margin-top: 0;
	padding: 0;
	border: none;
	
	.itemWrapper:first-child {
		margin-top: 0;
		padding-top: 0;
	}
	
	.itemWrapper:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	
	.itemWrapper .media,
	.itemWrapper .itemImageWrapper {
		width: 60px;
	}
	
	.itemWrapper .itemTitle {
		margin: 0;
		font-size: 1.125em;
		
		a {
			color: $color-action;
		}
	}
	
	.itemWrapper .itemAction {
		text-align: right;
		border: none;
	}
}


/* ## Review Mini */

.reviewMini {
	margin: 0 0 15px;
	padding: 0;
	border: 0;
	font-size: 16px;
	color: #bfbfbf;
	
	a {
		font-weight: 400;
		text-decoration: none;
		color: #e4701e;
	}
	
	.jumpToReview {
		font-size: 16px;
		margin-left: 7px;
		margin-right: 7px;		
	}
	
	.reviewList {
		padding: 0;
		background: none;
		border: none;
	}
	
	.reviewItem {
		margin: 0;
		padding: 20px 0;
		background: none;
		border: none;
		border-top: 1px solid $color-border;
		
		.ratingsGroup {
			margin-bottom: 10px;
		}
		
		.postStamp {
			margin-top: 3px;
			color: $color-text-primary;
		}
	}
}

.reviewSingle {
	.reviewItem {
		padding: 0;
		border: none;
	}
	
	.productList {
		padding-bottom: 1em;
		border-bottom: 1px solid $color-border;
	}
}


/* # static Rating */


.ratingStatic {
	position: relative;
    display: inline-block;
    width: 100px;
	height: 17px;
    margin-left: 0px;
    position: relative;
	
	&:before,
	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100px;
		height: 17px;
		background-image: url("../../images/layout/star.png");
		background-repeat: repeat-x;
	}
	
	/* rating foreground */
	&:after {
		width: 0;
		background-position: 0 -51px;
	}

	&[data-rating="0.1"]:after { width: 6.0px; }
	&[data-rating="0.2"]:after { width: 7.2px; }
	&[data-rating="0.3"]:after { width: 8.0px; }
	&[data-rating="0.4"]:after { width: 9.8px; }
	&[data-rating="0.5"]:after { width: 10.0px; }
	&[data-rating="0.6"]:after { width: 11.4px; }
	&[data-rating="0.7"]:after { width: 12.6px; }
	&[data-rating="0.8"]:after { width: 13.0px; }
	&[data-rating="0.9"]:after { width: 14.4px; }
	&[data-rating="1.0"]:after { width: 20.0px; }
	
	&[data-rating="1.1"]:after { width: 26.4px; }
	&[data-rating="1.2"]:after { width: 27.4px; }
	&[data-rating="1.3"]:after { width: 28.2px; }
	&[data-rating="1.4"]:after { width: 29.2px; }
	&[data-rating="1.5"]:after { width: 30.0px; }
	&[data-rating="1.6"]:after { width: 31.4px; }
	&[data-rating="1.7"]:after { width: 32.6px; }
	&[data-rating="1.8"]:after { width: 33.0px; }
	&[data-rating="1.9"]:after { width: 34.4px; }
	&[data-rating="2.0"]:after { width: 39.6px; }
	
	&[data-rating="2.1"]:after { width: 46.8px; }
	&[data-rating="2.2"]:after { width: 47.8px; }
	&[data-rating="2.3"]:after { width: 48.6px; }
	&[data-rating="2.4"]:after { width: 49.6px; }
	&[data-rating="2.5"]:after { width: 50.4px; }
	&[data-rating="2.6"]:after { width: 51.8px; }
	&[data-rating="2.7"]:after { width: 53.0px; }
	&[data-rating="2.8"]:after { width: 53.4px; }
	&[data-rating="2.9"]:after { width: 54.4px; }
	&[data-rating="3.0"]:after { width: 59.6px; }

	&[data-rating="3.1"]:after { width: 66.8px; }
	&[data-rating="3.2"]:after { width: 67.8px; }
	&[data-rating="3.3"]:after { width: 68.6px; }
	&[data-rating="3.4"]:after { width: 69.6px; }
	&[data-rating="3.5"]:after { width: 70.4px; }
	&[data-rating="3.6"]:after { width: 71.8px; }
	&[data-rating="3.7"]:after { width: 73.0px; }
	&[data-rating="3.8"]:after { width: 73.4px; }
	&[data-rating="3.9"]:after { width: 74.4px; }
	&[data-rating="4.0"]:after { width: 79.6px; }
		
	&[data-rating="4.1"]:after { width: 86.8px; }
	&[data-rating="4.2"]:after { width: 87.8px; }
	&[data-rating="4.3"]:after { width: 88.6px; }
	&[data-rating="4.4"]:after { width: 89.6px; }
	&[data-rating="4.5"]:after { width: 90.4px; }
	&[data-rating="4.6"]:after { width: 91.8px; }
	&[data-rating="4.7"]:after { width: 93.0px; }
	&[data-rating="4.8"]:after { width: 93.4px; }
	&[data-rating="4.9"]:after { width: 94.4px; }
	&[data-rating="5.0"]:after { width: 99.6px; }
}