/* # Site Search */

.siteSearch {
    margin: 0;
    font-style: italic;
    
    label {
        display: none;
    }

    input[type=text] {
		background-color: $white;
    }
    
	/*
    .btn {
        width: 35px;
		margin: -1px 0 0 -35px;
        padding: 6px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
    }
	*/
}


/* # hawk styles overwrites */

.hawk-guidedNavWrapper .hawk-selectedGroup .hawkIcon-close {
	font-family: "Roc-Icons";
	font-size: 20px;
	top: 0;
	left: 0;
	position: absolute;
	@media (max-width:1279px) {
		font-size: 17px;
	}		
	&:before {
		content: "\e614";
	}
}