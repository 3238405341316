﻿
.training-pg-wrapper {
	padding-left: 15px;
	padding-right: 15px;
	.training-banner {
		position: relative;
		margin-left: -15px;
		margin-right: -15px;		
	}	
}

.training-header-container {
    @include containers-w-margin;
}

.training-header-content-container {
    @include interior-container-half;
    padding: 0 100px 0 0;
	@media(min-width:600px) and (max-width:979px) {
		padding-right: 20px;
 	}
	@media(max-width:599px) {
		display: table;
		padding: 0 !important;
		width: 100% !important;
 	}	
}

.training-header-title {
    @include interior-header-title;
	font-family: Montserrat,Arial,sans-serif;
	font-weight: 800;
}

.training-header-line {
    @include title-line;
}

.training-header-content {
    line-height: 1.5em;
}

.training-header-search {
    @include interior-container-half;
    @include callout-container;
    border: 1px solid $color-border;
	@media(max-width:979px) {
		padding: 20px;
 	}	
	@media(max-width:599px) {
		display: table;
		width: 100% !important;
		margin-top: 25px;
 	}	
}

.training-search-title {
    @include title-font-small;
    margin: 0 0 20px 0;
}

.formee {
    .training-search-item {
        input[type=text] {
            padding: 10px 20px;
            margin-bottom: 30px;

            &::placeholder,
            &:-ms-input-placeholder,
            &::-ms-input-placeholder {
                color: #333;
            }
        }

        select {
            padding: 10px 15px;
            margin-bottom: 30px;
        }
    }
}

.training-search-date-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.training-search-from-date,
.training-search-to-date {
    width: 50%;
    display: inline-flex;

    @media(max-width:1200px) {
        width: 100%;
    }
}

.training-search-from-date {
    padding: 0 20px 0 0;

    @media(max-width:1200px) {
        padding: 0;
    }
}

.training-search-to-date {
    padding: 0 0 0 20px;

    @media(max-width:1200px) {
        padding: 0;
    }
}

.training-search-btns-wrapper {
    width: 100%;
    text-align: right;
    margin: 10px 0 0 0;

    .btnTextAlt {
        display: inline-block;
        margin: 0 40px 0 0;
        vertical-align: middle;
    }
}


.training-featured-banner {
    @include containers-w-margin;
}


/********************************/
/*   INTERIOR - CALLOUT ITEMS  */
/******************************/

.interior-callouts-container {
    @include containers-w-margin;
    display: flex;
    flex-wrap: wrap;
	&.programs-special-offers {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 50px;
		width: 100% !important;		
		flex-wrap: unset;
		margin-bottom: 60px;
		@media(min-width:980px) and (max-width:1279px) {
			grid-gap: 40px;
			margin-bottom: 40px;
		}		
		@media(min-width:768px) and (max-width:979px) {
			grid-gap: 30px;
			margin-bottom: 30px;
		}
		@media(min-width:480px) and (max-width:767px) {
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;
			margin-bottom: 30px;
		}
		@media(max-width:479px) {
			grid-template-columns: 1fr;
			grid-gap: 20px;
			margin-bottom: 30px;
		}		
		.interior-callout-image {
			img {
				width: 100%;
			}				
		}
		.interior-callout-line {
			width: 40px;
		}
		.interior-callout-image,
		.interior-callout-title,
		.interior-callout-content {
			@media(max-height:479px) {
				max-height: auto !important;
			}			
		}
		.interior-callout-item {
			/* IE10+ specific styles go here */
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			  display: inline-block;
			  width: 32.5%;
			  margin: 0;
			}			
		}
	}
}

.sfPageEditor {
	.interior-page-wrapper {
		.programs-offers-wrapper {
			.interior-callouts-container {
				&.programs-special-offers {
					grid-template-columns: 1fr;
					>.RadDockZone {
						display: grid;
						grid-gap: 20px;
						grid-template-columns: 1fr 1fr 1fr;						
					}
				}
			}
			.RadDockZone {
				&.RadDockZone_Default {
					&.rdVertical {
						&.zeDockZoneHasLabel {
							>.RadDock {
								&.RadDock_Default {
									&.zeLayoutDock {
										display: inline-block !important;
										width: 100% !important;
										margin: 0 !important;
									}
								}
							}
						}
					}
				}
			}
			.sf_colsIn {
				&.interior-callout-item {
				  display: block !important;
				  width: 100% !important;
				  margin: 0;
				}
			}			
		}
	}
}

.eventsWidget {
	display: table;
	position: relative;
	width: 100% !important;		
}


.events-widget-in {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100% !important;	
    @media(min-width:768px) and (max-width:1279px) {
		grid-template-columns: 1fr 1fr 1fr;		
    }
    @media(min-width:481px) and (max-width:979px) {
		grid-template-columns: 1fr 1fr;		
    }	
    @media(max-width:480px) {
		grid-template-columns: 1fr;		
    }	
}

.training-event-listing {
	.events-widget-in {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;
		width: 100% !important;	

		.interior-callout-item {
			position: relative;
			padding-bottom: 150px;
		}

		.interior-callout-btn {
			text-align: center;
			position: absolute;
			bottom: 0;
		}

		@media (max-width:1438px) {
			grid-template-columns: 1fr 1fr;		

			.interior-callout-item {
				padding-bottom: 100px;
			}
		}	
		@media (max-width:1000px) {
			grid-template-columns: 1fr;		
		}	
		@media (max-width:767px) {
			grid-template-columns: 1fr 1fr;		
		}	
		@media (max-width:500px) {
			grid-template-columns: 1fr;		
		}	
	}
}

.upcoming-events-in {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	width: 100% !important;		
    @media(min-width:768px) and (max-width:1279px) {
		grid-template-columns: 1fr 1fr 1fr;		
    }
    @media(min-width:481px) and (max-width:979px) {
		grid-template-columns: 1fr 1fr;		
    }	
    @media(max-width:480px) {
		grid-template-columns: 1fr;		
    }		
}

.interior-callout-item {
	height: auto !important;
//    width: 30%;
//    display: inline-flex;
//    flex-wrap: wrap;
//    margin: 25px 4.5% 25px 0;
	/* IE10+ specific styles go here */
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		display: inline-block;
		width: 24%;
		padding: 0 10px;
	}	
    @include interior-box-shadow-2;
    

//    @media(min-width:1401px) {
//        &:nth-of-type(3n+0) {
//            margin: 25px 0;
//        }
//    }
//
//    @media(max-width:1400px) {
//        width: 48%;
//        margin: 25px 4% 25px 0;
//
//        &:nth-of-type(2n+0) {
//            margin: 25px 0;
//        }
//    }

//    @media(max-width:768px) {
//        width: 100%;
//        margin: 25px 0;
//    }
	&.training-event-item {
		.interior-callout-image {
			position: relative;
			display: block;
			overflow: hidden;
			margin-bottom: 0;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#003772+0,265c9c+100 */
				background: rgb(0,55,114); /* Old browsers */
				background: -moz-linear-gradient(top,  rgba(0,55,114,0.8) 0%, rgba(38,92,156,0.8) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(0,55,114,0.8) 0%,rgba(38,92,156,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(0,55,114,0.8) 0%,rgba(38,92,156,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003772', endColorstr='#265c9c',GradientType=0 ); /* IE6-9 */			
			}
			&:after {
				content: '';
				position: absolute;
				display: block;
				bottom: -25px;
				left: -10px;
				right: 0;
				width: 150%;
				height: 40%;
				background: #fff;
				-webkit-transform: rotate(-6deg);
				-ms-transform: rotate(-6deg);
				transform: rotate(-6deg);			
			}
		}
	}
	&.webinars-item {
		.interior-callout-image {
			position: relative;
			display: block;
			overflow: hidden;
			margin-bottom: 0;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b64900+0,f57e2d+100 */
				background: rgb(182,73,0); /* Old browsers */
				background: -moz-linear-gradient(top,  rgba(182,73,0,0.8) 0%, rgba(245,126,45,0.8) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(182,73,0,0.8) 0%,rgba(245,126,45,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(182,73,0,0.8) 0%,rgba(245,126,45,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b64900', endColorstr='#f57e2d',GradientType=0 ); /* IE6-9 */				
			}
			&:after {
				content: '';
				position: absolute;
				display: block;
				bottom: -25px;
				left: -10px;
				right: 0;
				width: 150%;
				height: 40%;
				background: #fff;
				-webkit-transform: rotate(-6deg);
				-ms-transform: rotate(-6deg);
				transform: rotate(-6deg);			
			}
		}
	}
}

.interior-callouts-load-more,
.events-widget-load-more {
	display: table;
	position: relative;
	width: 100%;
	margin-top: 15px;
}

.interior-callout-image {
    width: 100%;
    text-align: center;
    margin: 0 0 15px 0;
}

.interior-callout-title {
	display: table;
    width: 100%;
    font-size: 20px;
    font-family: Montserrat,Arial,sans-serif;
	font-weight: 800!important;
	line-height: 27px;
    padding: 0 30px;
	margin-bottom: 15px;
    a {
		display: table;
		position: relative;
        color: $color-text-primary;
        font-family: SemplicitaPro-Semibold;
    }
}

.interior-callout-line {
    @include title-line;
    margin: 20px 30px;
}

.interior-callout-content {
    width: 100%;
    line-height: 1.75em;
    padding: 0 30px 15px 30px;

    @media(max-width:992px) {
        font-size: .9em;
    }
}

.interior-callout-date {
    width: 100%;
    padding: 0 30px;
    margin: 15px 0;
	display: table;
	.icon-wrap {
		display: table-cell;
		vertical-align: top;
		width: 25px;	
		padding-right: 5px;		
		.icon {
			width: 18px;
			height: 18px;
			position: relative;
			display: block;
			fill: #0a4e8c;			
		}
	}
	.date-col {
		display: table-cell;
		vertical-align: top;
		font-size: 16px;
		font-weight: 500;
		color: #333;		
	}
}

.interior-callout-time {
	width: 100%;
    padding: 0 30px;
    margin: 15px 0;
	display: table;
	.icon-wrap {
		display: table-cell;
		vertical-align: middle;
		width: 25px;	
		padding-right: 5px;		
		.icon {
			width: 18px;
			height: 18px;
			stroke: #0a4e8c;
			position: relative;
			display: block;		
		}
	}
	.date-col {
		display: table-cell;
		vertical-align: top;
		font-size: 16px;
		font-weight: 500;
		color: #333;		
	}
}

.interior-callout-item.webinars-item {
	.interior-callout-date {
		.icon-wrap {
			vertical-align: top;
			padding-top: 3px;
			padding-bottom: 5px;
		}
	}
}

.interior-callout-location {
    width: 100%;
    padding: 0 30px;
	display: table;
	.icon-wrap {
		display: table-cell;
		vertical-align: top;
		width: 25px;
		padding-right: 5px;
		.icon {
			width: 18px;
			height: 18px;
			position: relative;
			display: block;
			fill: #0a4e8c;			
		}
	}
	.location-col {
		display: table-cell;
		vertical-align: top;
		font-size: 16px;
		font-weight: 500;
		color: #333;
	}	
}

.interior-callout-summary {
    padding: 0 30px;
}

.interior-callout-btn {
    width: 100%;
    padding: 15px 30px 35px;
	.btnOutline {
		padding-left: 35px;
		padding-right: 35px;		
		font-size: 16px;
		font-weight: 600;
		color: #0a4e8c;
		-webkit-transform: perspective(1px) scale(1);
		-ms-transform: perspective(1px) scale(1);
		transform: perspective(1px) scale(1);	
		&:hover,
		&:focus {
			-webkit-transform: perspective(1px) scale(1.04);
			-ms-transform: perspective(1px) scale(1.04);
			transform: perspective(1px) scale(1.04);			
		}
	}
}

.training-confirmation-wrap {
	display: table;
	margin: 0 auto;
	width: 100%;
	max-width: 900px;
	p {
		strong {
			font-size: 24px;
		}
		&.train-conf-link {
			margin-bottom: 40px;
		}
		a {
			font-size: 24px;
			position: relative;
			display: inline;
			box-decoration-break: clone;
			transition: all 0.9s ease-in-out;
			background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.4), rgba(10, 78, 140, 0.4));
			background-size: 0 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			text-decoration: none;			
			&:hover,
			&:focus {
				background-size: 100% 1px;
			}			
		}
	}
}

/************************************/
/*   END INTERIOR - CALLOUT ITEMS  */
/**********************************/

.interior-callouts-load-more {
    width: 100%;
    text-align: center;
    padding: 25px 0;

    .btn {
        font-size: 1em;
        font-weight: 500;
        padding: 20px 75px;
    }
}


.training-event-pg-wrapper {
    .interior-pg-banner {
        min-height: 230px;
        margin: 0 0 50px 0;
        background-color: $color-primary;
    }
}

.training-event-two-speakers {

    .training-event-speaker {
        &:first-of-type {
            margin: 0 0 30px 0;
        }
    }

    .training-event-speaker-image-container {
        width: 33%;
        display: inline-block;
        vertical-align: middle;
    }

    .training-event-speaker-image {
        width: 125px;
        height: 125px;
    }

    .training-event-speaker-info-container {
        width: 66%;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }
}

.smc-training-list{
	border: none;
}
.smc-associated{
	.slick-prev{
		left: 0;
	}
	.slick-next{
		right: 0;
	}
	
	.btn-wrap {
		margin-top: auto;
		margin-bottom: 20px;
		.btn {
			width: 100%;
		}
	}
	.itemTitle{
		text-align: left;
	}
	
	&.slick-initialized {
		padding: 0 20px !important;
		margin: 0 !important;		
	}
}