﻿.saved-payments-container {

    .creditCardForm {
        padding: 0 0 25px 0;
        margin: 0 0 25px 0;
        border-bottom: 1px solid $color-border;
    }

    .form-card-num, .form-expire-month {
        padding-left: 0;
    }

    .billing-address-header {
        padding: 10px 0 20px 0;

        label {
            display: inline-block;
        }

        .btnAlt {
            float: right;
        }
    }
}