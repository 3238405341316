﻿.productListInner {
    &.brands {
        .itemWrapper {
            float: left;
            display: none;
            width: 33.333334%;

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                display: block;
            }

            @media (min-width: 980px) {
                width: 25%;

                &:nth-child(4) {
                    display: block;
                }
            }

            &.slick-slide {
                display: block;
                width: unset;
            }
        }
    }
}

.line-cards-wrapper {
    .rocModule-header {
        padding: 0 20px;
    }

    h2 {
        color: #0a4e8c;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    .line-card-table {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .line-card-btn-wrapper {
            margin: 0 0 15px 20px;

            &:first-of-type {
                margin: 0 0 15px 0;
            }
        }
    }
}


.manufacturers-info-wrapper {
    padding: 50px 20px 0 20px;

    h2 {
        color: #0a4e8c;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.jumpList-anchors {
    max-width: 2000px;
    width: 100%;
    margin: 0 auto;

    h2.title {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: #373737;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        margin: 0 auto 50px;
        padding: 0;

        .jumpList-anchorsItem {
            margin: 0 40px 0 0;
        }

    }
}

.jumpList {
    .jumpList-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #373737;
        font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0 auto 50px;
    }

    .jumpList-list {
        list-style: none;

        .jumpList-listItem {
            width: 33%;
            display: inline-flex;
            align-items: center;
            color: #373737;
            font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
            font-size: 20px;
            text-transform: uppercase;
            margin: 0 0 15px 0;
        }
    }

    .jumpList-collapse {
        &.collapse {
            display: block;
        }
    }
}