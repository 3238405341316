﻿.quick-order-pg-wrapper {

    .form-wrapper {
        .form-list-wrapper {
            width: 100%;
            margin: 0;
        }
    }

    .formee {
        input[type=text] {
            margin: 0 0 30px 0;
        }
    }

    .btnTextAlt {
        @include form-label-font;
    }
}