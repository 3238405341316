/* ##  Breadcrumb  ## */

.breadcrumbs {
	font-family: $font-stack;
    margin: 28px 0 10px;
    font-size: 16px;
	font-weight: 300;
	color: #767676;
	letter-spacing: 0;
	text-shadow: 1px 0 0 rgba(0, 77, 142, 0.4);	
	text-transform: none;
	@media (min-width:768px) and (max-width:979px) {
		margin-top: 15px;
	}
    a {
		display: inline;
		box-decoration-break: clone;
		transition: all 0.5s ease-in-out;
		position: relative;
		background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
		background-size: 0 1px;
		background-position: 0 100%;
		background-repeat: no-repeat;		
        margin: 0;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		text-transform: none;
		color: #004d8e;
		text-shadow: 1px 0 0 rgba(0, 77, 142, 0.4);	
        text-decoration: none;
        
        &:hover,
        &:active,
        &:focus {
			text-decoration: none;
			background-size: 100% 1px;	
        }
        
        &:first-child {
            margin-left: 0;
        }
    }
	
	.divider {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 6px;
		margin: 0 6px;	
		color: #0a4e8c;	
		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 9px;
			right: 0;
			margin: 0;
			vertical-align: middle;
			width: 0;
			height: 0;
			border: 0 solid transparent;
			border-bottom-width: 3px;
			border-top-width: 3px;
			border-left: 5px solid #004e8f;			
		}
		span {
			position: absolute;
			color: #0a4e8c;
			margin: 0;
			color: #0a4e8c;
			font-size: 18px;
			line-height: 14px;
			text-align: center;
    	}
	}
    
    .filterCrumbs {
        display: inline-block;
        margin-left: 1em;
    }
}

.filterCrumbs {
    font-size: 0.929em;
    
    a {
        font-weight: normal;
    }
    
    .clearLink {
        color: $color-action;
        font-weight: bold;
    }
    
    [class^="icon-"] {
        color: $color-action;
        font-size: 1.231em;
        vertical-align: text-bottom;
    }
}

.page-title-wrap {
	&+.breadcrumbs-wrap {
		.breadcrumbs {
			display: none;
		}
	}
}

/* 16px baseline (767px) */
@media (max-width:47.938em) {	

	.breadcrumbs {
		display: none;
//		a {
//			+a {
//				display: none !important;
//			}
//		}
//		.divider {
//			display: none;
//		}
	}
	
}

/*
.breadcrumbs {
	[title="Category"] {
		display: none;
		&+.divider {
			display: none;
		}
	}	
}
*/

