﻿.cart-pg-wrapper {

}

.cart-summary-wrapper {
    @include containers-w-margin;
}

.cart-order-tools-wrapper {
    margin: 0 0 40px 0;

    .btn {
        margin: 0 0 20px 0;

        &:last-of-type {
            margin: 0;
        }
    }
}

.cart-lists-wrapper {

}

.current-cart-container {
    @include border-default;
    padding: 0 20px;
}

.cart-summary-item-container {
    @include product-item-container;
    @include product-item;

    &:first-of-type {
        padding: 0;
        margin: 0;
        border: none;
    }

    .product-image, .product-content, .cart-summary-pricing-container {
        @include product-item-sections;
    }

    .product-image {
        @include product-item-image-section;
    }

    .product-content {
        @include product-item-content-section;
    }

    .cart-summary-pricing-container {
        @include product-item-action-section;
    }

    .product-sku {
        @include product-item-sku;
    }

    .product-title {
        @include product-item-title;
    }

    .product-delivery {
        @include product-item-delivery;

        span {
            @include product-item-delivery-span;
        }
    }

    .cart-summary-pricing-price {
        @include product-item-price;
    }

    .cart-summary-pricing-prev-price {

    }
}

.cart-help-wrapper {
    @include containers-w-margin;

    .rocWidget-header {
        padding: 0 0 20px 0;
        @include border-btm-default;

        .title {
            @include title-font-small;
        }
    }

    .rocWidget-help-item-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0 0 0;
		position: relative;
		@media(min-width:768px) {
			padding-bottom: 40px;
		}
		@media(max-width:767px) {
			flex-flow: column !important;
		}

        a {
            font-weight: 500;
        }
    }

    .rocWidget-help-item {
		@media(min-width:768px) {
			width: 33.333%;
			display: inline-flex;
			flex-wrap: wrap;
			padding: 0 20px 0 20px;
		}			
		@media(max-width:767px) {
			margin-bottom: 25px;
		}		
        @include border-right-default;
			@media(max-width:767px) {
				border-right: none !important;
			}		

        &:first-of-type {
            padding: 0;
        }
        &:last-of-type {
            border: none;
        }
		.rocWidget-help-item-inner {
			display: flex;
			margin: 0 auto;
			@media(max-width:767px) {
				display: table;
				margin-bottom: 15px;
			}				
			+.rocWidget-help-content {
				margin: 0 auto;
				width: 100%;
				a,
				button {
					&.actionLink {
						&.btn-brd {
							display: block;
							text-align: center;
							background-color: #fff;
							border: 2px solid #0A4E8C;
							margin: 0 auto;
							padding: 10px;
							width: 100%;
							max-width: 365px;
							font-size: 18px;
							font-weight: 700;
							line-height: 26px;	
							color: #0A4E8C;
							-webkit-transform: perspective(1px) scale(1.04);
							-ms-transform: perspective(1px) scale(1.04);
							transform: perspective(1px) scale(1.04);
							transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);	
							@media(min-width:768px) and (max-width:1279px) {
								font-size: 12px;
								line-height: 20px;
								max-width: 200px;
							}							
							&:after {
								display: none;
							}
							&:hover,
							&:focus {
								color: #fff;
								border: 2px solid rgba(228, 112, 30, 1);
								background-color: rgba(228, 112, 30, 1);
								-moz-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
								-webkit-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
								-o-box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);		
								box-shadow: 0 0 10px rgba(228, 112, 30, 0.5);
								-webkit-transform: perspective(1px) scale(1.08);
								-ms-transform: perspective(1px) scale(1.08);
								transform: perspective(1px) scale(1.08);								
							}
						}
					}
				}
				+.cart-ref-code-wrap {
					position: absolute;
					@media(min-width:768px) {
						left: 15px;						
						bottom: 0;
					}
					@media(max-width:767px) {
						left: 0;
						right: 0;
						bottom: -20px;
						width: 100%;
						text-align: center;
					}
					>* {
						display: inline-block;
						&:first-child {
							margin-right: 5px;		
						}
					}
				}
			}
		}
    }

    .rocWidget-help-icon {
        width: 25px;
        height: 100%;
        display: inline-flex;
        margin: 0 15px 0 0;
		@media(max-width:767px) {
			display: table-cell;
			vertical-align: middle;
		}		
		&.help-icon-contact-us {
			width: 40px;
			margin-top: 5px;
		}
        .icon {
            fill: $color-secondary;
            width: 25px;
            height: 25px;
			&.icon-contact-us {
				width: 40px;
				height: 20px;				
			}
        }
    }
	.rocWidget-help-content-wrapper {
		@media(max-width:767px) {
			display: table-cell;
			vertical-align: middle;
		}		
	}

    .rocWidget-help-title {
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
        margin: 0 0 15px 0;
		@media(max-width:767px) {
			margin-bottom: 0;
		}
    }

    .rocWidget-help-content {
        font-weight: 500;

        &.cart-ref-code {
            font-weight: 400;

            span {
                font-weight: 500;
            }
        }
    }

    .rocWidget-help-badges {
        width: 100%;
        text-align: right;
        padding: 40px 0 0 0;
		@media(min-width:768px) {
			padding-top: 20px;
		}		
    }
}



.cart-order-summary-wrapper {
    background-color: $light-gray;
    padding: 0;

    .cart-order-summary-title {
        @include title-font-small;
        background-color: $gray;
        padding: 20px;
    }

    .cart-order-summary-content-wrapper {
        padding: 20px;
    }

    .cart-order-summary-promo {
        margin: 0 0 20px 0;
    }

    .cart-order-summary-checkout-totals {
        padding: 20px 0 0 0;
        @include border-top-default;

        .costSummary {
            margin: 0 0 20px 0;

            .costRow {
                font-size: .9em;
                height: 30px;

                .title {
                    font-weight: 500;
                }
            }
        }
    }
}

/* Need Website Assistance PopUpCall Modal */
#NeedWebsiteAssistancePopUpCallModal {
	.mktoForm {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0 40px;
		width: 100% !important;				
		@media (max-width:767px) {
			display: block;
		}
		.mktoFormRow {
			margin-bottom: 15px;
			padding: 0;	
			@media (max-width:767px) {
				display: block;
				width: 100%;
				margin: 0 0 15px;
			}					
			/* IE10+ specific styles go here */
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				display: inline-block;
				width: 50%;
				padding: 0 10px;
			}	
			&:nth-of-type(1) {
				grid-column: 1/3;
			}
			&:nth-of-type(2) {
				display: none;
			}
		}
		.mktoButtonRow {
			grid-column: 1/3;
			width: 100%;		
			margin-top: 0 !important;
		}
		label {
			&.mktoLabel {
				font-size: 18px !important;
				font-weight: 700 !important;
				line-height: 18px !important;
				min-height: 33px;
				display: inline-block;
				position: relative;
				margin-bottom: 0 !important;
				width: auto !important;
				@media (max-width:980px) {
					font-size: 16px !important;
					line-height: 16px !important;
					min-height: 30px;
				}						
				.mktoAsterix {
					position: absolute;
					right: -13px;
					+b,
					+strong {
						display: inline-block;
						position: relative;
						margin-right: 0 !important;
						+span {
							font-size: 12px !important;
							font-weight: 500 !important;
							@media (max-width:980px) {
								display: block;
								margin-top: 5px;
							}
							+b,
							+strong {
								display: none;
							}
						}
					}
					+b {
						&:after {
							position: relative;
							right: 0;
						}
						+b {
							display: none;
						}
					}
				}

				strong {
				   font-family: "IBM Plex Sans",sans-serif !important;
					font-weight: 700 !important;
				}

				b {
				   font-family: "IBM Plex Sans",sans-serif !important;
					font-weight: 700 !important;
				}
			}
		}

		.mktoFormCol {
			margin-bottom: 20px !important;
		}

		.mktoField,
		.mktoLogicalField {
			border-radius: 0 !important;
			height: 48px !important;
		}

		.mktoButtonRow {
			width: 100%;
			display: table;
			justify-content: right;
			text-align: right;
			padding: 0;
			.mktoButtonWrap {
				&.mktoRound {
					.mktoButton {
						min-width: 174px;
						padding: 14px 25px 18px!important;
						font-family: "IBM Plex Sans",sans-serif!important;
						font-size: 16px!important;
						font-weight: 500!important;
						line-height: 16px!important;
						letter-spacing: 0!important;
						text-transform: none!important;
						color: #fff!important;
						background: #0a4e8c!important;
						border-color: #084174!important;
						border-radius: 0!important;
						box-shadow: 0 0 0 0 transparent!important;
						transition: all .3s;
						&:hover,
						&:focus {
							color: #fff!important;
							background: #e4701e!important;
							border-color: #e4701e!important;
							-moz-box-shadow: 0 0 10px rgba(0,0,0,.3)!important;
							-webkit-box-shadow: 0 0 10px rgba(0,0,0,.3)!important;
							-o-box-shadow: 0 0 10px rgba(0,0,0,.3)!important;
							box-shadow: 0 0 10px rgba(0,0,0,.3)!important;							
						}
					}						
				}
			}
			@media(max-width:767px) {
				justify-content: center;
				text-align: center;
			}
			div {
				text-align: right !important;						
			}
			span {
				margin-left: 0 !important;
				text-align: right !important;
			}

		}
	}
	.modal-body {
		.mktoForm {	
			.mktoFormCol {
				min-height: 0 !important;
				margin-bottom: 0 !important;
			}
			.mktoFormRow {
				.mktoFieldWrap {
					>.mktoHtmlText {
						&.mktoHasWidth {
							width: 100% !important;
						}
					}
				}
			}			
			>.mktoFormRow {
			
			}
		}
	}
}
