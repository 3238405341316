﻿.current-cart-summary-container, .saved-later-cart-summary-container {
    @include border-default;
    padding: 20px;
}

.cart-summary-recipient-wrapper {
    @include product-item-container;

    &:first-of-type {
        padding: 0;
        margin: 0;
        border: none;
    }
    &:last-of-type {
        padding: 0;
    }
}

.cart-summary-item-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .product-item {
        @include product-item;
        margin: 40px 0 0 0;

        &:first-of-type {
            margin: 0;
        }
    }

    .product-image, .product-content, .product-action {
        @include product-item-sections;
    }

    .product-image {
        @include product-item-image-section;
    }

    .product-content {
        @include product-item-content-section;
    }

    .product-restriction {
        @include product-item-restriction;
    }

    .product-action {
        @include product-item-action-section;
    }

    .product-sku {
        @include product-item-sku;
    }

    .product-title {
        @include product-item-title;
    }

    .product-delivery {
        width: 100%;
        @include product-item-delivery;

        span {
            @include product-item-delivery-span;
        }
    }

    .product-action {
        .product-delivery {
            @include product-item-delivery-action;
        }
    }

    .cart-summary-pricing-total {
        @include product-item-price;
        font-size: 1.5em;
        text-align: left;
        padding: 0 0 5px 0;

        span {
            @include product-item-price-span;
        }
    }

    .previous-price {
        @include product-item-price-prev;
    }

    .sale-price, .current-price {
        @include product-item-price-curr;
    }

    .cart-summary-price-unit {
        font-size: 1em;
        font-weight: 400;
        text-align: left;
        margin: 0 0 15px 0;

        .price {
            font-weight: 400;
        }
    }

    .cart-qty-label {
        width: 100%;
        font-weight: 500;
        margin: 0 0 7px 0;
    }

    .cart-summary-qty {
        @include product-item-qty;
    }

    .qty-increment {
        @include product-item-qty-increment;
        width: 100%;
        margin: 0;
    }

    .qty-decrease, .qty-increase {
        @include product-item-qty-btns;
    }

    input[type=number] {
        &.qty-amount {
            @include product-item-qty-amount;

            &::-webkit-inner-spin-button {
                @include product-item-qty-spinner;
            }
            &::-webkit-outer-spin-button {
                @include product-item-qty-spinner;
            }
        }
    }

    .order-qty, .ship-qty,
    .unit-price, .total-price {
        width: 100%;
        text-align: right;
        margin: 0 0 5px 0;

        span {
            font-weight: 600;
        }
    }

    .product-add-cart {
        width: 100%;
        padding: 0 0 15px 0;

        .btn, .btnAlt {
            width: 96%;
            margin: 0 7px;
        }
    }

    .product-unavailable {
        width: 100%;
        font-size: 1.15em;
        font-weight: 600;
        text-align: center;
        padding: 0 0 15px 0;
    }

    .product-move-item {
        width: 100%;
        padding: 0 0 15px 0;

        .move-item {
            width: 100%;
            padding: 10px;
        }
    }

    .btnText {
        width: 100%;
        color: $color-primary;
        font-weight: 600;
        text-align: left;
        padding: 5px;
        transform: none;
    }
}

.req-field {
    color: $red;
}


.saved-later-cart-summary-container {
    margin: 20px 0;

    .rocModule-cart {
        margin: 0;
    }

    .rocModule-header {
        padding: 0 0 20px 0;
        @include border-btm-default;

        .title {
            @include title-font-small;
        }
    }

    .cart-summary-item-container {
        &:first-of-type {
            padding: 10px 0 0 0;
        }
        &:last-of-type {
            padding: 20px 0 0 0;
        }

        .cart-qty-label {
            margin: 7px 0;
        }
    }
}