/* # Cart and Checkout Styles */

/* ## Cart Page Heading */

.cartHeading {
	margin: 0;
	border: none;
	.title {
		display: inline-block;
		margin-bottom: 10px;
	}
	.addon {
		display: inline-block;
		margin: 0 10px;
	}
	.btnProcess {
		min-width: 250px;
	}
}

ul.linkList.cart-links {
	margin: 10px -7px;
	font-size: 0.75em;
	@include clearfix;
	li {
		float: left;
		margin: 0;
		padding: 0 7px;
		&:not(:last-child) {
			border-right: 1px solid $color-border;
		}
	}
	a {
		font-weight: bold;
	}
	button {
		&.btn {
			color: #0a4e8c;
			text-decoration: none;
			text-align: right;
			display: inline;
			padding: 0;
			box-decoration-break: clone;
			transition: all 0.5s ease-in-out;
			position: relative;
			background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
			background-size: 0 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			&:hover,
			&:focus {
				background-color: transparent !important;
				box-shadow: none !important;
				text-decoration: none;
				color: #07335c;
				background-size: 100% 1px;
			}
		}
	}
}

.cartHeading-notification {
	margin-top: 10px;
	p:last-child {
		margin-bottom: 0;
	}
}

/* ## Cart */

.rocModule-cart {
    margin: 25px 0;

    .itemContent {
        text-align: left !important;
    }

    .rocModule-header {
        margin-bottom: 10px;
    }

    .cartList {
        margin: 0;
        padding: 0;
        border: none;
        border: 1px solid $color-border;

        a {
            &.btnTextAction {
                text-decoration: none;
                text-align: right;
                display: inline;
                padding: 0;
                box-decoration-break: clone;
                transition: all 0.5s ease-in-out;
                position: relative;
                background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
                background-size: 0 1px;
                background-position: 0 100%;
                background-repeat: no-repeat;

                &:hover,
                &:focus {
                    background-color: transparent !important;
                    box-shadow: none !important;
                    text-decoration: none;
                    color: #07335c;
                    background-size: 100% 1px;
                }
            }
        }

        .productListInner {
            margin: 0;
        }

        .itemWarning {
            border: 1px solid $color-error;
        }
    }

    .itemAddon {
        .btn {
            font-size: .875em;

            &.btnText {
                color: #0a4e8c;
                text-decoration: none;
                text-align: right;
                display: inline;
                box-decoration-break: clone;
                transition: all 0.5s ease-in-out;
                position: relative;
                background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.3), rgba(10, 78, 140, 0.3));
                background-size: 0 1px;
                background-position: 0 100%;
                background-repeat: no-repeat;

                &:hover,
                &:focus {
                    background-color: transparent !important;
                    box-shadow: none !important;
                    text-decoration: none;
                    color: #07335c;
                    background-size: 100% 1px;
                }
            }
        }
    }
}

.cartList {
	width: 100%;
	padding: 0;
	margin: 0;
	border: 0;
	>.media {
		display: table-cell;
		width: 30px;
		padding: 5px;
		text-align: center;
		vertical-align: middle;
		border-right: 1px solid $color-border;
	}
	.itemWrapper {
		flex-wrap: wrap;
		float: none;
		padding: 30px 0;
		@include lg {
			flex-wrap: nowrap;
		}
		&:not(:first-child) {
			border-top: 1px solid $color-border-light;
		}
		.roc-close {
			position: absolute;
			top: 2px;
			right: 2px;
			color: $color-action;
			font-size: 0.875em;
		}
		.media {
			display: block;
			width: 160px;
			min-width: 160px;
			margin-left: auto;
			margin-right: auto;
			@include lg {
				margin-left: 0;
				margin-right: 0;
			}
		}
		.itemAddon {
			@include sm {
				min-width: 160px;
				width: 160px;
			}
			.formee {
				margin-top: 0;
			}
		}
		.formee-lbl,
		.formee label {
			margin-top: 0;
			font-size: 0.875em;
			font-weight: bold;
		}
		>.itemContent {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 10px;
			width: 100%;
			@include lg {
				width: auto;
			}
			@include lg {
				flex-wrap: nowrap;
			}
			.itemImageWrapper {
				display: block;
				border: none;
				margin: 0 auto;
				.itemImage {
					border: 1px solid #bfbfbf;
				}
			}
			.itemDetails {
				display: block;
				padding-left: 10px;
				text-align: left;
				.itemTitle {
					margin: 0;
				}
				.itemSku {
					margin: 0;
				}
			}
		}
		>.modalButtons {
			.btn:not(:last-child) {
				margin-bottom: 10px
			}
		}
		>.itemShipTo {
			// width: 200px;
		}
		>.itemShipping {
			line-height: 1;
			.title {
				margin-bottom: 5px;
				font-size: 1em;
				font-weight: normal;
			}
			P {
				margin: 0 0 3px;
			}
			a {
				font-size: 0.838em;
			}
		}
		>.itemQty {
			// width: 110px;
			.btnText {
				font-size: 0.838em;
				font-weight: normal;
				text-decoration: underline;
			}
		}
		>.itemPrice {
			// width: 200px;
			text-align: right;
			>.itemPrice {
				font-size: 0.825em;
			}
		}
		>.itemTotal {
			// width: 116px;
			text-align: right;
			>.itemPrice {
				line-height: 1;
				margin: 3px 0 7px;
			}
			.btnText,
			.btnTextAction {
				font-weight: normal;
				font-size: 0.875em;
				margin-bottom: 10px;
			}
			.add-to-cart {
				margin-top: 5px;
			}
			.btnAddToCart {
				font-weight: bold;
				font-size: 1.125em;
			}
		}
		>*:last-child {
			padding-right: 20px;
			text-align: right;
		}
		@include xs {
			margin: 0;
			padding: 20px 10px;
			>.itemAddon {
				padding: 0;
			}
			>.itemShipTo {
				width: auto;
			}
			>.itemPrice {
				display: block;
				margin: 10px 0;
				padding: 0;
				text-align: center;
			}
			>.itemQty {
				display: block;
				margin: 10px 0;
				text-align: center;
			}
			>.itemTotal {
				display: block;
				margin: 10px 0;
				text-align: center;
			}
			.delivery-option-list {
				margin: 5px 0;
				padding: 5px 10px;
				background-color: $gray;
				border: 1px solid $color-border;
			}
		}
	}
	&:not(:last-child) {
		border-bottom: none;
	}
	&+.checkoutSummary {
		border-top: none;
	}
}

.rocModule-cart-recipient {
	margin: 0 !important;
	background-color: $gray;
	>.rocModule-content {
		padding: 20px;
		border: 1px solid $color-border;
	}
	.infoList:first-child {
		margin-top: 0;
		text-align: left;
	}
	.infoList:last-child {
		margin-bottom: 0;
		text-align: left;
	}
	&+.rocModule-cart {
		margin-top: -1px !important;
	}
}

/* ## item notification */

.itemNotification {
	margin: 0 20px;
	padding: 12px 0;
	border-top: 1px solid $color-border-light;
	p:last-child {
		margin: 0;
	}
	.itemPromo-sale {
		color: #f57e1e;
		.actionLink {
			margin-left: 10px;
		}
	}
	a {
		font-size: 0.875em;
	}
	>.contentBox:last-child {
		margin-bottom: 0;
	} // 767px 
	@media(max-width:47.938em) {
		margin: 0 10px;
	}
}

.itemShipTo {
	cursor: pointer;
}

.itemWrapper {
	&.roc-dismissable {
		.roc-close {
			margin-right: 10px;
		}
	}
}

.checkoutSummary .itemNotification {
	margin: 0;
	padding-top: 0;
	border: none;
}

/* ## Checkout Summary */

.checkoutSummary {
	position: relative;
	margin: 0;
	border: 1px solid $color-border;
	&:first-child {
		border-top: none;
	}
	>.title {
		margin: 0;
		padding: 5px 7px;
		font-weight: bold;
		color: #fff;
		background: #3f3f3f;
		font-size: 1em;
	}
	>.content {
		padding: 20px;
		&:first-child {
			font-size: 0.875em;
		}
	}
	.checkoutTotals {
		background-color: $gray;
		border-top: 1px solid $color-border;
	}
	.shippingMethod {
		margin: 10px 0;
		padding: 10px 0;
		border-top: 1px solid $color-border;
		border-bottom: 1px solid $color-border;
		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	.checkoutButtons {
		text-align: center;
		.or {
			margin: 10px 0;
			color: $color-text-secondary;
			font-size: 0.875em;
		}
	}
}

.costSummary {
	display: table;
	width: 100%;
	margin: 0 0 10px;
	.costRow {
		display: table-row;
		font-size: 0.875em;
	}
	.title {
		display: table-cell;
		padding: 5px 0 0;
		text-align: left;
		font-weight: bold;
		font-size: 1em;
		text-transform: none;
		vertical-align: middle;
		a {
			display: inline-block;
			font-size: 0.8em;
		}
	}
	.price {
		display: table-cell;
		padding: 5px 0 0 10px;
		text-align: right;
		vertical-align: middle;
	}
	.totalPrice {
		font-size: 0.938em;
		font-weight: bold;
	}
}

/* ## Shipping Method */

.shippingMethod {
	legend {
		font-weight: normal;
	}
	.formee-list {
		font-size: 0.938em;
		li {
			position: relative;
			margin: 10px 0;
			padding-left: 20px;
			input[type="radio"] {
				position: absolute;
				left: 0;
				top: 3px;
			}
		}
		label {
			display: block;
			margin: 0;
			>strong {
				display: block;
				font-size: 1.115em;
			}
		}
		.shipCost {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

/* ## Promos Applied List */

.promosApplied {
	li {
		margin: 5px 0;
		color: #f57e1e;
	}
}

.shiphdng {
	margin: 0 0 10px 0;
	padding: 5px;
	font-weight: bold;
	background-color: #efefef;
	border: 1px solid #D2D2D2;
}

.shipAddr {
	border-width: 1px 1px 0 1px;
	border-style: solid;
	border-color: #999999;
}

.shipAddr table td {
	padding: 5px 10px 5px 3px;
}

.cart {
	width: 100%;
	margin: 0 auto 5px auto;
	border-collapse: collapse;
}

.cartInfo {
	height: 1%;
	margin: 0 auto;
}

.cartInfo .promoCode {
	margin-bottom: 10px;
	padding: 15px 10px 10px;
	border: 1px solid #D2D2D2;
}

.cartInfo .promoCode input.select {
	width: 117px;
	background-color: #f7f7f7;
}

.recipientSummary {
	margin-bottom: 20px;
	border-bottom: 2px solid #000;
}

.recipientSummary .costSummary {
	border: 0;
	font-size: 0.9em;
}

.recipientSummary .costSummary .price {
	padding-right: 0;
}

.shipData {
	position: absolute;
	display: none;
	left: -5px;
	right: -5px;
	margin-top: -1px;
	padding: 20px 15px;
	margin-bottom: 2px;
	border: 1px solid #3f3f3f;
	background: #fff;
	z-index: 2;
	-webkit-box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.25);
	box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.25);
}

.shipData .icon-cancel-circle {
	position: absolute;
	top: -4px;
	right: -4px;
	font-size: 1.5em;
	cursor: pointer;
}

.shipData .shipRow {
	height: 1%;
	padding: 10px 0 0 0;
}

.shipData .shipTitle {
	float: left;
	display: block;
	width: 95px;
	margin-top: 3px;
}

.shipData .icon-close {
	position: absolute;
	right: 3px;
	top: 3px;
	cursor: pointer;
}

.slideCartCompanion {
	.rocModule,
	.rocWidget {
		margin: 25px 0;
		&:first-child {
			margin-top: 0;
		}
	}
}

/* ## Sliding Cart Summary */

.slideCartRail {
	position: relative;
}

.rocModule-slideCart {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	.rocModule-header {
		margin-bottom: 6px;
		>.title {
			font-size: 1.250em;
		}
	}
}

/* 16px baseline (0 - 767px) */

@media only screen and (max-width: 47.938em) {
	.slideCartRail {
		min-height: 0 !important;
	}
	.slideCartCompanion {
		min-height: 0 !important;
	}
}

/* 16px baseline (768px+) */

@media only screen and (min-width: 48em) {
	.slideCartWrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	.slideCartCompanion {
		.rocModule-miniList {
			.gridViewAlt {
				.itemWrapper {
					width: 24%;
				}
			}
		}
	}
	.rocModule-slideCart {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		max-width: 320px;
	}
	.slideCartRail .affix {
		position: fixed;
		left: auto;
		right: auto;
		width: 33.333%;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: -10px;
	}
	.slideCartRail .affix-bottom {}
}

/* one page checkout */

.memberBar {
	min-height: 1px;
	margin: 0 0 7px 0;
	padding: 5px;
	background: #ECF1F8;
}

.memberBar .hdng {
	margin: 0;
	padding: 0 10px;
	line-height: 30px;
	border: 0;
	border-right: 1px solid #fff;
}

.memberBar .memberBarInner {
	padding: 3px 15px 0 15px;
	text-align: right;
}

.memberBar label {
	margin-right: 2px;
	font-weight: bold;
	vertical-align: baseline;
}

.memberBar span.btnWrapper {
	margin-right: 10px;
}

.useSavedAddress {
	padding: 4px;
	font-size: .9em;
	color: #4e4e4e;
	background: #e2e2e2;
}

.useSavedAddress label {
	margin: 0 !important;
	cursor: pointer;
}

.useSavedAddress input {
	margin-right: 5px;
	vertical-align: middle;
}

.onePageCheckout {
	position: relative;
}

.onePageCheckout .checkoutSteps {
	margin-right: 300px;
}

dl.checkoutProcess {
	min-height: 1px;
	margin: 0 0 9px 0;
}

dl.checkoutProcess dt.stepTitle {
	border: 1px solid #3f3f3f;
}

dl.checkoutProcess dt.stepTitle span.stepTitleInner {
	position: relative;
	display: block;
	padding: 7px 65px 7px 10px;
	color: #fff;
	font-size: 0.875em;
	background-color: #3f3f3f;
}

dl.checkoutProcess dt.inactive {
	margin-bottom: 1px;
	border: 1px solid #d2d2d2;
}

dl.checkoutProcess dt.inactive span.stepTitleInner {
	color: #707070;
	background-color: #e3e3e3;
}

dl.checkoutProcess dt.previous {
	margin-bottom: 1px;
	border: 1px solid #d2d2d2;
}

dl.checkoutProcess dt.previous span.stepTitleInner {
	color: #707070;
	background-color: #e3e3e3;
}

dl.checkoutProcess dd.stepContent {
	margin: 0 0 1px 0;
	padding: 20px 10px;
	border: 1px solid #d2d2d2;
	border-top: 0;
}

dl.checkoutProcess .stepGroupTitle {
	margin-bottom: 10px;
	padding: 5px 10px;
	text-align: left;
	background-color: #E1E1E1;
	border: 1px solid #ccc;
}

dl.checkoutProcess .stepGroupTitle .hdng {
	margin: 0;
	font-size: 18px;
	border: 0;
	font-weight: bold;
}

dl.checkoutProcess .stepGroupTitle .formRow {
	float: right;
	display: inline;
	margin-top: 5px;
}

dl.checkoutProcess .stepGroupContent {
	margin-bottom: 30px;
	padding: 0 10px;
}

dl.checkoutProcess .stepGroupContent .accountBlocks {
	margin: 0;
	border: 0;
}

dl.checkoutProcess .stepGroupTitle .hdng span {
	font-size: 0.786em;
	font-weight: normal;
	text-transform: none;
}

dl.checkoutProcess .stepGroupTitle .hdng .formRow {
	margin: 0;
	font-size: 0.857em;
	font-weight: normal;
}

.checkoutProcess .forms fieldset {
	position: relative;
	margin: 0 0 10px 130px;
}

.checkoutProcess .forms fieldset legend {
	position: absolute;
	left: -130px;
	top: 0;
	width: 110px;
	color: #4E4E4E;
	font-size: 17px;
	font-weight: normal;
	white-space: normal;
}

.checkoutProcess .forms fieldset legend span {
	float: left;
}

.checkoutProcess .forms fieldset legend span.note {
	float: none;
	font-size: 11px;
}

.checkoutCartInner {
	margin: 0 0 2px 0;
}

.checkoutCart .cartItems {
	padding: 1px 10px;
}

.checkoutCart .itemWrapper {
	padding: 10px 0;
	border-top: 1px dashed #ccc;
}

.checkoutCart .itemWrapper:first-child {
	border-top: 0;
}

.checkoutCart .itemImage {
	float: left;
	display: inline;
	height: auto;
}

.checkoutCart .itemContent {
	position: static;
	padding: 0;
	text-align: left;
	font-size: 1em;
}

.checkoutCart .itemContent .itemTitle {
	float: left;
	width: 58%;
	margin: 0;
	text-align: left;
	font-size: 0.875em;
	font-weight: normal;
}

.checkoutCart .recipHeader {
	padding: 10px 0 0 0;
	border-bottom: 1px dotted #ccc;
}

.checkoutCart .itemWrapper .itemSwatch {
	float: left;
	display: inline;
	margin: 7px 0 12px 0;
}

.checkoutCart .itemWrapper .itemPrice {
	float: right;
	display: inline;
	width: 40%;
	margin: 0;
	font-size: 1em;
	text-align: right;
	font-weight: normal;
}

.checkoutCart .chkoutBoxes .chkoutRow .title {
	width: 160px;
}

.checkoutCart .chkoutBoxes .chkoutRow .price {
	width: 105px;
}

.siteStamps {
	margin: 10px 0;
}

.siteStamps img {
	vertical-align: middle;
}

dl.checkoutProcess dt.stepTitle a.editLink,
dl.checkoutProcess dt.stepTitle a.editLink:link,
dl.checkoutProcess dt.stepTitle a.editLink:visited {
	position: absolute;
	right: 10px;
	top: 8px;
	font-size: 1em;
	font-weight: bold;
	text-decoration: none;
	text-transform: none;
}

.checkoutCart .cartHeader a.editLink,
.checkoutCart .cartHeader a.editLink:link,
.checkoutCart .cartHeader a.editLink:visited {
	position: absolute;
	right: 10px;
	top: 8px;
	font-size: 0.875em;
	font-weight: bold;
	text-decoration: none;
	text-transform: none;
	color: #fff;
}

.dateSelection .selector {
	float: left;
	display: inline;
	width: 90px;
	margin-right: 10px;
}

.dateSelection div.selector span {
	width: 60px;
}

.dateSelection div.selector select {
	width: 97px;
}

.checkoutProcess .supportedPayments {
	margin: 0 0 10px;
}

.userMessage {
	width: 46%;
	padding: 10px 15px;
	margin-bottom: 10px;
	background: #E0E0E0;
	border: 1px solid #c7c7c7;
}

.helpfulInfo {
	font-size: 14px;
	color: #4e4e4e;
	line-height: 1.7em;
}

.helpfulInfo .hdng2 {
	margin: 0;
	font-size: 18px;
	font-weight: normal;
	color: #4e4e4e;
}

.cartQuestionsText {
	float: left;
	display: inline;
	width: 255px;
	padding: 0 20px;
}

.helpLinks {
	float: left;
	display: inline;
	width: 320px;
}

.helpLinks ul.linkList {}

.helpLinks ul.linkList li {
	float: left;
	display: inline;
	width: 140px;
	margin: 0 10px 0 0;
	padding: 0 0 0 10px;
	background: url(../../images/layout/arrow_right_red_open.gif) no-repeat 0 8px;
}

.helpLinks ul.linkList a,
.helpLinks ul.linkList a:link,
.helpLinks ul.linkList a:visited {
	color: #4e4e4e;
	text-decoration: none;
}

.shippingOptions {
	margin-bottom: 7px;
	font-size: 0.9em;
}

.shippingOptions .shipOpt {
	float: left;
	display: inline;
	width: 178px;
	margin: 0 5px 5px 0;
	padding: 5px 10px 5px 5px;
}

.shippingOptions .shipOpt label {
	margin: 0;
	cursor: pointer;
}

.shipping-selected {
	background-color: #EFEFEF;
}

.checkoutSummaryHeading {
	margin-bottom: 5px;
	padding-bottom: 5px;
	font-weight: bold;
	border-bottom: 1px solid #CCCCCC;
}

.checkoutBox {
	padding: 20px 9px;
	background: #efefef;
	border: 1px solid #e3e3e3;
}

.checkoutSubBox {
	padding: 10px;
	background: #F9F9F9;
	border: 1px solid #e3e3e3;
}

/* ## Checkout Review ## */

.billingEmail {
	word-wrap: break-word;
}

.checkoutReviewTotals {
	text-align: center;
}

.checkoutReviewTotals .reviewTotalBreakdown {
	display: inline-block;
	text-align: right;
}

.checkoutReviewTotals .grandTotal {
	font-size: 1.2em;
	font-weight: bold;
}

.reviewOptions {
	border-top: 1px dashed #999;
	padding-top: 20px;
}

#termsAndConditionsLabel {
	display: none;
}

.termsAndConditionsModalWrapper {
	padding: 15px;
	p {
		&.please-allow {
			font-size: 16px;
			font-weight: 400;
			color: #333;
			margin-bottom: 10px;
		}
	}
}
.login-form-existing-account {
	p {
		&.please-allow {
			font-size: 16px;
			font-weight: 400;
			color: #333;
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
}

.termsAndConditionsModalLink {
	position: relative;
	padding-left: 17px;
	color: $blue;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 4px;
		width: 12px;
		height: 12px;
		border: 1px solid darken($gray, 20%);
		border-radius: 2px;
		background-color: $gray;
	}
}

.termsAndConditionsModalLink-2 {
	display: none;
	padding-left: 17px;
	color: $blue;
}

/* ## Checkout Specific */

.roc-checkout {
	.formee {
		label,
		.formee-lbl {
			font-size: 0.875em;
		}
	}
	.panel-heading {
		h2 {
			&.panel-title {
				margin: 0;
				padding-left: 10px;
				padding-right: 10px;
				color: #fff !important;
			}
		}
	}
	.panel-body {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.panel-group {
		.panel-heading {
			@media (min-width: 48em) {
				padding-left: 10px !important;
				padding-right: 10px !important;
			}
		}
	}
}

.btn[disabled] {
	&:hover,
	&:focus {
		-moz-box-shadow: 0 0 0 rgba(0, 0, 0, .3);
		-webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .3);
		-o-box-shadow: 0 0 0 rgba(0, 0, 0, .3);
		box-shadow: 0 0 0 rgba(0, 0, 0, .3);
	}
}

.checkoutSummary {
	.itemWrapper {
		.itemContent {
			text-align: left;
		}
	}
}


#cartSavedItems {

    .rocModule-header {
        .title {
            font-size: 1.2em;
        }

        span {
            color: #e4701e;
            font-weight: 600;
        }
    }

    .rocModule-content {
        .cartList {

            .itemContent {

                .itemDetails {
                    .itemTitle {
                        /*font-size: 1.25em;
                        text-transform: none;
                        margin: 0 0 10px 0;

                        a {
                            font-weight: 700;
                        }*/
                    }

                    .currentShippingMethodWrapper {
                        margin: 1em 0;

                        @media(max-width:1200px) and (min-width:767px) {
                            margin: 1em 0 0 0;
                        }
                    }
                }
            }

            .itemTotal {
                width: 18%;
                min-width: 200px;
                min-height: auto !important;

                @media(max-width:1200px) {
                    width: 95%;
                    min-width: 95%;
                    border-bottom: 1px solid #d7d7d7;
                    padding: 0 10px 10px 10px;
                }
                @media(max-width:767px) {
                    text-align: right;
                }

                .itemPrice {
                    text-align: right;

                    .price {
                        font-size: 1.3em;
                    }
                }

                .buttons-wrapper {
                    .btnTextAction {
                        width: 100%;
                        text-align: right;
                        padding: 0;
                        margin: 10px 0 0 0;

                        &:hover, &:focus {
                            color: darken(#0a4e8c, 10%);
                        }
                    }

                    .add-to-cart {
                        margin: 0;
                    }
                }
            }

        }
    }

    .saved-items-backorder-info {
        margin: 0 -20px;
        clear: both;
    }
}

/* 16px baseline (0 - 767px) */

@media only screen and (max-width: 47.938em) {
	.checkoutReviewTotals {
		border-top: 1px dashed #999;
	}
	.reviewTotalBreakdown {
		display: inline-block;
		padding-top: 10px;
	}
}

/* 16px baseline (960px+) */

@media only screen and (min-width: 60em) {
	.checkoutReviewTotals {
		border-top: 1px dashed #999;
	}
	.reviewTotalBreakdown {
		padding-top: 10px;
	}
}

.title-banner-checkout {
	&.title-banner {
		margin-top: -1px;
		margin-bottom: 40px;
		>.banner-title {
			max-width: 1350px !important;
		}
		h1 {
			&.banner-title {
				padding-left: 0 !important;
				padding-right: 0 !important;	
				font-family: Montserrat,Arial,sans-serif !important;						
				font-size: 44px !important;
			}			
		}
	}
}

.opacity-hidden {
	display: block;		
	margin: 10px 0 10px;
	opacity: 0;
	font-size: 18px;
	visibility: hidden;
	@media(min-width: 768px) and (max-width: 979px) {
		font-size: 15px;
	}
	@media(max-width: 767px) {
		display: none;
	}	
}

.container-checkout-wrapper {
	@include container-checkout;
	margin-bottom: 40px;

	.formee-req:before,
	.formee-req[title]:before {
		font-size: 15px;
		vertical-align: top;
	}

	.formee {
		select {
			height: 50px;
		}
	}

	.item,
	.itemInterior,
	.content {
		@media(max-width: 480px) {
			height: auto !important;
		}
	}

	.panel-primary {
		border-color: #eaeaea;
	}

	.panel {
		background-color: #fff;
		border: 1px solid #eaeaea;
	}

	> .row {
		> .col-sm-8 {
			@media(min-width: 768px) {
				width: 69.7166666667% !important;
			}

			@media(max-width: 767px) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}

		> .col-sm-4 {
			@media(min-width: 768px) {
				width: 30.2843333333% !important;
			}

			@media(max-width: 767px) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}

		.col-sm-3,
		.col-sm-4,
		.col-sm-5 {
			@media(max-width: 767px) {
				padding-left: 0 !important;
				padding-right: 0 !important;

				&.form-security-code {
					padding-left: 10px !important;
				}
			}

			@media(min-width: 768px) and (max-width: 979px) {
				&.form-expire-year {
					padding-left: 5px !important;
					padding-right: 5px !important;
				}

				&.form-expire-month {
					padding-right: 5px !important;
				}

				&.form-security-code {
					padding-left: 5px !important;
				}
			}
		}
	}

	.roc-checkout {
		font-family: "IBM Plex Sans",sans-serif;
		color: #333;

		.formWrapper {
			&.shipping {
				.row {
					@media(min-width: 980px) {
						margin-left: -10px !important;
						margin-right: -10px !important;
					}
				}
			}
		}

		#po-number {
			.panel-body {
				padding: 0 40px !important;
			}

			label[for="poNumber"] {
				padding: 0 40px;
			}

			@media(max-width: 979px) {
				.panel-body {
					padding: 0 20px !important;
				}

				label[for="poNumber"] {
					padding: 0 20px;
				}
			}
		}

		.creditCardForm {
			+ hr {
				display: none;
			}
		}

		label {
			&[for="overrideTaxExemption"] {
				margin: 0 !important;
				padding-left: 5px;
				font-size: 16px;
				font-weight: 400;
			}
		}

		.panel-group {
			> .panel-primary {
				border: 1px solid #eaeaea;
			}

			> .panel {
				background-color: transparent;
			}

			.panel-heading {
				padding-left: 0 !important;
				padding-right: 0 !important;

				&.panel-heading-blue {
					background-color: #0a4e8c !important;
				}
			}

			.panel-order-notes {
				border-bottom: none !important
			}

			[data-roc-wrapper="ordernotes-step"] {
				border: none !important;
				background-color: transparent !important;

				.panel-heading {
					&.panel-sub-heading {
						padding: 0 !important;

						label {
							display: block;
							height: auto;
							margin: 0 0 10px;
							font-family: "IBM Plex Sans",sans-serif;
							font-size: 18px;
							font-weight: 600;
							color: #333;

							@media(min-width: 768px) and (max-width: 979px) {
								font-size: 15px;
							}
						}
					}
				}

				textarea {
					border: 1px solid #ccc !important;
				}
				//				.panel-body {
				//					padding-bottom: 25px !important;
				//				}
			}

			[data-roc-wrapper="terms-and-conditions-step"] {
				border-top: none !important;
				border-bottom: none !important;

				> .termsAndConditionsModalWrapper {
					padding: 15px 39px 30px;

					@media(max-width: 979px) {
						padding-left: 22px !important;
						padding-right: 22px !important;
					}

					.termsAndConditionsModalLink,
					[for="checkout_termsAndConditions"] {
						margin: 0 !important;
						padding-left: 0;
						font-size: 16px;
						font-weight: 400;
						color: #333;
					}

					.termsAndConditionsModalLink {
						padding-left: 20px;
					}

					.termsAndConditionsModalLink-2 {
						span {
							display: inline;
							box-decoration-break: clone;
							transition: all .5s ease-in-out;
							position: relative;
							background-image: linear-gradient(to bottom,rgba(10, 78, 140,.6),rgba(10, 78, 140,.6));
							background-size: 0 1px;
							background-position: 0 100%;
							background-repeat: no-repeat;
							text-decoration: none;
							font-size: 16px;
							font-weight: 500;
							color: #0a4e8c;
						}

						&:hover,
						&:focus {
							span {
								background-size: 100% 1px !important;
							}
						}
					}
				}
			}

			[data-roc-wrapper="ponumber-step"] {
				border-top: none !important;
				border-bottom: none !important;

				.panel-heading {
					&.panel-sub-heading {
						padding: 0 !important;

						label {
							display: block;
							height: auto;
							margin: 0 0 10px;
							font-family: "IBM Plex Sans",sans-serif;
							font-size: 18px;
							font-weight: 600;
							color: #333;

							@media(min-width: 768px) and (max-width: 979px) {
								font-size: 15px;
							}
						}
					}
				}
			}
		}

		#placeOrderBtns {
			padding: 5px 39px 20px;
			border-left: 1px solid #eaeaea;
			border-right: 1px solid #eaeaea;
			border-bottom: 1px solid #eaeaea;

			@media(max-width: 979px) {
				padding-left: 22px !important;
				padding-right: 22px !important;
			}

			.checkout-btn-wrapper {
				.btnProcess {
					background-color: #e4701e;
					font-weight: 600;
					text-transform: none;
				}
			}
		}

		.panel-heading {
			h2 {
				&.panel-title {
					font-family: Montserrat,Arial,sans-serif;
					font-size: 24px;
					font-weight: 500;
					line-height: 30px;
					text-transform: none;
					padding-left: 22px;
					padding-right: 22px;
				}
			}
		}

		.panel-body {
			padding: 39px !important;

			@media(max-width: 979px) {
				padding-left: 22px !important;
				padding-right: 22px !important;
			}

			#shipping-address-form {
				> .panel-heading {
					visibility: hidden;
					margin: 0;
					padding: 0;
					position: absolute;
				}
			}

			#pickup-location {
				> .panel-heading {
					display: none !important;
				}

				.pickup-methods-wrapper {
					display: table;
					width: 100%;
				}
			}

			> .panel {
				background-color: transparent;
				border: none;

				.panel-body {
					padding: 0 !important;

					.itemList {
						margin: 0 !important;

						.interiorList {
							display: grid;
							grid-template-columns: 1fr 1fr 1fr;
							grid-gap: 20px;
							margin: 0;
							width: 100%;

							@media(min-width: 768px) and (max-width: 979px) {
								grid-template-columns: 1fr 1fr;
							}

							@media(min-width: 481px) and (max-width: 759px) {
								grid-template-columns: 1fr 1fr;
							}

							@media(max-width: 480px) {
								grid-template-columns: 1fr;
								grid-gap: 15px;
							}

							> .item {
								display: inline-block !important;
								flex-wrap: unset !important;
								width: 100% !important;
								margin: 0 !important;
								padding: 17px 20px 20px !important;
								border: 1px solid #eaeaea;
								font-size: 16px;

								.itemContent {
									font-family: "IBM Plex Sans",sans-serif;
									color: #333;
									padding: 0;

									.itemHeader {
										.title {
											font-size: 18px;
											font-weight: 600;
											color: #333;
										}
									}
								}

								.content-in {
									margin-bottom: 20px;
								}

								.bottom-cap {
									display: table;
									width: 100%;

									> .btn {
										display: inline-block;
										position: relative;
										font-size: 16px;
										font-weight: 600;
										text-transform: capitalize;
										color: #0a4e8c;
										background-color: #fff;
										border: 1px solid #0a4e8c;
										padding: 14px 20px;

										@media(max-width: 979px) {
											padding-left: 10px;
											padding-right: 10px;
										}

										&[data-roc-action="btnChecked"] {
											padding-left: 25px;
											background-color: #0a4e8c;
											border: 1px solid #0a4e8c;
											color: #fff;

											&:before {
												content: "\e618";
												font-family: 'ROC-Icons';
												speak: none;
												font-style: normal;
												font-weight: normal;
												font-variant: normal;
												text-transform: none;
												line-height: 1.42857;
												/* Better Font Rendering =========== */
												-webkit-font-smoothing: antialiased;
												-moz-osx-font-smoothing: grayscale;
												display: inline-block;
												position: absolute;
												margin-left: -23px;
											}
										}

										&:hover,
										&:focus {
											background-color: #e4701e;
											color: #fff;
										}
									}
								}
							}
						}
					}
				}
			}

			.panel-heading {
				&.saved-credit-cards {
					margin-top: 15px;

					h2 {
						&.panel-title {
							position: relative;
							display: block;
							font-family: Montserrat,Arial,sans-serif;
							font-size: 24px;
							font-weight: 500;
							line-height: 30px;
							text-transform: none;
							padding: 0 0 22px;
							color: #333 !important;
							cursor: default;

							&:after {
								content: '';
								position: absolute;
								display: block;
								background-color: #eaeaea;
								left: 0;
								right: 0;
								bottom: 0;
								width: 100%;
								height: 1px;
							}
						}
					}
				}
			}

			#methodsList {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-gap: 20px;
				margin: 0;
				width: 100%;

				@media(min-width: 768px) and (max-width: 979px) {
					grid-template-columns: 1fr 1fr;
				}

				@media(min-width: 481px) and (max-width: 759px) {
					grid-template-columns: 1fr 1fr;
				}

				@media(max-width: 480px) {
					grid-template-columns: 1fr;
					grid-gap: 15px;
				}

				> .item {
					display: inline-block !important;
					flex-wrap: unset !important;
					width: 100% !important;
					margin: 0 !important;
					padding: 17px 20px 20px !important;
					border: 1px solid #eaeaea;
					font-size: 16px;

					.itemButton {
						display: table;
						width: 100%;

						> .btn {
							display: inline-block;
							position: relative;
							font-size: 16px;
							font-weight: 600;
							text-transform: capitalize;
							padding: 14px 20px;
							color: #0a4e8c;
							background-color: #fff;
							border: 1px solid #0a4e8c;

							@media(max-width: 979px) {
								padding-left: 10px;
								padding-right: 10px;
							}

							&[data-roc-action="btnChecked"] {
								padding-left: 25px;
								background-color: #0a4e8c;
								border: 1px solid #0a4e8c;
								color: #fff;

								&:before {
									content: "\e618";
									font-family: 'ROC-Icons';
									speak: none;
									font-style: normal;
									font-weight: normal;
									font-variant: normal;
									text-transform: none;
									line-height: 1.42857;
									/* Better Font Rendering =========== */
									-webkit-font-smoothing: antialiased;
									-moz-osx-font-smoothing: grayscale;
									display: inline-block;
									position: absolute;
									margin-left: -23px;
								}
							}

							&:hover,
							&:focus {
								background-color: #e4701e;
								color: #fff;
							}
						}
					}
				}

				margin-top: 15px;
			}

			#pickup-form {
				margin-top: 15px;

				h2 {
					&.panel-title {
						position: relative;
						display: block;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 24px;
						font-weight: 500;
						line-height: 30px;
						text-transform: none;
						padding: 0 0 22px;
						color: #333 !important;
						cursor: default;

						&:after {
							content: '';
							position: absolute;
							display: block;
							background-color: #eaeaea;
							left: 0;
							right: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
						}
					}
				}

				.formee {
					> .row {
						@media(min-width: 980px) {
							margin-left: -10px !important;
							margin-right: -10px !important;
						}

						> .col-ms-6 {
							margin-bottom: 15px !important;
						}

						input {
							height: 50px;
							margin-bottom: 0 !important;
						}
					}
				}
			}

			#billing-address-form {
				margin-top: 10px;

				.billing-address-header {
					> label {
						//								margin: 0;
						//								padding: 0;
						//								font-size: 0;
						//								visibility: hidden;
						position: relative;
						display: block;
						font-family: "IBM Plex Sans",sans-serif;
						font-size: 24px;
						font-weight: 500;
						line-height: 30px;
						text-transform: none;
						padding: 0 0 22px;
						color: #333 !important;
						cursor: default;
						margin-bottom: 30px;

						&:after {
							content: '';
							position: absolute;
							display: block;
							background-color: #eaeaea;
							left: 0;
							right: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
						}
					}

					button {
						&.btnAlt {
							position: relative;
							background-color: #dfdfdf;
							text-align: left;
							width: 100%;
							padding: 20px 20px;
							font-family: "IBM Plex Sans",sans-serif;
							font-size: 24px;
							font-weight: 500;
							color: #333;
							line-height: 24px;

							&:before {
								content: '';
								display: inline-block;
								position: absolute;
								top: 30px;
								right: 14px;
								margin: 0;
								width: 14px;
								height: 0px;
								border-bottom: 3px solid #2b2b2b;
								transform: rotate(90deg);
								transition: width 0.3s;
							}

							&:after {
								content: '';
								display: inline-block;
								position: absolute;
								top: 30px;
								right: 14px;
								margin: 0;
								width: 14px;
								height: 0px;
								border-bottom: 3px solid #2b2b2b;
								transform: rotate(0deg);
								transition: width 0.3s;
							}

							&:hover,
							&:focus {
								-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
								-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
								-o-box-shadow: 0 0 10px rgba(228,112,30,0);
								box-shadow: 0 0 10px rgba(228,112,30,0);
								-webkit-transform: perspective(1px) scale(1);
								-ms-transform: perspective(1px) scale(1);
								transform: perspective(1px) scale(1);
							}

							&.add-new-address-on {
								&:before {
									width: 0;
								}
							}
						}
					}
				}
			}

			#billing-form-panel {
				margin-top: 35px;

				.panel-heading {
					padding: 0 !important;
				}

				button {
					&#addPaymentMethodBtn {
						position: relative;
						background-color: #dfdfdf;
						text-align: left;
						width: 100%;
						padding: 20px 20px;
						font-family: "IBM Plex Sans",sans-serif;
						font-size: 24px;
						font-weight: 500;
						color: #333;
						line-height: 24px;

						&:before {
							content: '';
							display: inline-block;
							position: absolute;
							top: 30px;
							right: 14px;
							margin: 0;
							width: 14px;
							height: 0px;
							border-bottom: 3px solid #2b2b2b;
							transform: rotate(90deg);
							transition: width 0.3s;
						}

						&:after {
							content: '';
							display: inline-block;
							position: absolute;
							top: 30px;
							right: 14px;
							margin: 0;
							width: 14px;
							height: 0px;
							border-bottom: 3px solid #2b2b2b;
							transform: rotate(0deg);
							transition: width 0.3s;
						}

						&:hover,
						&:focus {
							-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
							-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
							-o-box-shadow: 0 0 10px rgba(228,112,30,0);
							box-shadow: 0 0 10px rgba(228,112,30,0);
							-webkit-transform: perspective(1px) scale(1);
							-ms-transform: perspective(1px) scale(1);
							transform: perspective(1px) scale(1);
						}

						&[aria-expanded="true"] {
							&:before {
								width: 0;
							}
						}
					}
				}

				+ #billing-address-form {
					margin-top: 0;
				}
			}

			.shipping-address-list-wrapper {
				display: block;
				margin: 0 !important;
				padding: 0 !important;

				.panel-heading {
					&.sub-panel-heading {
						padding-bottom: 30px !important;
					}
				}

				.itemList {
					margin: 0 !important;

					.interiorList {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						grid-gap: 20px;
						margin: 0;
						width: 100%;

						@media(min-width: 768px) and (max-width: 979px) {
							grid-template-columns: 1fr 1fr;
						}

						@media(min-width: 481px) and (max-width: 759px) {
							grid-template-columns: 1fr 1fr;
						}

						@media(max-width: 480px) {
							grid-template-columns: 1fr;
							grid-gap: 15px;
						}

						> .item {
							display: inline-block !important;
							flex-wrap: unset !important;
							width: 100% !important;
							margin: 0 !important;
							padding: 17px 20px 20px !important;
							border: 1px solid #eaeaea;
							font-size: 16px;

							.itemHeader {
								.title {
									font-size: 18px;
									font-weight: 600;
									color: #333;
								}
							}

							.itemContent {
								font-family: "IBM Plex Sans",sans-serif;
								color: #333;
								padding: 0;
								margin-bottom: 20px;
							}

							.itemButton {
								display: table;
								width: 100%;

								> .btn {
									display: inline-block;
									position: relative;
									font-size: 16px;
									font-weight: 600;
									text-transform: capitalize;
									padding: 14px 20px;
									color: #0a4e8c;
									background-color: #fff;
									border: 1px solid #0a4e8c;

									@media(max-width: 979px) {
										padding-left: 10px;
										padding-right: 10px;
									}

									&[data-roc-action="btnChecked"] {
										padding-left: 25px;
										background-color: #0a4e8c;
										border: 1px solid #0a4e8c;
										color: #fff;

										&:before {
											content: "\e618";
											font-family: 'ROC-Icons';
											speak: none;
											font-style: normal;
											font-weight: normal;
											font-variant: normal;
											text-transform: none;
											line-height: 1.42857;
											/* Better Font Rendering =========== */
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											display: inline-block;
											position: absolute;
											margin-left: -23px;
										}
									}

									&:hover,
									&:focus {
										background-color: #e4701e;
										color: #fff;
									}
								}
							}
						}
					}
				}
			}

			#delivery-methods {
				display: block;
				margin: 0 !important;
				padding: 0 !important;

				.panel-heading {
					&.sub-panel-heading {
						padding-bottom: 30px !important;
					}
				}

				.itemList {
					margin: 0 !important;

					.interiorList {
						display: flex;
						grid-gap: 20px;
						margin: 0;
						width: 100%;

						@media(max-width: 500px) {
							flex-wrap: wrap;
						}

						> .item {
							display: inline-block !important;
							flex-wrap: unset !important;
							width: 28% !important;
							max-width: 28%;
							flex-grow: 1;
							margin: 0 10px !important;
							padding: 17px 20px 20px !important;
							border: 1px solid #eaeaea;
							font-size: 16px;

							@media(max-width: 832px) {
								max-width: 100%;
							}

							@media(max-width: 500px) {
								width: 100% !important;
								margin: 10px 0 !important;
							}

							.itemContent {
								font-family: "IBM Plex Sans",sans-serif;
								color: #333;
								padding: 0;

								.itemHeader {
									margin-bottom: 20px;

									.title {
										font-size: 18px;
										font-weight: 600;
										color: #333;
									}
								}
							}

							.content-in {
								margin-bottom: 20px;
							}

							.bottom-cap {
								display: table;
								width: 100%;

								> .btn {
									display: inline-block;
									position: relative;
									font-size: 16px;
									font-weight: 600;
									text-transform: capitalize;
									padding: 14px 20px;
									color: #0a4e8c;
									background-color: #fff;
									border: 1px solid #0a4e8c;

									@media(max-width: 979px) {
										padding-left: 10px;
										padding-right: 10px;
									}

									&[data-roc-action="btnChecked"] {
										padding-left: 25px;
										background-color: #0a4e8c;
										border: 1px solid #0a4e8c;
										color: #fff;

										&:before {
											content: "\e618";
											font-family: 'ROC-Icons';
											speak: none;
											font-style: normal;
											font-weight: normal;
											font-variant: normal;
											text-transform: none;
											line-height: 1.42857;
											/* Better Font Rendering =========== */
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											display: inline-block;
											position: absolute;
											margin-left: -23px;
										}
									}

									&:hover,
									&:focus {
										background-color: #e4701e;
										color: #fff;
									}
								}
							}
						}
					}
				}
			}

			#shipping-methods {
				display: block;
				margin: 0 !important;
				padding: 0 !important;

				.panel-heading {
					&.sub-panel-heading {
						padding-top: 25px !important;
						padding-bottom: 30px !important;
					}
				}

				.itemList {
					margin: 0 !important;

					.interiorList {
						display: flex;
						margin: 0;
						width: 100%;

						@media(max-width: 1042px) {
							flex-wrap: wrap;
						}

						> .item {
							display: inline-block !important;
							flex-wrap: unset !important;
							width: 28% !important;
							flex-grow: 1;
							max-width: 28%;
							margin: 0 10px !important;
							padding: 17px 20px 20px !important;
							border: 1px solid #eaeaea;
							font-size: 16px;

							@media(max-width: 1042px) {
								width: 100% !important;
								max-width: 45%;
								margin: 10px !important;
							}

							@media(max-width: 500px) {
								max-width: 100%;
								margin: 10px 0 !important;
							}

							.itemContent {
								font-family: "IBM Plex Sans",sans-serif;
								color: #333;
								padding: 0;

								.itemHeader {
									.title {
										font-size: 18px;
										font-weight: 600;
										color: #333;
									}
								}
							}

							.content-in {
								margin-bottom: 20px;
							}

							.bottom-cap {
								display: table;
								width: 100%;

								> .btn {
									display: inline-block;
									position: relative;
									font-size: 16px;
									font-weight: 600;
									text-transform: capitalize;
									padding: 14px 20px;
									color: #0a4e8c;
									background-color: #fff;
									border: 1px solid #0a4e8c;

									@media(max-width: 979px) {
										padding-left: 10px;
										padding-right: 10px;
									}

									&[data-roc-action="btnChecked"] {
										padding-left: 25px;
										background-color: #0a4e8c;
										border: 1px solid #0a4e8c;
										color: #fff;

										&:before {
											content: "\e618";
											font-family: 'ROC-Icons';
											speak: none;
											font-style: normal;
											font-weight: normal;
											font-variant: normal;
											text-transform: none;
											line-height: 1.42857;
											/* Better Font Rendering =========== */
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											display: inline-block;
											position: absolute;
											margin-left: -23px;
										}
									}

									&:hover,
									&:focus {
										background-color: #e4701e;
										color: #fff;
									}
								}
							}
						}
					}
				}
			}

			.payment-option {
				&.payment-selector {
					display: block;
					margin: 0 !important;
					padding: 0 !important;

					> .itemList {
						margin: 0 !important;

						.interiorList {
							display: grid;
							grid-template-columns: 1fr 1fr 1fr;
							grid-gap: 20px;
							margin: 0;
							width: 100%;

							@media(min-width: 768px) and (max-width: 979px) {
								grid-template-columns: 1fr 1fr;
							}

							@media(min-width: 481px) and (max-width: 759px) {
								grid-template-columns: 1fr 1fr;
							}

							@media(max-width: 480px) {
								grid-template-columns: 1fr;
								grid-gap: 15px;
							}

							> .item {
								display: inline-block !important;
								flex-wrap: unset !important;
								width: 100% !important;
								margin: 0 !important;
								padding: 17px 20px 20px !important;
								border: 1px solid #eaeaea;
								font-size: 16px;

								.itemContent {
									font-family: "IBM Plex Sans",sans-serif;
									color: #333;
									padding: 0;

									.itemHeader {
										margin-bottom: 20px;

										.title {
											font-size: 18px;
											font-weight: 600;
											color: #333;
										}
									}
								}

								.content-in {
									margin-bottom: 20px;
								}

								.bottom-cap {
									display: table;
									width: 100%;

									> .btn {
										display: inline-block;
										position: relative;
										font-size: 16px;
										font-weight: 600;
										text-transform: capitalize;
										padding: 14px 20px;
										color: #0a4e8c;
										background-color: #fff;
										border: 1px solid #0a4e8c;

										@media(max-width: 979px) {
											padding-left: 10px;
											padding-right: 10px;
										}

										&[data-roc-action="btnChecked"] {
											padding-left: 25px;
											background-color: #0a4e8c;
											border: 1px solid #0a4e8c;
											color: #fff;

											&:before {
												content: "\e618";
												font-family: 'ROC-Icons';
												speak: none;
												font-style: normal;
												font-weight: normal;
												font-variant: normal;
												text-transform: none;
												line-height: 1.42857;
												/* Better Font Rendering =========== */
												-webkit-font-smoothing: antialiased;
												-moz-osx-font-smoothing: grayscale;
												display: inline-block;
												position: absolute;
												margin-left: -23px;
											}
										}

										&:hover,
										&:focus {
											background-color: #e4701e;
											color: #fff;
										}
									}
								}
							}
						}
					}
				}

				&.credit-card {
					.formee {
						.row {
							@media(min-width: 980px) {
								margin-left: -10px !important;
								margin-right: -10px !important;
							}

							> .col-sm-6 {
								margin-bottom: 15px !important;

								&.form-card-types {
									.payment-type-selection {
										margin-top: 23px;
									}
								}
							}

							input {
								height: 50px;
								margin-bottom: 0 !important;
							}
						}

						.roc-save-payment {
							label {
								margin: 0 !important;
								padding-left: 20px;
								font-size: 16px;
								font-weight: 400;
							}
						}

						[data-roc-wrapper="saveCCWrapper"] {
							margin-top: 10px;
							margin-bottom: 10px;

							.formee-list {
								li {
									margin-bottom: 0.5em;
								}
							}
						}

						hr {
							margin: 0 0 20px;
						}
					}
				}

				.billing-form {
					.billing-address-picker-form {
						.billing-address-header {
							> label {
								//								margin: 0;
								//								padding: 0;
								//								font-size: 0;
								//								visibility: hidden;
								position: relative;
								display: block;
								font-family: "IBM Plex Sans",sans-serif;
								font-size: 24px;
								font-weight: 500;
								line-height: 30px;
								text-transform: none;
								padding: 0 0 22px;
								color: #333 !important;
								cursor: default;
								margin-bottom: 30px;

								&:after {
									content: '';
									position: absolute;
									display: block;
									background-color: #eaeaea;
									left: 0;
									right: 0;
									bottom: 0;
									width: 100%;
									height: 1px;
								}
							}

							button {
								&.btnAlt {
									position: relative;
									background-color: #dfdfdf;
									text-align: left;
									width: 100%;
									padding: 20px 20px;
									font-family: "IBM Plex Sans",sans-serif;
									font-size: 24px;
									font-weight: 500;
									line-height: 24px;
									color: #333;

									&:before {
										content: '';
										display: inline-block;
										position: absolute;
										top: 30px;
										right: 14px;
										margin: 0;
										width: 14px;
										height: 0px;
										border-bottom: 3px solid #2b2b2b;
										transform: rotate(90deg);
										transition: width 0.3s;
									}

									&:after {
										content: '';
										display: inline-block;
										position: absolute;
										top: 30px;
										right: 14px;
										margin: 0;
										width: 14px;
										height: 0px;
										border-bottom: 3px solid #2b2b2b;
										transform: rotate(0deg);
										transition: width 0.3s;
									}

									&:hover,
									&:focus {
										-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
										-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
										-o-box-shadow: 0 0 10px rgba(228,112,30,0);
										box-shadow: 0 0 10px rgba(228,112,30,0);
										-webkit-transform: perspective(1px) scale(1);
										-ms-transform: perspective(1px) scale(1);
										transform: perspective(1px) scale(1);
									}

									&.add-new-address-on {
										&:before {
											width: 0;
										}
									}
								}
							}
						}

						.itemList {
							margin: 0;
						}
					}

					#newAddressFormWrapper {
						margin: 0;
						max-height: 0;
						-wekbit-transition: all .4s ease-in-out;
						transition: all .4s ease-in-out;
						overflow: hidden;

						&.add-new-address-on {
							margin: 25px 0 0;
							max-height: 2000em;
						}

						.formee-list {
							&.formee-list-horizontal {
								input {
									height: 13px;
								}

								label {
									margin: 0 !important;
									padding-left: 20px;
									font-size: 16px;
									font-weight: 400;
								}
							}
						}
					}

					.paymentMethodsWrapper {
						.itemList {
							> .item {
								width: 28% !important;

								.itemButton {
									display: table;
									width: 100%;

									> .btn {
										display: inline-block;
										position: relative;
										font-size: 16px;
										font-weight: 600;
										text-transform: capitalize;
										color: #0a4e8c;
										background-color: #fff;
										border: 1px solid #0a4e8c;
										padding: 14px 20px;

										@media(max-width: 979px) {
											padding-left: 10px;
											padding-right: 10px;
										}

										&[data-roc-action="btnChecked"] {
											padding-left: 25px;
											background-color: #0a4e8c;
											border: 1px solid #0a4e8c;
											color: #fff;

											&:before {
												content: "\e618";
												font-family: 'ROC-Icons';
												speak: none;
												font-style: normal;
												font-weight: normal;
												font-variant: normal;
												text-transform: none;
												line-height: 1.42857;
												/* Better Font Rendering =========== */
												-webkit-font-smoothing: antialiased;
												-moz-osx-font-smoothing: grayscale;
												display: inline-block;
												position: absolute;
												margin-left: -23px;
											}
										}

										&:hover,
										&:focus {
											background-color: #e4701e;
											color: #fff;
										}
									}
								}
							}
						}
					}
				}
			}

			.formee-list {
				&.formee-list-horizontal {
					&.roc-save-payment {
						position: relative;
						display: table;
						margin-top: 0 !important;

						&.save-payment-credit {
							margin-top: 25px !important;

							li {
								margin: 0 !important;
							}
						}
					}
				}
			}

			.billing-address-picker-form {
				.itemList {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-gap: 20px;
					margin: 0;
					width: 100%;

					@media(min-width: 768px) and (max-width: 979px) {
						grid-template-columns: 1fr 1fr;
					}

					@media(min-width: 481px) and (max-width: 759px) {
						grid-template-columns: 1fr 1fr;
					}

					@media(max-width: 480px) {
						grid-template-columns: 1fr;
						grid-gap: 15px;
					}

					> .item {
						display: inline-block !important;
						flex-wrap: unset !important;
						width: 100% !important;
						margin: 0 !important;
						padding: 17px 20px 20px !important;
						border: 1px solid #eaeaea;
						font-size: 16px;

						.itemContent {
							font-family: "IBM Plex Sans",sans-serif;
							color: #333;
							padding: 0;
							margin-bottom: 20px;

							.itemHeader {
								.title {
									font-size: 18px;
									font-weight: 600;
									color: #333;
								}
							}
						}

						.itemButton {
							display: table;
							width: 100%;

							> .btn {
								display: inline-block;
								position: relative;
								font-size: 16px;
								font-weight: 600;
								text-transform: capitalize;
								color: #0a4e8c;
								background-color: #fff;
								border: 1px solid #0a4e8c;
								padding: 14px 20px;

								@media(max-width: 979px) {
									padding-left: 10px;
									padding-right: 10px;
								}

								&[data-roc-action="btnChecked"] {
									padding-left: 25px;
									background-color: #0a4e8c;
									border: 1px solid #0a4e8c;
									color: #fff;

									&:before {
										content: "\e618";
										font-family: 'ROC-Icons';
										speak: none;
										font-style: normal;
										font-weight: normal;
										font-variant: normal;
										text-transform: none;
										line-height: 1.42857;
										/* Better Font Rendering =========== */
										-webkit-font-smoothing: antialiased;
										-moz-osx-font-smoothing: grayscale;
										display: inline-block;
										position: absolute;
										margin-left: -23px;
									}
								}

								&:hover,
								&:focus {
									background-color: #e4701e;
									color: #fff;
								}
							}
						}
					}
				}
			}

			#pickup-form,
			#delivery-methods,
			#shipping-methods {
				> .panel-heading {
					background-color: transparent;
				}

				h2 {
					&.panel-title {
						position: relative;
						display: block;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 24px;
						font-weight: 500;
						line-height: 30px;
						text-transform: none;
						margin-top: 10px;
						padding: 0 0 22px;
						color: #333 !important;
						cursor: default;

						&:after {
							content: '';
							position: absolute;
							display: block;
							background-color: #eaeaea;
							left: 0;
							right: 0;
							bottom: 0;
							width: 100%;
							height: 1px;
						}
					}
				}
			}

			#noShippingMethods {
				margin-top: 20px;
				margin-bottom: 0;
			}
		}

		.panel {
			+ .panel-body {
				padding-top: 0 !important;
				//padding-bottom: 10px !important;
			}
		}

		[data-roc-wrapper="overrideTaxExemption-step"] {
			border-left: 1px solid #eaeaea !important;
			border-right: 1px solid #eaeaea !important;
			border-top: none !important;
			border-bottom: none !important;
		}

		.formee {
			label,
			&.formee-lbl {
				font-family: "IBM Plex Sans",sans-serif;
				font-size: 18px;
				font-weight: 600;
				color: #333;

				@media(min-width: 768px) and (max-width: 979px) {
					font-size: 15px;
				}
			}
		}

		.formee-lbl,
		.formee label {
			margin: 10px 0 10px;
		}
	}

	#overrideTaxExemptionPanel {
		> .panel-body {
			border-bottom: 1px solid #eaeaea;
		}
	}

	#slideCartRail {
		> h2 {
			&.title {
				position: relative;
				display: block;
				font-family: Montserrat,Arial,sans-serif;
				font-size: 24px;
				font-weight: 600;
				line-height: 30px;
				margin: 0;
				padding: 15px 20px;
				color: #333 !important;
				background-color: #dfdfdf;

				@media(min-width: 768px) and (max-width: 979px) {
					font-size: 20px;
					line-height: 26px;
				}
			}
		}

		.checkoutSummary {
			&.panel-group {
				> .content {
					@media(min-width: 768px) and (max-width: 979px) {
						padding: 15px;
					}
				}

				> .cartItems {
					> .panel {
						&.panel-default {
							background-color: transparent;
							border: none;

							> .panel-heading {
								visibility: hidden;
								margin: 0;
								padding: 0;
								position: absolute;
							}
						}
					}

					.productList {
						&.listView {
							&.list-micro {
								.productListInner {
									display: block !important;

									.itemWrapper {
										border-bottom: 1px solid #eaeaea;
										padding-bottom: 20px;

										@media(max-width: 767px) {
											padding: 0 !important;
										}

										&:last-child {
											border-bottom: none;
											padding-bottom: 0;
										}

										> .media {
											//display: none;
											padding-right: 15px;

											.itemImageWrapper {
												margin-top: 2px;

												img {
													border: 1px solid #eaeaea;
												}
											}
										}

										> .itemContent {
											padding-left: 0;
											height: auto !important;
											font-family: "IBM Plex Sans",sans-serif;
											font-size: 16px;
											line-height: 20px;

											.itemTitle {
												display: block;
												font-family: "IBM Plex Sans",sans-serif;
												font-size: 16px;
												line-height: 25px;
												color: #231f20;
												margin-bottom: 15px;
												min-height: inherit !important;
											}

											.inlineList {
												font-size: 16px;
												line-height: 20px;

												li {
													&:not(:last-child) {
														margin-right: 3px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.checkoutTotals {
					background-color: #dfdfdf;
					font-family: "IBM Plex Sans",sans-serif;
					font-size: 16px;
					line-height: 20px;
					border-top: 1px solid #eaeaea;

					.costSummary {
						.costRow {
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 7px;

							.title {
								padding: 0;
								font-size: 16px;
								font-weight: 600;
								line-height: 20px;
								padding-bottom: 10px;

								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 14px;
									line-height: 18px;
								}
							}

							.price {
								padding-top: 0;
								padding-bottom: 10px;

								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 14px;
									line-height: 18px;
								}
							}
						}

						+ .checkoutButtons {
							.btnProcess {
								background-color: #e4701e;
								font-weight: 600;
								text-transform: none;
							}

							+ .content-in {
								p {
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.recipient-container,
	#overrideTaxExemptionPanel,
	[data-roc-wrapper="payment-step"],
	[data-roc-wrapper="ponumber-step"],
	[data-roc-wrapper="upload-po-step"] {
		> .panel-heading {
			position: relative;
			width: 100%;
			padding-top: 15px !important;
			padding-bottom: 15px !important;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(90deg);
				transition: width 0.3s;
			}

			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(0deg);
				transition: width 0.3s;
			}

			&:hover,
			&:focus {
				-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
				-o-box-shadow: 0 0 10px rgba(228,112,30,0);
				box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-transform: perspective(1px) scale(1);
				-ms-transform: perspective(1px) scale(1);
				transform: perspective(1px) scale(1);
			}

			&.panel-open {
				&:before {
					width: 0;
				}
			}

			> .panel-title {
				padding: 0 40px 0 22px !important;
			}
		}

		> .panel-body,
		> .panel-collapse {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;

			&.panel-open {
				padding-top: 39px !important;
				padding-bottom: 39px !important;
				max-height: 2000em;

				@media(max-width: 979px) {
					padding-top: 22px !important;
					padding-bottom: 22px !important;
				}
			}

			> .list-group {
				border-top: none;
			}

			.panel-body {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
				border-top: none;
			}
		}
	}

	.ship-complete-request {
		> .panel-heading {
			position: relative;
			width: 100%;
			padding-top: 15px !important;
			padding-bottom: 15px !important;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(90deg);
				transition: width 0.3s;
			}

			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(0deg);
				transition: width 0.3s;
			}

			&:hover,
			&:focus {
				-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
				-o-box-shadow: 0 0 10px rgba(228,112,30,0);
				box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-transform: perspective(1px) scale(1);
				-ms-transform: perspective(1px) scale(1);
				transform: perspective(1px) scale(1);
			}

			&.panel-open {
				&:before {
					width: 0;
				}
			}

			> .panel-title {
				padding: 0 40px 0 22px !important;
			}
		}

		> .panel-body {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;

			&.panel-open {
				padding-top: 39px !important;
				padding-bottom: 39px !important;
				max-height: 2000em;

				@media(max-width: 979px) {
					padding-top: 22px !important;
					padding-bottom: 22px !important;
				}
			}
		}

		+ .panel-body {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;
		}
	}

	[data-roc-wrapper="ordernotes-step"] {
		> .panel-heading {
			position: relative;
			width: 100%;
			padding-top: 15px !important;
			padding-bottom: 15px !important;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(90deg);
				transition: width 0.3s;
			}

			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 28px;
				right: 18px;
				margin: 0;
				width: 16px;
				height: 0px;
				border-bottom: 3px solid #fff;
				transform: rotate(0deg);
				transition: width 0.3s;
			}

			&:hover,
			&:focus {
				-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
				-o-box-shadow: 0 0 10px rgba(228,112,30,0);
				box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-transform: perspective(1px) scale(1);
				-ms-transform: perspective(1px) scale(1);
				transform: perspective(1px) scale(1);
			}

			&.panel-open {
				&:before {
					width: 0;
				}
			}

			> .panel-title {
				padding: 0 40px 0 22px !important;
			}
		}

		> .panel-body {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;

			&.panel-open {
				padding-top: 39px !important;
				padding-bottom: 20px !important;
				max-height: 2000em;

				@media(max-width: 979px) {
					padding-top: 22px !important;
				}
			}
		}

		+ .panel-body {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;
		}
	}
}

#quotingRequestWrapper {
	.panel-primary {
		background-color: transparent;
		border-top: 1px solid #eaeaea;
		border-left: 1px solid #eaeaea;
		border-right: 1px solid #eaeaea;
		border-bottom: none;				
	}
	.bottom-cap {
		display: table;
		width: 100%;
		>.btn {
			display: inline-block;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			text-transform: capitalize;
			color: #0a4e8c;
			background-color: #fff;
			border: 1px solid #0a4e8c;
			padding: 14px 20px;
			@media(max-width: 979px) {
				padding-left: 10px;
				padding-right: 10px;
			}											
			&[data-roc-action="btnChecked"] {
				padding-left: 25px;
				background-color: #0a4e8c;
				border: 1px solid #0a4e8c;
				color: #fff;
				&:before {
					content: "\e618";
					font-family: 'ROC-Icons';
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1.42857;
					/* Better Font Rendering =========== */
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;		
					display: inline-block;
					position: absolute;
					margin-left: -23px;
				}
			}
			&:hover,
			&:focus {
				background-color: #e4701e;
				color: #fff;
			}
		}
	}	
	.panel-quote-request {
		>.panel {
			>.panel-heading {
				position: relative;
				width: 100%;
				padding-top: 15px !important;
				padding-bottom: 15px !important;
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 28px;
					right: 18px;
					margin: 0;
					width: 16px;
					height: 0px;
					border-bottom: 3px solid #fff;
					transform: rotate(90deg);
					transition: width 0.3s;
				}
				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					top: 28px;
					right: 18px;
					margin: 0;
					width: 16px;
					height: 0px;
					border-bottom: 3px solid #fff;
					transform: rotate(0deg);
					transition: width 0.3s;
				}
			}
		}
		.panel-body {
			padding-top: 0 !important;
			padding-bottom: 0 !important;			
			max-height: 0;
			-wekbit-transition: all .4s ease-in-out;
			transition: all .4s ease-in-out;
			overflow: hidden;			
		}
		#po-number {
			&.panel {
				background-color: transparent;
				border: none;
				padding-left: 39px;
				padding-right: 39px;
				@media(max-width: 979px) {
					padding-left: 22px;
					padding-right: 22px;
				}				
				>.panel-heading {
					padding-top: 0 !important;
					padding-bottom: 0 !important;
					margin: 0;
					&.panel-sub-heading {
						label {
							margin-bottom: 0;
							font-size: 0;
						}
						&:before,
						&:after {
							display: none;
						}
					}
					.panel-title {
						display: block;
						height: auto;
						margin: 0 0 0;
						font-family: "IBM Plex Sans",sans-serif;
						font-size: 18px;
						font-weight: 600;	
						text-transform: none;
						color: #333;
						@media(min-width: 768px) and (max-width: 979px) {
							font-size: 15px;
						}						
					}					
				}
				>.panel-body {
					padding: 0 0 0 !important;
					.panel-heading {
						margin: 0;
						padding: 0 !important;
						.panel-title {
							display: block;
							height: auto;
							margin: 0 0 0;
							font-family: "IBM Plex Sans",sans-serif;
							font-size: 18px;
							font-weight: 600;	
							text-transform: none;
							color: #333;
							@media(min-width: 768px) and (max-width: 979px) {
								font-size: 15px;
							}							
						}						
					}
					.panel-body {
						padding: 0 0 0 !important;
						.panel-heading {
							margin: 0;
							padding: 0 !important;
							.panel-title {
								display: block;
								height: auto;
								margin: 0 0 0;
								font-family: "IBM Plex Sans",sans-serif;
								font-size: 18px;
								font-weight: 600;	
								text-transform: none;
								color: #333;
								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 15px;
								}								
							}						
						}						
					}					
				}
				+.panel-body {
					padding: 0 39px !important;
					@media(max-width: 979px) {
						padding-left: 22px !important;
						padding-right: 22px !important;
					}					
					.panel-heading {
						margin: 0;
						padding: 0 !important;
						.panel-title {
							display: block;
							height: auto;
							margin: 0 0 0;
							font-family: "IBM Plex Sans",sans-serif;
							font-size: 18px;
							font-weight: 600;	
							text-transform: none;
							color: #333;
							@media(min-width: 768px) and (max-width: 979px) {
								font-size: 15px;
							}							
						}						
					}					
					.panel-body {
						padding: 0 0 0 !important;
						.panel-heading {
							margin: 0;
							padding: 0 !important;
							.panel-title {
								display: block;
								height: auto;
								margin: 0 0 0;
								font-family: "IBM Plex Sans",sans-serif;
								font-size: 18px;
								font-weight: 600;	
								text-transform: none;
								color: #333;
								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 15px;
								}								
							}						
						}						
					}					
					+.panel-body {
						padding: 0 39px 0 !important;
						@media(max-width: 979px) {
							padding-left: 22px !important;
							padding-right: 22px !important;
						}						
						.panel-heading {
							margin: 0;
							padding: 0 !important;
							.panel-title {
								display: block;
								height: auto;
								margin: 0 0 0;
								font-family: "IBM Plex Sans",sans-serif;
								font-size: 18px;
								font-weight: 600;	
								text-transform: none;
								color: #333;
								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 15px;
								}								
							}						
						}						
						.panel-body {
							padding: 0 0 0 !important;
							.panel-heading {
								margin: 0;
								padding: 0 !important;
								.panel-title {
									display: block;
									height: auto;
									margin: 0 0 0;
									font-family: "IBM Plex Sans",sans-serif;
									font-size: 18px;
									font-weight: 600;	
									text-transform: none;
									color: #333;
									@media(min-width: 768px) and (max-width: 979px) {
										font-size: 15px;
									}									
								}						
							}
							#quote-phone {
								margin-bottom: 0;
							}
						}						
					}			
					+.panel-body {
						padding: 0 39px 0 !important;
						@media(max-width: 979px) {
							padding-left: 22px !important;
							padding-right: 22px !important;
						}						
						.panel-heading {
							margin: 0;
							padding: 0 !important;
							.panel-title {
								display: block;
								height: auto;
								margin: 0 0 0;
								font-family: "IBM Plex Sans",sans-serif;
								font-size: 18px;
								font-weight: 600;	
								text-transform: none;
								color: #333;
								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 15px;
								}								
							}						
						}						
						.panel-body {
							padding: 0 0 0 !important;
							.panel-heading {
								margin: 0;
								padding: 0 !important;
								.panel-title {
									display: block;
									height: auto;
									margin: 0 0 0;
									font-family: "IBM Plex Sans",sans-serif;
									font-size: 18px;
									font-weight: 600;	
									text-transform: none;
									color: #333;
									@media(min-width: 768px) and (max-width: 979px) {
										font-size: 15px;
									}									
								}						
							}
							#quote-phone {
								margin-bottom: 0;
							}
						}						
					}		
				}
			}
		}
		&.panel-quote-request-open {
			>.panel {
				>.panel-heading {
					&:before {
						width: 0;
					}
					.panel-title {
						margin: 0;
					}					
				}
			}
			.panel-body {
				padding-top: 39px !important;
				padding-bottom: 20px !important;
				max-height: 2000em;								
			}
			#po-number {
				&.panel {
					>.panel-heading {
						padding-top: 0 !important;
						padding-bottom: 0 !important;
						&.panel-sub-heading {
							label {
								margin-bottom: 10px;
								font-size: 18px;
								@media(min-width: 768px) and (max-width: 979px) {
									font-size: 15px;
								}								
							}
						}
					}		
					>.panel-body {
						padding: 0 0 8px !important;
						.panel-heading {
							.panel-title {
								margin: 0 0 10px;
							}						
						}
						.panel-body {
							padding: 0 0 8px !important;
							.panel-heading {
								.panel-title {
									margin: 0 0 10px;
								}						
							}						
						}					
					}
					+.panel-body {
						padding: 0 39px !important;
						@media(max-width: 979px) {
							padding-left: 22px !important;
							padding-right: 22px !important;
						}						
						.panel-heading {
							.panel-title {
								margin: 0 0 10px;
							}						
						}					
						.panel-body {
							padding: 0 0 8px !important;
							.panel-heading {
								.panel-title {
									margin: 0 0 10px;
								}						
							}						
						}					
						+.panel-body {
							padding: 0 39px 8px !important;
							@media(max-width: 979px) {
								padding-left: 22px !important;
								padding-right: 22px !important;
							}							
							.panel-heading {
								.panel-title {
									margin: 0 0 10px;
								}						
							}						
							.panel-body {
								padding: 0 0 0 !important;
								.panel-heading {
									.panel-title {
										margin: 0 0 10px;
									}						
								}							
							}						
						}					
					}					
				}
			}			
		}
	}
	.checkout-btn-wrapper {
		padding: 27px 39px 35px;
		border-left: 1px solid #eaeaea;
		border-right: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea;
		@media(max-width: 979px) {
			padding-left: 22px !important;
			padding-right: 22px !important;
		}		
		.btnProcess {
			background-color: #e4701e;
			font-weight: 600;
			text-transform: none;
		}		
	}
}

.add-address-header,
.see-more-addresses-btn {
	display: block;
	position: relative;
	margin: 20px 0;
	button {
		&.btnAlt {
			position: relative;
			background-color: #dfdfdf;
			text-align: left;
			width: 100%;
			padding: 20px 20px;
			font-family: "IBM Plex Sans",sans-serif;
			font-size: 24px;
			font-weight: 500;
			color: #333;
			line-height: 24px;
			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 30px;
				right: 14px;
				margin: 0;
				width: 14px;
				height: 0px;
				border-bottom: 3px solid #2b2b2b;
				transform: rotate(90deg);
				transition: width 0.3s;
			}
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 30px;
				right: 14px;
				margin: 0;
				width: 14px;
				height: 0px;
				border-bottom: 3px solid #2b2b2b;
				transform: rotate(0deg);
				transition: width 0.3s;
			}
			&:hover,
			&:focus {
				-moz-box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-box-shadow: 0 0 10px rgba(228,112,30,0);
				-o-box-shadow: 0 0 10px rgba(228,112,30,0);
				box-shadow: 0 0 10px rgba(228,112,30,0);
				-webkit-transform: perspective(1px) scale(1);
				-ms-transform: perspective(1px) scale(1);
				transform: perspective(1px) scale(1);										
			}
			&[aria-expanded="true"] {
				&:before {
					width: 0;
				}										
			}
		}
	}
}
.see-more-addresses-btn {
	margin-top: 40px;
}

#orderConfirmationDetails,
#quoteConfirmationWrapper {
	@media (min-width: 980px) {
		padding-left: 15px;
		padding-right: 15px;
	}	
	.quote-title {
		margin-top: 0;
	}
	.info-container {
		.info-header {
			.title {
				margin-bottom: 5px;
			}
		}
	}
	.panel {
		background-color: transparent;
		border: none;
		.panel-body {
			padding: 0;
			@media (max-width: 767px) {
				padding: 0 !important;
			}			
			.productList {
				&.cartList {
					display: block;
					position: relative;
					border: 1px solid #dfdfdf;
					padding: 20px !important;
					margin-top: 30px;
					margin-bottom: 30px;
					.productListInner {
						@media (max-width: 979px) {
							display: block !important;
							position: relative !important;
						}						
					}
					.itemWrapper {
						display: table;
						position: relative;
						border-bottom: 1px solid #eaeaea;
						padding: 20px 0 !important;
						width: 100%;
						@media(min-width: 481px) and (max-width: 979px) {
							display: grid;
							grid-template-columns: auto auto;
							grid-gap: 20px;
							margin: 0;
							width: auto;
						}			
						@media(max-width: 480px) {
							display: grid;
							grid-template-columns: 1fr;
							grid-gap: 10px;
						}						
						&:first-child {
							padding-top: 0 !important;
						}
						&:not(:first-child) {
							border-top: none !important;
						}						
						&:last-child {
							padding-bottom: 0 !important;
							border-bottom: none !important;
						}
						.product-image {
							display: table-cell;
							vertical-align: top;
							@media (max-width: 979px) {
								display: block !important;
								position: relative !important;
							}							
						}
						.product-content {
							display: table-cell;
							vertical-align: top;
							padding: 0 25px;
							@media(max-width: 979px) {
								padding: 0;
							}							
							.product-sku {
								font-size: 16px;
								font-weight: 500;
								padding: 0 0 7px 0;
								color: #656565;								
							}
							.product-title {
								font-weight: 700;
								a {
									font-size: 16px;
									font-weight: 600;
									text-decoration: none;
									transition: color .3s;
									color: #0a4e8c;									
								}
							}
							.product-delivery {
								margin-top: 15px;
								font-size: 16px;
								font-weight: 500;
								color: #333;								
								span {
									font-weight: 600;
								}
							}
							
						}
						.itemAddon {
							display: table-cell;
							vertical-align: top;
							@media(max-width: 979px) {
								text-align: left;
								margin: 0;
								padding: 0;
							}							
							>p {
								font-size: 16px;
								font-weight: 500;
								padding: 0;
								color: #656565;								
							}
						}
						.itemAddon {
							&.itemPrice {
								display: table-cell;
								vertical-align: top;
								padding-right: 0 !important;
								margin-top: 0;
								margin: 0;
								font-size: 16px;
								font-weight: 500;
								color: #333;
								@media(max-width: 979px) {
									text-align: left;
									margin: 0;
									padding: 0;
								}								
								strong {
									font-weight: 600;
								}								
								.itemPrice {
									text-align: right;
									margin-top: 0;
									font-size: 16px;
									font-weight: 500;
									color: #333;
									line-height: 24px;
									margin: 4px 0 0;
									@media(max-width: 979px) {
										text-align: left;
									}									
									strong {
										font-weight: 600;
									}									
								}								
							}
						}						
					}
				}
			}
		}
	}
}

.white-popup {
	.modal-header {
		>.title {
			&.title-custom {
				margin-bottom: 0;
			}
		}
	}
	&.addressesModal {
		@media(max-width: 767px) {
			max-width: 100%;			
			padding-left: 15px;
			padding-right: 15px;			
		}		
	}
}

.address-book-list {
	.itemList {
		&.roc-list-address-modal {
			display: block !important;
			flex-wrap: unset !important;
			flex-direction: unset !important;
			margin: 0;
			.interiorList {
				display: grid !important;
				grid-template-columns: 1fr 1fr 1fr !important;
				flex-wrap: unset !important;
				flex-direction: unset !important;				
				grid-gap: 15px !important;
				width: 100% !important;
				word-break: break-word;
				margin: 0 0 15px;
				@media(min-width: 481px) and (max-width: 767px) {
					grid-template-columns: 1fr 1fr !important;
				}
				@media(max-width: 480px) {
					grid-template-columns: 1fr !important;
				}				
				.item {
					width: 100% !important;
					display: inline-block !important;
					flex-wrap: unset !important;
					margin: 0 !important;
					padding: 0 !important;	
					word-break: break-word;
					.itemHeader  {
						@media(max-width: 480px) {
							height: auto !important;
						}						
						.title {
							&.titleAlt {
								margin-bottom: 0;		
							}
						}
					}
					.itemContent {
						.content {
							@media(max-width: 480px) {
								height: auto !important;
							}							
						}
						&.capped-bottom {
							padding: 15px;
							.bottom-cap {
								position: relative;
								left: 0;
								right: 0;
								bottom: 0;								
							}
						}
					}
					.shipping-address-label {
						margin-bottom: 15px;
						@media(max-width: 480px) {
							height: auto !important;
						}						
					}
					.bottom-cap {
						display: table;
						width: 100%;
						>.btn {
							display: inline-block;
							position: relative;
							font-size: 14px;
							font-weight: 600;
							text-transform: capitalize;
							color: #0a4e8c;
							background-color: #fff;
							border: 1px solid #0a4e8c;
							padding: 14px 10px;
							height: auto !important;
							@media(max-width: 979px) {
								padding-left: 5px;
								padding-right: 5px;
							}											
							&[data-roc-action="btnChecked"] {
								padding-left: 25px;
								background-color: #0a4e8c;
								border: 1px solid #0a4e8c;
								color: #fff;
								&:before {
									content: "\e618";
									font-family: 'ROC-Icons';
									speak: none;
									font-style: normal;
									font-weight: normal;
									font-variant: normal;
									text-transform: none;
									line-height: 1.42857;
									/* Better Font Rendering =========== */
									-webkit-font-smoothing: antialiased;
									-moz-osx-font-smoothing: grayscale;		
									display: inline-block;
									position: absolute;
									margin-left: -18px;
								}
							}
							&:hover,
							&:focus {
								background-color: #e4701e;
								color: #fff;
							}
						}
					}					
				}
			}
		}		
	}
	.pagination {
		text-align: left;
		position: relative;		
		@media(max-width: 767px) {
			padding-top: 23px;
			width: 100%;
		}			
		.paging  {
			margin: 0 0 0 auto;		
			.roc-pageOptions-count {
				@media(max-width: 767px) {
					position: absolute;
					top: 0;
					left: 0;
				}				
			}
			.pageNum {
				height: 52px;
			}
		}
	}
}

#billing-address-form {
	.billing {
		#billing-same-as-shipping {
			position: relative;
			height: 40px;
		}
	}
}

.container-checkout-wrapper .roc-checkout .panel-body .payment-option .billing-form #newAddressFormWrapper .formee-list.formee-list-horizontal label {
	height: 50px;
	margin: 10px 0 10px !important;
	padding-left: 0;
}

#quotingRequestWrapper {
	.panel-quote-request {
		#po-number {
			label[for=poNumber] {
				padding: 0;
			}
		}

		.panel-body {
			padding: 0 20px !important;
		}

		.panel-body:last-child {
			padding: 0 20px !important;

			.panel-heading {
				padding: 15px 0 0 !important;
				
				.panel-title {
					color: #000;
					font-size: 18px;
					font-weight: 600;
					text-transform: none;
					padding: 0 20px;
				}
			}
		}
		.panel-default {
			.panel-body.panel-open {
				padding: 0 39px !important;
			}
		}
	}
}

div {
	&.please-allow {
		display: block;
		font-size: 16px;
		font-weight: 400;
		color: #333;
		margin: 0;
		padding: 10px 10px 0;	
	}	
}

.panel-primary {
	>.panel-heading {
		+.panel-collapse {
			>.panel-body {
				&.please-allow-panel {
					padding-bottom: 20px !important;
				}
			}
		}
	}
}