.branchLocatorsWrpr {
    overflow: hidden;
    clear: both;
}

// BRANCH LOCATION HEADER 
.branch-location-header-wrapper {
    @include containers-w-margin;
}


// BRANCH LOCATION CONTENT
.branch-location-content-wrapper {
    @include containers-w-margin;
}

.branch-location-title {
    @include title-font-large;
    margin: 0 0 30px 0;
}

.branch-location-content {
    width: 85%;
    line-height: 1.75em;
}


// BRANCH LOCATION MAP
.branch-location-map-wrapper {
    @include containers;
    padding: 30px 0;

    .row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

/* Branch Map Info - Left-hand Sidebar */
.branch-location-map-info {
    width: 25%;
    display: inline-flex;
    flex-wrap: wrap;
    background: $light-gray;
    @include border-default;
    padding: 0;
}

.branch-map-info-details-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    font-size: .9em;
    line-height: 1.75em;
    padding: 15px;
}

/* Branch Map Info - Location and Contact */
.branch-map-info-location-container {
    padding: 0 0 30px 0;
}

.branch-map-info-title {
    @include title-font-small;
    padding: 0 0 10px 0;
}

.branch-map-info-address {
    padding: 0 0 15px 0;
}

.branch-map-info-contact {
    span {
        white-space: nowrap;
    }
}

/* Branch Map Info - Hours */
.branch-map-info-hours-container {
    width: 100%;
}

.branch-map-info-hours-title {
    @include sm-callout-title-font;
    padding: 0 0 10px 0;
    margin: 0 0 15px 0;
    @include border-btm-default;
}

.branch-map-info-hours-list {
    line-height: 1.75em;

    .map-hours-day {
        width: 40%;
        display: inline-block;
    }

    .map-hours-time {
        width: 60%;
        display: inline-block;
    }
}

/* Branch Map Info - Certifications */
.branch-map-info-certs-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
    background: $color-primary;
    color: $white;
    @include disclaimer-font;
    font-weight: 500;
    padding: 15px;
}


// BRANCH TRAINING SECTION
.branch-location-training-wrapper {
    width: 100%;
    background: $light-gray;
    @include border-top-default;
}

.branch-location-training-container {
    @include containers;
    text-align: center;
    padding: 30px 0;
}

.branch-location-training-title {
    @include title-font-large;
    padding: 30px 0;
}

.branch-location-training-events-wrapper {

    .events-widget-in {
        grid-template-columns: 1fr 1fr;
    }

    .interior-callout-item-wide {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: $white;
        @include interior-box-shadow-2;
    }

    .interior-callout-content-container-wide {
        width: 50%;
        display: inline-flex;
        flex-wrap: wrap;
        padding: 30px;
    }

    .interior-callout-title-wide {
        @include title-font-small;
        text-align: left;

        a {
            color: $black;
        }
    }

    .interior-callout-line-wide {
        @include title-line;
        width: 50px;
        margin: 20px 50px 20px 0;
    }

    .interior-callout-btn-wide {
        padding: 15px 30px 0;

        a {
            min-width: 150px;
        }
    }

    .interior-callout-image-wide {
        width: 50%;
        display: inline-flex;
    }

    .interior-callouts-load-more {
        .btnOutline {
            min-width: 200px;
        }
    }
}


// BRANCH SMC NEWS SECTION
.branch-location-news-wrapper {
    width: 100%;
    background-color: $color-primary;
    background-image: url('../../../images/layout/locations-news-bkgd.jpg');
}

.branch-location-news-container {
    @include containers;
    text-align: center;
    padding: 30px 0;
}

.branch-location-news-title {
    color: $white;
    @include title-font-large;
    padding: 30px 0;
}

.branch-location-news-items-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .news-item-container-wide {
        width: 45%;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: $white;
        margin: 0 2% 0 0;

        &:nth-of-type(2n+0) {
            margin: 0 0 0 2%;
        }
    }

    .news-item-content-container-wide {
        width: 50%;
        display: inline-flex;
        flex-wrap: wrap;
        padding: 30px;
    }

    .news-item-title-wide {
        @include title-font-small;
        text-align: left;

        a {
            color: $black;
        }
    }

    .news-item-line-wide {
        @include title-line;
        width: 50px;
        margin: 20px 50px 20px 0;
    }

    .news-item-btn-wide {
        padding: 15px 30px 0;

        a {
            min-width: 150px;
        }
    }

    .news-item-image-wide {
        width: 50%;
        display: inline-flex;
    }
}