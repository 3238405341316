﻿.account-users-container {
    margin: 0 0 30px 0;
    border: 1px solid $color-border;

    .account-users-header {
        margin: 30px;
        padding: 0 0 15px 0;
        border-bottom: 1px solid $color-border;
    } 

    .paging {         
        input {
            &[type=number] {
                padding: 0 8px 0 10px;
                height: 52px;
            }
        }
    }

    @media (max-width: 480px) {
        .paging {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            >* {
                display: inline-block;
                &:first-child {
                    width: 100%;
                  }
                &:not(:first-child) {
                    flex: 1;
                } 
                &:nth-child(2) {
                    height: 52px;
                }
                &:nth-child(3) {
                    height: 52px;
                }                  
                &:nth-child(5) {
                    height: 52px;
                } 
            }
            .roc-pageOptions-count {
                margin-right: 0;
                margin-bottom: 10px;
            } 
        }        
    } 

    .title {
        width: 49%;
        display: inline-block;
        font-size: 1.75em;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .btn-wrapper {
        width: 50%;
        display: inline-block;
        text-align: right;
        float: right;
    }

    .account-users-pagination {
        margin: 30px;

        .paging {
            float: right;
        }
    }
}

#pendingSubUsers {
    .account-users-header {
        .title {
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
}

.table-headings {
    font-weight: 700;
    border-bottom: 1px solid $color-border;
}

.account-users-table {
    margin: 30px;
    margin-top: 0;
    .user-col {
        display: inline-block;
        padding: 15px;
    }

    // .user-fname, .user-lname { width: 20%; }

    // .user-date { width: 12%; }

    // .user-email { width: 30%; }

    .user-action { 
        // width: 15%; 
        text-align: right;

        a {
            color: $link-text;
        }
    }

    .user-edit {
        margin: 0 5px 0 0;
        padding: 0 10px 0 0;
        border-right: 2px solid $color-text-primary;
    }

}

.account-users-table-wrap {
    overflow-x: auto;
}
.account-users-table {
    display: table;
    min-width: 738px;
    width: -webkit-fill-available; 
    .table-headings,
    .table-content {
        display: table-row;
        >div {
            display: table-cell;
            vertical-align: top;
        }
    }
}

// #pendingSubUsers {
//     .account-users-table {

//         .user-fname, .user-lname { width: 25%; }

//         .user-email { width: 35%; }
//     }
// }