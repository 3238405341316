﻿.ordersList {
 
    .orderItem-wrapper {
        padding: 30px 0 0 0;
        margin: 30px 0 0 0;
        border: 1px solid $color-border;

        &:first-of-type {
            padding: 0;
            margin: 0;
            border: none;
        }
    }

    .order-details, .order-actions {
        display: inline-block;
        vertical-align: top;
    }

    .order-details {
        width: 74%;
        padding: 0 50px 0 0;
    }

    .order-actions {
        width: 25%;
    }

    .order-date {
        color: $dark-gray;
        font-weight: 500;
        margin: 0 0 5px 0;
    }

    .cart-name {
        font-size: 1.25em;
        font-weight: 600;
    }

    .order-total {
        font-weight: 600;
        margin: 0 0 25px 0;
    }

    .order-show-btn {
        .btn {
            width: 100%;
            margin: 0 0 25px 0;
        }
    }

    .order-delete-btn {
        text-align: center;

        .btnText {
            color: $color-primary;
            font-weight: 600;
            padding: 5px;
        }
    }

    .order-ship-to {
        margin: 0 0 40px 0;

        .ship-to {
            font-weight: 600;
        }
    }

    .product-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.75em;
    }

    .product-image {
        width: 20%;

        a {
            width: 160px;
            border: 1px solid #dfdfdf;
        }
    }

    .product-content {
        width: 50%;
    }

    .product-sku {
        color: $dark-gray;
    }

    .product-title {
        @include sm-callout-title-font;
        margin: 0 0 15px 0;

        a {
            color: $black;
        }
    }

    .product-action {
        width: 25%;
        font-weight: 600;
        text-align: right;
    }

    .product-list {
        .unit-price, .total-price {
            font-size: .9em;
            margin: 0;
        }
    }

    .orderList-summary {
        margin: 25px 0;

        .summary-left {
            width: 49%;
            display: inline-block;
        }
        .summary-right {
            width: 50%;
            display: inline-block;
            text-align: right;
        }
    }

    .load-cart-btn {
        text-align: right;
    }
}


.saved-carts-container {
    padding: 30px;
    border: 1px solid $color-border;
}

.order-item-container {
    padding: 20px 0 0 0;
    margin: 20px 30px 0 30px;
    border-top: 1px solid $color-border;

    &:first-of-type {
        padding: 0;
        margin: 30px 30px 0 30px;
        border: none;
    }

    .order-item-header {
        padding: 10px 0 30px 0;
        margin: 0;

        .order-item-details, .order-item-action {
            display: inline-block;
            vertical-align: top;
        }

        .order-item-details { width: 50%; }
        .order-item-action { width: 100%; }

        .order-num {
            font-size: 1.25em;
            font-weight: 600;
            margin: 0 0 5px 0;
        }

        .order-name, .order-loc {
            font-weight: 600;
            margin: 0 0 5px 0;
        }

        .order-status {
            font-size: 1.25em;
            font-weight: 500;
            margin: 0 0 5px 0;
        }

        .order-approver {
            font-weight: 500;
            margin: 0 0 5px 0;
        }

        .order-total {
            font-weight: 500;
            margin: 0 0 15px 0;
        }

        .order-btns-wrapper {
            display: flex;

            .btn, .btnAction, .btnAlt {
                width: 50%;
                display: inline-block;
                margin: 0;
				padding-left: 15px;
				padding-right: 15px;				

                &:first-of-type {
                    margin: 0 4% 0 0;
                }
                &:nth-of-type(3n) {
                    padding: 15px 4% 0 0;
                }
                &:nth-of-type(4n) {
                    padding: 15px 0 0 0;
                }
            }

            @media(max-width:500px) {
                .btnAction {
                    width: 100%;
                    margin: 2px auto;
                }
            }
        }
    }

    .order-item-content {
        margin: 0 0 30px 0;
        border: 1px solid $color-border-dark;
    }

    .order-address-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 30px;

        .info-container {
            width: 48%;
            display: inline-flex;
            flex-wrap: wrap;
            border: 1px solid $color-border-dark;

            &:first-of-type {
                margin: 0 4% 0 0;
            }

            .content-left, .content-right {
                width: 49%;
                display: inline-block;
                vertical-align: top;

                div {
                    margin: 0 0 5px 0;
                }
            }
        }

        .info-header {
            width: 100%;
            background-color: $gray;
            padding: 15px;

            .title {
                font-size: 1.35em;
                font-weight: 600;
                margin: 0;
            }
        }

        .info-content {
            width: 100%;
            height: 80%;
            padding: 15px;
        }

        .sub-title {
            font-weight: 600;
        }
    }

    .order-product {
        padding: 0 30px 30px 30px;
    }
}

.orderList-summary-wrapper {
    background-color: $gray;

    .orderList-summary {
        margin: 0;
        padding: 30px;

        .summary-left {
            font-weight: 600;
            margin: 0 0 10px 0;

            &:last-of-type {
                margin: 0;
            }
        }

        .order-total {
            font-weight: 600;
            margin: 0;
        }
    }
}