.white-popup {
	background: #fff;
	text-align: left;
	padding: 20px 33px;
	max-width: 650px;
	margin: 40px auto;
	position: relative;	
	.modal-header {
	    margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-border;

		> .title {
			margin: 0 0 10px;
            padding: 0;
			font-size: 1.4em;
		}

		> .titleAlt {
			font-size: 1.2em;
			margin: 0 0 20px;
			padding: 0;
			border: none;
		}
	}

    .modal-body {
		.row {
			margin: 0 !important;
			padding: 0 !important;
			.quick-view-top-table {
				display: table;
				table-layout: fixed;
				width: 100%;
				.quick-view-top-table-tr {
					display: table-row;
					.quick-view-top-table-td {
						display: table-cell;
						vertical-align: top;
						padding-top: 35px;
						padding-bottom: 15px;
						padding-left: 40px;
						padding-right: 18px;
						@media(max-width:579px) {
							display: table;
							width: 100%;
							padding-top: 20px;
							padding-left: 20px;
							padding-right: 20px;
							padding-bottom: 5px;
						}						
						.itemSku {
							display: block;
							font-family: "IBM Plex Sans",sans-serif;
							font-size: 16px;
							font-weight: 600;
							line-height: 19px;
							color: #767676;
							margin-bottom: 5px;
						}
						h2, .titleProduct {
							display: block;
							font-family: Montserrat,Arial,sans-serif;
							font-size: 16px;
							font-weight: 900;
							line-height: 22px;
							color: #0a4e8c;
							margin: 0;
							padding: 0;
						}
						.productImage {
							max-width: 200px;
							+.altViews  {
								display: none !important;
							}
						}
						+.quick-view-top-table-td {
							padding-left: 0;
							padding-right: 40px;
							@media(max-width:579px) {
								display: table;
								width: 100%;
								padding-top: 10px;
								padding-left: 20px;
								padding-right: 20px;
								padding-bottom: 15px;
							}							
							.itemButtons {
								.btn {
									&.btnAction {
										margin-left: 0 !important;
										width: 100% !important;
										background-color: #e4701e;
									}
								}
							}
							.productDetails {
								padding-left: 18px;
								border-left: 1px solid #eaeaea;
								@media(max-width:579px) {
									padding-left: 0;
									padding-top: 20px;
									border-top: 1px solid #eaeaea;
									border-left: none;
								}								
								.detailsRow {
									padding: 0 0 5px;
									.itemPrice {
										font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
										font-size: 18px;
										font-weight: 400;						
										.price {
											display: block;
											font-family: "IBM Plex Sans",sans-serif;
											font-size: 24px;
											font-weight: 700;
											line-height: 29px;
											color: #333;
											margin: 0;
											padding: 0;
										}
									}
								}
								h2 {
									display: block;
									font-family: "IBM Plex Sans",sans-serif;
									font-size: 18px;
									font-weight: 600;
									line-height: 22px;
									color: #333;
									margin: 0;
									padding: 0;
								}
								.delivery-option-list {
									.delivery-options-mfg {
										padding: 0 !important;
									}
									ul {
										margin-top: 10px;
										.delivery-option {
											.content {
												padding-bottom: 0;
											}
										}
									}
									.delivery-options-pick-delivery {
										@media(min-width:481px) and (max-width:579px) {
											table-layout: fixed;
										}										
										li {
											display: table;
											width: 100%;
											text-align: left;
											@media(min-width:481px) and (max-width:579px) {
												display: table-cell;
												padding-bottom: 10px;
											}											
											label {
												padding: 10px 0 10px 35px;
												strong {
													font-size: 15px !important;
													line-height: 17px !important;
												}
												strong {
													+span {
														font-size: 14px !important;
														line-height: 16px !important;												
														padding-left: 20px;
													}
												}
											}
											.icon-pickup,
											.icon-delivery {
												top: 15px;
												.icon {
													width: 25px;
													height: 28px;
												}
											}
										}
									}
								}
							}
							.itemQuantity {
								&.formee {
									display: block;
									color: #333;
									margin: 20px 0 10px;
									text-align: left;
									width: 100%;
									@media (max-width: 767px) {
										text-align: center;
									}					
									.pdp-qty-sub-add-block {
										display: inline-block;
										vertical-align: middle;
										margin-left: 7px;
										@media(max-width:579px) {
											margin-left: 0;
										}										
									}
									label {
										position: relative;
										margin: 0;
										font-family: "IBM Plex Sans",sans-serif;
										font-size: 16px;
										font-weight: 600;
										line-height: 18px;
										text-align: left;
										text-transform: uppercase;
										color: #333;
										width: 49px;
										height: 25px;
										vertical-align: middle;
										display: inline-block;
										&:after {
											position: absolute;
											right: 10px;
											display: inline-block;
											content: "*";
											font-family: $font-stack;
											font-size: 17px;
											color: #ff0000;
											border: none;
										}						
									}
									input {
										&[type=number] {
											border: 1px solid #ccc;
											border-radius: 0;							
											width: 50px;
											height: 50px;
											margin: 0;
											padding: 0;
											-moz-appearance: textfield;
											text-align: center;
											/* Hide HTML5 Up and Down arrows. */
											&::-webkit-outer-spin-button,
											&::-webkit-inner-spin-button {
												-webkit-appearance: none;
												margin: 0;
											}
										}
									}
									button {
										&.pdp-qty-sub {
											position: relative;
											display: inline-block;
											vertical-align: top;
											border: 1px solid #ccc;
											background: none;
											border-radius: 0;							
											width: 50px;
											height: 50px;	
											@media (min-width:980px) and (max-width:1279px) {
												width: 45px;
											}							
											&:after {
												content: '';
												display: table;
												position: absolute;
												top: 23px;
												left: 17px;
												margin: 0 auto;
												width: 14px;
												height: 0;
												border-bottom: thin solid #767676;
												transform: rotate(0);
												transition: width 0.5s ease-in-out;
											}
											&:hover,
											&:focus {
												&:after {
													-webkit-transform: scale(1.15);
													-ms-transform: scale(1.15);
													transform: scale(1.15);								
												}
											}							
										}
										&.pdp-qty-add {
											position: relative;
											display: inline-block;
											vertical-align: top;
											border: 1px solid #ccc;
											background: none;
											border-radius: 0;	
											width: 50px;
											height: 50px;
											@media (min-width:980px) and (max-width:1279px) {
												width: 45px;
											}							
											&:before {
												content: '';
												display: table !important;
												position: absolute;
												top: 23px;
												left: 17px;
												margin: 0 auto;
												width: 14px;
												height: 0;
												border-bottom: thin solid #767676;
												transform: rotate(90deg);
												transition: width 0.5s ease-in-out;							
											}							
											&:after {
												content: '';
												display: table !important;
												position: absolute;
												top: 23px;
												left: 17px;
												margin: 0 auto;
												width: 14px;
												height: 0;
												border-bottom: thin solid #767676;
												transform: rotate(0);
												transition: width 0.5s ease-in-out;								
											}
											&:hover,
											&:focus {
												&:before {
													-webkit-transform: scale(1.15) rotate(90deg);
													-ms-transform: scale(1.15) rotate(90deg);
													transform: scale(1.15) rotate(90deg);								
												}								
												&:after {
													-webkit-transform: scale(1.15);
													-ms-transform: scale(1.15);
													transform: scale(1.15);								
												}
											}							
										}
									}
								}
							}
							.itemButtons {
								flex-direction: column;
								.itemButtons-in {
									+.itemButtons-in {
										margin-top: 10px;
										@media (max-width: 767px) {
											margin-top: 0;
										}						
									}
									.roc-addToCartFavoritesWrapper {
										display: block;
									}
								}
								.btn {
									&.btnAction {
										width: 353px;
										height: 50px;
										margin-left: 15px;
										margin-right: 0 !important;
										text-transform: none;
										border-radius: 0;
										border: none;
										font-family: $font-stack;
										font-size: 16px;
										font-weight: 600;
										@media (min-width:980px) and (max-width:1279px) {
											width: 280px;
										}
										@media (max-width: 767px) {
											width: 100%;
											margin-left: 0;
										}						
									}
									&.btnTextAction {
										width: 353px;
										height: 50px;
										margin-left: 15px;
										margin-right: 0 !important;
										text-transform: none;
										border-radius: 0;
										border: none;
										font-family: $font-stack;
										font-size: 16px;
										font-weight: 600;
										letter-spacing: 0;
										@media (min-width:980px) and (max-width:1279px) {
											width: 280px;
										}
										@media (max-width: 767px) {
											width: 100%;
											margin-left: 0;
										}						
										&.btnText {
											margin-left: 15px;
											margin-right: 0 !important;
											text-transform: none;
											border-radius: 0;
											border: 1px solid #ccc;
											background: none;
											font-family: $font-stack;
											font-size: 16px;
											font-weight: 600;
											color: #0a4e8c;
											@media (max-width: 767px) {
												width: 100%;
												margin-left: 0;
											}								
											span {
												position: relative;								
												display: table;
												margin: 0 auto;
												color: #0f518e;
												span {
													position: relative;
													padding-left: 25px;
													&:first-child {
														position: absolute;
														top: 2.3px;
														left: 0;										
														border: 2px solid #0f518e;
														border-radius: 50px;
														width: 16px;
														height: 16px;
														padding: 0;
														font-size: 15px;
														font-weight: 600;
														line-height: 12px;
														text-align: center;
														color: #0f518e;
														-webkit-transform: perspective(1px) scale(1.0);
														-ms-transform: perspective(1px) scale(1.0);
														transform: perspective(1px) scale(1.0);		
														/* IE10+ specific styles go here */
														@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
															line-height: 14px;
														}										
														/* Edge 12+ specific styles go here */
														@supports (-ms-ime-align:auto) {
															line-height: 14px;
														}										
														/* Firefox */
														@supports (-moz-appearance:none) { 
															width: 17px;
															height: 17px;
															line-height: 10px;
														}										
													}
												}							
											}
											&:hover,
											&:focus {
												border: 1px solid rgba(204, 204, 204, 0);
												-moz-box-shadow: 0 0 10px rgba(0,0,0,.2);
												-webkit-box-shadow: 0 0 10px rgba(0,0,0,.2);
												-o-box-shadow: 0 0 10px rgba(0,0,0,.2);
												box-shadow: 0 0 10px rgba(0,0,0,.2);								
											}
										}						
									}					
								}
							}							
						}
					}
				}
			}
			+.row {
				background-color: #dfdfdf;
				.quick-view-bottom-table {
					display: table;
					width: 100%;
					.quick-view-bottom-table-tr {
						display: table-row;
						.quick-view-bottom-table-td {
							display: table-cell;
							vertical-align: top;
							padding-top: 30px;
							padding-bottom: 0;
							padding-left: 40px;
							padding-right: 18px;
							@media(max-width:579px) {
								display: table;
								width: 100%;
								padding-top: 25px;
								padding-left: 20px;
								padding-right: 20px;
								padding-bottom: 5px;
							}							
							+.quick-view-bottom-table-td {
								padding-left: 0;
								padding-right: 40px;
								text-align: right;
								width: 41%;
								@media(max-width:767px) {
									width: 50%;
								}
								@media(max-width:579px) {
									display: none;
								}								
								p {
									strong {
										color: #333;
									}									
								}
							}
							p {				
								font-family: "IBM Plex Sans",sans-serif;
								font-size: 18px;
								font-weight: 500;
								line-height: 22px;
								color: #333;
								margin-bottom: 0;
								@media(max-width:579px) {
									text-align: center;
								}								
								strong {
									color: #0a4e8c;
								}							
							}
							.btn {
								&.btnDefault {
									margin-top: 20px;
									margin-left: 0 !important;
									padding-left: 12px;
									padding-right: 12px;
									width: 67%;
									@media(max-width:767px) {
										width: 100%;
									}
									@media(max-width:579px) {
										margin-top: 5px;
									}
								}
							}
							.btn {
								&.btnProcess {
									margin-top: 20px;
									margin-left: 0 !important;									
									padding-left: 12px;
									padding-right: 12px;									
									width: 100% !important;	
								}
							}							
						}
						+.quick-view-bottom-table-tr {
							.quick-view-bottom-table-td {
								padding-top: 0;
								padding-bottom: 35px;
								@media(max-width:579px) {
									display: table;
									width: 100%;
									padding-top: 0;
									padding-left: 20px;
									padding-right: 20px;
									padding-bottom: 10px;
									.items-count {
										display: block;
										margin-bottom: 5px;
									}									
								}
								@media(min-width:580px) {
									.items-count {
										display: none;
									}									
								}								
								+.quick-view-bottom-table-td {
									@media(max-width:579px) {
										display: table;
										width: 100%;
										padding-top: 15px;
										padding-left: 20px;
										padding-right: 20px;
										padding-bottom: 25px;
									}									
									.btn {
										&.btnProcess {
											background-color: #e4701e;
											width: 100%;
											@media(max-width:579px) {
												margin-top: 5px;
											}
										}										
									}
								}								
							}
						}
					}
				}
				.col-sm-6 {
					padding-left: 30px !important;
					padding-bottom: 35px !important;
					font-family: "IBM Plex Sans",sans-serif;
					font-size: 17px;
					font-weight: 500;
					line-height: 22px;
					color: #333;
					margin-bottom: 0;
					strong {
						color: #0a4e8c;
					}
					.btn {
						&.btnDefault,
						&.btnProcess {
							margin-top: 20px;
							margin-left: 0 !important;
							width: 100% !important;				
						}
						&.btnProcess {
							background-color: #e4701e;
							.icon-arrow-bracket-right {
								display: none;
							}
						}
					}
					+.col-sm-6 {
						padding-right: 30px !important;
						text-align: right;
						strong {
							color: #333;
						}						
					}
				}
			}	
			.delivery-options-mfg {
				font-family: $font-stack;
				width: 100%;
				position: relative;
				display: block;
				margin: 10px 0 0 !important;
				padding: 7px 0 !important;
				color: #333;
				li {
					display: block;
					margin: 0 !important;
					padding: 0 !important;		
					background-color: #fff !important;
					border: none !important;
					text-align: left;
					>.delivery-option {
						margin: 3px 0 !important;
						border: 1px solid #ccc !important;
					}
					label {
						position: relative;
						display: block;
						margin: 0 !important;
						padding: 13px 18px;
						font-family: $font-stack;
						font-size: 16px !important;
						font-weight: 400 !important;
						line-height: 21px;
						color: #333 !important;
						-wekbit-transition: all 0.9s ease-in-out;
						transition: all 0.9s ease-in-out;			
						strong {
							font-family: $font-stack;
							font-size: 16px !important;
							font-weight: 400 !important;
							line-height: 24px;
							color: #333 !important;
						}
						&:before {
							position: absolute;
							content: '';
							bottom: 0;
							left: 0;
							width: 0;
							height: 3px;
							background-color: #e26f1e;
							transform-origin: left;
							-wekbit-transition: all 0.9s ease-in-out;
							transition: all 0.9s ease-in-out;	
							z-index: 1;				
						}
						button {
							&:hover,
							&:focus {
								-webkit-transform: perspective(1px) scale(1);
								-ms-transform: perspective(1px) scale(1);
								transform: perspective(1px) scale(1);
								transition: all 1s cubic-bezier(.25,.46,.45,.94);	
								box-shadow: 0 0 0 0 transparent !important;
								text-shadow: 0 0 !important;							
							}							
						}
						&:hover,
						&:focus {
							background-color: #dfdfdf;
							-webkit-transform: perspective(1px) scale(1);
							-ms-transform: perspective(1px) scale(1);
							transform: perspective(1px) scale(1);
							transition: all 1s cubic-bezier(.25,.46,.45,.94);	
							box-shadow: 0 0 0 0 transparent !important;
							text-shadow: 0 0 !important;							
							
							&:before {
								width: 100%;
							}
						}
					}
					input {
						&[type="radio"] {
							width: 0 !important;
							height: 0 !important;
							margin: 0 !important;
							padding: 0 !important;
							visibility: hidden;
							position: absolute;
							&:checked {
								+label {
									background-color: #dfdfdf;
									&:before {
										width: 100%;
									}						
								}
							}
						}
					}		
				}
			}
		}
        .divider {
            margin: 25px 0;
            text-align: center;
            line-height: 0;
            border-bottom: 1px solid $color-border;
        
            > span {
                padding: 0 5px;
                background: #fff;
            }
        }
    }
	
	.modal-footer {
		text-align: right;
	}
    .mfp-close {
        color: #fff;
        width: 22px;
        height: 22px;
        font-size: 25px;
        font-weight: 300;		
		line-height: 20px;
		letter-spacing: -1px;
        opacity: 1;
		background: #767676;
		border-radius: 50px;
		margin: 10px 10px 0 0;
    }	
}

#quickview-modal {
	&.white-popup {
		padding: 0;	
		max-width: 560px;
		@media (max-width: 767px) {
			max-width: 84%;
		}
	}
}

#termsAndConditionsModal {
	&.white-popup {
		max-height: 400px;
		padding-bottom: 50px;
		.terms-conditions-content {
			max-height: 320px;
			overflow: auto;
			position: relative;
			// 768px 
			@media(min-width:48em) {
				margin-bottom: 30px;
				z-index: 9;
			}			
			h2 {
				&:first-child {
					margin-top: 0;
				}	
			}
		}
		.terms-conditions-buttons {
			position: absolute;
			left: 0;
			right: 0;			
			bottom: -10px;
			padding: 10px 30px;	
			background-color: #fff;
			z-index: 1;
			.btn {
				// 767px 
				@media(max-width:767px) {
    				font-size: .775em;
					margin-top: 5px;
					margin-bottom: 5px;
				}					
				// 389px 
				@media(max-width:389px) {
    				font-size: .575em;
					margin-top: 5px;
					margin-bottom: 5px;
				}					
			}
		}
	}
}


/* # Modal Size modifiers */

.modal-xs { max-width: 360px; }
.modal-sm { max-width: 520px; }
.modal-md { max-width: 650px; }
.modal-lg { max-width: 780px; }



.newsletter-popup, .register-popup {
	max-width: 800px;
    background: $color-secondary;
    background: -webkit-linear-gradient(left, $color-secondary 50% , $color-primary 50%);
    background: -o-linear-gradient(right, $color-secondary 50%, $color-primary 50%);
    background: -moz-linear-gradient(right, $color-secondary 50%, $color-primary 50%);
    background: linear-gradient(to right, $color-secondary 50% , $color-primary 50%);

    .mfp-close {
        background: transparent;
        color: $white;
        width: 50px;
        height: 50px;
        font-size: 42px;
        font-weight: 400;
        margin: 0;
        opacity: 1;
    }

    .newsletter-popup-content, .register-popup-content {
        background: $white;
        max-width: 700px;
        margin: 20px auto;
    }

    .newsletter-popup-logo, .register-popup-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0 10px 0;

        img {
            width: 90px;
            height: 91px !important;
        }
    }

    .modal-header {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border: none;
        margin: 0 0 10px 0;

        h2 {
            width: 100%;
			color: $black;
            font-size: 2.5em;
            font-weight: 500;
            text-align: center;
            margin: 0 0 5px 0;

            span {
                color: $black;
                font-weight: 700;
				text-transform: uppercase;
            }
        }

        p {
            width: 60%;
            color: $black;
            font-family: "IBM Plex Sans",sans-serif;
            font-weight: 500;
            text-align: center;
            margin: 0;
            
			/*&:after {
				content: '';
				display: block;
				position: absolute;
				background: #eaeaea;
				width: 100%;
				height: 1px;
				left: 0;
				right: 0;
				bottom: 0;
			}*/
			/*span {
				&.req-form-astr {
					position: absolute;
					right: 0;
					color: #ff0000;
					font-family: "IBM Plex Sans",sans-serif;
					font-size: 16px !important;
					font-weight: 600;		
					@media(max-width:767px) {
						font-size: 12px !important;
					}					
				}
			}*/
        }
    }

    .modal-body {
        #formContainerPopup {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .formee-group {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
        }

        .newsletter-form-container, .register-form-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0 0 15px 0;

            input, select {
                width: 80%;
                color: $black;
                height: 40px;
                @include border-default;
            }
        }

        .register-form-container {
            width: 33.333%;
            padding: 0 10px;

            &:last-of-type {
                width: 66.666%;
            }

            @media(max-width:850px) {
                width: 50%;

                &:last-of-type {
                    width: 100%;
                }
            }

            @media(max-width:550px) {
                width: 100%;
            }

            input, select {
                width: 100%;
            }
        }

        .formee-group-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 5px 0 10px 0;

            #newsletterButtonPopup {
                width: 150px;
                height: 100%;
                padding: 15px;
                border-radius: 0;

                span {
                    color: $white;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }

            #registerButtonPopup {
                width: 200px;
                height: 100%;
                padding: 15px;
                border-radius: 5px;

                span {
                    color: $white;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }

        .mktoForm {
            label {
                &.mktoLabel {
                    font-size: 16px !important;
                    line-height: 1.15em !important;
                }
            }
        }
    }

    #successContainerPopup {
        padding: 0 0 100px 0;
    }

    .terms-conditions {
        width: 180px;
        font-size: 16px;
		font-weight: 700;
		color: #0a4e8c;
        text-align: left;
		margin: -50px auto 0 0 !important;
        padding: 0;
		z-index: 9;
		@media(max-width:767px) {
			width: 100%;
			margin: 0 0 0 0 !important;
			 text-align: center;
		}		
		a {
			position: relative;
			display: inline;
			box-decoration-break: clone;
			transition: all 0.9s ease-in-out;
			background-image: linear-gradient(to bottom, rgba(10, 78, 140, 0.4), rgba(10, 78, 140, 0.4));
			background-size: 0 1px;
			background-position: 0 100%;
			background-repeat: no-repeat;
			text-decoration: none;			
			&:hover,
			&:focus {
				background-size: 100% 1px;
			}
		}
    }

}


.register-popup {
	padding: 20px 33px;
	max-width: 860px;
    background: #fff !important;
	.mfp-close {
        color: #fff !important;
        width: 22px !important;
        height: 22px !important;
        font-size: 25px !important;
        font-weight: 300 !important;		
		line-height: 20px !important;
		letter-spacing: -1px !important;
		background: #767676 !important;
		border-radius: 50px !important;
		margin: 10px 10px 0 0 !important;		
	}
	.register-popup-content {
		background: #fff !important;
		display: block !important;
		margin: 0 !important;
		width: 100% !important;
		max-width: 100% !important;			
	}
	.register-popup-logo {
		display: none !important;
	}
	.modal-header {
		>.titleAlt {
			text-align: left !important;			
		}
		h2 {
            font-size: 40px !important;
            font-weight: 600 !important;
            text-align: left !important;
			color: #333 !important;			
			margin: 0 !important;
			@media(max-width:767px) {
				 font-size: 30px !important;
			}
			span {
				font-weight: 600 !important;
				text-transform: none !important;
			}
		}
		p {
			display: block;
			position: relative;
			margin: 10px 0 0 0 !important;			
            width: 100% !important;
            color: #333 !important;
			font-size: 24px !important;
            font-weight: 600 !important;
			text-transform: none !important;
			padding-bottom: 20px !important;
			&:after {
				content: '';
				display: block;
				position: absolute;
				background: #eaeaea;
				width: 100%;
				height: 1px;
				left: 0;
				right: 0;
				bottom: 0;
			}
			span {
				&.req-form-astr {
					position: absolute;
					right: 0;
					color: #ff0000;
					font-family: "IBM Plex Sans",sans-serif;
					font-size: 16px !important;
					font-weight: 600;		
					@media(max-width:767px) {
						font-size: 12px !important;
					}					
				}
			}			
		}
	}
    .modal-body {

       .register-form-container {
            input, select {
				color: #333 !important;
				border: 1px solid #d7d7d7 !important;
            }
        }
	}
}

@media (max-width:850px) {
    .newsletter-popup, .register-popup {
        width: 500px;

        .newsletter-popup-content, .register-popup-content {
            width: 450px;
        }

        .newsletter-popup-logo, .register-popup-logo {
            padding: 15px 0 10px 0;

            img {
                width: 75px !important;
                height: 76px !important;
            }
        }

        .modal-header {
            p {
                width: 90%;
            }
        }
    }
}

@media (max-width:550px) {
    .newsletter-popup, .register-popup {
        width: 350px;

        .newsletter-popup-content, .register-popup-content {
            width: 300px;
        }

        .newsletter-popup-logo, .register-popup-logo {
            padding: 10px 0;

            img {
                width: 50px !important;
                height: 50px !important;
            }
        }

        .modal-header {

            h2 {
                font-size: 2em;
            }

            p {
                font-size: 0.9em;
            }
        }

        .modal-body {
            .newsletter-form-container, .register-form-container {
                margin: 0 0 10px 0;

                input {
                    width: 90%;
                    height: 30px;
                }
            }
        }
    }
}

@media (max-width:479px) {
	.white-popup {
		padding: 20px 15px;
	}
    .newsletter-popup, .register-popup {
        width: 300px;

        .newsletter-popup-content, .register-popup-content {
            width: 290px;
        }
	}
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px), only screen and (max-device-height: 480px) {
	.mktoMobileShow .mktoForm, .mktoForm * {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		padding: 0 !important;
	}	
}

.register-popup {
	.modal-header {
		padding: 0;

		img {
			padding: 0 0 25px 0;
		}

		h1 {
			width: 100%;
			font-size: 40px;
			font-weight: 600;
			text-align: left;
			color: #333;

			span {
				font-weight: 600;
				text-transform: none;
			}
		}

		p {
			margin: 10px 0 0 0 !important;
			width: 100%;
			color: #333;
			font-family: "IBM Plex Sans",sans-serif;
			font-size: 24px !important;
			font-weight: 600;
			text-transform: none;
		}
	}

	.modal-body {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		#formContainerPopup, #marketoFormPopupContainer {
			width: 100%;

			.formee-group {
				padding: 0 20px;
			}

			.mktoForm {
				//                display: flex;
				//                flex-wrap: wrap;
				//                align-items: flex-end;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 0 40px;
				width: 100% !important;

				@media (max-width:767px) {
					display: block;
				}

				.mktoFormRow {
					padding: 0;

					@media (max-width:767px) {
						display: block;
						width: 100%;
						margin: 0;
					}
					//					&:nth-child(12) {
					//						grid-column: 1/3;
					//					}
					/* IE10+ specific styles go here */
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
						display: inline-block;
						width: 50%;
						padding: 0 10px;
					}

					+ p {
						margin-left: 20px;
						margin-top: -10px;
						grid-column: 1/3;
						width: 100%;

						label {
							&[for="wantPayOnline"] {
								&:before {
									margin-top: 4px;
								}
							}
						}
					}

					&:last-of-type {
						width: 66%;
					}

					&:nth-of-type(11) {
						grid-column: 1/3;
						width: 100%;

						@media (max-width:767px) {
							margin-top: 15px;
						}
					}
					//                    &:nth-of-type(12) {
					//						grid-column: 1/3;
					//                        width: 100%;
					//                    }


					&:nth-of-type(9) {
						label {
							&.mktoLabel {
								strong {
									margin: 0 25px 0 0;
								}
							}
						}
					}
				}

				.mktoButtonRow {
					grid-column: 1/3;
					width: 100%;
				}

				label {
					&.mktoLabel {
						font-size: 18px !important;
						font-weight: 700 !important;
						line-height: 18px !important;
						min-height: 33px;
						display: flex;
						flex-wrap: wrap;
						align-items: flex-end;
						margin-bottom: 13px !important;

						@media (max-width:980px) {
							font-size: 16px !important;
							line-height: 16px !important;
							min-height: 30px;
						}

						.mktoAsterix {
							visibility: hidden;
							margin: -1px;
							font-size: 0;

							+ b,
							+ strong {
								display: inline-block;
								position: relative;
								margin-right: 17px !important;

								&:after {
									content: '*';
									position: absolute;
									right: -12px;
									font-size: 18px;
									color: #ff0000;
								}

								+ span {
									font-size: 12px !important;
									font-weight: 500 !important;

									@media (max-width:980px) {
										display: block;
										margin-top: 5px;
									}

									+ b,
									+ strong {
										display: none;
									}
								}
							}

							+ b {
								&:after {
									position: relative;
									right: 0;
								}

								+ b {
									display: none;
								}
							}
						}

						strong {
							font-family: "IBM Plex Sans",sans-serif !important;
							font-weight: 700 !important;
						}

						b {
							font-family: "IBM Plex Sans",sans-serif !important;
							font-weight: 700 !important;
						}
					}
				}

				.mktoFormCol {
					margin-bottom: 12px !important;
				}

				.mktoField,
				.mktoLogicalField {
					border-radius: 0 !important;
				}

				.mktoButtonRow {
					width: 100%;
					display: table;
					justify-content: right;
					text-align: right;
					padding: 10px 0;

					@media(max-width:767px) {
						justify-content: center;
						text-align: center;
					}

					div {
						text-align: right !important;
					}

					span {
						margin-left: 0 !important;
						text-align: right !important;
					}

					.mktoButtonWrap {
						&.mktoDownloadButton {
							.mktoButton {
								color: #e4701e !important;

								&:after {
									content: 'Register';
									font-size: 16px !important;
									font-weight: 500 !important;
									line-height: 20px !important;
									color: #fff !important;
									position: absolute;
									left: 0;
									right: 0;
									top: 14px;
									width: 100%;
								}

								&:hover,
								&:focus {
									color: #e4701e !important;
								}
							}

							.btn {
								color: #fff !important;

								&:hover,
								&:focus {
									color: #fff !important;
								}
							}

							.mktoButton,
							.btn {
								min-width: 205px;
								height: 50px;
								//border-radius: 5px;
								padding: 11px 25px 10px !important;
								font-size: 16px !important;
								font-weight: 500 !important;
								line-height: 20px !important;
								letter-spacing: 0 !important;
								text-transform: none !important;
								border-radius: 0 !important;
								position: relative;
								display: inline-block;
								background-color: #e4701e;
								font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
								text-align: center;
								margin: 0;
								border: none;
								-webkit-transform: perspective(1px) scale(1);
								-ms-transform: perspective(1px) scale(1);
								transform: perspective(1px) scale(1);
								transition: all 1s cubic-bezier(.25,.46,.45,.94);
								box-shadow: 0 0 0 0 transparent !important;
								text-shadow: 0 -1px #e4701e !important;

								&:hover,
								&:focus {
									padding: 11px 25px 10px !important;
									font-size: 16px !important;
									font-weight: 500 !important;
									line-height: 20px !important;
									letter-spacing: 0 !important;
									text-transform: none !important;
									border-radius: 0 !important;
									position: relative;
									display: inline-block;
									background-color: #e4701e;
									font-family: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;
									text-align: center;
									margin: 0;
									border: none;
									box-shadow: 0 0 0 0 transparent !important;
									text-shadow: 0 -1px #e4701e !important;
									-webkit-transform: perspective(1px) scale(1.04);
									-ms-transform: perspective(1px) scale(1.04);
									transform: perspective(1px) scale(1.04);
									transition: all 1s cubic-bezier(.25,.46,.45,.94);

									&:after {
										color: #fff;
									}
								}
							}
						}
					}
				}
			}
		}

		.formee-group-btn {
			margin: 20px 0 10px 0;
		}
	}
}

#quickview-modal .delivery-options-pick-delivery li:first-child .delivery-option .content:before {
	content: "Choose One";
	display: block;
	text-align: center;
	margin: auto 5px;
	padding: 0;
	font-weight: 700;
	color: #000;
}

.container-product-detail-page .productDetails {
	.delivery-options-pick-delivery li:first-child .delivery-option .content:before {
		display: none;
	}

	.delivery-options-pick-delivery:before {
		content: "Choose One";
		display: block;
		position: absolute;
		text-align: center;
		width: 100%;
		padding: 0;
		top: 5px;
		font-weight: 700;
		color: #000;
	}
}

#commentsAndPriority {
	max-width: 390px;
}

#shareFavorites {
	max-width: 390px;
	
	.modal-body {
		a {
			font-weight: normal;
			word-wrap: break-word;
		}
	}
}

#editFavorites {
	max-width: 390px;
}

#createFavorites {
	max-width: 390px;
}

#quickview-modal {
    
    .itemPrice {
        font-size: 0.875em;
    }
}

#store-pickup-modal {
    .modal-header > .productList {
        margin: 0;
    }
}

#recently-added {
    .cartList {
        .itemWrapper {
            .itemPrice {
                font-size: 1em;
                text-align: right;
            }
        }
    }
}


/* # Base Styles for BlockUI */

.blockOverlay {
    background-color: #0b0b0b !important;
    opacity: 0.5 !important;
	z-index: 9991 !important;
}

.blockMsg {
    padding: 0;
    margin:  0; 
    width: 30%;
    top: 40%; 
    left: 35%;
    text-align: center; 
    color: #000; 
    border: 3px solid #aaa;
    background-color: #fff; 
    cursor: wait;
	z-index: 9991 !important;
}

/* ## Block Overlay when element blocking */
.roc-block-container {
    min-height: 75px;

    .blockOverlay {
        background-color: #fff !important;
        opacity: 0.8 !important;
    }
}

.mktoButtonRow {
	.roc-block-container {
		min-height: auto !important;
		.blockUI {
			display: none !important;
		}
	}	
}

/* # Page/Element loading styles */

.roc-waiting {
    position: fixed;
    top: 40%;
    width: 130px;
    left: 50%;
    margin-left: -65px;
    padding: 10px 0 0;
    text-align: center;
    border: 1px solid $color-border;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25);
}

.roc-waiting-content {
    font-size: 0.75em;
        
    .loading-squares {
        margin-top: 10px;
    }
}

.loading-squares, .loading-squares * {
	box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#registerPopupContainer, #marketoFormPopupContainer {
	.register-popup-content {
		#formContainerPopup {
			label .mktoAsterix {
				& + strong:after,
				& + b:after {
					content: '' !important;
				}
			}

			.mktoRequiredField {
				label {
					display: inline-block;

					.mktoAsterix {
						& + strong {
							margin-right: 0 !important;
						}
					}

					&:after {
						content: '*';
						display: inline-block;
						font-size: 28px;
						color: red;
					}
				}
			}
		}
	}
}


.loading-squares {
	position:relative;
	display:block;
    height: 6px;
	margin: 0;
    background-color: darken($color-primary, 15%);
    overflow: hidden;
    
    span {
        display: inline-block;
        height: 6px;
        width: 20%;
        background: $color-primary;
        border-radius: 0px;
        vertical-align: top;

        &:nth-child(1) {
            -webkit-animation: fadeInFromNone 1s 0.175s infinite ease-out;
               -moz-animation: fadeInFromNone 1s 0.175s infinite ease-out;
                -ms-animation: fadeInFromNone 1s 0.175s infinite ease-out;
                 -o-animation: fadeInFromNone 1s 0.175s infinite ease-out;
                    animation: fadeInFromNone 1s 0.175s infinite ease-out;
        }
        
        &:nth-child(2) {
            -webkit-animation: fadeInFromNone 1s 0.36s infinite ease-out;
               -moz-animation: fadeInFromNone 1s 0.36s infinite ease-out;
                -ms-animation: fadeInFromNone 1s 0.36s infinite ease-out;
                 -o-animation: fadeInFromNone 1s 0.36s infinite ease-out;
                    animation: fadeInFromNone 1s 0.36s infinite ease-out;
        }
        
        &:nth-child(3) {
            -webkit-animation: fadeInFromNone 1s 0.535s infinite ease-out;
               -moz-animation: fadeInFromNone 1s 0.535s infinite ease-out;
                -ms-animation: fadeInFromNone 1s 0.535s infinite ease-out;
                 -o-animation: fadeInFromNone 1s 0.535s infinite ease-out;
                    animation: fadeInFromNone 1s 0.535s infinite ease-out;
        }
        
        &:nth-child(4) {
            -webkit-animation: fadeInFromNone 1s 0.72s infinite ease-out;
               -moz-animation: fadeInFromNone 1s 0.72s infinite ease-out;
                -ms-animation: fadeInFromNone 1s 0.72s infinite ease-out;
                 -o-animation: fadeInFromNone 1s 0.72s infinite ease-out;
                    animation: fadeInFromNone 1s 0.72s infinite ease-out;
        }
        
        &:nth-child(5) {
            -webkit-animation: fadeInFromNone 1s 0.895s infinite ease-out;
               -moz-animation: fadeInFromNone 1s 0.895s infinite ease-out;
                -ms-animation: fadeInFromNone 1s 0.895s infinite ease-out;
                 -o-animation: fadeInFromNone 1s 0.895s infinite ease-out;
                    animation: fadeInFromNone 1s 0.895s infinite ease-out;
        }
    }
}


@keyframes fadeInFromNone {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeInFromNone {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeInFromNone {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeInFromNone {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeInFromNone {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1;
	}
}