article {
	&.news {
		&.details {
			display: table;
			margin: 0 auto 0;		
			@media (max-width:767px) {
				margin-top: 0;
			}			
			.news-details-date-title {
				display: table;
				margin: 0px 0px 20px 0px;
				width: 100%;
				h1 {
					&.title {
						display: table-cell;
						vertical-align: middle;
						margin: 0;
						padding: 0;
						font-family: Montserrat,Arial,sans-serif;
						font-size: 54px;
						font-weight: 900;
						background: 0 0;
						border: 0;
						color: #373737;
						text-transform: uppercase;
						line-height: 54px;
						@media (max-width:979px) {
							display: table;
							padding: 0;
						}	
						@media (max-width:767px) {
							font-size: 30px;
							line-height: 35px;
						}							
					}
				}
				.news-details-date {
					display: table-cell;
					padding-right: 30px;            
					vertical-align: middle;
					width: 180px;     
					@media (max-width:979px) {
						display: table;
						margin: 0 0 15px;
						padding: 0;
						width: auto;
					}					
					span {
						display: table;
						padding: 8px 10px 10px;
						background-color: #dfdfdf;
						font-size: 18px;
						text-align: center;
						color: #0a4e8c;
						z-index: 1;   
						@media (max-width:767px) {
							font-size: 14px;
						}							
					}
				}
			}
			.news-details-content {
				font-size: 18px;
				font-weight: 300;
				line-height: 25px;
				color: #444;			
			}
			.go-back {
				margin: 35px 0 0;
				a {
					&.go-back {
						display: table;
						position: relative;
						.icon {
							display: block;
							fill: #0a4e8c;
							width: 10px;
							height: 16px;
							position: absolute;
							top: 2px;			
						}		
						span {
							&.td-icon {
								display: table-cell;
								vertical-align: middle;		
								width: 17px;
							}					
							&.td-txt {
								display: table-cell;
								vertical-align: middle;
								box-decoration-break: clone;
								transition: all .5s ease-in-out;
								position: relative;
								background-image: linear-gradient(to bottom,rgba(10,78,140,.3),rgba(10,78,140,.3));
								background-size: 100% 1px;
								background-position: 0 100%;
								background-repeat: no-repeat;
								font-size: 15px;
								font-weight: 400;
								line-height: 22px;
								color: #0a4e8c;
								letter-spacing: 1px;
								margin: 0;				
							}	
						}		
						&:hover,
						&:focus {
							span {
								&.td-txt {
									background-size: 0 1px;					
								}			
							}
						}
					}
				}
			}
		}
	}
}