﻿$font-stack: 'SemplicitaPro','HelveticaNeue','Helvetica Neue','Helvetica-Neue',Helvetica,Arial,sans-serif;

/* ## base colors */
$blue: #0a4e8c;
$orange: #e4701e;
$gray: #dfdfdf;
$light-gray: #f1f1f1;
$dark-gray: #656565;
$gray-lighter: lighten($gray, 30%);
$dark-gray-lighter: lighten($dark-gray, 20%);
$black: #333;
$white: #fff;
$red: #ff0000;
$green: #1e8e3e;


/* ## global colors */
$site-bg: $white;
$color-primary: $blue;
$color-primary-light: #e0ebf5;
$color-secondary: $orange;
$color-error: $red;
$color-danger: $red;
$color-action: $blue;
$color-sale: $red;
$color-info: #fcf8e1;

$nav-bg: $white;
$nav-bg-hover: $gray;
$nav-disabled: $dark-gray;

$search-bg: $gray;


/* ## base text colors */
$color-text-primary: $black;
$text-muted: $dark-gray;
$color-text-secondary: $white;
$link-text: $orange;
$link-text-hover: $blue;


/* ## border colors */
$color-border: $gray;
$color-border-light: lighten($gray, 10%);
$color-border-dark: #ccc;
$table-border-color: $color-border;


/* ## button colors */
$color-button-primary: $blue;
$color-button-hover-primary: $orange;
$color-button-secondary: $orange;
$color-button-text: $white;
$color-button-action: $color-action;

$btn-outline: $white;
$btn-outline-accent: $blue;
$btn-outline-hover: $orange;




/* ## States and Alerts */

$state-info-text:				$color-text-primary !default;
$state-info-bg:					$color-info !default;
$state-info-border:				darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-danger-text:              $color-danger !default;
$state-danger-bg:                lighten($color-danger, 50%) !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;



/* ## Panels */

$panel-bg: $gray;
$panel-text: $blue;



//** Border color for elements within panels
$panel-inner-border:          $color-border-light !default;
$panel-footer-bg:             $gray !default;

$panel-default-text:			$color-text-primary !default;
$panel-default-border:			$color-border !default;
$panel-default-heading-bg:		$gray !default;

$panel-primary-text:          $color-text-primary !default;
$panel-primary-border:        $color-border !default;
$panel-primary-heading-bg:    $color-primary !default;

$panel-secondary-text:          $white !default;
$panel-secondary-border:        $color-border !default;
$panel-secondary-heading-bg:    lighten($color-secondary, 10%) !default;

$panel-action-text:				$color-action !default;
$panel-action-border:			$color-border !default;
$panel-action-heading-bg:		$gray !default;

$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;

$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;