﻿.roc-pageOptions {
    .formee-group {
        display: block;
        margin: 0;
    }

    .formee-control {
        display: inline-block;
        width: 97%;
        height: 40px;
        margin: 0;

        @media(max-width:992px) {
            width: 96%;
        }
        @media(max-width:768px) {
            width: 95%;
        }
        @media(max-width:600px) {
            width: 92%;
        }
    }

    .formee-group-btn {
        display: inline-block;
        width: 25px;
        position: relative;
        top: 7px;
        left: -35px;
        z-index: 3;
    }

    #btnSearch {
       width: 24px;
       height: 24px;
       background-color: transparent;
       border: none;
       padding: 0;
       margin: 0;
       box-shadow: 0 1px 2px rgba(0,0,0,0);
       transform-origin: 0 0;
       -webkit-transition: all .6s cubic-bezier(.165, .84, .44, 1);
       transition: all .6s cubic-bezier(.165, .84, .44, 1);

       &:hover, &:focus {
           border-color: $color-primary;
           transform: scale(1.09, 1.09);
           -webkit-transform: scale(1.09, 1.09);
       }

       .icon {
           position: absolute;
           display: inline-block;
           width: 24px;
           height: 24px;
           top: 0;
           left: 0;
           fill: $color-primary;
           z-index: 9;
       }
    }
}