﻿.smcusa-wrapper-all {
    @font-face {
        font-family: 'flexslider-icon';
        src: url("/css/fonts/flexslider-icon.eot");
        src: url("/css/fonts/flexslider-icon.eot?#iefix") format('embedded-opentype'),url("/css/fonts/flexslider-icon.woff") format('woff'),url("/css/fonts/flexslider-icon.ttf") format('truetype'),url("/css/fonts/flexslider-icon.svg#flexslider-icon") format('svg');
        font-weight: normal;
        font-style: normal;
    }

    .flex-container a:hover, .flex-slider a:hover {
        outline: none;
    }

    .slides, .slides > li, .flex-control-nav, .flex-direction-nav {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .flex-pauseplay span {
        text-transform: capitalize;
    }

    .flexslider {
        margin: 0;
        padding: 0;
    }

    .flexslider .slides > li {
        display: none;
        -webkit-backface-visibility: hidden;
    }

    .flexslider .slides img {
        max-width: 100%;
        display: block;
    }

    .flexslider .slides:after {
        content: "\0020";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    html[xmlns] .flexslider .slides {
        display: block;
    }

    * html .flexslider .slides {
        height: 1%;
    }

    .no-js .flexslider .slides > li:first-child {
        display: block;
    }

    .flexslider {
        margin: 0 0 60px;
        background: #fff;
        border: 4px solid #fff;
        position: relative;
        zoom: 1;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: '' 0 1px 4px rgba(0,0,0,0.2);
        -moz-box-shadow: '' 0 1px 4px rgba(0,0,0,0.2);
        -o-box-shadow: '' 0 1px 4px rgba(0,0,0,0.2);
        box-shadow: '' 0 1px 4px rgba(0,0,0,0.2);
    }

    .flexslider .slides {
        zoom: 1;
    }

    .flexslider .slides img {
        height: auto;
        -moz-user-select: none;
    }

    .flex-viewport {
        max-height: 2000px;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }

    .loading .flex-viewport {
        max-height: 300px;
    }

    .carousel li {
        margin-right: 5px;
    }

    .flex-direction-nav {
        *height: 0;
    }

    .flex-direction-nav a {
        text-decoration: none;
        display: block;
        width: 40px;
        height: 40px;
        margin: -20px 0 0;
        position: absolute;
        top: 50%;
        z-index: 10;
        overflow: hidden;
        opacity: 0;
        cursor: pointer;
        color: rgba(0,0,0,0.8);
        text-shadow: 1px 1px 0 rgba(255,255,255,0.3);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .flex-direction-nav a:before {
        font-family: "flexslider-icon";
        font-size: 40px;
        display: inline-block;
        content: '\f001';
        color: rgba(0,0,0,0.8);
        text-shadow: 1px 1px 0 rgba(255,255,255,0.3);
    }

    .flex-direction-nav a.flex-next:before {
        content: '\f002';
    }

    .flex-direction-nav .flex-prev {
        left: -50px;
    }

    .flex-direction-nav .flex-next {
        right: -50px;
        text-align: right;
    }

    .flexslider:hover .flex-direction-nav .flex-prev {
        opacity: 0.7;
        left: 10px;
    }

    .flexslider:hover .flex-direction-nav .flex-prev:hover {
        opacity: 1;
    }

    .flexslider:hover .flex-direction-nav .flex-next {
        opacity: 0.7;
        right: 10px;
    }

    .flexslider:hover .flex-direction-nav .flex-next:hover {
        opacity: 1;
    }

    .flex-direction-nav .flex-disabled {
        opacity: 0 !important;
        filter: alpha(opacity=0);
        cursor: default;
        z-index: -1;
    }

    .flex-pauseplay a {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 5px;
        left: 10px;
        opacity: 0.8;
        z-index: 10;
        overflow: hidden;
        cursor: pointer;
        color: #000;
    }

    .flex-pauseplay a:before {
        font-family: "flexslider-icon";
        font-size: 20px;
        display: inline-block;
        content: '\f004';
    }

    .flex-pauseplay a:hover {
        opacity: 1;
    }

    .flex-pauseplay a.flex-play:before {
        content: '\f003';
    }

    .flex-control-nav {
        width: 100%;
        position: absolute;
        bottom: -40px;
        text-align: center;
    }

    .flex-control-nav li {
        margin: 0 6px;
        display: inline-block;
        zoom: 1;
        *display: inline;
    }

    .flex-control-paging li a {
        width: 11px;
        height: 11px;
        display: block;
        background: #666;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        text-indent: -9999px;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -moz-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -o-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }

    .flex-control-paging li a:hover {
        background: #333;
        background: rgba(0,0,0,0.7);
    }

    .flex-control-paging li a.flex-active {
        background: #000;
        background: rgba(0,0,0,0.9);
        cursor: default;
    }

    .flex-control-thumbs {
        margin: 5px 0 0;
        position: static;
        overflow: hidden;
    }

    .flex-control-thumbs li {
        width: 25%;
        float: left;
        margin: 0;
    }

    .flex-control-thumbs img {
        width: 100%;
        height: auto;
        display: block;
        opacity: .7;
        cursor: pointer;
        -moz-user-select: none;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }

    .flex-control-thumbs img:hover {
        opacity: 1;
    }

    .flex-control-thumbs .flex-active {
        opacity: 1;
        cursor: default;
    }

    @media screen and (max-width:860px) {
        .flex-direction-nav .flex-prev {
            opacity: 1;
            left: 10px;
        }

        .flex-direction-nav .flex-next {
            opacity: 1;
            right: 10px;
        }
    }

    html {
        font-size: 16px;
    }

    body {
        font-family: Arial,Helvetica,sans-serif;
        font-size: 100%;
        line-height: 1.5;
        color: #444444;
        -webkit-font-smoothing: antialiased;
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/bg_gray-stripe.png") repeat center top;
    }

    .responsive-disable-scroll {
        height: 100%;
        overflow: hidden;
    }

    a:hover {
        text-decoration: none;
    }

    ol li {
        font-size: 14px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .red {
        color: red !important;
    }

    .blue {
        color: blue !important;
    }

    .green {
        color: blue !important;
    }

    .orange {
        color: orange !important;
    }

    #top-bar {
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/bg_gray-stripe.png") repeat center top;
        height: 36px;
    }

    #topnav {
        line-height: 36px;
    }

    #topnav ul {
        padding-left: 0px;
    }

    #topnav ul li {
        list-style-type: none;
        display: inline-block;
    }

    #topnav ul li a {
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 10px 10px 10px 24px;
    }

    #topnav ul li a i {
        font-size: 14px;
    }

    #topnav ul li a:hover i {
        color: #fff;
    }

    #topnav ul.dropdown-menu li a {
        color: #444;
        background-color: transparent;
    }

    #topnav ul li a:hover, #topnav ul li a:active {
        color: #f39a00;
    }

    .global-locator {
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/icon-region.png") no-repeat 10px 7px;
        background-position: left 4px;
        background-size: 20px;
    }

    .distributor-locator {
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/icon-distributor.png") no-repeat 10px 7px;
        background-position: left 4px;
        background-size: 20px;
    }

    #topnav .login {
        background-color: #f39a00 !important;
        background-position: 10px 5px;
        font-weight: 600;
        margin-left: 10px;
        padding: 5px 10px 11px 40px !important;
        border-radius: 5px 5px 0 0;
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/icon-login.png") no-repeat 10px 3px;
        background-size: 18px;
        font-size: 14px;
        line-height: 14px;
        display: inline-block;
    }

    #topnav .login:hover {
        color: #ffffff !important;
        background-color: #c07a00 !important;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    #header {
        background-color: #e8e8e8;
        background-image: -webkit-gradient(linear,left top,left bottom,from(#ffffff),to(#e8e8e8));
        background-image: -webkit-linear-gradient(top,#ffffff,#e8e8e8);
        background-image: linear-gradient(to bottom,#ffffff,#e8e8e8);
        padding: 10px 0 0;
        border-bottom: solid 1px #bcbcbc;
        box-shadow: 0 0 10px 3px rgba(0,0,0,0.25);
    }

    .logo-mobile {
        width: 100px;
        margin: 20px 20px 10px;
    }

    #mobile-nav-container {
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/bg_gray-stripe.png") repeat center top;
        position: absolute;
        z-index: 99;
        height: 100%;
        display: none;
    }

    #mobilenav ul {
        padding-left: 0px;
    }

    #mobilenav li a {
        color: #fff;
        font-size: 16px;
        text-decoration: none;
        display: block;
        padding: 10px 20px 10px 40px;
    }

    #mobilenav li a:hover, #mobilenav li a:active {
        background-color: rgba(0,0,0,0.5);
        color: #f39a00;
    }

    #mainnav ul {
        padding-left: 0px;
    }

    #mainnav ul li {
        list-style-type: none;
        display: block;
    }

    #mainnav ul li a {
        color: #444444;
        text-decoration: none;
        font-size: 14px;
        padding: 15px 12px 15px 12px;
        white-space: pre;
    }

    #mainnav ul li a:hover, #mainnav ul li a:active {
        color: #007abf;
    }

    #mainnav ul li a:after {
        content: '|';
        margin-left: 15px;
        margin-right: -15px;
        color: #e8e8e8;
        display: inline-block;
    }

    .mobile-alt-nav, .mobile-tertiary-nav {
        display: none;
    }

    @media only screen and (min-width:992px) {
        .main-navigation {
            display: block !important;
        }

        #mainnav > ul li:first-child a {
            padding-left: 0;
        }

        #mainnav ul.dropdown-menu li:first-child a {
            padding-left: 12px;
        }

        #main-navigation[style] {
            width: initial !important;
        }
    }

    @media only screen and (max-width:991.5px) {
        .navbar-header {
            float: none;
        }

        .navbar-toggle {
            display: block !important;
            position: absolute;
            z-index: 10000;
            left: 240px;
            top: -40px;
            margin-right: 0;
            border-color: rgba(198,198,198,0.7);
            border-width: 1px;
        }

        .navbar-toggle .icon-bar {
            background-color: #fff;
            height: 3px;
            display: none;
        }

        .navbar-toggle .icon-close {
            color: rgba(198,198,198,0.7);
            display: block;
            font-size: 30px;
            line-height: 16px;
        }

        .navbar-toggle.collapsed {
            border-color: #077FBE;
            top: -5px;
            left: auto;
            right: 15px;
        }

        .navbar-toggle.collapsed .icon-bar {
            background-color: #077FBE;
            display: block;
        }

        .navbar-toggle.collapsed .icon-close {
            display: none;
        }

        .navbar-toggle:hover {
            border-color: #fff;
        }

        .navbar-toggle:hover .icon-close {
            color: #fff;
        }

        .navbar-toggle.collapsed:hover {
            border-color: #077FBE !important;
        }

        .navbar-collapse {
            border-top: 1px solid transparent;
            box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
        }

        .navbar-collapse.collapse {
            display: none !important;
        }

        .navbar-nav {
            float: none !important;
            margin: 7.5px -15px;
        }

        .navbar-nav > li {
            float: none;
        }

        .navbar-nav > li > a {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .navbar-collapse.collapse.in {
            display: block !important;
        }

        .main-navigation {
            display: none;
            position: absolute;
            z-index: 9999;
            top: 0 !important;
            width: 100%;
            min-height: 100%;
            min-width: 0;
            margin-top: 0 !important;
            -webkit-box-shadow: 10px 0 5px -2px rgba(51,51,51,0.72);
            box-shadow: 10px 0 5px -2px rgba(51,51,51,0.72);
            border-right: 1px solid gray;
            -webkit-transition: max-width 35ms ease-in-out;
            transition: max-width 35ms ease-in-out;
            -webkit-transition: width 35ms ease-in-out;
            transition: width 35ms ease-in-out;
        }

        .main-navigation.in {
            position: fixed;
            overflow-y: auto;
            max-height: 100%;
        }

        .main-navigation.collapsing {
        }

        .main-navigation.collapsed .site-mobile-logo {
            display: none;
        }

        .main-navigation .site-mobile-logo {
            margin-top: 45px;
            border-bottom: 2px solid rgba(255,255,255,0.25);
        }

        .main-navigation.in .site-mobile-logo {
            display: block;
        }

        .main-navigation .navbar {
            background: none !important;
            background-color: none !important;
            margin-top: 0px;
        }

        .navbar-nav .open .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .navbar-nav .open .dropdown-menu {
            background-color: rgba(0,0,0,0.25);
            margin: 0 0 20px;
        }

        .navbar-nav .dropdown-menu li a {
            font: 1em;
        }

        .mobile-alt-nav, .mobile-tertiary-nav {
            display: block;
        }
    }

    @media only screen and (min-width:768px) and (max-width:991.5px) {
        .main-navigation {
            max-width: 0 !important;
        }

        .main-navigation.collapsing {
            max-width: 350px !important;
        }

        .main-navigation.in {
            max-width: 350px !important;
        }
    }

    @media only screen and (max-width:767px) {
        .navbar-toggle {
            top: -5px !important;
            left: auto;
            right: 15px;
        }

        .responsive-disable-scroll #main-content {
            display: none;
        }

        .responsive-disable-scroll footer, .responsive-disable-scroll .sub-footer {
            display: none;
        }
    }

    @media only screen and (max-width:440px) {
        .hidden-mobile {
            display: none !important;
        }
    }

    .dropdown-menu {
        background: #f5f5f5;
        width: 250px;
    }

    .dropdown-menu a:after {
        content: none !important;
    }

    .dropdown-menu li a {
        margin: 0;
        padding: 10px 0;
        -webkit-transition: width 200ms ease-in-out;
        transition: width 200ms ease-in-out;
        width: 100%;
        border-bottom: solid 1px rgba(232,232,232,0.04);
    }

    .dropdown-menu li a:hover {
        background-color: #fff;
    }

    .dropdown-menu li:last-child a {
        border-bottom: none;
    }

    #mainnav ul li:last-child a:after {
        content: none;
    }

    #mainnav .popout > a {
        background-image: url("//content2.smcetech.com/NewSMCUSAImage/images/menu-right-arrows.png");
        background-position: right 0px;
        background-repeat: no-repeat;
    }

    #mainnav .popout-menu {
        display: block;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 248px;
        width: 0px;
        background: #ffffff;
        margin: 0;
        padding: 0px;
        box-shadow: 5px 5px 8px rgba(0,0,0,0.25);
        border-radius: 0 0 5px 0;
        -webkit-transition: box-shadow .5s;
        transition: box-shadow .5s;
        overflow: hidden;
        opacity: 0;
    }

    #mainnav .popout:hover .popout-menu {
        width: 500px;
        -webkit-transition: width .3s;
        transition: width .3s;
    }

    #mainnav .popout:hover + .popout:not(:hover) .popout-menu {
        border-left: 1px solid #e8e8e8 !important;
        width: 500px;
        opacity: 0;
        z-index: -1;
    }

    #mainnav .popout ~ .popout:hover .popout-menu {
        border-left: 1px solid #e8e8e8 !important;
        width: 500px;
        z-index: 100;
        min-width: 500px;
    }

    #mainnav .popout:hover .popout-menu, #mainnav .popout-menu:hover {
        border-left: 1px solid #e8e8e8 !important;
        opacity: 1;
        width: 500px;
        z-index: 100;
    }

    #mainnav .popout-menu .content {
        opacity: 0;
        width: 500px;
        padding: 20px;
        border-top: 20px solid #fff;
        padding-top: 0;
        -webkit-transition: opacity .5s .5s;
        transition: opacity .5s .5s;
    }

    #mainnav .popout:hover .popout-menu .content {
        opacity: 1;
        -webkit-transition: opacity .1s 0s;
        transition: opacity .1s 0s;
    }

    #mainnav .popout-menu .content img.hero {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: auto;
        max-width: 400px;
        margin: 0 auto;
        border-radius: 0 0 5px 0;
    }

    #mainnav .popout-menu .content h3 {
        max-width: 210px;
        font-size: 1.4em;
        color: #007abf;
        white-space: normal;
        margin-bottom: 20px;
        white-space: nowrap;
    }

    #mainnav .popout-menu .content .subMenu li {
        max-width: 240px;
        white-space: normal;
        padding: 0px;
    }

    #mainnav .popout-menu .content .subMenu li a {
        display: block;
        background: none !important;
        color: #444444;
        border: none;
        padding: 10px 0;
        -webkit-transition: padding 0.24s ease-in-out;
        transition: padding 0.24s ease-in-out;
    }

    #mainnav .popout-menu .content .subMenu li a + div {
        position: absolute;
        opacity: 0;
        z-index: -1;
        top: 140px;
        right: 0;
        width: 250px;
        height: 140px;
        background-color: #fff;
        background-position: center bottom;
        -webkit-transition: top 0.24s ease-in-out;
        transition: top 0.24s ease-in-out;
    }

    #mainnav .popout-menu .content .subMenu li a:hover + div {
        top: 20px;
        opacity: 1;
    }

    #mainnav .popout-menu .content .subMenu li:hover ~ li a + div {
        top: -140px;
        opacity: 0;
    }

    #mainnav .popout-menu .content .subMenu li.default div {
        position: absolute;
        top: 20px;
        right: 0;
        width: 250px;
        height: 140px;
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/img-menu-temp.jpg") no-repeat center bottom;
        -webkit-transition: background 0.24s ease-in-out;
        transition: background 0.24s ease-in-out;
        margin-bottom: 10px;
    }

    #mainnav .popout-menu .content .subMenu li h4 {
        position: absolute;
        z-index: 100;
        opacity: 0;
        top: 160px;
        right: 0;
        width: 250px;
        height: auto;
        font-size: 1.25em;
        color: #007abf;
        background-color: #fff;
        display: block;
        padding-top: 10px;
        padding-bottom: 21px;
        text-align: center;
        margin-bottom: -30px;
    }

    #mainnav .popout-menu .content .subMenu li.default h4, #mainnav .popout-menu .content .subMenu li:hover h4 {
        opacity: 1;
    }

    #mainnav .popout-menu .content .subMenu:hover li.default {
        opacity: 0;
    }

    #mainnav .popout-menu .content .subMenu li.default:hover {
        opacity: 1 !important;
    }

    #mainnav .popout-menu .content .subMenu li.active a, #mainnav .popout-menu .content .subMenu li a:hover {
        color: #007abf;
        padding-left: 10px;
    }

    #mainnav .popout-menu .content .subMenu-additional {
        position: absolute;
        z-index: 500;
        top: 0px;
        right: 0px;
        bottom: 0px;
        width: 250px;
        background: transparent;
        white-space: normal;
        padding: 20px;
    }

    #mainnav .popout-menu .content .subMenu-additional div {
        position: absolute;
        top: 220px;
        right: 0;
        background-color: #f5f5f5;
        padding: 14px 20px 4px;
        border: solid #e8e8e8;
        border-width: 1px 0 1px 1px;
        width: 100%;
    }

    #mainnav .popout-menu .content .subMenu-additional div h5 {
        font-size: 1.25em;
        color: #007abf;
    }

    #mainnav .popout-menu .content .subMenu-additional div ul {
        margin: 0px;
    }

    #mainnav .popout-menu .content .subMenu-additional div ul li {
        font-size: 1em;
        margin: 14px 0;
    }

    #mainnav .popout-menu .content .subMenu-additional div ul li a {
        font-size: 1em;
        color: #000;
        padding: 0;
        border: none;
    }

    #mainnav .popout-menu .content .subMenu-additional div ul li a:hover {
        background-color: transparent;
        color: #007abf !important;
        padding-left: 10px;
        transition: padding 0.24s ease-in-out;
    }

    #headnav ul {
        padding-left: 0px;
    }

    #headnav li a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        display: block;
        padding: 10px 20px 10px 40px;
    }

    #headnav li a:hover, #headnav li a:active {
        color: #f39a00;
    }

    #content {
        border-top: solid 5px #007abf;
        background: #f5f5f5;
        padding: 20px 0 40px;
    }

    @media only screen and (max-width:440px) {
        .main-content {
            margin-bottom: 50px;
        }
    }

    .validation-summary-errors, .field-validation-error {
        color: #AA0000;
        font-size: 14px;
    }

    @media only screen and (min-width:960px) {
        #content .container {
            width: 92%;
        }

        #content .container {
            padding: 0px 0 0px;
        }
    }

    .main {
        background: #ffffff;
        border: 1px solid #e8e8e8;
        padding: 30px 40px 40px 40px;
    }

    .main p {
        font-size: 14px;
    }

    .main h1 {
        font-family: Montserrat,Arial,sans-serif;
        font-size: 28px;
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .main h2 {
        font-family: Montserrat,Arial,sans-serif;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .main h3 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .main ul {
        list-style: disc;
    }

    .main ul li {
        font-size: 14px;
        white-space: normal;
    }

    @media (max-width:991.5px) {
        .main {
            margin-bottom: 30px;
        }

        .secondary {
            padding: 0px;
        }
    }

    @media only screen and (min-width:960px) {
        .main {
            margin: 0;
            padding: 20px;
            border-top: solid 1px #e8e8e8;
            margin-bottom: 60px;
        }
    }

    .login-form {
    }

    .login-form .rm {
        font-size: 16px;
        font-weight: bold;
    }

    .login-form .link-label {
        font-size: 1.2em;
    }

    .profile-form {
        margin-top: 40px
    }

    .profile-form .rm {
        font-size: 16px;
        font-weight: bold;
    }

    .better-placeholder {
        position: relative;
        margin-bottom: 15px !important;
    }

    .better-placeholder label {
        max-width: 110%;
        visibility: visible;
        -webkit-transform: scale(.8);
        -ms-transform: scale(.8);
        transform: scale(.8);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
        color: #428bca;
        position: absolute;
        margin-top: 3px;
        margin-left: 12px;
        font-size: 14px;
        z-index: 100;
    }

    .better-placeholder input {
        font-weight: 400;
        letter-spacing: 1.1px;
        padding: 25px 12px 5px 12px !important;
        height: auto !important;
        margin-bottom: 0px;
        font-size: 15px;
        display: block;
        line-height: 1;
        color: #444444;
        border-radius: 4px;
        vertical-align: middle;
        box-shadow: none;
        border: solid 1px #bcbcbc;
        width: 100%;
        font-family: "proxima-nova",Arial,sans-serif;
        background-color: #ffffff;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    .better-placeholder textarea {
        padding-top: 15px;
    }

    .better-placeholder input.input-validation-error {
        border-color: #ff0000;
    }

    .better-placeholder select {
        height: 46px;
        padding-top: 12px;
    }

    .standard-button {
        background: #007abf;
        padding: 8px 13px;
        color: #fff;
        font-size: 17px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border: 1px solid #1A87B9;
        -webkit-font-smoothing: antialiased;
        -webkit-transition: background 0.24s ease-in-out;
        transition: background 0.24s ease-in-out;
    }

    .standard-button:hover {
        background: #00598c;
        -webkit-transition: background 0.24s ease-in-out;
        transition: background 0.24s ease-in-out;
    }

    .submit-button {
        background: rgb(99,169,9) !important;
        border-color: darkseagreen;
    }

    #footnav ul {
        padding-left: 0px;
    }

    #footnav li a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        display: block;
        padding: 10px 20px 10px 40px;
    }

    #footnav li a:hover, #footnav li a:active {
        color: #f39a00;
    }

    .navbar {
        display: block;
        width: 100%;
        min-height: 44px;
        height: auto;
        background: #ffffff;
        border-top: solid 2px #e8e8e8;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .search-field {
        margin-left: 0;
        margin-right: 0px;
    }

    #search {
        background: #e8e8e8;
        padding: 10px;
        border: solid 1px #bcbcbc;
        border-radius: 4px;
        overflow: hidden;
        margin: 5px auto;
    }

    #search label {
        line-height: 40px;
        padding: 0px;
        margin: 0px;
    }

    #search .btn-default {
        border: solid 1px #00598c;
        height: 40px;
    }

    #search .input-group {
        width: 100%
    }

    #search .input-group .input-group-btn {
        position: absolute;
        right: 40px;
        z-index: 1000;
        width: 0;
    }

    #search .search-label {
        width: 11.5% !important;
    }

    @media only screen and (max-width:1250.5px) {
        #search .search-label {
            width: 15% !important;
        }
    }

    @media only screen and (max-width:991.5px) {
        #search .search-label {
            width: 10% !important;
        }
    }

    @media only screen and (max-width:767px) {
        #search {
            background: none;
            padding: 0;
            border: none;
            border-radius: 0;
            overflow: hidden;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        #search .search-label {
            display: none;
        }

        #search .search-input {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media only screen and (max-width:440px) {
    }

    .search-txt {
        font-size: 15px;
        margin-top: 8px;
    }

    #searchForm {
        position: relative;
    }

    .btn-search {
        background-color: #007abf;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    .btn-search:hover, .btn-search:active {
        background-color: #00598c;
    }

    .btn-search i {
        color: #fff;
        font-size: 16px;
    }

    #searchTerm {
        width: 100% !important;
        max-width: 100% !important;
        height: 40px !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-right: 50px;
    }

    .site-logo {
        margin-top: 10px;
        margin-bottom: -10px;
    }

    .site-logo .slogan {
        color: #007abf;
        font-size: 14px;
        font-weight: bold;
    }

    .site-mobile-logo {
        display: none;
    }

    .site-mobile-logo img {
        padding-bottom: 10px;
    }

    .site-mobile-logo .slogan {
        color: #fff;
    }

    @media only screen and (max-width:767px) {
        .site-logo .slogan {
            display: none;
        }

        .site-mobile-logo .slogan {
            display: none;
        }
    }

    .featured {
        font-family: "proxima-nova",Arial,sans-serif;
        font-weight: 400;
        background-color: #003959;
        background-image: -webkit-gradient(linear,left top,left bottom,from(#007abf),to(#003959));
        background-image: -webkit-linear-gradient(top,#007abf,#003959);
        background-image: linear-gradient(to bottom,#007abf,#003959);
    }

    .container {
        width: 95%;
        max-width: 1540px;
    }

    #slider .container {
        width: 100%;
        max-width: 1540px;
    }

    .navbar-collapse {
        padding: 0px;
    }

    .featured .featured-banner {
        display: table;
    }

    .featured .featured-banner .featured-text, .featured .featured-banner .featured-image {
        display: table-cell;
    }

    .featured .featured-banner .featured-text {
        width: 60%;
        vertical-align: top;
    }

    .featured .featured-banner .featured-image {
        width: 40%;
        vertical-align: middle;
    }

    .featured h1 {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .featured p {
        color: #fff;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .featured a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
    }

    .featured a:hover {
        color: #f39a00;
    }

    .read-more::after {
        content: '❯';
        margin-left: 5px;
    }

    #slider {
        position: relative;
        background: #00598c url("https://content2.smcetech.com/NewSMCUSAImage/images/bg_blue-stripe.png") repeat 0 0;
        padding: 20px;
        overflow: hidden;
        margin-bottom: 0px;
        border: none;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
    }

    #slider h2 {
        color: #fff;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 2.5em;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 0 !important;
    }

    .slides h2 span {
        color: #f39a00;
        font-size: 0.7em;
    }

    .flex-direction-nav a:before {
        color: rgba(255,255,255,0.8);
    }

    .flex-direction-nav a {
        width: 45px;
        height: 45px;
    }

    .orange-bubble {
        display: inline-block;
        width: 50px;
        height: 50px;
        font-size: 0.8em;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        border-radius: 100px;
        background: #f39a00;
        position: absolute;
        right: 35%;
        bottom: 15%;
    }

    .orange-bubble span {
        line-height: 50px;
        text-align: center;
        display: block;
        width: 50px;
    }

    .orange-btn {
        background-color: #f39a00;
        padding: 14px 20px 12px 20px;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
        border-radius: 4px;
        font-weight: bold;
        font-size: 16px !important;
        display: inline-block;
        margin: 15px auto;
    }

    .orange-btn:hover {
        color: #fff !important;
        background-color: #c07a00;
    }

    .orange-btn::after {
        content: '❯';
        margin-left: 15px;
        font-weight: 100;
    }

    @media only screen and (max-width:1199.5px) {
        .featured .container .promo {
            display: none !important;
        }

        .featured .container .banner {
            width: 100% !important;
        }
    }

    @media only screen and (max-width:991.5px) {
        .featured .container {
            width: 100%;
        }

        .flexslider {
            max-height: 500px;
            min-height: 334px;
        }

        .flexslider .img-responsive {
            max-width: 275px;
        }

        .slides h2 span {
            font-size: 22px;
        }

        .slides h2 {
            font-size: 26px;
        }

        .featured a {
            font-size: 13px !important;
        }

        #slider .orange-bubble {
            bottom: 10%;
        }
    }

    @media only screen and (max-width:768.5px) {
        #slider .container h2 {
            font-size: 2.5em;
            line-height: 1;
            margin-bottom: 20px;
            padding-top: 0 !important;
        }

        .featured .container .container-content .flexslider .slides img {
            width: 170px;
        }

        #slider .orange-btn {
            font-size: 1em;
            padding: 14px 40px 12px 20px;
        }

        #slider .orange-bubble {
            bottom: 15%;
        }
    }

    @media only screen and (max-width:677.5px) {
        .featured .featured-banner {
            display: block;
        }

        .featured .featured-banner .featured-text, .featured .featured-banner .featured-image {
            display: inline-block;
        }

        .featured .featured-banner .featured-text {
            width: 100%;
            vertical-align: top;
        }

        .featured .featured-banner .featured-image {
            width: 40%;
            float: right;
            margin-top: -14%;
        }
    }

    @media only screen and (max-width:599.5px) {
        .featured, .flexslider {
            min-height: 180px;
        }

        #slider {
            padding-left: 40px;
            padding-right: 40px;
        }

        .featured .container {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }

        #slider .orange-bubble {
            bottom: 0%;
        }

        #slider p {
            display: none;
        }
    }

    @media only screen and (max-width:440px) {
        .featured, .flexslider {
            min-height: 150px;
        }

        #slider .orange-btn {
            padding-right: 20px;
        }

        .featured .container .container-row {
            margin-left: 0;
            margin-right: 0;
        }

        .featured .container .container-content {
            padding-left: 0;
            padding-right: 0;
        }

        .featured .container .container-content .flexslider .slides img {
            width: 150px;
        }

        #slider {
            padding-left: 20px;
            padding-right: 20px;
        }

        #slider h2 span {
            font-size: 0.7em;
            color: #f39a00;
        }

        #slider h2 {
            color: #ffffff;
            margin-bottom: 10px;
        }

        #slider .orange-bubble {
        }

        .flexslider .flex-direction-nav .flex-next, .flexslider .flex-direction-nav .flex-prev {
            width: 36px;
            height: 36px;
        }

        .flexslider .flex-direction-nav .flex-next:before, .flexslider .flex-direction-nav .flex-prev:before {
            font-size: 25px !important;
        }

        .featured-image img {
            margin-left: 0%;
        }

        .featured .featured-banner .featured-image {
            margin-top: -25%;
        }
    }

    .featured blue-icon-btn:first-child {
        margin-top: 0px;
    }

    .home-tabs {
        background: #f5f5f5;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .home-tabs .tab-callouts {
        display: table;
    }

    .home-tabs .tab-callouts .tab {
        float: none;
        display: table-cell;
        vertical-align: top;
        width: 33.3333%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .home-tabs .tab-callouts .tab a {
        display: list-item;
        list-style-type: none;
    }

    .home-tabs .tab-callouts .tab a.read-more {
        width: inherit;
    }

    .home-tabs .home-sidebar {
        margin-top: 20px;
    }

    .home-tabs .tab:first-child {
        border: 1px solid #e8e8e8;
    }

    .home-tabs .tab {
        border-top: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
    }

    .fluid-height {
        display: table;
    }

    .fluid-height .fluid-col {
        float: none;
        display: table-cell;
        vertical-align: top;
    }

    .tab {
        background: #fff;
    }

    .tab h2 {
        color: #007abf;
        font-size: 22px;
        font-weight: bold;
    }

    .tab h3 {
        color: #444444;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .tab h4 {
        color: #007abf;
        font-size: 16px;
        font-weight: bold;
    }

    .tab p {
        color: #444444;
        font-size: 14px;
        margin-bottom: 30px;
    }

    .tab small {
        font-size: 12px;
    }

    .tab .read-more {
        color: #007abf;
        font-size: 14px;
        margin-bottom: 30px;
        display: block;
    }

    .tab .read-more:hover {
        color: #00598c;
    }

    .blue-icon-btn:first-child {
        margin-top: 0px;
    }

    .blue-icon-btn {
        background: #007abf;
        background-color: #007abf;
        display: inline-block;
        width: 100%;
        max-width: 340px;
        height: 40px;
        margin: 10px 0;
        color: #fff;
        border-radius: 4px;
        padding: 0px 10px 0px 50px;
        text-transform: uppercase;
        text-decoration: none !important;
        font-size: 12px;
        font-weight: bold;
        position: relative;
        line-height: 40px;
    }

    .blue-icon-btn:hover, .blue-icon-btn:active {
        background: #00598c;
        color: #fff;
    }

    .blue-icon-btn i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 28px;
        padding: 6px;
        background: #00598c;
        border-radius: 4px 0 0 4px;
    }

    .home-tabs .promo {
        display: none;
    }

    .home-tabs .promo h3 {
        font-weight: bold;
        font-size: 28px;
    }

    .home-tabs .promo .read-more {
        font-size: 14px;
    }

    .home-tabs .home-sidebar .home-sidebar-button {
        background: #007abf;
        background-color: #007abf;
        display: inline-block;
        width: 100%;
        height: 40px;
        margin: 10px 0;
        color: #fff;
        border-radius: 4px;
        padding: 0px 10px 0px 50px;
        text-transform: uppercase;
        text-decoration: none !important;
        font-size: 12px;
        font-weight: bold;
        position: relative;
        line-height: 40px;
    }

    .home-tabs .home-sidebar .home-sidebar-button:hover, .home-tabs .home-sidebar .home-sidebar-button:active {
        background: #00598c;
        color: #fff;
    }

    .home-tabs .home-sidebar .home-sidebar-button i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 28px;
        padding: 6px;
        background: #00598c;
        border-radius: 4px 0 0 4px;
    }

    @media (max-width:1199.5px) {
        .home-tabs .promo {
            display: block !important;
        }

        .home-tabs .tab-callouts {
            padding-right: 0;
        }

        .home-tabs .home-sidebar {
            margin-top: 5px;
        }
    }

    @media only screen and (max-width:1023.5px) {
        .home-tabs .promo {
            display: block;
        }

        .tab-callouts {
            margin-top: 30px;
            padding-right: 0;
        }
    }

    @media only screen and (max-width:991.5px) {
        .home-tabs .promo {
            padding-left: 0;
            padding-right: 0;
        }

        .home-tabs .promo h3 {
            margin-top: 5px;
            margin-bottom: 20px;
        }

        .home-tabs .promo p {
            margin-bottom: 20px;
        }

        .home-tabs .home-sidebar .home-sidebar-button {
            width: 49.5%;
        }

        .home-tabs .tab-callouts {
            padding-left: 0;
        }

        .home-tabs .home-sidebar {
            margin-top: 15px;
        }
    }

    @media (max-width:991.5px) {
        .home-tabs.tab-callouts {
            display: block;
        }
    }

    @media only screen and (min-width:769.5px) {
        .home-tabs .tab-callouts .tab h2 a {
            pointer-events: none;
        }

        .home-tabs .tab .content.collapse {
            display: block;
        }
    }

    @media only screen and (max-width:768.5px) {
        .home-tabs .container {
            width: 100%;
        }

        .home-tabs .promo {
            padding-left: 20px;
            padding-right: 20px;
        }

        .home-tabs .tab-callouts {
            margin-top: 30px;
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }

        .home-tabs .home-sidebar {
            padding-left: 20px;
            padding-right: 20px;
        }

        .home-tabs .tab-callouts .tab {
            display: block;
            width: 100%;
        }

        .home-tabs .tab:first-child {
            border-top: 1px solid #e8e8e8 !important;
        }

        .home-tabs .tab {
            border: none !important;
            border-bottom: 1px solid #e8e8e8 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .home-tabs .tab h2 {
            font-size: 1.15em;
            margin: 0;
            padding: 14px 5% 14px 16%;
            outline: none;
            cursor: pointer;
            padding: 14px 3% 14px 5%;
            position: relative;
        }

        .home-tabs .tab h2 a {
            color: #000;
            text-decoration: none;
        }

        .home-tabs .tab h2 a {
            display: block;
            color: #007abf;
            padding-left: 30px;
            text-decoration: none;
        }

        .home-tabs .tab h2 a.collapsed {
            color: #000;
        }

        .home-tabs .tab h2 a:before {
            content: '>';
            transform: scale(1.2,1.0) rotate(-90deg);
            -ms-transform: scale(1.2,1.0) rotate(-90deg);
            -webkit-transform: scale(1.2,1.0) rotate(-90deg);
            margin-top: 15px;
            margin-left: 30px;
            font-weight: normal !important;
            top: 0px;
            left: 0px;
            position: absolute;
        }

        .home-tabs .tab h2 a.collapsed:before {
            transform: scale(1.2,1.0) rotate(90deg);
            -ms-transform: scale(1.2,1.0) rotate(90deg);
            -webkit-transform: scale(1.2,1.0) rotate(90deg);
        }

        .home-tabs .tab .content.collapse {
        }

        .home-tabs .tab .content {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media only screen and (max-width:650.5px) {
        .home-tabs .home-sidebar .home-sidebar-button {
            width: 100%;
        }

        .home-tabs .tab-callouts {
            width: 100%;
            margin-left: 0px;
        }
    }

    @media only screen and (max-width:440px) {
        .home-tabs .promo h3 {
            font-size: 1.25em;
            margin-top: 5px;
            margin-bottom: 20px;
        }

        .home-tabs .promo p {
            font-size: 1.05em;
            margin-bottom: 20px;
        }

        .home-tabs .promo .read-more {
            font-size: 1em;
        }
    }

    .better-form .contour legend {
        font-weight: bold;
        border-bottom: none;
        font-size: 18px;
    }

    .better-form .contour label {
        color: #007abf;
        font-size: 14px;
    }

    .better-form .contour .text, .better-form .contour textarea {
        max-width: none !important;
        width: 100%;
        border-radius: 4px;
        font-size: 16px;
        max-height: 125px;
    }

    .better-form .contour select {
        width: 100%;
        border-radius: 4px;
        font-size: 16px;
        height: 35px;
    }

    .better-form .contour .contourIndicator {
        display: none;
    }

    .better-form .contour .btn {
        background-color: #007abf;
        color: #fff;
        padding: 10px 20px 10px 20px;
        text-transform: uppercase;
        font-weight: bold;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    .better-form .contour .btn:hover {
        background-color: #00598c;
    }

    .left-nav {
        background: rgba(255,255,255,0.5);
        padding: 0;
        border: solid 1px #e8e8e8;
    }

    .left-nav h2 {
        margin-bottom: 10px;
        margin-top: 0px;
        padding: 20px 20px 0;
        font-weight: bold;
        font-size: 25px;
    }

    .left-nav ul {
        padding-left: 0px;
    }

    .left-nav ul li {
        list-style-type: none;
        border-top: solid 1px #e8e8e8;
    }

    .left-nav ul li a {
        display: block;
        color: #444444;
        padding: 10px 20px;
        text-decoration: none;
        font-size: 15px;
    }

    .left-nav ul li a:hover {
        color: #007abf;
    }

    .text-danger {
        color: #444;
    }

    .breadcrumbs {
        padding-left: 0px;
        margin-bottom: 30px;
    }

    .breadcrumbs li {
        list-style-type: none;
        display: inline-block;
        font-size: 14px;
    }

    .breadcrumbs li a {
        text-decoration: none;
        margin-right: 5px;
    }

    .breadcrumbs li::after {
        content: "❯";
        margin-right: 5px;
    }

    .breadcrumbs li:last-child::after {
        content: none;
    }

    .breadcrumbs li a .fa {
        margin-right: 5px;
        font-size: 20px;
    }

    .newsroom h2 {
        font-size: 22px;
    }

    .newsroom a {
        text-decoration: none;
    }

    .newsroom .post-date {
        color: #777777;
        margin-bottom: 15px;
        font-size: 13px;
    }

    .article h1 {
        font-family: Montserrat,Arial,sans-serif;
        font-size: 22px;
    }

    .accordion {
        list-style: none;
        padding: 0;
        list-style: none;
    }

    .accordion .inner {
        padding-left: 15px;
        overflow: hidden;
        display: none;
    }

    .inner a:hover {
        background: #f5f5f5;
        display: block;
    }

    .accordion li {
        margin: 5px 0;
        list-style-type: none;
    }

    .accordion li a.toggle {
        width: 100%;
        display: block;
        color: #007abf;
        padding: 5px;
        transition: background .24s ease;
        -webkit-transition: background .24s ease;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
    }

    .accordion li a.toggle:focus {
        outline: thin dotted;
    }

    .accordion li a.toggle:hover {
        background: #f5f5f5;
    }

    .footer {
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .sub-footer {
        padding: 20px 0;
        background: url("https://content2.smcetech.com/NewSMCUSAImage/images/bg_gray-stripe.png") repeat center top;
        font-size: 13px;
    }

    .sub-footer ul {
        padding-left: 0px;
    }

    .sub-footer ul li {
        display: inline-block;
        margin-right: 10px;
    }

    .sub-footer ul li:last-child {
        display: inline-block;
        margin-right: 0px;
    }

    .sub-footer a {
        color: #fff;
        text-decoration: none;
    }

    .sub-footer a:hover {
        color: #f39a00;
    }

    .copyright {
        color: #fff;
    }

    .copyright span {
        margin-right: 20px;
    }

    .social-links {
        padding-left: 0px;
        margin-bottom: 20px;
    }

    .social-links li {
        display: inline-block;
        margin-right: 10px;
    }

    .social-links li:last-child {
        margin-right: 0px;
    }

    .social-links a {
        background-color: #bcbcbc;
        height: 36px;
        width: 36px;
        display: block;
        position: relative;
        border-radius: 4px;
    }

    .social-links i {
        font-size: 20px;
        color: #fff;
        position: absolute;
        left: 25%;
        top: 25%;
    }

    .linkedin:hover {
        background-color: #3371b7;
    }

    .facebook:hover {
        background-color: #3e5b98;
    }

    .twitter:hover {
        background-color: #4da7de;
    }

    .youtube:hover {
        background-color: #e02a20;
    }

    .recent-news .read-more {
        font-size: 16px;
    }

    .recent-news h2 {
        margin-bottom: 30px;
    }

    .recent-news h4 a {
        font-weight: bold;
    }

    .recent-news .post-date {
        color: #777777;
        font-size: 14px;
    }

    .post h2 a {
        font-size: 22px;
    }

    .post .post-date {
        color: #777777;
        font-size: 13px;
    }

    .post a {
        font-size: 14px;
        margin-top: 20px;
        display: block;
    }

    .navbar {
        border: none;
        margin-top: 0px;
    }

    .main-navigation {
        margin-top: 10px;
        background: #fff;
    }

    .overview {
        border: 1px solid #e8e8e8;
        padding: 20px;
        margin: 10px auto;
        -webkit-transition: all 0.24s ease-in-out;
        transition: all 0.24s ease-in-out;
        min-height: 250px;
    }

    .overview:hover {
        border-color: #007abf;
    }

    .overview .summary {
        -webkit-transition: max-height 1s ease-in-out;
        transition: max-height 1s ease-in-out;
        position: relative;
        max-height: 45px;
        overflow: hidden;
    }

    .overview .summary.active {
        -webkit-transition: max-height 1s ease-in-out;
        transition: max-height 1s ease-in-out;
        max-height: 1100px;
    }

    .overview .summary ul li {
        height: auto;
        overflow: visible;
        margin-left: -20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .overview .less {
        display: none;
    }

    .overview .more, .overview .less {
        font-size: 14px;
    }

    @media only screen and (min-width:768px) {
        .overview p:first-of-type {
            margin-bottom: 20px;
        }
    }

    .np-media i {
        font-size: 20px;
        margin-right: 5px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .np-media .fa-file-pdf-o {
        color: #ff4848;
    }

    .np-media .fa-file-video-o {
        color: #333;
    }

    .np-post-date {
        color: #777;
        font-size: 13px !important;
        margin-bottom: 30px;
    }

    table { /* border-collapse:collapse; */ /* border-spacing:0; */
    }

    .main table { /* max-width:100%; */ /* width:100%; */ /* border-right:solid 1px #e8e8e8; */
    }

    .main table th {
        background: #007abf;
        text-align: left;
        color: #ffffff;
    }

    .main table th, .main table td {
        font-size: 14px;
        line-height: 1.5;
        padding: 8px;
        border: solid #e8e8e8;
        border-width: 1px 0 2px 1px;
    }

    .event-table {
    }

    .social-two a {
        float: right;
    }

    .social-two a:first-child {
        margin-right: 0px !important;
    }

    .social-two a:last-child {
        margin-right: 30px;
    }

    .img-center {
        margin: 0px auto;
    }

    .main-btn {
        background-color: #007abf;
        color: #fff;
        padding: 10px 20px 10px 20px;
        text-transform: uppercase;
        font-weight: bold;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
        display: block;
        text-align: center;
        border-radius: 5px;
        font-weight: bold;
        font-size: 14px;
        margin-top: 20px;
        text-decoration: none;
    }

    .main-btn:hover {
        color: #fff;
        background-color: #00598c;
    }

    .main-btn:active, .main-btn:focus {
        color: #fff;
        text-decoration: none;
    }

    .match-height h3 {
        height: 40px;
    }

    .overview-title {
        font-size: 18px !important;
        text-align: center;
    }

    .mb-25 {
        margin-bottom: 25px;
    }

    #return-btn {
        float: right;
        margin-bottom: 15px;
        margin-top: -48px;
    }

    .blue-btn {
        background-color: #007abf;
        color: #fff;
        display: block;
        font-size: 14px;
        font-weight: bold;
        padding: 6px 10px;
        border-radius: 4px;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
        text-decoration: none;
    }

    .blue-btn::after {
        content: '❯';
        margin-left: 15px;
        font-weight: 100;
    }

    .blue-btn:hover {
        color: #fff;
        background-color: #00598c;
        text-decoration: none;
    }

    .blue-btn:focus {
        color: #fff;
        text-decoration: none;
    }

    #map-navigation {
        margin-bottom: 20px;
    }

    .jvectormap-zoomin, .jvectormap-zoomout {
        width: 16px;
        height: 16px;
        z-index: 90;
    }

    #world-map {
        height: 460px;
        margin-bottom: 30px;
    }

    .jvectormap-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: solid 1px #e8e8e8;
        border-radius: 4px;
        background-color: rgb(255,255,255);
        position: relative;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    .jvectormap-zoomin, .jvectormap-zoomout, .jvectormap-goback {
        position: absolute;
        left: 10px;
        border-radius: 3px;
        background: #444444;
        padding: 0;
        color: #ffffff;
        cursor: pointer;
        line-height: 16px;
        text-align: center;
        font-size: 15px;
    }

    .jvectormap-zoomin {
        margin-top: 15px;
        top: 0;
    }

    .jvectormap-zoomout {
        margin-top: 30px;
        top: 5px;
    }

    #search-region {
        display: none;
    }

    #divLoading {
        display: none;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        left: 0;
        background-color: rgba(255,255,255,0.9);
    }

    #divLoading img {
        display: block;
        width: 64px;
        margin: 200px auto 0;
        position: absolute;
        left: 50%;
        top: 25%;
    }

    .lp-fix {
        min-height: 240px;
    }

    .lp-fix p {
        color: #777777;
    }

    .right-sb p {
        font-size: 14px;
    }

    .right-sb p img {
        max-width: 100%;
        height: auto !important;
    }

    .right-sb-header {
        margin-top: 0px;
        margin-bottom: 15px;
    }

    .right-sb-links {
        display: flex;
    }

    .right-sb .blue-btn {
        text-transform: uppercase;
        font-size: 15px;
        padding: 10px;
        display: inline-block;
        margin-top: 10px;
    }

    .right-sb .fa {
        font-size: 20px;
        width: 15px;
        margin-right: 10px;
    }

    .right-sb .fa-file-pdf-o {
        color: #ff4848;
    }

    .right-sb .fa-file-powerpoint-o {
        color: #f39a00;
    }

    .gallery-grid .container {
        width: 100%;
    }

    .gallery-grid img {
        display: block;
        max-width: 100%;
        height: auto;
        border: 1px solid #e8e8e8;
        padding: 10px
    }

    .gallery-grid h1 {
        text-align: center;
        font-family: Montserrat,Arial,sans-serif;
        font-size: 14px;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 0px;
        border: 1px solid #e8e8e8;
        border-bottom: none;
        padding: 5px;
    }

    .gallery-grid .row .col-md-6:nth-child(2n) {
        padding-right: 0px
    }

    .gallery-grid .row .col-md-6:nth-child(2n + 1) {
        padding-left: 0px
    }

    @media (max-width:991.5px) {
        .gallery-grid .row .col-md-6:nth-child(2n) {
            padding-right: 15px
        }

        .gallery-grid .row .col-md-6:nth-child(2n + 1) {
            padding-left: 15px
        }
    }

    .environmental_iframe_div iframe {
        width: 100%;
        min-height: 578px;
    }

    .orange-btn, .blue-btn, .icon-btn, input[type="submit"] {
        display: inline-block;
        width: auto;
        font-family: "proxima-nova",Arial,sans-serif;
        font-size: 1em;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        color: #ffffff !important;
        padding: 11px 15px 9px 10px;
        border: none;
        border-radius: 4px;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    .orange-btn.disabled, .blue-btn.disabled, .icon-btn.disabled, input[type="submit"].disabled {
        background-color: lightgray !important;
        color: gray !important;
        cursor: default;
        cursor: not-allowed;
    }

    .orange-btn {
        background-color: #f39a00;
    }

    .orange-btn:hover, .orange-btn:active {
        background-color: #c07a00;
    }

    .blue-btn {
        background-color: #007abf;
        margin-bottom: 10px;
    }

    .blue-btn:hover, .blue-btn:active {
        background-color: #00598c;
    }

    .icon-btn {
        position: relative;
        background-image: none;
        padding: 13px 10px 12px 50px;
    }

    .icon-btn i {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 40px;
        border-radius: 4px 0 0 4px;
    }

    .button {
        display: block;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        margin-bottom: 14px;
        font-family: "proxima-nova",Arial,sans-serif;
        font-size: 1em;
        color: #444444;
        border-radius: 4px;
        vertical-align: middle;
        box-shadow: none;
        border: solid 1px #bcbcbc;
        width: 100%;
        max-width: 400px;
        background-color: #ffffff;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    .button {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAIAAAADnC86AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDEzNDY4OUIzMkIwMTFFNEJBQ0ZBRjUwREY0MzMyMjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDEzNDY4OUMzMkIwMTFFNEJBQ0ZBRjUwREY0MzMyMjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFQjRCRTA5QTMyQTgxMUU0QkFDRkFGNTBERjQzMzIyNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MTM0Njg5QTMyQjAxMUU0QkFDRkFGNTBERjQzMzIyNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Prdicz4AAADQSURBVHja7NYxCoMwFAZgDV1zAlcP4AVyggwZHRWEnMHVSY9gNtdsLjrpcQQnwclNfNClQws1tBbK/4YMf+B9eRBC/HmeOefe5cW8HxVgwIABAwYM2BFO09Ra+3SL8iRJ3m91OwVnWRbH8bquWuvH3BhTFMWrM30AFkL0fa+UWpYlz/N7WJZlXdeUR1H0LZiKug/DIKUku6oq4tu2HccxDMNTfXy3z940TWTv+84Y67ouCIKLbjVJTdNs20arg+o+MR4QwIABAwYM+B/gQ4ABAMa8RxBCaA6hAAAAAElFTkSuQmCC");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        margin-bottom: 20px;
        padding: 0 40px 0 10px;
    }

    input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], select, textarea, .field {
        display: block;
        height: 30px;
        line-height: 1;
        padding: 0 10px;
        font-size: 1em;
        color: #444444;
        border-radius: 4px;
        vertical-align: middle;
        box-shadow: none;
        border: solid 1px #bcbcbc;
        width: 100%;
        font-family: "proxima-nova",Arial,sans-serif;
        background-color: #ffffff;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    input[type="text"]:focus, input[type="text"]:active, input[type="password"]:focus, input[type="password"]:active, input[type="datetime"]:focus, input[type="datetime"]:active, input[type="datetime-local"]:focus, input[type="datetime-local"]:active, input[type="date"]:focus, input[type="date"]:active, input[type="month"]:focus, input[type="month"]:active, input[type="time"]:focus, input[type="time"]:active, input[type="week"]:focus, input[type="week"]:active, input[type="number"]:focus, input[type="number"]:active, input[type="email"]:focus, input[type="email"]:active, input[type="url"]:focus, input[type="url"]:active, input[type="search"]:focus, input[type="search"]:active, input[type="tel"]:focus, input[type="tel"]:active, input[type="color"]:focus, input[type="color"]:active, select:focus, select:active, textarea:focus, textarea:active, .field:focus, .field:active {
        background-color: #ffffff;
        border-color: #007abf;
    }

    textarea {
        width: 100% !important;
        height: 120px !important;
        line-height: 1.5em !important;
    }

    select {
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-position: right center;
        cursor: pointer;
        font-weight: 400;
    }

    label {
        font-family: "proxima-nova",Arial,sans-serif;
        font-weight: bold;
    }

    input[type="submit"] {
        background: #007abf;
        font-size: 0.9em;
        padding: 11px 10px 9px 10px;
        border: none;
    }

    input[type="submit"]:hover, input[type="submit"]:active {
        background-color: #00598c;
    }

    input[type="radio"] {
        margin-right: 5px;
    }

    legend {
        border: 0;
        padding: 0;
        margin: 0;
        font-size: inherit;
    }

    .modal-header {
        background-color: #007abf !important;
        color: #fff !important;
    }

    .modal-header .close {
        color: #fff;
        opacity: 1;
    }

    input.blue-btn {
        border: none;
        outline: none;
        margin-top: 15px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px 15px 10px 15px;
    }

    #searchResults {
    }

    .searchResult {
        font-weight: 400;
    }

    .searchResult:hover {
        font-weight: 700;
    }

    .searchResult + .searchResult {
        border-top: solid 1px #eee;
    }

    .searchResult a {
        font-size: 14px;
    }

    .searchResult a:hover {
        font-size: 15px;
        -webkit-transition: all .3s;
        transition: all .3s;
    }

    .searchResult img {
        padding: 1px;
        margin-right: 15px;
        height: auto;
        vertical-align: middle;
    }

    .searchResult span {
        white-space: nowrap;
    }

    ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;
    }

    @media (min-width:979px) {
        ul.nav li.dropdown:hover > ul.dropdown-menu {
            display: block;
        }
    }

    #sidebar-nav {
        background: rgba(255,255,255,0.5);
        padding: 0;
        border: solid 1px #e8e8e8;
        -webkit-transition: background 0.24s ease-in-out;
        transition: background 0.24s ease-in-out;
    }

    #sidebar-nav + hr {
        margin-top: 30px;
    }

    #sidebar-nav h2 {
        text-rendering: optimizelegibility;
        font-family: "proxima-nova",Arial,sans-serif;
        font-weight: 600;
        line-height: 1.2;
        margin: 0;
        font-size: 1.4em;
        margin-bottom: 10px;
        padding: 20px 20px 0;
    }

    #sidebar-nav > ul {
        padding: 0;
        list-style-type: none;
        margin: 0;
    }

    #sidebar-nav ul li {
        font-size: 0.9em;
        border-top: solid 1px #e8e8e8;
        cursor: pointer;
    }

    #sidebar-nav > ul > li.dropdown > a {
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/icon-vert-arrows.png") no-repeat 20px -54px;
        padding: 10px 20px 10px 40px;
        -webkit-transition: color 0.24s ease-in-out;
        transition: color 0.24s ease-in-out;
    }

    #sidebar-nav > ul > li.dropdown > a:hover {
        color: #007abf;
    }

    #sidebar-nav > ul > li.dropdown.active > a {
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/icon-vert-arrows.png") no-repeat 20px -54px;
        background-position: 20px -4px;
        color: #007abf;
    }

    #sidebar-nav ul li a {
        display: block;
        color: #444444;
        padding: 10px 20px;
    }

    #sidebar-nav ul ul {
        margin: 0 0 0 20px;
        padding: 10px 0 0 20px;
        border: none;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    #sidebar-nav ul li.dropdown.active ul {
        max-height: 800px;
        margin-bottom: 20px;
    }

    #sidebar-nav ul ul li {
        list-style: disc;
        font-size: 0.9em;
        padding: 0;
        border: none;
    }

    #sidebar-nav ul ul li a {
        background: none;
        padding: 5px 0;
        -webkit-transition: margin 0.24s ease-in-out;
        transition: margin 0.24s ease-in-out;
    }

    #sidebar-nav ul ul li a:hover, #sidebar-nav ul ul li a:active {
        color: #007abf;
        background-color: transparent;
        margin-left: 10px;
    }

    #sidebar-nav ul ul li.active-category a {
        color: #007abf;
    }

    #sidebar-nav-sub {
    }

    #sidebar-nav-sub ul {
        margin: 0 0 0 -20px;
        padding: 10px 0 0 20px;
        border: none;
    }

    #sidebar-nav-sub ul li {
        list-style: none;
        font-size: 0.9em;
        padding: 0;
        margin-bottom: 20px;
    }

    #sidebar-nav-sub ul li a {
        position: relative;
        background-image: none;
        display: block;
        width: auto;
        font-family: "proxima-nova",Arial,sans-serif;
        font-size: 1em;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        color: #ffffff;
        padding: 13px 10px 13px 50px;
        border: none;
        border-radius: 4px;
        -webkit-transition: background-color 0.24s ease-in-out;
        transition: background-color 0.24s ease-in-out;
    }

    #sidebar-nav-sub ul li a.blue {
        background-color: #007abf;
        color: #fff !important;
    }

    #sidebar-nav-sub ul li a.blue:hover {
        background-color: #00598c;
    }

    #sidebar-nav-sub ul li a i {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 40px;
        height: 40px;
        border-radius: 4px 0 0 4px;
    }

    #sidebar-nav-sub ul li a i.distributor {
        background: #00598c url("https://content2.smcetech.com/NewSMCUSAImage/images/icon-distributor.png") no-repeat center center;
        background-size: 24px;
    }

    #sidebar-nav-sub ul li a i.etools {
        background: #00598c url("https://content2.smcetech.com/NewSMCUSAImage/images/icon-etools.png") no-repeat center center;
        background-size: 24px;
    }

    .categories .category-item {
        padding: 20px 20px 10px;
        margin: 0 1% 20px;
        border: solid 1px #e8e8e8;
        -webkit-transition: border-color 0.3s;
        transition: border-color 0.3s;
        min-height: 250px;
        overflow: hidden;
        position: relative;
    }

    .categories .category-item:hover {
        border-color: #007abf;
    }

    .categories .category-item > a {
        text-align: center;
        display: block;
    }

    .categories .category-item > a > img {
        height: 140px;
    }

    .categories .category-item.expandable .cat-content {
        max-height: 150px;
        -webkit-transition: all 1s;
        transition: all 1s;
    }

    .categories .category-item.expandable.active .cat-content {
        max-height: 800px;
        padding-bottom: 20px;
    }

    .categories .category-item .cat-content h3 {
        font-size: 1.2em;
    }

    .categories .category-item .cat-content p {
        text-align: justify;
    }

    .categories .category-item.expandable .cat-content-show-more::after {
        content: '\f055';
        font-family: FontAwesome;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 75px 0 0 0;
        color: #007abf;
        font-size: 25px;
        background-image: linear-gradient(to bottom,transparent,#fff);
    }

    .categories .category-item.expandable.active .cat-content-show-more::after {
        content: '\f056';
        background-image: none;
    }

    .categories .category-item.expandable .cat-content-show-more:hover:after {
        cursor: pointer;
        color: #00598c;
    }

    .products .categories .category-item.expandable {
        min-height: 360px;
    }

    #productTabs .cat-block, #catTabs .cat-block {
        margin: 0;
    }

    #productTabs .product-block, #productTabs .cat-block, #catTabs .product-block, #catTabs .cat-block {
        margin: 0 -6%;
        border-top: solid 1px #e8e8e8;
    }

    @media only screen and (min-width:768px) {
        #productTabs, #catTabs {
            margin: 20px -3% 0;
            border-bottom: none;
        }

        #catTabs {
            margin: 0;
        }

        #productTabs .product-block, #productTabs .cat-block, #catTabs .product-block, #catTabs .cat-block {
            margin: 0;
            padding: 0;
        }

        #catTabs h3 {
            float: left;
            width: 50%;
        }
    }

    @media only screen and (min-width:991.5px) {
        #catTabs {
            border: none;
        }

        #catTabs .cat-block:nth-of-type(4n+1) {
            clear: left;
        }

        #catTabs .cat-block {
            position: relative;
            float: left;
            width: 24%;
            margin: 0 .5% 20px .5%;
            padding: 10px;
            border: solid 1px #e8e8e8;
            overflow: visible;
        }

        #catTabs .cat-block h3 {
            float: none;
            width: 100%;
            font-size: 1em;
        }

        #catTabs .cat-block h3 a.accordion-link {
            display: none;
        }

        #catTabs .cat-block ul.cat-list {
            opacity: 0;
            float: none;
            position: absolute;
            top: 0;
            left: -1px;
            right: -1px;
            z-index: 200;
            background-color: #ffffff;
            width: auto;
            overflow-x: hidden;
            overflow-y: hidden;
            height: 0px;
            padding: 10px;
            border: solid 1px #007abf;
            box-shadow: 0 0 5px 3px rgba(0,0,0,0.1);
        }

        #catTabs .cat-block:hover ul.cat-list {
            opacity: 1;
            display: block;
            height: auto;
            z-index: 500;
            -webkit-animation: catlistmove 0.18s ease-in-out forwards;
            animation: catlistmove 0.18s ease-in-out forwards;
        }

        #catTabs .cat-block ul.cat-list:before, #catTabs .cat-block ul.cat-list:hover:before {
            opacity: 1;
            display: block;
            height: auto;
        }

        @-webkit-keyframes catlistmove {
            0% {
                height: 0px;
            }

            50% {
                height: 80px;
            }

            80% {
                height: 350px;
            }

            100% {
                height: auto;
            }
        }

        @keyframes catlistmove {
            0% {
                height: 0px;
            }

            50% {
                height: 80px;
            }

            80% {
                height: 350px;
            }

            100% {
                height: auto;
            }
        }

        #catTabs .cat-block ul.cat-list .cat-list-container {
            position: relative;
            border-top: 10px solid #fff;
            margin-top: -10px;
        }

        #catTabs ul.cat-list {
            list-style: none;
            margin: 0 0 20px;
        }

        #catTabs ul.cat-list .rollover {
            width: 300px;
            height: 150px;
            background-size: 300px;
            background-position-x: center;
            background-position-y: bottom;
            display: block;
            margin-bottom: 15px;
            -webkit-transition: background 0.24s ease-in-out;
            transition: background 0.24s ease-in-out;
            background: no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list:hover .rollover {
            opacity: 0;
            width: 100%;
            position: relative;
        }

        #catTabs .cat-block ul.cat-list:hover .rollover .helper {
            position: absolute;
            left: 0;
            bottom: -50px;
            display: block;
            width: 110%;
            height: 100%;
            margin-top: -10px;
            margin-left: -10px;
        }

        #catTabs .cat-block ul.cat-list .rollover:hover {
            opacity: 1;
        }

        #catTabs .cat-block ul.cat-list img, #catTabs .cat-block ul.cat-list h3 {
            display: block;
        }

        #catTabs .cat-block ul.cat-list h3 {
            background-color: #fff;
            padding-top: 5px;
            padding-bottom: 20px;
            margin-bottom: 0;
        }

        #catTabs .cat-image {
            float: none;
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }

        #catTabs .cat-block ul.cat-list li {
            background-color: #ffffff;
            font-size: 0.8em;
        }

        #catTabs .cat-block ul.cat-list li {
            background-color: #ffffff;
            font-size: 0.8em;
        }

        #catTabs ul.cat-list li {
            font-size: 0.9em;
            margin: 0;
            padding: 0;
            border-bottom: solid 1px #e8e8e8;
        }

        #catTabs ul.cat-list li a {
            display: block;
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/icon-right-arrow-blue.png") no-repeat right center;
            padding: 10px 15px 10px 0;
        }

        #catTabs .cat-block ul.cat-list li a {
            -webkit-transition: padding 0.24s ease-in-out;
            transition: padding 0.24s ease-in-out;
        }

        #catTabs .cat-block ul.cat-list li a:hover, #catTabs .cat-block ul.cat-list li a:active {
            padding-left: 10px;
        }

        #catTabs .cat-block ul.cat-list li a + div {
            position: absolute;
            z-index: -1;
            top: 120px;
            left: 0;
            width: 100%;
            height: 150px;
            background-color: #fff;
            background-size: 300px;
            background-position-x: center;
            background-position-y: bottom;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity 0.07s ease-in-out;
            -webkit-transition: opacity 0.07s ease-in-out;
            transition: top 0.12s ease-in-out;
            -webkit-transition: top 0.12s ease-in-out;
        }

        #catTabs .cat-block ul.cat-list li:hover ~ li a + div {
            opacity: 0;
            top: -120px;
        }

        #catTabs .cat-block ul.cat-list li a:hover + div {
            opacity: 1;
            top: 10px;
        }
    }

    @media only screen and (max-width:991.5px) {
        #catTabs {
            margin: 0;
        }

        #catTabs .cat-block ul.cat-list {
            width: 50%;
            clear: both;
            margin-left: 50%;
            padding-left: 0px
        }

        #catTabs .cat-block .cat-image {
            display: none;
        }

        #catTabs .cat-block li {
            font-size: 0.9em;
            display: block;
            clear: both;
            margin-left: 0;
            border-bottom: solid 1px #e8e8e8;
        }

        #catTabs .cat-block li:last-child {
            border-bottom: none;
        }

        #catTabs ul.cat-list li a {
            display: block;
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/icon-right-arrow-blue.png") no-repeat right center;
            padding: 10px 15px 10px 0;
        }

        #catTabs .cat-block ul.cat-list.in .cat-list-image {
            display: block;
            position: absolute;
            margin-left: 40px;
            left: 0;
        }

        #catTabs .cat-block h3 {
            float: none;
            width: 100%;
            background-position: 3% -50px;
            position: relative;
        }

        #catTabs .cat-block h3 a.normal-link {
            display: none;
        }

        #catTabs .cat-block h3 a.accordion-link {
            display: block;
            color: #007abf;
            padding-left: 40px;
            text-decoration: none;
        }

        #catTabs .cat-block h3 .accordion-link.collapsed {
            color: #000;
        }

        #catTabs .cat-block h3 a.accordion-link:before {
            content: '>';
            transform: scale(1.2,1.0) rotate(-90deg);
            -ms-transform: scale(1.2,1.0) rotate(-90deg);
            -webkit-transform: scale(1.2,1.0) rotate(-90deg);
            margin-left: 10px;
            font-weight: normal !important;
            top: 0px;
            left: 0px;
            position: absolute;
        }

        #catTabs .cat-block h3 .accordion-link.collapsed:before {
            transform: scale(1.2,1.0) rotate(90deg);
            -ms-transform: scale(1.2,1.0) rotate(90deg);
            -webkit-transform: scale(1.2,1.0) rotate(90deg);
        }

        #catTabs .cat-block .cat-list.in .rollover {
            margin-top: -10px;
            margin-left: -45px;
            padding-left: 0px;
            float: left;
            width: 50%;
        }
    }

    @media only screen and (min-width:850.5px) and (max-width:991.5px) {
        #catTabs .cat-block ul.cat-list {
            width: 55%;
            margin-left: 45%;
        }

        #catTabs .cat-block .cat-list .rollover {
            width: 45%;
        }
    }

    @media only screen and (max-width:768.5px) {
        #catTabs {
            margin: 0;
        }

        #catTabs .cat-block ul.cat-list {
            margin-left: 0;
            width: 100%;
        }

        #catTabs .cat-block h3 a.accordion-link {
            padding-left: 25px !important;
        }

        #catTabs .cat-block h3 a.accordion-link:before {
            margin-left: 0px !important;
        }

        #catTabs .cat-block ul.cat-list.in .cat-list-image {
            display: block;
            position: inherit;
        }

        #catTabs .cat-block ul.cat-list.in .rollover {
            width: 100%;
        }

        #catTabs .cat-block {
            margin: 0;
            padding: 0;
        }
    }

    .e-trust-row {
        max-width: 600px;
        display: block;
        padding: 15px 0;
    }

    .e-trust {
        float: right;
        margin-top: -20px;
    }

    @media only screen and (min-width:768px) {
        .support .categories .category-item.expandable .cat-content-show-more::after {
            margin: 0 auto;
            margin: 0;
            padding: 5px 0 0 0;
        }

        .support .categories .category-item {
            text-align: center;
            padding: 10px 20px 10px;
        }

        .support .categories .category-item.expandable {
            min-height: 160px;
        }

        .support .categories .category-item.expandable .cat-content {
            max-height: 3.5em;
            -webkit-transition: all 1s ease-in-out !important;
            transition: all 1s ease-in-out !important;
            text-align: left;
        }

        .support .categories .category-item.expandable .cat-content .overview-title {
            text-align: left;
        }

        .support .categories .category-item.expandable.active .cat-content {
            max-height: 12em;
            padding-bottom: 20px;
        }

        .support .categories .category-item .cat-content p {
            text-align: left;
            margin-bottom: 5px;
            font-size: 0.9em;
        }

        .support .categories .overview .overview-title {
            text-align: left;
        }

        .support .categories .summary img {
            width: 100px;
            height: 110px;
        }
    }

    .support .support-table {
        display: block;
        width: 100%;
        float: left;
        border: 1px solid #000;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .support .support-table .title {
        background-color: #0074bf;
        border-bottom: 1px solid #000;
        color: #fff;
        font-weight: bold;
    }

    .support .support-table .support-table-row {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .support .support-table .support-table-row .title-caption {
        font-weight: bold;
        padding-bottom: 10px;
    }

    .support .support-table .support-table-row div:nth-child(odd) {
        border-right: 1px solid #000;
    }

    .support .instruction-table {
        display: block;
        width: 100%;
        float: left;
        border: solid 1px #e8e8e8;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .support .instruction-table .title {
        background-color: #007abf;
        border-bottom: 1px solid #e8e8e8;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .support .instruction-table .instruction-table-row {
        display: block;
        width: 100%;
    }

    .support .instruction-table .instruction-table-row div {
        border-right: solid 1px #e8e8e8;
        padding-top: 10px;
        padding-bottom: 5px;
        font-size: 0.94em;
    }

    .support .instruction-table .instruction-table-row div a i {
        margin-right: 5px;
    }

    .support .instruction-table .instruction-table-row:first-child div {
        padding-top: 10px !important;
    }

    .support .instruction-table .instruction-table-row:last-child div {
        padding-bottom: 20px;
    }

    .reset-buttons .btn {
        color: #fff !important;
        font-size: 18px;
    }

    .reset-buttons .btn-continue {
        background: #63a909 !important;
        border-color: darkseagreen;
    }

    .reset-buttons .btn-continue:hover {
        background: #307600 !important;
    }

    .reset-buttons .btn-cancel {
        background: #00598c !important;
        border-color: darkseagreen;
    }

    .login-buttons .btn-cancel:hover {
        background: #002659 !important;
    }

    .login-buttons .btn {
        color: #fff !important;
        font-size: 18px;
    }

    .login-buttons .btn-continue {
        background: #63a909 !important;
        border-color: darkseagreen;
    }

    .login-buttons .btn-continue:hover {
        background: #307600 !important;
    }

    .login-buttons .btn-cancel {
        background: #00598c !important;
        border-color: darkseagreen;
    }

    .login-buttons .btn-cancel:hover {
        background: #002659 !important;
    }

    #content .register-steps {
        list-style: none;
        margin: 0 0 20px;
        color: #a8a8a8;
    }

    #content .register-steps li {
        display: inline-block;
        font-size: 0.8em;
        margin: 0;
    }

    #content .register-steps li.active {
        color: #007abf;
    }

    #content .register-steps li img {
        margin: 0 5px;
    }

    .register-buttons .btn {
        margin-top: 30px;
        color: #fff !important;
        font-size: 18px;
    }

    .register-buttons .btn-back {
        background: #f39a00 !important;
        border-color: darkseagreen;
    }

    .register-buttons .btn-back:hover {
        background: #c06700 !important;
    }

    .register-buttons .btn-continue {
        background: #63a909 !important;
        border-color: darkseagreen;
    }

    .register-buttons .btn-continue:hover {
        background: #307600 !important;
    }

    .register-buttons .btn-cancel {
        background: #00598c !important;
        border-color: darkseagreen;
    }

    .register-buttons .btn-cancel:hover {
        background: #002659 !important;
    }

    .register-form .step {
        display: none;
    }

    .register-form .step.active {
        display: block;
    }

    .register-form .field-notef {
        display: block;
    }

    .field-note {
        font-size: 12px;
        font-weight: 400;
    }

    @media (max-width:991.5px) {
        #header {
            border-bottom: 1px solid #000;
        }

        .main-navigation, .navbar-header, .navbar-collapse {
            background: url("https://content2.smcetech.com/NewSMCUSAImage/images/bg_gray-stripe.png") repeat center top;
        }

        #mainnav ul li a {
            color: #fff;
        }

        #mainnav ul li a:hover {
            color: #f39a00;
            background-color: rgba(0,0,0,0.5);
        }

        #mainnav ul li a:after {
            content: none;
        }

        ul.nav li.dropdown:hover .dropdown-menu {
            display: none;
        }

        ul.nav li.dropdown:active .dropdown-menu {
            display: block;
        }

        .popout:hover .popout-menu {
            display: none !important;
        }

        #mainnav .popout > a {
            background: none;
        }

        .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
            background-color: rgba(0,0,0,0.5);
            color: #f39a00 !important;
        }

        .mobile-alt-nav {
            border-top: 1px solid #fff;
        }

        .mobile-alt-nav li {
            margin-left: 15px;
        }

        .mobile-alt-nav li a {
            padding-left: 30px !important;
        }

        .login-mobile {
            background: url("https://content2.smcetech.com/NewSMCUSAImage/images/icon-login.png") no-repeat 10px 3px;
            background-position: left 11px;
            background-size: 18px;
        }

        .mobile-alt-nav li a:hover {
            background-color: transparent !important;
        }

        .mobile-alt-nav li a:focus {
            background-color: transparent !important;
        }

        .mobile-tertiary-nav {
            border-top: 1px solid #fff;
        }

        .mobile-tertiary-nav li a:hover, .mobile-tertiary-nav li a:focus {
            background-color: transparent !important;
        }

        a#return-btn {
            font-size: 12px;
            margin-top: 0px;
            float: none;
        }
    }

    @media (max-width:767.5px) {
        .distributor-sidebar {
            margin-top: 80px;
        }
    }

    #assember-container iframe {
        overflow-y: scroll;
        margin: 10px 0;
        width: 100%;
        height: 850px;
        border: 1px solid white;
        background-color: white;
    }

    .expandable {
        min-height: 430px;
        -webkit-transition: border-color 0.3s;
        transition: border-color 0.3s;
        overflow: hidden;
        position: relative;
    }

    .expandable .concat-content {
        max-height: 150px;
        transition: all 1s;
    }

    .expandable.active .concat-content {
        max-height: 800px;
        padding-bottom: 20px;
    }

    .expandable .concat-content-show-more:after {
        content: '\f055';
        font-family: FontAwesome;
        text-align: center;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 75px 0 0 0;
        color: #007abf;
        font-size: 25px;
        background-image: linear-gradient(to bottom,transparent,#fff);
    }

    .expandable.active .concat-content-show-more:after {
        content: '\f056';
        background-image: none;
    }

    .maintenance-table {
        border: 1px solid #e8e8e8;
    }

    .maintenance-table .row {
        border-bottom: 1px solid #e8e8e8;
        margin-left: 0px;
        margin-right: 0px;
    }

    .maintenance-table .row:last-child {
        border-bottom: none;
        margin-bottom: 0px;
    }

    .maintenance-table a {
        display: inline-block;
    }

    .m-header {
        margin-bottom: 10px;
    }

    .leftBorder {
        border-left: .5px solid #e8e8e8;
        height: auto;
    }

    .rightBorder {
        border-right: .5px solid #e8e8e8;
        height: auto;
    }

    .tablePadding {
        padding: 30px 40px;
    }

    .blue-btn a {
        color: #fff;
    }

    .category-sub-seciton {
        display: none;
    }

    .msg-error {
        color: #ff0000;
    }

    .msg-success {
        color: #3f6f01;
    }

    .contacdt-form legend {
        font-weight: 700;
        margin-bottom: 10px;
    }

    .category-item {
        float: none;
        display: inline-block;
        width: 98%;
        margin: 0 1% 20px;
        padding: 10px;
        border: solid 1px #e8e8e8;
        -webkit-transition: all 0.24s ease-in-out;
        transition: all 0.24s ease-in-out;
    }

    .category-item:hover {
        color: #444444;
        border-color: #007abf;
    }

    .fp-img {
        margin: auto;
        width: 100px;
        height: 110px;
    }

    .sub h4 {
        display: table-cell;
        height: 52px;
        font-size: 1.1em;
        padding-bottom: 10px;
        vertical-align: middle;
        font-weight: 600;
    }

    .pw-fix {
        min-width: 315px !important;
        width: auto !important;
    }

    .cw-fix {
        width: auto !important;
    }

    .cw-fix li {
        max-width: 300px !important;
    }

    .nopadding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .row.nomargin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    @media (min-width:768px) {
        .col-sm-1.nopadding, .col-sm-10.nopadding, .col-sm-11.nopadding, .col-sm-12.nopadding, .col-sm-2.nopadding, .col-sm-3.nopadding, .col-sm-4.nopadding, .col-sm-5.nopadding, .col-sm-6.nopadding, .col-sm-7.nopadding, .col-sm-8.nopadding, .col-sm-9 {
            float: left;
        }
    }

    .tab-callouts .img-responsive {
        margin: 0px auto;
        max-width: 360px;
        width: 100%;
    }

    #siteNotification {
        position: relative;
        width: 100%;
        padding: 10px 10%;
        text-align: center;
        font-weight: 700;
        background-color: #f39a00;
        color: white;
        display: none;
    }

    #siteNotification button {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    #lstResults {
        margin: 20px 0px;
        list-style: none;
    }

    #lstResults .crnResult {
        padding: 5px 10px;
        margin-bottom: 5px;
        background-color: lightgray;
    }

    .product-title {
        font-size: 19px !important;
        color: #007abf;
    }

    .modal-footer .registration {
        text-transform: uppercase;
        text-align: left;
        font-weight: 700;
    }

    .text-left {
        text-align: left;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    #configuration_area {
        font-size: .95em !important;
        font-weight: 600;
        color: #444444 !important;
    }

    #configuration_area tr {
    }

    #configuration_area td {
        font-size: inherit !important;
        min-width: 40px;
    }

    .option-selection select {
        margin-bottom: 0px;
        font-size: 1em !important;
    }

    #configuration_area .incomplete .option-label {
        color: #007abf;
    }

    #configuration_area .incomplete .option-selection select {
        color: #007abf !important;
        border: solid 2px #007abf;
    }

    #configuration_area .invalid .option-selection select {
        color: #aa0000 !important;
        border: solid 2px #aa0000;
    }

    #configuration_area .option-selection .error {
        color: #aa0000 !important;
        font-size: .9em;
        font-weight: 400;
    }

    #configuration_area .option-status img {
        height: 25px;
        width: 25px;
        margin-top: -5px;
    }

    #config {
        position: relative;
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/bg_white-stripe.png") repeat center center;
        margin: 0 -6% 20px;
        padding: 15px 6%;
        border: solid 1px #e8e8e8;
        box-shadow: inset 0 0 10px 3px rgba(0,0,0,0.1);
        overflow: hidden;
    }

    #config h2 {
        font-size: 1.4em;
        color: #444444;
        margin-bottom: 20px;
    }

    #config .table-responsive {
        margin: 0;
    }

    #config legend {
        margin-bottom: 10px;
    }

    #config table { /* display:block; */ /* border:none; */
    }

    #config table tr {
        display: block;
    }

    #config table td {
        display: inline-block;
        padding: 0;
        border: none;
    }

    #config table table { /* display:inline-block; */ /* background:transparent; */ /* margin-bottom:10px; */ /* overflow:hidden; */
    }

    #config table table tbody {
        display: block;
    }

    #config table table tr {
        display: block;
        overflow: hidden;
    }

    #config table table td {
        display: block;
        float: left;
        font-size: 0.8em;
        text-align: center;
        vertical-align: top;
        margin-bottom: 5px;
        padding: 2px 4px;
        border: solid #bcbcbc;
        border-width: 1px 0 1px 1px;
        border-collapse: collapse;
    }

    #config table table td.valid {
        background: transparent;
        color: #444444;
    }

    #config table table td.invalid {
        background: #AA0000;
        color: #ffffff;
    }

    #config table table td.empty {
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/bg_blue-white-stripe.png") repeat 0 0;
    }

    #config table table td:nth-of-type(9) {
        clear: left;
    }

    #config table table td:last-child {
        border-right: solid 1px #bcbcbc;
    }

    #config #status_img {
        position: absolute;
        top: 14px;
        right: 3%;
        width: 24px !important;
        height: auto !important;
    }

    #config.sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        margin: 0;
        z-index: 9999;
    }

    #config #cpn_state {
        float: left;
        font-size: 0.8em;
        padding: 0;
    }

    #config .reset_btn {
        float: right;
        background: #ffffff;
        font-family: "proxima-nova",Arial,sans-serif;
        font-size: 0.8em;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        color: #444444;
        padding: 5px 10px;
        border: solid 1px #e8e8e8;
        border-radius: 4px;
    }

    #config a.clipboard {
        font-size: 0.8em;
        cursor: pointer;
    }

    #productTabs .product-block, #productTabs .cat-block, #catTabs .product-block, #catTabs .cat-block {
        margin: 0 -6%;
        border-top: solid 1px #e8e8e8;
    }

    #productTabs .product-block:last-child, #productTabs .cat-block:last-child, #catTabs .product-block:last-child, #catTabs .cat-block:last-child {
        border-bottom: solid 1px #e8e8e8;
    }

    #productTabs .product-block h2, #productTabs .cat-block h2, #catTabs .product-block h2, #catTabs .cat-block h2 {
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/icon-vert-arrows.png") no-repeat 8% -50px;
        font-size: 1.15em;
        color: #444444;
        margin: 0;
        padding: 14px 5% 14px 16%;
        outline: none;
        cursor: pointer;
    }

    #productTabs .product-block h2.ui-state-active, #productTabs .cat-block h2.ui-state-active, #catTabs .product-block h2.ui-state-active, #catTabs .cat-block h2.ui-state-active {
        background-position: 8% 0;
        color: #007abf;
    }

    #productTabs .product-block > div, #productTabs .cat-block > div, #catTabs .product-block > div, #catTabs .cat-block > div {
        padding: 10px 5%;
    }

    #productTabs .cat-block, #catTabs .cat-block {
        margin: 0;
    }

    #productTabs .cat-block:last-child, #catTabs .cat-block:last-child {
        border-bottom: none;
    }

    #catTabs {
        margin: 0;
    }

    #catTabs .cat-image {
        display: block;
        margin-bottom: 15px;
    }

    #catTabs ul.cat-list {
        list-style: none;
        margin: 0 0 20px;
    }

    #catTabs ul.cat-list img, #catTabs ul.cat-list h3 {
        display: none;
    }

    #catTabs ul.cat-list li {
        font-size: 0.9em;
        margin: 0;
        padding: 0;
        border-bottom: solid 1px #e8e8e8;
    }

    #catTabs ul.cat-list li a {
        display: block;
        background: url("//content2.smcetech.com/NewSMCUSAImage/images/icon-right-arrow-blue.png") no-repeat right center;
        padding: 10px 15px 10px 0;
    }

    #catTabs ul.cat-list li:last-child {
        border: none;
    }

    #options select {
        font-size: 0.8em;
    }

    #options h2 {
        font-size: 1.4em;
        color: #444444;
        margin: 0px 0px 20px 0px;
        text-rendering: optimizelegibility;
        font-family: "proxima-nova",Arial,sans-serif;
        font-weight: 600;
        line-height: 1.2;
    }

    #options h5 {
        padding-top: 20px;
    }

    #options input.overlay {
        width: 86%;
        background: none;
        font-size: 0.8em;
        margin-top: -54px;
        border: none;
    }

    #options table {
        border: none;
    }

    #options #table-options {
        display: block;
    }

    #options #table-options tr {
        display: table;
        width: 100%;
    }

    #options #table-options tr.complete td.option-label {
        color: #444444 !important;
    }

    #options #table-options tr.incomplete td.option-label {
        color: #007abf;
    }

    #options #table-options tr.incomplete select {
        color: #007abf !important;
        border: solid 2px #007abf;
    }

    #options #table-options tr.invalid td.option-label {
        color: #AA0000;
    }

    #options #table-options tr.invalid select {
        color: #AA0000 !important;
        border: solid 2px #AA0000;
    }

    #options #table-options td {
        vertical-align: middle;
        padding: 0 0 14px;
        border-width: 0 0 1px;
        border-color: #ffffff;
        white-space: normal;
    }

    #options #table-options td.option-label {
        display: table-row;
        font-weight: 600;
        padding-bottom: 5px;
    }

    #options #table-options td.option-selection .error {
        font-size: 0.9em;
        color: #AA0000;
        padding: 5px;
    }

    #options #table-options td.option-status {
        background: transparent !important;
        vertical-align: middle;
        width: 16px;
        line-height: 16px;
        padding: 0 0 14px 10px;
    }

    #options #table-options img {
        max-width: inherit;
    }

    #options #table-options select, #options #table-options input {
        margin: 0;
    }

    #options #table-options input[type="radio"] {
        margin-right: 5px;
    }

    #options #table-options .selectBox {
        position: relative;
    }

    #options #table-options .selectBoxOptionContainer {
        display: none;
        position: absolute;
        left: 30px;
        min-width: 200px;
        background: #ffffff;
        margin-top: -1px;
        padding: 10px;
        border: solid 1px #bcbcbc;
    }

    #options #table-options .selectBoxArrow {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-color: #ffffff;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAIAAAADnC86AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDEzNDY4OUIzMkIwMTFFNEJBQ0ZBRjUwREY0MzMyMjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDEzNDY4OUMzMkIwMTFFNEJBQ0ZBRjUwREY0MzMyMjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFQjRCRTA5QTMyQTgxMUU0QkFDRkFGNTBERjQzMzIyNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MTM0Njg5QTMyQjAxMUU0QkFDRkFGNTBERjQzMzIyNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Prdicz4AAADQSURBVHja7NYxCoMwFAZgDV1zAlcP4AVyggwZHRWEnMHVSY9gNtdsLjrpcQQnwclNfNClQws1tBbK/4YMf+B9eRBC/HmeOefe5cW8HxVgwIABAwYM2BFO09Ra+3SL8iRJ3m91OwVnWRbH8bquWuvH3BhTFMWrM30AFkL0fa+UWpYlz/N7WJZlXdeUR1H0LZiKug/DIKUku6oq4tu2HccxDMNTfXy3z940TWTv+84Y67ouCIKLbjVJTdNs20arg+o+MR4QwIABAwYM+B/gQ4ABAMa8RxBCaA6hAAAAAElFTkSuQmCC");
        background-repeat: no-repeat;
        background-position: right center;
        cursor: pointer;
        border: solid #bcbcbc;
        border-width: 1px 1px 1px 0;
        border-radius: 0 4px 4px 0;
    }

    #options #table-options .subBox {
        padding: 5px 10px 0;
    }

    #options #table-options ul li:first-child span {
        color: #777777 !important;
    }

    #options #table-options ul li:nth-of-type(2) span {
        color: #AA0000 !important;
    }

    #options #table-options ul li:nth-of-type(3) span {
        color: #CCAA00 !important;
    }

    #downloads .col, #categories .col {
        margin-bottom: 30px;
    }

    #downloads .icon-btn, #categories .icon-btn {
        display: block;
        max-width: 340px;
        min-height: 40px;
        margin: 10px 0;
    }

    #downloads .disabled .download {
        background-color: lightgray !important;
    }

    #downloads .download, #categories .download {
        background: #00598c url("//content2.smcetech.com/NewSMCUSAImage/images/icon-download.png") no-repeat center center;
        background-size: 24px;
    }

    #downloads .download.pdf, #categories .download.pdf {
        background: #00598c url("//content2.smcetech.com/NewSMCUSAImage/images/icon-download-pdf.png") no-repeat center center;
        background-size: 24px;
    }

    #downloads .download.cad, #categories .download.cad {
        background: #00598c url("//content2.smcetech.com/NewSMCUSAImage/images/icon-download-cad.png") no-repeat center center;
        background-size: 24px;
    }

    #downloads .download.view, #categories .download.view {
        background: #00598c url("//content2.smcetech.com/NewSMCUSAImage/images/icon-view-cad.png") no-repeat center center;
        background-size: 24px;
    }

    #downloads .download.dwg, #categories .download.dwg {
        background: #00598c url("//content2.smcetech.com/NewSMCUSAImage/images/icon-view-dwg.png") no-repeat center center;
        background-size: 24px;
    }

    #downloads .favs, #categories .favs {
        background: #00598c url("//content2.smcetech.com/NewSMCUSAImage/images/icon-add-favorite.png") no-repeat center center;
        background-size: 18px;
    }

    #downloads .category-item .icon-btn, #categories .category-item .icon-btn {
        margin: 10px 0 0;
    }

    #relatedTabs .tabnav {
        display: none;
    }

    #relatedTabs h3 {
        clear: both;
    }

    #relatedTabs h3 a {
        margin-left: 20px;
    }

    #relatedTabs ul.prod-list {
        list-style: none;
        margin: 0 0 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px #e8e8e8;
        overflow: hidden;
    }

    #relatedTabs ul.prod-list img {
        margin-bottom: 10px;
    }

    #relatedTabs ul.prod-list li {
        float: left;
        width: 48%;
        font-size: 0.8em;
        line-height: 1.2;
        text-align: center;
        margin: 0;
        padding: 10px;
    }

    #relatedTabs ul.prod-list li:nth-of-type(2n+1) {
        clear: left;
    }

    @media only screen and (min-width:481px) {
        #config table table td:nth-of-type(9) {
            clear: none;
        }

        #config #status_img {
            right: 4%;
        }

        #productTabs .product-block h2, #productTabs .cat-block h2, #catTabs .product-block h2, #catTabs .cat-block h2 {
            background-position: 7% -50px;
            padding: 14px 5% 14px 12%;
        }

        #productTabs .product-block h2.ui-state-active, #productTabs .cat-block h2.ui-state-active, #catTabs .product-block h2.ui-state-active, #catTabs .cat-block h2.ui-state-active {
            background-position: 7% 0;
        }

        #productTabs .product-block p, #productTabs .cat-block p, #catTabs .product-block p, #catTabs .cat-block p {
            font-size: 0.9em;
        }

        #downloads .icon-btn, #categories .icon-btn {
            min-height: 40px;
            margin: 20px 0;
            padding: 13px 10px 11px 50px;
        }

        #downloads .icon-btn i, #categories .icon-btn i {
            height: 40px;
        }
    }

    .download-message {
        display: none;
        font-weight: 700;
    }

    .download-message.show {
        display: block;
        color: #cc0000;
    }

    @media only screen and (min-width:600px) {
        #productTabs .product-block h2, #productTabs .cat-block h2, #catTabs .product-block h2, #catTabs .cat-block h2 {
            background-position: 6% -50px;
            padding: 14px 5% 14px 10%;
        }

        #productTabs .product-block h2.ui-state-active, #productTabs .cat-block h2.ui-state-active, #catTabs .product-block h2.ui-state-active, #catTabs .cat-block h2.ui-state-active {
            background-position: 6% 0;
        }
    }

    @media only screen and (min-width:768px) {
        #config {
            margin: 0 0 20px;
            padding: 15px 20px;
            overflow: hidden;
            clear: both;
        }

        #config legend {
            position: absolute;
            margin: 0;
        }

        #config table table {
            display: table;
            margin: 15px 0 0;
        }

        #config table table tbody {
            display: table-row-group;
        }

        #config table table tr {
            display: table-row;
        }

        #config table table td {
            display: table-cell;
            font-size: 0.9em;
            padding: 4px;
        }

        #config #status_img {
            top: 10px;
            right: 25px;
            bottom: auto;
            width: 32px !important;
        }

        #config #cpn_state {
            position: absolute;
            right: 70px;
            padding-top: 3px;
        }

        #config.sticky .table-responsive {
            position: relative;
            width: 94%;
            margin: 0 auto;
            padding: 0 20px;
        }

        #config.sticky #status_img {
            top: 0;
        }

        #config.sticky #cpn_state {
            padding-top: 7px;
        }

        #productTabs, #catTabs {
            margin: 20px -3% 0;
            border-bottom: none;
        }

        #productTabs .product-block, #productTabs .cat-block, #catTabs .product-block, #catTabs .cat-block {
            margin: 0;
            padding: 0;
        }

        #productTabs .product-block:last-child, #productTabs .cat-block:last-child, #catTabs .product-block:last-child, #catTabs .cat-block:last-child {
            border-bottom: none;
        }

        #productTabs .product-block h2, #productTabs .cat-block h2, #catTabs .product-block h2, #catTabs .cat-block h2 {
            background-position: 3% -50px;
            padding: 14px 3% 14px 6%;
        }

        #productTabs .product-block h2.ui-state-active, #productTabs .cat-block h2.ui-state-active, #catTabs .product-block h2.ui-state-active, #catTabs .cat-block h2.ui-state-active {
            background-position: 3% 0;
        }

        #productTabs .product-block > div, #productTabs .cat-block > div, #catTabs .product-block > div, #catTabs .cat-block > div {
            overflow: hidden;
            padding: 0 3%;
        }

        #catTabs {
            margin: 0;
        }

        #catTabs .cat-block {
            overflow: hidden;
        }

        #catTabs .cat-image {
            float: left;
            width: 50%;
            max-width: 300px;
            margin-right: 20px;
        }

        #catTabs h3 {
            float: left;
            width: 50%;
        }

        #catTabs ul.cat-list {
            float: left;
            width: 50%;
        }

        #downloads .col, #categories .col {
            float: left;
            width: 50%;
            padding: 0 10px;
        }

        #downloads .col.right, #categories .col.right {
            float: right;
        }

        #downloads .icon-btn, #categories .icon-btn {
            min-height: 40px;
            margin: 20px 0;
            padding: 13px 10px 11px 50px;
        }

        #downloads .icon-btn.no-icon, #categories .icon-btn.no-icon {
            padding: 13px 10px 11px 10px;
        }

        #relatedTabs ul.prod-list li {
            float: left;
            width: 32%;
        }

        #relatedTabs ul.prod-list li:nth-of-type(2n+1) {
            clear: none;
        }

        #relatedTabs ul.prod-list li:nth-of-type(3n+1) {
            clear: left;
        }

        #options {
            clear: both;
            padding: 0 20px;
        }

        #options #table-options, #options #configuration_area {
            display: table;
            border: none;
        }

        #options #table-options tbody, #options #configuration_area tbody {
            display: table-row-group;
        }

        #options #table-options tr, #options #configuration_area tr {
            display: table-row;
            width: auto;
        }

        #options #table-options tr:first-child, #options #configuration_area tr:first-child {
            position: relative;
            display: block;
            padding: 10px 0 40px;
        }

        #options #table-options tr:first-child td, #options #configuration_area tr:first-child td {
            display: inline-block;
            padding: 0 0 20px;
        }

        #options #table-options tr:first-child td:first-child, #options #configuration_area tr:first-child td:first-child {
            display: none;
        }

        #options #configuration_area td {
            border: none;
        }

        #options #table-options tr:first-child td:nth-of-type(2), #options #configuration_area tr:first-child td:nth-of-type(2) {
            position: absolute;
            white-space: nowrap;
            font-size: .9em !important;
            font-weight: 400;
        }

        #options #table-options tr:nth-of-type(even), #options #configuration_area tr:nth-of-type(even) {
            background-color: #f5f5f5 !important;
        }

        #options #table-options tr:last-child, #options #configuration_area tr:last-child {
            background-color: transparent !important;
        }

        #options #table-options td, #options #configuration_area td {
            font-size: 0.8em;
            padding: 8px;
        }

        #options #table-options td.option-label, #options #configuration_area td.option-label {
            display: table-cell;
            width: 25%;
            white-space: normal;
        }

        #options #table-options td.option-status, #options #configuration_area td.option-status {
            padding: 10px 8px 8px;
        }

        #options #table-options img, #options #configuration_area img {
            max-width: inherit;
            width: 24px;
            height: 24px;
        }

        #options #table-options select, #options #configuration_area select {
            font-size: 1em;
            margin: 0;
        }

        #options h5 {
            margin-left: 25%;
        }

        #options input.overlay {
            width: 80%;
        }
    }

    @media only screen and (min-width:960px) {
        #config table {
            position: relative;
        }

        #config #cpn_state {
            right: 45px;
            padding-top: 7px;
        }

        #config #status_img {
            top: 0;
            right: 2px;
        }

        #config.sticky {
            padding-left: 25%;
        }

        #config.sticky .table-responsive {
            width: 96%;
            padding: 0 27px 0 40px;
        }

        #productTabs {
            margin: 20px 0 0;
            padding: 20px;
            border-top: solid 1px #e8e8e8;
        }

        #productTabs .product-block {
            margin-bottom: 20px;
            padding: 0;
            border: none;
            border-bottom: solid 1px #e8e8e8;
        }

        #productTabs .product-block h2 {
            background: none;
            font-size: 1.4em;
            color: #007abf;
            margin: 0 0 20px;
            padding: 0;
            cursor: auto;
        }

        #productTabs .product-block h3 {
            font-size: 1.1em;
        }

        #productTabs .product-block > div {
            padding: 0;
        }

        #productTabs #downloads {
            margin-bottom: 20px;
            overflow: hidden;
        }

        #productTabs #downloads > div {
            display: block !important;
            padding: 0;
        }

        #productTabs #downloads .col {
            float: left;
            width: 33.3333%;
            padding: 0 10px;
        }

        #productTabs #downloads .col.right {
            float: right;
        }

        #productTabs #downloads .blue-btn {
            max-width: 300px;
            font-size: 0.8em;
        }

        #productTabs #related {
            clear: both;
            margin-bottom: 20px;
            border-bottom: none;
        }

        #productTabs #related > div {
            display: block !important;
            padding: 0;
        }

        #catTabs {
            border: none;
        }

        #catTabs .cat-block {
            position: relative;
            float: left;
            width: 24%;
            margin: 0 .5% 20px .5%;
            padding: 10px;
            border: solid 1px #e8e8e8;
            overflow: visible;
        }

        #catTabs .cat-block:last-child {
            border-bottom: solid 1px #e8e8e8;
        }

        #catTabs .cat-block:hover {
            border-color: transparent;
        }

        #catTabs .cat-block > div {
            display: block !important;
            padding: 0;
        }

        #catTabs .cat-block:nth-of-type(4n+1) {
            clear: left;
        }

        #catTabs .cat-block h2 {
            display: none;
        }

        #catTabs .cat-block h3 {
            float: none;
            width: 100%;
            font-size: 1em;
        }

        #catTabs .cat-block h3 a {
            display: block;
            -webkit-transition: padding 0.24s ease-in-out;
            transition: padding 0.24s ease-in-out;
        }

        #catTabs .cat-block h3 a:hover, #catTabs .cat-block h3 a:active {
            padding-left: 10px;
        }

        #catTabs .cat-block ul.cat-list {
            display: none;
            float: none;
            position: absolute;
            top: 0;
            left: -1px;
            right: -1px;
            z-index: 200;
            background-color: #ffffff;
            width: auto;
            padding: 10px;
            border: solid 1px #007abf;
            box-shadow: 0 0 5px 3px rgba(0,0,0,0.1);
        }

        #catTabs .cat-block ul.cat-list img, #catTabs .cat-block ul.cat-list h3 {
            display: block;
        }

        #catTabs .cat-block ul.cat-list img {
            margin-bottom: 15px;
        }

        #catTabs .cat-block ul.cat-list .rollover {
            display: block;
            margin-bottom: 15px;
            -webkit-transition: background 0.24s ease-in-out;
            transition: background 0.24s ease-in-out;
            background: no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.actuators {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-01-actuators.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.dcvalves {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-02-dcvalves.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.airline {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-03-airline.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.airprep {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-04-airprep.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.connectors {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-05-connectors.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.electric {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-06-electric.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.vacuum {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-07-vacuum.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.sensors {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-08-sensors.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.fpvalves {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-09-fpvalves.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.static {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-10-static.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.tempcontrol {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-11-tempcontrol.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list .rollover.specialty {
            background: url("//content2.smcetech.com/NewSMCUSAImage/images/products-12-specialty.jpg") no-repeat center bottom;
        }

        #catTabs .cat-block ul.cat-list li {
            background-color: #ffffff;
            font-size: 0.8em;
        }

        #catTabs .cat-block ul.cat-list li a {
            -webkit-transition: padding 0.24s ease-in-out;
            transition: padding 0.24s ease-in-out;
        }

        #catTabs .cat-block ul.cat-list li a:hover, #catTabs .cat-block ul.cat-list li a:active {
            padding-left: 10px;
        }

        #catTabs .cat-image {
            float: none;
            width: 100%;
            margin-right: 0;
        }

        #catTabs ul.cat-list {
            float: none;
            width: 100%;
        }

        #relatedTabs .tabnav {
            display: block;
            list-style: none;
            overflow: hidden;
            background-color: #e8e8e8;
            background-image: -webkit-gradient(linear,left top,left bottom,from(#f5f5f5),to(#e8e8e8));
            background-image: -webkit-linear-gradient(top,#f5f5f5,#e8e8e8);
            background-image: linear-gradient(to bottom,#f5f5f5,#e8e8e8);
            margin: 0px;
            padding: 0;
            border-radius: 4px 4px 0 0;
            border: solid 1px #e8e8e8;
            border-bottom: none;
        }

        #relatedTabs .tabnav li {
            float: left;
            margin: 0;
        }

        #relatedTabs .tabnav li a {
            display: block;
            font-family: "proxima-nova",Arial,sans-serif;
            font-size: 0.9em;
            font-weight: 600;
            color: #444444;
            padding: 12px 10px;
            border-right: solid 1px #e8e8e8;
            border-bottom: none;
        }

        #relatedTabs .tabnav li.active a {
            background: #ffffff;
            color: #007abf;
        }

        #relatedTabs .tab-panel {
            padding: 20px;
            border: solid #e8e8e8;
            border-width: 0 1px 1px;
            border-radius: 0 0 4px 4px;
        }

        #relatedTabs ul.prod-list {
            border: none;
            margin: 0;
            padding: 0;
        }
    }

    @media only screen and (min-width:1030px) {
        #config legend {
            font-size: 1.2em;
        }

        #config #cpn_state {
            font-size: 1em;
        }

        #config #status_img {
            top: 0;
            right: 3px;
        }

        #config table table td {
            font-size: 1.1em;
        }

        #config.sticky #cpn_state {
            padding-top: 5px;
        }

        #config.sticky #status_img {
            top: 0;
            right: 3px;
        }

        #productTabs #downloads .blue-btn {
            font-size: 0.9em;
        }

        #options #table-options td {
            font-size: 0.9em;
        }
    }
}