[role="button"] {
    cursor: pointer;
}

.btnWrapper {
	display: inline-block;
	padding: 0;
	text-decoration: none !important;
}

.table-butttons {
	position: relative;
	display: table;
	margin: 20px 0 0;
	width: 100%;
	@media(max-width: 479px) {
		margin-top: 0;
	}	
	.table-buttton-td {
		display: table-cell;
		padding: 0 7px;
		@media(min-width: 768px) and (max-width: 979px) {
			display: block;
			padding: 5px 0;
		}
		@media(max-width: 479px) {
			display: block;
			padding: 5px 0;
		}		
		.btnAction {
			width: 100%;
		}
		&:first-child {
			padding-left: 0;
			@media(min-width: 768px) and (max-width: 979px) {
				padding-top: 0;
			}			
		}
		&:last-child {
			padding-right: 0;
			@media(min-width: 768px) and (max-width: 979px) {
				padding-bottom: 0;
			}			
		}		
	}
}

.btnAction {
    @extend %btn;
    &:hover {
		background-color: $color-success;
		border-color: $color-success;
	}
	
	.icon {
		display: inline-block;
		vertical-align: middle;
		fill: white;
		width: 10px;
		height: 12px;
		margin: 0px 0 0 3px;
	}
}

.interior-solutions-pg-wrapper {
	.btnAction {
		border-radius: 3px;

		.icon {
			display: inline-block;
			vertical-align: middle;
			fill: white;
			width: 10px;
			height: 12px;
			margin: 0px 0 0 3px;
		}
	}
}

#order-history {
    .btnAction {
        &:hover {
		    color: #fff;
    	    background-color: #e4701e;
            border-color: #e4701e;
        }
    }
}

.btn {
	&.btnsmaller,
	&.btnSmall {
		color: #bdd7ef;
		&:hover {
			color: #fff;
		}	
	}
}

.alignRight {
	button {
		&+button {
			margin-left: 10px;
		}
	}
}

.btnProcess {
    @extend %btn;
    text-transform: uppercase;
    background-color: $color-button-process;
    border-color: darken( $color-button-process, 10% );
    
    &:hover {
        background-color:  darken( $color-button-process, 5% );
    }
}


/* ## Small Text Buttons */

.btnSmall {
    @extend %btn;
	padding: 8px 10px;
	font-size: 0.875em;
}


/* ## Larger Buttons */

.btnLarge {
    @extend %btn;
	padding: 5px 10px;
	font-size: 1.4em;
}


.btnTextAction,
.btnTextAction .btn {
    /*@extend %btnText;*/
	min-width: auto !important;
    color: #e4701e !important;
	text-decoration: none;
	background-color: transparent !important;
	padding: 0 !important;
	text-align: left !important;
	box-decoration-break: clone;
	transition: all 0.5s ease-in-out;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)) !important;
	background-size: 0 1px !important;
	background-position: 0 100% !important;
	background-repeat: no-repeat;
	text-decoration: none;	
    
    &:active,
	&:hover,
    &:focus {
		text-decoration: none;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)) !important;		
		background-position: 0 100% !important;		
		background-size: 100% 1px !important;
	}
}

.btnContinueShopping {
	background-color:#005e8f;
}

.btnContinueShopping .btn {
	width: 172px;
	padding: 3px 2px;
	font-size: 16px;
	font-weight: normal;
	color: #fff;
	background-color: #407abc;
	border: 1px solid #97b3cd;
	text-align: center;
	text-decoration: none;
	cursor:pointer;
}

[data-toggle="collapse"] {
	cursor: pointer;
}

.btn[disabled] {
    color: lighten($color-text-secondary, 30%);
    
    opacity: 0.75;
    cursor: default;

    &:not(.btnText):not(.btnTextAction) {
        background-color: $gray-lighter;
        border-color: $color-border-light;
    }
}

.btnWrapper {
	&.btnAlt {
		background: transparent !important;
		padding: 0 !important;
		border: none !important;
	}
}

.editItemBtn {
	margin: 10px 0 30px;
}

.quote-transaction-pg-wrapper {
	#billing-addNewAddress.btnAlt {
		position: relative;
		background-color: #dfdfdf;
		text-align: left;
		width: 100%;
		padding: 20px 20px;
		font-family: "IBM Plex Sans",sans-serif;
		font-size: 24px;
		font-weight: 500;
		color: #333;
		line-height: 24px;

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 30px;
			right: 14px;
			margin: 0;
			width: 14px;
			height: 0;
			border-bottom: 3px solid #2b2b2b;
			transform: rotate(90deg);
			transition: width .3s;
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 30px;
			right: 14px;
			margin: 0;
			width: 14px;
			height: 0;
			border-bottom: 3px solid #2b2b2b;
			transform: rotate(0);
			transition: width .3s;
		}

		&:hover,
		&:focus {
			transform: none;
		}
	}
}