/* Removed :link from the list to help cut down on writing time and prevent the need for extra specificity for classes */
a,
%links {
    color: $color-action;
	font-weight: 600;
    text-decoration: none;
    transition: color 0.3s; 		
    
    &:hover {
        color: darken($color-action, 10%);
        text-decoration: none;
    }
    
    &:active,
    &:focus {
        color: darken($color-action, 10%);
        text-decoration: none;
        outline:0;
		/*background-size: 100% 1px;*/
    }
}


.btnText { margin:0; padding:0; color:#6D6D6D; text-decoration:underline; border:0; background:none; }
.btnText:hover { color:#6D6D6D; text-decoration:none; }


/* ## Link Modifiers ## */

a.link-icon {
    text-decoration: none !important;
    
    .text {
        text-decoration:none;
    }
    
    &:hover .text,
    &:active .text,
    &:focus .text {
        text-decoration:none;
		/*background-size: 100% 1px;*/
    }
}


a [class^="icon-"] {
    text-decoration:none;
}

a.removeLink {
    color: $color-action;
    font-size: 0.925em;
}

a.actionLink {
	color: $color-action;
	font-weight: bold;
	text-decoration: none;
	
	&:hover {
		color: darken($color-action, 10%);
	}
	
	&:after {
		@extend %iconFont;
		@extend .icon-arrow-bracket-right:before;
		position: relative;
		font-size: 1.125em;
		top: 2px;
	}
}

/* ## Link Lists ## */
/* We use '<ul class="linkList">' when setting a list of organized links. */

ul.linkList {
	margin:0 0 15px 0;
	padding:0;
	list-style-type:none;

	li {
		padding: 7px;
		padding-bottom: 7px;
		margin-bottom: 0;
		line-height: 20px;
	}

	a {
		font-weight: normal;
		text-decoration:none;
	}
	
	a:hover,
	a:active {
        text-decoration: none;
		background-size: 100% 1px;
	}
	
	&.linkList-inline {
		li {
			display: inline-block;
			margin: 0 10px;
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

dl.linkList {
	dt {
		margin-top: 10px;
		font-weight: bold;
		
		a {
			font-weight: bold;
		}
	}
	
	dt:first-child {
		margin-top: 0;
	}
	
	dd {
		margin: 0;
		padding: 0;
		font-size: 0.875em;
	}
	
	a {
		margin: 0;
	}
}