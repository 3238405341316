﻿.my-account-progress-bar-wrap {
    h2 {
        font-family: Montserrat,Arial,sans-serif;
        font-size: 1.5em;
        font-weight: 400;
        margin: 0 0 20px;

        @media(max-width:768px) {
            line-height: 1em;
        }

        span {
            position: relative;
            font-weight: 600;
            padding: 0;

            &:before {
                content: '0%';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            &.percentage {
                display: inline-block;

                &:before {
                    display: none;
                }

                .hide-prc {
                    background: $site-bg;
                    opacity: 0;
                    transition: all 1s ease-in-out;

                    &:before {
                        display: none;
                    }
                }

                &.visible {
                    .hide-prc {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.my-account-progress-bar {
    position: relative;
    margin: 0 0 30px 0;

    @media(max-width:768px) {
        margin: 0 0 15px 0;
    }

    &.hidden {
        position: absolute;
	    top: -20em;
	    left: -200em;
    }

    &.focus {
        background-color: $light-gray;
    }

    .progress {
        height: 24px;
        background-color: $gray;
        border: none;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .progressIndicator {
        width: 100%;
	    height: 100%;
        position: relative;
        top: 0;
	    left: 0;
        margin: 0;
	    padding: 0;
	    transition: all 1500s ease;	

        &.loaded {
            background-position: left bottom;
        }
    }

    .progressVal {
        height: 20px;
        position: relative;
        top: -18px;
        font-weight: bold;
        text-align: center;
        margin: 0;
	    padding: 0;
    }
}		

.progress-stripe-wrap {
    width: 100%;
	height: 100%;
	position: absolute;
    top: 0;
	right: 0;
	bottom: 0;
    left: 0;

    .progress-stripe {
        position: relative;
	    display: inline-block;
	    width: 10%;
	    height: 100%;

        &:after {
            content: '';
            width: 2px;
	        height: 100%;
	        background: $site-bg;
	        position: absolute;
            top: 0;
	        right: 0;
	        bottom: 0;
        }

        &.progress-stripe-10 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0a4e8c+0,165084+100 */
	        background: rgb(10,78,140); /* Old browsers */
	        background: -moz-linear-gradient(left,  rgba(10,78,140,1) 0%, rgba(22,80,132,1) 100%); /* FF3.6-15 */
	        background: -webkit-linear-gradient(left,  rgba(10,78,140,1) 0%,rgba(22,80,132,1) 100%); /* Chrome10-25,Safari5.1-6 */
	        background: linear-gradient(to right,  rgba(10,78,140,1) 0%,rgba(22,80,132,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a4e8c', endColorstr='#165084',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-20 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#175084+0,285279+100 */
	        background: rgb(23,80,132); /* Old browsers */
	        background: -moz-linear-gradient(left,  rgba(23,80,132,1) 0%, rgba(40,82,121,1) 100%); /* FF3.6-15 */
	        background: -webkit-linear-gradient(left,  rgba(23,80,132,1) 0%,rgba(40,82,121,1) 100%); /* Chrome10-25,Safari5.1-6 */
	        background: linear-gradient(to right,  rgba(23,80,132,1) 0%,rgba(40,82,121,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#175084', endColorstr='#285279',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-30 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#295279+0,3d556c+100 */
            background: rgb(41,82,121); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(41,82,121,1) 0%, rgba(61,85,108,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(41,82,121,1) 0%,rgba(61,85,108,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(41,82,121,1) 0%,rgba(61,85,108,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#295279', endColorstr='#3d556c',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-40 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3e566b+0,54595d+100 */
            background: rgb(62,86,107); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(62,86,107,1) 0%, rgba(84,89,93,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(62,86,107,1) 0%,rgba(84,89,93,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(62,86,107,1) 0%,rgba(84,89,93,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e566b', endColorstr='#54595d',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-50 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#55595c+0,6d5c4d+100 */
            background: rgb(85,89,92); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(85,89,92,1) 0%, rgba(109,92,77,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(85,89,92,1) 0%,rgba(109,92,77,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(85,89,92,1) 0%,rgba(109,92,77,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55595c', endColorstr='#6d5c4d',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-60 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6f5d4c+0,89603b+100 */
            background: rgb(111,93,76); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(111,93,76,1) 0%, rgba(137,96,59,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(111,93,76,1) 0%,rgba(137,96,59,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(111,93,76,1) 0%,rgba(137,96,59,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6f5d4c', endColorstr='#89603b',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-70 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#8b613a+0,a66529+100 */
            background: rgb(139,97,58); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(139,97,58,1) 0%, rgba(166,101,41,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(139,97,58,1) 0%,rgba(166,101,41,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(139,97,58,1) 0%,rgba(166,101,41,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b613a', endColorstr='#a66529',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-80 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a66529+0,bb6825+100 */
            background: rgb(166,101,41); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(166,101,41,1) 0%, rgba(187,104,37,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(166,101,41,1) 0%,rgba(187,104,37,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(166,101,41,1) 0%,rgba(187,104,37,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a66529', endColorstr='#bb6825',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-90 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#bc6925+0,d16d21+100 */
            background: rgb(188,105,37); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(188,105,37,1) 0%, rgba(209,109,33,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(188,105,37,1) 0%,rgba(209,109,33,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(188,105,37,1) 0%,rgba(209,109,33,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bc6925', endColorstr='#d16d21',GradientType=1 ); /* IE6-9 */
        }

        &.progress-stripe-100 {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d26d21+0,e4701e+100 */
	        background: rgb(210,109,33); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(210,109,33,1) 0%, rgba(228,112,30,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(210,109,33,1) 0%,rgba(228,112,30,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(210,109,33,1) 0%,rgba(228,112,30,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d26d21', endColorstr='#e4701e',GradientType=1 ); /* IE6-9 */

            &:after {
                display: none;
            }
        }

    }
}

.progress-on {
    width: 100%;
    height: 100%;
    background-color: $gray;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(1);
    transform-origin: 100% 50%;
    transition: transform 2.5s ease-in;
    background-size: 200% 100%;
    background-position: left bottom;	

    &.loaded {
        background-position: left bottom;
        background-size: 200% 100%;
        transform: scaleX(0);
    }
}

.progress-stripe-divide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    .progress-stripe {
        &:after {
            content: '';
            width: 2px;
            height: 100%;
            background: $site-bg;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }

        &.progress-stripe-10 {
            &:after { left: 10%; }
        }
        &.progress-stripe-20 {
            &:after { left: 20%; }
        }
        &.progress-stripe-30 {
            &:after { left: 30%; }
        }
        &.progress-stripe-40 {
            &:after { left: 40%; }
        }
        &.progress-stripe-50 {
            &:after { left: 50%; }
        }
        &.progress-stripe-60 {
            &:after { left: 60%; }
        }
        &.progress-stripe-70 {
            &:after { left: 70%; }
        }
        &.progress-stripe-80 {
            &:after { left: 80%; }
        }
        &.progress-stripe-90 {
            &:after { left: 90%; }
        }
    }
}

.add-sub-users-nav-wrapper {
	display: table;
	width: 100%;

	.btn-step  {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin: 10px 0 15px;
		padding: 0;
		text-decoration: none;
		background-color: transparent;
		border: none;

        &.prev, &.next {
            &:after {
                display: none;
            }

            .link-icon {
				display: table;
				text-decoration: none;

                .icon {
					position: relative;
					display: table-cell;
					vertical-align: middle;
					width: 14px;
					height: 22px;

					.icon-left-arrow-2, .icon-right-arrow-2 {
						position: absolute;
                        top: 0;
						left: 0;
						fill: #0a4e8c;
						width: 14px;
						height: 22px;
						transition: all 0.5s ease-in-out;
					}					
				}

                .text {
					display: table-cell;
					vertical-align: middle;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 23px;
					text-transform: none;
					color: #004d8e;
					text-shadow: 1px 0 0 rgba(0, 77, 142, 0.4);

					>span {
						display: inline;
						box-decoration-break: clone;
						transition: all 1s ease-in-out;
						position: relative;
						background-image: linear-gradient(to bottom, rgba(0, 77, 142, 0.6), rgba(0, 77, 142, 0.6));
						background-size: 0 1px;
						background-position: 0 100%;
						background-repeat: no-repeat;						
						text-decoration: none;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 0;
						text-transform: none;
						color: #004d8e;
						text-shadow: 1px 0 0 rgba(0, 77, 142, 0.5);						
					}
				}
            }

            &:hover, &:focus {
				text-decoration: none;
				background-size: 0 1px;

				.link-icon {
					.icon {
						.icon-left-arrow-2, .icon-right-arrow-2 {
							-webkit-transform: scale(1.15);
							-ms-transform: scale(1.15);
							transform: scale(1.15);
						}					
					}
                    					
					.text {
						>span {
							background-size: 100% 1px;
						}					
					}						
				}
			}
        }


		&.prev  {
			float: left;
			margin-left: -2px;

			.link-icon {

				.text {
					padding-left: 8px;
				}
			}				
		}


		&.next  {
			float: right;
			margin-right: -2px;

			.link-icon {
				.text {
					padding-right: 8px;
				}
			}				
		}
	}	
}

.my-account-progress-bar-tasks {

    .slick-list {
        width: 64%;
        margin: 0 18%;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-prev, .slick-next {
        color: $color-primary;
        font-size: 1.15em;
        font-weight: 600;
        line-height: 1em;
        top: 28px;
        display: flex;
        align-items: center;
    }

    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 40px;
    }

    &.bannerEffects {
        .slideItem {
            color: $black;
        }

        .slick-prev {
            &:before {
                color: $color-button-primary;
                font-size: 1.25em;
                opacity: 1;
                padding: 0 2px 0 0;

                &:hover, &:focus {
                    color: $color-button-hover-primary;
                }
            }
        }

        .slick-next {
            &:before {
                display: none;
            }
            &:after {
                content: $slick-next-character;
                font-family: $slick-font-family;
                color: $color-button-primary;
                font-size: 1.25em;
                line-height: 1;
                opacity: 1;
                padding: 0 0 0 2px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                &:hover, &:focus {
                    color: $color-button-hover-primary;
                }
            }
        }
    }
}

.my-account-progress-task-container {
    a {
        color: $black;
        @include subtitle-font;
        line-height: 1.75em;

        span {
            color: $red;
        }
    }

    &.completed {
        a {
            &:before {
                content: '\2714\0020';
                color: $green;
                padding: 0 2px 0 0;
            }

            span {
                color: $green;
            }
        }
    }
}
