﻿.express-ship-to {
    @include containers-w-margin;
    @include title-font-small;
    text-align: right;

    .icon {
        fill: $color-secondary;
        width: 25px;
        height: 25px;
    }

    .ship-to-name {
        font-size: .8em;
        color: $color-primary;
    }

    .btn, .btnAlt {
        margin: 0 0 0 25px;
    }
}