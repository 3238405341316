﻿#order-history-wrapper {
    .search-products-header {
        .roc-pageOptions-filter {
            width: 100%;
            display: inline-block;

            @media(max-width:992px) {
                width: 100%;
            }
        }

        select {
            width: 100%;
        }

        .search-products-pagination {
            width: 100%;
            display: inline-block;
        }

        .roc-pageOptions-count {
            display: inline-block;
            position: relative;
            top: 15px;
            font-size: .9em;
            margin: 0 8px 0 0;
        }

        input {
            width: 100%;
            display: inline-block;

            @media(max-width: 767px) {
                width: 100%;
            }
        }

        input.search-po {
            width: 80%;
            display: inline-block;

            @media(max-width: 767px) {
                width: 90%;
            }
        }

        .pagination {
            input {
                width: 50px;
                margin-top: -10px;
            }

            .paging .total {
                padding-right: 20px;
                white-space: nowrap;
            }
        }
        // .formee-group-btn {
        //     width: 80px;
        //     position: absolute;
        //     top: -20px;
        //     left: unset;
        //     right: 0;
        //     @media(max-width:1200px) {
        //         width: 65px;
        //     }
        //     @media(max-width:992px) {
        //         width: 80px;
        //     }
        //     .icon {
        //         right: 12px;
        //         @media(max-width:1280px) {
        //             width: 25px;
        //             height: 25px;
        //             top: -2px;
        //             right: 0;
        //         }
        //         @media(max-width:992px) {
        //             width: 30px;
        //             height: 30px;
        //             top: -5px;
        //         }
        //     }
        // }
    }
}

/* /ordering-tools/order-history/ page module styles */
.order-history-pg-wrapper,
.quote-history-pg-wrapper,
.orders-pending-pg-wrapper {
    #order-history-wrapper {
        .search-products-header {
            text-align: center;
            display: flex;

            .roc-pageOptions-filter {
                flex: 1;
                padding: 0 15px;
                margin: 0;

                .formee-group-btn {
                    width: 50px;
                    display: inline-block;
                    margin-top: -2px;

                    #btnSearch {
                        padding: 15px 0;
                    }
                }
            }

            .search-products-pagination {
                width: auto;
                flex: 1;
                vertical-align: bottom;
                align-self: flex-end;

                .paging {
                    position: relative;

                    input.pageNum {
                        margin-top: 0;
                        height: 52px;
                    }

                    .roc-pageOptions-count {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        top: auto;
                        bottom: -20px;
                    }
                }
            }

            @media(max-width: 1268px) {
                padding: 30px 10px;

                .roc-pageOptions-filter {
                    padding: 0 5px;

                    .formee-group-btn {
                        width: 18%;

                        .icon {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }

            @media(max-width: 979px) {
                flex-wrap: wrap;

                .roc-pageOptions-filter {
                    flex: 0 100%;
                    text-align: left;
                    margin: 10px 0;

                    label {
                        display: block;
                    }

                    input {
                        width: 100%;
                    }

                    input.search-po {
                        width: 93.5%;
                    }

                    .formee-group-btn {
                        width: 6%;
                    }
                }

                .search-products-pagination {
                    margin: 15px auto;
                }
            }

            @media(max-width: 854px) {
                .roc-pageOptions-filter {

                    input {
                        width: 100%;
                    }

                    input.search-po {
                        width: 93.5%;
                    }
                }
            }

            @media(max-width: 600px) {
                .roc-pageOptions-filter {
                    input {
                        width: 100%;
                    }

                    .formee-group-btn {
                        width: 100%;
                        margin-top: 5px;

                        #btnSearch {
                            width: 100%;
                        }
                    }
                }

                .pagination .paging .total {
                    padding-right: 10px;
                }
            }
        }
    }
}

