﻿.services-pg-wrapper,
.solutions-pg-wrapper {

    .interior-callouts-container {
        @include containers-w-margin;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .interior-callout-item {
        width: 30%;
        display: inline;
        flex-wrap: wrap;
        margin: 15px 0;
        padding-bottom: 60px;
        position: relative;
        @include interior-box-shadow;

        // @media(min-width:1401px) {
        //     &:nth-of-type(3n+0) {
        //         margin: 25px 0;
        //     }
        // }
        @media(max-width:1400px) {
            width: 48%;
            margin: 25px 4% 25px 0;

            &:nth-of-type(2n+0) {
                margin: 25px 0;
            }
        }
        @media(max-width:768px) {
            width: 100%;
            margin: 25px 0;
        }

        .interior-callout-btn {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .interior-callout-image {
        width: 100%;
        text-align: center;
        margin: 0 0 15px 0;
    }

    .interior-callout-title {
        width: 100%;
        text-align: center; 

        @include title-font-small;
        padding: 0 30px;

        a {
            color: $color-text-primary;
            font-weight: 600;
        }
    }

    .interior-callout-type {
        text-align: center;
        width: 100%;
    }

    .interior-callout-line {
        @include title-line;
        margin: 20px auto;
    }

    .interior-callout-content {
        width: 100%;
        line-height: 1.75em;
        padding: 0 30px 30px 30px;

        @media(max-width:992px) {
            font-size: .9em;
        }
    }

    .interior-callout-date {
        width: 100%;
        padding: 0 30px;
        margin: 15px 0;
    }

    .interior-callout-location {
        width: 100%;
        padding: 0 30px;
    }

    .interior-callout-summary {
        padding: 0 30px;
    }

    .interior-callout-btn {
        width: 100%;
        padding: 30px;
    }
}

.solutions-container,
.services-container {
    .interior-callout-item {
        .interior-callout-title {
            font-family: Montserrat,Arial,sans-serif;
            font-weight: 800;
        }
        .interior-callout-type {
            display:none;
        }
    }
}