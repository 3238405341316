﻿.my-part-num-container {
    border: 1px solid $color-border;

    .roc-pageOptions {
        background-color: $light-gray;
        margin: 0;
        padding: 25px 40px;
    }

    .customer-part-number-wrapper {
        padding: 20px 40px;
    }
}